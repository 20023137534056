import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertUser, UpsertUserVariables } from '../types/schemaTypes';

export const UPSERT_USER = gql`
  mutation UpsertUser($input: UpsertUserInput!) {
    upsertUser(input: $input) {
      id
    }
  }
`;

export const useUpsertUserMutation = () => {
  return useMutation<UpsertUser, UpsertUserVariables>(UPSERT_USER);
};
