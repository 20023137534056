import { LazyQueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Upload, UploadVariables } from '../types/schemaTypes';

export const UPLOAD = gql`
  query Upload($id: String!) {
    upload(id: $id) {
      uploadDetails {
        id
        fileName
        name
      }
    }
  }
`;

export const useUploadQuery = (id: string) => {
  return useQuery<Upload, UploadVariables>(UPLOAD, {
    variables: {
      id,
    },
  });
};

export const useUploadLazyQuery = (
  options?: LazyQueryHookOptions<Upload, UploadVariables>,
) => {
  return useLazyQuery<Upload, UploadVariables>(UPLOAD, { ...options });
};
