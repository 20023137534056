import gql from 'graphql-tag';
import { SearchEntity } from '../fragments';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { EntityById, EntityByIdVariables } from '../types/schemaTypes';

const ENTITY_BY_ID = gql`
  query EntityById($id: ID!) {
    entityById(id: $id) {
      data {
        ...SearchEntity
        type {
          fields {
            id
            name
            dataPoint {
              dataType
              __typename
            }
          }
        }
      }
      errors
    }
  }
  ${SearchEntity}
`;

export const useEntityDetailsQuery = (
  options?: QueryHookOptions<EntityById, EntityByIdVariables>,
) => {
  return useQuery<EntityById, EntityByIdVariables>(ENTITY_BY_ID, {
    ...options,
  });
};
