import { atom } from 'recoil';

interface UserMetadataModalOpenInterface {
  entityId: string;
  open: boolean;
}

export const userMetadataModalOpenAtom = atom<UserMetadataModalOpenInterface>({
  key: 'UserMetadataModalOpen',
  default: { entityId: '', open: false },
});
