import React from 'react';
import {
  searchFormActions,
  useSearchFormDispatch,
  useSearchFormState,
} from '../../contexts';
import SearchFormVisibilityToggle from './SearchFormVisibilityToggle';

const SearchFormVisibilityToggleConnected = () => {
  const { select } = useSearchFormState();
  const dispatch = useSearchFormDispatch();

  const updateVisibility = React.useCallback(
    (select: boolean) => {
      dispatch(
        searchFormActions.update({
          value: {
            select,
          },
        }),
      );
    },
    [dispatch],
  );
  return (
    <SearchFormVisibilityToggle value={select} onChange={updateVisibility} />
  );
};

export default SearchFormVisibilityToggleConnected;
