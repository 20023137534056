import React from 'react';
import { useUnclassifiedGroupById } from '../../queries';
import { useSetRecoilState } from 'recoil';
import { ClassificationSnackbarOpen } from '../../atoms/ClassificationJobsAtom';
import UnclassifiedGroupCard, {
  UnclassifiedGroupCardProps,
} from './UnclassifiedGroupCard';

export interface UnclassifiedGroupCardConnectedProps
  extends UnclassifiedGroupCardProps {
  refetchGroups: () => void;
}

const UnclassifiedGroupCardConnected = ({
  id,
  onClick,
  locked,
  refetchGroups,
  ...rest
}: UnclassifiedGroupCardConnectedProps) => {
  const setClassificationSnackbarOpen = useSetRecoilState(
    ClassificationSnackbarOpen,
  );
  const handleClick = React.useCallback(
    (id: string) => {
      setClassificationSnackbarOpen(false);
      onClick(id);
    },
    [setClassificationSnackbarOpen, onClick],
  );

  const { startPolling, stopPolling } = useUnclassifiedGroupById({
    variables: {
      id: id,
    },
    skip: !locked,
    onCompleted: (result) => {
      if (
        (result.unclassifiedGroupById &&
          !result?.unclassifiedGroupById.locked) ||
        result.unclassifiedGroupById === null
      ) {
        refetchGroups();
      }
    },
  });

  React.useEffect(() => {
    if (locked) {
      startPolling(1000);
    } else {
      stopPolling();
    }
  }, [stopPolling, startPolling, locked]);

  return (
    <UnclassifiedGroupCard
      id={id}
      locked={locked}
      onClick={handleClick}
      {...rest}
    />
  );
};

export default UnclassifiedGroupCardConnected;
