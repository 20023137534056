import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import {
  SearchSavedSearches,
  SearchSavedSearchesVariables,
} from '../types/schemaTypes';
export const SEARCH_SAVED_SEARCHES = gql`
  query SearchSavedSearches(
    $filter: SavedSearchesSearchFilter
    $order: SortOrder
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    searchSavedSearches(
      filter: $filter
      order: $order
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data {
        totalCount
        pageInfo {
          endCursor
          startCursor
          endIndex @client
          startIndex @client
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            name
            description
            public
            createdAt
            updatedAt
            type
            user {
              id
              name
              picture
            }
          }
        }
      }
      errors
    }
  }
`;

export const useSavedSearchesQuery = (
  options?: LazyQueryHookOptions<
    SearchSavedSearches,
    SearchSavedSearchesVariables
  >,
) => {
  return useLazyQuery<SearchSavedSearches, SearchSavedSearchesVariables>(
    SEARCH_SAVED_SEARCHES,
    {
      ...options,
      notifyOnNetworkStatusChange: true,
    },
  );
};

export const useSearchSavedSearchesQuery = (
  options?: QueryHookOptions<SearchSavedSearches, SearchSavedSearchesVariables>,
) => {
  return useQuery<SearchSavedSearches, SearchSavedSearchesVariables>(
    SEARCH_SAVED_SEARCHES,
    {
      ...options,
      notifyOnNetworkStatusChange: true,
    },
  );
};
