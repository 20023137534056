import React from 'react';
import {
  CircularProgress,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DownloadIcon from '@mui/icons-material/Download';
import { EntityJobState } from '../../types/schemaTypes';
import {
  useGetExportJobQuery,
  useLazyGetExportJobLinkQuery,
} from '../../queries';

interface ExportSnackbarContentProps {
  exportJobId: string;
  index: number;
  reportJobState: (state: EntityJobState, index: number) => void;
}

const ExportSnackbarContent = ({
  exportJobId,
  index,
  reportJobState,
}: ExportSnackbarContentProps) => {
  const { data, stopPolling, startPolling } = useGetExportJobQuery({
    variables: {
      id: exportJobId,
    },
  });

  const [getLink, { data: linkData }] = useLazyGetExportJobLinkQuery({
    variables: {
      id: exportJobId,
    },
    onCompleted: (result) => {
      if (result.getExportJob?.outputDetails?.link) {
        document.location.href = result.getExportJob.outputDetails.link;
      }
    },
  });

  React.useEffect(() => {
    if (data?.getExportJob?.state) {
      reportJobState(data.getExportJob.state, index);
      if (data.getExportJob.state === EntityJobState.PROCESSING) {
        startPolling(5000);
      }
      if (data.getExportJob.state === EntityJobState.FAILED) {
        stopPolling();
      }
      if (data.getExportJob.state === EntityJobState.COMPLETE) {
        stopPolling();
        if (data.getExportJob.outputDetails?.hasExportedResults) {
          getLink({ variables: { id: exportJobId } });
        }
      }
    }
  }, [
    data,
    exportJobId,
    getLink,
    index,
    reportJobState,
    startPolling,
    stopPolling,
  ]);

  const jobState = data?.getExportJob?.state;

  return (
    <>
      <ListItemAvatar>
        {jobState === EntityJobState.PROCESSING && (
          <CircularProgress size={20} />
        )}
        {jobState === EntityJobState.COMPLETE && <CheckIcon color="success" />}
        {jobState === EntityJobState.FAILED && (
          <ReportProblemIcon color="error" />
        )}
      </ListItemAvatar>
      <ListItemText>
        {jobState === EntityJobState.PROCESSING &&
          'Export in progress. Please wait...'}
        {jobState === EntityJobState.COMPLETE && 'Export complete.'}
        {jobState === EntityJobState.FAILED &&
          'An error occured while preparing your download. Please try again.'}
      </ListItemText>
      <ListItemSecondaryAction>
        {jobState === EntityJobState.COMPLETE && (
          <IconButton
            href={linkData?.getExportJob?.outputDetails?.link || ''}
            rel="noreferrer"
          >
            <DownloadIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </>
  );
};

export default ExportSnackbarContent;
