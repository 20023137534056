import { EditEntitiesState } from '../contexts';
import {
  EditEntities,
  EditEntities_editEntities_validations_fieldValidations,
} from '../types/schemaTypes';

export const reduceValidations = (data: EditEntities) => {
  if (
    data &&
    data.editEntities &&
    data.editEntities.validations &&
    data.editEntities.validations.length > 0
  ) {
    const reducedValidations = data.editEntities.validations.reduce<
      EditEntitiesState['validations']
    >((acc, validation) => {
      return {
        ...acc,
        [validation.id]: {
          ...validation,
          fieldValidations: validation.fieldValidations.reduce<
            Record<
              string,
              EditEntities_editEntities_validations_fieldValidations
            >
          >((acc, fieldValidation) => {
            return {
              ...acc,
              [fieldValidation.fieldDefinitionId]: fieldValidation,
            };
          }, {}),
        },
      };
    }, {});
    return reducedValidations;
  }
  return undefined;
};
