import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  RemoveControlledVocabularyCategories,
  RemoveControlledVocabularyCategoriesVariables,
} from '../types/schemaTypes';

const DELETE_LIST = gql`
  mutation RemoveControlledVocabularyCategories($ids: [ID!]!) {
    removeControlledVocabularyCategories(ids: $ids) {
      count
      errors
    }
  }
`;
export const useDeleteListMutation = (
  options?: MutationHookOptions<
    RemoveControlledVocabularyCategories,
    RemoveControlledVocabularyCategoriesVariables
  >,
) => {
  return useMutation<
    RemoveControlledVocabularyCategories,
    RemoveControlledVocabularyCategoriesVariables
  >(DELETE_LIST, options);
};
