import { Draft } from 'immer';
import { searchFormActions, useSearchFormDispatch } from '../contexts';
import { FilterLeaf, AtLeastOne } from '../types';

function useFilterLeafUpdate() {
  const dispatch = useSearchFormDispatch();
  return (id: string, changes: Draft<AtLeastOne<FilterLeaf>>) => {
    dispatch(
      searchFormActions.filter.leaf.update({
        id,
        value: changes,
      }),
    );
  };
}

export default useFilterLeafUpdate;
