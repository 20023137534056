import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
  GroupWithProjectAccess,
  GroupWithProjectAccessVariables,
} from '../types/schemaTypes';

export const GROUP_WITH_PROJECT_ACCESS = gql`
  query GroupWithProjectAccess($id: ID!) {
    group(id: $id) {
      group {
        id
        name
        groupProjectAccess(first: 10) {
          totalCount
          edges {
            node {
              id
              project {
                id
                name
              }
            }
          }
        }
      }
      errors
    }
  }
`;

export const useGetGroupWithProjectAccessQuery = (
  options?: QueryHookOptions<
    GroupWithProjectAccess,
    GroupWithProjectAccessVariables
  >,
) => {
  return useQuery<GroupWithProjectAccess, GroupWithProjectAccessVariables>(
    GROUP_WITH_PROJECT_ACCESS,
    {
      ...options,
    },
  );
};
