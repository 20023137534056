import { Box, Dialog, Grid } from '@mui/material';
import React from 'react';
import {
  Uploads_uploads_edges_node,
  UploadState,
} from '../../types/schemaTypes';
import { useUploadsQuery } from '../../queries/useUploadsQuery';
import { useUploadTabsState } from '../../contexts';
import UploadFilterDropdown from '../UploadFilterDropdown';
import ListSearchControl from '../ListSearchControl';
import { useDebounce } from '../../hooks';
import SelectableListToolbar from '../SelectableListToolbar';
import SNButton from '../SNButton';
import EditIcon from '@mui/icons-material/Edit';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { UploadToReviewIdAtom } from './UploadToReviewIdAtom';
import UploadReviewModal from '../UploadReviewModal/UploadReviewModal';
import { pageIdsAtom, selectedIdsAtom } from '../../atoms/listSelection';

import { ScreenWidthAtom } from '../../atoms/screenWidth';
import SearchIcon from '@mui/icons-material/Search';
import ListFilterModal from '../ListFilterModal';
import SNPagination from '../SNPagination';
import SNTable from '../SNTable';
import UploadListItem from '../UploadListItem';
import ListSelectionControl from '../ListSelectionControl';
import { useActiveUploadsQuery } from '../../queries';

const defaultUploadState = [
  UploadState.NEEDS_REVIEW,
  UploadState.CAPTURING,
  UploadState.COMPLETED,
  UploadState.PROCESSING,
];

const headers = ['name', 'data subset', 'quality', 'date', 'user', 'status'];

interface UploadsTableProps {
  disableStateFilter?: boolean;
}

const UploadsTable = ({ disableStateFilter }: UploadsTableProps) => {
  const pageSize = 20;
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const [searchTerm, setSearchTerm] = React.useState('');
  const setUploadToReviewId = useSetRecoilState(UploadToReviewIdAtom);
  const debouncedValue = useDebounce(searchTerm, 300);
  const value = useUploadTabsState();
  const activeUploads = useActiveUploadsQuery();
  const [
    uploadsQuery,
    { data, error, loading, fetchMore, startPolling, stopPolling },
  ] = useUploadsQuery({
    onCompleted: (result) => {
      const pageIds = result?.uploads?.edges?.reduce<string[]>(
        (result, edge) =>
          edge?.node?.__typename === 'UploadStatus'
            ? result.concat(edge.node.uploadDetails.id)
            : result,
        [],
      );
      setPageIds(pageIds || []);
    },
  });
  const selectedRowIds = useRecoilValue(selectedIdsAtom);
  const screenWidth = useRecoilValue(ScreenWidthAtom);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);

  React.useEffect(() => {
    uploadsQuery({
      variables: {
        filter: {
          uploadStates: value || defaultUploadState,
          searchTerm: debouncedValue,
        },
        first: pageSize,
      },
    });
  }, [uploadsQuery, value, debouncedValue]);

  const uploads = React.useMemo(() => {
    return data?.uploads?.edges?.reduce<Uploads_uploads_edges_node[]>(
      (result, edge) =>
        edge?.node?.__typename === 'UploadStatus'
          ? result.concat(edge.node)
          : result,
      [],
    );
  }, [data]);

  const uploadsProcessing = React.useMemo(() => {
    const processing = uploads?.find(
      (upload) => upload.uploadState === UploadState.PROCESSING,
    );
    return !!processing;
  }, [uploads]);

  if (uploadsProcessing || activeUploads.length > 0) {
    startPolling(4000);
  } else {
    stopPolling();
  }

  const pageTotal = uploads?.length || 0;

  return (
    <Box>
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            {...data?.uploads?.pageInfo}
            fetchMore={fetchMore}
            loading={uploadsProcessing ? false : loading}
            pageSize={pageSize}
            pageTotal={pageTotal}
            totalCount={data?.uploads?.totalCount}
          />
        </Box>
        <Box>
          <Grid container spacing={1}>
            {selectedRowIds.length > 0 && (
              <Grid item>
                <SNButton snVariant="text" startIcon={<EditIcon />}>
                  Edit Metadata
                </SNButton>
              </Grid>
            )}
            {selectedRowIds.length === 0 && screenWidth > 1100 && (
              <>
                {!disableStateFilter && (
                  <Grid item>
                    <UploadFilterDropdown />
                  </Grid>
                )}
                <Grid item>
                  <ListSearchControl
                    value={searchTerm}
                    setValue={setSearchTerm}
                  />
                </Grid>
              </>
            )}
            {selectedRowIds.length === 0 && screenWidth < 1100 && (
              <>
                <SNButton
                  data-testid="filter_list_button"
                  snVariant="text"
                  startIcon={<SearchIcon />}
                  onClick={() => setFilterModalOpen(true)}
                >
                  Filter this list
                </SNButton>
                <Dialog open={filterModalOpen}>
                  <ListFilterModal
                    listTitle="Uploads"
                    closeModal={() => setFilterModalOpen(false)}
                  >
                    <Box m={1} ml={2}>
                      <ListSearchControl
                        value={searchTerm}
                        setValue={setSearchTerm}
                        forceShow
                      />
                    </Box>
                    {!disableStateFilter && (
                      <Box m={1}>
                        <UploadFilterDropdown />
                      </Box>
                    )}
                  </ListFilterModal>
                </Dialog>
              </>
            )}
            {selectedRowIds.length === 1 && (
              <Grid item>
                <SNButton
                  snVariant="text"
                  startIcon={<ReportProblemIcon />}
                  onClick={() =>
                    setUploadToReviewId({ open: true, id: selectedRowIds[0] })
                  }
                >
                  View Quality Summary
                </SNButton>
              </Grid>
            )}
          </Grid>
        </Box>
      </SelectableListToolbar>
      <SNTable
        avatar
        error={error}
        hasResults={!!uploads}
        headers={headers}
        id="uploads"
        loading={uploadsProcessing ? false : loading}
        rowCount={pageTotal}
        selectable
      >
        {uploads?.map(
          (
            {
              processingDetails,
              uploadDetails: { name, id, completedAt, fileName, subset, user },
              uploadState,
            },
            index,
          ) => (
            <UploadListItem
              key={id}
              completedAt={completedAt}
              fileName={fileName || ''}
              id={id}
              index={index}
              name={name}
              reviewCount={processingDetails?.reviewCount || 0}
              subset={subset}
              uploadState={uploadState}
              userName={user?.name || ''}
              userPicture={user?.picture || ''}
            />
          ),
        )}
      </SNTable>
      <UploadReviewModal />
    </Box>
  );
};

export default UploadsTable;
