import React from 'react';
import {
  CustomCell,
  CustomRenderer,
  GridCellKind,
  ProvideEditorCallback,
} from '@glideapps/glide-data-grid';
import { AnnotationSeverity } from '../../types/schemaTypes';
import { Box } from '@mui/material';
import { drawTextCell } from './CustomCells';
import Pill from '../Pill';

interface LinkSummaryCellProps {
  readonly kind: 'link-summary-cell';
  readonly displayStatus: AnnotationSeverity | null;
  readonly displayValue: string;
  readonly entityId: string;
  readonly onClick: (entityId: string) => void;
}

type LinkSummaryCell = CustomCell<LinkSummaryCellProps>;
export type { LinkSummaryCell as LinkSummaryCellType };

const Editor: ReturnType<ProvideEditorCallback<LinkSummaryCell>> = (props) => {
  const { value } = props;

  const handleClick = React.useCallback(() => {
    value.data.onClick(value.data.entityId);
  }, [value.data]);

  return (
    <Box minHeight={59} px={2} alignItems="center" display="flex">
      <Box component="label" textOverflow="ellipsis" overflow="hidden">
        <Pill snVariant="input" onClick={handleClick}>
          {value.data.displayValue}
        </Pill>
      </Box>
    </Box>
  );
};

const renderer: CustomRenderer<LinkSummaryCell> = {
  isMatch: (cell: CustomCell): cell is LinkSummaryCell =>
    (cell.data as any).kind === 'link-summary-cell',
  draw: (args, cell) => {
    const { data, readonly } = cell;
    const { displayStatus: status, displayValue: value } = data;
    return drawTextCell(args, {
      value,
      status,
      textDecoration: 'underline',
      readonly,
    });
  },
  provideEditor: () => ({
    editor: Editor,
    disablePadding: true,
  }),
  kind: GridCellKind.Custom,
};

export default renderer;
