import { Box, IconButton, IconButtonProps } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styled from '../../services/styled';

interface DialogCloseButtonProps {
  onClose: () => void;
  size?: IconButtonProps['size'];
  spacing?: number;
}

export const StyledCloseButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.text.secondary};

  :hover {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  :focus {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const DialogCloseButton: React.FC<DialogCloseButtonProps> = ({
  onClose,
  size,
  spacing = 6,
}) => {
  return (
    <Box position="absolute" top={spacing} right={spacing}>
      <StyledCloseButton onClick={onClose} size={size}>
        <CloseIcon fontSize={size} />
      </StyledCloseButton>
    </Box>
  );
};

export default DialogCloseButton;
