import { Box, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import styled from '../../services/styled';

interface SearchFormVisibilityToggleProps {
  value: boolean;
  onChange: (checked: boolean) => void;
}

const StyledVisibilityIcon = styled(VisibilityIcon)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledVisibilityOffIcon = styled(VisibilityOffIcon)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

const SearchFormVisibilityToggle: React.FC<SearchFormVisibilityToggleProps> = ({
  onChange,
  value,
}) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    onChange(checked);
  };

  return (
    <Box pl="2px" my={-1} pt={1}>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            checkedIcon={<StyledVisibilityIcon />}
            icon={<StyledVisibilityOffIcon />}
            onChange={handleChange}
          />
        }
        label={value ? 'Show in results' : 'Do not show in results'}
      />
    </Box>
  );
};

export default SearchFormVisibilityToggle;
