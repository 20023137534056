import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { ApproveReview, ApproveReviewVariables } from '../types/schemaTypes';

export const APPROVE_REVIEW = gql`
  mutation ApproveReview($input: ApproveReviewInput!) {
    approveReview(input: $input) {
      success
      errors
    }
  }
`;

export const useApproveReviewMutation = (
  options?: MutationHookOptions<ApproveReview, ApproveReviewVariables>,
) => {
  return useMutation<ApproveReview, ApproveReviewVariables>(
    APPROVE_REVIEW,
    options,
  );
};
