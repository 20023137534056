import React from 'react';
import { Box, Collapse, Dialog, Grid } from '@mui/material';
import { SearchTree } from '../../components';
import { SearchTreeNavProvider, EditEntitiesProvider } from '../../contexts';
import SearchSavePrompt from '../../components/SearchSavePrompt';
import EntityTypeResultTabs from '../../components/EntityTypeResultTabs';
import SearchFilterDropdown from '../../components/SearchFilterDropdown';
import { useShowFiltersState } from '../../contexts/ShowFiltersContext';
import { hasEntityEditsState, unsavedSearchVar } from '../../localState';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isEditingEntitiesAtom } from '../../atoms/editEntities';
import { ENTITIES_BY_TYPE, useEntitiesByTypeQuery } from '../../queries';
import { pageIdsAtom } from '../../atoms/listSelection';
import { useSearchInput } from '../../hooks';
import ErrorResult from '../../components/ErrorResult';
import LoadingOverlay from '../../components/LoadingOverlay';
import SNPagination from '../../components/SNPagination';
import ClassificationWizard from '../../components/ClassificationWizard';
import { useApolloClient } from '@apollo/client';
import { NEW_REVIEW_KEY, NEW_SEARCH_KEY } from '../../constants';
import {
  searchTabsActiveTabSelector,
  searchTabsTabOrderSelector,
} from '../../atoms/latestSearchAtom';
import ScrollingContainer from '../../components/ScrollingContainer';
import EntitiesTable from '../../components/EntitiesTable';
import UnclassifiedGroupCard from '../../components/UnclassifiedGroupCard';
import { entityTableRows } from '../../atoms/entityTableRows';
import { mapEntityResultToTableRow } from '../../utils/entityResultsToTableRows';
import SNToolbar from '../../components/SNToolbar';
import ConnectedGlideDataGridSelectionControl from '../../components/GlideDataGridSelectionControl/ConnectedGlideDataGridSelectionControl';
import SearchResultsTableActions from '../../components/SearchResultsTableActions';
import { collectErrors } from '../../utils/collectErrors';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';

interface ReviewContainerProps {
  searchId: string;
}

/*
  So this is a bit hacky. Basically what I am using this for
  is to sort one list by another list so that the new list takes
  on the order of the old list wherever they overlap. Without this
  conversion items that are in the new list, but not the old get
  an sort value -1 which puts them at the front of the list. I want
  them to go at the end. This seemed like the cleanest way to do that.
*/
const changeNegativeOneToNineHundredNinetyNine = (int: number) =>
  int === -1 ? 999 : int;

const tableId = 'review';
const pageSize = 30;

const ReviewContainer: React.FC<ReviewContainerProps> = ({ searchId }) => {
  const [page, setPage] = React.useState(0);
  const showFilters = useShowFiltersState();
  const isEditing = useRecoilValue(isEditingEntitiesAtom);
  const hasEntityEdits = useRecoilValue(hasEntityEditsState);
  const client = useApolloClient();
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);

  const [currentTypeId, setCurrentTypeId] = useRecoilState(
    searchTabsActiveTabSelector(searchId),
  );
  const [classificationModalInfo, setClassificationModalInfo] = React.useState<{
    open: boolean;
    id: string;
  }>({ open: false, id: '' });
  const generateSearchInput = useSearchInput(searchId);
  const searchQuery = React.useMemo(() => {
    return generateSearchInput();
  }, [generateSearchInput]);
  const setSearchResultValues = useSetRecoilState(entityTableRows(tableId));
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const setTabOrder = useSetRecoilState(searchTabsTabOrderSelector(searchId));

  const { loading, error, data, fetchMore, refetch } = useEntitiesByTypeQuery({
    variables: {
      first: pageSize,
      searchQuery,
      type: currentTypeId,
    },
    onCompleted: (result) => {
      const startIndex = result.entitiesByType.data?.pageInfo.startIndex;
      if (typeof startIndex === 'number') {
        setPage(startIndex / pageSize);
      }
      const receivedTypeId = result.entitiesByType.data?.currentType?.id;
      if (result.entitiesByType.data?.types.length) {
        if (receivedTypeId && !currentTypeId) {
          client.cache.updateQuery(
            {
              query: ENTITIES_BY_TYPE,
              variables: { first: pageSize, searchQuery, type: receivedTypeId },
            },
            () => result,
          );
          setCurrentTypeId(receivedTypeId);
        }
      }
    },
  });

  React.useEffect(() => {
    const receivedTypeId = data?.entitiesByType.data?.currentType?.id;
    if (data?.entitiesByType.data?.types.length) {
      const typeIds = data.entitiesByType.data.types.map(({ id }) => id);
      setTabOrder((prev) =>
        typeIds
          .slice()
          .sort(
            (a, b) =>
              changeNegativeOneToNineHundredNinetyNine(prev.indexOf(a)) -
              changeNegativeOneToNineHundredNinetyNine(prev.indexOf(b)),
          ),
      );
      if (
        receivedTypeId &&
        !data.entitiesByType.data.types.find(
          (type) => type.id === receivedTypeId,
        )
      ) {
        setCurrentTypeId(data.entitiesByType.data.types[0].id);
      }
    }
  }, [client.cache, data, searchQuery, setCurrentTypeId, setTabOrder]);

  React.useEffect(() => {
    if (
      data?.entitiesByType?.data?.__typename === 'EntityResultsByTypeConnection'
    ) {
      setPageIds(
        data.entitiesByType.data.edges.map((edge) => edge.node.entity.id),
      );
      setSearchResultValues(
        data.entitiesByType.data.edges.map(mapEntityResultToTableRow),
      );
    }
  }, [data, setPageIds, setSearchResultValues]);

  const handleBlockSearchNavigation = React.useCallback(
    () => unsavedSearchVar(),
    [],
  );

  const handleSearchNavigation = React.useCallback(() => {
    unsavedSearchVar(false);
  }, []);

  const handleClassifyClick = React.useCallback((groupId: string) => {
    setClassificationModalInfo({ open: true, id: groupId });
  }, []);

  const handleRefetch = React.useCallback(() => {
    refetch();
  }, [refetch]);

  const handleClassifyClose = React.useCallback(() => {
    setClassificationModalInfo((prev) => {
      return { ...prev, open: false };
    });
    handleRefetch();
  }, [handleRefetch]);
  const pageTotal = data?.entitiesByType?.data?.edges.length || 0;

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.entitiesByType.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error querying for entities',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <>
      <SearchSavePrompt
        when={
          ![NEW_SEARCH_KEY, NEW_REVIEW_KEY].includes(searchId) &&
          !hasEntityEdits
        }
        onNavigate={handleSearchNavigation}
        shouldBlockNavigation={handleBlockSearchNavigation}
        title="Unsaved changes"
        message="You have unsaved changes to this search. Do you want to proceed and
            discard those changes?"
        primary="Discard Changes"
      />
      <SearchTreeNavProvider>
        <Collapse in={!isEditing && showFilters}>
          <Box pt={2} pb={4} px={4}>
            <SearchTree searchId={searchId} />
          </Box>
        </Collapse>
      </SearchTreeNavProvider>
      <Box pb={2}>
        <SearchFilterDropdown isReview searchId={searchId} />
      </Box>
      <Box position="relative">
        <EntityTypeResultTabs
          searchId={searchId}
          disabled={isEditing || loading}
          types={data?.entitiesByType?.data?.types}
        />
        {error && <ErrorResult data-testid="search-results-error" />}
        <EditEntitiesProvider>
          <SNToolbar bgcolor={isEditing ? 'primary.main' : 'grey.900'}>
            <Box display="flex" alignItems="center">
              {data?.entitiesByType.data?.__typename ===
                'EntityResultsByTypeConnection' && (
                <Box display="flex" alignItems="center" ml={-1} pr={2}>
                  <ConnectedGlideDataGridSelectionControl
                    page={page}
                    pageTotal={pageTotal}
                    tableId={tableId}
                  />
                </Box>
              )}
              <SNPagination
                {...data?.entitiesByType.data?.pageInfo}
                fetchMore={fetchMore}
                loading={loading}
                pageSize={pageSize}
                pageTotal={pageTotal}
              />
            </Box>
            {data?.entitiesByType?.data?.__typename !==
              'UnclassifiedResultGroupsConnection' && (
              <SearchResultsTableActions
                isReview
                onEditSuccess={handleRefetch}
                page={page}
                pageTotal={pageTotal}
                searchId={searchId}
                tableId={tableId}
                typeId={currentTypeId}
              />
            )}
          </SNToolbar>
          <Box id="search-results-table">
            <ScrollingContainer>
              {data?.entitiesByType.data?.__typename ===
                'EntityResultsByTypeConnection' &&
                data?.entitiesByType.data?.currentType && (
                  <EntitiesTable
                    rowCount={data.entitiesByType.data.edges.length}
                    currentTypeId={data.entitiesByType.data.currentType.id}
                    tableId={tableId}
                    page={page}
                  />
                )}
              {data?.entitiesByType.data?.__typename ===
                'UnclassifiedResultGroupsConnection' && (
                <Box p={2.5}>
                  <Grid container spacing={2.5}>
                    {data.entitiesByType.data.edges.map(({ node }) => (
                      <Grid item key={node.id}>
                        <UnclassifiedGroupCard
                          id={node.id}
                          name={node.name}
                          totalCount={node.totalCount || 0}
                          confidence={node.confidence}
                          onClick={handleClassifyClick}
                          locked={node.locked}
                          entityTypeName={
                            node.matchingTypes.length > 0
                              ? node.matchingTypes[0].name
                              : undefined
                          }
                          refetchGroups={refetch}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
            </ScrollingContainer>
          </Box>
        </EditEntitiesProvider>
        <Dialog
          open={classificationModalInfo.open}
          fullScreen
          sx={{ zIndex: 2 }}
        >
          <ClassificationWizard
            groupId={classificationModalInfo.id}
            onClose={handleClassifyClose}
            searchId={searchId}
          />
        </Dialog>
        {loading && (
          <LoadingOverlay
            isLoading={loading}
            data-testid="search-results-loading"
          />
        )}
      </Box>
    </>
  );
};

export default ReviewContainer;
