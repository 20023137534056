import React, { ChangeEvent } from 'react';
import { Box, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { ClassificationWizardFormInfo } from '../../atoms/ClassificationWizardFormInfo';
import { ClassificationDetail } from '../../types/schemaTypes';
import SNInput from '../SNInput';

interface ClassificationQuestionAddMissingColumnsProps {
  groupInfo: ClassificationDetail | null;
}

const ClassificationQuestionAddMissingColumns = ({
  groupInfo,
}: ClassificationQuestionAddMissingColumnsProps) => {
  const [classificationWizardFormInfo, setClassificationWizardFormInfo] =
    useRecoilState(ClassificationWizardFormInfo);

  const filteredMissingColumns = React.useMemo(() => {
    //get the right entitytype
    const correctType = groupInfo?.matchingTypes?.find(
      (match) =>
        match.entityType.id === classificationWizardFormInfo.selectedTypeId,
    );
    //check the missing cols agianst the renamed cols
    const missingCols = correctType?.missingColumns.filter((missingCol) => {
      let renamedMatch = false;
      classificationWizardFormInfo.columnsToRename.forEach((renamedCol) => {
        if (
          !renamedMatch &&
          renamedCol.targetFieldDefinitionId === missingCol.fieldDefinitionId
        ) {
          renamedMatch = true;
        }
      });
      if (!renamedMatch) {
        return missingCol;
      }
    });
    return missingCols;
  }, [
    classificationWizardFormInfo.selectedTypeId,
    classificationWizardFormInfo.columnsToRename,
    groupInfo,
  ]);

  const handleDefaultValueChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setClassificationWizardFormInfo((prev) => {
      const restOfMisingColumns = prev.missingColumns.filter(
        (missingCol) => missingCol.fieldDefinitionId !== e.target.name,
      );
      return {
        ...prev,
        missingColumns: [
          ...restOfMisingColumns,
          { fieldDefinitionId: e.target.name, value: e.target.value },
        ],
      };
    });
  };

  const getInputValue = (fieldID: string) => {
    const thing = classificationWizardFormInfo.missingColumns.filter(
      (col) => fieldID === col.fieldDefinitionId,
    );
    return thing[0] ? thing[0].value : '';
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="h3">Step 3: Missing Columns</Typography>
      </Box>
      <Typography>
        The following columns are missing. You must add them before proceeding
      </Typography>
      {filteredMissingColumns && filteredMissingColumns.length > 0 ? (
        <Box>
          {filteredMissingColumns?.map((missingColumn, index) => (
            <Box
              mt={4}
              display="flex"
              flexDirection="column"
              key={missingColumn.fieldDefinitionId}
            >
              <Box display="flex" flexDirection="column">
                <Typography variant="h5">
                  {index + 1}. {missingColumn.name}
                </Typography>
              </Box>
              <Box ml={2} mt={1} display="flex" flexDirection="column">
                <Typography variant="h6" color="textSecondary">
                  Default value
                </Typography>
              </Box>
              <Box ml={2} mt={1}>
                <SNInput
                  name={missingColumn.fieldDefinitionId}
                  value={getInputValue(missingColumn.fieldDefinitionId)}
                  onChange={handleDefaultValueChange}
                  size="small"
                  fullWidth
                />
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" mt={8}>
          <Typography variant="h5">
            No columns are missing. Good job! Click next to review your changes.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ClassificationQuestionAddMissingColumns;
