import React from 'react';
import Delete from '@mui/icons-material/Delete';
import SNButton from '../SNButton';
import { useRecoilValue } from 'recoil';
import { selectedIdsAtom } from '../../atoms/listSelection';

interface SavedSearchesDeleteProps {
  onDelete: (ids: string[]) => void;
}

const SavedSearchesDelete = ({ onDelete }: SavedSearchesDeleteProps) => {
  const selectedIds = useRecoilValue(selectedIdsAtom);

  const handleDelete = React.useCallback(() => {
    onDelete(selectedIds);
  }, [onDelete, selectedIds]);

  return (
    <SNButton
      data-testid="delete_saved_search_button"
      snVariant="text"
      onClick={handleDelete}
      startIcon={<Delete />}
    >
      Delete
    </SNButton>
  );
};

export default SavedSearchesDelete;
