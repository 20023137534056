import React from 'react';
import styled from '../../services/styled';
import { Box, Typography, ButtonBase, IconButton } from '@mui/material';
import {
  useSearchTreeNavState,
  useSearchTreeNavDispatch,
} from '../../contexts';
import { Add as AddIcon } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { BoxProps } from '@mui/material/Box';
import { unsavedSearchVar } from '../../localState';

interface SearchTreeLeafProps {
  id: string;
  hoverRef: React.MutableRefObject<HTMLDivElement | null>;
  isHovered: boolean;
  isLast: boolean;
  onAddChild: () => void;
  onDelete?: (id: string) => void;
  locked?: boolean;
  parentId: string;
}

interface StyledLeafBoxProps extends BoxProps {
  isActive: boolean;
  isHovered: boolean;
}

interface StyledAddBoxProps extends BoxProps {
  isVisible: boolean;
}

const StyledLateralDeleteBox = styled(
  ({ isVisible, ...props }: StyledAddBoxProps) => <Box {...props} />,
)`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5000;
`;

const StyledIconButtonDelete = styled(IconButton)`
  :hover {
    background: rgba(153, 51, 51, 0.5);
    color: red;
  }
  > * {
    pointer-events: none;
  }
`;

const StyledLateralAddBox = styled(
  ({ isVisible, ...props }: StyledAddBoxProps) => <Box {...props} />,
)`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
`;

const StyleButtonBaseNoPointer = styled(ButtonBase)`
  > * {
    pointer-events: none;
  }
`;

const StyledLeafButtonBox = styled(
  ({ isActive, isHovered, ...props }: StyledLeafBoxProps) => <Box {...props} />,
)`
  background-color: ${({ isActive, isHovered, theme }) => {
    if (isActive) {
      if (isHovered) {
        return theme.palette.success.dark;
      }
      return theme.palette.success.main;
    } else if (isHovered) {
      return theme.palette.secondary.dark;
    }
    return theme.palette.secondary.main;
  }};
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const StyledIconButtonNoPointer = styled(IconButton)`
  > * {
    pointer-events: none;
  }
`;

const SearchTreeLeaf: React.FC<SearchTreeLeafProps> = ({
  hoverRef,
  id,
  isHovered,
  isLast,
  onAddChild,
  onDelete,
  children,
  parentId,
}) => {
  const { activeId } = useSearchTreeNavState();
  const setActiveId = useSearchTreeNavDispatch();

  function handleExistingClick(_event: React.MouseEvent<HTMLButtonElement>) {
    setActiveId({ showForm: true, activeId: id, parentId });
  }

  const handleDelete = () => {
    if (onDelete) onDelete(id);
    unsavedSearchVar(true);
  };

  return (
    <div data-testid="search-node" ref={hoverRef}>
      <Box display="flex">
        <Box position="relative">
          {onDelete && (
            <StyledLateralDeleteBox left={-22} isVisible={isHovered}>
              <StyledIconButtonDelete
                data-testid={`search-tree-leaf-delete-button-${id}`}
                onClick={handleDelete}
              >
                <DeleteForeverIcon />
              </StyledIconButtonDelete>
            </StyledLateralDeleteBox>
          )}
          <StyleButtonBaseNoPointer onClick={handleExistingClick}>
            <StyledLeafButtonBox
              display="flex"
              alignItems="center"
              width={200}
              height={57}
              paddingY={1}
              paddingX={2}
              boxShadow="0 4px 10px 0 rgba(0, 0, 0, 0.14)"
              isActive={activeId === id}
              isHovered={isHovered}
            >
              {children}
            </StyledLeafButtonBox>
          </StyleButtonBaseNoPointer>
          <StyledLateralAddBox right={-22} isVisible={isHovered}>
            <StyledIconButtonNoPointer
              onClick={onAddChild}
              data-testid={`search-tree-leaf-add-button-${id}`}
            >
              <AddIcon />
            </StyledIconButtonNoPointer>
          </StyledLateralAddBox>
        </Box>
        {isLast && isHovered && (
          <Box pl="2px">
            <ButtonBase onClick={onAddChild}>
              <Box
                bgcolor="grey.700"
                width={200}
                height={57}
                paddingY={1}
                paddingX={2}
                boxShadow="0 4px 10px 0 rgba(0, 0, 0, 0.14)"
                display="flex"
                alignItems="center"
                position="relative"
                justifyContent="center"
              >
                <Typography variant="h6">Add to Search</Typography>
              </Box>
            </ButtonBase>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default SearchTreeLeaf;
