/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ActiveUploadAddRemove
// ====================================================

export interface ActiveUploadAddRemove_activeUploadAddRemove {
  __typename: "ActiveUpload";
  id: string;
  fileName: string;
  fileType: string;
}

export interface ActiveUploadAddRemove {
  activeUploadAddRemove: ActiveUploadAddRemove_activeUploadAddRemove[];
}

export interface ActiveUploadAddRemoveVariables {
  activeUpload: ActiveUploadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ActiveUploadsSet
// ====================================================

export interface ActiveUploadsSet_activeUploadsSet {
  __typename: "ActiveUpload";
  id: string;
  fileName: string;
  fileType: string;
}

export interface ActiveUploadsSet {
  activeUploadsSet: ActiveUploadsSet_activeUploadsSet[];
}

export interface ActiveUploadsSetVariables {
  activeUploads: ActiveUploadInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ActiveUploadUpdate
// ====================================================

export interface ActiveUploadUpdate_activeUploadUpdate {
  __typename: "ActiveUpload";
  id: string;
  fileName: string;
  fileType: string;
}

export interface ActiveUploadUpdate {
  activeUploadUpdate: ActiveUploadUpdate_activeUploadUpdate[];
}

export interface ActiveUploadUpdateVariables {
  activeUpload: ActiveUploadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddGroup
// ====================================================

export interface AddGroup_addGroup_group {
  __typename: "Group";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
}

export interface AddGroup_addGroup {
  __typename: "GroupResponse";
  group: AddGroup_addGroup_group | null;
  errors: string[] | null;
}

export interface AddGroup {
  addGroup: AddGroup_addGroup | null;
}

export interface AddGroupVariables {
  input: AddGroupInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddProject
// ====================================================

export interface AddProject_addProject_project {
  __typename: "Project";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
}

export interface AddProject_addProject {
  __typename: "ProjectResponse";
  project: AddProject_addProject_project | null;
  errors: string[] | null;
}

export interface AddProject {
  addProject: AddProject_addProject | null;
}

export interface AddProjectVariables {
  input: AddProjectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddSavedSearch
// ====================================================

export interface AddSavedSearch_addSavedSearch_data {
  __typename: "SavedSearch";
  id: string;
}

export interface AddSavedSearch_addSavedSearch {
  __typename: "SavedSearchResponse";
  data: AddSavedSearch_addSavedSearch_data | null;
  errors: string[] | null;
}

export interface AddSavedSearch {
  addSavedSearch: AddSavedSearch_addSavedSearch;
}

export interface AddSavedSearchVariables {
  input: AddSavedSearchInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddItemsToControlledVocabularyCategory
// ====================================================

export interface AddItemsToControlledVocabularyCategory_addItemsToControlledVocabularyCategory_data_items_edges_node_category {
  __typename: "ControlledVocabularyCategory";
  id: string;
  name: string;
}

export interface AddItemsToControlledVocabularyCategory_addItemsToControlledVocabularyCategory_data_items_edges_node {
  __typename: "ControlledVocabularyItem";
  category: AddItemsToControlledVocabularyCategory_addItemsToControlledVocabularyCategory_data_items_edges_node_category | null;
  description: string | null;
  createdAt: any | null;
  id: string;
  value: string;
  updatedAt: any | null;
}

export interface AddItemsToControlledVocabularyCategory_addItemsToControlledVocabularyCategory_data_items_edges {
  __typename: "ControlledVocabularyItemResultEdge";
  node: AddItemsToControlledVocabularyCategory_addItemsToControlledVocabularyCategory_data_items_edges_node;
}

export interface AddItemsToControlledVocabularyCategory_addItemsToControlledVocabularyCategory_data_items {
  __typename: "ControlledVocabularyItemsConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * The elements in the current page of the connection
   */
  edges: AddItemsToControlledVocabularyCategory_addItemsToControlledVocabularyCategory_data_items_edges[];
}

export interface AddItemsToControlledVocabularyCategory_addItemsToControlledVocabularyCategory_data {
  __typename: "ControlledVocabularyCategory";
  updatedAt: any | null;
  name: string;
  items: AddItemsToControlledVocabularyCategory_addItemsToControlledVocabularyCategory_data_items | null;
  id: string;
  description: string | null;
  createdAt: any | null;
}

export interface AddItemsToControlledVocabularyCategory_addItemsToControlledVocabularyCategory {
  __typename: "ControlledVocabularyCategoryResponse";
  data: AddItemsToControlledVocabularyCategory_addItemsToControlledVocabularyCategory_data | null;
}

export interface AddItemsToControlledVocabularyCategory {
  addItemsToControlledVocabularyCategory: AddItemsToControlledVocabularyCategory_addItemsToControlledVocabularyCategory | null;
}

export interface AddItemsToControlledVocabularyCategoryVariables {
  input: AddItemsToControlledVocabularyCategoryInput;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddUsersToGroups
// ====================================================

export interface AddUsersToGroups_addUsersToGroups_groupMemberships_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface AddUsersToGroups_addUsersToGroups_groupMemberships_group {
  __typename: "Group";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
}

export interface AddUsersToGroups_addUsersToGroups_groupMemberships {
  __typename: "GroupMembership";
  user: AddUsersToGroups_addUsersToGroups_groupMemberships_user | null;
  userRole: UserRole;
  group: AddUsersToGroups_addUsersToGroups_groupMemberships_group | null;
}

export interface AddUsersToGroups_addUsersToGroups {
  __typename: "GroupMembershipsResponse";
  groupMemberships: AddUsersToGroups_addUsersToGroups_groupMemberships[] | null;
  errors: string[] | null;
}

export interface AddUsersToGroups {
  addUsersToGroups: AddUsersToGroups_addUsersToGroups | null;
}

export interface AddUsersToGroupsVariables {
  input: AddUsersToGroupsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GrantUsersAccessToProjects
// ====================================================

export interface GrantUsersAccessToProjects_grantUsersAccessToProjects_projectAccesses_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface GrantUsersAccessToProjects_grantUsersAccessToProjects_projectAccesses_project {
  __typename: "Project";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
}

export interface GrantUsersAccessToProjects_grantUsersAccessToProjects_projectAccesses {
  __typename: "ProjectAccess";
  user: GrantUsersAccessToProjects_grantUsersAccessToProjects_projectAccesses_user | null;
  userRole: UserRole;
  project: GrantUsersAccessToProjects_grantUsersAccessToProjects_projectAccesses_project | null;
}

export interface GrantUsersAccessToProjects_grantUsersAccessToProjects {
  __typename: "ProjectAccessesResponse";
  projectAccesses: GrantUsersAccessToProjects_grantUsersAccessToProjects_projectAccesses[] | null;
  errors: string[] | null;
}

export interface GrantUsersAccessToProjects {
  grantUsersAccessToProjects: GrantUsersAccessToProjects_grantUsersAccessToProjects | null;
}

export interface GrantUsersAccessToProjectsVariables {
  input: GrantUsersAccessToProjectsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApproveEntities
// ====================================================

export interface ApproveEntities_approveEntities {
  __typename: "ApprovalResponse";
  /**
   * Whether approval succeeded or not
   */
  success: boolean;
  /**
   * Errors is defined when there is a problem parsing the query
   */
  errors: string[] | null;
}

export interface ApproveEntities {
  /**
   * Approve a group of specific entities without an edit
   */
  approveEntities: ApproveEntities_approveEntities;
}

export interface ApproveEntitiesVariables {
  input: ApproveEntitiesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApproveReview
// ====================================================

export interface ApproveReview_approveReview {
  __typename: "ApprovalResponse";
  /**
   * Whether approval succeeded or not
   */
  success: boolean;
  /**
   * Errors is defined when there is a problem parsing the query
   */
  errors: string[] | null;
}

export interface ApproveReview {
  /**
   * Approve an entire review
   */
  approveReview: ApproveReview_approveReview;
}

export interface ApproveReviewVariables {
  input: ApproveReviewInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClassifyEntities
// ====================================================

export interface ClassifyEntities_classifyEntities_data_classificationJob {
  __typename: "EntityBatchJob";
  id: string;
  /**
   * The kind of job
   */
  type: EntityJobType;
  /**
   * The job's current state
   */
  state: EntityJobState;
}

export interface ClassifyEntities_classifyEntities_data_group {
  __typename: "UnclassifiedResultGroup";
  /**
   * The ID of the group
   */
  id: string;
  /**
   * The name of the group that should be displayed in the group
   */
  name: string;
  /**
   * The count of the group within the current search query
   */
  totalCount: number | null;
  /**
   * Whether the group is currently locked because it's being classified
   */
  locked: boolean;
}

export interface ClassifyEntities_classifyEntities_data {
  __typename: "ClassifyEntitiesSuccessResponse";
  /**
   * The job that will process classification
   */
  classificationJob: ClassifyEntities_classifyEntities_data_classificationJob;
  /**
   * The group that was classified
   */
  group: ClassifyEntities_classifyEntities_data_group;
}

export interface ClassifyEntities_classifyEntities {
  __typename: "ClassifyEntitiesResponse";
  data: ClassifyEntities_classifyEntities_data | null;
  errors: string[] | null;
}

export interface ClassifyEntities {
  /**
   * Classify a group of entities
   */
  classifyEntities: ClassifyEntities_classifyEntities;
}

export interface ClassifyEntitiesVariables {
  input: ClassifyEntitiesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddControlledVocabularyCategory
// ====================================================

export interface AddControlledVocabularyCategory_addControlledVocabularyCategory_data {
  __typename: "ControlledVocabularyCategory";
  updatedAt: any | null;
  createdAt: any | null;
  description: string | null;
  id: string;
  name: string;
}

export interface AddControlledVocabularyCategory_addControlledVocabularyCategory {
  __typename: "ControlledVocabularyCategoryResponse";
  data: AddControlledVocabularyCategory_addControlledVocabularyCategory_data | null;
  errors: string[] | null;
}

export interface AddControlledVocabularyCategory {
  addControlledVocabularyCategory: AddControlledVocabularyCategory_addControlledVocabularyCategory | null;
}

export interface AddControlledVocabularyCategoryVariables {
  input: AddControlledVocabularyCategoryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUser
// ====================================================

export interface CreateUser_createUser_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface CreateUser_createUser {
  __typename: "UserResponse";
  user: CreateUser_createUser_user | null;
  errors: string[] | null;
}

export interface CreateUser {
  createUser: CreateUser_createUser | null;
}

export interface CreateUserVariables {
  input: CreateUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveGroup
// ====================================================

export interface RemoveGroup_removeGroup {
  __typename: "DeleteResponse";
  id: string | null;
  errors: string[] | null;
}

export interface RemoveGroup {
  removeGroup: RemoveGroup_removeGroup | null;
}

export interface RemoveGroupVariables {
  input: DeleteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveItemsFromControlledVocabularyCategory
// ====================================================

export interface RemoveItemsFromControlledVocabularyCategory_removeItemsFromControlledVocabularyCategory_data_items_edges_node {
  __typename: "ControlledVocabularyItem";
  id: string;
  value: string;
}

export interface RemoveItemsFromControlledVocabularyCategory_removeItemsFromControlledVocabularyCategory_data_items_edges {
  __typename: "ControlledVocabularyItemResultEdge";
  node: RemoveItemsFromControlledVocabularyCategory_removeItemsFromControlledVocabularyCategory_data_items_edges_node;
}

export interface RemoveItemsFromControlledVocabularyCategory_removeItemsFromControlledVocabularyCategory_data_items {
  __typename: "ControlledVocabularyItemsConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * The elements in the current page of the connection
   */
  edges: RemoveItemsFromControlledVocabularyCategory_removeItemsFromControlledVocabularyCategory_data_items_edges[];
}

export interface RemoveItemsFromControlledVocabularyCategory_removeItemsFromControlledVocabularyCategory_data {
  __typename: "ControlledVocabularyCategory";
  id: string;
  name: string;
  items: RemoveItemsFromControlledVocabularyCategory_removeItemsFromControlledVocabularyCategory_data_items | null;
}

export interface RemoveItemsFromControlledVocabularyCategory_removeItemsFromControlledVocabularyCategory {
  __typename: "ControlledVocabularyCategoryResponse";
  data: RemoveItemsFromControlledVocabularyCategory_removeItemsFromControlledVocabularyCategory_data | null;
  errors: string[] | null;
}

export interface RemoveItemsFromControlledVocabularyCategory {
  removeItemsFromControlledVocabularyCategory: RemoveItemsFromControlledVocabularyCategory_removeItemsFromControlledVocabularyCategory | null;
}

export interface RemoveItemsFromControlledVocabularyCategoryVariables {
  input: RemoveItemsFromControlledVocabularyCategoryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveControlledVocabularyCategories
// ====================================================

export interface RemoveControlledVocabularyCategories_removeControlledVocabularyCategories {
  __typename: "BulkDeleteResponse";
  count: number | null;
  errors: string[] | null;
}

export interface RemoveControlledVocabularyCategories {
  removeControlledVocabularyCategories: RemoveControlledVocabularyCategories_removeControlledVocabularyCategories | null;
}

export interface RemoveControlledVocabularyCategoriesVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveProject
// ====================================================

export interface RemoveProject_removeProject {
  __typename: "DeleteResponse";
  id: string | null;
  errors: string[] | null;
}

export interface RemoveProject {
  removeProject: RemoveProject_removeProject | null;
}

export interface RemoveProjectVariables {
  input: DeleteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSavedSearches
// ====================================================

export interface DeleteSavedSearches_deleteSavedSearches_data {
  __typename: "DeleteSavedSearchesData";
  deleteCount: number;
}

export interface DeleteSavedSearches_deleteSavedSearches {
  __typename: "DeleteSavedSearchesResponse";
  data: DeleteSavedSearches_deleteSavedSearches_data | null;
  errors: string[] | null;
}

export interface DeleteSavedSearches {
  deleteSavedSearches: DeleteSavedSearches_deleteSavedSearches;
}

export interface DeleteSavedSearchesVariables {
  input: DeleteSavedSearchesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUser
// ====================================================

export interface DeleteUser_deleteUser_user {
  __typename: "User";
  id: string;
  name: string | null;
  status: UserStatus;
}

export interface DeleteUser_deleteUser {
  __typename: "UserResponse";
  user: DeleteUser_deleteUser_user | null;
  errors: string[] | null;
}

export interface DeleteUser {
  deleteUser: DeleteUser_deleteUser | null;
}

export interface DeleteUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DuplicateSavedSearch
// ====================================================

export interface DuplicateSavedSearch_duplicateSavedSearch_data {
  __typename: "SavedSearch";
  id: string;
}

export interface DuplicateSavedSearch_duplicateSavedSearch {
  __typename: "SavedSearchResponse";
  data: DuplicateSavedSearch_duplicateSavedSearch_data | null;
  errors: string[] | null;
}

export interface DuplicateSavedSearch {
  duplicateSavedSearch: DuplicateSavedSearch_duplicateSavedSearch;
}

export interface DuplicateSavedSearchVariables {
  input: DuplicateSavedSearchInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditEntities
// ====================================================

export interface EditEntities_editEntities_data_createdBy {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface EditEntities_editEntities_data_linkSummaries_linkDefinition {
  __typename: "EntityLinkDefinition";
  id: string;
}

export interface EditEntities_editEntities_data_linkSummaries_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface EditEntities_editEntities_data_linkSummaries {
  __typename: "EntityLinkSummary";
  /**
   * A unique id assigned to the link or links being summarized
   */
  id: string;
  /**
   * The label that should appear for this summary (e.g. plated on)
   */
  label: string;
  /**
   * The value to display for this link (e.g. Plate-123)
   */
  value: string;
  /**
   * The direction of the link (from is a link from this entity to another, to is a link to this entity from another)
   */
  direction: Direction;
  /**
   * The number of links included in the summary
   */
  linkCount: number;
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The definition being summarized
   */
  linkDefinition: EditEntities_editEntities_data_linkSummaries_linkDefinition;
  /**
   * Any annotations to display in the summary
   */
  annotations: EditEntities_editEntities_data_linkSummaries_annotations[];
}

export interface EditEntities_editEntities_data_userMetadata {
  __typename: "MetadataValue";
  /**
   * The metdata field
   */
  label: string;
  /**
   * The metadata value
   */
  value: string;
}

export interface EditEntities_editEntities_data_metadata_status {
  __typename: "ProcessingStatus";
  state: ProcessingState;
}

export interface EditEntities_editEntities_data_metadata_contributors {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface EditEntities_editEntities_data_metadata {
  __typename: "EntityMetadata";
  /**
   * The id of the source (e.g. the id of the upload document or transaction)
   */
  sourceId: string | null;
  /**
   * The kind of source the entity came from
   */
  sourceType: SourceType | null;
  /**
   * An optional link to a preview of the entity
   */
  previewLink: string | null;
  /**
   * The status of the entity in the ingestion process
   */
  status: EditEntities_editEntities_data_metadata_status;
  /**
   * The users who have edited the entity
   */
  contributors: EditEntities_editEntities_data_metadata_contributors[];
}

export interface EditEntities_editEntities_data_fieldValues_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface EditEntities_editEntities_data_fieldValues_fieldDefinition_dataPoint {
  __typename: "DataPoint";
  /**
   * The type of data being stored
   */
  dataType: DataType;
}

export interface EditEntities_editEntities_data_fieldValues_fieldDefinition {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * The data point the field captures
   */
  dataPoint: EditEntities_editEntities_data_fieldValues_fieldDefinition_dataPoint;
}

export interface EditEntities_editEntities_data_fieldValues {
  __typename: "FieldValue";
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The value to display for this field (e.g. Plate-123)
   */
  value: string;
  /**
   * Any annotations to display in the summary
   */
  annotations: EditEntities_editEntities_data_fieldValues_annotations[];
  /**
   * The definition of the field
   */
  fieldDefinition: EditEntities_editEntities_data_fieldValues_fieldDefinition;
}

export interface EditEntities_editEntities_data_type {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
}

export interface EditEntities_editEntities_data_annotations {
  __typename: "QualityAnnotation";
  source: AnnotationSource;
  severity: AnnotationSeverity;
  message: string;
}

export interface EditEntities_editEntities_data {
  __typename: "Entity";
  id: string;
  createdBy: EditEntities_editEntities_data_createdBy;
  createdOn: string;
  displayName: string;
  displayStatus: AnnotationSeverity | null;
  lastModified: string;
  linkSummaries: EditEntities_editEntities_data_linkSummaries[];
  userMetadata: EditEntities_editEntities_data_userMetadata[];
  metadata: EditEntities_editEntities_data_metadata;
  fieldValues: EditEntities_editEntities_data_fieldValues[];
  type: EditEntities_editEntities_data_type;
  annotations: EditEntities_editEntities_data_annotations[];
}

export interface EditEntities_editEntities_validations_generalAnnotations {
  __typename: "QualityAnnotation";
  source: AnnotationSource;
  severity: AnnotationSeverity;
  message: string;
  dataPath: string[];
}

export interface EditEntities_editEntities_validations_fieldValidations_annotations {
  __typename: "QualityAnnotation";
  source: AnnotationSource;
  severity: AnnotationSeverity;
  message: string;
  dataPath: string[];
}

export interface EditEntities_editEntities_validations_fieldValidations {
  __typename: "EntityFieldValidation";
  /**
   * The id of the field impacted by the edit
   */
  fieldDefinitionId: string;
  displayStatus: AnnotationSeverity | null;
  name: string | null;
  /**
   * Any annotations to display in the summary
   */
  annotations: EditEntities_editEntities_validations_fieldValidations_annotations[];
}

export interface EditEntities_editEntities_validations {
  __typename: "EntityValidations";
  /**
   * The id of the entity the errors pertain to
   */
  id: string;
  displayStatus: AnnotationSeverity | null;
  /**
   * Any errors that pertain to the entity as a whole (e.g. the entity was deleted by another user, etc)
   */
  generalAnnotations: EditEntities_editEntities_validations_generalAnnotations[];
  /**
   * Any errors pertaining to a particular field on the entity
   */
  fieldValidations: EditEntities_editEntities_validations_fieldValidations[];
}

export interface EditEntities_editEntities {
  __typename: "EntityEditResult";
  /**
   * The updated entities; will be null if there were any errors
   */
  data: EditEntities_editEntities_data[] | null;
  /**
   * The errors for entities in the request
   */
  validations: EditEntities_editEntities_validations[] | null;
}

export interface EditEntities {
  /**
   * Edit a group of entities
   */
  editEntities: EditEntities_editEntities;
}

export interface EditEntitiesVariables {
  input: EditEntitiesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExportSearch
// ====================================================

export interface ExportSearch_exportSearch_data_outputDetails {
  __typename: "ExportJobOutputDetails";
  /**
   * The link to the job output if it exists
   */
  link: string | null;
  /**
   * Whether there were results in the export
   */
  hasExportedResults: boolean;
  /**
   * Whether the export includes access to the raw files that records were
   * harvested from
   */
  includesRawFiles: boolean;
}

export interface ExportSearch_exportSearch_data {
  __typename: "EntityExportJob";
  id: string;
  /**
   * The kind of job
   */
  type: EntityJobType;
  /**
   * The job's current state
   */
  state: EntityJobState;
  /**
   * A link to download the job output; if null the export has not finished
   */
  outputDetails: ExportSearch_exportSearch_data_outputDetails | null;
}

export interface ExportSearch_exportSearch {
  __typename: "ExportEntitiesResponse";
  data: ExportSearch_exportSearch_data | null;
  errors: string[] | null;
}

export interface ExportSearch {
  exportSearch: ExportSearch_exportSearch;
}

export interface ExportSearchVariables {
  input: ExportSearchInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GrantGroupsAccessToProjects
// ====================================================

export interface GrantGroupsAccessToProjects_grantGroupsAccessToProjects_groupProjectAccesses_group {
  __typename: "Group";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
}

export interface GrantGroupsAccessToProjects_grantGroupsAccessToProjects_groupProjectAccesses_project {
  __typename: "Project";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
}

export interface GrantGroupsAccessToProjects_grantGroupsAccessToProjects_groupProjectAccesses {
  __typename: "GroupProjectAccess";
  id: string;
  group: GrantGroupsAccessToProjects_grantGroupsAccessToProjects_groupProjectAccesses_group | null;
  project: GrantGroupsAccessToProjects_grantGroupsAccessToProjects_groupProjectAccesses_project | null;
}

export interface GrantGroupsAccessToProjects_grantGroupsAccessToProjects {
  __typename: "GroupProjectAccessesResponse";
  groupProjectAccesses: GrantGroupsAccessToProjects_grantGroupsAccessToProjects_groupProjectAccesses[] | null;
  errors: string[] | null;
}

export interface GrantGroupsAccessToProjects {
  grantGroupsAccessToProjects: GrantGroupsAccessToProjects_grantGroupsAccessToProjects | null;
}

export interface GrantGroupsAccessToProjectsVariables {
  input: GrantGroupsAccessToProjectsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GrantUserAccessToProject
// ====================================================

export interface GrantUserAccessToProject_grantUserAccessToProject_projectAccess_project {
  __typename: "Project";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
}

export interface GrantUserAccessToProject_grantUserAccessToProject_projectAccess_user {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface GrantUserAccessToProject_grantUserAccessToProject_projectAccess {
  __typename: "ProjectAccess";
  project: GrantUserAccessToProject_grantUserAccessToProject_projectAccess_project | null;
  user: GrantUserAccessToProject_grantUserAccessToProject_projectAccess_user | null;
  userRole: UserRole;
}

export interface GrantUserAccessToProject_grantUserAccessToProject {
  __typename: "ProjectAccessResponse";
  projectAccess: GrantUserAccessToProject_grantUserAccessToProject_projectAccess | null;
  errors: string[] | null;
}

export interface GrantUserAccessToProject {
  grantUserAccessToProject: GrantUserAccessToProject_grantUserAccessToProject | null;
}

export interface GrantUserAccessToProjectVariables {
  input: GrantUserAccessToProjectInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: NewLocalFileUpload
// ====================================================

export interface NewLocalFileUpload_newLocalFileUpload_uploadUrls {
  __typename: "UploadUrl";
  /**
   * The part number
   */
  partNumber: number;
  /**
   * The presigned storage upload url
   */
  url: string;
}

export interface NewLocalFileUpload_newLocalFileUpload {
  __typename: "RawObjectStorageData";
  /**
   * The presigned storage upload urls
   */
  uploadUrls: NewLocalFileUpload_newLocalFileUpload_uploadUrls[];
  /**
   * The external storage service asssigned upload id
   */
  uploadId: string;
  /**
   * The system assigned id for the uploaded file
   */
  fileId: string;
}

export interface NewLocalFileUpload {
  /**
   * Returns a signed url for the Upload file
   */
  newLocalFileUpload: NewLocalFileUpload_newLocalFileUpload;
}

export interface NewLocalFileUploadVariables {
  input: NewLocalFileInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RawObjectStorageComplete
// ====================================================

export interface RawObjectStorageComplete_rawObjectStorageComplete {
  __typename: "NewFileUploadStatus";
  /**
   * The status of the upload
   */
  uploadStatus: Status | null;
  /**
   * The system assigned id for the uploaded file
   */
  fileId: string | null;
}

export interface RawObjectStorageComplete {
  /**
   * Storage of local file is complete
   */
  rawObjectStorageComplete: RawObjectStorageComplete_rawObjectStorageComplete;
}

export interface RawObjectStorageCompleteVariables {
  input: StorageCompleteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveUserFromGroup
// ====================================================

export interface RemoveUserFromGroup_removeUserFromGroup {
  __typename: "DeleteResponse";
  id: string | null;
  errors: string[] | null;
}

export interface RemoveUserFromGroup {
  removeUserFromGroup: RemoveUserFromGroup_removeUserFromGroup | null;
}

export interface RemoveUserFromGroupVariables {
  input: DeleteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RevokeGroupProjectAccess
// ====================================================

export interface RevokeGroupProjectAccess_revokeGroupProjectAccess {
  __typename: "DeleteResponse";
  id: string | null;
  errors: string[] | null;
}

export interface RevokeGroupProjectAccess {
  revokeGroupProjectAccess: RevokeGroupProjectAccess_revokeGroupProjectAccess | null;
}

export interface RevokeGroupProjectAccessVariables {
  input: DeleteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RevokeUserProjectAccess
// ====================================================

export interface RevokeUserProjectAccess_revokeProjectAccess_data {
  __typename: "RevokeProjectAccessData";
  projectAccessIds: string[];
  groupMembershipIds: string[];
}

export interface RevokeUserProjectAccess_revokeProjectAccess {
  __typename: "RevokeProjectAccessResponse";
  data: RevokeUserProjectAccess_revokeProjectAccess_data | null;
  errors: (string | null)[] | null;
}

export interface RevokeUserProjectAccess {
  revokeProjectAccess: RevokeUserProjectAccess_revokeProjectAccess | null;
}

export interface RevokeUserProjectAccessVariables {
  input: RevokeProjectAccessInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEntityMetadata
// ====================================================

export interface UpdateEntityMetadata_editEntityMetadata_data_createdBy {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface UpdateEntityMetadata_editEntityMetadata_data_linkSummaries_linkDefinition {
  __typename: "EntityLinkDefinition";
  id: string;
}

export interface UpdateEntityMetadata_editEntityMetadata_data_linkSummaries_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface UpdateEntityMetadata_editEntityMetadata_data_linkSummaries {
  __typename: "EntityLinkSummary";
  /**
   * A unique id assigned to the link or links being summarized
   */
  id: string;
  /**
   * The label that should appear for this summary (e.g. plated on)
   */
  label: string;
  /**
   * The value to display for this link (e.g. Plate-123)
   */
  value: string;
  /**
   * The direction of the link (from is a link from this entity to another, to is a link to this entity from another)
   */
  direction: Direction;
  /**
   * The number of links included in the summary
   */
  linkCount: number;
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The definition being summarized
   */
  linkDefinition: UpdateEntityMetadata_editEntityMetadata_data_linkSummaries_linkDefinition;
  /**
   * Any annotations to display in the summary
   */
  annotations: UpdateEntityMetadata_editEntityMetadata_data_linkSummaries_annotations[];
}

export interface UpdateEntityMetadata_editEntityMetadata_data_userMetadata {
  __typename: "MetadataValue";
  /**
   * The metdata field
   */
  label: string;
  /**
   * The metadata value
   */
  value: string;
}

export interface UpdateEntityMetadata_editEntityMetadata_data_metadata_status {
  __typename: "ProcessingStatus";
  state: ProcessingState;
}

export interface UpdateEntityMetadata_editEntityMetadata_data_metadata_contributors {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface UpdateEntityMetadata_editEntityMetadata_data_metadata {
  __typename: "EntityMetadata";
  /**
   * The id of the source (e.g. the id of the upload document or transaction)
   */
  sourceId: string | null;
  /**
   * The kind of source the entity came from
   */
  sourceType: SourceType | null;
  /**
   * An optional link to a preview of the entity
   */
  previewLink: string | null;
  /**
   * The status of the entity in the ingestion process
   */
  status: UpdateEntityMetadata_editEntityMetadata_data_metadata_status;
  /**
   * The users who have edited the entity
   */
  contributors: UpdateEntityMetadata_editEntityMetadata_data_metadata_contributors[];
}

export interface UpdateEntityMetadata_editEntityMetadata_data_fieldValues_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface UpdateEntityMetadata_editEntityMetadata_data_fieldValues_fieldDefinition_dataPoint {
  __typename: "DataPoint";
  /**
   * The type of data being stored
   */
  dataType: DataType;
}

export interface UpdateEntityMetadata_editEntityMetadata_data_fieldValues_fieldDefinition {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * The data point the field captures
   */
  dataPoint: UpdateEntityMetadata_editEntityMetadata_data_fieldValues_fieldDefinition_dataPoint;
}

export interface UpdateEntityMetadata_editEntityMetadata_data_fieldValues {
  __typename: "FieldValue";
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The value to display for this field (e.g. Plate-123)
   */
  value: string;
  /**
   * Any annotations to display in the summary
   */
  annotations: UpdateEntityMetadata_editEntityMetadata_data_fieldValues_annotations[];
  /**
   * The definition of the field
   */
  fieldDefinition: UpdateEntityMetadata_editEntityMetadata_data_fieldValues_fieldDefinition;
}

export interface UpdateEntityMetadata_editEntityMetadata_data_type {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
}

export interface UpdateEntityMetadata_editEntityMetadata_data_annotations {
  __typename: "QualityAnnotation";
  source: AnnotationSource;
  severity: AnnotationSeverity;
  message: string;
}

export interface UpdateEntityMetadata_editEntityMetadata_data {
  __typename: "Entity";
  id: string;
  createdBy: UpdateEntityMetadata_editEntityMetadata_data_createdBy;
  createdOn: string;
  displayName: string;
  displayStatus: AnnotationSeverity | null;
  lastModified: string;
  linkSummaries: UpdateEntityMetadata_editEntityMetadata_data_linkSummaries[];
  userMetadata: UpdateEntityMetadata_editEntityMetadata_data_userMetadata[];
  metadata: UpdateEntityMetadata_editEntityMetadata_data_metadata;
  fieldValues: UpdateEntityMetadata_editEntityMetadata_data_fieldValues[];
  type: UpdateEntityMetadata_editEntityMetadata_data_type;
  annotations: UpdateEntityMetadata_editEntityMetadata_data_annotations[];
}

export interface UpdateEntityMetadata_editEntityMetadata_generalAnnotations {
  __typename: "QualityAnnotation";
  source: AnnotationSource;
  severity: AnnotationSeverity;
  message: string;
}

export interface UpdateEntityMetadata_editEntityMetadata {
  __typename: "EntityMetadataEditResult";
  /**
   * The updated entity; will be null if there were any errors
   */
  data: UpdateEntityMetadata_editEntityMetadata_data | null;
  /**
   * Any errors that pertain to the entity as a whole (e.g. the entity was deleted by another user, etc)
   */
  generalAnnotations: UpdateEntityMetadata_editEntityMetadata_generalAnnotations[];
}

export interface UpdateEntityMetadata {
  /**
   * Edit entity metadata
   */
  editEntityMetadata: UpdateEntityMetadata_editEntityMetadata;
}

export interface UpdateEntityMetadataVariables {
  input: EditEntityMetadataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateProjectAccess
// ====================================================

export interface UpdateProjectAccess_updateProjectAccess_projectAccess_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface UpdateProjectAccess_updateProjectAccess_projectAccess_project {
  __typename: "Project";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
}

export interface UpdateProjectAccess_updateProjectAccess_projectAccess {
  __typename: "ProjectAccess";
  id: string;
  user: UpdateProjectAccess_updateProjectAccess_projectAccess_user | null;
  userRole: UserRole;
  project: UpdateProjectAccess_updateProjectAccess_projectAccess_project | null;
}

export interface UpdateProjectAccess_updateProjectAccess {
  __typename: "ProjectAccessResponse";
  projectAccess: UpdateProjectAccess_updateProjectAccess_projectAccess | null;
  errors: string[] | null;
}

export interface UpdateProjectAccess {
  updateProjectAccess: UpdateProjectAccess_updateProjectAccess | null;
}

export interface UpdateProjectAccessVariables {
  input: UpdateProjectAccessInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSavedSearch
// ====================================================

export interface UpdateSavedSearch_updateSavedSearch_data {
  __typename: "SavedSearch";
  id: string;
  name: string;
  description: string | null;
  public: boolean;
}

export interface UpdateSavedSearch_updateSavedSearch {
  __typename: "SavedSearchResponse";
  data: UpdateSavedSearch_updateSavedSearch_data | null;
  errors: string[] | null;
}

export interface UpdateSavedSearch {
  updateSavedSearch: UpdateSavedSearch_updateSavedSearch;
}

export interface UpdateSavedSearchVariables {
  input: UpdateSavedSearchInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserRole
// ====================================================

export interface UpdateUserRole_updateUserRole_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
  defaultRole: UserRole;
}

export interface UpdateUserRole_updateUserRole {
  __typename: "UserResponse";
  user: UpdateUserRole_updateUserRole_user | null;
  errors: string[] | null;
}

export interface UpdateUserRole {
  updateUserRole: UpdateUserRole_updateUserRole | null;
}

export interface UpdateUserRoleVariables {
  input: UpdateUserRoleInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserPreferences
// ====================================================

export interface UpdateUserPreferences_updateUserPreferences_preferences {
  __typename: "UserPreferences";
  uploadWizardSkipAllQuestions: boolean | null;
  uploadWizardSkipAllQuestionsPrompt: boolean | null;
  rememberExportOptions: boolean | null;
  exportOptionsPreference: ExportOption | null;
}

export interface UpdateUserPreferences_updateUserPreferences {
  __typename: "User";
  id: string;
  preferences: UpdateUserPreferences_updateUserPreferences_preferences | null;
}

export interface UpdateUserPreferences {
  updateUserPreferences: UpdateUserPreferences_updateUserPreferences | null;
}

export interface UpdateUserPreferencesVariables {
  input: UpdateUserPreferenceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertUser
// ====================================================

export interface UpsertUser_upsertUser {
  __typename: "User";
  id: string;
}

export interface UpsertUser {
  upsertUser: UpsertUser_upsertUser;
}

export interface UpsertUserVariables {
  input: UpsertUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ActiveUploads
// ====================================================

export interface ActiveUploads_activeUploads {
  __typename: "ActiveUpload";
  id: string;
  fileName: string;
  fileType: string;
  status: FileUploadStatus;
}

export interface ActiveUploads {
  activeUploads: ActiveUploads_activeUploads[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllowedLinkTypes
// ====================================================

export interface AllowedLinkTypes_allowedLinkTypes_status {
  __typename: "DefinitionStatus";
  message: string;
  severity: Severity;
}

export interface AllowedLinkTypes_allowedLinkTypes_linkTypes_fields_inputOptions_Autogenerated {
  __typename: "Autogenerated" | "RemoteSelectInputOptions";
}

export interface AllowedLinkTypes_allowedLinkTypes_linkTypes_fields_inputOptions_TextInputOptions {
  __typename: "TextInputOptions";
  minLength: number | null;
  maxLength: number | null;
  type: InputType;
}

export interface AllowedLinkTypes_allowedLinkTypes_linkTypes_fields_inputOptions_LocalSelectInputOptions_options {
  __typename: "SelectOptions";
  value: string;
  text: string;
}

export interface AllowedLinkTypes_allowedLinkTypes_linkTypes_fields_inputOptions_LocalSelectInputOptions {
  __typename: "LocalSelectInputOptions";
  options: AllowedLinkTypes_allowedLinkTypes_linkTypes_fields_inputOptions_LocalSelectInputOptions_options[];
  type: InputType;
  minItems: number | null;
  maxItems: number | null;
}

export interface AllowedLinkTypes_allowedLinkTypes_linkTypes_fields_inputOptions_NumericInputOptions {
  __typename: "NumericInputOptions";
  type: InputType;
  minValue: number | null;
  maxValue: number | null;
}

export interface AllowedLinkTypes_allowedLinkTypes_linkTypes_fields_inputOptions_DateTimeInputOptions {
  __typename: "DateTimeInputOptions";
  minDate: string | null;
  maxDate: string | null;
  captureTime: boolean;
}

export type AllowedLinkTypes_allowedLinkTypes_linkTypes_fields_inputOptions = AllowedLinkTypes_allowedLinkTypes_linkTypes_fields_inputOptions_Autogenerated | AllowedLinkTypes_allowedLinkTypes_linkTypes_fields_inputOptions_TextInputOptions | AllowedLinkTypes_allowedLinkTypes_linkTypes_fields_inputOptions_LocalSelectInputOptions | AllowedLinkTypes_allowedLinkTypes_linkTypes_fields_inputOptions_NumericInputOptions | AllowedLinkTypes_allowedLinkTypes_linkTypes_fields_inputOptions_DateTimeInputOptions;

export interface AllowedLinkTypes_allowedLinkTypes_linkTypes_fields {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * Human readable name for the field (may be the same as that of the data point, or may be different)
   */
  name: string;
  /**
   * Human readable description of the field
   */
  description: string | null;
  /**
   * How the field should be collected from users
   */
  inputOptions: AllowedLinkTypes_allowedLinkTypes_linkTypes_fields_inputOptions;
}

export interface AllowedLinkTypes_allowedLinkTypes_linkTypes {
  __typename: "LinkType";
  id: string;
  /**
   * The relationship verb
   */
  name: string;
  /**
   * A human readable description of the meaning of the relationship verb
   */
  description: string | null;
  /**
   * This is the data the applies to the link itself
   */
  fields: AllowedLinkTypes_allowedLinkTypes_linkTypes_fields[];
}

export interface AllowedLinkTypes_allowedLinkTypes {
  __typename: "AllowedLinkTypesResponse";
  status: AllowedLinkTypes_allowedLinkTypes_status | null;
  linkTypes: AllowedLinkTypes_allowedLinkTypes_linkTypes[];
}

export interface AllowedLinkTypes {
  allowedLinkTypes: AllowedLinkTypes_allowedLinkTypes;
}

export interface AllowedLinkTypesVariables {
  filter: AllowedLinkTypesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllowedTargetEntityTypes
// ====================================================

export interface AllowedTargetEntityTypes_allowedTargetEntityTypes_status {
  __typename: "DefinitionStatus";
  message: string;
  severity: Severity;
}

export interface AllowedTargetEntityTypes_allowedTargetEntityTypes_entityTypes {
  __typename: "EntityType";
  id: string;
}

export interface AllowedTargetEntityTypes_allowedTargetEntityTypes {
  __typename: "AllowedTargetEntityTypesResponse";
  status: AllowedTargetEntityTypes_allowedTargetEntityTypes_status | null;
  entityTypes: AllowedTargetEntityTypes_allowedTargetEntityTypes_entityTypes[];
}

export interface AllowedTargetEntityTypes {
  allowedTargetEntityTypes: AllowedTargetEntityTypes_allowedTargetEntityTypes | null;
}

export interface AllowedTargetEntityTypesVariables {
  filter: AllowedTargetEntityTypesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DataPoints
// ====================================================

export interface DataPoints_dataPoints {
  __typename: "DataPoint";
  id: string;
  /**
   * The human readable name for the datapoint (unique across data points)
   */
  name: string;
  /**
   * The optional human readable description for the data point
   */
  description: string | null;
  /**
   * The type of data being stored
   */
  dataType: DataType;
  UIType: SearchFilterValueType;
}

export interface DataPoints {
  dataPoints: DataPoints_dataPoints[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EntitiesById
// ====================================================

export interface EntitiesById_entitiesById_createdBy {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface EntitiesById_entitiesById_linkSummaries_linkDefinition {
  __typename: "EntityLinkDefinition";
  id: string;
}

export interface EntitiesById_entitiesById_linkSummaries_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface EntitiesById_entitiesById_linkSummaries {
  __typename: "EntityLinkSummary";
  /**
   * A unique id assigned to the link or links being summarized
   */
  id: string;
  /**
   * The label that should appear for this summary (e.g. plated on)
   */
  label: string;
  /**
   * The value to display for this link (e.g. Plate-123)
   */
  value: string;
  /**
   * The direction of the link (from is a link from this entity to another, to is a link to this entity from another)
   */
  direction: Direction;
  /**
   * The number of links included in the summary
   */
  linkCount: number;
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The definition being summarized
   */
  linkDefinition: EntitiesById_entitiesById_linkSummaries_linkDefinition;
  /**
   * Any annotations to display in the summary
   */
  annotations: EntitiesById_entitiesById_linkSummaries_annotations[];
}

export interface EntitiesById_entitiesById_userMetadata {
  __typename: "MetadataValue";
  /**
   * The metdata field
   */
  label: string;
  /**
   * The metadata value
   */
  value: string;
}

export interface EntitiesById_entitiesById_metadata_status {
  __typename: "ProcessingStatus";
  state: ProcessingState;
}

export interface EntitiesById_entitiesById_metadata_contributors {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface EntitiesById_entitiesById_metadata {
  __typename: "EntityMetadata";
  /**
   * The id of the source (e.g. the id of the upload document or transaction)
   */
  sourceId: string | null;
  /**
   * The kind of source the entity came from
   */
  sourceType: SourceType | null;
  /**
   * An optional link to a preview of the entity
   */
  previewLink: string | null;
  /**
   * The status of the entity in the ingestion process
   */
  status: EntitiesById_entitiesById_metadata_status;
  /**
   * The users who have edited the entity
   */
  contributors: EntitiesById_entitiesById_metadata_contributors[];
}

export interface EntitiesById_entitiesById_fieldValues_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface EntitiesById_entitiesById_fieldValues_fieldDefinition_dataPoint {
  __typename: "DataPoint";
  /**
   * The type of data being stored
   */
  dataType: DataType;
}

export interface EntitiesById_entitiesById_fieldValues_fieldDefinition {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * The data point the field captures
   */
  dataPoint: EntitiesById_entitiesById_fieldValues_fieldDefinition_dataPoint;
}

export interface EntitiesById_entitiesById_fieldValues {
  __typename: "FieldValue";
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The value to display for this field (e.g. Plate-123)
   */
  value: string;
  /**
   * Any annotations to display in the summary
   */
  annotations: EntitiesById_entitiesById_fieldValues_annotations[];
  /**
   * The definition of the field
   */
  fieldDefinition: EntitiesById_entitiesById_fieldValues_fieldDefinition;
}

export interface EntitiesById_entitiesById_type {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
}

export interface EntitiesById_entitiesById_annotations {
  __typename: "QualityAnnotation";
  source: AnnotationSource;
  severity: AnnotationSeverity;
  message: string;
}

export interface EntitiesById_entitiesById {
  __typename: "Entity";
  id: string;
  createdBy: EntitiesById_entitiesById_createdBy;
  createdOn: string;
  displayName: string;
  displayStatus: AnnotationSeverity | null;
  lastModified: string;
  linkSummaries: EntitiesById_entitiesById_linkSummaries[];
  userMetadata: EntitiesById_entitiesById_userMetadata[];
  metadata: EntitiesById_entitiesById_metadata;
  fieldValues: EntitiesById_entitiesById_fieldValues[];
  type: EntitiesById_entitiesById_type;
  annotations: EntitiesById_entitiesById_annotations[];
}

export interface EntitiesById {
  entitiesById: EntitiesById_entitiesById[];
}

export interface EntitiesByIdVariables {
  ids: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EntitiesByTypeForSearch
// ====================================================

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  endIndex: number;
  startIndex: number;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_types {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_currentType {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_matches_display {
  __typename: "MatchDisplay";
  match: string;
  type: MatchDisplayType;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_matches {
  __typename: "Match";
  /**
   * The field matched
   */
  fieldName: string;
  /**
   * How to display the match
   */
  display: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_matches_display;
  /**
   * Where the match ends in the field
   */
  highlightEnd: number;
  /**
   * Where the match starts in the field
   */
  highlightStart: number;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_createdBy {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_linkSummaries_linkDefinition {
  __typename: "EntityLinkDefinition";
  id: string;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_linkSummaries_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_linkSummaries {
  __typename: "EntityLinkSummary";
  /**
   * A unique id assigned to the link or links being summarized
   */
  id: string;
  /**
   * The label that should appear for this summary (e.g. plated on)
   */
  label: string;
  /**
   * The value to display for this link (e.g. Plate-123)
   */
  value: string;
  /**
   * The direction of the link (from is a link from this entity to another, to is a link to this entity from another)
   */
  direction: Direction;
  /**
   * The number of links included in the summary
   */
  linkCount: number;
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The definition being summarized
   */
  linkDefinition: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_linkSummaries_linkDefinition;
  /**
   * Any annotations to display in the summary
   */
  annotations: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_linkSummaries_annotations[];
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_userMetadata {
  __typename: "MetadataValue";
  /**
   * The metdata field
   */
  label: string;
  /**
   * The metadata value
   */
  value: string;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_metadata_status {
  __typename: "ProcessingStatus";
  state: ProcessingState;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_metadata_contributors {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_metadata {
  __typename: "EntityMetadata";
  /**
   * The id of the source (e.g. the id of the upload document or transaction)
   */
  sourceId: string | null;
  /**
   * The kind of source the entity came from
   */
  sourceType: SourceType | null;
  /**
   * An optional link to a preview of the entity
   */
  previewLink: string | null;
  /**
   * The status of the entity in the ingestion process
   */
  status: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_metadata_status;
  /**
   * The users who have edited the entity
   */
  contributors: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_metadata_contributors[];
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_fieldValues_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_fieldValues_fieldDefinition_dataPoint {
  __typename: "DataPoint";
  /**
   * The type of data being stored
   */
  dataType: DataType;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_fieldValues_fieldDefinition {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * The data point the field captures
   */
  dataPoint: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_fieldValues_fieldDefinition_dataPoint;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_fieldValues {
  __typename: "FieldValue";
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The value to display for this field (e.g. Plate-123)
   */
  value: string;
  /**
   * Any annotations to display in the summary
   */
  annotations: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_fieldValues_annotations[];
  /**
   * The definition of the field
   */
  fieldDefinition: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_fieldValues_fieldDefinition;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_type {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_annotations {
  __typename: "QualityAnnotation";
  source: AnnotationSource;
  severity: AnnotationSeverity;
  message: string;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity {
  __typename: "Entity";
  id: string;
  createdBy: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_createdBy;
  createdOn: string;
  displayName: string;
  displayStatus: AnnotationSeverity | null;
  lastModified: string;
  linkSummaries: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_linkSummaries[];
  userMetadata: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_userMetadata[];
  metadata: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_metadata;
  fieldValues: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_fieldValues[];
  type: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_type;
  annotations: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity_annotations[];
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node {
  __typename: "EntitySearchResult";
  /**
   * The matches in the entity
   */
  matches: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_matches[];
  /**
   * The entity result
   */
  entity: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges {
  __typename: "EntityResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node;
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch_data {
  __typename: "EntityResultsByTypeConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * Details about the current page
   */
  pageInfo: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_pageInfo;
  /**
   * The types of results the search will return
   */
  types: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_types[];
  /**
   * The current type the collection contains
   */
  currentType: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_currentType | null;
  /**
   * The elements in the current page of the connection
   */
  edges: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges[];
}

export interface EntitiesByTypeForSearch_entitiesByTypeForSearch {
  __typename: "EntitiesByTypeSearchResponse";
  /**
   * Errors is defined when there is a problem parsing the query
   */
  errors: string[] | null;
  /**
   * Data is defined when there there is no issue with the query
   */
  data: EntitiesByTypeForSearch_entitiesByTypeForSearch_data | null;
}

export interface EntitiesByTypeForSearch {
  entitiesByTypeForSearch: EntitiesByTypeForSearch_entitiesByTypeForSearch;
}

export interface EntitiesByTypeForSearchVariables {
  first?: number | null;
  last?: number | null;
  searchQuery?: SearchQuery | null;
  before?: string | null;
  after?: string | null;
  type?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EntitiesByType
// ====================================================

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  endIndex: number;
  startIndex: number;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_types {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_currentType {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_matches_display {
  __typename: "MatchDisplay";
  match: string;
  type: MatchDisplayType;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_matches {
  __typename: "Match";
  /**
   * The field matched
   */
  fieldName: string;
  /**
   * How to display the match
   */
  display: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_matches_display;
  /**
   * Where the match ends in the field
   */
  highlightEnd: number;
  /**
   * Where the match starts in the field
   */
  highlightStart: number;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_createdBy {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_linkSummaries_linkDefinition {
  __typename: "EntityLinkDefinition";
  id: string;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_linkSummaries_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_linkSummaries {
  __typename: "EntityLinkSummary";
  /**
   * A unique id assigned to the link or links being summarized
   */
  id: string;
  /**
   * The label that should appear for this summary (e.g. plated on)
   */
  label: string;
  /**
   * The value to display for this link (e.g. Plate-123)
   */
  value: string;
  /**
   * The direction of the link (from is a link from this entity to another, to is a link to this entity from another)
   */
  direction: Direction;
  /**
   * The number of links included in the summary
   */
  linkCount: number;
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The definition being summarized
   */
  linkDefinition: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_linkSummaries_linkDefinition;
  /**
   * Any annotations to display in the summary
   */
  annotations: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_linkSummaries_annotations[];
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_userMetadata {
  __typename: "MetadataValue";
  /**
   * The metdata field
   */
  label: string;
  /**
   * The metadata value
   */
  value: string;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_metadata_status {
  __typename: "ProcessingStatus";
  state: ProcessingState;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_metadata_contributors {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_metadata {
  __typename: "EntityMetadata";
  /**
   * The id of the source (e.g. the id of the upload document or transaction)
   */
  sourceId: string | null;
  /**
   * The kind of source the entity came from
   */
  sourceType: SourceType | null;
  /**
   * An optional link to a preview of the entity
   */
  previewLink: string | null;
  /**
   * The status of the entity in the ingestion process
   */
  status: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_metadata_status;
  /**
   * The users who have edited the entity
   */
  contributors: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_metadata_contributors[];
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_fieldValues_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_fieldValues_fieldDefinition_dataPoint {
  __typename: "DataPoint";
  /**
   * The type of data being stored
   */
  dataType: DataType;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_fieldValues_fieldDefinition {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * The data point the field captures
   */
  dataPoint: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_fieldValues_fieldDefinition_dataPoint;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_fieldValues {
  __typename: "FieldValue";
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The value to display for this field (e.g. Plate-123)
   */
  value: string;
  /**
   * Any annotations to display in the summary
   */
  annotations: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_fieldValues_annotations[];
  /**
   * The definition of the field
   */
  fieldDefinition: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_fieldValues_fieldDefinition;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_type {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_annotations {
  __typename: "QualityAnnotation";
  source: AnnotationSource;
  severity: AnnotationSeverity;
  message: string;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity {
  __typename: "Entity";
  id: string;
  createdBy: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_createdBy;
  createdOn: string;
  displayName: string;
  displayStatus: AnnotationSeverity | null;
  lastModified: string;
  linkSummaries: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_linkSummaries[];
  userMetadata: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_userMetadata[];
  metadata: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_metadata;
  fieldValues: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_fieldValues[];
  type: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_type;
  annotations: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity_annotations[];
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node {
  __typename: "EntitySearchResult";
  /**
   * The matches in the entity
   */
  matches: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_matches[];
  /**
   * The entity result
   */
  entity: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node_entity;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges {
  __typename: "EntityResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges_node;
}

export interface EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection {
  __typename: "EntityResultsByTypeConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * Details about the current page
   */
  pageInfo: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_pageInfo;
  /**
   * The types of results the search will return
   */
  types: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_types[];
  /**
   * The current type the collection contains
   */
  currentType: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_currentType | null;
  /**
   * The elements in the current page of the connection
   */
  edges: EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection_edges[];
}

export interface EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  endIndex: number;
  startIndex: number;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
}

export interface EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_types {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
}

export interface EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_currentType {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
}

export interface EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_edges_node_matchingTypes_entityType {
  __typename: "EntityType";
  id: string;
}

export interface EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_edges_node_matchingTypes_invalidColumns_suggestedColumns {
  __typename: "UnclassifiedColumnMatch";
  /**
   * The human readable name of the entity type field
   */
  name: string;
  /**
   * The field definition id of the field
   */
  fieldDefinitionId: string;
  /**
   * The confidence of the match
   */
  confidence: number;
}

export interface EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_edges_node_matchingTypes_invalidColumns {
  __typename: "UnclassifiedField";
  /**
   * The header the field has in the unclassified entities
   */
  header: string;
  /**
   * Any hint message for the UI about the field
   */
  hint: string;
  /**
   * The columns that the field may match in the entity type.
   * 
   * If a column does not match against any fields within the type then this list
   * will be empty.
   */
  suggestedColumns: EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_edges_node_matchingTypes_invalidColumns_suggestedColumns[];
}

export interface EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_edges_node_matchingTypes_missingColumns {
  __typename: "MissingField";
  /**
   * The name of the field from the entity type
   */
  name: string;
  /**
   * The id of the field from the entity type
   */
  fieldDefinitionId: string;
}

export interface EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_edges_node_matchingTypes {
  __typename: "UnclassifiedTypeMatch";
  /**
   * The name of the type matched
   */
  name: string;
  /**
   * The confidence in the match between the group and the type
   */
  confidence: number;
  /**
   * The entity type matched
   */
  entityType: EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_edges_node_matchingTypes_entityType;
  /**
   * The columns in the group that could not be matched to a field on the entity type
   */
  invalidColumns: EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_edges_node_matchingTypes_invalidColumns[];
  /**
   * The fields from the entity type that are not directly matched by columns from the group.
   * 
   * All suggested fields from invalid columns that do not have a definite match will
   * be represented in this list since they are missing from the type until a
   * definite determination for each invalid column has been made.
   */
  missingColumns: EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_edges_node_matchingTypes_missingColumns[];
}

export interface EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_edges_node {
  __typename: "UnclassifiedResultGroup";
  /**
   * The ID of the group
   */
  id: string;
  /**
   * The name of the group that should be displayed in the group
   */
  name: string;
  /**
   * The count of the group within the current search query
   */
  totalCount: number | null;
  /**
   * How likely the match was if there is a single possible match above 50%
   */
  confidence: number | null;
  /**
   * The headers that are in the group
   */
  headers: string[];
  /**
   * The types that the group matches
   */
  matchingTypes: EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_edges_node_matchingTypes[];
  /**
   * Whether the group is currently locked because it's being classified
   */
  locked: boolean;
}

export interface EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_edges {
  __typename: "UnclassifiedResultGroupEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  /**
   * The unclassified result group at the end of the edge
   */
  node: EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_edges_node;
}

export interface EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection {
  __typename: "UnclassifiedResultGroupsConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * Details about the current page
   */
  pageInfo: EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_pageInfo;
  /**
   * The types of results the search will return
   */
  types: EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_types[];
  /**
   * The current type the collection contains
   */
  currentType: EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_currentType | null;
  /**
   * The elements in the current page of the connection
   */
  edges: EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection_edges[];
}

export type EntitiesByType_entitiesByType_data = EntitiesByType_entitiesByType_data_EntityResultsByTypeConnection | EntitiesByType_entitiesByType_data_UnclassifiedResultGroupsConnection;

export interface EntitiesByType_entitiesByType {
  __typename: "EntitiesByTypeResponse";
  /**
   * Errors is defined when there is a problem parsing the query
   */
  errors: string[] | null;
  /**
   * Data is defined when there there is no issue with the query
   */
  data: EntitiesByType_entitiesByType_data | null;
}

export interface EntitiesByType {
  entitiesByType: EntitiesByType_entitiesByType;
}

export interface EntitiesByTypeVariables {
  first?: number | null;
  last?: number | null;
  searchQuery?: SearchQuery | null;
  before?: string | null;
  after?: string | null;
  type?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EntityById
// ====================================================

export interface EntityById_entityById_data_createdBy {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface EntityById_entityById_data_linkSummaries_linkDefinition {
  __typename: "EntityLinkDefinition";
  id: string;
}

export interface EntityById_entityById_data_linkSummaries_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface EntityById_entityById_data_linkSummaries {
  __typename: "EntityLinkSummary";
  /**
   * A unique id assigned to the link or links being summarized
   */
  id: string;
  /**
   * The label that should appear for this summary (e.g. plated on)
   */
  label: string;
  /**
   * The value to display for this link (e.g. Plate-123)
   */
  value: string;
  /**
   * The direction of the link (from is a link from this entity to another, to is a link to this entity from another)
   */
  direction: Direction;
  /**
   * The number of links included in the summary
   */
  linkCount: number;
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The definition being summarized
   */
  linkDefinition: EntityById_entityById_data_linkSummaries_linkDefinition;
  /**
   * Any annotations to display in the summary
   */
  annotations: EntityById_entityById_data_linkSummaries_annotations[];
}

export interface EntityById_entityById_data_userMetadata {
  __typename: "MetadataValue";
  /**
   * The metdata field
   */
  label: string;
  /**
   * The metadata value
   */
  value: string;
}

export interface EntityById_entityById_data_metadata_status {
  __typename: "ProcessingStatus";
  state: ProcessingState;
}

export interface EntityById_entityById_data_metadata_contributors {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface EntityById_entityById_data_metadata {
  __typename: "EntityMetadata";
  /**
   * The id of the source (e.g. the id of the upload document or transaction)
   */
  sourceId: string | null;
  /**
   * The kind of source the entity came from
   */
  sourceType: SourceType | null;
  /**
   * An optional link to a preview of the entity
   */
  previewLink: string | null;
  /**
   * The status of the entity in the ingestion process
   */
  status: EntityById_entityById_data_metadata_status;
  /**
   * The users who have edited the entity
   */
  contributors: EntityById_entityById_data_metadata_contributors[];
}

export interface EntityById_entityById_data_fieldValues_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface EntityById_entityById_data_fieldValues_fieldDefinition_dataPoint {
  __typename: "DataPoint";
  /**
   * The type of data being stored
   */
  dataType: DataType;
}

export interface EntityById_entityById_data_fieldValues_fieldDefinition {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * The data point the field captures
   */
  dataPoint: EntityById_entityById_data_fieldValues_fieldDefinition_dataPoint;
}

export interface EntityById_entityById_data_fieldValues {
  __typename: "FieldValue";
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The value to display for this field (e.g. Plate-123)
   */
  value: string;
  /**
   * Any annotations to display in the summary
   */
  annotations: EntityById_entityById_data_fieldValues_annotations[];
  /**
   * The definition of the field
   */
  fieldDefinition: EntityById_entityById_data_fieldValues_fieldDefinition;
}

export interface EntityById_entityById_data_type_fields_dataPoint {
  __typename: "DataPoint";
  /**
   * The type of data being stored
   */
  dataType: DataType;
}

export interface EntityById_entityById_data_type_fields {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * Human readable name for the field (may be the same as that of the data point, or may be different)
   */
  name: string;
  /**
   * The data point the field captures
   */
  dataPoint: EntityById_entityById_data_type_fields_dataPoint;
}

export interface EntityById_entityById_data_type {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
  /**
   * The fields on the type
   */
  fields: EntityById_entityById_data_type_fields[];
}

export interface EntityById_entityById_data_annotations {
  __typename: "QualityAnnotation";
  source: AnnotationSource;
  severity: AnnotationSeverity;
  message: string;
}

export interface EntityById_entityById_data {
  __typename: "Entity";
  id: string;
  createdBy: EntityById_entityById_data_createdBy;
  createdOn: string;
  displayName: string;
  displayStatus: AnnotationSeverity | null;
  lastModified: string;
  linkSummaries: EntityById_entityById_data_linkSummaries[];
  userMetadata: EntityById_entityById_data_userMetadata[];
  metadata: EntityById_entityById_data_metadata;
  fieldValues: EntityById_entityById_data_fieldValues[];
  type: EntityById_entityById_data_type;
  annotations: EntityById_entityById_data_annotations[];
}

export interface EntityById_entityById {
  __typename: "EntityResponse";
  /**
   * Data is defined when there there is no issue retrieving the entity
   */
  data: EntityById_entityById_data | null;
  /**
   * Errors is defined when there is a problem retrieving the entity by id
   */
  errors: string[] | null;
}

export interface EntityById {
  entityById: EntityById_entityById;
}

export interface EntityByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EntityHistory
// ====================================================

export interface EntityHistory_entityHistory_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
}

export interface EntityHistory_entityHistory_edges_node_changes_values_EntityHistorySimpleAddition {
  __typename: "EntityHistorySimpleAddition";
  /**
   * The new value
   */
  simpleAdditionNew: string | null;
}

export interface EntityHistory_entityHistory_edges_node_changes_values_EntityHistorySimpleChange {
  __typename: "EntityHistorySimpleChange";
  /**
   * The new value
   */
  simpleChangeNew: string | null;
  /**
   * The old value
   */
  simpleChangeOld: string | null;
}

export interface EntityHistory_entityHistory_edges_node_changes_values_EntityHistoryEntityAddition_entityAdditionNew {
  __typename: "Entity";
  id: string;
  displayName: string;
}

export interface EntityHistory_entityHistory_edges_node_changes_values_EntityHistoryEntityAddition {
  __typename: "EntityHistoryEntityAddition";
  /**
   * The new value
   */
  entityAdditionNew: EntityHistory_entityHistory_edges_node_changes_values_EntityHistoryEntityAddition_entityAdditionNew | null;
}

export interface EntityHistory_entityHistory_edges_node_changes_values_EntityHistoryEntityChange_entityChangeNew {
  __typename: "Entity";
  id: string;
  displayName: string;
}

export interface EntityHistory_entityHistory_edges_node_changes_values_EntityHistoryEntityChange_entityChangeOld {
  __typename: "Entity";
  id: string;
  displayName: string;
}

export interface EntityHistory_entityHistory_edges_node_changes_values_EntityHistoryEntityChange {
  __typename: "EntityHistoryEntityChange";
  /**
   * The new value
   */
  entityChangeNew: EntityHistory_entityHistory_edges_node_changes_values_EntityHistoryEntityChange_entityChangeNew | null;
  /**
   * The old value
   */
  entityChangeOld: EntityHistory_entityHistory_edges_node_changes_values_EntityHistoryEntityChange_entityChangeOld | null;
}

export type EntityHistory_entityHistory_edges_node_changes_values = EntityHistory_entityHistory_edges_node_changes_values_EntityHistorySimpleAddition | EntityHistory_entityHistory_edges_node_changes_values_EntityHistorySimpleChange | EntityHistory_entityHistory_edges_node_changes_values_EntityHistoryEntityAddition | EntityHistory_entityHistory_edges_node_changes_values_EntityHistoryEntityChange;

export interface EntityHistory_entityHistory_edges_node_changes {
  __typename: "EntityHistoryChanges";
  /**
   * The label for the change
   */
  key: string | null;
  /**
   * The change values
   */
  values: EntityHistory_entityHistory_edges_node_changes_values | null;
}

export interface EntityHistory_entityHistory_edges_node_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface EntityHistory_entityHistory_edges_node {
  __typename: "EntityHistory";
  /**
   * The entity changes
   */
  changes: (EntityHistory_entityHistory_edges_node_changes | null)[];
  /**
   * The event origin
   */
  eventOrigin: EntityHistoryEventOrigin | null;
  /**
   * The event type
   */
  eventType: EntityHistoryEventType;
  /**
   * The unique identifier for the entity history
   */
  id: string;
  /**
   * When this version of the entity was created
   */
  updatedAt: string;
  /**
   * The user who updated the entity
   */
  user: EntityHistory_entityHistory_edges_node_user;
}

export interface EntityHistory_entityHistory_edges {
  __typename: "EntityHistoryEdge";
  cursor: string | null;
  node: EntityHistory_entityHistory_edges_node | null;
}

export interface EntityHistory_entityHistory {
  __typename: "EntityHistoryConnection";
  pageInfo: EntityHistory_entityHistory_pageInfo;
  /**
   * the total history event count
   */
  totalCount: number | null;
  edges: (EntityHistory_entityHistory_edges | null)[] | null;
}

export interface EntityHistory {
  /**
   * A single entity history by id
   */
  entityHistory: EntityHistory_entityHistory | null;
}

export interface EntityHistoryVariables {
  filter?: EntityHistoryFilter | null;
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  id: string;
  order?: SortDirection | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EntityLinkSummaries
// ====================================================

export interface EntityLinkSummaries_entityById_data_linkSummaries_annotations {
  __typename: "QualityAnnotation";
  source: AnnotationSource;
  severity: AnnotationSeverity;
  message: string;
  dataPath: string[];
}

export interface EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_dataPoint {
  __typename: "DataPoint";
  id: string;
  /**
   * The human readable name for the datapoint (unique across data points)
   */
  name: string;
  /**
   * The optional human readable description for the data point
   */
  description: string | null;
  /**
   * The type of data being stored
   */
  dataType: DataType;
}

export interface EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_inputOptions_RemoteSelectInputOptions {
  __typename: "RemoteSelectInputOptions";
}

export interface EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_inputOptions_Autogenerated {
  __typename: "Autogenerated";
  displayOnEntry: boolean;
}

export interface EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_inputOptions_DateTimeInputOptions {
  __typename: "DateTimeInputOptions";
  captureTime: boolean;
}

export interface EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_inputOptions_LocalSelectInputOptions_options {
  __typename: "SelectOptions";
  text: string;
  value: string;
}

export interface EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_inputOptions_LocalSelectInputOptions {
  __typename: "LocalSelectInputOptions";
  type: InputType;
  options: EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_inputOptions_LocalSelectInputOptions_options[];
}

export interface EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_inputOptions_NumericInputOptions {
  __typename: "NumericInputOptions";
  type: InputType;
  precision: number;
}

export interface EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_inputOptions_TextInputOptions {
  __typename: "TextInputOptions";
  type: InputType;
}

export type EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_inputOptions = EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_inputOptions_RemoteSelectInputOptions | EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_inputOptions_Autogenerated | EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_inputOptions_DateTimeInputOptions | EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_inputOptions_LocalSelectInputOptions | EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_inputOptions_NumericInputOptions | EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_inputOptions_TextInputOptions;

export interface EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * Human readable name for the field (may be the same as that of the data point, or may be different)
   */
  name: string;
  /**
   * Human readable description of the field
   */
  description: string | null;
  /**
   * The data point the field captures
   */
  dataPoint: EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_dataPoint;
  /**
   * How the field should be collected from users
   */
  inputOptions: EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields_inputOptions;
}

export interface EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type {
  __typename: "LinkType";
  id: string;
  /**
   * The relationship verb
   */
  name: string;
  /**
   * A human readable description of the meaning of the relationship verb
   */
  description: string | null;
  /**
   * This is the data the applies to the link itself
   */
  fields: EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type_fields[];
}

export interface EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition {
  __typename: "EntityLinkDefinition";
  id: string;
  /**
   * Human readable name for the link relationship (e.g. sample, parent, source file)
   */
  name: string | null;
  /**
   * Human readable description of the relationship at the entity level
   */
  description: string | null;
  /**
   * The type of link (i.e. the verb in the link triple)
   */
  type: EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition_type;
}

export interface EntityLinkSummaries_entityById_data_linkSummaries {
  __typename: "EntityLinkSummary";
  /**
   * A unique id assigned to the link or links being summarized
   */
  id: string;
  /**
   * The label that should appear for this summary (e.g. plated on)
   */
  label: string;
  /**
   * The value to display for this link (e.g. Plate-123)
   */
  value: string;
  /**
   * The direction of the link (from is a link from this entity to another, to is a link to this entity from another)
   */
  direction: Direction;
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The number of links included in the summary
   */
  linkCount: number;
  /**
   * Any annotations to display in the summary
   */
  annotations: EntityLinkSummaries_entityById_data_linkSummaries_annotations[];
  /**
   * The definition being summarized
   */
  linkDefinition: EntityLinkSummaries_entityById_data_linkSummaries_linkDefinition;
}

export interface EntityLinkSummaries_entityById_data {
  __typename: "Entity";
  id: string;
  linkSummaries: EntityLinkSummaries_entityById_data_linkSummaries[];
}

export interface EntityLinkSummaries_entityById {
  __typename: "EntityResponse";
  /**
   * Data is defined when there there is no issue retrieving the entity
   */
  data: EntityLinkSummaries_entityById_data | null;
  /**
   * Errors is defined when there is a problem retrieving the entity by id
   */
  errors: string[] | null;
}

export interface EntityLinkSummaries {
  entityById: EntityLinkSummaries_entityById;
}

export interface EntityLinkSummariesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Entity
// ====================================================

export interface Entity_entity_createdBy {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface Entity_entity_linkSummaries_linkDefinition {
  __typename: "EntityLinkDefinition";
  id: string;
}

export interface Entity_entity_linkSummaries_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface Entity_entity_linkSummaries {
  __typename: "EntityLinkSummary";
  /**
   * A unique id assigned to the link or links being summarized
   */
  id: string;
  /**
   * The label that should appear for this summary (e.g. plated on)
   */
  label: string;
  /**
   * The value to display for this link (e.g. Plate-123)
   */
  value: string;
  /**
   * The direction of the link (from is a link from this entity to another, to is a link to this entity from another)
   */
  direction: Direction;
  /**
   * The number of links included in the summary
   */
  linkCount: number;
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The definition being summarized
   */
  linkDefinition: Entity_entity_linkSummaries_linkDefinition;
  /**
   * Any annotations to display in the summary
   */
  annotations: Entity_entity_linkSummaries_annotations[];
}

export interface Entity_entity_userMetadata {
  __typename: "MetadataValue";
  /**
   * The metdata field
   */
  label: string;
  /**
   * The metadata value
   */
  value: string;
}

export interface Entity_entity_metadata_status {
  __typename: "ProcessingStatus";
  state: ProcessingState;
}

export interface Entity_entity_metadata_contributors {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface Entity_entity_metadata {
  __typename: "EntityMetadata";
  /**
   * The id of the source (e.g. the id of the upload document or transaction)
   */
  sourceId: string | null;
  /**
   * The kind of source the entity came from
   */
  sourceType: SourceType | null;
  /**
   * An optional link to a preview of the entity
   */
  previewLink: string | null;
  /**
   * The status of the entity in the ingestion process
   */
  status: Entity_entity_metadata_status;
  /**
   * The users who have edited the entity
   */
  contributors: Entity_entity_metadata_contributors[];
}

export interface Entity_entity_fieldValues_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface Entity_entity_fieldValues_fieldDefinition_dataPoint {
  __typename: "DataPoint";
  /**
   * The type of data being stored
   */
  dataType: DataType;
}

export interface Entity_entity_fieldValues_fieldDefinition {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * The data point the field captures
   */
  dataPoint: Entity_entity_fieldValues_fieldDefinition_dataPoint;
}

export interface Entity_entity_fieldValues {
  __typename: "FieldValue";
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The value to display for this field (e.g. Plate-123)
   */
  value: string;
  /**
   * Any annotations to display in the summary
   */
  annotations: Entity_entity_fieldValues_annotations[];
  /**
   * The definition of the field
   */
  fieldDefinition: Entity_entity_fieldValues_fieldDefinition;
}

export interface Entity_entity_type {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
}

export interface Entity_entity_annotations {
  __typename: "QualityAnnotation";
  source: AnnotationSource;
  severity: AnnotationSeverity;
  message: string;
}

export interface Entity_entity {
  __typename: "Entity";
  id: string;
  createdBy: Entity_entity_createdBy;
  createdOn: string;
  displayName: string;
  displayStatus: AnnotationSeverity | null;
  lastModified: string;
  linkSummaries: Entity_entity_linkSummaries[];
  userMetadata: Entity_entity_userMetadata[];
  metadata: Entity_entity_metadata;
  fieldValues: Entity_entity_fieldValues[];
  type: Entity_entity_type;
  annotations: Entity_entity_annotations[];
}

export interface Entity {
  entity: Entity_entity;
}

export interface EntityVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EntityTypes
// ====================================================

export interface EntityTypes_entityTypes_fields_dataPoint {
  __typename: "DataPoint";
  /**
   * The type of data being stored
   */
  dataType: DataType;
}

export interface EntityTypes_entityTypes_fields_inputOptions_RemoteSelectInputOptions {
  __typename: "RemoteSelectInputOptions";
}

export interface EntityTypes_entityTypes_fields_inputOptions_TextInputOptions {
  __typename: "TextInputOptions";
  type: InputType;
}

export interface EntityTypes_entityTypes_fields_inputOptions_Autogenerated {
  __typename: "Autogenerated";
  displayOnEntry: boolean;
}

export interface EntityTypes_entityTypes_fields_inputOptions_DateTimeInputOptions {
  __typename: "DateTimeInputOptions";
  captureTime: boolean;
}

export interface EntityTypes_entityTypes_fields_inputOptions_LocalSelectInputOptions_options {
  __typename: "SelectOptions";
  text: string;
  value: string;
}

export interface EntityTypes_entityTypes_fields_inputOptions_LocalSelectInputOptions {
  __typename: "LocalSelectInputOptions";
  type: InputType;
  options: EntityTypes_entityTypes_fields_inputOptions_LocalSelectInputOptions_options[];
}

export interface EntityTypes_entityTypes_fields_inputOptions_NumericInputOptions {
  __typename: "NumericInputOptions";
  type: InputType;
  precision: number;
}

export type EntityTypes_entityTypes_fields_inputOptions = EntityTypes_entityTypes_fields_inputOptions_RemoteSelectInputOptions | EntityTypes_entityTypes_fields_inputOptions_TextInputOptions | EntityTypes_entityTypes_fields_inputOptions_Autogenerated | EntityTypes_entityTypes_fields_inputOptions_DateTimeInputOptions | EntityTypes_entityTypes_fields_inputOptions_LocalSelectInputOptions | EntityTypes_entityTypes_fields_inputOptions_NumericInputOptions;

export interface EntityTypes_entityTypes_fields {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * Human readable name for the field (may be the same as that of the data point, or may be different)
   */
  name: string;
  /**
   * The data point the field captures
   */
  dataPoint: EntityTypes_entityTypes_fields_dataPoint;
  type: SearchFilterValueType;
  /**
   * How the field should be collected from users
   */
  inputOptions: EntityTypes_entityTypes_fields_inputOptions;
}

export interface EntityTypes_entityTypes_objectLinks {
  __typename: "EntityLinkDefinition";
  id: string;
  /**
   * Human readable name for the link relationship (e.g. sample, parent, source file)
   */
  name: string | null;
  /**
   * The label to use when the link is displayed from it's object
   */
  objectLabel: string | null;
}

export interface EntityTypes_entityTypes_subjectLinks {
  __typename: "EntityLinkDefinition";
  id: string;
  /**
   * Human readable name for the link relationship (e.g. sample, parent, source file)
   */
  name: string | null;
}

export interface EntityTypes_entityTypes {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
  /**
   * The fields on the type
   */
  fields: EntityTypes_entityTypes_fields[];
  /**
   * Links where this type is the object of the link edge
   */
  objectLinks: EntityTypes_entityTypes_objectLinks[];
  /**
   * Links where this type is the subject of the link edge
   */
  subjectLinks: EntityTypes_entityTypes_subjectLinks[];
  totalCount: number | null;
}

export interface EntityTypes {
  entityTypes: EntityTypes_entityTypes[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEntityJobStatus
// ====================================================

export interface GetEntityJobStatus_getEntityJobStatus {
  __typename: "EntityBatchJobStatus";
  /**
   * The id of the job
   */
  id: string;
  /**
   * The job's state
   */
  state: EntityJobState;
}

export interface GetEntityJobStatus {
  /**
   * Get the status for an entity job
   */
  getEntityJobStatus: GetEntityJobStatus_getEntityJobStatus | null;
}

export interface GetEntityJobStatusVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportJob
// ====================================================

export interface ExportJob_getExportJob_outputDetails {
  __typename: "ExportJobOutputDetails";
  /**
   * Whether there were results in the export
   */
  hasExportedResults: boolean;
  /**
   * Whether the export includes access to the raw files that records were
   * harvested from
   */
  includesRawFiles: boolean;
}

export interface ExportJob_getExportJob {
  __typename: "EntityExportJob";
  id: string;
  /**
   * The kind of job
   */
  type: EntityJobType;
  /**
   * The job's current state
   */
  state: EntityJobState;
  /**
   * A link to download the job output; if null the export has not finished
   */
  outputDetails: ExportJob_getExportJob_outputDetails | null;
}

export interface ExportJob {
  getExportJob: ExportJob_getExportJob | null;
}

export interface ExportJobVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExportJobLink
// ====================================================

export interface ExportJobLink_getExportJob_outputDetails {
  __typename: "ExportJobOutputDetails";
  /**
   * Whether there were results in the export
   */
  hasExportedResults: boolean;
  /**
   * The link to the job output if it exists
   */
  link: string | null;
  /**
   * Whether the export includes access to the raw files that records were
   * harvested from
   */
  includesRawFiles: boolean;
}

export interface ExportJobLink_getExportJob {
  __typename: "EntityExportJob";
  id: string;
  /**
   * The kind of job
   */
  type: EntityJobType;
  /**
   * The job's current state
   */
  state: EntityJobState;
  /**
   * A link to download the job output; if null the export has not finished
   */
  outputDetails: ExportJobLink_getExportJob_outputDetails | null;
}

export interface ExportJobLink {
  getExportJob: ExportJobLink_getExportJob | null;
}

export interface ExportJobLinkVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Group
// ====================================================

export interface Group_group_group_groupMembership {
  __typename: "GroupMembershipsConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
}

export interface Group_group_group_groupProjectAccess {
  __typename: "GroupProjectAccessesConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
}

export interface Group_group_group {
  __typename: "Group";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
  /**
   * Users in the group
   */
  groupMembership: Group_group_group_groupMembership | null;
  /**
   * Projects to which the group has access
   */
  groupProjectAccess: Group_group_group_groupProjectAccess | null;
}

export interface Group_group {
  __typename: "GroupResponse";
  group: Group_group_group | null;
  errors: string[] | null;
}

export interface Group {
  group: Group_group | null;
}

export interface GroupVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Groups
// ====================================================

export interface Groups_groups_data_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
  endIndex: number;
  startIndex: number;
}

export interface Groups_groups_data_edges_node_groupMembership_edges_node_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface Groups_groups_data_edges_node_groupMembership_edges_node {
  __typename: "GroupMembership";
  user: Groups_groups_data_edges_node_groupMembership_edges_node_user | null;
}

export interface Groups_groups_data_edges_node_groupMembership_edges {
  __typename: "GroupMembershipResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: Groups_groups_data_edges_node_groupMembership_edges_node;
}

export interface Groups_groups_data_edges_node_groupMembership {
  __typename: "GroupMembershipsConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * The elements in the current page of the connection
   */
  edges: Groups_groups_data_edges_node_groupMembership_edges[];
}

export interface Groups_groups_data_edges_node_groupProjectAccess {
  __typename: "GroupProjectAccessesConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
}

export interface Groups_groups_data_edges_node {
  __typename: "Group";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
  lastModified: any;
  /**
   * Users in the group
   */
  groupMembership: Groups_groups_data_edges_node_groupMembership | null;
  /**
   * Projects to which the group has access
   */
  groupProjectAccess: Groups_groups_data_edges_node_groupProjectAccess | null;
}

export interface Groups_groups_data_edges {
  __typename: "GroupResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: Groups_groups_data_edges_node;
}

export interface Groups_groups_data {
  __typename: "GroupsConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * Details about the current page
   */
  pageInfo: Groups_groups_data_pageInfo;
  /**
   * The elements in the current page of the connection
   */
  edges: Groups_groups_data_edges[];
}

export interface Groups_groups {
  __typename: "GroupsResponse";
  data: Groups_groups_data | null;
  errors: string[] | null;
}

export interface Groups {
  groups: Groups_groups | null;
}

export interface GroupsVariables {
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  filter?: GroupFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupWithMembership
// ====================================================

export interface GroupWithMembership_group_group_groupMembership_edges_node_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface GroupWithMembership_group_group_groupMembership_edges_node {
  __typename: "GroupMembership";
  id: string;
  user: GroupWithMembership_group_group_groupMembership_edges_node_user | null;
}

export interface GroupWithMembership_group_group_groupMembership_edges {
  __typename: "GroupMembershipResultEdge";
  node: GroupWithMembership_group_group_groupMembership_edges_node;
}

export interface GroupWithMembership_group_group_groupMembership {
  __typename: "GroupMembershipsConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * The elements in the current page of the connection
   */
  edges: GroupWithMembership_group_group_groupMembership_edges[];
}

export interface GroupWithMembership_group_group {
  __typename: "Group";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * Users in the group
   */
  groupMembership: GroupWithMembership_group_group_groupMembership | null;
}

export interface GroupWithMembership_group {
  __typename: "GroupResponse";
  group: GroupWithMembership_group_group | null;
  errors: string[] | null;
}

export interface GroupWithMembership {
  group: GroupWithMembership_group | null;
}

export interface GroupWithMembershipVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupWithProjectAccess
// ====================================================

export interface GroupWithProjectAccess_group_group_groupProjectAccess_edges_node_project {
  __typename: "Project";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
}

export interface GroupWithProjectAccess_group_group_groupProjectAccess_edges_node {
  __typename: "GroupProjectAccess";
  id: string;
  project: GroupWithProjectAccess_group_group_groupProjectAccess_edges_node_project | null;
}

export interface GroupWithProjectAccess_group_group_groupProjectAccess_edges {
  __typename: "GroupProjectAccessResultEdge";
  node: GroupWithProjectAccess_group_group_groupProjectAccess_edges_node;
}

export interface GroupWithProjectAccess_group_group_groupProjectAccess {
  __typename: "GroupProjectAccessesConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * The elements in the current page of the connection
   */
  edges: GroupWithProjectAccess_group_group_groupProjectAccess_edges[];
}

export interface GroupWithProjectAccess_group_group {
  __typename: "Group";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * Projects to which the group has access
   */
  groupProjectAccess: GroupWithProjectAccess_group_group_groupProjectAccess | null;
}

export interface GroupWithProjectAccess_group {
  __typename: "GroupResponse";
  group: GroupWithProjectAccess_group_group | null;
  errors: string[] | null;
}

export interface GroupWithProjectAccess {
  group: GroupWithProjectAccess_group | null;
}

export interface GroupWithProjectAccessVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ControlledVocabularyCategory
// ====================================================

export interface ControlledVocabularyCategory_getControlledVocabularyCategory_data_items_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  endIndex: number;
  startIndex: number;
}

export interface ControlledVocabularyCategory_getControlledVocabularyCategory_data_items_edges_node_category {
  __typename: "ControlledVocabularyCategory";
  name: string;
  id: string;
}

export interface ControlledVocabularyCategory_getControlledVocabularyCategory_data_items_edges_node {
  __typename: "ControlledVocabularyItem";
  id: string;
  value: string;
  description: string | null;
  updatedAt: any | null;
  createdAt: any | null;
  category: ControlledVocabularyCategory_getControlledVocabularyCategory_data_items_edges_node_category | null;
}

export interface ControlledVocabularyCategory_getControlledVocabularyCategory_data_items_edges {
  __typename: "ControlledVocabularyItemResultEdge";
  cursor: string;
  node: ControlledVocabularyCategory_getControlledVocabularyCategory_data_items_edges_node;
}

export interface ControlledVocabularyCategory_getControlledVocabularyCategory_data_items {
  __typename: "ControlledVocabularyItemsConnection";
  /**
   * Details about the current page
   */
  pageInfo: ControlledVocabularyCategory_getControlledVocabularyCategory_data_items_pageInfo;
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * The elements in the current page of the connection
   */
  edges: ControlledVocabularyCategory_getControlledVocabularyCategory_data_items_edges[];
}

export interface ControlledVocabularyCategory_getControlledVocabularyCategory_data {
  __typename: "ControlledVocabularyCategory";
  id: string;
  name: string;
  description: string | null;
  updatedAt: any | null;
  createdAt: any | null;
  items: ControlledVocabularyCategory_getControlledVocabularyCategory_data_items | null;
}

export interface ControlledVocabularyCategory_getControlledVocabularyCategory {
  __typename: "ControlledVocabularyCategoryResponse";
  data: ControlledVocabularyCategory_getControlledVocabularyCategory_data | null;
  errors: string[] | null;
}

export interface ControlledVocabularyCategory {
  getControlledVocabularyCategory: ControlledVocabularyCategory_getControlledVocabularyCategory | null;
}

export interface ControlledVocabularyCategoryVariables {
  id: string;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  filter?: CVTermFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ControlledVocabularyCategories
// ====================================================

export interface ControlledVocabularyCategories_getControlledVocabularyCategories_data_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  endIndex: number;
  startIndex: number;
}

export interface ControlledVocabularyCategories_getControlledVocabularyCategories_data_edges_node_items_edges_node {
  __typename: "ControlledVocabularyItem";
  id: string;
  value: string;
}

export interface ControlledVocabularyCategories_getControlledVocabularyCategories_data_edges_node_items_edges {
  __typename: "ControlledVocabularyItemResultEdge";
  cursor: string;
  node: ControlledVocabularyCategories_getControlledVocabularyCategories_data_edges_node_items_edges_node;
}

export interface ControlledVocabularyCategories_getControlledVocabularyCategories_data_edges_node_items {
  __typename: "ControlledVocabularyItemsConnection";
  /**
   * The elements in the current page of the connection
   */
  edges: ControlledVocabularyCategories_getControlledVocabularyCategories_data_edges_node_items_edges[];
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
}

export interface ControlledVocabularyCategories_getControlledVocabularyCategories_data_edges_node {
  __typename: "ControlledVocabularyCategory";
  id: string;
  name: string;
  description: string | null;
  updatedAt: any | null;
  createdAt: any | null;
  items: ControlledVocabularyCategories_getControlledVocabularyCategories_data_edges_node_items | null;
}

export interface ControlledVocabularyCategories_getControlledVocabularyCategories_data_edges {
  __typename: "ControlledVocabularyCategoryResultEdge";
  cursor: string;
  node: ControlledVocabularyCategories_getControlledVocabularyCategories_data_edges_node;
}

export interface ControlledVocabularyCategories_getControlledVocabularyCategories_data {
  __typename: "ControlledVocabularyCategoriesConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * Details about the current page
   */
  pageInfo: ControlledVocabularyCategories_getControlledVocabularyCategories_data_pageInfo;
  /**
   * The elements in the current page of the connection
   */
  edges: ControlledVocabularyCategories_getControlledVocabularyCategories_data_edges[];
}

export interface ControlledVocabularyCategories_getControlledVocabularyCategories {
  __typename: "ControlledVocabularyCategoriesResponse";
  data: ControlledVocabularyCategories_getControlledVocabularyCategories_data | null;
  errors: string[] | null;
}

export interface ControlledVocabularyCategories {
  getControlledVocabularyCategories: ControlledVocabularyCategories_getControlledVocabularyCategories | null;
}

export interface ControlledVocabularyCategoriesVariables {
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  filter?: CVCategoryFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Project
// ====================================================

export interface Project_project_project_projectAccess {
  __typename: "ProjectAccessesConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
}

export interface Project_project_project {
  __typename: "Project";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
  /**
   * Users on the project
   */
  projectAccess: Project_project_project_projectAccess | null;
}

export interface Project_project {
  __typename: "ProjectResponse";
  project: Project_project_project | null;
  errors: string[] | null;
}

export interface Project {
  project: Project_project | null;
}

export interface ProjectVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Projects
// ====================================================

export interface Projects_projects_data_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
  endIndex: number;
  startIndex: number;
}

export interface Projects_projects_data_edges_node_projectAccess_edges_node_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface Projects_projects_data_edges_node_projectAccess_edges_node {
  __typename: "ProjectAccess";
  user: Projects_projects_data_edges_node_projectAccess_edges_node_user | null;
}

export interface Projects_projects_data_edges_node_projectAccess_edges {
  __typename: "ProjectAccessResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: Projects_projects_data_edges_node_projectAccess_edges_node;
}

export interface Projects_projects_data_edges_node_projectAccess {
  __typename: "ProjectAccessesConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * The elements in the current page of the connection
   */
  edges: Projects_projects_data_edges_node_projectAccess_edges[];
}

export interface Projects_projects_data_edges_node_groupProjectAccess {
  __typename: "GroupProjectAccessesConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
}

export interface Projects_projects_data_edges_node {
  __typename: "Project";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
  lastModified: any;
  /**
   * Users on the project
   */
  projectAccess: Projects_projects_data_edges_node_projectAccess | null;
  /**
   * Groups on the project
   */
  groupProjectAccess: Projects_projects_data_edges_node_groupProjectAccess | null;
}

export interface Projects_projects_data_edges {
  __typename: "ProjectResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: Projects_projects_data_edges_node;
}

export interface Projects_projects_data {
  __typename: "ProjectsConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * Details about the current page
   */
  pageInfo: Projects_projects_data_pageInfo;
  /**
   * The elements in the current page of the connection
   */
  edges: Projects_projects_data_edges[];
}

export interface Projects_projects {
  __typename: "ProjectsResponse";
  data: Projects_projects_data | null;
  errors: string[] | null;
}

export interface Projects {
  projects: Projects_projects | null;
}

export interface ProjectsVariables {
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  filter?: ProjectFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSavedSearch
// ====================================================

export interface GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_target {
  __typename: "SerializedSearchTreeTarget";
  /**
   * The id of the type being filtered on
   */
  id: string;
  /**
   * The kind of node in the entity graph to filter on
   */
  type: SearchTargetType;
}

export interface GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_filter_filterBranches_branch {
  __typename: "SerializedFilterBranch";
  parent: string | null;
  connective: Connective;
  name: string | null;
  children: string[];
}

export interface GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_filter_filterBranches {
  __typename: "SerializedFilterBranchNode";
  name: string;
  branch: GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_filter_filterBranches_branch;
}

export interface GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_filter_filterLeaves_branch_value {
  __typename: "SerializedFilterValue";
  value: string | null;
  type: string;
}

export interface GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_filter_filterLeaves_branch_metadata {
  __typename: "SerializedFilterMetadata";
  caseSensitive: boolean | null;
}

export interface GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_filter_filterLeaves_branch {
  __typename: "SerializedFilterLeaf";
  parent: string;
  connective: Connective;
  fieldId: string;
  type: string;
  value: GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_filter_filterLeaves_branch_value[];
  comparator: string;
  metadata: GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_filter_filterLeaves_branch_metadata | null;
}

export interface GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_filter_filterLeaves {
  __typename: "SerializedFilterLeafNode";
  name: string;
  branch: GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_filter_filterLeaves_branch;
}

export interface GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_filter {
  __typename: "SerializedFilter";
  rootFilterId: string;
  filterBranches: GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_filter_filterBranches[];
  filterLeaves: GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_filter_filterLeaves[];
}

export interface GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_joinData {
  __typename: "SerializedJoinDetails";
  /**
   * The kind of join being performed
   */
  joinType: JoinType;
  /**
   * How far away in the graph the join should look
   */
  joinDistance: number;
  /**
   * Whether the join is required for a search result to be returned
   */
  joinRequired: boolean;
}

export interface GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch {
  __typename: "SerializedSearchTreeBranch";
  /**
   * The target that the search is against
   */
  target: GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_target;
  /**
   * Whether the node is selected to be returned as part of the results set
   */
  select: boolean;
  /**
   * The ids of the children of this search tree
   */
  children: string[];
  /**
   * The id of the parent for this search tree
   */
  parent: string | null;
  /**
   * The filter to apply to this type or relationship
   */
  filter: GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_filter;
  /**
   * How this node is joined (only set when the node is a join)
   */
  joinData: GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch_joinData | null;
}

export interface GetSavedSearch_getSavedSearch_data_searchQuery_branches {
  __typename: "SerializedSearchTreeNode";
  name: string;
  branch: GetSavedSearch_getSavedSearch_data_searchQuery_branches_branch;
}

export interface GetSavedSearch_getSavedSearch_data_searchQuery {
  __typename: "SerializedSearchQuery";
  /**
   * The branches in the filter tree, normalized into ID, node data objects
   */
  branches: GetSavedSearch_getSavedSearch_data_searchQuery_branches[];
  /**
   * The root nodes in the search
   */
  roots: string[];
}

export interface GetSavedSearch_getSavedSearch_data_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface GetSavedSearch_getSavedSearch_data {
  __typename: "SavedSearch";
  id: string;
  name: string;
  description: string | null;
  public: boolean;
  createdAt: any;
  searchQuery: GetSavedSearch_getSavedSearch_data_searchQuery;
  updatedAt: any;
  user: GetSavedSearch_getSavedSearch_data_user | null;
}

export interface GetSavedSearch_getSavedSearch {
  __typename: "SavedSearchResponse";
  data: GetSavedSearch_getSavedSearch_data | null;
  errors: string[] | null;
}

export interface GetSavedSearch {
  getSavedSearch: GetSavedSearch_getSavedSearch;
}

export interface GetSavedSearchVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupsOfProject
// ====================================================

export interface GroupsOfProject_project_project_groupProjectAccess_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
  endIndex: number;
  startIndex: number;
}

export interface GroupsOfProject_project_project_groupProjectAccess_edges_node_group_groupMembership_edges_node_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface GroupsOfProject_project_project_groupProjectAccess_edges_node_group_groupMembership_edges_node {
  __typename: "GroupMembership";
  user: GroupsOfProject_project_project_groupProjectAccess_edges_node_group_groupMembership_edges_node_user | null;
}

export interface GroupsOfProject_project_project_groupProjectAccess_edges_node_group_groupMembership_edges {
  __typename: "GroupMembershipResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: GroupsOfProject_project_project_groupProjectAccess_edges_node_group_groupMembership_edges_node;
}

export interface GroupsOfProject_project_project_groupProjectAccess_edges_node_group_groupMembership {
  __typename: "GroupMembershipsConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * The elements in the current page of the connection
   */
  edges: GroupsOfProject_project_project_groupProjectAccess_edges_node_group_groupMembership_edges[];
}

export interface GroupsOfProject_project_project_groupProjectAccess_edges_node_group_groupProjectAccess {
  __typename: "GroupProjectAccessesConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
}

export interface GroupsOfProject_project_project_groupProjectAccess_edges_node_group {
  __typename: "Group";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
  /**
   * Users in the group
   */
  groupMembership: GroupsOfProject_project_project_groupProjectAccess_edges_node_group_groupMembership | null;
  /**
   * Projects to which the group has access
   */
  groupProjectAccess: GroupsOfProject_project_project_groupProjectAccess_edges_node_group_groupProjectAccess | null;
}

export interface GroupsOfProject_project_project_groupProjectAccess_edges_node {
  __typename: "GroupProjectAccess";
  id: string;
  createdAt: any;
  lastModified: any;
  group: GroupsOfProject_project_project_groupProjectAccess_edges_node_group | null;
}

export interface GroupsOfProject_project_project_groupProjectAccess_edges {
  __typename: "GroupProjectAccessResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: GroupsOfProject_project_project_groupProjectAccess_edges_node;
}

export interface GroupsOfProject_project_project_groupProjectAccess {
  __typename: "GroupProjectAccessesConnection";
  /**
   * Details about the current page
   */
  pageInfo: GroupsOfProject_project_project_groupProjectAccess_pageInfo;
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * The elements in the current page of the connection
   */
  edges: GroupsOfProject_project_project_groupProjectAccess_edges[];
}

export interface GroupsOfProject_project_project {
  __typename: "Project";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
  /**
   * Groups on the project
   */
  groupProjectAccess: GroupsOfProject_project_project_groupProjectAccess | null;
}

export interface GroupsOfProject_project {
  __typename: "ProjectResponse";
  project: GroupsOfProject_project_project | null;
  errors: string[] | null;
}

export interface GroupsOfProject {
  project: GroupsOfProject_project | null;
}

export interface GroupsOfProjectVariables {
  id: string;
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  filter?: GroupFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupsOfUser
// ====================================================

export interface GroupsOfUser_user_user_groupMembership_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
  endIndex: number;
  startIndex: number;
}

export interface GroupsOfUser_user_user_groupMembership_edges_node_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface GroupsOfUser_user_user_groupMembership_edges_node_group_groupMembership_edges_node_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface GroupsOfUser_user_user_groupMembership_edges_node_group_groupMembership_edges_node {
  __typename: "GroupMembership";
  user: GroupsOfUser_user_user_groupMembership_edges_node_group_groupMembership_edges_node_user | null;
}

export interface GroupsOfUser_user_user_groupMembership_edges_node_group_groupMembership_edges {
  __typename: "GroupMembershipResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: GroupsOfUser_user_user_groupMembership_edges_node_group_groupMembership_edges_node;
}

export interface GroupsOfUser_user_user_groupMembership_edges_node_group_groupMembership {
  __typename: "GroupMembershipsConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * The elements in the current page of the connection
   */
  edges: GroupsOfUser_user_user_groupMembership_edges_node_group_groupMembership_edges[];
}

export interface GroupsOfUser_user_user_groupMembership_edges_node_group_groupProjectAccess {
  __typename: "GroupProjectAccessesConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
}

export interface GroupsOfUser_user_user_groupMembership_edges_node_group {
  __typename: "Group";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
  /**
   * Users in the group
   */
  groupMembership: GroupsOfUser_user_user_groupMembership_edges_node_group_groupMembership | null;
  /**
   * Projects to which the group has access
   */
  groupProjectAccess: GroupsOfUser_user_user_groupMembership_edges_node_group_groupProjectAccess | null;
}

export interface GroupsOfUser_user_user_groupMembership_edges_node {
  __typename: "GroupMembership";
  id: string;
  user: GroupsOfUser_user_user_groupMembership_edges_node_user | null;
  createdAt: any;
  lastModified: any;
  group: GroupsOfUser_user_user_groupMembership_edges_node_group | null;
}

export interface GroupsOfUser_user_user_groupMembership_edges {
  __typename: "GroupMembershipResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: GroupsOfUser_user_user_groupMembership_edges_node;
}

export interface GroupsOfUser_user_user_groupMembership {
  __typename: "GroupMembershipsConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * Details about the current page
   */
  pageInfo: GroupsOfUser_user_user_groupMembership_pageInfo;
  /**
   * The elements in the current page of the connection
   */
  edges: GroupsOfUser_user_user_groupMembership_edges[];
}

export interface GroupsOfUser_user_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
  email: string;
  defaultRole: UserRole;
  groupMembership: GroupsOfUser_user_user_groupMembership | null;
}

export interface GroupsOfUser_user {
  __typename: "UserResponse";
  user: GroupsOfUser_user_user | null;
  errors: string[] | null;
}

export interface GroupsOfUser {
  user: GroupsOfUser_user | null;
}

export interface GroupsOfUserVariables {
  id: string;
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  filter?: GroupFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LinkSummaryDetails
// ====================================================

export interface LinkSummaryDetails_linksForSummary_edges_node_linkedEntity {
  __typename: "Entity";
  id: string;
  displayName: string;
}

export interface LinkSummaryDetails_linksForSummary_edges_node_metadata_fields_fieldDefinition {
  __typename: "DataFieldDefinition";
  id: string;
}

export interface LinkSummaryDetails_linksForSummary_edges_node_metadata_fields {
  __typename: "FieldValue";
  /**
   * The label that should appear for this field (e.g. plated on)
   */
  label: string;
  /**
   * The value to display for this field (e.g. Plate-123)
   */
  value: string;
  /**
   * The definition of the field
   */
  fieldDefinition: LinkSummaryDetails_linksForSummary_edges_node_metadata_fields_fieldDefinition;
}

export interface LinkSummaryDetails_linksForSummary_edges_node_metadata {
  __typename: "LinkMetadata";
  fields: LinkSummaryDetails_linksForSummary_edges_node_metadata_fields[];
}

export interface LinkSummaryDetails_linksForSummary_edges_node {
  __typename: "EntityLink";
  id: string;
  direction: Direction;
  linkedEntity: LinkSummaryDetails_linksForSummary_edges_node_linkedEntity;
  metadata: LinkSummaryDetails_linksForSummary_edges_node_metadata | null;
}

export interface LinkSummaryDetails_linksForSummary_edges {
  __typename: "EntityLinkEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: LinkSummaryDetails_linksForSummary_edges_node;
}

export interface LinkSummaryDetails_linksForSummary_linkDefinition_allowedTypes {
  __typename: "EntityType";
  id: string;
}

export interface LinkSummaryDetails_linksForSummary_linkDefinition_type_fields {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * Human readable name for the field (may be the same as that of the data point, or may be different)
   */
  name: string;
  /**
   * Human readable description of the field
   */
  description: string | null;
}

export interface LinkSummaryDetails_linksForSummary_linkDefinition_type {
  __typename: "LinkType";
  id: string;
  /**
   * The relationship verb
   */
  name: string;
  /**
   * A human readable description of the meaning of the relationship verb
   */
  description: string | null;
  /**
   * This is the data the applies to the link itself
   */
  fields: LinkSummaryDetails_linksForSummary_linkDefinition_type_fields[];
}

export interface LinkSummaryDetails_linksForSummary_linkDefinition {
  __typename: "EntityLinkDefinition";
  id: string;
  /**
   * Human readable name for the link relationship (e.g. sample, parent, source file)
   */
  name: string | null;
  /**
   * Human readable description of the relationship at the entity level
   */
  description: string | null;
  /**
   * The types that are allowed for the link (null indicates that any type is allowed)
   */
  allowedTypes: LinkSummaryDetails_linksForSummary_linkDefinition_allowedTypes[] | null;
  /**
   * The type of link (i.e. the verb in the link triple)
   */
  type: LinkSummaryDetails_linksForSummary_linkDefinition_type;
}

export interface LinkSummaryDetails_linksForSummary_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  endIndex: number;
  startIndex: number;
}

export interface LinkSummaryDetails_linksForSummary {
  __typename: "SummarizedLinksConnection";
  /**
   * The elements on the current page of the connection
   */
  edges: LinkSummaryDetails_linksForSummary_edges[];
  /**
   * The definition being summarized
   */
  linkDefinition: LinkSummaryDetails_linksForSummary_linkDefinition;
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * Details about the current page
   */
  pageInfo: LinkSummaryDetails_linksForSummary_pageInfo;
}

export interface LinkSummaryDetails {
  linksForSummary: LinkSummaryDetails_linksForSummary | null;
}

export interface LinkSummaryDetailsVariables {
  filter: SummaryFilter;
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectAccess
// ====================================================

export interface ProjectAccess_projectAccess_projectAccess_user {
  __typename: "User";
  id: string;
  name: string | null;
}

export interface ProjectAccess_projectAccess_projectAccess_project {
  __typename: "Project";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
}

export interface ProjectAccess_projectAccess_projectAccess_sources_groupMembership_group {
  __typename: "Group";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
}

export interface ProjectAccess_projectAccess_projectAccess_sources_groupMembership {
  __typename: "GroupMembership";
  group: ProjectAccess_projectAccess_projectAccess_sources_groupMembership_group | null;
}

export interface ProjectAccess_projectAccess_projectAccess_sources {
  __typename: "AccessSource";
  id: string;
  sourceType: AccessSourceType;
  groupMembership: ProjectAccess_projectAccess_projectAccess_sources_groupMembership | null;
}

export interface ProjectAccess_projectAccess_projectAccess {
  __typename: "ProjectAccess";
  id: string;
  user: ProjectAccess_projectAccess_projectAccess_user | null;
  project: ProjectAccess_projectAccess_projectAccess_project | null;
  hasDirectAccess: boolean;
  /**
   * The sources granting project access
   */
  sources: ProjectAccess_projectAccess_projectAccess_sources[];
}

export interface ProjectAccess_projectAccess {
  __typename: "ProjectAccessResponse";
  projectAccess: ProjectAccess_projectAccess_projectAccess | null;
  errors: string[] | null;
}

export interface ProjectAccess {
  projectAccess: ProjectAccess_projectAccess | null;
}

export interface ProjectAccessVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectsOfGroup
// ====================================================

export interface ProjectsOfGroup_group_group_groupProjectAccess_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
  endIndex: number;
  startIndex: number;
}

export interface ProjectsOfGroup_group_group_groupProjectAccess_edges_node_project {
  __typename: "Project";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
}

export interface ProjectsOfGroup_group_group_groupProjectAccess_edges_node {
  __typename: "GroupProjectAccess";
  id: string;
  createdAt: any;
  project: ProjectsOfGroup_group_group_groupProjectAccess_edges_node_project | null;
}

export interface ProjectsOfGroup_group_group_groupProjectAccess_edges {
  __typename: "GroupProjectAccessResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: ProjectsOfGroup_group_group_groupProjectAccess_edges_node;
}

export interface ProjectsOfGroup_group_group_groupProjectAccess {
  __typename: "GroupProjectAccessesConnection";
  /**
   * Details about the current page
   */
  pageInfo: ProjectsOfGroup_group_group_groupProjectAccess_pageInfo;
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * The elements in the current page of the connection
   */
  edges: ProjectsOfGroup_group_group_groupProjectAccess_edges[];
}

export interface ProjectsOfGroup_group_group {
  __typename: "Group";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
  /**
   * Projects to which the group has access
   */
  groupProjectAccess: ProjectsOfGroup_group_group_groupProjectAccess | null;
}

export interface ProjectsOfGroup_group {
  __typename: "GroupResponse";
  group: ProjectsOfGroup_group_group | null;
  errors: string[] | null;
}

export interface ProjectsOfGroup {
  group: ProjectsOfGroup_group | null;
}

export interface ProjectsOfGroupVariables {
  id: string;
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  filter?: ProjectFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProjectsOfUser
// ====================================================

export interface ProjectsOfUser_user_user_projectAccess_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
  endIndex: number;
  startIndex: number;
}

export interface ProjectsOfUser_user_user_projectAccess_edges_node_project {
  __typename: "Project";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
}

export interface ProjectsOfUser_user_user_projectAccess_edges_node_sources {
  __typename: "AccessSource";
  id: string;
  sourceType: AccessSourceType;
}

export interface ProjectsOfUser_user_user_projectAccess_edges_node {
  __typename: "ProjectAccess";
  id: string;
  userRole: UserRole;
  createdAt: any;
  project: ProjectsOfUser_user_user_projectAccess_edges_node_project | null;
  hasDirectAccess: boolean;
  /**
   * The sources granting project access
   */
  sources: ProjectsOfUser_user_user_projectAccess_edges_node_sources[];
}

export interface ProjectsOfUser_user_user_projectAccess_edges {
  __typename: "ProjectAccessResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: ProjectsOfUser_user_user_projectAccess_edges_node;
}

export interface ProjectsOfUser_user_user_projectAccess {
  __typename: "ProjectAccessesConnection";
  /**
   * Details about the current page
   */
  pageInfo: ProjectsOfUser_user_user_projectAccess_pageInfo;
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * The elements in the current page of the connection
   */
  edges: ProjectsOfUser_user_user_projectAccess_edges[];
}

export interface ProjectsOfUser_user_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
  email: string;
  defaultRole: UserRole;
  projectAccess: ProjectsOfUser_user_user_projectAccess | null;
}

export interface ProjectsOfUser_user {
  __typename: "UserResponse";
  user: ProjectsOfUser_user_user | null;
  errors: string[] | null;
}

export interface ProjectsOfUser {
  user: ProjectsOfUser_user | null;
}

export interface ProjectsOfUserVariables {
  id: string;
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  filter?: ProjectFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReviewSummary
// ====================================================

export interface ReviewSummary_reviewSummary_statusCounts_reviewSummaryIssuesLists_items {
  __typename: "ReviewSummaryIssuesListItem";
  /**
   * The description of the issue
   */
  label: string;
}

export interface ReviewSummary_reviewSummary_statusCounts_reviewSummaryIssuesLists {
  __typename: "ReviewSummaryIssuesList";
  /**
   * The title of the issues list
   */
  title: string;
  /**
   * The individual issue items
   */
  items: ReviewSummary_reviewSummary_statusCounts_reviewSummaryIssuesLists_items[];
}

export interface ReviewSummary_reviewSummary_statusCounts {
  __typename: "StatusCount";
  /**
   * The number of entities with this status in the review
   */
  count: number;
  /**
   * The review status of the entities
   */
  status: ReviewStatus;
  /**
   * The list of top issues for this status
   */
  reviewSummaryIssuesLists: ReviewSummary_reviewSummary_statusCounts_reviewSummaryIssuesLists[] | null;
}

export interface ReviewSummary_reviewSummary_reviewSummaryIssuesLists_items {
  __typename: "ReviewSummaryIssuesListItem";
  /**
   * The description of the issue
   */
  label: string;
}

export interface ReviewSummary_reviewSummary_reviewSummaryIssuesLists {
  __typename: "ReviewSummaryIssuesList";
  /**
   * The title of the issues list
   */
  title: string;
  /**
   * The individual issue items
   */
  items: ReviewSummary_reviewSummary_reviewSummaryIssuesLists_items[];
}

export interface ReviewSummary_reviewSummary {
  __typename: "ReviewSummaryResponse";
  /**
   * The total number of entities in this review
   */
  totalCount: number;
  /**
   * The number of entities in review by status
   */
  statusCounts: ReviewSummary_reviewSummary_statusCounts[] | null;
  /**
   * The list of top issues in this review
   */
  reviewSummaryIssuesLists: ReviewSummary_reviewSummary_reviewSummaryIssuesLists[] | null;
}

export interface ReviewSummary {
  reviewSummary: ReviewSummary_reviewSummary;
}

export interface ReviewSummaryVariables {
  reviewSummaryFilter?: ReviewSummaryFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchSavedSearches
// ====================================================

export interface SearchSavedSearches_searchSavedSearches_data_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  endIndex: number;
  startIndex: number;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
}

export interface SearchSavedSearches_searchSavedSearches_data_edges_node_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface SearchSavedSearches_searchSavedSearches_data_edges_node {
  __typename: "SavedSearch";
  id: string;
  name: string;
  description: string | null;
  public: boolean;
  createdAt: any;
  updatedAt: any;
  type: SavedSearchType;
  user: SearchSavedSearches_searchSavedSearches_data_edges_node_user | null;
}

export interface SearchSavedSearches_searchSavedSearches_data_edges {
  __typename: "SavedSearchEdge";
  cursor: string;
  node: SearchSavedSearches_searchSavedSearches_data_edges_node;
}

export interface SearchSavedSearches_searchSavedSearches_data {
  __typename: "SavedSearchesConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * Details about the current page
   */
  pageInfo: SearchSavedSearches_searchSavedSearches_data_pageInfo;
  /**
   * The elements in the current page of the connection
   */
  edges: SearchSavedSearches_searchSavedSearches_data_edges[];
}

export interface SearchSavedSearches_searchSavedSearches {
  __typename: "SavedSearchesResponse";
  data: SearchSavedSearches_searchSavedSearches_data | null;
  errors: string[] | null;
}

export interface SearchSavedSearches {
  searchSavedSearches: SearchSavedSearches_searchSavedSearches | null;
}

export interface SearchSavedSearchesVariables {
  filter?: SavedSearchesSearchFilter | null;
  order?: SortOrder | null;
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchTargets
// ====================================================

export interface SearchTargets_searchTargets_LinkType_fields_inputOptions_Autogenerated {
  __typename: "Autogenerated" | "RemoteSelectInputOptions";
}

export interface SearchTargets_searchTargets_LinkType_fields_inputOptions_TextInputOptions {
  __typename: "TextInputOptions";
  minLength: number | null;
  maxLength: number | null;
  type: InputType;
}

export interface SearchTargets_searchTargets_LinkType_fields_inputOptions_LocalSelectInputOptions_options {
  __typename: "SelectOptions";
  value: string;
  text: string;
}

export interface SearchTargets_searchTargets_LinkType_fields_inputOptions_LocalSelectInputOptions {
  __typename: "LocalSelectInputOptions";
  options: SearchTargets_searchTargets_LinkType_fields_inputOptions_LocalSelectInputOptions_options[];
  type: InputType;
}

export interface SearchTargets_searchTargets_LinkType_fields_inputOptions_NumericInputOptions {
  __typename: "NumericInputOptions";
  type: InputType;
}

export interface SearchTargets_searchTargets_LinkType_fields_inputOptions_DateTimeInputOptions {
  __typename: "DateTimeInputOptions";
  minDate: string | null;
}

export type SearchTargets_searchTargets_LinkType_fields_inputOptions = SearchTargets_searchTargets_LinkType_fields_inputOptions_Autogenerated | SearchTargets_searchTargets_LinkType_fields_inputOptions_TextInputOptions | SearchTargets_searchTargets_LinkType_fields_inputOptions_LocalSelectInputOptions | SearchTargets_searchTargets_LinkType_fields_inputOptions_NumericInputOptions | SearchTargets_searchTargets_LinkType_fields_inputOptions_DateTimeInputOptions;

export interface SearchTargets_searchTargets_LinkType_fields {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * Human readable name for the field (may be the same as that of the data point, or may be different)
   */
  name: string;
  /**
   * Human readable description of the field
   */
  description: string | null;
  /**
   * How the field should be collected from users
   */
  inputOptions: SearchTargets_searchTargets_LinkType_fields_inputOptions;
}

export interface SearchTargets_searchTargets_LinkType {
  __typename: "LinkType";
  id: string;
  /**
   * The relationship verb
   */
  name: string;
  /**
   * A human readable description of the meaning of the relationship verb
   */
  description: string | null;
  searchTargetType: SearchTargetType;
  /**
   * This is the data the applies to the link itself
   */
  fields: SearchTargets_searchTargets_LinkType_fields[];
}

export interface SearchTargets_searchTargets_EntityType {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
  searchTargetType: SearchTargetType;
}

export type SearchTargets_searchTargets = SearchTargets_searchTargets_LinkType | SearchTargets_searchTargets_EntityType;

export interface SearchTargets {
  searchTargets: SearchTargets_searchTargets[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SystemFields
// ====================================================

export interface SystemFields_systemFields_dataPoint {
  __typename: "DataPoint";
  /**
   * The type of data being stored
   */
  dataType: DataType;
}

export interface SystemFields_systemFields {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * Human readable name for the field (may be the same as that of the data point, or may be different)
   */
  name: string;
  /**
   * The data point the field captures
   */
  dataPoint: SystemFields_systemFields_dataPoint;
  type: SearchFilterValueType;
}

export interface SystemFields {
  systemFields: SystemFields_systemFields[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LinkEntities
// ====================================================

export interface LinkEntities_entities_data_edges_node_entity {
  __typename: "Entity";
  id: string;
  displayName: string;
}

export interface LinkEntities_entities_data_edges_node {
  __typename: "EntitySearchResult";
  /**
   * The entity result
   */
  entity: LinkEntities_entities_data_edges_node_entity;
}

export interface LinkEntities_entities_data_edges {
  __typename: "EntityResultEdge";
  node: LinkEntities_entities_data_edges_node;
}

export interface LinkEntities_entities_data {
  __typename: "EntityResultsConnection";
  /**
   * The elements in the current page of the connection
   */
  edges: LinkEntities_entities_data_edges[];
}

export interface LinkEntities_entities {
  __typename: "EntitiesResponse";
  /**
   * Data is defined when there there is no issue with the query
   */
  data: LinkEntities_entities_data | null;
  /**
   * Errors is defined when there is a problem parsing the query
   */
  errors: string[] | null;
}

export interface LinkEntities {
  entities: LinkEntities_entities;
}

export interface LinkEntitiesVariables {
  first?: number | null;
  last?: number | null;
  searchQuery?: SearchQuery | null;
  before?: string | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UnclassifiedGroupById
// ====================================================

export interface UnclassifiedGroupById_unclassifiedGroupById {
  __typename: "UnclassifiedResultGroup";
  /**
   * The ID of the group
   */
  id: string;
  /**
   * Whether the group is currently locked because it's being classified
   */
  locked: boolean;
}

export interface UnclassifiedGroupById {
  /**
   * Get an unclassified group.
   * 
   * Note: groups returned from this endpoint will always return null for
   * totalCount
   */
  unclassifiedGroupById: UnclassifiedGroupById_unclassifiedGroupById | null;
}

export interface UnclassifiedGroupByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UnclassifiedGroupMembers
// ====================================================

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  endIndex: number;
  startIndex: number;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_createdBy {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_linkSummaries_linkDefinition {
  __typename: "EntityLinkDefinition";
  id: string;
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_linkSummaries_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_linkSummaries {
  __typename: "EntityLinkSummary";
  /**
   * A unique id assigned to the link or links being summarized
   */
  id: string;
  /**
   * The label that should appear for this summary (e.g. plated on)
   */
  label: string;
  /**
   * The value to display for this link (e.g. Plate-123)
   */
  value: string;
  /**
   * The direction of the link (from is a link from this entity to another, to is a link to this entity from another)
   */
  direction: Direction;
  /**
   * The number of links included in the summary
   */
  linkCount: number;
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The definition being summarized
   */
  linkDefinition: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_linkSummaries_linkDefinition;
  /**
   * Any annotations to display in the summary
   */
  annotations: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_linkSummaries_annotations[];
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_userMetadata {
  __typename: "MetadataValue";
  /**
   * The metdata field
   */
  label: string;
  /**
   * The metadata value
   */
  value: string;
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_metadata_status {
  __typename: "ProcessingStatus";
  state: ProcessingState;
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_metadata_contributors {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_metadata {
  __typename: "EntityMetadata";
  /**
   * The id of the source (e.g. the id of the upload document or transaction)
   */
  sourceId: string | null;
  /**
   * The kind of source the entity came from
   */
  sourceType: SourceType | null;
  /**
   * An optional link to a preview of the entity
   */
  previewLink: string | null;
  /**
   * The status of the entity in the ingestion process
   */
  status: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_metadata_status;
  /**
   * The users who have edited the entity
   */
  contributors: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_metadata_contributors[];
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_fieldValues_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_fieldValues_fieldDefinition_dataPoint {
  __typename: "DataPoint";
  /**
   * The type of data being stored
   */
  dataType: DataType;
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_fieldValues_fieldDefinition {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * The data point the field captures
   */
  dataPoint: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_fieldValues_fieldDefinition_dataPoint;
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_fieldValues {
  __typename: "FieldValue";
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The value to display for this field (e.g. Plate-123)
   */
  value: string;
  /**
   * Any annotations to display in the summary
   */
  annotations: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_fieldValues_annotations[];
  /**
   * The definition of the field
   */
  fieldDefinition: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_fieldValues_fieldDefinition;
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_type {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_annotations {
  __typename: "QualityAnnotation";
  source: AnnotationSource;
  severity: AnnotationSeverity;
  message: string;
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity {
  __typename: "Entity";
  id: string;
  createdBy: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_createdBy;
  createdOn: string;
  displayName: string;
  displayStatus: AnnotationSeverity | null;
  lastModified: string;
  linkSummaries: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_linkSummaries[];
  userMetadata: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_userMetadata[];
  metadata: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_metadata;
  fieldValues: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_fieldValues[];
  type: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_type;
  annotations: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity_annotations[];
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node {
  __typename: "EntitySearchResult";
  /**
   * The entity result
   */
  entity: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node_entity;
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges {
  __typename: "EntityResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges_node;
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers_data {
  __typename: "EntityResultsConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * Details about the current page
   */
  pageInfo: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_pageInfo;
  /**
   * The elements in the current page of the connection
   */
  edges: UnclassifiedGroupMembers_unclassifiedGroupMembers_data_edges[];
}

export interface UnclassifiedGroupMembers_unclassifiedGroupMembers {
  __typename: "UnclassifiedEntitiesResponse";
  /**
   * Data is defined when there there is no issue with the query
   */
  data: UnclassifiedGroupMembers_unclassifiedGroupMembers_data | null;
  /**
   * Errors is defined when there is a problem parsing the query
   */
  errors: string[] | null;
}

export interface UnclassifiedGroupMembers {
  unclassifiedGroupMembers: UnclassifiedGroupMembers_unclassifiedGroupMembers;
}

export interface UnclassifiedGroupMembersVariables {
  groupId: string;
  searchQuery?: SearchQuery | null;
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Upload
// ====================================================

export interface Upload_upload_uploadDetails {
  __typename: "UploadDetails";
  /**
   * The unique identifier for the upload
   */
  id: string;
  /**
   * The upload's source file name
   */
  fileName: string | null;
  /**
   * The name of the upload object
   */
  name: string;
}

export interface Upload_upload {
  __typename: "UploadStatus";
  /**
   * Basic information about an upload
   */
  uploadDetails: Upload_upload_uploadDetails;
}

export interface Upload {
  /**
   * A single upload
   */
  upload: Upload_upload | null;
}

export interface UploadVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Uploads
// ====================================================

export interface Uploads_uploads_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
  startIndex: number;
  endIndex: number;
}

export interface Uploads_uploads_edges_node_uploadDetails_user {
  __typename: "User";
  name: string | null;
  picture: string | null;
}

export interface Uploads_uploads_edges_node_uploadDetails {
  __typename: "UploadDetails";
  /**
   * The unique identifier for the upload
   */
  id: string;
  /**
   * The date the upload data upload completed and processing started
   */
  completedAt: any | null;
  /**
   * The upload's source file name
   */
  fileName: string | null;
  /**
   * The upload's source file mime type
   */
  fileMimeType: string | null;
  /**
   * The name of the upload object
   */
  name: string;
  /**
   * Whether an upload is a subset of a file
   */
  subset: boolean;
  /**
   * The upload type
   */
  uploadType: UploadType;
  user: Uploads_uploads_edges_node_uploadDetails_user | null;
}

export interface Uploads_uploads_edges_node_processingDetails {
  __typename: "ProcessingDetails";
  /**
   * The total count of entities processed
   */
  importCount: number | null;
  /**
   * The total count of unclassified and invalid entities that need manual review
   */
  reviewCount: number | null;
}

export interface Uploads_uploads_edges_node {
  __typename: "UploadStatus";
  /**
   * The state of the upload
   */
  uploadState: UploadState;
  /**
   * Basic information about an upload
   */
  uploadDetails: Uploads_uploads_edges_node_uploadDetails;
  /**
   * Processing information for an upload
   */
  processingDetails: Uploads_uploads_edges_node_processingDetails | null;
}

export interface Uploads_uploads_edges {
  __typename: "UploadStatusEdge";
  cursor: string | null;
  node: Uploads_uploads_edges_node | null;
}

export interface Uploads_uploads {
  __typename: "UploadStatusConnection";
  /**
   * how many uploads match the filters
   */
  totalCount: number | null;
  pageInfo: Uploads_uploads_pageInfo;
  edges: (Uploads_uploads_edges | null)[] | null;
}

export interface Uploads {
  /**
   * The status of all uploads in the system, with optional filters
   */
  uploads: Uploads_uploads | null;
}

export interface UploadsVariables {
  first?: number | null;
  last?: number | null;
  filter?: UploadFilter | null;
  before?: string | null;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GroupsOfUserForProfile
// ====================================================

export interface GroupsOfUserForProfile_user_user_groupMembership_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
  endIndex: number;
  startIndex: number;
}

export interface GroupsOfUserForProfile_user_user_groupMembership_edges_node_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface GroupsOfUserForProfile_user_user_groupMembership_edges_node_group_createdBy {
  __typename: "User";
  name: string | null;
  id: string;
  picture: string | null;
}

export interface GroupsOfUserForProfile_user_user_groupMembership_edges_node_group_groupMembership_edges_node_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface GroupsOfUserForProfile_user_user_groupMembership_edges_node_group_groupMembership_edges_node {
  __typename: "GroupMembership";
  user: GroupsOfUserForProfile_user_user_groupMembership_edges_node_group_groupMembership_edges_node_user | null;
}

export interface GroupsOfUserForProfile_user_user_groupMembership_edges_node_group_groupMembership_edges {
  __typename: "GroupMembershipResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: GroupsOfUserForProfile_user_user_groupMembership_edges_node_group_groupMembership_edges_node;
}

export interface GroupsOfUserForProfile_user_user_groupMembership_edges_node_group_groupMembership {
  __typename: "GroupMembershipsConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * The elements in the current page of the connection
   */
  edges: GroupsOfUserForProfile_user_user_groupMembership_edges_node_group_groupMembership_edges[];
}

export interface GroupsOfUserForProfile_user_user_groupMembership_edges_node_group_groupProjectAccess {
  __typename: "GroupProjectAccessesConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
}

export interface GroupsOfUserForProfile_user_user_groupMembership_edges_node_group {
  __typename: "Group";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
  createdBy: GroupsOfUserForProfile_user_user_groupMembership_edges_node_group_createdBy | null;
  /**
   * Users in the group
   */
  groupMembership: GroupsOfUserForProfile_user_user_groupMembership_edges_node_group_groupMembership | null;
  /**
   * Projects to which the group has access
   */
  groupProjectAccess: GroupsOfUserForProfile_user_user_groupMembership_edges_node_group_groupProjectAccess | null;
}

export interface GroupsOfUserForProfile_user_user_groupMembership_edges_node {
  __typename: "GroupMembership";
  id: string;
  user: GroupsOfUserForProfile_user_user_groupMembership_edges_node_user | null;
  createdAt: any;
  lastModified: any;
  group: GroupsOfUserForProfile_user_user_groupMembership_edges_node_group | null;
}

export interface GroupsOfUserForProfile_user_user_groupMembership_edges {
  __typename: "GroupMembershipResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: GroupsOfUserForProfile_user_user_groupMembership_edges_node;
}

export interface GroupsOfUserForProfile_user_user_groupMembership {
  __typename: "GroupMembershipsConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * Details about the current page
   */
  pageInfo: GroupsOfUserForProfile_user_user_groupMembership_pageInfo;
  /**
   * The elements in the current page of the connection
   */
  edges: GroupsOfUserForProfile_user_user_groupMembership_edges[];
}

export interface GroupsOfUserForProfile_user_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
  email: string;
  defaultRole: UserRole;
  groupMembership: GroupsOfUserForProfile_user_user_groupMembership | null;
}

export interface GroupsOfUserForProfile_user {
  __typename: "UserResponse";
  user: GroupsOfUserForProfile_user_user | null;
  errors: string[] | null;
}

export interface GroupsOfUserForProfile {
  user: GroupsOfUserForProfile_user | null;
}

export interface GroupsOfUserForProfileVariables {
  id: string;
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  filter?: GroupFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: User
// ====================================================

export interface User_user_user_preferences {
  __typename: "UserPreferences";
  uploadWizardSkipAllQuestions: boolean | null;
  uploadWizardSkipAllQuestionsPrompt: boolean | null;
  rememberExportOptions: boolean | null;
  exportOptionsPreference: ExportOption | null;
}

export interface User_user_user {
  __typename: "User";
  id: string;
  email: string;
  name: string | null;
  picture: string | null;
  defaultRole: UserRole;
  preferences: User_user_user_preferences | null;
}

export interface User_user {
  __typename: "UserResponse";
  user: User_user_user | null;
  errors: string[] | null;
}

export interface User {
  user: User_user | null;
}

export interface UserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UsersOfGroup
// ====================================================

export interface UsersOfGroup_group_group_groupMembership_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
  endIndex: number;
  startIndex: number;
}

export interface UsersOfGroup_group_group_groupMembership_edges_node_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
  email: string;
}

export interface UsersOfGroup_group_group_groupMembership_edges_node {
  __typename: "GroupMembership";
  id: string;
  createdAt: any;
  user: UsersOfGroup_group_group_groupMembership_edges_node_user | null;
}

export interface UsersOfGroup_group_group_groupMembership_edges {
  __typename: "GroupMembershipResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: UsersOfGroup_group_group_groupMembership_edges_node;
}

export interface UsersOfGroup_group_group_groupMembership {
  __typename: "GroupMembershipsConnection";
  /**
   * Details about the current page
   */
  pageInfo: UsersOfGroup_group_group_groupMembership_pageInfo;
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * The elements in the current page of the connection
   */
  edges: UsersOfGroup_group_group_groupMembership_edges[];
}

export interface UsersOfGroup_group_group {
  __typename: "Group";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
  /**
   * Users in the group
   */
  groupMembership: UsersOfGroup_group_group_groupMembership | null;
}

export interface UsersOfGroup_group {
  __typename: "GroupResponse";
  group: UsersOfGroup_group_group | null;
  errors: string[] | null;
}

export interface UsersOfGroup {
  group: UsersOfGroup_group | null;
}

export interface UsersOfGroupVariables {
  id: string;
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  filter?: UserFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UsersOfProject
// ====================================================

export interface UsersOfProject_project_project_projectAccess_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
  endIndex: number;
  startIndex: number;
}

export interface UsersOfProject_project_project_projectAccess_edges_node_sources {
  __typename: "AccessSource";
  id: string;
  sourceType: AccessSourceType;
}

export interface UsersOfProject_project_project_projectAccess_edges_node_user {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
  email: string;
  defaultRole: UserRole;
}

export interface UsersOfProject_project_project_projectAccess_edges_node {
  __typename: "ProjectAccess";
  id: string;
  userRole: UserRole;
  hasDirectAccess: boolean;
  /**
   * The sources granting project access
   */
  sources: UsersOfProject_project_project_projectAccess_edges_node_sources[];
  user: UsersOfProject_project_project_projectAccess_edges_node_user | null;
  createdAt: any;
}

export interface UsersOfProject_project_project_projectAccess_edges {
  __typename: "ProjectAccessResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: UsersOfProject_project_project_projectAccess_edges_node;
}

export interface UsersOfProject_project_project_projectAccess {
  __typename: "ProjectAccessesConnection";
  /**
   * Details about the current page
   */
  pageInfo: UsersOfProject_project_project_projectAccess_pageInfo;
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * The elements in the current page of the connection
   */
  edges: UsersOfProject_project_project_projectAccess_edges[];
}

export interface UsersOfProject_project_project {
  __typename: "Project";
  id: string;
  /**
   * The name of the project
   */
  name: string | null;
  /**
   * A description of the project
   */
  description: string | null;
  /**
   * Users on the project
   */
  projectAccess: UsersOfProject_project_project_projectAccess | null;
}

export interface UsersOfProject_project {
  __typename: "ProjectResponse";
  project: UsersOfProject_project_project | null;
  errors: string[] | null;
}

export interface UsersOfProject {
  project: UsersOfProject_project | null;
}

export interface UsersOfProjectVariables {
  id: string;
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  filter?: UserFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Users
// ====================================================

export interface Users_users_data_pageInfo {
  __typename: "PageInfo";
  /**
   * When paginating forward, the cursor to continue
   */
  endCursor: string | null;
  /**
   * When paginating backward, the cursor to continue
   */
  startCursor: string | null;
  /**
   * Are there items after the end cursor?
   */
  hasNextPage: boolean;
  /**
   * Are there items before the start cursor?
   */
  hasPreviousPage: boolean;
  endIndex: number;
  startIndex: number;
}

export interface Users_users_data_edges_node_groupMembership {
  __typename: "GroupMembershipsConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
}

export interface Users_users_data_edges_node_projectAccess {
  __typename: "ProjectAccessesConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
}

export interface Users_users_data_edges_node {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
  email: string;
  defaultRole: UserRole;
  source: string | null;
  status: UserStatus;
  groupMembership: Users_users_data_edges_node_groupMembership | null;
  projectAccess: Users_users_data_edges_node_projectAccess | null;
}

export interface Users_users_data_edges {
  __typename: "UserResultEdge";
  /**
   * The cursor to reach this entity as a starting point
   */
  cursor: string;
  node: Users_users_data_edges_node;
}

export interface Users_users_data {
  __typename: "UsersConnection";
  /**
   * How many total elements are in the connection.  If null, the connection is not finite
   */
  totalCount: number | null;
  /**
   * Details about the current page
   */
  pageInfo: Users_users_data_pageInfo;
  /**
   * The elements in the current page of the connection
   */
  edges: Users_users_data_edges[];
}

export interface Users_users {
  __typename: "UsersResponse";
  data: Users_users_data | null;
  errors: string[] | null;
}

export interface Users {
  users: Users_users;
}

export interface UsersVariables {
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  filter?: UsersFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClassificationTableDetails
// ====================================================

export interface ClassificationTableDetails {
  __typename: "UnclassifiedResultGroup";
  /**
   * The ID of the group
   */
  id: string;
  /**
   * The name of the group that should be displayed in the group
   */
  name: string;
  /**
   * The headers that are in the group
   */
  headers: string[];
  /**
   * The count of the group within the current search query
   */
  totalCount: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClassificationDetail
// ====================================================

export interface ClassificationDetail_matchingTypes_entityType {
  __typename: "EntityType";
  id: string;
}

export interface ClassificationDetail_matchingTypes_invalidColumns_suggestedColumns {
  __typename: "UnclassifiedColumnMatch";
  /**
   * The human readable name of the entity type field
   */
  name: string;
  /**
   * The field definition id of the field
   */
  fieldDefinitionId: string;
  /**
   * The confidence of the match
   */
  confidence: number;
}

export interface ClassificationDetail_matchingTypes_invalidColumns {
  __typename: "UnclassifiedField";
  /**
   * The header the field has in the unclassified entities
   */
  header: string;
  /**
   * Any hint message for the UI about the field
   */
  hint: string;
  /**
   * The columns that the field may match in the entity type.
   * 
   * If a column does not match against any fields within the type then this list
   * will be empty.
   */
  suggestedColumns: ClassificationDetail_matchingTypes_invalidColumns_suggestedColumns[];
}

export interface ClassificationDetail_matchingTypes_missingColumns {
  __typename: "MissingField";
  /**
   * The name of the field from the entity type
   */
  name: string;
  /**
   * The id of the field from the entity type
   */
  fieldDefinitionId: string;
}

export interface ClassificationDetail_matchingTypes {
  __typename: "UnclassifiedTypeMatch";
  /**
   * The name of the type matched
   */
  name: string;
  /**
   * The confidence in the match between the group and the type
   */
  confidence: number;
  /**
   * The entity type matched
   */
  entityType: ClassificationDetail_matchingTypes_entityType;
  /**
   * The columns in the group that could not be matched to a field on the entity type
   */
  invalidColumns: ClassificationDetail_matchingTypes_invalidColumns[];
  /**
   * The fields from the entity type that are not directly matched by columns from the group.
   * 
   * All suggested fields from invalid columns that do not have a definite match will
   * be represented in this list since they are missing from the type until a
   * definite determination for each invalid column has been made.
   */
  missingColumns: ClassificationDetail_matchingTypes_missingColumns[];
}

export interface ClassificationDetail {
  __typename: "UnclassifiedResultGroup";
  /**
   * The ID of the group
   */
  id: string;
  /**
   * The name of the group that should be displayed in the group
   */
  name: string;
  /**
   * The count of the group within the current search query
   */
  totalCount: number | null;
  /**
   * How likely the match was if there is a single possible match above 50%
   */
  confidence: number | null;
  /**
   * The headers that are in the group
   */
  headers: string[];
  /**
   * The types that the group matches
   */
  matchingTypes: ClassificationDetail_matchingTypes[];
  /**
   * Whether the group is currently locked because it's being classified
   */
  locked: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CurrentEntityType
// ====================================================

export interface CurrentEntityType_fields {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * Human readable name for the field (may be the same as that of the data point, or may be different)
   */
  name: string;
}

export interface CurrentEntityType {
  __typename: "EntityType";
  id: string;
  /**
   * The fields on the type
   */
  fields: CurrentEntityType_fields[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FieldDetails
// ====================================================

export interface FieldDetails {
  __typename: "DataFieldDefinition";
  /**
   * Human readable name for the field (may be the same as that of the data point, or may be different)
   */
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TargetEntity
// ====================================================

export interface TargetEntity {
  __typename: "Entity";
  id: string;
  displayName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FieldDefinitionDetails
// ====================================================

export interface FieldDefinitionDetails_dataPoint {
  __typename: "DataPoint";
  /**
   * The type of data being stored
   */
  dataType: DataType;
}

export interface FieldDefinitionDetails_inputOptions_RemoteSelectInputOptions {
  __typename: "RemoteSelectInputOptions";
}

export interface FieldDefinitionDetails_inputOptions_Autogenerated {
  __typename: "Autogenerated";
  displayOnEntry: boolean;
}

export interface FieldDefinitionDetails_inputOptions_DateTimeInputOptions {
  __typename: "DateTimeInputOptions";
  captureTime: boolean;
}

export interface FieldDefinitionDetails_inputOptions_LocalSelectInputOptions_options {
  __typename: "SelectOptions";
  text: string;
  value: string;
}

export interface FieldDefinitionDetails_inputOptions_LocalSelectInputOptions {
  __typename: "LocalSelectInputOptions";
  type: InputType;
  options: FieldDefinitionDetails_inputOptions_LocalSelectInputOptions_options[];
}

export interface FieldDefinitionDetails_inputOptions_NumericInputOptions {
  __typename: "NumericInputOptions";
  type: InputType;
  precision: number;
}

export interface FieldDefinitionDetails_inputOptions_TextInputOptions {
  __typename: "TextInputOptions";
  type: InputType;
}

export type FieldDefinitionDetails_inputOptions = FieldDefinitionDetails_inputOptions_RemoteSelectInputOptions | FieldDefinitionDetails_inputOptions_Autogenerated | FieldDefinitionDetails_inputOptions_DateTimeInputOptions | FieldDefinitionDetails_inputOptions_LocalSelectInputOptions | FieldDefinitionDetails_inputOptions_NumericInputOptions | FieldDefinitionDetails_inputOptions_TextInputOptions;

export interface FieldDefinitionDetails {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * Human readable name for the field (may be the same as that of the data point, or may be different)
   */
  name: string;
  /**
   * The data point the field captures
   */
  dataPoint: FieldDefinitionDetails_dataPoint;
  type: SearchFilterValueType;
  /**
   * How the field should be collected from users
   */
  inputOptions: FieldDefinitionDetails_inputOptions;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SearchBranchSearchTarget
// ====================================================

export interface SearchBranchSearchTarget_LinkType {
  __typename: "LinkType";
  /**
   * The relationship verb
   */
  name: string;
}

export interface SearchBranchSearchTarget_EntityType {
  __typename: "EntityType";
  /**
   * The human readable name for the type
   */
  name: string;
}

export type SearchBranchSearchTarget = SearchBranchSearchTarget_LinkType | SearchBranchSearchTarget_EntityType;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserSource
// ====================================================

export interface UserSource {
  __typename: "User";
  id: string;
  name: string | null;
  source: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ClassificationTypeMatch
// ====================================================

export interface ClassificationTypeMatch_entityType {
  __typename: "EntityType";
  id: string;
}

export interface ClassificationTypeMatch_invalidColumns_suggestedColumns {
  __typename: "UnclassifiedColumnMatch";
  /**
   * The human readable name of the entity type field
   */
  name: string;
  /**
   * The field definition id of the field
   */
  fieldDefinitionId: string;
  /**
   * The confidence of the match
   */
  confidence: number;
}

export interface ClassificationTypeMatch_invalidColumns {
  __typename: "UnclassifiedField";
  /**
   * The header the field has in the unclassified entities
   */
  header: string;
  /**
   * Any hint message for the UI about the field
   */
  hint: string;
  /**
   * The columns that the field may match in the entity type.
   * 
   * If a column does not match against any fields within the type then this list
   * will be empty.
   */
  suggestedColumns: ClassificationTypeMatch_invalidColumns_suggestedColumns[];
}

export interface ClassificationTypeMatch_missingColumns {
  __typename: "MissingField";
  /**
   * The name of the field from the entity type
   */
  name: string;
  /**
   * The id of the field from the entity type
   */
  fieldDefinitionId: string;
}

export interface ClassificationTypeMatch {
  __typename: "UnclassifiedTypeMatch";
  /**
   * The name of the type matched
   */
  name: string;
  /**
   * The confidence in the match between the group and the type
   */
  confidence: number;
  /**
   * The entity type matched
   */
  entityType: ClassificationTypeMatch_entityType;
  /**
   * The columns in the group that could not be matched to a field on the entity type
   */
  invalidColumns: ClassificationTypeMatch_invalidColumns[];
  /**
   * The fields from the entity type that are not directly matched by columns from the group.
   * 
   * All suggested fields from invalid columns that do not have a definite match will
   * be represented in this list since they are missing from the type until a
   * definite determination for each invalid column has been made.
   */
  missingColumns: ClassificationTypeMatch_missingColumns[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EntityTypeFields
// ====================================================

export interface EntityTypeFields_fields_dataPoint {
  __typename: "DataPoint";
  /**
   * The type of data being stored
   */
  dataType: DataType;
}

export interface EntityTypeFields_fields {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * Human readable name for the field (may be the same as that of the data point, or may be different)
   */
  name: string;
  /**
   * The data point the field captures
   */
  dataPoint: EntityTypeFields_fields_dataPoint;
}

export interface EntityTypeFields {
  __typename: "EntityType";
  /**
   * The human readable name for the type
   */
  name: string;
  /**
   * The fields on the type
   */
  fields: EntityTypeFields_fields[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SearchEntity
// ====================================================

export interface SearchEntity_createdBy {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface SearchEntity_linkSummaries_linkDefinition {
  __typename: "EntityLinkDefinition";
  id: string;
}

export interface SearchEntity_linkSummaries_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface SearchEntity_linkSummaries {
  __typename: "EntityLinkSummary";
  /**
   * A unique id assigned to the link or links being summarized
   */
  id: string;
  /**
   * The label that should appear for this summary (e.g. plated on)
   */
  label: string;
  /**
   * The value to display for this link (e.g. Plate-123)
   */
  value: string;
  /**
   * The direction of the link (from is a link from this entity to another, to is a link to this entity from another)
   */
  direction: Direction;
  /**
   * The number of links included in the summary
   */
  linkCount: number;
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The definition being summarized
   */
  linkDefinition: SearchEntity_linkSummaries_linkDefinition;
  /**
   * Any annotations to display in the summary
   */
  annotations: SearchEntity_linkSummaries_annotations[];
}

export interface SearchEntity_userMetadata {
  __typename: "MetadataValue";
  /**
   * The metdata field
   */
  label: string;
  /**
   * The metadata value
   */
  value: string;
}

export interface SearchEntity_metadata_status {
  __typename: "ProcessingStatus";
  state: ProcessingState;
}

export interface SearchEntity_metadata_contributors {
  __typename: "User";
  id: string;
  name: string | null;
  picture: string | null;
}

export interface SearchEntity_metadata {
  __typename: "EntityMetadata";
  /**
   * The id of the source (e.g. the id of the upload document or transaction)
   */
  sourceId: string | null;
  /**
   * The kind of source the entity came from
   */
  sourceType: SourceType | null;
  /**
   * An optional link to a preview of the entity
   */
  previewLink: string | null;
  /**
   * The status of the entity in the ingestion process
   */
  status: SearchEntity_metadata_status;
  /**
   * The users who have edited the entity
   */
  contributors: SearchEntity_metadata_contributors[];
}

export interface SearchEntity_fieldValues_annotations {
  __typename: "QualityAnnotation";
  message: string;
  severity: AnnotationSeverity;
  source: AnnotationSource;
}

export interface SearchEntity_fieldValues_fieldDefinition_dataPoint {
  __typename: "DataPoint";
  /**
   * The type of data being stored
   */
  dataType: DataType;
}

export interface SearchEntity_fieldValues_fieldDefinition {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * The data point the field captures
   */
  dataPoint: SearchEntity_fieldValues_fieldDefinition_dataPoint;
}

export interface SearchEntity_fieldValues {
  __typename: "FieldValue";
  /**
   * The status to display with the link
   */
  displayStatus: AnnotationSeverity | null;
  /**
   * The value to display for this field (e.g. Plate-123)
   */
  value: string;
  /**
   * Any annotations to display in the summary
   */
  annotations: SearchEntity_fieldValues_annotations[];
  /**
   * The definition of the field
   */
  fieldDefinition: SearchEntity_fieldValues_fieldDefinition;
}

export interface SearchEntity_type {
  __typename: "EntityType";
  id: string;
  /**
   * The human readable name for the type
   */
  name: string;
}

export interface SearchEntity_annotations {
  __typename: "QualityAnnotation";
  source: AnnotationSource;
  severity: AnnotationSeverity;
  message: string;
}

export interface SearchEntity {
  __typename: "Entity";
  id: string;
  createdBy: SearchEntity_createdBy;
  createdOn: string;
  displayName: string;
  displayStatus: AnnotationSeverity | null;
  lastModified: string;
  linkSummaries: SearchEntity_linkSummaries[];
  userMetadata: SearchEntity_userMetadata[];
  metadata: SearchEntity_metadata;
  fieldValues: SearchEntity_fieldValues[];
  type: SearchEntity_type;
  annotations: SearchEntity_annotations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UnclassifiedGroup
// ====================================================

export interface UnclassifiedGroup_matchingTypes_entityType {
  __typename: "EntityType";
  id: string;
}

export interface UnclassifiedGroup_matchingTypes_invalidColumns_suggestedColumns {
  __typename: "UnclassifiedColumnMatch";
  /**
   * The human readable name of the entity type field
   */
  name: string;
  /**
   * The field definition id of the field
   */
  fieldDefinitionId: string;
  /**
   * The confidence of the match
   */
  confidence: number;
}

export interface UnclassifiedGroup_matchingTypes_invalidColumns {
  __typename: "UnclassifiedField";
  /**
   * The header the field has in the unclassified entities
   */
  header: string;
  /**
   * Any hint message for the UI about the field
   */
  hint: string;
  /**
   * The columns that the field may match in the entity type.
   * 
   * If a column does not match against any fields within the type then this list
   * will be empty.
   */
  suggestedColumns: UnclassifiedGroup_matchingTypes_invalidColumns_suggestedColumns[];
}

export interface UnclassifiedGroup_matchingTypes_missingColumns {
  __typename: "MissingField";
  /**
   * The name of the field from the entity type
   */
  name: string;
  /**
   * The id of the field from the entity type
   */
  fieldDefinitionId: string;
}

export interface UnclassifiedGroup_matchingTypes {
  __typename: "UnclassifiedTypeMatch";
  /**
   * The name of the type matched
   */
  name: string;
  /**
   * The confidence in the match between the group and the type
   */
  confidence: number;
  /**
   * The entity type matched
   */
  entityType: UnclassifiedGroup_matchingTypes_entityType;
  /**
   * The columns in the group that could not be matched to a field on the entity type
   */
  invalidColumns: UnclassifiedGroup_matchingTypes_invalidColumns[];
  /**
   * The fields from the entity type that are not directly matched by columns from the group.
   * 
   * All suggested fields from invalid columns that do not have a definite match will
   * be represented in this list since they are missing from the type until a
   * definite determination for each invalid column has been made.
   */
  missingColumns: UnclassifiedGroup_matchingTypes_missingColumns[];
}

export interface UnclassifiedGroup {
  __typename: "UnclassifiedResultGroup";
  /**
   * The ID of the group
   */
  id: string;
  /**
   * The name of the group that should be displayed in the group
   */
  name: string;
  /**
   * The count of the group within the current search query
   */
  totalCount: number | null;
  /**
   * How likely the match was if there is a single possible match above 50%
   */
  confidence: number | null;
  /**
   * The headers that are in the group
   */
  headers: string[];
  /**
   * The types that the group matches
   */
  matchingTypes: UnclassifiedGroup_matchingTypes[];
  /**
   * Whether the group is currently locked because it's being classified
   */
  locked: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: activeEntityType
// ====================================================

export interface activeEntityType_fields {
  __typename: "DataFieldDefinition";
  id: string;
  /**
   * Human readable name for the field (may be the same as that of the data point, or may be different)
   */
  name: string;
  type: SearchFilterValueType;
}

export interface activeEntityType {
  __typename: "EntityType";
  id: string;
  /**
   * The fields on the type
   */
  fields: activeEntityType_fields[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The source of Access to a Project
 */
export enum AccessSourceType {
  DIRECT = "DIRECT",
  GROUP = "GROUP",
}

export enum AnnotationSeverity {
  ERROR = "ERROR",
  INFO = "INFO",
  WARNING = "WARNING",
}

export enum AnnotationSource {
  ANOMALY_DETECTION = "ANOMALY_DETECTION",
  CLASSIFICATION = "CLASSIFICATION",
  STRUCTURING = "STRUCTURING",
  VALIDATION = "VALIDATION",
}

export enum Connective {
  AND = "AND",
  OR = "OR",
}

export enum DataType {
  BOOLEAN = "BOOLEAN",
  DATETIME = "DATETIME",
  FLOAT = "FLOAT",
  INT = "INT",
  STRING = "STRING",
  URI = "URI",
  URL = "URL",
}

export enum Direction {
  FROM = "FROM",
  TO = "TO",
}

export enum EntityHistoryEventOrigin {
  ANOMALY_DETECTION = "ANOMALY_DETECTION",
  CLASSIFICATION = "CLASSIFICATION",
  LINKING = "LINKING",
  NORMALIZATION = "NORMALIZATION",
  STRUCTURING = "STRUCTURING",
  UPLOAD = "UPLOAD",
  USER_INTERFACE = "USER_INTERFACE",
  VALIDATION = "VALIDATION",
}

export enum EntityHistoryEventType {
  APPROVAL = "APPROVAL",
  CREATE = "CREATE",
  DELETE = "DELETE",
  EDIT = "EDIT",
  LINKING = "LINKING",
}

/**
 * The state of an entity job
 */
export enum EntityJobState {
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  PROCESSING = "PROCESSING",
}

/**
 * The kind of job being processed
 */
export enum EntityJobType {
  CLASSIFICATION = "CLASSIFICATION",
  Classification = "Classification",
  EXPORT = "EXPORT",
  Export = "Export",
  UPDATE = "UPDATE",
  Update = "Update",
}

export enum ExportOption {
  EXPORT_RESULTS_AND_SOURCE = "EXPORT_RESULTS_AND_SOURCE",
  EXPORT_RESULTS_ONLY = "EXPORT_RESULTS_ONLY",
}

export enum FileUploadStatus {
  COMPLETE = "COMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum InputType {
  CHECKBOX = "CHECKBOX",
  DURATION = "DURATION",
  SCAN = "SCAN",
  SELECT = "SELECT",
  TEXT = "TEXT",
  TEXTAREA = "TEXTAREA",
  TIMESTAMP = "TIMESTAMP",
}

export enum JoinType {
  BIDIRECTIONAL = "BIDIRECTIONAL",
  INBOUND = "INBOUND",
  NULL = "NULL",
  OUTBOUND = "OUTBOUND",
}

export enum MatchDisplayType {
  SIMPLE = "SIMPLE",
  TEXT_PREVIEW = "TEXT_PREVIEW",
}

export enum ProcessingState {
  COMPLETED = "COMPLETED",
  PROCESSING = "PROCESSING",
  QUALITY_ISSUES = "QUALITY_ISSUES",
  UNCLASSIFIED = "UNCLASSIFIED",
  UNSTRUCTURED = "UNSTRUCTURED",
}

export enum ReviewStatus {
  ANOMALOUS = "ANOMALOUS",
  INVALID = "INVALID",
  UNCLASSIFIED = "UNCLASSIFIED",
}

export enum SavedSearchType {
  REVIEW = "REVIEW",
  SEARCH = "SEARCH",
}

export enum SearchFilterValueType {
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  NUMBER = "NUMBER",
  STRING = "STRING",
}

export enum SearchTargetType {
  ENTITY_TYPE = "ENTITY_TYPE",
  RELATIONSHIP_TYPE = "RELATIONSHIP_TYPE",
}

export enum Severity {
  ERROR = "ERROR",
  INFO = "INFO",
  WARNING = "WARNING",
}

/**
 * Sort direction
 */
export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export enum SourceType {
  INTEGRATION = "INTEGRATION",
  TRANSACTION = "TRANSACTION",
  UPLOAD = "UPLOAD",
}

/**
 * File upload status
 */
export enum Status {
  IN_PROGRESS = "IN_PROGRESS",
  TERMINAL_ERROR = "TERMINAL_ERROR",
}

/**
 * The state of the upload
 */
export enum UploadState {
  CAPTURING = "CAPTURING",
  COMPLETED = "COMPLETED",
  NEEDS_REVIEW = "NEEDS_REVIEW",
  PROCESSING = "PROCESSING",
}

export enum UploadType {
  IMAGE = "IMAGE",
  PLAIN_TEXT = "PLAIN_TEXT",
  RICH_TEXT = "RICH_TEXT",
  TABULAR = "TABULAR",
  UNSTRUCTURED_TYPE_UNSET = "UNSTRUCTURED_TYPE_UNSET",
}

/**
 * The role of a User on a Project
 */
export enum UserRole {
  ADMINISTRATOR = "ADMINISTRATOR",
  APPROVER = "APPROVER",
  COLLABORATOR = "COLLABORATOR",
  GUEST = "GUEST",
  OWNER = "OWNER",
  READER = "READER",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface ActiveUploadInput {
  id: string;
  fileName: string;
  fileType: string;
  status: FileUploadStatus;
}

export interface AddControlledVocabularyCategoryInput {
  description?: string | null;
  name: string;
}

export interface AddGroupInput {
  description: string;
  name: string;
}

export interface AddItemsToControlledVocabularyCategoryInput {
  categoryId: string;
  items: AddItemsToControlledVocabularyCategoryInputItem[];
}

export interface AddItemsToControlledVocabularyCategoryInputItem {
  description?: string | null;
  value: string;
}

export interface AddProjectInput {
  description: string;
  name: string;
}

export interface AddSavedSearchInput {
  description?: string | null;
  id: string;
  name: string;
  public: boolean;
  searchQuery: SearchQuery;
  type?: SavedSearchType | null;
  userId: string;
  userName: string;
}

export interface AddUserToGroupInput {
  groupId: string;
  userId: string;
}

export interface AddUsersToGroupsInput {
  additions: AddUserToGroupInput[];
}

export interface AllowedLinkTypesInput {
  entityTypeIds: string[];
}

export interface AllowedTargetEntityCoordinate {
  vesselType: string;
  x: number;
  y: number;
}

export interface AllowedTargetEntityTypesInput {
  containerPosition?: AllowedTargetEntityCoordinate | null;
  direction?: Direction | null;
  entityTypeIds: string[];
  linkTypeId?: string | null;
  linkTypeIds?: string[] | null;
}

/**
 * Input for approving a group of entities by their IDs
 */
export interface ApproveEntitiesInput {
  approval: ErrorApproval;
  ids: string[];
}

/**
 * Input for approving an entire entity review by either its ID or using a search query
 */
export interface ApproveReviewInput {
  approval: ErrorApproval;
  id?: string | null;
  reviewQuery?: SearchQuery | null;
}

export interface CVCategoryFilter {
  searchTerm?: string | null;
}

export interface CVTermFilter {
  searchTerm?: string | null;
}

/**
 * Operation details for column addition during classification
 */
export interface ClassificationColumnAddition {
  fieldDefinitionId: string;
  value?: string | null;
}

/**
 * Operation details for renaming a column during classification
 */
export interface ClassificationColumnRename {
  sourceColumnName: string;
  targetFieldDefinitionId: string;
}

/**
 * Information needed to classify a subset of a group
 */
export interface ClassifyEntitiesInput {
  columnsAsMetadata: string[];
  columnsToDelete: string[];
  columnsToRename: ClassificationColumnRename[];
  groupId: string;
  missingColumns: ClassificationColumnAddition[];
  searchQuery: SearchQuery;
  selectedTypeId: string;
}

export interface CreateUserInput {
  defaultRole: UserRole;
  email: string;
  groupIds?: string[] | null;
  id?: string | null;
  name: string;
  password: string;
  picture?: string | null;
  projectIds?: string[] | null;
}

export interface DeleteInput {
  id: string;
}

export interface DeleteSavedSearchesInput {
  ids: string[];
}

export interface DuplicateSavedSearchInput {
  description?: string | null;
  name: string;
  newId: string;
  oldId: string;
  public: boolean;
  type: SavedSearchType;
  userId: string;
  userName: string;
}

/**
 * Input for editing a group of entities
 */
export interface EditEntitiesInput {
  approval?: ErrorApproval | null;
  entities: EditEntityInput[];
}

/**
 * Input for updating values on an entity; fields should be considered a merge operation
 */
export interface EditEntityInput {
  fields: FieldInput[];
  id: string;
  typeId: string;
}

/**
 * Input for editing the user metadata of an entity
 */
export interface EditEntityMetadataInput {
  id: string;
  userMetadata: MetadataValueInput[];
}

/**
 * Filters used to limit which events are returned
 */
export interface EntityHistoryFilter {
  eventTypes?: EntityHistoryEventType[] | null;
  searchTerm?: string | null;
}

/**
 * Input for approving edits to entities that do not pass quality rules
 */
export interface ErrorApproval {
  reason: string;
}

/**
 * Input data needed to launch an export
 */
export interface ExportSearchInput {
  includeRawFiles: boolean;
  searchQuery: SearchQuery;
}

/**
 * An individual field value being captured
 */
export interface FieldInput {
  deleteField?: boolean | null;
  fieldDefinitionId: string;
  value?: string | null;
}

export interface Filter {
  filterBranches: FilterBranchNode[];
  filterLeaves: FilterLeafNode[];
  rootFilterId: string;
}

export interface FilterBranch {
  children: string[];
  connective: Connective;
  name?: string | null;
  parent?: string | null;
}

export interface FilterBranchNode {
  branch: FilterBranch;
  name: string;
}

export interface FilterLeaf {
  comparator: string;
  connective: Connective;
  fieldId: string;
  metadata?: FilterMetadata | null;
  parent: string;
  type: string;
  value: FilterValue[];
}

export interface FilterLeafNode {
  branch: FilterLeaf;
  name: string;
}

export interface FilterMetadata {
  caseSensitive?: boolean | null;
}

export interface FilterValue {
  type: string;
  value?: string | null;
}

export interface GlobalFilter {
  appliesTo?: string[] | null;
  filter: Filter;
}

export interface GrantGroupAccessToProjectInput {
  groupId: string;
  projectId: string;
}

export interface GrantGroupsAccessToProjectsInput {
  grants: GrantGroupAccessToProjectInput[];
}

export interface GrantUserAccessToProjectInput {
  projectId: string;
  userId: string;
  userRole: UserRole;
}

export interface GrantUsersAccessToProjectsInput {
  grants: GrantUserAccessToProjectInput[];
}

export interface GroupFilter {
  searchTerm?: string | null;
}

/**
 * Details about a join to be performed between two search nodes
 */
export interface JoinDetails {
  joinDistance: number;
  joinRequired: boolean;
  joinType: JoinType;
}

/**
 * A metadata value describing an entity
 */
export interface MetadataValueInput {
  deleteField?: boolean | null;
  label: string;
  value?: string | null;
}

/**
 * Input information for starting a local upload
 */
export interface NewLocalFileInput {
  fileId?: string | null;
  fileName: string;
  fileSize: number;
}

export interface ProjectFilter {
  searchTerm?: string | null;
}

export interface RemoveItemsFromControlledVocabularyCategoryInput {
  categoryId: string;
  ids: string[];
}

export interface ReviewSummaryFilter {
  uploadId?: string | null;
}

export interface RevokeProjectAccessInput {
  groupIds?: string[] | null;
  id: string;
}

export interface SavedSearchesSearchFilter {
  authorId?: string | null;
  searchTerm?: string | null;
  types?: SavedSearchType[] | null;
  userId?: string | null;
}

export interface SearchQuery {
  branches: SearchTreeNode[];
  globalFilters?: GlobalFilter[] | null;
  roots: string[];
}

/**
 * Details about a search on a particular node (either an entity or relationship type) in the entity graph
 */
export interface SearchTreeBranch {
  children: string[];
  filter: Filter;
  joinData?: JoinDetails | null;
  parent?: string | null;
  select: boolean;
  target: SearchTreeTarget;
}

export interface SearchTreeNode {
  branch: SearchTreeBranch;
  name: string;
}

/**
 * The specific type of entity to query against
 */
export interface SearchTreeTarget {
  id: string;
  type: SearchTargetType;
}

/**
 * Input for information about a local file upload succeeding
 */
export interface StorageCompleteInput {
  fileId: string;
  fileName: string;
  fileSize: number;
  linkingContexts?: UploadLinkingContext[] | null;
  links?: UploadLinkCreation[] | null;
  metadata?: UploadMetadataInput[] | null;
  projectIds?: string[] | null;
  selectedSubsets: string[];
  subsets: string[];
  uploadId: string;
  uploadPartConfirmations: UploadPartConfirmation[];
  uploadedBy: string;
}

/**
 * Filter criteria for returning information that composes a link summary
 */
export interface SummaryFilter {
  id: string;
}

export interface UpdateProjectAccessInput {
  id: string;
  userRole: UserRole;
}

export interface UpdateSavedSearchInput {
  description?: string | null;
  id: string;
  name: string;
  public: boolean;
  searchQuery: SearchQuery;
  type?: SavedSearchType | null;
}

export interface UpdateUserPreferenceInput {
  id: string;
  preferences: UserPreferencesInput;
}

export interface UpdateUserRoleInput {
  defaultRole?: UserRole | null;
  id: string;
}

/**
 * Filters used to limit which uploads are returned
 */
export interface UploadFilter {
  searchTerm?: string | null;
  uploadStates?: (UploadState | null)[] | null;
}

/**
 * Links to existing entities in the system
 */
export interface UploadLinkCreation {
  linkData?: UploadLinkField[] | null;
  linkTargetIds?: string[] | null;
  linkTypeId: string;
  selectedUploadIds: string[];
}

/**
 * Data fields for links that are uploaded into the system
 */
export interface UploadLinkField {
  field: string;
  value: string;
}

/**
 * Associations with other files for extracting links with non-uniform identifiers
 */
export interface UploadLinkingContext {
  existingObjectIds: string[];
  selectedUploadIds: string[];
}

/**
 * Upload metadata fields
 */
export interface UploadMetadataInput {
  field: string;
  value: string;
}

/**
 * Multipart upload confirmation
 */
export interface UploadPartConfirmation {
  eTag: string;
  partNumber: number;
}

export interface UpsertUserInput {
  email: string;
  id: string;
  name?: string | null;
  picture?: string | null;
  source?: string | null;
}

export interface UserFilter {
  searchTerm?: string | null;
}

export interface UserPreferencesInput {
  exportOptionsPreference?: ExportOption | null;
  rememberExportOptions?: boolean | null;
  uploadWizardSkipAllQuestions?: boolean | null;
  uploadWizardSkipAllQuestionsPrompt?: boolean | null;
}

export interface UsersFilter {
  searchTerm?: string | null;
  status?: UserStatus | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
