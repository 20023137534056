import gql from 'graphql-tag';
import { useMutation, MutationHookOptions } from '@apollo/client';

import {
  UpdateEntityMetadata,
  UpdateEntityMetadataVariables,
} from '../types/schemaTypes';
import { SearchEntity } from '../fragments';

export const UPDATE_ENTITY_METADATA = gql`
  mutation UpdateEntityMetadata($input: EditEntityMetadataInput!) {
    editEntityMetadata(input: $input) {
      data {
        ...SearchEntity
      }
      generalAnnotations {
        source
        severity
        message
      }
    }
  }
  ${SearchEntity}
`;

export const useUpdateEntityMetadata = (
  options?: MutationHookOptions<
    UpdateEntityMetadata,
    UpdateEntityMetadataVariables
  >,
) => {
  return useMutation<UpdateEntityMetadata, UpdateEntityMetadataVariables>(
    UPDATE_ENTITY_METADATA,
    options,
  );
};
