import gql from 'graphql-tag';
import { useMutation, MutationHookOptions } from '@apollo/client';

import {
  UpdateUserPreferences,
  UpdateUserPreferencesVariables,
} from '../types/schemaTypes';

export const UPDATE_USER_PREFERENCES = gql`
  mutation UpdateUserPreferences($input: UpdateUserPreferenceInput!) {
    updateUserPreferences(input: $input) {
      id
      preferences {
        uploadWizardSkipAllQuestions
        uploadWizardSkipAllQuestionsPrompt
        rememberExportOptions
        exportOptionsPreference
      }
    }
  }
`;

export const useUpdateUserPreferences = (
  options?: MutationHookOptions<
    UpdateUserPreferences,
    UpdateUserPreferencesVariables
  >,
) => {
  return useMutation<UpdateUserPreferences, UpdateUserPreferencesVariables>(
    UPDATE_USER_PREFERENCES,
    options,
  );
};
