import { Redirect, Switch, Route, useParams } from 'react-router-dom';
import { useGetProjectByIdQuery } from '../../queries/useGetProjectByIdQuery';
import ProjectUsersTable from '../../components/ProjectUsersTable';
import DetailTabs from '../../components/DetailTabs';
import ProjectGroupsTable from '../../components/ProjectGroupsTable';
import React from 'react';
import { collectErrors } from '../../utils/collectErrors';
import { useSetRecoilState } from 'recoil';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';

const projectDetailTabsArray = ['users', 'groups'];

const ProjectDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetProjectByIdQuery({
    variables: { id: id },
  });
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.project?.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error fetching default project',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <DetailTabs
      actionLabel={data?.project?.project?.id && 'View Project Data'}
      baseRoute="settings/projects"
      id={id}
      loading={loading}
      tabs={projectDetailTabsArray}
      title={data?.project?.project?.name || 'Project Not Found'}
    >
      <Switch>
        <Route path="/settings/projects/:id/users">
          <ProjectUsersTable />
        </Route>
        <Route path="/settings/projects/:id/groups">
          <ProjectGroupsTable />
        </Route>
        <Redirect to={`/settings/projects/${id}/users`} />
      </Switch>
    </DetailTabs>
  );
};

export default ProjectDetail;
