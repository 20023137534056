import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  RemoveItemsFromControlledVocabularyCategory,
  RemoveItemsFromControlledVocabularyCategoryVariables,
} from '../types/schemaTypes';

const DELETE_LIST_ITEM = gql`
  mutation RemoveItemsFromControlledVocabularyCategory(
    $input: RemoveItemsFromControlledVocabularyCategoryInput!
  ) {
    removeItemsFromControlledVocabularyCategory(input: $input) {
      data {
        id
        name
        items {
          totalCount
          edges {
            node {
              id
              value
            }
          }
        }
      }
      errors
    }
  }
`;

export const useDeleteListItemMutation = (
  options?: MutationHookOptions<
    RemoveItemsFromControlledVocabularyCategory,
    RemoveItemsFromControlledVocabularyCategoryVariables
  >,
) => {
  return useMutation<
    RemoveItemsFromControlledVocabularyCategory,
    RemoveItemsFromControlledVocabularyCategoryVariables
  >(DELETE_LIST_ITEM, options);
};
