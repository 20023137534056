import { defaultProjectId } from '../constants';

export const extractProjectIdsByFileId = (
  fileId: string,
  projectInfo: Record<
    string,
    { projectName: string; projectId: string; filesInProject: string[] }
  >,
) => {
  const projectIds: string[] = [];
  const projects = Object.entries(projectInfo);
  projects.map((project) => {
    const projectId = project[1].projectId;
    const projectData = project[1];
    if (projectData.filesInProject.includes(fileId)) {
      projectIds.push(projectId);
    }
  });
  projectIds.push(defaultProjectId);
  return projectIds;
};
