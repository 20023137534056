import { FilterValueType, FilterMultipleType } from '../types';

const filterValueTypes: FilterValueType[] = [
  'BOOLEAN',
  'DATE',
  'NUMBER',
  'STRING',
];

export default function isFilterValueType(
  type: FilterValueType | FilterMultipleType,
): type is FilterValueType {
  return filterValueTypes.includes(type as FilterValueType);
}
