import React from 'react';

export function useOnScreen<T extends HTMLElement>(
  ref: React.RefObject<T>,
  { rootMargin, root, threshold }: IntersectionObserverInit,
  sticky?: boolean,
) {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = React.useState(false);

  React.useEffect(() => {
    const node = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        if (sticky && isIntersecting) return;
        setIntersecting(entry.isIntersecting);
      },
      {
        root,
        rootMargin,
        threshold,
      },
    );
    if (node) {
      observer.observe(node);
    }
    return () => {
      if (node) {
        observer.unobserve(node);
      }
    };
  }, [isIntersecting, ref, root, rootMargin, threshold, sticky]); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
}
