import { AnnotationSeverity } from '../types/schemaTypes';
import { PaletteStatus } from '../types';

const AnnotationThemeHash: Record<AnnotationSeverity, PaletteStatus> = {
  [AnnotationSeverity.ERROR]: 'error',
  [AnnotationSeverity.WARNING]: 'warning',
  [AnnotationSeverity.INFO]: 'info',
};

type GetThemeStatusFromServerStatus = (
  serverStatus: AnnotationSeverity | null,
) => PaletteStatus | undefined;
const getThemeStatusFromServerStatus: GetThemeStatusFromServerStatus = (
  serverStatus,
) => (serverStatus ? AnnotationThemeHash[serverStatus] : undefined);

export default getThemeStatusFromServerStatus;
