import { Box, Dialog } from '@mui/material';
import React from 'react';
import SelectableListToolbar from '../SelectableListToolbar';
import SNButton from '../SNButton';
import { useSetRecoilState } from 'recoil';
import { pageIdsAtom } from '../../atoms/listSelection';

import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import { useGroupsOfUserQuery } from '../../queries/useGroupsOfUserQuery';
import SNTable from '../SNTable';
import SNPagination from '../SNPagination';
import SearchableListToolbarActions from '../SearchableListToolbarActions';
import { useToggle } from '../../hooks/useToggle';
import AddGroupsToUserForm from '../AddGroupsToUserForm';
import GroupsTableRow from '../GroupsTableRow';
import { UserRole } from '../../types/schemaTypes';
import RoleMask from '../RoleMask';
import ListSelectionControl from '../ListSelectionControl';
import { collectErrors } from '../../utils/collectErrors';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { useDebounce } from '../../hooks';

interface UserDetailGroupsTableProps {
  desiredUserId?: string;
}

const UserDetailGroupsTable = ({
  desiredUserId,
}: UserDetailGroupsTableProps) => {
  const headers = ['name', 'description', 'users', 'projects', 'date added'];
  const pageSize = 10;
  const { id } = useParams<{ id: string }>();
  const userId = React.useMemo(() => {
    return desiredUserId ? desiredUserId : id;
  }, [id, desiredUserId]);
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const [addGroupsModalOpen, setAddGroupModalOpen, setAddGroupModalClosed] =
    useToggle(false);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { data, loading, error, fetchMore, refetch } = useGroupsOfUserQuery({
    variables: {
      id: userId,
      first: pageSize,
      filter: { searchTerm: debouncedSearchTerm },
    },
    onCompleted: (result) => {
      const pageIds = result?.user?.user?.groupMembership?.edges.map(
        (edge) => edge.node.id,
      );
      setPageIds(pageIds || []);
    },
  });
  React.useEffect(() => {
    refetch({
      id: userId,
      first: pageSize,
      filter: { searchTerm: debouncedSearchTerm },
    });
  }, [refetch, userId, debouncedSearchTerm]);

  const pageTotal = data?.user?.user?.groupMembership?.edges.length || 0;

  const groupMembership = React.useMemo(
    () => data?.user?.user?.groupMembership?.edges.map((edge) => edge.node),
    [data],
  );

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.user?.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: "Error fetching user's groups",
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);
  const closeAddGroupsModal = () => {
    setAddGroupModalClosed();
    refetch();
  };

  return (
    <Box>
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            {...data?.user?.user?.groupMembership?.pageInfo}
            fetchMore={fetchMore}
            loading={loading}
            pageSize={pageSize}
            pageTotal={pageTotal}
            totalCount={data?.user?.user?.groupMembership?.totalCount}
          />
        </Box>
        <SearchableListToolbarActions
          value={searchTerm}
          setValue={setSearchTerm}
        >
          <Dialog fullWidth open={addGroupsModalOpen}>
            <AddGroupsToUserForm onClose={closeAddGroupsModal} />
          </Dialog>
          <RoleMask allowedRoles={[UserRole.ADMINISTRATOR]}>
            <SNButton
              startIcon={<AddIcon />}
              snVariant="text"
              onClick={setAddGroupModalOpen}
            >
              Add Groups
            </SNButton>
          </RoleMask>
        </SearchableListToolbarActions>
      </SelectableListToolbar>
      <SNTable
        error={error}
        hasResults={!!groupMembership}
        headers={headers}
        id="user-groups"
        loading={loading}
        rowCount={pageTotal}
        selectable
      >
        {groupMembership?.map(({ id, group, ...groupMembership }, index) => (
          <GroupsTableRow
            key={id}
            index={index}
            groupMembership={group?.groupMembership?.edges}
            totalGroupMembershipCount={group?.groupMembership?.totalCount || 0}
            totalGroupProjectAccessCount={
              group?.groupProjectAccess?.totalCount || 0
            }
            id={group?.id}
            name={group?.name}
            description={group?.description}
            {...groupMembership}
          />
        ))}
      </SNTable>
    </Box>
  );
};

export default UserDetailGroupsTable;
