import {
  atom,
  atomFamily,
  DefaultValue,
  selector,
  selectorFamily,
} from 'recoil';

export const shiftKeyAtom = atom({
  key: 'shift-key-down',
  default: false,
});

export const lastSelectedIndexAtom = atom<number | null>({
  key: 'last-selected-index',
  default: null,
});

export const isSelectedIdAtom = atomFamily({
  key: 'is-selected-id',
  default: false,
});

export const selectedIdsAtom = atom<string[]>({
  key: 'selected-ids',
  default: [],
});

export const selectedTotalSelector = selector({
  key: 'selected-total',
  get: ({ get }) => get(selectedIdsAtom).length,
});

export const pageIdsAtom = atom<string[]>({
  key: 'page-ids',
  default: [],
});

export const pageTotalSelector = selector({
  key: 'page-total',
  get: ({ get }) => get(pageIdsAtom).length,
});

export const selectAllResultsAtom = atom({
  key: 'select-all-results',
  default: false,
});

export const selectAllResultsSelector = selector<boolean>({
  key: 'select-all-results-selector',
  get: ({ get }) => get(selectAllResultsAtom),
  set: ({ get, reset, set }, selectAll) => {
    if (selectAll instanceof DefaultValue) {
      reset(selectAllResultsAtom);
      reset(selectedIdsSelector);
    } else {
      set(selectAllResultsAtom, selectAll);
      if (selectAll) {
        const pageIds = get(pageIdsAtom);
        set(selectedIdsSelector, pageIds);
      } else {
        reset(selectedIdsSelector);
      }
    }
  },
});

export const listItemByIndexSelector = selectorFamily<boolean, number>({
  key: 'list-item-by-index-selector',
  get:
    (index) =>
    ({ get }) => {
      const pageIds = get(pageIdsAtom);
      const rowId = pageIds[index];
      const selected = get(isSelectedIdAtom(rowId));
      return selected;
    },
  set:
    (index) =>
    ({ get, reset, set }, selected) => {
      if (selected instanceof DefaultValue) {
        const pageIds = get(pageIdsAtom);
        const rowId = pageIds[index];
        reset(isSelectedIdAtom(rowId));
        set(selectedIdsAtom, (prev) => prev.filter((id) => id !== rowId));
      } else {
        set(selectAllResultsAtom, false);
        const shiftKey = get(shiftKeyAtom);
        const pageIds = get(pageIdsAtom);
        const lastSelectedIndex = get(lastSelectedIndexAtom);
        let selectedIds: string[] = [];
        if (shiftKey && typeof lastSelectedIndex === 'number') {
          selectedIds =
            index < lastSelectedIndex
              ? pageIds.slice(index, lastSelectedIndex + 1)
              : pageIds.slice(lastSelectedIndex, index + 1);
        } else {
          selectedIds = [pageIds[index]];
        }
        selectedIds.forEach((rowId) => {
          set(isSelectedIdAtom(rowId), selected);
        });
        set(selectedIdsAtom, (prev) =>
          selected
            ? [...new Set([...prev, ...selectedIds])]
            : prev.filter((id) => !selectedIds.includes(id)),
        );
        set(lastSelectedIndexAtom, index);
      }
    },
});

export const selectedIdsSelector = selector<string[]>({
  key: 'selected-ids-selector',
  get: ({ get }) => get(selectedIdsAtom),
  set: ({ get, reset, set }, selectedIds) => {
    if (selectedIds instanceof DefaultValue) {
      const currentIds = get(selectedIdsAtom);
      currentIds.forEach((rowId) => {
        set(isSelectedIdAtom(rowId), false);
      });
      reset(selectedIdsAtom);
    } else {
      selectedIds.forEach((rowId) => {
        set(isSelectedIdAtom(rowId), true);
      });
      set(selectedIdsAtom, (prev) => [...new Set([...prev, ...selectedIds])]);
    }
  },
});
