import gql from 'graphql-tag';

export default gql`
  enum SearchFilterValueType {
    BOOLEAN
    DATE
    NUMBER
    STRING
  }

  enum FileUploadStatus {
    IN_PROGRESS
    COMPLETE
  }

  input ActiveUploadInput {
    id: ID!
    fileName: String!
    fileType: String!
    status: FileUploadStatus!
  }

  type ActiveUpload {
    id: ID!
    fileName: String!
    fileType: String!
    status: FileUploadStatus!
  }

  extend type EntityFieldValidation {
    displayStatus: AnnotationSeverity
    name: String
  }

  extend type EntityValidations {
    displayStatus: AnnotationSeverity
  }

  extend type EntityType {
    searchTargetType: SearchTargetType!
    size: Int!
  }

  extend type LinkType {
    searchTargetType: SearchTargetType!
  }

  extend type DataFieldDefinition {
    type: SearchFilterValueType!
  }

  extend type DataPoint {
    UIType: SearchFilterValueType!
  }

  extend type PageInfo {
    endIndex: Int!
    startIndex: Int!
  }

  extend type ProjectAccess {
    hasDirectAccess: Boolean!
  }

  extend type Mutation {
    activeUploadAddRemove(activeUpload: ActiveUploadInput!): [ActiveUpload!]!
    activeUploadsSet(activeUploads: [ActiveUploadInput!]!): [ActiveUpload!]!
    activeUploadUpdate(activeUpload: ActiveUploadInput!): [ActiveUpload!]!
  }
  extend type Query {
    activeUploads: [ActiveUpload!]!
    entitiesById(ids: [String!]!): [Entity!]!
    entity(id: ID!): Entity!
  }
`;
