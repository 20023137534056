import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import Checkbox from '../Checkbox';
import { useRecoilState } from 'recoil';
import { listItemByIndexSelector } from '../../atoms/listSelection';
import SNTableRow from '../SNTableRow';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

interface GroupProjectTableRowProps {
  id?: string;
  name?: string | null;
  description?: string | null;
  createdAt: string;
  index: number;
}

const GroupProjectTableRow = ({
  name,
  id,
  description,
  createdAt,
  index,
}: GroupProjectTableRowProps) => {
  const [selected, selectRow] = useRecoilState(listItemByIndexSelector(index));

  const handleRowSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      selectRow(checked);
    },
    [selectRow],
  );

  return (
    <SNTableRow selected={selected} key={id}>
      <td>
        <Box display="block" pl={4} pr={3}>
          <Checkbox checked={selected} onChange={handleRowSelect} />
        </Box>
      </td>
      <td>
        <Link to={`/settings/projects/${id}`} component={RouterLink}>
          <Box display="flex" alignItems="center" height={56}>
            <Box fontWeight="bold">
              <Typography>{name}</Typography>
            </Box>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/projects/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <Box overflow="hidden" textOverflow="ellipsis">
              <Typography>{description}</Typography>
            </Box>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/projects/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <Typography>{moment(createdAt).format('YYYY-MM-DD')}</Typography>
          </Box>
        </Link>
      </td>
    </SNTableRow>
  );
};

export default GroupProjectTableRow;
