import React from 'react';
import { Box, Typography } from '@mui/material';
import ExtensionIcon from '../ExtensionIcon';
import CheckIcon from '@mui/icons-material/Check';
import { useRecoilValue } from 'recoil';
import {
  uploadFilesByIdAtom,
  uploadSubsetsSelector,
  uploadLinkingContextsAtom,
  uploadLinksAtom,
  uploadMetadataSelector,
  uploadProjectsAtom,
} from '../../atoms/uploadFiles';

interface UploadFileSummaryProps {
  fileName: string;
  fileId: string;
  isInLinkingContext: boolean;
  isLinked: boolean;
}

const UploadFileSummary = ({
  fileId,
  fileName,
  isInLinkingContext,
  isLinked,
}: UploadFileSummaryProps) => {
  const metadataById = useRecoilValue(uploadMetadataSelector(fileId));
  const subsets = useRecoilValue(uploadSubsetsSelector(fileId));
  const projects = useRecoilValue(uploadProjectsAtom);

  const addedToProjects = React.useMemo(() => {
    const allProjects = Object.entries(projects);
    let inProject = false;
    allProjects.forEach((project) => {
      if (project[1].filesInProject.includes(fileId)) {
        inProject = true;
      }
    });
    return inProject;
  }, [fileId, projects]);

  const hasSheetsSelected = React.useMemo(
    () => subsets && Object.values(subsets).some((checked) => checked),
    [subsets],
  );

  const hasMetadata = React.useMemo(
    () =>
      metadataById &&
      Object.values(metadataById).some((metadata) => metadata.value),
    [metadataById],
  );

  const uploadSummaryBullet = (text: string) => {
    return (
      <Box p={1} pl={5} display="flex" alignItems="center">
        <Box pr={2} color="text.secondary" display="flex" alignItems="center">
          <CheckIcon fontSize="small" color="inherit" />
        </Box>
        <Typography color="textSecondary">{text}</Typography>
      </Box>
    );
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height={44}
      >
        <Box display="flex" alignItems="center">
          <Box pr={2} display="flex" alignItems="center">
            <ExtensionIcon fileName={fileName} />
          </Box>
          <Typography variant="h5">{fileName}</Typography>
        </Box>
      </Box>
      {hasSheetsSelected && uploadSummaryBullet('Sheets selected')}
      {hasMetadata && uploadSummaryBullet('Metadata added')}
      {isInLinkingContext && uploadSummaryBullet('Part of a linking context')}
      {isLinked && uploadSummaryBullet('Links added')}
      {addedToProjects && uploadSummaryBullet('Added to additional projects')}
    </Box>
  );
};

const UploadModalSummary = () => {
  const filesById = useRecoilValue(uploadFilesByIdAtom);
  const linkingContexts = useRecoilValue(uploadLinkingContextsAtom);
  const links = useRecoilValue(uploadLinksAtom);

  const isInLinkingContext = React.useCallback(
    (fileId: string) =>
      linkingContexts.some((context) =>
        context.selectedUploadIds.includes(fileId),
      ),
    [linkingContexts],
  );

  const isLinked = React.useCallback(
    (fileId: string) =>
      links.some((link) => link.selectedUploadIds.includes(fileId)),
    [links],
  );

  return (
    <Box py={3}>
      {Object.entries(filesById).map(([fileId, file]) => (
        <UploadFileSummary
          key={fileId}
          fileId={fileId}
          fileName={file.name}
          isInLinkingContext={isInLinkingContext(fileId)}
          isLinked={isLinked(fileId)}
        />
      ))}
    </Box>
  );
};

export default UploadModalSummary;
