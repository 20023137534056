import { Box, ListItemText, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { useAddGlobalFilter } from '../../hooks';
import { generateGlobalStatusFilters } from '../../utils/index';
import Dropdown from '../Dropdown';
import Checkbox from '../Checkbox';
import { AnnotationSeverity, ProcessingState } from '../../types/schemaTypes';

interface Option {
  primary: string;
  secondary?: string;
  value: AnnotationSeverity | ProcessingState;
}

const reviewOptions: Option[] = [
  {
    primary: 'Invalid data',
    secondary: 'Data that does not meet established rules',
    value: AnnotationSeverity.ERROR,
  },
  {
    primary: ' Anomalous data',
    secondary: 'Data that deviates from what is normal or expected',
    value: AnnotationSeverity.WARNING,
  },
  {
    primary: 'Unclassified data',
    secondary: 'Data that did not match an established shape',
    value: ProcessingState.UNCLASSIFIED,
  },
];

const searchOptions: Option[] = [
  {
    primary: 'Complete data',
    secondary: 'Data that has passed validation or been approved',
    value: ProcessingState.COMPLETED,
  },
];

const labelValue: Record<string, string> = {
  [AnnotationSeverity.ERROR]: 'invalid',
  [AnnotationSeverity.WARNING]: 'anomalous',
  [ProcessingState.UNCLASSIFIED]: 'unclassified',
  [ProcessingState.COMPLETED]: 'complete',
};

interface SearchFilterDropdownProps {
  searchId: string;
  isReview?: boolean;
}

const reviewFilters = [
  AnnotationSeverity.ERROR,
  AnnotationSeverity.WARNING,
  ProcessingState.UNCLASSIFIED,
];

const searchFilters = [ProcessingState.COMPLETED];

export const SearchFilterDropdown = ({
  searchId,
  isReview,
}: SearchFilterDropdownProps) => {
  const [checked, setChecked] = React.useState<string[]>(
    isReview ? reviewFilters : searchFilters,
  );
  const setGlobalFilterInContext = useAddGlobalFilter(searchId);

  React.useEffect(() => {
    const statusFilters = generateGlobalStatusFilters(checked);
    setGlobalFilterInContext('statusFilter', { ...statusFilters });
  }, [checked, setGlobalFilterInContext]);

  const handleToggle = (value: Option['value']) => () => {
    const currentIndex = checked.indexOf(value);
    if (checked.length === 1 && checked[currentIndex]) {
      return null;
    }
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const label = (values: string[]) => {
    const labels = values.map((value) => labelValue[value]);
    if (labels.length === 0) {
      return 'No filters selected';
    }
    if (labels.length === 1) {
      return `Showing ${labels[0]} data`;
    }
    return `Showing ${labels.slice(0, labels.length - 1).join(', ')}${
      labels.length > 1 ? ` and ${labels[labels.length - 1]}` : undefined
    } data`;
  };

  const options = isReview ? reviewOptions : searchOptions;

  return (
    <Box pl={4}>
      <Dropdown
        label={<Typography variant="h3">{label(checked)}</Typography>}
        snVariant="underline"
      >
        {options.map(({ primary, secondary, value }) => (
          <MenuItem key={value} disableRipple onClick={handleToggle(value)}>
            <Box pr={2}>
              <Checkbox
                tabIndex={-1}
                disableRipple
                checked={checked.indexOf(value) !== -1}
              />
            </Box>
            <Box pr={2}>
              <ListItemText primary={primary} secondary={secondary} />
            </Box>
          </MenuItem>
        ))}
      </Dropdown>
    </Box>
  );
};

export default SearchFilterDropdown;
