import React from 'react';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import CollapsePanel from '../CollapsePanel';
import RoleMatrix from '../RoleMatrix';
import SNDialogTitle from '../SNDialogTitle';

interface RolesExplanationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const RolesExplanationModal = ({
  setOpen,
  open,
}: RolesExplanationModalProps) => {
  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <SNDialogTitle onClose={handleModalClose}>
        Roles and Permissions
      </SNDialogTitle>
      <DialogContent>
        <Box my={2}>
          <CollapsePanel
            open={false}
            name={
              <Box display="flex" flexDirection="column" my={2}>
                <Box mb={1}>
                  <Typography variant="h4">Guest</Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  Guests can upload and review their own data
                </Typography>
              </Box>
            }
          >
            <Box display="flex" justifyContent="center">
              <RoleMatrix
                uploadCreate
                uploadRead
                uploadEdit
                uploadLink
                reviewRead
                reviewEdit
                reviewLink
              />
            </Box>
          </CollapsePanel>
        </Box>
        <Box my={2}>
          <CollapsePanel
            open={false}
            name={
              <Box display="flex" flexDirection="column" my={2}>
                <Box mb={1}>
                  <Typography variant="h4">Reader</Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  Readers can upload, review, and search. They have read-only
                  access to project data
                </Typography>
              </Box>
            }
          >
            <Box display="flex" justifyContent="center">
              <RoleMatrix
                uploadCreate
                uploadRead
                uploadEdit
                uploadLink
                reviewRead
                reviewEdit
                reviewLink
                searchCreate
                searchRead
                dataViewsCreate
                dataViewsEdit
                dataViewsRead
                projectsRead
              />
            </Box>
          </CollapsePanel>
        </Box>
        <Box my={2}>
          <CollapsePanel
            open={false}
            name={
              <Box display="flex" flexDirection="column" my={2}>
                <Box mb={1}>
                  <Typography variant="h4">Collaborator</Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  Collaborators can upload, review, and search. They can also
                  edit project data
                </Typography>
              </Box>
            }
          >
            <Box display="flex" justifyContent="center">
              <RoleMatrix
                uploadCreate
                uploadRead
                uploadEdit
                uploadLink
                reviewRead
                reviewEdit
                reviewLink
                reviewCreate
                searchCreate
                searchRead
                searchEdit
                searchLink
                dataViewsCreate
                dataViewsEdit
                dataViewsRead
                projectsRead
              />
            </Box>
          </CollapsePanel>
        </Box>
        <Box my={2}>
          <CollapsePanel
            open={false}
            name={
              <Box display="flex" flexDirection="column" my={2}>
                <Box mb={1}>
                  <Typography variant="h4">Approver</Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  Approvers can upload, review, and search. They can also edit
                  and approve project data
                </Typography>
              </Box>
            }
          >
            <Box display="flex" justifyContent="center">
              <RoleMatrix
                uploadCreate
                uploadRead
                uploadEdit
                uploadLink
                reviewRead
                reviewEdit
                reviewLink
                reviewCreate
                reviewApprove
                searchCreate
                searchRead
                searchEdit
                searchLink
                searchApprove
                dataViewsCreate
                dataViewsEdit
                dataViewsRead
                projectsRead
              />
            </Box>
          </CollapsePanel>
        </Box>
        <Box my={2}>
          <CollapsePanel
            open={false}
            name={
              <Box display="flex" flexDirection="column" my={2}>
                <Box mb={1}>
                  <Typography variant="h4">owner</Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  Owners have full access to data and features, with the
                  exception of admin-only controls.
                </Typography>
              </Box>
            }
          >
            <Box display="flex" justifyContent="center">
              <RoleMatrix
                uploadCreate
                uploadRead
                uploadEdit
                uploadLink
                reviewRead
                reviewEdit
                reviewLink
                reviewCreate
                reviewApprove
                searchCreate
                searchRead
                searchEdit
                searchLink
                searchApprove
                projectsRead
                projectsCreate
                projectsEdit
                dataViewsCreate
                dataViewsEdit
                dataViewsRead
                usersRead
              />
            </Box>
          </CollapsePanel>
        </Box>
        <Box my={2}>
          <CollapsePanel
            open={false}
            name={
              <Box display="flex" flexDirection="column" my={2}>
                <Box mb={1}>
                  <Typography variant="h4">admin</Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  Admins have full access to data and features. They can also
                  manage users.
                </Typography>
              </Box>
            }
          >
            <Box display="flex" justifyContent="center">
              <RoleMatrix
                uploadCreate
                uploadRead
                uploadEdit
                uploadLink
                reviewRead
                reviewEdit
                reviewLink
                reviewCreate
                reviewApprove
                searchCreate
                searchRead
                searchEdit
                searchLink
                searchApprove
                dataViewsCreate
                dataViewsEdit
                dataViewsRead
                projectsRead
                projectsCreate
                projectsEdit
                usersRead
                usersCreate
                usersEdit
              />
            </Box>
          </CollapsePanel>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RolesExplanationModal;
