import { Box, Typography } from '@mui/material';
import React from 'react';
import { NoResults as NoResultsIcon } from '../SvgIcon';

interface NoResultsMessageProps {
  title?: string;
  message?: string;
}

const NoResultsMessage = ({
  title = 'No results',
  message = 'Unable to locate any records matching your search',
}: NoResultsMessageProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      position="absolute"
      pb={9}
      data-testid="no-results-message"
    >
      <NoResultsIcon />
      <Box p={1}>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Box height={88}>
        <Box p={1}>
          <Typography color="textSecondary">{message}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NoResultsMessage;
