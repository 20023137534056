import React from 'react';
import { Location } from 'history';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { Prompt, useHistory } from 'react-router-dom';
import SNButton from '../SNButton';
import SNDialogTitle from '../SNDialogTitle';

interface SearchSavePromptProps {
  message: string;
  onNavigate: () => void;
  preventPush?: boolean;
  primary: string;
  shouldBlockNavigation: (location: Location) => boolean;
  title: string;
  when?: boolean | undefined;
}

const SearchSavePrompt: React.FC<SearchSavePromptProps> = ({
  message,
  onNavigate,
  preventPush,
  primary,
  shouldBlockNavigation,
  title,
  when,
}) => {
  const { push } = useHistory();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [lastLocation, setLastLocation] = React.useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };
  const navigate = React.useCallback(
    (path: string) => {
      onNavigate();
      !preventPush && push(path);
    },
    [onNavigate, preventPush, push],
  );
  React.useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Dialog open={modalVisible} onClose={closeModal}>
        <SNDialogTitle onClose={closeModal}>{title}</SNDialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <SNButton onClick={closeModal}>Cancel</SNButton>
          <SNButton snVariant="primary" onClick={handleConfirmNavigationClick}>
            {primary}
          </SNButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SearchSavePrompt;
