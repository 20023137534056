import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import styled from '../../services/styled';
import { useOnScreen } from '../../hooks/useOnScreen';
import StickyColumnHeading from '../StickyColumnHeading';
import ScrollingContainer from '../ScrollingContainer';
import LoadingOverlay from '../LoadingOverlay';
import ErrorResult from '../ErrorResult';
import { ApolloError } from '@apollo/client';
import NoResultsMessage from '../NoResultsMessage';

interface SNTableProps {
  avatar?: boolean;
  emptyTitle?: string;
  emptyMessage?: string;
  error?: ApolloError;
  hasResults: boolean;
  headers: ReactNode[];
  id: string;
  loading: boolean;
  rowCount: number;
  selectable?: boolean;
}

const StyledTable = styled('table')`
  width: 100%;
  border-collapse: collapse;
`;

const StyledScrollTracker = styled('tr')`
  transform: translateY(-34px); // table heading height
`;

const SNTable: React.FC<SNTableProps> = ({
  avatar,
  emptyTitle,
  emptyMessage,
  error,
  hasResults,
  headers,
  id,
  loading,
  rowCount,
  selectable,
  children,
}) => {
  const [ref, setRef] = React.useState<React.RefObject<HTMLTableRowElement>>({
    current: null,
  });
  const measuredRef = React.useCallback((node: HTMLTableRowElement | null) => {
    setRef({ current: node });
  }, []);
  const isOnScreen = useOnScreen(ref, {});
  const isOverlapping = rowCount > 0 && !isOnScreen;
  return (
    <Box position="relative">
      <ScrollingContainer>
        {loading && (
          <LoadingOverlay data-testid={`${id}-loading`} isLoading={loading} />
        )}
        {!error && hasResults && (
          <StyledTable>
            {rowCount > 0 && (
              <thead data-testid={`${id}-headers`}>
                <tr>
                  {selectable && (
                    <StickyColumnHeading
                      width={92}
                      isOverlapping={isOverlapping}
                    />
                  )}
                  {avatar && (
                    <StickyColumnHeading
                      width={40}
                      isOverlapping={isOverlapping}
                    />
                  )}
                  {headers.map((header, index) => (
                    <StickyColumnHeading
                      key={index}
                      isOverlapping={isOverlapping}
                      pl={!selectable && index === 0 ? 4 : 0}
                    >
                      {header}
                    </StickyColumnHeading>
                  ))}
                </tr>
              </thead>
            )}
            <tbody>
              <StyledScrollTracker ref={measuredRef} />
              {children}
              {Array.isArray(children) && children.length === 0 && (
                <tr>
                  <td colSpan={headers.length + (selectable ? 1 : 0)}>
                    <NoResultsMessage
                      title={emptyTitle}
                      message={emptyMessage}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </StyledTable>
        )}
        {error && <ErrorResult />}
      </ScrollingContainer>
    </Box>
  );
};

export default SNTable;
