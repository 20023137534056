import React from 'react';
import { Typography, Box } from '@mui/material';
import SNTabs from './SNTabs';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import ProfilePreferences from './ProfilePreferences';
import SNButton from './SNButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import { useUserState } from '../contexts';
import UserDetailProjectsTableReadOnly from './UserProjectsTableReadOnly';
import UserGroupsTableReadOnly from './UserGroupsTableReadOnly/UserGroupsTableReadOnly';

const ProfileTabs = () => {
  const user = useUserState();
  const { tab = 'preferences' } = useParams<{ tab?: string }>();
  const history = useHistory();

  const handleTabClick = React.useCallback(
    (tab: string) => () => {
      history.push(`/profile/${tab}`);
    },
    [history],
  );

  return (
    <Box>
      <Box ml={4} pt={1}>
        <Box m={1}>
          <SNButton
            startIcon={<ArrowBackIcon />}
            snVariant="underline"
            onClick={() => history.goBack()}
          >
            Back
          </SNButton>
        </Box>
        <Typography variant="h1">My Account</Typography>
      </Box>

      <SNTabs
        active={tab}
        onTabClick={handleTabClick}
        tabs={['preferences', 'projects', 'groups']}
      />
      <Switch>
        <Route path="/profile/projects">
          <UserDetailProjectsTableReadOnly desiredUserId={user.userId} />
        </Route>
        <Route path="/profile/preferences">
          <ProfilePreferences />
        </Route>
        <Route path="/profile/groups">
          <UserGroupsTableReadOnly
            desiredUserId={user.userId}
            allowSelection={false}
          />
        </Route>
        <Redirect to="/profile/preferences" />
      </Switch>
    </Box>
  );
};

export default ProfileTabs;
