import { atom, atomFamily, DefaultValue, selectorFamily } from 'recoil';

export const latestSearchAtom = atom({
  key: 'latest-search',
  default: 'new',
});

interface SearchTabs {
  activeTab: string;
  tabOrder: string[];
}

export const searchTabsAtom = atomFamily<SearchTabs, string>({
  key: 'search-tabs',
  default: {
    activeTab: '',
    tabOrder: [],
  },
});

export const searchTabsTabOrderSelector = selectorFamily<string[], string>({
  key: 'search-tabs-tab-order',
  get:
    (param) =>
    ({ get }) =>
      get(searchTabsAtom(param)).tabOrder,
  set:
    (param) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        set(searchTabsAtom(param), (prev) => ({
          ...prev,
          tabOrder: [],
        }));
      } else {
        set(searchTabsAtom(param), (prev) => ({
          ...prev,
          tabOrder: newValue,
        }));
      }
    },
});

export const searchTabsActiveTabSelector = selectorFamily<string, string>({
  key: 'search-tabs-active-tab',
  get:
    (param) =>
    ({ get }) =>
      get(searchTabsAtom(param)).activeTab,
  set:
    (param) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        set(searchTabsAtom(param), (prev) => ({
          ...prev,
          activeTab: '',
        }));
      } else {
        set(searchTabsAtom(param), (prev) => ({
          ...prev,
          activeTab: newValue,
        }));
      }
    },
});
