import React from 'react';
import {
  CustomCell,
  CustomRenderer,
  GridCellKind,
  ProvideEditorCallback,
} from '@glideapps/glide-data-grid';
import { Box } from '@mui/material';
import { drawTextCell } from './CustomCells';
import Pill from '../Pill';
import { useSetRecoilState } from 'recoil';
import { userMetadataModalOpenAtom } from '../../atoms/userMetadataModalOpenAtom';

interface MetadataCellProps {
  readonly kind: 'metadata-cell';
  readonly displayValue: string;
  readonly entityId: string;
}

type MetadataCell = CustomCell<MetadataCellProps>;
export type { MetadataCell as MetadataCellType };

const Editor: ReturnType<ProvideEditorCallback<MetadataCell>> = (props) => {
  const setUserMetadataModalOpen = useSetRecoilState(userMetadataModalOpenAtom);
  const { value } = props;

  const handleClick = React.useCallback(() => {
    setUserMetadataModalOpen({ open: true, entityId: value.data.entityId });
  }, [setUserMetadataModalOpen, value.data.entityId]);

  return (
    <Box minHeight={59} px={2} alignItems="center" display="flex">
      <Box component="label" textOverflow="ellipsis" overflow="hidden">
        <Pill snVariant="input" onClick={handleClick}>
          {value.data.displayValue}
        </Pill>
      </Box>
    </Box>
  );
};

const renderer: CustomRenderer<MetadataCell> = {
  isMatch: (cell: CustomCell): cell is MetadataCell =>
    (cell.data as any).kind === 'metadata-cell',
  draw: (args, cell) => {
    const { displayValue: value } = cell.data;
    return drawTextCell(args, {
      readonly: !cell.allowOverlay,
      value,
      status: null,
      textDecoration: cell.allowOverlay ? 'underline' : undefined,
    });
  },
  provideEditor: () => ({
    editor: Editor,
    disablePadding: true,
  }),
  kind: GridCellKind.Custom,
};

export default renderer;
