import React from 'react';
import { useFilterLeafUpdate } from '../../hooks';
import SNInput from '../SNInput';
import { MenuItem } from '@mui/material';

interface SearchFormFilterLeafInputBooleanProps {
  disabled: boolean;
  id: string;
  value: string;
}

const SearchFormFilterLeafInputBoolean = ({
  disabled,
  id,
  value,
}: SearchFormFilterLeafInputBooleanProps) => {
  const updateFilterLeaf = useFilterLeafUpdate();

  const handleBooleanChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    updateFilterLeaf(id, {
      value: [{ type: 'BOOLEAN', value: event.target.value }],
    });
  };

  return (
    <SNInput
      disabled={disabled}
      select
      value={value}
      onChange={handleBooleanChange}
      size="small"
      fullWidth
    >
      <MenuItem value="true">True</MenuItem>
      <MenuItem value="false">False</MenuItem>
    </SNInput>
  );
};

export default SearchFormFilterLeafInputBoolean;
