import React from 'react';
import { Dialog, DialogActions } from '@mui/material';
import ActionApproveData from '../ActionApproveData';
import ActionEditEntities from '../ActionEditEntities';
import UserMetadataModal from '../UserMetadataModal';
import { useRecoilValue } from 'recoil';
import { userMetadataModalOpenAtom } from '../../atoms/userMetadataModalOpenAtom';
import { userMetadataEditModalOpenAtom } from '../../atoms/userMetadataEditModalOpenAtom';
import MetadataEditModal from '../MetadataEditModal';
import ActionExportEntities from '../ActionExportEntities/ActionExportEntities';

interface SearchResultsTableActionsProps {
  isReview?: boolean;
  onEditSuccess?: () => void;
  page: number;
  pageTotal: number;
  searchId: string;
  tableId: string;
  typeId: string;
}

const SearchResultsTableActions = ({
  isReview,
  onEditSuccess,
  page,
  pageTotal,
  searchId,
  tableId,
  typeId,
}: SearchResultsTableActionsProps) => {
  const userMetadataModalOpen = useRecoilValue(userMetadataModalOpenAtom);
  const userMetadataEditModalOpen = useRecoilValue(
    userMetadataEditModalOpenAtom,
  );

  return (
    <>
      <DialogActions>
        <ActionEditEntities
          isReview
          onEditSuccess={onEditSuccess}
          pageTotal={pageTotal}
          typeId={typeId}
        />
        {isReview && (
          <ActionApproveData
            page={page}
            pageTotal={pageTotal}
            searchId={searchId}
            tableId={tableId}
          />
        )}
        <ActionExportEntities
          pageTotal={pageTotal}
          page={page}
          searchId={searchId}
          tableId={tableId}
          isReview={isReview || false}
        />
      </DialogActions>
      <Dialog open={userMetadataModalOpen.open} fullWidth maxWidth="sm">
        <UserMetadataModal />
      </Dialog>
      <Dialog open={userMetadataEditModalOpen.open} fullWidth maxWidth="sm">
        <MetadataEditModal />
      </Dialog>
    </>
  );
};

export default SearchResultsTableActions;
