import React from 'react';
import { FilterType } from '../../types';
import { useFilterLeafUpdate } from '../../hooks';
import isFilterValueType from '../../utils/isFilterValueType';
import SNInput from '../SNInput';

interface SearchFormFilterLeafProps {
  disabled: boolean;
  id: string;
  type: FilterType;
  value: string;
}

const SearchFormFilterLeafInput = ({
  disabled,
  id,
  type,
  value,
}: SearchFormFilterLeafProps) => {
  const [localValue, setLocalValue] = React.useState(value);
  const updateFilterLeaf = useFilterLeafUpdate();

  const handleSubmit = () => {
    if (localValue !== value && isFilterValueType(type)) {
      updateFilterLeaf(id, {
        value: [{ value: localValue, type }],
      });
    }
  };

  const handleBlur = () => {
    handleSubmit();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(event.currentTarget.value);
  };

  return (
    <SNInput
      disabled={disabled}
      size="small"
      id={id}
      value={localValue}
      onBlur={handleBlur}
      onChange={handleChange}
      type={type === 'NUMBER' ? 'number' : 'text'}
      fullWidth
    />
  );
};

export default SearchFormFilterLeafInput;
