import { Box, ListItemText, MenuItem } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import Dropdown from '../Dropdown';
import Checkbox from '../Checkbox';
import { SortOrder } from '../../types/schemaTypes';

interface Option {
  primary: string;
  secondary?: string;
  value: SortOrder;
}

const options: Option[] = [
  {
    primary: 'Newest First',
    secondary: '',
    value: SortOrder.desc,
  },
  {
    primary: 'Oldest First',
    secondary: '',
    value: SortOrder.asc,
  },
];

interface RecencyDirectionSelectorProps {
  direction: SortOrder;
  setDirection: Dispatch<SetStateAction<SortOrder>>;
  label: string;
}

const RecencyDirectionSelector = ({
  direction,
  setDirection,
  label,
}: RecencyDirectionSelectorProps) => {
  const handleToggle = (value: Option['value']) => () => {
    setDirection(value === SortOrder.asc ? SortOrder.asc : SortOrder.desc);
  };

  return (
    <Dropdown
      label={
        direction === SortOrder.asc
          ? `Viewing oldest ${label} first`
          : `Viewing newest ${label} first`
      }
      snVariant="text"
    >
      {options.map(({ primary, secondary, value }) => (
        <MenuItem key={value} disableRipple onClick={handleToggle(value)}>
          <Box pr={2}>
            <Checkbox
              tabIndex={-1}
              disableRipple
              checked={direction === value}
            />
          </Box>
          <Box pr={2}>
            <ListItemText primary={primary} secondary={secondary} />
          </Box>
        </MenuItem>
      ))}
    </Dropdown>
  );
};

export default RecencyDirectionSelector;
