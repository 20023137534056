import { GlideHeaderInfo } from '../types';
import { SearchFilterValueType } from '../types/schemaTypes';

export const SYSTEM_FIELD_HEADINGS: GlideHeaderInfo[] = [
  {
    title: 'Entity Name',
    id: 'system.displayName',
    width: 300,
    field: {
      type: SearchFilterValueType.STRING,
      inputOptions: {
        __typename: 'Autogenerated',
        displayOnEntry: true,
      },
    },
  },
  {
    title: 'Metadata',
    id: 'user.metadata',
    type: 'metadata',
    icon: 'metadata',
    field: {
      type: SearchFilterValueType.STRING,
      inputOptions: {
        __typename: 'Autogenerated',
        displayOnEntry: true,
      },
    },
  },
  {
    title: 'Source Type',
    id: 'system.sourceType',
    icon: 'snLogo',
    field: {
      type: SearchFilterValueType.STRING,
      inputOptions: {
        __typename: 'Autogenerated',
        displayOnEntry: true,
      },
    },
  },
  {
    title: 'Last Modified',
    id: 'system.lastModified',
    icon: 'snLogo',
    field: {
      type: SearchFilterValueType.DATE,
      inputOptions: {
        __typename: 'Autogenerated',
        displayOnEntry: true,
      },
    },
  },
  {
    title: 'Created By',
    id: 'system.createdBy',
    icon: 'snLogo',
    field: {
      type: SearchFilterValueType.STRING,
      inputOptions: {
        __typename: 'Autogenerated',
        displayOnEntry: true,
      },
    },
  },
  {
    title: 'Created On',
    id: 'system.createdOn',
    icon: 'snLogo',
    field: {
      type: SearchFilterValueType.DATE,
      inputOptions: {
        __typename: 'Autogenerated',
        displayOnEntry: true,
      },
    },
  },
];
