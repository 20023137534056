import { Avatar, Box } from '@mui/material';
import { PersonOutline } from '@mui/icons-material';
import React from 'react';

interface UserAvatarProps {
  className?: string;
  name?: string;
  picture?: string;
  size?: number;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  className,
  name,
  picture,
  size = 40,
}) => {
  if (picture) {
    return (
      <Box border={1} borderColor="transparent" borderRadius="50%">
        <Avatar
          title={name}
          alt={name}
          className={className}
          src={picture}
          sx={{
            height: size,
            width: size,
          }}
        />
      </Box>
    );
  }

  return (
    <PersonOutline
      className={className}
      color="primary"
      fontSize="inherit"
      titleAccess={name}
      viewBox="4 4 16 16"
    />
  );
};

export default UserAvatar;
