import { searchFormActions, useSearchFormDispatch } from '../contexts';
import { FilterValueType } from '../types';
import cuid from 'cuid';

interface UseFilterLeafAddOptions {
  parentId: string;
  type: FilterValueType;
  fieldId?: string;
  value?: string;
}

function useFilterLeafAdd() {
  const dispatch = useSearchFormDispatch();
  return ({ parentId, type, value, fieldId }: UseFilterLeafAddOptions) => {
    const newId = cuid();
    dispatch(
      searchFormActions.filter.leaf.add({
        id: newId,
        parentId,
        type,
        fieldId,
        value,
      }),
    );
    return newId;
  };
}

export default useFilterLeafAdd;
