import { gql, useApolloClient } from '@apollo/client';
import { Box, Dialog, Grid, Link, Paper, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import moment from 'moment';
import React from 'react';
import { useEntityDetailsQuery } from '../../queries/useEntityDetailsQuery';
import styled from '../../services/styled';
import { getMuiVariant } from '../../services/theme';
import LoadingOverlay from '../LoadingOverlay';
import ScrollingContainer from '../ScrollingContainer';
import SNButton from '../SNButton';
import { CurrentEntityType, DataType } from '../../types/schemaTypes';
import MetadataEditModal from '../MetadataEditModal';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userMetadataEditModalOpenAtom } from '../../atoms/userMetadataEditModalOpenAtom';
import ActionEditEntity from '../ActionEditEntity';
import { collectErrors } from '../../utils/collectErrors';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';

interface EntityDataProps {
  id: string;
}

const StyledPaper = styled(Paper)`
  border-radius: 0;
`;

const StyledTable = styled(Box)`
  border-collapse: collapse;
  overflow-wrap: anywhere;
`;

const StyledTableRow = styled(Box)`
  height: 40px;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const StyledTableCell = styled(Box)`
  border-color: ${({ theme }) => theme.palette.grey[600]};
  border-style: solid;
  border-width: 1px;
  vertical-align: top;
`;

export const EntityDataLabel: React.FC = ({ children }) => (
  <StyledTableCell component="td" whiteSpace="nowrap">
    <Box px={2} py="11px" color="grey.300">
      <Typography color="inherit" variant={getMuiVariant('label')}>
        {children}
      </Typography>
    </Box>
  </StyledTableCell>
);

export const EntityDataValue: React.FC = ({ children }) => (
  <StyledTableCell component="td">
    <Box px={2} py="11px">
      <Typography>{children}</Typography>
    </Box>
  </StyledTableCell>
);

export const EntityDataHiddenTableHead = () => (
  <Box component="thead" visibility="collapse">
    <tr>
      <Box component="th" width="0%">
        <Typography>Label</Typography>
      </Box>
      <Box component="th" width="100%">
        <Typography>Value</Typography>
      </Box>
    </tr>
  </Box>
);

const EntityData: React.FC<EntityDataProps> = ({ id }) => {
  const [metadataEditOpen, setMetadataEditOpen] = useRecoilState(
    userMetadataEditModalOpenAtom,
  );
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const client = useApolloClient();

  const { data, loading } = useEntityDetailsQuery({
    variables: { id },
  });

  const entityTypeId = data?.entityById?.data?.type.id;

  const entityTypeFieldsById = React.useMemo(() => {
    try {
      const entityWithFields = client.readFragment<CurrentEntityType>({
        id: `EntityType:${entityTypeId}`,
        fragment: gql`
          fragment CurrentEntityType on EntityType {
            id
            fields {
              id
              name
            }
          }
        `,
      });
      return (
        entityWithFields?.fields.reduce<Record<string, string>>(
          (result, field) => ({
            ...result,
            [field.id]: field.name,
          }),
          {},
        ) || {}
      );
    } catch (error) {
      return {};
    }
  }, [client, entityTypeId]);

  const handleOpenMetadataEditModal = () => {
    setMetadataEditOpen({ entityId: id, open: true });
  };

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.entityById?.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error fetching this entity',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <ScrollingContainer>
      <Box px={5} py={3} position="relative">
        <Grid container spacing={4}>
          <Grid item md={6} sm={12}>
            <StyledPaper>
              <Box
                px={3}
                py={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h3">Entity Data</Typography>
                <ActionEditEntity entityId={id} entityTypeId={entityTypeId} />
                <Dialog open={metadataEditOpen.open} fullWidth>
                  <MetadataEditModal />
                </Dialog>
              </Box>
              <Box px={3} pb={2.5}>
                <StyledTable
                  component="table"
                  width="100%"
                  bgcolor="background.default"
                >
                  <EntityDataHiddenTableHead />
                  <tbody>
                    {data?.entityById?.data?.fieldValues.map(
                      ({
                        value,
                        fieldDefinition: { id: fieldDefinitionId, dataPoint },
                      }) => (
                        <StyledTableRow key={fieldDefinitionId} component="tr">
                          <EntityDataLabel>
                            {entityTypeFieldsById[fieldDefinitionId]}
                          </EntityDataLabel>
                          {dataPoint.dataType === DataType.URL ? (
                            <EntityDataValue>
                              <StyledLink
                                target="_blank"
                                rel="noreferrer"
                                href={value}
                              >
                                {value}
                              </StyledLink>
                            </EntityDataValue>
                          ) : (
                            <EntityDataValue>{value}</EntityDataValue>
                          )}
                        </StyledTableRow>
                      ),
                    )}
                  </tbody>
                </StyledTable>
              </Box>
            </StyledPaper>
          </Grid>
          <Grid item md={6} sm={12}>
            <StyledPaper>
              <Box
                px={3}
                py={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h3">Upload Data</Typography>
              </Box>
              <Box px={3} pb={2.5}>
                <StyledTable
                  component="table"
                  width="100%"
                  bgcolor="background.default"
                >
                  <EntityDataHiddenTableHead />
                  <tbody>
                    {data?.entityById?.data?.createdBy.name && (
                      <StyledTableRow component="tr">
                        <EntityDataLabel>Uploaded By</EntityDataLabel>
                        <EntityDataValue>
                          {data.entityById.data.createdBy.name}
                        </EntityDataValue>
                      </StyledTableRow>
                    )}
                    {data?.entityById?.data?.createdOn && (
                      <StyledTableRow component="tr">
                        <EntityDataLabel>Upload Date</EntityDataLabel>
                        <EntityDataValue>
                          {moment(data.entityById.data.createdOn).format(
                            'YYYY-MM-DD',
                          )}
                        </EntityDataValue>
                      </StyledTableRow>
                    )}
                  </tbody>
                </StyledTable>
              </Box>
            </StyledPaper>
          </Grid>

          <Grid item md={6} sm={12}>
            <StyledPaper>
              <Box
                px={3}
                py={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h3">User Metadata</Typography>
                <SNButton
                  snVariant="text"
                  startIcon={<Edit />}
                  onClick={handleOpenMetadataEditModal}
                >
                  Edit
                </SNButton>
              </Box>
              <Box px={3} pb={2.5}>
                <StyledTable
                  component="table"
                  width="100%"
                  bgcolor="background.default"
                >
                  <EntityDataHiddenTableHead />
                  <tbody>
                    {data?.entityById?.data?.userMetadata.map(
                      ({ value, label }) => (
                        <StyledTableRow
                          key={`${label}:${value}`}
                          component="tr"
                        >
                          <EntityDataLabel>{label}</EntityDataLabel>
                          <EntityDataValue>{value}</EntityDataValue>
                        </StyledTableRow>
                      ),
                    )}
                  </tbody>
                </StyledTable>
              </Box>
            </StyledPaper>
          </Grid>
        </Grid>
      </Box>
      <LoadingOverlay id="entity-data-loading" isLoading={loading} />
    </ScrollingContainer>
  );
};

export default EntityData;
