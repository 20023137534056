import React from 'react';
import { Box, capitalize, Typography } from '@mui/material';
import { LayerProps } from 'react-laag';
import { UserMetadata } from '../../types';

type TooltipProps = Omit<LayerProps, 'ref'>;

interface AnnotationTooltipProps extends TooltipProps {
  metadata: UserMetadata[];
}

const MetadataTooltip = React.forwardRef<
  HTMLDivElement,
  AnnotationTooltipProps
>(({ metadata, ...rest }, ref) => {
  const shown = metadata.slice(0, 9);
  const described = metadata.slice(9);

  return (
    <Box px={2.5} py={1} bgcolor="grey.900" {...rest} ref={ref}>
      <ul>
        {shown.map((metadata) => (
          <li key={`${metadata.label}:${metadata.value}`}>
            <Typography>
              <Typography component="span" fontWeight={700}>
                {capitalize(metadata.label)}
              </Typography>
              :&nbsp;{metadata.value}
            </Typography>
          </li>
        ))}
        {described.length > 0 && (
          <li>
            <Typography>+ {described.length} more</Typography>
          </li>
        )}
      </ul>
    </Box>
  );
});

MetadataTooltip.displayName = 'MetadataTooltip';

export default MetadataTooltip;
