import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import Checkbox from '../Checkbox';
import { useRecoilState } from 'recoil';
import { listItemByIndexSelector } from '../../atoms/listSelection';
import moment from 'moment';
import SNTableRow from '../SNTableRow';
import UserAvatar from '../UserAvatar';
import { Link as RouterLink } from 'react-router-dom';

interface GroupUsersRowProps {
  id?: string;
  name?: string | null;
  email?: string;
  picture?: string | null;
  index: number;
  createdAt: string;
}

const GroupUsersRow = ({
  id,
  name,
  email,
  picture,
  index,
  createdAt,
}: GroupUsersRowProps) => {
  const [selected, selectRow] = useRecoilState(listItemByIndexSelector(index));

  const handleRowSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      selectRow(checked);
    },
    [selectRow],
  );

  return (
    <SNTableRow selected={selected}>
      <td>
        <Box pl={4} pr={3} position="relative">
          <Checkbox checked={selected} onChange={handleRowSelect} />
        </Box>
      </td>
      <td>
        <Link to={`/settings/users/${id}`} component={RouterLink}>
          <Box display="flex" alignItems="center" height={56} mr={2}>
            <UserAvatar name={name || ''} picture={picture || ''} />
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/users/${id}`} component={RouterLink}>
          <Box display="flex" alignItems="center" height={56}>
            <Box fontWeight={600}>
              <Typography>{name}</Typography>
            </Box>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/users/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <Box overflow="hidden" textOverflow="ellipsis">
              <Typography>{email || ''}</Typography>
            </Box>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/users/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <Typography>{moment(createdAt).format('YYYY-MM-DD')}</Typography>
          </Box>
        </Link>
      </td>
    </SNTableRow>
  );
};

export default GroupUsersRow;
