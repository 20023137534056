import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import { UsersOfProject, UsersOfProjectVariables } from '../types/schemaTypes';

export const USERS_OF_PROJECT = gql`
  query UsersOfProject(
    $id: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: UserFilter
  ) {
    project(id: $id) {
      project {
        id
        name
        description
        projectAccess(
          after: $after
          before: $before
          first: $first
          last: $last
          filter: $filter
        ) {
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
            endIndex @client
            startIndex @client
          }
          totalCount
          edges {
            cursor
            node {
              id
              userRole
              hasDirectAccess @client
              sources {
                id
                sourceType
              }
              user {
                id
                name
                picture
                email
                defaultRole
              }
              createdAt
            }
          }
        }
      }
      errors
    }
  }
`;

export const useLazyUsersOfProjectQuery = (
  options?: LazyQueryHookOptions<UsersOfProject, UsersOfProjectVariables>,
) => {
  return useLazyQuery<UsersOfProject, UsersOfProjectVariables>(
    USERS_OF_PROJECT,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );
};

export const useUsersOfProjectQuery = (
  options?: QueryHookOptions<UsersOfProject, UsersOfProjectVariables>,
) => {
  return useQuery<UsersOfProject, UsersOfProjectVariables>(USERS_OF_PROJECT, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};
