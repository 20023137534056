import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import ProjectsTable from '../../components/ProjectsTable';
import GroupsTable from '../../components/GroupsTable';
import UsersTable from '../../components/UsersTable';
import SNTabs from '../../components/SNTabs';
import ListsTable from '../../components/ListsTable';
import EntityTypesTable from '../../components/EntityTypesTable';

const settingTabsTypesArray = [
  'projects',
  'users',
  'groups',
  'lists',
  'entity types',
];

const SettingsTabs = () => {
  const { tab = '' } = useParams<{ tab?: string }>();
  const history = useHistory();

  const handleTabClick = React.useCallback(
    (tab: string) => () => {
      history.push(`/settings/${tab.replace(/\s+/g, '')}`);
    },
    [history],
  );

  return (
    <div>
      <SNTabs
        active={tab}
        onTabClick={handleTabClick}
        tabs={settingTabsTypesArray}
      />
      <Switch>
        <Route path="/settings/projects">
          <ProjectsTable />
        </Route>
        <Route path="/settings/users">
          <UsersTable />
        </Route>
        <Route path="/settings/groups">
          <GroupsTable />
        </Route>
        <Route path="/settings/lists">
          <ListsTable />
        </Route>
        <Route path="/settings/entitytypes">
          <EntityTypesTable />
        </Route>
        <Redirect to="/settings/projects" />
      </Switch>
    </div>
  );
};

export default SettingsTabs;
