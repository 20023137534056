import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import Checkbox from '../Checkbox';
import { useRecoilState } from 'recoil';
import { listItemByIndexSelector } from '../../atoms/listSelection';
import moment from 'moment';
import { UserRole } from '../../types/schemaTypes';
import SNTableRow from '../SNTableRow';
import UserAvatar from '../UserAvatar';
import { Link as RouterLink } from 'react-router-dom';
import RoleDropdown from '../RoleDropdown';
import pluralize from 'pluralize';
import ListSummaryButton from '../ListSummaryButton';
import { useUpdateProjectAccessMutation } from '../../mutations';
import { useIsUserOwnerOf } from '../../hooks';

interface ProjectUsersTableRowProps {
  createdAt: string;
  email?: string;
  hasDirectAccess: boolean;
  userId?: string;
  projectAccessId?: string;
  index: number;
  name?: string | null;
  picture?: string | null;
  sourceCount: number;
  defaultRole?: UserRole;
  userRole: UserRole;
  projectId: string;
}

const ProjectUsersTableRow = ({
  createdAt,
  email,
  hasDirectAccess,
  userId,
  projectAccessId,
  defaultRole,
  userRole,
  name,
  picture,
  index,
  sourceCount,
  projectId,
}: ProjectUsersTableRowProps) => {
  const [selected, selectRow] = useRecoilState(listItemByIndexSelector(index));
  const [changeUserRole] = useUpdateProjectAccessMutation();
  const isOwner = useIsUserOwnerOf(projectId);

  const handleRowSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      selectRow(checked);
    },
    [selectRow],
  );

  const handleProjectRoleChange = (userId: string, newRole: UserRole) => {
    if (projectAccessId) {
      changeUserRole({
        variables: {
          input: {
            id: projectAccessId,
            userRole: newRole,
          },
        },
      });
    }
  };

  const groupCount = sourceCount - (hasDirectAccess ? 1 : 0);

  return (
    <SNTableRow selected={selected} key={userId}>
      <td>
        <Box pl={4} pr={3} position="relative">
          <Checkbox checked={selected} onChange={handleRowSelect} />
        </Box>
      </td>
      <td>
        <Link to={`/settings/users/${userId}`} component={RouterLink}>
          <Box display="flex" alignItems="center" height={56} mr={2}>
            <UserAvatar name={name || ''} picture={picture || ''} />
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/users/${userId}`} component={RouterLink}>
          <Box display="flex" alignItems="center" height={56}>
            <Box fontWeight={600}>
              <Typography>{name}</Typography>
            </Box>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/users/${userId}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <Box overflow="hidden" textOverflow="ellipsis">
              <Typography>{email}</Typography>
            </Box>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/users/${userId}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <RoleDropdown
              id={userId}
              currentRole={userRole}
              defaultRole={defaultRole}
              onRoleChange={handleProjectRoleChange}
              disabled={!isOwner}
            />
          </Box>
        </Link>
      </td>
      <td>
        <Link
          to={`/settings/users/${userId}${groupCount > 0 ? '/groups' : ''}`}
          component={RouterLink}
        >
          <Box height={56} display="flex" alignItems="center">
            {hasDirectAccess && (
              <ListSummaryButton variant="text">Direct</ListSummaryButton>
            )}
            {groupCount > 0 && (
              <Box ml={hasDirectAccess ? 1 : 0}>
                <ListSummaryButton variant="text">
                  {hasDirectAccess && '+'} {groupCount}{' '}
                  {pluralize('Group', groupCount)}
                </ListSummaryButton>
              </Box>
            )}
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/users/${userId}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <Typography>{moment(createdAt).format('YYYY-MM-DD')}</Typography>
          </Box>
        </Link>
      </td>
    </SNTableRow>
  );
};

export default ProjectUsersTableRow;
