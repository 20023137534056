import { Dialog } from '@mui/material';
import cuid from 'cuid';
import React from 'react';
import {
  useCompleteSearchObject,
  useSaveSearchDispatch,
  useSaveSearchState,
  useUserState,
} from '../../contexts';
import { useAddSavedSearchMutation } from '../../mutations';
import { SavedSearchType } from '../../types/schemaTypes';
import { searchInputTransform } from '../../utils';
import { createCacheInvalidator } from '../../utils/invalidateCache';
import SearchSave from './SearchSave';
import SNDialogTitle from '../SNDialogTitle';
import { collectErrors } from '../../utils/collectErrors';

interface SearchSaveAddProps {
  defaultName?: string;
  defaultDescription?: string;
  onSaveComplete: (id: string) => void;
  searchId: string;
  title?: string;
  type?: SavedSearchType;
}

const SearchSaveAdd = ({
  defaultName = '',
  defaultDescription = '',
  onSaveComplete,
  searchId,
  title = 'Save Search As',
  type = SavedSearchType.SEARCH,
}: SearchSaveAddProps) => {
  const user = useUserState();
  const saveType = useSaveSearchState();
  const setSaveSearchState = useSaveSearchDispatch();
  const searchState = useCompleteSearchObject(searchId);
  const handleClose = React.useCallback(() => {
    setSaveSearchState(null);
  }, [setSaveSearchState]);

  const [addSavedSearch, { loading, data }] = useAddSavedSearchMutation({
    onCompleted: (data) => {
      if (data.addSavedSearch.data) {
        onSaveComplete(data.addSavedSearch.data.id);
        handleClose();
      }
    },
    update: createCacheInvalidator(/^SavedSearch/),
  });

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.addSavedSearch.errors]);
  }, [data]);

  const handleSubmit = ({
    description,
    isPublic,
    name,
  }: {
    description: string;
    isPublic: boolean;
    name: string;
  }) => {
    const searchQuery = searchInputTransform(searchState);
    const id = cuid();
    addSavedSearch({
      variables: {
        input: {
          id,
          name,
          description,
          public: isPublic,
          type,
          userId: user.userId,
          userName: user.name,
          searchQuery,
        },
      },
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={saveType === 'SAVE_AS'}
      onClose={handleClose}
    >
      <SNDialogTitle onClose={handleClose}>{title}</SNDialogTitle>
      <SearchSave
        loading={loading}
        onSubmit={handleSubmit}
        name={defaultName}
        description={defaultDescription}
        isPublic={false}
        errors={collectedErrors}
      />
    </Dialog>
  );
};

export default SearchSaveAdd;
