import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  ControlledVocabularyCategory,
  ControlledVocabularyCategoryVariables,
} from '../types/schemaTypes';

export const LIST = gql`
  query ControlledVocabularyCategory(
    $id: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: CVTermFilter
  ) {
    getControlledVocabularyCategory(id: $id) {
      data {
        id
        name
        description
        updatedAt
        createdAt
        items(
          first: $first
          before: $before
          last: $last
          after: $after
          filter: $filter
        ) {
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
            endIndex @client
            startIndex @client
          }
          totalCount
          edges {
            cursor
            node {
              id
              value
              description
              updatedAt
              createdAt
              category {
                name
                id
              }
            }
          }
          totalCount
        }
      }
      errors
    }
  }
`;

export const useGetListQuery = (
  options: QueryHookOptions<
    ControlledVocabularyCategory,
    ControlledVocabularyCategoryVariables
  >,
) => {
  return useQuery<
    ControlledVocabularyCategory,
    ControlledVocabularyCategoryVariables
  >(LIST, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};
