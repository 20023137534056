import { FieldPolicy, Reference } from '@apollo/client';
import { __rest } from 'tslib';

type TEdge<TNode> =
  | {
      cursor?: string;
      node: TNode;
    }
  | (Reference & { cursor?: string });

export type TPageInfo = {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string | null;
  startIndex: number;
  endCursor: string | null;
  endIndex: number;
};

export type TExistingRelay<TNode> = Readonly<{
  edges?: TEdge<TNode>[];
  totalCount?: null | number;
  pageInfo?: TPageInfo;
}>;

type TExistingNestedRelay<TNode> = Readonly<{
  data: TExistingRelay<TNode>;
}>;

type TSafeIncomingRelay<TNode> = {
  edges?: TEdge<TNode>[];
  pageInfo?: TPageInfo;
};

type TIncomingRelay<TNode> = {
  edges?: TEdge<TNode>[];
  pageInfo?: TPageInfo;
};

type TIncomingNestedRelay<TNode> = {
  data: TIncomingRelay<TNode>;
};

type RelayFieldPolicy<TNode> = FieldPolicy<
  TExistingRelay<TNode>,
  TIncomingRelay<TNode>,
  TIncomingRelay<TNode>
>;

type RelayNestedFieldPolicy<TNode> = FieldPolicy<
  TExistingNestedRelay<TNode>,
  TIncomingNestedRelay<TNode>,
  TIncomingNestedRelay<TNode>
>;

// Returns any unrecognized properties of the given object.
const notExtras = ['data', 'edges', 'pageInfo'];
const getExtras = (obj: Record<string, any>) => __rest(obj, notExtras);

export const isNestedConnection = (
  connection: TIncomingNestedRelay<any> | TIncomingRelay<any>,
): connection is TIncomingNestedRelay<any> => {
  return (connection as TIncomingNestedRelay<any>).data !== undefined;
};

export const readSlice = (
  existing: TExistingRelay<any>,
): TExistingRelay<any> => {
  if (existing.pageInfo && existing.edges) {
    const { endCursor, startCursor } = existing.pageInfo;
    const startIndex = startCursor
      ? existing.edges.findIndex((edge) => {
          if (!edge.cursor) {
            throw Error('No cursor provided. check your query');
          }
          return edge.cursor === startCursor;
        })
      : 0;
    let endIndex = endCursor
      ? existing.edges.findIndex((edge) => {
          if (!edge.cursor) {
            throw Error('No cursor provided. check your query');
          }
          return edge.cursor === endCursor;
        })
      : undefined;
    const edges = existing.edges.slice(
      startIndex,
      typeof endIndex === 'number' ? endIndex + 1 : undefined,
    );
    endIndex = startIndex + edges.length - 1;

    return {
      ...existing,
      totalCount:
        existing.totalCount === null && existing.pageInfo.hasNextPage === false
          ? endIndex + 1
          : existing.totalCount,
      pageInfo: {
        ...existing.pageInfo,
        endIndex,
        startIndex,
      },
      edges,
    };
  }
  return existing;
};

export const mergeRelayConnection = (
  existing: TExistingRelay<any> | undefined,
  incoming: TSafeIncomingRelay<any>,
  args: Record<string, any> | null,
): TExistingRelay<any> => {
  if (existing?.edges) {
    let prefix = existing.edges;
    let suffix: typeof prefix = [];

    if (args && args.after) {
      // This comparison does not need to use readField("cursor", edge),
      // because we stored the cursor field of any Reference edges as an
      // extra property of the Reference object.
      const index = prefix.findIndex((edge) => {
        if (!edge.cursor) {
          throw Error('No cursor provided. check your query');
        }
        return edge.cursor === args.after;
      });
      if (index >= 0) {
        prefix = prefix.slice(0, index + 1);
        // suffix = []; // already true
      }
    } else if (args && args.before) {
      const index = prefix.findIndex((edge) => {
        if (!edge.cursor) {
          throw Error('No cursor provided. check your query');
        }
        return edge.cursor === args.before;
      });
      suffix = index < 0 ? prefix : prefix.slice(index);
      prefix = prefix.slice(0, index - args.last);
    } else if (incoming.edges) {
      // If we have neither args.after nor args.before, the incoming
      // edges cannot be spliced into the existing edges, so they must
      // replace the existing edges. See #6592 for a motivating example.
      prefix = [];
    }

    const edges = [...prefix, ...(incoming.edges || []), ...suffix];

    const pageInfo = {
      // The ordering of these two ...spreads may be surprising, but it
      // makes sense because we want to combine PageInfo properties with a
      // preference for existing values, *unless* the existing values are
      // overridden by the logic below, which is permitted only when the
      // incoming page falls at the beginning or end of the data.
      ...existing.pageInfo,
      ...incoming.pageInfo,
    };

    return {
      ...getExtras(existing),
      ...getExtras(incoming),
      edges,
      pageInfo,
    };
  }
  return incoming;
};

/**
 *
 * Adopted and adapted from https://github.com/apollographql/apollo-client/blob/02a78df36ab26af3dec71fb7b48c00d5836ed2cb/src/utilities/policies/pagination.ts#L95
 */
export const relayStylePagination = (
  keyArgs: FieldPolicy['keyArgs'],
): RelayFieldPolicy<any> => ({
  keyArgs,
  /**
   * In the read function we a mimicking offset-based pagination by returning slices
   * of the accumulated list of items
   */
  read(existing) {
    if (!existing) return;
    return readSlice(existing);
  },
  /**
   * We are accumulating values in the same style as infinite scroll in the merge function
   */
  merge(existing, incoming, { args, isReference, readField }) {
    const incomingEdges = incoming?.edges?.map((edge) => {
      if (isReference((edge = { ...edge }))) {
        // In case edge is a Reference, we read out its cursor field and
        // store it as an extra property of the Reference object.
        edge.cursor = readField<string>('cursor', edge);
      }
      return edge;
    });
    const safeIncoming = { ...incoming, edges: incomingEdges };
    return mergeRelayConnection(existing, safeIncoming, args);
  },
});

export const relayStylePaginationNested = (
  keyArgs: FieldPolicy['keyArgs'],
): RelayNestedFieldPolicy<any> => ({
  keyArgs,
  read(existing) {
    if (!existing) return;
    return {
      ...getExtras(existing),
      data: readSlice(existing.data),
    };
  },
  merge(existing, incoming, { args, isReference, readField }) {
    const incomingEdges = incoming?.data?.edges?.map((edge) => {
      if (isReference((edge = { ...edge }))) {
        // In case edge is a Reference, we read out its cursor field and
        // store it as an extra property of the Reference object.
        edge.cursor = readField<string>('cursor', edge);
      }
      return edge;
    });
    const safeIncoming = { ...incoming.data, edges: incomingEdges };
    return {
      ...getExtras(incoming),
      data: mergeRelayConnection(existing?.data, safeIncoming, args),
    };
  },
});
