import React from 'react';
import SearchFormFilterBranch from '../SearchFormFilterBranch';
import { isFilterGroup } from '../../utils';
import { useSearchFormState } from '../../contexts';
import SearchFormFilterLeaf from '../SearchFormFilterLeaf';
import { Box, MenuItem } from '@mui/material';
import { Connective } from '../../types/schemaTypes';
import { useFilterBranchUpdate, useFilterLeafUpdate } from '../../hooks';
import DropdownSelect from '../DropdownSelect';
import { CONNECTIVE_LABELS } from '../../constants';

interface SearchFormFilterProps {
  depth: number;
  id: string;
  isRoot?: boolean;
  index: number;
}

const SearchFormFilter = ({
  depth,
  id,
  index,
  isRoot,
  ...props
}: SearchFormFilterProps) => {
  const {
    filter: { filterBranches, filterLeaves },
  } = useSearchFormState();
  const updateFilterBranch = useFilterBranchUpdate();
  const updateFilterLeaf = useFilterLeafUpdate();
  const filter = filterBranches[id] || filterLeaves[id];
  const filterExists = Boolean(filter);

  const handleBranchConnectiveChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (filterExists) {
        updateFilterBranch({
          id,
          changes: {
            connective: event.target.value as Connective,
          },
        });
      }
    },
    [filterExists, id, updateFilterBranch],
  );

  const handleLeafConnectiveChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (filterExists) {
        updateFilterLeaf(id, {
          connective: event.target.value as Connective,
        });
      }
    },
    [filterExists, id, updateFilterLeaf],
  );

  if (!filter) {
    return null;
  }

  if (isFilterGroup(filter)) {
    return (
      <>
        {index !== 0 && (
          <Box px={depth < 2 ? 2 : 0} py={1}>
            <DropdownSelect
              onChange={handleBranchConnectiveChange}
              size="small"
              value={filter.connective}
            >
              <MenuItem value={Connective.AND}>
                <Box width={90}>{CONNECTIVE_LABELS[Connective.AND]}</Box>
              </MenuItem>
              <MenuItem value={Connective.OR}>
                <Box width={90}>{CONNECTIVE_LABELS[Connective.OR]}</Box>
              </MenuItem>
            </DropdownSelect>
          </Box>
        )}
        <Box pl={isRoot || depth < 2 ? 0 : 1}>
          <SearchFormFilterBranch
            depth={depth}
            id={id}
            isRoot={isRoot}
            index={index}
            {...props}
            {...filter}
          />
        </Box>
      </>
    );
  }

  return (
    <>
      {index !== 0 && (
        <Box px={depth < 2 ? 2 : 0} py={1}>
          <DropdownSelect
            onChange={handleLeafConnectiveChange}
            size="small"
            value={filter.connective}
          >
            <MenuItem value={Connective.AND}>
              <Box width={90}>{CONNECTIVE_LABELS[Connective.AND]}</Box>
            </MenuItem>
            <MenuItem value={Connective.OR}>
              <Box width={90}>{CONNECTIVE_LABELS[Connective.OR]}</Box>
            </MenuItem>
          </DropdownSelect>
        </Box>
      )}
      <Box
        width="100%"
        bgcolor="background.default"
        pl={depth < 2 ? 2 : 0}
        py={depth < 2 ? 2 : 0}
      >
        <SearchFormFilterLeaf id={id} {...props} {...filter} />
      </Box>
    </>
  );
};

export default SearchFormFilter;
