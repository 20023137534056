import React from 'react';
import MapIcon from '@mui/icons-material/Map';
import ExploreIcon from '@mui/icons-material/Explore';
import UploadModalQuestion from '../UploadModalQuestion';
import { Box, Checkbox, Typography } from '@mui/material';
import {
  useUploadWizardState,
  useUploadWizardDispatch,
  useUserState,
} from '../../contexts';
import { useUserQuery } from '../../queries';
import { Redirect } from 'react-router-dom';

interface UploadModalGuidePromptProps {
  // firstTab: 'sheets' | 'projects';
  onChange: (checked: boolean) => void;
  skipPrompt: boolean;
}

const UploadModalGuidePrompt: React.FC<UploadModalGuidePromptProps> = ({
  // firstTab,
  onChange,
  skipPrompt,
}) => {
  const wizardState = useUploadWizardState();
  const setWizardState = useUploadWizardDispatch();
  const user = useUserState();
  const { data } = useUserQuery({ variables: { id: user.userId } });

  React.useEffect(() => {
    const skipAllQuestions =
      data?.user?.user?.preferences?.uploadWizardSkipAllQuestions;
    setWizardState((prevState) => ({
      ...prevState,
      skipAllQuestions:
        typeof skipAllQuestions === 'boolean' ? skipAllQuestions : null,
    }));
  }, [data, setWizardState]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    onChange(checked);
  };

  if (!data || !data.user || !data.user.user) return null;

  if (data?.user?.user?.preferences?.uploadWizardSkipAllQuestionsPrompt) {
    return (
      <Redirect
        to={
          data.user.user.preferences.uploadWizardSkipAllQuestions
            ? `/projects/content`
            : `/projects/question`
        }
      />
    );
  }
  return (
    <UploadModalQuestion
      question="Next, we’ll classify and organize data. How would you like to
                  proceed?"
      field="skipAllQuestions"
      optionAText="Guide me"
      optionAIcon={<MapIcon fontSize="large" />}
      optionAVariant="light"
      optionAValue={false}
      optionBText="Let me explore"
      optionBIcon={<ExploreIcon fontSize="large" />}
      optionBVariant="light"
      optionBValue
    >
      <Box component="label" display="flex" alignItems="center" mt={4}>
        <Box pr={1}>
          <Checkbox
            disabled={wizardState.skipAllQuestions === null}
            checked={skipPrompt}
            onChange={handleChange}
          />
        </Box>
        <Typography>Remember my choice</Typography>
      </Box>
    </UploadModalQuestion>
  );
};

export default UploadModalGuidePrompt;
