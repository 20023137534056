import { Box } from '@mui/material';
import UploadModalProjectsPanel from '../UploadModalProjectsPanel/UploadModalProjectsPanel';
import SNButton from '../SNButton';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useGetProjectsQuery } from '../../queries/useGetProjectsQuery';
import {
  uploadFilesByIdAtom,
  uploadProjectsAtom,
} from '../../atoms/uploadFiles';
import cuid from 'cuid';
import { defaultProjectId } from '../../constants';

export interface ProjectInfo {
  projectId: string;
  projectName: string;
  filesInProject: string[];
}

export interface PossibleProject {
  projectId: string;
  projectName: string | null;
}

const UploadModalProjectSelection = () => {
  const [filesInProjects, setFilesInProjects] =
    useRecoilState(uploadProjectsAtom);
  const { data } = useGetProjectsQuery();
  const filesById = useRecoilValue(uploadFilesByIdAtom);

  const emptyProject: ProjectInfo = React.useMemo(() => {
    return {
      projectId: '',
      projectName: '',
      filesInProject: [...Object.keys(filesById)],
    };
  }, [filesById]);

  //we have to set the atom to have at least one project in it.
  React.useEffect(() => {
    if (
      data?.projects?.data?.totalCount &&
      data?.projects?.data?.totalCount > 0 &&
      Object.keys(filesInProjects).length === 0
    ) {
      setFilesInProjects(() => {
        return {
          [cuid()]: emptyProject,
        };
      });
    }
  }, [emptyProject, filesInProjects, setFilesInProjects, data]);

  const presentProjectIdKeys = React.useMemo(() => {
    const ids = [];
    for (const project in filesInProjects) {
      ids.push(filesInProjects[project].projectId);
    }
    return ids;
  }, [filesInProjects]);
  const possibleRemainingProjects = React.useMemo(() => {
    return data?.projects?.data?.edges
      .filter((edge) => {
        return (
          !presentProjectIdKeys.includes(edge.node.id) &&
          edge.node.id !== defaultProjectId
        );
      })
      .map((edge) => {
        return {
          projectId: edge.node.id,
          projectName: edge.node.name,
        };
      });
  }, [presentProjectIdKeys, data]);

  const handleAddNewProjectAssociation = () => {
    if (possibleRemainingProjects && possibleRemainingProjects.length > 0) {
      setFilesInProjects((previous) => {
        return {
          ...previous,
          [cuid()]: emptyProject,
        };
      });
    }
  };

  const defaultProjectName = React.useMemo(() => {
    return data?.projects?.data?.edges.filter((edge) => {
      return edge.node.id === defaultProjectId;
    })[0].node.name;
  }, [data]);

  return (
    <Box py={2}>
      <Box py={2} display="flex" alignItems="center">
        Note: data is automatically added to the &nbsp;
        <Box display="inline" sx={{ fontWeight: 900 }}>
          {defaultProjectName || 'default'}
        </Box>
        &nbsp; project
      </Box>
      {Object.keys(filesInProjects).map((dataId, index) => (
        <Box my={2} key={dataId}>
          <UploadModalProjectsPanel
            possibleProjects={possibleRemainingProjects || []}
            name={`Project ${index + 1}`}
            dataId={dataId}
          />
        </Box>
      ))}
      <Box py={3}>
        <SNButton
          startIcon={<AddIcon />}
          onClick={handleAddNewProjectAssociation}
          hover
          disabled={
            !possibleRemainingProjects || possibleRemainingProjects.length === 0
          }
        >
          Add another project
        </SNButton>
      </Box>
    </Box>
  );
};

export default UploadModalProjectSelection;
