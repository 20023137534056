import { useSearchTreeDispatch, searchTreeActions } from '../contexts';

function useRootsSet() {
  const dispatch = useSearchTreeDispatch();
  return (newRootIds: string[], searchId: string) => {
    dispatch(
      searchTreeActions.roots.set({
        key: searchId,
        value: newRootIds,
      }),
    );
  };
}

export default useRootsSet;
