import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import { Project, ProjectVariables } from '../types/schemaTypes';

export const PROJECT = gql`
  query Project($id: ID!) {
    project(id: $id) {
      project {
        id
        name
        description
        projectAccess {
          totalCount
        }
      }
      errors
    }
  }
`;

export const useGetLazyProjectByIdQuery = (
  options?: LazyQueryHookOptions<Project, ProjectVariables>,
) => {
  return useLazyQuery<Project, ProjectVariables>(PROJECT, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};

export const useGetProjectByIdQuery = (
  options?: QueryHookOptions<Project, ProjectVariables>,
) => {
  return useQuery<Project, ProjectVariables>(PROJECT, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};
