import React from 'react';
import { Box } from '@mui/material';
import Autocomplete from '../Autocomplete';
import SNInput from '../SNInput';
import { UserRole } from '../../types/schemaTypes';
import { filterRoles } from '../../utils/FilterRoles';

interface RoleDropdownProps {
  currentRole: UserRole;
  defaultRole?: UserRole;
  disabled?: boolean;
  id?: string;
  onRoleChange?: (user: string, role: UserRole) => void;
}

const RoleDropdown = ({
  id,
  onRoleChange,
  currentRole,
  defaultRole,
  disabled = false,
}: RoleDropdownProps) => {
  const options = filterRoles(defaultRole || UserRole.ADMINISTRATOR);

  const handleRoleChange = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    e: React.ChangeEvent<{}>,
    value: UserRole,
  ) => {
    if (id && onRoleChange) {
      onRoleChange(id, value);
    }
  };

  return (
    <Box
      style={{ zIndex: 1 }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      width={175}
    >
      <Autocomplete
        disabled={disabled}
        freeSolo={false}
        disableClearable
        multiple={false}
        value={currentRole}
        options={options}
        renderInput={({ InputProps, InputLabelProps, ...params }) => {
          return (
            <SNInput
              {...params}
              {...InputProps}
              className=""
              size="small"
              disabled={disabled}
            />
          );
        }}
        onChange={handleRoleChange}
      />
    </Box>
  );
};

export default RoleDropdown;
