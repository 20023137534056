import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import {
  ExportJob,
  ExportJobVariables,
  ExportJobLink,
  ExportJobLinkVariables,
} from '../types/schemaTypes';

export const GET_EXPORT_JOB = gql`
  query ExportJob($id: ID!) {
    getExportJob(id: $id) {
      id
      type
      state
      outputDetails {
        hasExportedResults
        includesRawFiles
      }
    }
  }
`;

export const useGetExportJobQuery = (
  options?: QueryHookOptions<ExportJob, ExportJobVariables>,
) => {
  return useQuery<ExportJob, ExportJobVariables>(GET_EXPORT_JOB, options);
};

export const GET_EXPORT_JOB_LINK = gql`
  query ExportJobLink($id: ID!) {
    getExportJob(id: $id) {
      id
      type
      state
      outputDetails {
        hasExportedResults
        link
        includesRawFiles
      }
    }
  }
`;

export const useLazyGetExportJobLinkQuery = (
  options?: LazyQueryHookOptions<ExportJobLink, ExportJobLinkVariables>,
) => {
  return useLazyQuery<ExportJobLink, ExportJobLinkVariables>(
    GET_EXPORT_JOB_LINK,
    options,
  );
};
