import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  ActiveUploadUpdate,
  ActiveUploadUpdateVariables,
} from '../types/schemaTypes';

export const ACTIVE_UPLOAD_UPDATE = gql`
  mutation ActiveUploadUpdate($activeUpload: ActiveUploadInput!) {
    activeUploadUpdate(activeUpload: $activeUpload) @client {
      id
      fileName
      fileType
    }
  }
`;

export const useActiveUploadUpdate = () => {
  return useMutation<ActiveUploadUpdate, ActiveUploadUpdateVariables>(
    ACTIVE_UPLOAD_UPDATE,
  );
};
