import { Box } from '@mui/material';
import styled from '../services/styled';

export const ScrollbarsX =
  '::-webkit-scrollbar { height: 7px; background: rgba(0,0,0,0.5)} ' +
  '::-webkit-scrollbar-thumb { height: 5; border-radius: 6px; background: rgba(255,51,102,0.5);}' +
  '::-webkit-scrollbar-corner { background: rgba(0,0,0,0.5) }';
export const ScrollbarsY =
  '::-webkit-scrollbar { width: 7px; background: rgba(0,0,0,0.5)} ' +
  '::-webkit-scrollbar-thumb { width: 5; border-radius: 6px; background: rgba(255,51,102,0.5);}' +
  '::-webkit-scrollbar-corner { background: rgba(0,0,0,0.5) }';

export const StyledScrollingBox = styled(Box)`
  ${ScrollbarsX}
  ${ScrollbarsY}
`;
