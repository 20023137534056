import React from 'react';
import {
  FilterLeaf,
  Comparators,
  QuantitativeComparators,
  StringComparators,
  BooleanComparators,
} from '../../types';
import { useFilterLeafUpdate } from '../../hooks';
import { MenuItem } from '@mui/material';
import SNInput from '../SNInput';

interface SearchFormFilterLeafComparatorProps
  extends Pick<FilterLeaf, 'comparator' | 'type'> {
  id: string;
}

const booleanComparators: Record<BooleanComparators, string> = {
  EQUAL_TO: 'Is equal to',
};

const quantitativeComparators: Record<QuantitativeComparators, string> = {
  EMPTY: 'Has no value',
  EQUAL_TO: 'Is equal to',
  GREATER_THAN: 'Is greater than',
  GREATER_THAN_OR_EQUAL_TO: 'Is greater than or equal to',
  LESS_THAN: 'Is less than',
  LESS_THAN_OR_EQUAL_TO: 'Is less than or equal to',
  NOT_EQUAL_TO: 'Is not equal to',
  PRESENT: 'Has a value',
};

const stringComparators: Record<StringComparators, string> = {
  EMPTY: 'Has no value',
  EQUAL_TO: 'Is equal to',
  INCLUDES: 'Includes',
  NOT_EQUAL_TO: 'Is not equal to',
  REGEX: 'Matches the regular expression',
  PRESENT: 'Has a value',
};

const SearchFormFilterLeafComparator = ({
  id,
  comparator,
  type,
}: SearchFormFilterLeafComparatorProps) => {
  const updateFilterLeaf = useFilterLeafUpdate();

  const handleUpdateContext = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFilterLeaf(id, {
      comparator: e.target.value as Comparators,
    });
  };

  const options = React.useMemo(() => {
    switch (type) {
      case 'BOOLEAN':
        return booleanComparators;
      case 'STRING':
        return stringComparators;
      case 'NUMBER':
      case 'DATE':
        return quantitativeComparators;
      default:
        return booleanComparators;
    }
  }, [type]);

  return (
    <SNInput
      select
      size="small"
      onChange={handleUpdateContext}
      value={comparator}
    >
      {Object.entries(options).map(([key, value]) => (
        <MenuItem key={key} value={key}>
          {value}
        </MenuItem>
      ))}
    </SNInput>
  );
};

export default SearchFormFilterLeafComparator;
