import gql from 'graphql-tag';
import { SearchEntity } from '../fragments';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { Entity, EntityVariables } from '../types/schemaTypes';

const ENTITY = gql`
  query Entity($id: ID!) {
    entity(id: $id) @client {
      ...SearchEntity
    }
  }
  ${SearchEntity}
`;

export const useEntityQuery = (
  options?: QueryHookOptions<Entity, EntityVariables>,
) => {
  return useQuery<Entity, EntityVariables>(ENTITY, {
    ...options,
  });
};
