import React from 'react';
import PaginationControls from '../PaginationControls';

interface FetchMoreVariables {
  after?: string | null;
  before?: string | null;
  first?: number;
  last?: number;
}

export interface FetchMoreArgs {
  variables: FetchMoreVariables;
}

interface PaginationProps {
  endCursor?: string | null;
  endIndex?: number | null;
  fetchMore?: (args: FetchMoreArgs) => void;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  loading: boolean;
  pageSize: number;
  pageTotal: number;
  selectable?: boolean;
  startCursor?: string | null;
  startIndex?: number | null;
  totalCount?: number | null;
}

const SNPagination = ({
  endCursor,
  fetchMore,
  hasNextPage,
  hasPreviousPage,
  loading,
  pageSize,
  pageTotal,
  startCursor,
  startIndex,
  totalCount,
}: PaginationProps) => {
  const loadMore = (increment: number) => {
    if (fetchMore) {
      const variables =
        increment > 0
          ? {
              after: endCursor,
              before: undefined,
              first: pageSize,
              last: undefined,
            }
          : {
              after: undefined,
              before: startCursor,
              first: undefined,
              last: pageSize,
            };
      fetchMore({
        variables,
      });
    }
  };

  return (
    <PaginationControls
      disableNext={!hasNextPage}
      disablePrevious={!hasPreviousPage}
      loading={loading}
      onPage={loadMore}
      pageCount={(startIndex || 0) / pageSize}
      pageSize={pageSize}
      pageTotal={pageTotal}
      totalCount={totalCount}
    />
  );
};

export default SNPagination;
