import React from 'react';
import { Box } from '@mui/material';
import styled from '../../services/styled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface RoleMatrixProps {
  uploadCreate?: boolean;
  uploadRead?: boolean;
  uploadEdit?: boolean;
  uploadLink?: boolean;
  reviewCreate?: boolean;
  reviewRead?: boolean;
  reviewEdit?: boolean;
  reviewLink?: boolean;
  reviewApprove?: boolean;
  searchCreate?: boolean;
  searchRead?: boolean;
  searchEdit?: boolean;
  searchLink?: boolean;
  searchApprove?: boolean;
  dataViewsCreate?: boolean;
  dataViewsRead?: boolean;
  dataViewsEdit?: boolean;
  projectsCreate?: boolean;
  projectsRead?: boolean;
  projectsEdit?: boolean;
  usersCreate?: boolean;
  usersRead?: boolean;
  usersEdit?: boolean;
}

const StyledGridCell = styled(Box)`
  border: 1px solid ${(p) => p.theme.palette.grey[600]};
  background: ${(p) => p.theme.palette.background.default};
  padding: 16px;
  display: flex;
  justify-content: center;
  width: 80px;
  height: 52px;
`;

const StyledLabeldGridCell = styled(Box)`
  border: 1px solid ${(p) => p.theme.palette.grey[600]};
  background: ${(p) => p.theme.palette.background.default};
  padding: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90px;
  height: 52px;
`;

const RoleMatrix = (props: RoleMatrixProps) => {
  return (
    <Box width="100%" height="100%" m={2}>
      <Box display="flex" justifyContent="center">
        <StyledLabeldGridCell>{}</StyledLabeldGridCell>
        <StyledGridCell>Create</StyledGridCell>
        <StyledGridCell>Read</StyledGridCell>
        <StyledGridCell>Edit</StyledGridCell>
        <StyledGridCell>Link</StyledGridCell>
        <StyledGridCell>Approve</StyledGridCell>
      </Box>
      <Box display="flex" justifyContent="center">
        <StyledLabeldGridCell>Upload</StyledLabeldGridCell>
        <StyledGridCell>
          {props.uploadCreate && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.uploadRead && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.uploadEdit && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.uploadLink && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>N/A</StyledGridCell>
      </Box>
      <Box display="flex" justifyContent="center">
        <StyledLabeldGridCell>Review</StyledLabeldGridCell>
        <StyledGridCell>
          {props.reviewCreate && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.reviewRead && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.reviewEdit && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.reviewLink && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.reviewApprove && <CheckCircleIcon />}
        </StyledGridCell>
      </Box>
      <Box display="flex" justifyContent="center">
        <StyledLabeldGridCell>Search</StyledLabeldGridCell>
        <StyledGridCell>
          {props.searchCreate && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.searchRead && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.searchEdit && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.searchLink && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.searchApprove && <CheckCircleIcon />}
        </StyledGridCell>
      </Box>
      <Box display="flex" justifyContent="center">
        <StyledLabeldGridCell>Data Views</StyledLabeldGridCell>
        <StyledGridCell>
          {props.dataViewsCreate && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.dataViewsRead && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.dataViewsEdit && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>N/A</StyledGridCell>
        <StyledGridCell>N/A</StyledGridCell>
      </Box>
      <Box display="flex" justifyContent="center">
        <StyledLabeldGridCell>Projects</StyledLabeldGridCell>
        <StyledGridCell>
          {props.projectsCreate && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.projectsRead && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.projectsEdit && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>N/A</StyledGridCell>
        <StyledGridCell>N/A</StyledGridCell>
      </Box>
      <Box display="flex" justifyContent="center">
        <StyledLabeldGridCell>Users</StyledLabeldGridCell>
        <StyledGridCell>
          {props.usersCreate && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.usersRead && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>
          {props.usersEdit && <CheckCircleIcon />}
        </StyledGridCell>
        <StyledGridCell>N/A</StyledGridCell>
        <StyledGridCell>N/A</StyledGridCell>
      </Box>
    </Box>
  );
};

export default RoleMatrix;
