import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Edit from '@mui/icons-material/Edit';
import { Dialog } from '@mui/material';
import produce from 'immer';
import React from 'react';
import { useRecoilState } from 'recoil';
import { isEditingEntitiesAtom } from '../../atoms/editEntities';
import { useEditEntitiesDispatch, useEditEntitiesState } from '../../contexts';
import { useToggle } from '../../hooks/useToggle';
import { unsavedSearchVar } from '../../localState';
import { useEditEntitiesMutation } from '../../mutations/useEditEntitiesMutation';
import { editContextToMutationTransformer } from '../../utils/EditContextToMutationTransformer';
import { reduceValidations } from '../../utils/reduceValidations';
import EditConfirmationSnackbar from '../EditConfirmationSnackbar';
import EntityEditApprovalModal from '../EntityEditApprovalModal';
import LoadingOverlay from '../LoadingOverlay';
import SearchResultsEditValidationModal from '../SearchResultsEditValidationModal';
import SearchSavePrompt from '../SearchSavePrompt';
import SNButton from '../SNButton';

export interface ActionEditEntitiesProps {
  isReview?: boolean;
  onEditSuccess?: () => void;
  pageTotal: number;
  typeId: string;
}

const ActionEditEntities = ({
  isReview,
  onEditSuccess,
  pageTotal,
  typeId,
}: ActionEditEntitiesProps) => {
  const [isEditing, setIsEditing] = useRecoilState(isEditingEntitiesAtom);
  const editState = useEditEntitiesState();
  const setEditState = useEditEntitiesDispatch();
  const hasEntityEdits = Object.keys(editState.edits).length > 0;
  const [confirmedEditCount, setConfirmedEditCount] = React.useState(0);
  const [confirmationOpen, setConfirmationOpen, setConfirmationClose] =
    useToggle(false);
  const [validationOpen, setValidationOpen, setValidationClosed] =
    useToggle(false);
  const [approvalOpen, setApprovalOpen, setApprovalClosed] = useToggle(false);

  const [editEntities, { loading }] = useEditEntitiesMutation();

  const handleEditClear = React.useCallback(() => {
    setIsEditing(false);
    setEditState((prevState) =>
      produce(prevState, (draft) => {
        draft.edits = {};
        draft.validations = {};
      }),
    );
  }, [setIsEditing, setEditState]);

  const handleEditToggle = React.useCallback(() => {
    setIsEditing((prevState) => !prevState);
  }, [setIsEditing]);

  const handleEditSubmit = () => {
    editEntities({
      variables: {
        input: {
          entities: editContextToMutationTransformer(editState, typeId),
        },
      },
      onCompleted: (result) => {
        if (result?.editEntities.data && result.editEntities.data.length > 0) {
          setIsEditing(false);
          handleEditClear();
          let count = 0;
          if (!isReview) {
            count = Object.values(editState.edits).reduce(
              (result, edit) => result + Object.keys(edit).length,
              0,
            );
          } else {
            count = result.editEntities.data.length;
            if (onEditSuccess) {
              onEditSuccess();
            }
          }
          setConfirmedEditCount(count);
          setConfirmationOpen();
        } else {
          const reducedValidations = reduceValidations(result);
          if (reducedValidations) {
            setEditState((prevState) =>
              produce(prevState, (draft) => {
                draft.validations = reducedValidations;
              }),
            );
          }
          setValidationOpen();
        }
      },
    });
  };

  const handleConfirmationClose = React.useCallback(() => {
    setConfirmationClose();
    setTimeout(() => {
      setConfirmedEditCount(0);
    }, 100);
  }, [setConfirmationClose]);

  const handleApprove = React.useCallback(() => {
    setValidationClosed();
    setApprovalOpen();
  }, [setApprovalOpen, setValidationClosed]);

  const handleApprovalSubmit = React.useCallback(
    (value: string) => {
      editEntities({
        variables: {
          input: {
            entities: editContextToMutationTransformer(editState, typeId),
            approval: {
              reason: value,
            },
          },
        },
        onCompleted: (result) => {
          if (
            result?.editEntities.data &&
            result.editEntities.data.length > 0
          ) {
            const count = Object.values(editState.edits).reduce(
              (result, edit) => result + Object.keys(edit).length,
              0,
            );
            setConfirmedEditCount(count);
            setConfirmationOpen();
            setIsEditing(false);
            handleEditClear();
          }
          setApprovalClosed();
        },
      });
    },
    [
      editEntities,
      editState,
      typeId,
      setApprovalClosed,
      setConfirmationOpen,
      setIsEditing,
      handleEditClear,
    ],
  );

  const handleBlockEditNavigation = React.useCallback(
    () => hasEntityEdits,
    [hasEntityEdits],
  );

  const handleEditNavigation = React.useCallback(() => {
    handleEditClear();
  }, [handleEditClear]);

  return (
    <>
      {isEditing && (
        <SearchSavePrompt
          when={hasEntityEdits}
          onNavigate={handleEditNavigation}
          shouldBlockNavigation={handleBlockEditNavigation}
          preventPush={unsavedSearchVar()}
          title="Unsaved edits"
          message="You have unsaved edits to these results. Do you want to proceed and
            discard those edits?"
          primary="Discard Edits"
        />
      )}
      {isEditing && (
        <>
          <SNButton
            startIcon={<Cancel />}
            onClick={handleEditClear}
            snVariant="text"
          >
            Cancel Edits
          </SNButton>
          <SNButton
            disabled={!hasEntityEdits}
            startIcon={<CheckCircle />}
            onClick={handleEditSubmit}
            snVariant="primary"
          >
            Save Edits
          </SNButton>
        </>
      )}
      {!isEditing && (
        <SNButton
          disabled={pageTotal === 0}
          startIcon={<Edit />}
          onClick={handleEditToggle}
          snVariant="text"
        >
          Edit Values
        </SNButton>
      )}
      <EditConfirmationSnackbar
        open={confirmationOpen}
        onClose={handleConfirmationClose}
        changeCount={confirmedEditCount}
      />
      <Dialog open={validationOpen} fullWidth maxWidth="sm">
        <SearchResultsEditValidationModal
          onClose={setValidationClosed}
          onApprove={handleApprove}
        />
      </Dialog>
      <Dialog open={approvalOpen} fullWidth maxWidth="sm">
        <EntityEditApprovalModal
          onClose={setApprovalClosed}
          onSubmit={handleApprovalSubmit}
        />
      </Dialog>
      <LoadingOverlay isLoading={loading} data-testid="updating-entities" />
    </>
  );
};

export default ActionEditEntities;
