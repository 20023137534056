import { Box, Typography } from '@mui/material';
import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { useRecoilValue } from 'recoil';
import {
  ClassificationTableDetails,
  EntityTypeFields,
} from '../../types/schemaTypes';
import { ClassificationWizardFormInfo } from '../../atoms/ClassificationWizardFormInfo';
import styled from '../../services/styled';
import SNStatusBanner from '../SNStatusBanner';
import { EntityTypeFieldsFragment } from '../../fragments/EntityTypeFields';

interface ClassificationReviewProps {
  groupId: string;
  classificationErrors?: string[];
}

const StyledBulletList = styled('ul')`
  list-style-type: disc;
`;

const ClassificationReview = ({
  groupId,
  classificationErrors,
}: ClassificationReviewProps) => {
  const classificationWizardformInfo = useRecoilValue(
    ClassificationWizardFormInfo,
  );
  const client = useApolloClient();
  const unclassifiedGroup = client.readFragment<ClassificationTableDetails>({
    id: `UnclassifiedResultGroup:${groupId}`,
    fragment: gql`
      fragment ClassificationTableDetails on UnclassifiedResultGroup {
        id
        name
        headers
        totalCount
      }
    `,
  });
  const selectedEntityTypeFieldInfo = client.readFragment<EntityTypeFields>({
    id: `EntityType:${classificationWizardformInfo.selectedTypeId}`,
    fragment: EntityTypeFieldsFragment,
  });

  return (
    <Box>
      <Box m={3}>
        <Typography variant="h3">Classification Summary</Typography>
      </Box>
      {classificationErrors && classificationErrors.length > 0 && (
        <Box m={3}>
          <SNStatusBanner status="error">
            <ul>
              {classificationErrors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </SNStatusBanner>
        </Box>
      )}
      <Box m={3}>
        <Typography>
          {unclassifiedGroup?.totalCount} entities classified as{' '}
          {selectedEntityTypeFieldInfo?.name}
        </Typography>
      </Box>
      <Box m={3} ml={5}>
        <StyledBulletList>
          <li>
            {selectedEntityTypeFieldInfo
              ? selectedEntityTypeFieldInfo?.fields?.length -
                (classificationWizardformInfo.missingColumns.length +
                  classificationWizardformInfo.columnsToRename.length)
              : ''}
            &nbsp;columns were matched
          </li>
          <li>
            {classificationWizardformInfo.columnsToRename.length}&nbsp;columns
            were renamed
          </li>
          <li>
            {classificationWizardformInfo.columnsAsMetadata.length}&nbsp;columns
            were captured as metadata
          </li>
          <li>
            {classificationWizardformInfo.columnsToDelete.length}&nbsp;columns
            were deleted
          </li>
          <li>
            {classificationWizardformInfo.missingColumns.length}&nbsp;columns
            were added with default values
          </li>
        </StyledBulletList>
      </Box>
    </Box>
  );
};

export default ClassificationReview;
