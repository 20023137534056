import * as React from 'react';
import { createAction, ActionType } from 'typesafe-actions';
import produce, { Draft } from 'immer';
import {
  SearchTree,
  SearchTreeBranch,
  AtLeastOne,
  SearchState,
  GlobalFilter,
} from '../types';
import { NEW_REVIEW_KEY, NEW_SEARCH_KEY } from '../constants';

interface PayloadBranchBase {
  key: string;
  id: string;
}
interface PayloadBranchAdd extends PayloadBranchBase {
  value: Draft<SearchTreeBranch>;
}

type PayloadBranchDelete = PayloadBranchBase;

interface PayloadBranchUpdate extends PayloadBranchBase {
  value: Draft<AtLeastOne<SearchTreeBranch>>;
}

interface PayloadGlobalFilterAdd extends PayloadBranchBase {
  value: Draft<GlobalFilter>;
}

interface PayloadGlobalFilterUpdate extends PayloadBranchBase {
  value: Draft<AtLeastOne<GlobalFilter>>;
}

interface PayloadRootsSet {
  key: string;
  value: string[];
}
interface PayloadRootAdd {
  key: string;
  value: string;
}

interface PayloadSetTree {
  key: string;
  roots: string[];
  branches: Draft<SearchTree>;
}

const branchAdd = createAction('search/BRANCH_ADD')<PayloadBranchAdd>();
const branchDelete = createAction(
  'search/BRANCH_DELETE',
)<PayloadBranchDelete>();
const branchUpdate = createAction(
  'search/BRANCH_UPDATE',
)<PayloadBranchUpdate>();
const globalFilterAdd = createAction(
  'search/GLOBAL_FILTER_ADD',
)<PayloadGlobalFilterAdd>();
const globalFilterUpdate = createAction(
  'search/GLOBAL_FILTER_UPDATE',
)<PayloadGlobalFilterUpdate>();
export const treeSet = createAction('search/TREE_SET')<PayloadSetTree>();
const rootsSet = createAction('search/ROOTS_SET')<PayloadRootsSet>();
const rootAdd = createAction('search/ROOT_ADD')<PayloadRootAdd>();
const rootDelete = createAction('search/ROOT_DELETE')<PayloadRootAdd>();

const searchTreeActions = {
  branch: {
    add: branchAdd,
    delete: branchDelete,
    update: branchUpdate,
  },
  tree: {
    set: treeSet,
  },
  roots: {
    add: rootAdd,
    delete: rootDelete,
    set: rootsSet,
  },
  globalFilter: {
    add: globalFilterAdd,
    update: globalFilterUpdate,
  },
};
type SearchTreeActions = ActionType<typeof searchTreeActions>;

type Dispatch = (action: SearchTreeActions) => void;
interface SearchTreeProviderProps {
  children: React.ReactNode;
  initialState?: SearchState;
}
const SearchTreeStateContext = React.createContext<SearchState | undefined>(
  undefined,
);
const SearchTreeDispatchContexts = React.createContext<Dispatch | undefined>(
  undefined,
);

const getNodeDescendants = (state: SearchTree, id: string): string[] => {
  const activeNode = state[id];
  return activeNode.children.reduce(
    (result, child) => result.concat(getNodeDescendants(state, child)),
    [id],
  );
};

export const treeReducer = (
  draft: Draft<SearchState>,
  action: SearchTreeActions,
) => {
  switch (action.type) {
    case 'search/BRANCH_ADD':
      draft[action.payload.key].branches[action.payload.id] =
        action.payload.value;
      break;
    case 'search/BRANCH_DELETE': {
      getNodeDescendants(
        draft[action.payload.key].branches,
        action.payload.id,
      ).forEach((nodeId) => {
        delete draft[action.payload.key].branches[nodeId];
      });
      break;
    }
    case 'search/BRANCH_UPDATE': {
      // Not as type-safe as you might think because payload relies on Partial<>
      draft[action.payload.key].branches[action.payload.id] = {
        ...draft[action.payload.key].branches[action.payload.id],
        ...action.payload.value,
      };
      break;
    }
    case 'search/TREE_SET': {
      draft[action.payload.key] = {
        branches: action.payload.branches,
        roots: action.payload.roots,
        globalFilters: draft[action.payload.key]
          ? draft[action.payload.key].globalFilters
          : {},
      };
      break;
    }
    case 'search/ROOTS_SET':
      draft[action.payload.key].roots = action.payload.value;
      break;
    case 'search/ROOT_ADD':
      draft[action.payload.key].roots.push(action.payload.value);
      break;
    case 'search/ROOT_DELETE':
      draft[action.payload.key].roots = draft[action.payload.key].roots.filter(
        (rootId) => rootId !== action.payload.value,
      );
      break;
    case 'search/GLOBAL_FILTER_ADD':
      if (draft[action.payload.key]) {
        draft[action.payload.key].globalFilters[action.payload.id] =
          action.payload.value;
      } else {
        draft[action.payload.key] = {
          roots: [],
          branches: {},
          globalFilters: {
            [action.payload.id]: action.payload.value,
          },
        };
      }
      break;
  }
};

const curriedTreeReducer = produce(treeReducer);

const defaultState = {
  [NEW_SEARCH_KEY]: {
    branches: {},
    roots: [],
    globalFilters: {},
  },
  [NEW_REVIEW_KEY]: {
    branches: {},
    roots: [],
    globalFilters: {},
  },
};

function SearchTreeProvider({
  initialState = defaultState,
  children,
}: SearchTreeProviderProps) {
  const [state, dispatch] = React.useReducer(curriedTreeReducer, initialState);
  return (
    <SearchTreeStateContext.Provider value={state}>
      <SearchTreeDispatchContexts.Provider value={dispatch}>
        {children}
      </SearchTreeDispatchContexts.Provider>
    </SearchTreeStateContext.Provider>
  );
}

function useSearchTreeBranches(searchId: string) {
  const context = React.useContext(SearchTreeStateContext);

  if (context === undefined) {
    throw new Error(
      'useSearchTreeBranches must be used within a SearchTreeProvider',
    );
  }
  const searchState = context[searchId];
  return searchState ? searchState.branches : {};
}

function useSearchTreeRoots(searchId: string) {
  const context = React.useContext(SearchTreeStateContext);

  if (context === undefined) {
    throw new Error(
      'useSearchTreeBranches must be used within a SearchTreeProvider',
    );
  }
  const searchState = context[searchId];
  return searchState ? searchState.roots : [];
}

function useSearchTreeDispatch() {
  const context = React.useContext(SearchTreeDispatchContexts);
  if (context === undefined) {
    throw new Error(
      'useSearchTreeDispatch must be used within a SearchTreeProvider',
    );
  }
  return context;
}

function useCompleteSearchObject(searchId: string) {
  const context = React.useContext(SearchTreeStateContext);

  if (context === undefined) {
    throw new Error(
      'useSearchTreeState must be used within a SearchTreeProvider',
    );
  }
  const searchState = context[searchId];
  return searchState
    ? searchState
    : {
        branches: {},
        roots: [],
        globalFilters: {},
      };
}

export {
  SearchTreeProvider,
  useSearchTreeBranches,
  useSearchTreeRoots,
  useSearchTreeDispatch,
  searchTreeActions,
  useCompleteSearchObject,
};
