import {
  useSearchTreeDispatch,
  searchTreeActions,
  useSearchTreeBranches,
} from '../contexts';
import useRootDelete from './useRootDelete';

function useBranchDelete(searchId: string) {
  const branches = useSearchTreeBranches(searchId);
  const dispatch = useSearchTreeDispatch();
  const deleteRootId = useRootDelete(searchId);

  return (id: string) => {
    // First remove the id from the parent branches list of childre
    // This will hide the node and it's descendants in the UI
    const branch = branches[id];
    const parentBranch = branches[branch.parent];
    if (parentBranch) {
      dispatch(
        searchTreeActions.branch.update({
          key: searchId,
          id: branch.parent,
          value: {
            children: parentBranch.children.filter((childId) => childId !== id),
          },
        }),
      );
    } else {
      deleteRootId(id);
    }

    // Then delete the actual branch object, and its descendants, from state
    dispatch(
      searchTreeActions.branch.delete({
        key: searchId,
        id,
      }),
    );
  };
}

export default useBranchDelete;
