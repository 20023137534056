import { Box, Paper } from '@mui/material';
import React from 'react';
import { useResetRecoilState } from 'recoil';
import { searchTabsAtom } from '../../atoms/latestSearchAtom';
import {
  NEW_REVIEW_KEY,
  NEW_SEARCH_KEY,
  SEARCH_QUERY_BRANCH_DEFAULT,
} from '../../constants';
import { SearchFormProvider, SearchTreeNavProvider } from '../../contexts';
import styled from '../../services/styled';
import SearchFormDialog from '../SearchFormDialog';

const StyledPaper = styled(Paper)`
  width: calc(100% - 96px);
  max-width: 960px;
  display: flex;
  max-height: calc(100vh - 168px); // 96px + 72px for top navbar
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  margin: 48px;
`;

interface NewSearchFormProps {
  isReview?: boolean;
  searchId: string;
}

const NewSearchForm = ({ isReview, searchId }: NewSearchFormProps) => {
  const resetLatestSearch = useResetRecoilState(
    searchTabsAtom(isReview ? NEW_REVIEW_KEY : NEW_SEARCH_KEY),
  );
  React.useEffect(() => {
    resetLatestSearch();
  }, [resetLatestSearch]);
  return (
    <SearchTreeNavProvider>
      <SearchFormProvider initialState={SEARCH_QUERY_BRANCH_DEFAULT}>
        <Box width="100%" display="flex" justifyContent="center">
          <StyledPaper>
            <SearchFormDialog isNewSearch searchId={searchId} />
          </StyledPaper>
        </Box>
      </SearchFormProvider>
    </SearchTreeNavProvider>
  );
};

export default NewSearchForm;
