import React from 'react';
import { useEditEntitiesState } from '../../contexts';
import styled from '../../services/styled';
import { SEVERITY_PALETTE } from '../../constants';
import { AnnotationSeverity } from '../../types/schemaTypes';
import ValidationBox from '../ValidationBox';
import { Typography } from '@mui/material';

const StyledTableBox = styled('table')`
  width: 100%;
  padding: 0;
  margin-top: 8px;
  border-collapse: collapse;

  tr {
    width: 100%;
    border-bottom: none;
    border-collapse: collapse;
    margin: 0;
    border: 1px solid ${(p) => p.theme.palette.grey[600]};
    border-bottom: none;
  }

  tr:last-of-type {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid ${(p) => p.theme.palette.grey[600]};
    margin: 0;
  }
`;

const SearchResultsFieldValidationsList = () => {
  const editState = useEditEntitiesState();
  const fieldValidationsList = React.useMemo(() => {
    return Object.keys(editState.validations).flatMap((id) => {
      const fieldValidations = Object.keys(
        editState.validations[id].fieldValidations,
      );
      return fieldValidations.map((fieldVal) => {
        const status =
          SEVERITY_PALETTE[
            editState.validations[id].fieldValidations[fieldVal]
              .displayStatus || AnnotationSeverity.INFO
          ];
        return {
          rowId: id,
          fieldId: fieldVal,
          displayStatus: status,
          value: editState.edits[id][fieldVal],
          name: editState.validations[id].fieldValidations[fieldVal].name || '',
        };
      });
    });
  }, [editState]);

  if (fieldValidationsList.length === 0) return null;

  return (
    <>
      <Typography variant="h4">Issues:</Typography>
      <StyledTableBox>
        <tbody>
          {fieldValidationsList.map((validationSummary) => (
            <ValidationBox
              key={`${validationSummary.rowId}:${validationSummary.fieldId}`}
              {...validationSummary}
            />
          ))}
        </tbody>
      </StyledTableBox>
    </>
  );
};

export default SearchResultsFieldValidationsList;
