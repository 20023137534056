import React from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import SNButton from '../SNButton';
import SNInput from '../SNInput';
import SNDialogTitle from '../SNDialogTitle';
import SNStatusBanner from '../SNStatusBanner';
import { collectErrors } from '../../utils/collectErrors';
import { useCreateListMutation } from '../../mutations';

interface CreateListModalProps {
  onClose: () => void;
}

const CreateListModal = ({ onClose }: CreateListModalProps) => {
  const [newListVariables, setNewListVariables] = React.useState({
    name: '',
    description: '',
  });
  const [clickAttempted, setClickAttempted] = React.useState(false);
  const [createNewList, { data: newListData }] = useCreateListMutation({
    variables: { input: { ...newListVariables } },
    onCompleted: () => {
      onClose();
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewListVariables((previous) => {
      return {
        ...previous,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleAddNewList = () => {
    if (newListVariables.name) {
      createNewList();
      setNewListVariables({ name: '', description: '' });
      setClickAttempted(false);
    } else {
      setClickAttempted(true);
    }
  };

  const handleClose = React.useCallback(() => {
    setNewListVariables({ name: '', description: '' });
    setClickAttempted(false);
    onClose();
  }, [onClose, setNewListVariables]);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([
      newListData?.addControlledVocabularyCategory?.errors,
    ]);
  }, [newListData]);

  return (
    <>
      <SNDialogTitle onClose={handleClose}>New List </SNDialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          {collectedErrors.length > 0 && (
            <Box mb={2}>
              <SNStatusBanner status="error">
                <ul>
                  {collectedErrors.map((error) => {
                    return <li key={error}>{error}</li>;
                  })}
                </ul>
              </SNStatusBanner>
            </Box>
          )}
          <Box mb={2}>
            <SNInput
              id="name"
              required
              autoFocus
              fullWidth
              value={newListVariables.name}
              onChange={handleChange}
              label="Name"
              name="name"
              error={clickAttempted && !newListVariables.name}
              helperText={
                clickAttempted && !newListVariables.name
                  ? 'You must provide a name for the new list'
                  : ''
              }
            />
          </Box>
          <SNInput
            id="description"
            multiline
            rows={3}
            fullWidth
            value={newListVariables.description}
            onChange={handleChange}
            label="Description"
            name="description"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <SNButton onClick={handleClose}>Cancel</SNButton>
        <SNButton
          snVariant="primary"
          onClick={handleAddNewList}
          disabled={!newListVariables.name}
        >
          Create List
        </SNButton>
      </DialogActions>
    </>
  );
};

export default CreateListModal;
