import React from 'react';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { UploadToReviewIdAtom } from '../UploadsTable/UploadToReviewIdAtom';
import SNTab from '../SNTab';
import theme from '../../services/theme';
import styled from '../../services/styled';
import { useReviewSummaryLazyQuery } from '../../queries';
import { ReviewStatus } from '../../types/schemaTypes';
import TopList from '../TopList';
import LoadingOverlay from '../LoadingOverlay';
import { useUploadLazyQuery } from '../../queries/useUploadQuery';
import SNDialogTitle from '../SNDialogTitle';

const TabBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 168px;
`;

const UploadReviewModal = () => {
  const [UploadToReviewId, setUploadToReviewId] =
    useRecoilState(UploadToReviewIdAtom);
  const [activeTab, setActiveTab] = React.useState<ReviewStatus>(
    ReviewStatus.INVALID,
  );
  const [getReviewSummary, { loading, data }] = useReviewSummaryLazyQuery();
  const [getUploadData, { data: uploadData, loading: uploadDetailsLoading }] =
    useUploadLazyQuery();

  React.useEffect(() => {
    if (UploadToReviewId.id) {
      getReviewSummary({
        variables: {
          reviewSummaryFilter: {
            uploadId: UploadToReviewId.id,
          },
        },
      });
      getUploadData({
        variables: {
          id: UploadToReviewId.id,
        },
      });
    }
  }, [getUploadData, UploadToReviewId, getReviewSummary]);

  const handleTabClick = (newTab: ReviewStatus) => {
    setActiveTab(newTab);
  };

  return (
    <Dialog open={UploadToReviewId.open}>
      <SNDialogTitle
        onClose={() => {
          setUploadToReviewId((prev) => {
            return { ...prev, open: false };
          });
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <Typography variant="h2">Quality Summary</Typography>
          </Box>
          <Box>
            <Typography>
              <strong>{uploadData?.upload?.uploadDetails?.fileName}</strong>
            </Typography>
          </Box>
        </Box>
      </SNDialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Box
            display="flex"
            minWidth={504}
            width="100%"
            bgcolor="#000000"
            justifyContent="space-around"
            alignItems="center"
            borderBottom={`1px solid ${theme.palette.grey[800]}`}
          >
            {data?.reviewSummary?.statusCounts?.map((statusCount) => (
              <SNTab
                key={statusCount.status}
                snVariant="underline"
                active={activeTab}
                value={statusCount.status}
                onClick={() => {
                  handleTabClick(statusCount.status);
                }}
              >
                <TabBox>
                  <Typography variant="h2">{statusCount.count}</Typography>
                  <Typography variant="h4">{statusCount.status}</Typography>
                </TabBox>
              </SNTab>
            ))}
          </Box>
          <Box
            minWidth={504}
            width="100%"
            display="flex"
            justifyContent="center"
            bgcolor="#000000"
            p={2}
          >
            {data?.reviewSummary?.statusCounts?.map((statusCount) => (
              <Box key={statusCount.status}>
                {activeTab === statusCount.status && (
                  <Box>
                    {statusCount?.reviewSummaryIssuesLists?.map((list) => (
                      <TopList
                        key={list.title}
                        title={list.title}
                        items={list.items}
                      />
                    ))}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
        <LoadingOverlay isLoading={loading || uploadDetailsLoading} />
      </DialogContent>
    </Dialog>
  );
};

export default UploadReviewModal;
