import * as React from 'react';

interface SearchTreeProviderProps {
  children: React.ReactNode;
}

export type UploadWizardKeys =
  | 'skipAllQuestions'
  | 'uploadAllSheets'
  | 'addProjects'
  | 'addMetadata'
  | 'createContext'
  | 'linkData';

type UploadWizardState = Record<UploadWizardKeys, boolean | null>;

type Dispatch = (action: React.SetStateAction<UploadWizardState>) => void;

const UploadWizardContext = React.createContext<UploadWizardState | undefined>(
  undefined,
);
const UploadWizardDispatchContexts = React.createContext<Dispatch | undefined>(
  undefined,
);

const initialUploadWizardState: UploadWizardState = {
  skipAllQuestions: null,
  uploadAllSheets: null,
  addProjects: null,
  addMetadata: null,
  createContext: null,
  linkData: null,
};

function UploadWizardProvider({ children }: SearchTreeProviderProps) {
  const [state, setState] = React.useState<UploadWizardState>(
    initialUploadWizardState,
  );
  return (
    <UploadWizardContext.Provider value={state}>
      <UploadWizardDispatchContexts.Provider value={setState}>
        {children}
      </UploadWizardDispatchContexts.Provider>
    </UploadWizardContext.Provider>
  );
}
function useUploadWizardState() {
  const context = React.useContext(UploadWizardContext);
  if (context === undefined) {
    throw new Error(
      'useUploadWizardState must be used within a UploadWizardProvider',
    );
  }
  return context;
}

function useUploadWizardDispatch() {
  const context = React.useContext(UploadWizardDispatchContexts);
  if (context === undefined) {
    throw new Error(
      'useUploadWizardDispatch must be used within a UploadWizardProvider',
    );
  }
  return context;
}
export {
  UploadWizardProvider,
  useUploadWizardState,
  useUploadWizardDispatch,
  initialUploadWizardState,
};
