import styled from '../../services/styled';
import { Input } from '@mui/material';
import { InputProps } from '@mui/material/Input/Input';

const EditableTitle = styled(Input)<InputProps>`
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  :hover {
    border-color: ${({ theme }) => theme.palette.grey['600']};
  }
  &.Mui-focused {
    border-color: ${({ theme }) => theme.palette.primary.main};
    :hover {
      border-color: ${({ theme }) => theme.palette.info.light};
    }
  }
  > .MuiInput-input {
    font-family: Akkurat, sans-serif;
    font-size: 1.875rem; // 30px (Responsive)
    font-weight: bold;
    line-height: calc(1.875rem + 1px);
    letter-spacing: 0.1rem;
    padding: 9px 7px;
  }
`;

export default EditableTitle;
