import React from 'react';
import styled from '../../services/styled';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { SearchSavedSearches_searchSavedSearches_data_edges_node } from '../../types/schemaTypes';
import Checkbox from '../Checkbox';
import SNTableRow from '../SNTableRow';
import { useRecoilState } from 'recoil';
import { listItemByIndexSelector } from '../../atoms/listSelection';
import UserAvatar from '../UserAvatar';

interface SavedSearchesTableRowProps
  extends Omit<
    SearchSavedSearches_searchSavedSearches_data_edges_node,
    'user'
  > {
  onSelect: (id: string) => void;
  index: number;
  isPublic?: boolean;
  userName?: string | null;
  userPicture?: string | null;
}

const StyledTableCell = styled('td')`
  overflow: hidden;
`;

const LinkBox = styled(Box)`
  cursor: pointer;
`;

const SavedSearchesTableRow: React.FC<SavedSearchesTableRowProps> = ({
  createdAt,
  id,
  isPublic,
  name,
  description,
  onSelect,
  public: publicSearch = true,
  updatedAt,
  userName,
  userPicture,
  index,
}) => {
  const [selected, onRowSelect] = useRecoilState(
    listItemByIndexSelector(index),
  );

  const handleRowSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onRowSelect(checked);
    },
    [onRowSelect],
  );

  const handleSelect = React.useCallback(() => {
    onSelect(id);
  }, [onSelect, id]);

  return (
    <SNTableRow selected={selected}>
      <td>
        <Box pl={4} pr={3} position="relative">
          <Checkbox
            checked={selected}
            onChange={handleRowSelect}
            data-testid={`savedSearch_checkbox_${id}`}
          />
        </Box>
      </td>
      <StyledTableCell>
        <LinkBox
          data-testid={`savedSearch_name_description_${id}`}
          display="flex"
          alignItems="center"
          height={56}
          onClick={handleSelect}
        >
          <Box pr={1.5} display="flex">
            <Box fontWeight="bold" overflow="hidden" textOverflow="ellipsis">
              <Typography noWrap>{name}</Typography>
              <Typography noWrap color="textSecondary">
                {description}
              </Typography>
            </Box>
          </Box>
        </LinkBox>
      </StyledTableCell>
      {isPublic ? (
        <td>
          <LinkBox
            display="flex"
            alignItems="center"
            height={56}
            onClick={handleSelect}
          >
            <Box>
              <Box display="flex" alignItems="center">
                <UserAvatar
                  name={userName || 'Unknown'}
                  picture={userPicture || undefined}
                  size={32}
                />
              </Box>
            </Box>
          </LinkBox>
        </td>
      ) : (
        <td>
          <LinkBox
            data-testid={`savedSearch_visibility_${id}`}
            display="flex"
            alignItems="center"
            height={56}
            onClick={handleSelect}
          >
            <Typography>{publicSearch ? 'Public' : 'Private'}</Typography>
          </LinkBox>
        </td>
      )}
      <td>
        <LinkBox
          data-testid={`savedSearch_created_${id}`}
          display="flex"
          alignItems="center"
          height={56}
          onClick={handleSelect}
        >
          <Typography noWrap>
            {moment(createdAt).format('YYYY-MM-DD')}
          </Typography>
        </LinkBox>
      </td>
      <td>
        <LinkBox
          data-testid={`savedSearch_updated_${id}`}
          display="flex"
          alignItems="center"
          height={56}
          onClick={handleSelect}
        >
          <Typography noWrap>
            {moment(updatedAt).format('YYYY-MM-DD')}
          </Typography>
        </LinkBox>
      </td>
    </SNTableRow>
  );
};

export default SavedSearchesTableRow;
