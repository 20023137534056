import { Box, Collapse, IconButton, Snackbar, Typography } from '@mui/material';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import React from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Close from '@mui/icons-material/Close';

const GeneralErrorSnackbar = () => {
  const [generalError, setGeneralError] = useRecoilState(
    GeneralErrorSnackbarAtom,
  );
  const resetAtom = useResetRecoilState(GeneralErrorSnackbarAtom);
  const [expanded, setExpanded] = React.useState(false);

  const handleClose = () => {
    setGeneralError((prev) => {
      return { ...prev, open: false, details: '', message: '' };
    });
    setTimeout(() => {
      resetAtom();
    }, 200);
  };

  return (
    <Snackbar
      id="general-error-snackbar"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={generalError.open}
      autoHideDuration={10000}
      onClose={handleClose}
    >
      <Box bgcolor="background.paper" minWidth={360}>
        <Box
          bgcolor="grey.900"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height={52}
        >
          <Box pl={2} mr={2} display="flex" alignItems="center">
            <ReportProblemIcon color="error" />
            <Box pl={2}>
              <Typography variant="h3" color="error">
                An error occurred
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="expand collapse"
              color="inherit"
              onClick={() => setExpanded(!expanded)}
            >
              {!expanded ? <ExpandMore /> : <ExpandLess />}
            </IconButton>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Box
          pt={0}
          p={1}
          display="flex"
          bgcolor="grey.900"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box mb={2}>
            <Typography>{generalError.message}</Typography>
          </Box>
          <Collapse in={expanded}>
            <Box mb={2}>
              <Typography color="textSecondary">
                {generalError.details}
              </Typography>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Snackbar>
  );
};

export default GeneralErrorSnackbar;
