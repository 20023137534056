import * as React from 'react';
import {
  EditEntities_editEntities_validations,
  EditEntities_editEntities_validations_fieldValidations,
} from '../types/schemaTypes';

interface EditEntitiesProviderProps {
  children: React.ReactNode;
}

interface EditEntitiesValidationState
  extends Omit<EditEntities_editEntities_validations, 'fieldValidations'> {
  fieldValidations: Record<
    string,
    EditEntities_editEntities_validations_fieldValidations
  >;
}

export interface EditEntitiesState {
  edits: {
    [key: string]: Record<string, string>;
  };
  validations: {
    [key: string]: EditEntitiesValidationState;
  };
}

const EditEntitiesContext = React.createContext<EditEntitiesState | undefined>(
  undefined,
);
const EditEntitiesDispatchContexts = React.createContext<
  React.Dispatch<React.SetStateAction<EditEntitiesState>> | undefined
>(undefined);

function EditEntitiesProvider({ children }: EditEntitiesProviderProps) {
  const [EditsState, setEditsState] = React.useState<EditEntitiesState>({
    edits: {},
    validations: {},
  });
  return (
    <EditEntitiesContext.Provider value={EditsState}>
      <EditEntitiesDispatchContexts.Provider value={setEditsState}>
        {children}
      </EditEntitiesDispatchContexts.Provider>
    </EditEntitiesContext.Provider>
  );
}
function useEditEntitiesState() {
  const context = React.useContext(EditEntitiesContext);
  if (context === undefined) {
    throw new Error(
      'useEditEntitiesState must be used within a EditEntitiesProvider',
    );
  }
  return context;
}

function useEditEntitiesDispatch() {
  const context = React.useContext(EditEntitiesDispatchContexts);
  if (context === undefined) {
    throw new Error(
      'useEditEntitiesDispatch must be used within a EditEntitiesProvider',
    );
  }
  return context;
}
export { EditEntitiesProvider, useEditEntitiesState, useEditEntitiesDispatch };
