import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from '../../services/styled';

const StyledContainerBox = styled(Box)`
  background: #000000;
  overflow-y: auto;
  padding: 24px;
`;

const StyledList = styled('ol')`
  list-style: none;
  padding-left: 0;
  counter-reset: my-counter;
  li {
    display: flex;
    counter-increment: my-counter;
    padding-top: 16px;
  }
  li::before {
    font-weight: ${(p) => p.theme.typography.h5.fontWeight};
    line-height: ${(p) => p.theme.typography.h5.lineHeight};
    font-feature-settings: ${(p) => p.theme.typography.h5.fontFeatureSettings};
    padding-left: 0;
    padding-right: 24px;
    font-size: ${(p) => p.theme.typography.h5.fontSize};
    content: counter(my-counter);
    color: ${(p) => p.theme.palette.text.secondary};
  }
`;

interface TopListProps {
  title: string;
  items: { label: string }[];
}

const TopList = ({ title, items }: TopListProps) => {
  return (
    <StyledContainerBox>
      <Typography variant="h3">{title}</Typography>
      <StyledList>
        {items.map((item, index) => (
          <li key={index}>
            <Box display="flex" alignItems="center">
              <Typography variant="h5">{item.label}</Typography>
            </Box>
          </li>
        ))}
      </StyledList>
    </StyledContainerBox>
  );
};

export default TopList;
