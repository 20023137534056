import React from 'react';
import { Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import SNButton from '../SNButton';
import SNInput from '../SNInput';
import { AddProjectInput } from '../../types/schemaTypes';
import { useAddProjectMutation } from '../../mutations/useAddProjectMutation';
import UsersToAddSelector from '../UsersToAddSelector';
import { SelectedUsers } from '../UsersToAddSelector/UsersToAddSelector';
import { useAddUsersToProjectsMutation } from '../../mutations/useAddUsersToProjects';
import SNDialogTitle from '../SNDialogTitle';
import { collectErrors } from '../../utils/collectErrors';
import SNStatusBanner from '../SNStatusBanner';

interface CreateProjectModalProps {
  isOpen: boolean;
  setClosed: () => void;
}

const CreateProjectModal = ({ isOpen, setClosed }: CreateProjectModalProps) => {
  const [newProjectVariables, setNewProjectVariables] =
    React.useState<AddProjectInput>({ name: '', description: '' });
  const [clickAttempted, setClickAttempted] = React.useState(false);
  const [selectedUsers, setSelectedUsers] = React.useState<SelectedUsers>({});
  const [grantUsersAccessToProject, { data: grantData }] =
    useAddUsersToProjectsMutation({
      refetchQueries: ['Projects'],
      onCompleted: () => {
        setSelectedUsers({});
        setClosed();
      },
    });
  const [createNewProject, { data: newProjectData }] = useAddProjectMutation({
    refetchQueries: ['Projects'],
    variables: { input: { ...newProjectVariables } },
    onCompleted: (response) => {
      handleGrantUsersAccessToProject(
        response.addProject?.project?.id,
        selectedUsers,
      );
    },
  });

  const handleGrantUsersAccessToProject = (
    projectId: string | undefined,
    selectedUsers: SelectedUsers,
  ) => {
    const selectedUserIds = Object.keys(selectedUsers);
    if (projectId && selectedUserIds.length > 0) {
      const grants = selectedUserIds.map((userId) => {
        return {
          userId,
          projectId,
          userRole: selectedUsers[userId].defaultRole,
        };
      });
      grantUsersAccessToProject({ variables: { input: { grants } } });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClickAttempted(false);
    setNewProjectVariables((previous) => {
      return {
        ...previous,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleAddNewProject = () => {
    if (newProjectVariables.name && newProjectVariables.description) {
      createNewProject();
      setNewProjectVariables({ name: '', description: '' });
      setClosed();
    } else {
      setClickAttempted(true);
    }
  };

  const handleClose = React.useCallback(() => {
    setNewProjectVariables({ name: '', description: '' });
    setClickAttempted(false);
    setClosed();
    setSelectedUsers({});
  }, [setClosed, setNewProjectVariables]);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([
      newProjectData?.addProject?.errors,
      grantData?.grantUsersAccessToProjects?.errors,
    ]);
  }, [newProjectData, grantData]);

  return (
    <Dialog open={isOpen} fullWidth>
      <SNDialogTitle onClose={handleClose}>New Project</SNDialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          {collectedErrors.length > 0 && (
            <Box mb={2}>
              <SNStatusBanner status="error">
                <ul>
                  {collectedErrors.map((error) => {
                    return <li key={error}>{error}</li>;
                  })}
                </ul>
              </SNStatusBanner>
            </Box>
          )}
          <Box mb={2}>
            <SNInput
              id="name"
              required
              autoFocus
              fullWidth
              value={newProjectVariables.name}
              onChange={handleChange}
              label="Name"
              name="name"
              error={clickAttempted && !newProjectVariables.name}
              helperText={
                clickAttempted && !newProjectVariables.name
                  ? 'You must provide a name for the new project'
                  : ''
              }
            />
          </Box>
          <Box mb={2}>
            <SNInput
              id="description"
              required
              fullWidth
              value={newProjectVariables.description}
              onChange={handleChange}
              label="Description"
              name="description"
              error={clickAttempted && !newProjectVariables.description}
              multiline
              rows={3}
              helperText={
                clickAttempted && !newProjectVariables.description
                  ? 'You must provide a description for the new project'
                  : ''
              }
            />
          </Box>
          <Box>
            <UsersToAddSelector
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              addCurrentUser
              showRole
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <SNButton onClick={handleClose}>Cancel</SNButton>
        <SNButton
          snVariant="primary"
          onClick={handleAddNewProject}
          disabled={
            !newProjectVariables.name || !newProjectVariables.description
          }
        >
          Create Project
        </SNButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateProjectModal;
