import { Box, Tooltip, Typography } from '@mui/material';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { Skeleton } from '@mui/material';

import React from 'react';
import { useGetGroupWithProjectAccessQuery } from '../../queries/useGetGroupWithProjectAccessQuery';
import styled from '../../services/styled';
import pluralize from 'pluralize';
import { collectErrors } from '../../utils/collectErrors';
import { useSetRecoilState } from 'recoil';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';

const BulletedList = styled('ul')`
  list-style-type: disc;
  margin: 8px 0;
  padding-left: 20px;
`;

interface GroupProjectAccessProps {
  id: string;
}

const GroupProjectAccess = ({ id }: GroupProjectAccessProps) => {
  const { data, loading } = useGetGroupWithProjectAccessQuery({
    variables: { id },
  });
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.group?.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error fetching group project access',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <>
      {loading && (
        <Typography>
          <Skeleton width={100} />
        </Typography>
      )}
      {!loading && !!data?.group?.group?.groupProjectAccess?.totalCount && (
        <>
          <Typography>
            {data.group.group.groupProjectAccess.totalCount}&nbsp;
            {pluralize(
              'project',
              data.group.group.groupProjectAccess.totalCount || 0,
            )}
          </Typography>
          <Box ml={1} fontSize="1rem" color="text.secondary" display="flex">
            <Tooltip
              title={
                <Box p={1}>
                  <Typography>
                    This group has access to the following projects:
                  </Typography>
                  <BulletedList>
                    {data.group.group.groupProjectAccess.edges.map(
                      ({ node }) => (
                        <li key={node.id}>
                          <Typography>{node.project?.name}</Typography>
                        </li>
                      ),
                    )}
                  </BulletedList>
                  {data.group.group.groupProjectAccess.totalCount >
                    data.group.group.groupProjectAccess.edges.length && (
                    <Box pl={1}>
                      <Typography>
                        +
                        {data.group.group.groupProjectAccess.totalCount -
                          data.group.group.groupProjectAccess.edges.length}
                        &nbsp;additional projects
                      </Typography>
                    </Box>
                  )}
                </Box>
              }
            >
              <HelpOutline fontSize="inherit" color="inherit" />
            </Tooltip>
          </Box>
        </>
      )}
      {!loading && !data?.group?.group?.groupProjectAccess?.totalCount && (
        <Typography color="textSecondary">No project access</Typography>
      )}
    </>
  );
};

export default GroupProjectAccess;
