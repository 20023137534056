import { UserRole } from '../../types/schemaTypes';
import { ReactNode } from 'react';
import { useIsUserAllowedTo } from '../../hooks';

interface RoleMaskProps {
  allowedRoles: UserRole[];
  children: ReactNode;
}

const RoleMask = ({ allowedRoles, children }: RoleMaskProps) => {
  const isAllowed = useIsUserAllowedTo(allowedRoles);

  if (isAllowed) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default RoleMask;
