import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { RemoveGroup, RemoveGroupVariables } from '../types/schemaTypes';

export const DELETE_GROUP = gql`
  mutation RemoveGroup($input: DeleteInput!) {
    removeGroup(input: $input) {
      id
      errors
    }
  }
`;

export const useDeleteGroupMutation = (
  options?: MutationHookOptions<RemoveGroup, RemoveGroupVariables>,
) => {
  return useMutation<RemoveGroup, RemoveGroupVariables>(DELETE_GROUP, options);
};
