// it seems most of our mutations return error fields as string[] | null | undefined. this function takes an array of error arrays.
// (for use in components where multiple mutations are called and all possibly return error arrays)
// makes sure they're not null or underfined, then combines their contents into a single string array for display on the front end.

const filterToDefinedNonNull = <T>(arg: undefined | null | T): arg is T =>
  arg !== null && arg !== undefined;

export const collectErrors = (
  mutationErrorList: ((string | null)[] | null | undefined)[],
): string[] => {
  return mutationErrorList
    .filter(filterToDefinedNonNull)
    .flatMap((error) => error)
    .filter(filterToDefinedNonNull);
};
