import React from 'react';
import { Auth0User } from '../types';

interface UserProviderProps {
  user: Auth0User;
  children: React.ReactNode;
}

type UserState = Auth0User;

type Dispatch = (action: React.SetStateAction<UserState>) => void;

const UserStateContext = React.createContext<UserState | undefined>(undefined);
const UserDispatchContext = React.createContext<Dispatch | undefined>(
  undefined,
);

function UserProvider({ user, children }: UserProviderProps) {
  const [state, setState] = React.useState<UserState>(user);
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={setState}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}
function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}
export { UserProvider, useUserState, useUserDispatch };
