import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { Box } from '@mui/material';
import { useUnclassifiedGroupMembersQuery } from '../../queries/useUnclassifiedGroupMembers';
import { ClassificationTableDetails } from '../../types/schemaTypes';
import SNPagination from '../SNPagination';
import SNTableRow from '../SNTableRow';
import SNToolbar from '../SNToolbar';
import { SearchResultDatum } from '../../types';
import StickyColumnHeading from '../StickyColumnHeading';
import styled from '../../services/styled';
import { useOnScreen } from '../../hooks/useOnScreen';
import LoadingOverlay from '../LoadingOverlay';
import { useSearchInput } from '../../hooks';
import ListSelectionControl from '../ListSelectionControl';
import { useSetRecoilState } from 'recoil';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { collectErrors } from '../../utils/collectErrors';

const StyledTableBox = styled(Box)`
  height: calc(100% - 65px);
  overflow: auto;
  position: relative;
`;

const StyledTable = styled('table')`
  width: 100%;
  border-collapse: collapse;
`;

const StyledScrollTracker = styled('tr')`
  transform: translateY(-34px); // table heading height
`;

export interface ClassificationTableProps {
  groupId: string;
  searchId: string;
}

const ClassificationTable = ({
  groupId,
  searchId,
}: ClassificationTableProps) => {
  const pageSize = 30;
  const [ref, setRef] = React.useState<React.RefObject<HTMLTableRowElement>>({
    current: null,
  });
  const isOnScreen = useOnScreen(ref, {});
  const measuredRef = React.useCallback((node: HTMLTableRowElement | null) => {
    setRef({ current: node });
  }, []);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const [searchResultValues, setSearchResultValues] = React.useState<
    Record<string, SearchResultDatum>[]
  >([]);

  const client = useApolloClient();
  const unclassifiedGroup = client.readFragment<ClassificationTableDetails>({
    id: `UnclassifiedResultGroup:${groupId}`,
    fragment: gql`
      fragment ClassificationTableDetails on UnclassifiedResultGroup {
        id
        name
        headers
        totalCount
      }
    `,
  });

  const generateSearchInput = useSearchInput(searchId);
  const searchQuery = React.useMemo(() => {
    return generateSearchInput();
  }, [generateSearchInput]);

  const { data, error, loading, fetchMore } = useUnclassifiedGroupMembersQuery({
    variables: {
      first: pageSize,
      groupId,
      searchQuery,
    },
  });

  React.useEffect(() => {
    if (data?.unclassifiedGroupMembers?.data) {
      setSearchResultValues(
        data.unclassifiedGroupMembers.data.edges.map<
          Record<string, SearchResultDatum>
        >((row) => {
          const {
            node: {
              entity: {
                createdBy,
                createdOn,
                fieldValues,
                lastModified,
                linkSummaries,
                metadata: { sourceType },
                userMetadata,
              },
            },
          } = row;

          return {
            'system.createdOn': { value: createdOn },
            'system.createdBy': { value: createdBy.name || '' },
            'system.lastModified': { value: lastModified },
            'system.sourceType': { value: sourceType || '' },
            'user.metadata': {
              value: userMetadata.length.toString(),
              additionalData: userMetadata,
            },
            ...linkSummaries.reduce<Record<string, SearchResultDatum>>(
              (data, linkSummary) => ({
                ...data,
                [linkSummary.linkDefinition.id]: {
                  value: linkSummary.value,
                  linkSummaryId: linkSummary.id,
                },
              }),
              {},
            ),
            ...fieldValues.reduce<Record<string, SearchResultDatum>>(
              (data, fieldValue) => ({
                ...data,
                [fieldValue.fieldDefinition.id]: {
                  value: fieldValue.value,
                  annotations: fieldValue.annotations,
                  displayStatus: fieldValue.displayStatus,
                },
              }),
              {},
            ),
          };
        }),
      );
    }
  }, [data]);

  const pageTotal = data?.unclassifiedGroupMembers.data?.edges.length || 0;
  const isOverlapping = pageTotal > 0 && !isOnScreen;

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.unclassifiedGroupMembers?.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Unable to fetch unclassified group members',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <Box height="100%">
      <SNToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            {...data?.unclassifiedGroupMembers.data?.pageInfo}
            fetchMore={fetchMore}
            loading={loading}
            pageSize={pageSize}
            pageTotal={pageTotal}
            totalCount={
              data?.unclassifiedGroupMembers.data?.totalCount ||
              unclassifiedGroup?.totalCount
            }
          />
        </Box>
      </SNToolbar>
      <StyledTableBox>
        <LoadingOverlay
          isLoading={loading}
          data-testid="loading-group-members"
        />
        {data && !error && (
          <StyledTable>
            <thead data-testid="classification-table-headers">
              <tr>
                {unclassifiedGroup?.headers.map((header, index) => (
                  <StickyColumnHeading
                    key={header}
                    isOverlapping={isOverlapping}
                    pl={index === 0 ? 4 : 0}
                    minWidth={index === 0 ? 232 : 200}
                  >
                    {header}
                  </StickyColumnHeading>
                ))}
              </tr>
            </thead>
            <tbody>
              <StyledScrollTracker ref={measuredRef} />
              {data?.unclassifiedGroupMembers.data?.edges.map(
                (edge, rowIndex) => (
                  <SNTableRow key={edge.node.entity.id}>
                    {unclassifiedGroup?.headers.map((header, columnIndex) => (
                      <td key={header}>
                        <Box
                          display="flex"
                          alignItems="center"
                          height={56}
                          pl={columnIndex === 0 ? 4 : 0}
                        >
                          {searchResultValues[rowIndex] &&
                          searchResultValues[rowIndex][header]
                            ? searchResultValues[rowIndex][header].value
                            : ''}
                        </Box>
                      </td>
                    ))}
                  </SNTableRow>
                ),
              )}
            </tbody>
          </StyledTable>
        )}
      </StyledTableBox>
    </Box>
  );
};

export default ClassificationTable;
