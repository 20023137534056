import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Tooltip, Typography } from '@mui/material';
import RolesExplanationModal from '../RolesExplanationModal';
import styled from '../../services/styled';

const StyledHelpIcon = styled(HelpOutlineIcon)`
  color: ${(p) => p.theme.palette.text.secondary};
  cursor: pointer;
`;

interface RoleModalHeaderButtonProps {
  label: string;
}

const RoleHeaderModalButton = ({ label }: RoleModalHeaderButtonProps) => {
  const [roleModalOpen, setRoleModalOpen] = React.useState(false);

  const handleSetRoleModal = (open: boolean) => {
    setRoleModalOpen(open);
  };

  return (
    <Box display="flex" alignItems="center">
      <Box mr={1}>
        <Typography variant="h6" color="textSecondary">
          {label}
        </Typography>
      </Box>
      <Tooltip title="Click for more info about roles and permissions">
        <StyledHelpIcon onClick={() => setRoleModalOpen(!roleModalOpen)} />
      </Tooltip>
      <RolesExplanationModal
        open={roleModalOpen}
        setOpen={handleSetRoleModal}
      />
    </Box>
  );
};

export default RoleHeaderModalButton;
