import styled from '../../services/styled';
interface SNTableRowProps {
  selected?: boolean;
}
const SNTableRow = styled('tr')<SNTableRowProps>`
  height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[700]};
  background-color: ${({ selected, theme }) =>
    selected ? theme.palette.grey[700] : theme.palette.background.default};
  :hover {
    background-color: ${({ theme }) => theme.palette.grey[700]};
  }
`;

export default SNTableRow;
