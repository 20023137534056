import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import Checkbox from '../Checkbox';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { listItemByIndexSelector } from '../../atoms/listSelection';
import { UserRole } from '../../types/schemaTypes';
import SNTableRow from '../SNTableRow';
import UserAvatar from '../UserAvatar';
import { Link as RouterLink } from 'react-router-dom';
import ListSummaryTag from '../ListSummaryTag';
import RoleDropdown from '../RoleDropdown';
import { useUpdateSystemRoleMutation } from '../../mutations/useUpdateSystemRoleMutation';
import { SystemRoleChangeWarningModalOpenAtom } from '../../atoms/SystemRoleChangeWarningModalOpenAtom';
import { useIsUserAllowedTo } from '../../hooks';

interface UsersTableRowProps {
  email: string;
  id: string;
  name: string | null;
  picture: string | null;
  defaultRole: UserRole;
  groupMembershipCount: number;
  projectAccessCount: number;
  index: number;
  source: string | null;
}

const UsersTableRow = ({
  name,
  id,
  email,
  picture,
  defaultRole,
  groupMembershipCount,
  projectAccessCount,
  index,
  source,
}: UsersTableRowProps) => {
  const [selected, selectRow] = useRecoilState(listItemByIndexSelector(index));
  const setRoleWarningOpen = useSetRecoilState(
    SystemRoleChangeWarningModalOpenAtom,
  );
  const [changeSystemRole] = useUpdateSystemRoleMutation({
    onCompleted: () => {
      setRoleWarningOpen(true);
    },
  });
  const isAllowed = useIsUserAllowedTo([UserRole.ADMINISTRATOR]);
  const handleRowSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      selectRow(checked);
    },
    [selectRow],
  );

  const handleRoleChange = (user: string, role: UserRole) => {
    changeSystemRole({
      variables: {
        input: {
          id: user,
          defaultRole: role,
        },
      },
    });
  };

  return (
    <SNTableRow selected={selected} key={id}>
      <td>
        <Box display="block" pl={4} pr={3}>
          <Checkbox checked={selected} onChange={handleRowSelect} />
        </Box>
      </td>
      <td>
        <Link to={`/settings/users/${id}`} component={RouterLink}>
          <Box display="flex" alignItems="center" height={56} mr={2}>
            <UserAvatar name={name || ''} picture={picture || ''} />
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/users/${id}`} component={RouterLink}>
          <Box display="flex" alignItems="center" height={56}>
            <Box fontWeight={600}>
              <Typography>{name}</Typography>
            </Box>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/users/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <Box overflow="hidden" textOverflow="ellipsis">
              <Typography>{email}</Typography>
            </Box>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/users/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <RoleDropdown
              id={id}
              currentRole={defaultRole}
              onRoleChange={handleRoleChange}
              disabled={!isAllowed}
            />
          </Box>
        </Link>
      </td>
      <td>
        {groupMembershipCount > 0 ? (
          <Link to={`/settings/users/${id}/groups`} component={RouterLink}>
            <Box height={56} display="flex" alignItems="center">
              <ListSummaryTag
                remainingLabelPlural="Groups"
                remainingLabelSingular="Group"
                totalCount={groupMembershipCount}
              />
            </Box>
          </Link>
        ) : (
          <Link to={`/settings/users/${id}`} component={RouterLink}>
            <Box height={56} display="flex" alignItems="center">
              <Typography color="textSecondary">
                User is in no groups
              </Typography>
            </Box>
          </Link>
        )}
      </td>
      <td>
        {projectAccessCount > 0 ? (
          <Link to={`/settings/users/${id}/projects`} component={RouterLink}>
            <Box height={56} display="flex" alignItems="center">
              <ListSummaryTag
                remainingLabelPlural="Projects"
                remainingLabelSingular="Project"
                totalCount={projectAccessCount}
              />
            </Box>
          </Link>
        ) : (
          <Link to={`/settings/users/${id}`} component={RouterLink}>
            <Box height={56} display="flex" alignItems="center">
              <Typography color="textSecondary">
                User has no project access
              </Typography>
            </Box>
          </Link>
        )}
      </td>
      <td>
        <Link to={`/settings/users/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            {source}
          </Box>
        </Link>
      </td>
    </SNTableRow>
  );
};

export default UsersTableRow;
