import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
  AllowedTargetEntityTypes,
  AllowedTargetEntityTypesVariables,
} from '../types/schemaTypes';

export const ALLOWED_TARGET_ENTITY_TYPES = gql`
  query AllowedTargetEntityTypes($filter: AllowedTargetEntityTypesInput!) {
    allowedTargetEntityTypes(filter: $filter) {
      status {
        message
        severity
      }
      entityTypes {
        id
      }
    }
  }
`;

export const useAllowedTargetEntityTypesQuery = (
  variables: AllowedTargetEntityTypesVariables,
) => {
  return useQuery<AllowedTargetEntityTypes, AllowedTargetEntityTypesVariables>(
    ALLOWED_TARGET_ENTITY_TYPES,
    {
      notifyOnNetworkStatusChange: true,
      variables,
    },
  );
};
