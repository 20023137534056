import { useParams } from 'react-router-dom';
import { Redirect, Switch, Route } from 'react-router-dom';
import { useUserQuery } from '../../queries';

import UserDetailProjectsTable from '../../components/UserProjectsTable';
import UserDetailGroupsTable from '../../components/UserGroupsTable';
import DetailTabs from '../../components/DetailTabs';

const userDetailTabsArray = ['projects', 'groups'];

const UserDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useUserQuery({ variables: { id } });

  return (
    <DetailTabs
      baseRoute="settings/users"
      id={id}
      loading={loading}
      tabs={userDetailTabsArray}
      title={data?.user?.user?.name || 'User Not Found'}
    >
      <Switch>
        <Route path="/settings/users/:id/projects">
          <UserDetailProjectsTable />
        </Route>
        <Route path="/settings/users/:id/groups">
          <UserDetailGroupsTable />
        </Route>
        <Redirect to={`/settings/users/${id}/projects`} />
      </Switch>
    </DetailTabs>
  );
};

export default UserDetail;
