import React from 'react';

type setTrue = () => void;
type setFalse = () => void;

export const useToggle = (
  initialValue: boolean,
): [boolean, setTrue, setFalse] => {
  const [isTrue, setIsTrue] = React.useState(initialValue);

  const handleSetTrue = React.useCallback(() => {
    setIsTrue(true);
  }, []);

  const handleSetFalse = React.useCallback(() => {
    setIsTrue(false);
  }, []);

  return [isTrue, handleSetTrue, handleSetFalse];
};
