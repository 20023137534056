import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import {
  GroupsOfUserForProfile,
  GroupsOfUserForProfileVariables,
} from '../types/schemaTypes';

export const GROUPS_OF_USER_FOR_PROFILE = gql`
  query GroupsOfUserForProfile(
    $id: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: GroupFilter
  ) {
    user(id: $id) {
      user {
        id
        name
        picture
        email
        defaultRole
        groupMembership(
          after: $after
          before: $before
          first: $first
          last: $last
          filter: $filter
        ) {
          totalCount
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
            endIndex @client
            startIndex @client
          }
          edges {
            cursor
            node {
              id
              user {
                id
                name
                picture
              }
              createdAt
              lastModified
              group {
                id
                name
                description
                createdBy {
                  name
                  id
                  picture
                }
                groupMembership(first: 5) {
                  totalCount
                  edges {
                    cursor
                    node {
                      user {
                        id
                        name
                        picture
                      }
                    }
                  }
                }
                groupProjectAccess(first: 0) {
                  totalCount
                }
              }
            }
          }
        }
      }
      errors
    }
  }
`;

export const useLazyUserProfileGroupsQuery = (
  options?: LazyQueryHookOptions<
    GroupsOfUserForProfile,
    GroupsOfUserForProfileVariables
  >,
) => {
  return useLazyQuery<GroupsOfUserForProfile, GroupsOfUserForProfileVariables>(
    GROUPS_OF_USER_FOR_PROFILE,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );
};

export const useUserProfileGroupsQuery = (
  options?: QueryHookOptions<
    GroupsOfUserForProfile,
    GroupsOfUserForProfileVariables
  >,
) => {
  return useQuery<GroupsOfUserForProfile, GroupsOfUserForProfileVariables>(
    GROUPS_OF_USER_FOR_PROFILE,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );
};
