import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  ApproveEntities,
  ApproveEntitiesVariables,
} from '../types/schemaTypes';

export const APPROVE_ENTITIES = gql`
  mutation ApproveEntities($input: ApproveEntitiesInput!) {
    approveEntities(input: $input) {
      success
      errors
    }
  }
`;

export const useApproveEntitiesMutation = (
  options?: MutationHookOptions<ApproveEntities, ApproveEntitiesVariables>,
) => {
  return useMutation<ApproveEntities, ApproveEntitiesVariables>(
    APPROVE_ENTITIES,
    options,
  );
};
