import { Link, LinkProps, Typography } from '@mui/material';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from '../../services/styled';
import { Link as RouterLink } from 'react-router-dom';

export interface TopBarNavItemProps {
  to: string;
  additionalMatches?: string[];
}

const StyledTypography = styled(Typography)`
  transition: ${({ theme }) => theme.transitions.create('color')};
`;

interface StyledUnderlineProps extends LinkProps {
  to: string;
  match: boolean;
}

const StyledUnderline = styled(({ to, children }: StyledUnderlineProps) => (
  <Link component={RouterLink} to={to}>
    {children}
  </Link>
))`
  display: block;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  :hover {
    border-bottom-color: ${({ match, theme }) =>
      match ? theme.palette.text.primary : theme.palette.text.secondary};
  }
  transition: border-bottom-color 300ms ease-in-out;
`;

const TopBarNavItem: React.FC<TopBarNavItemProps> = ({
  children,
  to,
  additionalMatches = [],
}) => {
  const match = useRouteMatch([to, ...additionalMatches]);
  return (
    <StyledUnderline match={Boolean(match)} to={to}>
      <StyledTypography
        variant="h6"
        color={match ? 'textPrimary' : 'textSecondary'}
      >
        {children}
      </StyledTypography>
    </StyledUnderline>
  );
};

export default TopBarNavItem;
