import { SYSTEM_FIELD_HEADINGS } from '../constants';
import { GlideHeaderInfo } from '../types';
import {
  EntityTypes_entityTypes,
  EntityTypes_entityTypes_fields,
  SearchFilterValueType,
} from '../types/schemaTypes';

const getHeaderInfo = (
  field: EntityTypes_entityTypes_fields,
): GlideHeaderInfo => {
  return {
    id: field.id,
    title: field.name,
    field,
  };
};

export const entityTypeFieldsToColumns = (
  entityTypes: EntityTypes_entityTypes[],
): Record<string, GlideHeaderInfo[]> => {
  return entityTypes.reduce<Record<string, GlideHeaderInfo[]>>(
    (result, entityType) => {
      const dataColumns = entityType.fields.map(getHeaderInfo);
      const subjectLinksColumns = entityType.subjectLinks.map<GlideHeaderInfo>(
        (subjectLink) => ({
          id: subjectLink.id,
          title: subjectLink.name || '',
          type: 'linkSummary',
          icon: 'link',
          field: {
            type: SearchFilterValueType.STRING,
            inputOptions: {
              __typename: 'Autogenerated',
              displayOnEntry: true,
            },
          },
        }),
      );
      const objectLinkColumns = entityType.objectLinks.map<GlideHeaderInfo>(
        (objectLink) => ({
          id: objectLink.id,
          title: objectLink.objectLabel || '',
          type: 'linkSummary',
          icon: 'link',
          field: {
            type: SearchFilterValueType.STRING,
            inputOptions: {
              __typename: 'Autogenerated',
              displayOnEntry: true,
            },
          },
        }),
      );
      return {
        ...result,
        [entityType.id]: [
          ...SYSTEM_FIELD_HEADINGS.slice(0, 1),
          ...dataColumns,
          ...subjectLinksColumns,
          ...objectLinkColumns,
          ...SYSTEM_FIELD_HEADINGS.slice(1),
        ],
      };
    },
    {},
  );
};
