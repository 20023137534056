import { UserRole } from '../types/schemaTypes';

export const filterRoles = (defaultRole: UserRole) => {
  switch (defaultRole) {
    case UserRole.ADMINISTRATOR:
      return [
        UserRole.ADMINISTRATOR,
        UserRole.OWNER,
        UserRole.APPROVER,
        UserRole.COLLABORATOR,
        UserRole.READER,
        UserRole.GUEST,
      ];
    case UserRole.OWNER:
      return [
        UserRole.OWNER,
        UserRole.APPROVER,
        UserRole.COLLABORATOR,
        UserRole.READER,
        UserRole.GUEST,
      ];
    case UserRole.APPROVER:
      return [
        UserRole.APPROVER,
        UserRole.COLLABORATOR,
        UserRole.READER,
        UserRole.GUEST,
      ];
    case UserRole.COLLABORATOR:
      return [UserRole.COLLABORATOR, UserRole.READER, UserRole.GUEST];
    case UserRole.READER:
      return [UserRole.GUEST, UserRole.READER];
    case UserRole.GUEST:
      return [UserRole.GUEST];
  }
};
