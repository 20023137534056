import { Box, BoxProps } from '@mui/material';

interface SNListItemProps extends BoxProps {
  showEmpty?: boolean;
}

const SNListItem: React.FC<SNListItemProps> = ({ showEmpty, ...rest }) => {
  if (showEmpty) {
    return (
      <Box
        position="relative"
        height={64}
        display="flex"
        alignItems="center"
        mt={1}
        px={2}
        border={1}
        borderColor="grey.600"
        {...rest}
      />
    );
  }

  return (
    <Box
      position="relative"
      height={64}
      bgcolor="background.default"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mt={1}
      px={2}
      {...rest}
    />
  );
};

export default SNListItem;
