import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import SelectableListToolbar from '../SelectableListToolbar';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import {
  pageIdsAtom,
  selectedIdsAtom,
  selectedIdsSelector,
} from '../../atoms/listSelection';
import { useLazyUsersPaginatedQuery } from '../../queries/useUsersPaginatedQuery';
import SNTable from '../SNTable';
import UsersTableRow from '../UsersTableRow/UsersTableRow';
import SNPagination from '../SNPagination';
import RoleHeaderModalButton from '../RoleHeaderModalButton';
import { useDebounce } from '../../hooks';
import ToolbarActionGroup from '../ToolbarActionGroup';
import SNButton from '../SNButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ListSearchControl from '../ListSearchControl';
import AddUserModal from '../AddUserModal';
import styled from '../../services/styled';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SystemRoleChangeWarningModal from '../SystemRoleChangeWarningModal';
import { SystemRoleChangeWarningModalOpenAtom } from '../../atoms/SystemRoleChangeWarningModalOpenAtom';
import RoleMask from '../RoleMask';
import {
  UserRole,
  Users_users_data_edges_node,
  UserStatus,
} from '../../types/schemaTypes';
import { useDeleteUserMutation } from '../../mutations/useDeleteUserMutation';
import SNDialogTitle from '../SNDialogTitle';
import { gql, useApolloClient } from '@apollo/client';
import SNStatusBanner from '../SNStatusBanner';
import ListSelectionControl from '../ListSelectionControl';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { collectErrors } from '../../utils/collectErrors';

const StyledHelpIcon = styled(HelpOutlineIcon)`
  color: ${(p) => p.theme.palette.text.secondary};
`;

const headers = [
  'name',
  'email',
  <RoleHeaderModalButton key="usersTableRoleButton" label="system role" />,
  'groups',
  'projects',
  <Box display="flex" alignItems="center" key="usersTableSourceButton">
    <Box mr={1}>
      <Typography variant="h6" color="textSecondary">
        Source
      </Typography>
    </Box>
    <Tooltip title="Source indicates what manner a user was added to the platform, and as a result, how you must manage them.">
      <StyledHelpIcon />
    </Tooltip>
  </Box>,
];

const UserTable = () => {
  const pageSize = 10;
  const client = useApolloClient();
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const selectedIds = useRecoilValue(selectedIdsAtom);
  const resetSelectedIds = useResetRecoilState(selectedIdsSelector);
  const [userDeleteErrors, setUserDeleteErrors] = React.useState<string[]>([]);
  const [deleteUser] = useDeleteUserMutation({
    update: (cache, result) => {
      if (result.data?.deleteUser?.user?.id) {
        cache.evict({
          id: `User:${result.data?.deleteUser.user.id}`,
        });
        cache.gc();
      }
    },
    onCompleted: (res) => {
      if (res.deleteUser?.errors && res.deleteUser.errors.length > 0) {
        setUserDeleteErrors(res.deleteUser.errors);
      } else {
        resetSelectedIds();
      }
    },
  });
  const [addUserModalOpen, setAddUserModalOpen] = React.useState(false);
  const [getUsers, { data, error, loading, fetchMore }] =
    useLazyUsersPaginatedQuery({
      onCompleted: (result) => {
        const pageIds = result?.users.data?.edges.map((edge) => edge.node.id);
        setPageIds(pageIds || []);
      },
    });
  const [roleWarningOpen, setRoleWarningOpen] = useRecoilState(
    SystemRoleChangeWarningModalOpenAtom,
  );
  const handleWarningClose = () => {
    setRoleWarningOpen(false);
  };

  const handleAddUserModalClose = () => {
    setAddUserModalOpen(false);
  };

  const isAuth0User = React.useMemo(() => {
    if (selectedIds.length === 1) {
      const user = client.readFragment<Users_users_data_edges_node>({
        id: `User:${selectedIds[0]}`,
        fragment: gql`
          fragment UserSource on User {
            id
            name
            source
          }
        `,
      });
      return user?.source === 'auth0';
    }
  }, [client, selectedIds]);

  const handleRemoveUser = (userId: string) => {
    if (isAuth0User) {
      deleteUser({ variables: { id: userId } });
    }
  };

  React.useEffect(() => {
    getUsers({
      variables: {
        first: pageSize,
        filter: {
          searchTerm: debouncedSearchTerm,
          status: UserStatus.ACTIVE,
        },
      },
    });
  }, [debouncedSearchTerm, pageSize, getUsers]);

  const users = React.useMemo(
    () => data?.users?.data?.edges.map((edge) => edge.node),
    [data],
  );

  const pageTotal = users?.length || 0;

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.users?.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Unable to fetch users',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <Box>
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            {...data?.users?.data?.pageInfo}
            fetchMore={fetchMore}
            loading={loading}
            pageSize={pageSize}
            pageTotal={pageTotal}
            totalCount={data?.users?.data?.totalCount}
          />
        </Box>
        <Box>
          <Grid container spacing={1}>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 0}
            >
              <Grid item>
                <Dialog
                  fullWidth
                  open={addUserModalOpen}
                  onClose={setAddUserModalOpen}
                >
                  <AddUserModal onClose={handleAddUserModalClose} />
                </Dialog>
                <RoleMask allowedRoles={[UserRole.ADMINISTRATOR]}>
                  <SNButton
                    startIcon={<AddIcon />}
                    snVariant="text"
                    onClick={() => setAddUserModalOpen(true)}
                  >
                    Add User
                  </SNButton>
                </RoleMask>
              </Grid>
              <Grid item>
                <ListSearchControl
                  setValue={setSearchTerm}
                  value={searchTerm}
                />
              </Grid>
            </ToolbarActionGroup>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 1}
            >
              <SNButton
                disabled={!isAuth0User}
                snVariant="destructive"
                startIcon={<DeleteIcon />}
                onClick={() => handleRemoveUser(selectedIds[0])}
              >
                Remove User
              </SNButton>
              <Dialog
                open={userDeleteErrors.length > 0}
                maxWidth="sm"
                fullWidth
              >
                <SNDialogTitle onClose={() => setUserDeleteErrors([])}>
                  Cant Remove User
                </SNDialogTitle>
                <DialogContent>
                  <SNStatusBanner status="error">
                    <Box>
                      <ul>
                        {userDeleteErrors.map((error) => {
                          return <li key={error}>{error}</li>;
                        })}
                      </ul>
                    </Box>
                  </SNStatusBanner>
                </DialogContent>
                <DialogActions>
                  <SNButton onClick={() => setUserDeleteErrors([])}>
                    OK
                  </SNButton>
                </DialogActions>
              </Dialog>
            </ToolbarActionGroup>
            <SystemRoleChangeWarningModal
              isOpen={roleWarningOpen}
              setClosed={handleWarningClose}
            />
          </Grid>
        </Box>
      </SelectableListToolbar>
      <SNTable
        avatar
        error={error}
        hasResults={!!users}
        headers={headers}
        id="users"
        loading={loading}
        rowCount={pageTotal}
        selectable
      >
        {users?.map(({ groupMembership, projectAccess, ...user }, index) => (
          <UsersTableRow
            key={user.id}
            index={index}
            groupMembershipCount={groupMembership?.totalCount || 0}
            projectAccessCount={projectAccess?.totalCount || 0}
            {...user}
          />
        ))}
      </SNTable>
    </Box>
  );
};

export default UserTable;
