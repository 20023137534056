import React from 'react';
import { styled, Typography } from '@mui/material';
import { LayerProps } from 'react-laag';
import { PaletteStatus } from '../../types';

interface TooltipProps extends Omit<LayerProps, 'ref'> {
  themeColor: PaletteStatus | undefined;
  themeVariant: 'main' | 'light' | 'dark';
}

const Tooltip = styled('div', {
  shouldForwardProp: (propName) => {
    if (propName === 'themeColor' || propName === 'themeVariant') {
      return false;
    }
    return true;
  },
})<TooltipProps>({}, ({ themeColor = 'info', style, theme, themeVariant }) => ({
  ...style,
  backgroundColor: theme.palette.background.paper,
  borderColor:
    themeColor && themeVariant
      ? theme.palette[themeColor][themeVariant]
      : 'transparent',
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: theme.spacing(2),
}));

const StyledList = styled('ul')`
  padding-left: ${({ theme }) => theme.spacing(2)};
  list-style-type: initial;
`;

interface AnnotationTooltipProps extends TooltipProps {
  messages: string[];
}

const AnnotationTooltip = React.forwardRef<
  HTMLDivElement,
  AnnotationTooltipProps
>(({ messages, ...rest }, ref) => {
  return (
    <Tooltip {...rest} ref={ref}>
      <StyledList>
        {messages.map((message, index) => (
          <li key={`${message}_${index}_${Math.random() * 1000}`}>
            <Typography>{message}</Typography>
          </li>
        ))}
      </StyledList>
    </Tooltip>
  );
});

AnnotationTooltip.displayName = 'AnnotationTooltip';

export default AnnotationTooltip;
