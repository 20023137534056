import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
  GroupWithMembership,
  GroupWithMembershipVariables,
} from '../types/schemaTypes';

export const GROUP_WITH_MEMBERSHIP = gql`
  query GroupWithMembership($id: ID!) {
    group(id: $id) {
      group {
        id
        name
        groupMembership(first: 5) {
          totalCount
          edges {
            node {
              id
              user {
                id
                name
                picture
              }
            }
          }
        }
      }
      errors
    }
  }
`;

export const useGetGroupWithMembershipQuery = (
  options?: QueryHookOptions<GroupWithMembership, GroupWithMembershipVariables>,
) => {
  return useQuery<GroupWithMembership, GroupWithMembershipVariables>(
    GROUP_WITH_MEMBERSHIP,
    {
      ...options,
    },
  );
};
