import React from 'react';
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material/Autocomplete';
import { ExpandMore } from '@mui/icons-material';

function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(props: MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  return <MuiAutocomplete popupIcon={<ExpandMore />} {...props} />;
}

export default Autocomplete;
