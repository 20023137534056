import { Box, Grid, Tooltip, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React from 'react';
import NewSearchForm from '../../components/NewSearchForm';
import { SearchSaveAdd } from '../../components/SearchSave';
import {
  searchTreeActions,
  TabOverflowProvider,
  useSaveSearchDispatch,
  useSearchTreeDispatch,
  useSearchTreeRoots,
} from '../../contexts';
import SearchContainer from '../SearchContainer';
import { useHistory } from 'react-router-dom';
import styled from '../../services/styled';
import SNButton from '../../components/SNButton';
import { ShowFiltersProvider } from '../../contexts/ShowFiltersContext';
import SearchFilterToggle from '../../components/SearchFilterToggle';
import { unsavedSearchVar } from '../../localState';
import { NEW_SEARCH_KEY } from '../../constants';
import { latestSearchAtom } from '../../atoms/latestSearchAtom';
import { useResetRecoilState } from 'recoil';

const StyledButton = styled(SNButton)`
  min-width: 150px;
`;

const SearchNew = () => {
  const dispatch = useSearchTreeDispatch();
  const setSaveSearchState = useSaveSearchDispatch();
  const roots = useSearchTreeRoots(NEW_SEARCH_KEY);
  const { push } = useHistory();
  const resetLatestSearch = useResetRecoilState(latestSearchAtom);

  React.useEffect(() => {
    resetLatestSearch();
  }, [resetLatestSearch]);

  const handleSaveClick = React.useCallback(() => {
    setSaveSearchState('SAVE_AS');
  }, [setSaveSearchState]);

  const handleSaveComplete = React.useCallback(
    (id: string) => {
      unsavedSearchVar(false);
      push(`/search/${id}`);
      dispatch(
        searchTreeActions.tree.set({
          key: NEW_SEARCH_KEY,
          branches: {},
          roots: [],
        }),
      );
    },
    [dispatch, push],
  );

  if (roots.length === 0) {
    return <NewSearchForm searchId={NEW_SEARCH_KEY} />;
  }

  return (
    <ShowFiltersProvider>
      <Box position="relative">
        <Box
          display="flex"
          justifyContent="space-between"
          alignContent="center"
          py={2}
          px={4}
        >
          <Tooltip title="Save Search">
            <Box role="button" onClick={handleSaveClick}>
              <Typography variant="h1">Untitled Search</Typography>
            </Box>
          </Tooltip>
          <Box>
            <Grid container spacing={1}>
              <Grid item>
                <SearchFilterToggle />
              </Grid>
              <Grid item>
                <StyledButton
                  snVariant="text"
                  startIcon={<SaveIcon />}
                  onClick={handleSaveClick}
                >
                  Save Search
                </StyledButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <TabOverflowProvider>
          <SearchContainer searchId={NEW_SEARCH_KEY} />
        </TabOverflowProvider>
        <SearchSaveAdd
          onSaveComplete={handleSaveComplete}
          searchId={NEW_SEARCH_KEY}
          title="Save New Search"
        />
      </Box>
    </ShowFiltersProvider>
  );
};

export default SearchNew;
