import { useLazyQuery, LazyQueryHookOptions } from '@apollo/client';
import gql from 'graphql-tag';
import { Uploads, UploadsVariables } from '../types/schemaTypes';

export const UPLOADS = gql`
  query Uploads(
    $first: Int
    $last: Int
    $filter: UploadFilter
    $before: String
    $after: String
  ) {
    uploads(
      last: $last
      first: $first
      filter: $filter
      before: $before
      after: $after
    ) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
        startIndex @client
        endIndex @client
      }
      edges {
        cursor
        node {
          uploadState
          uploadDetails {
            id
            completedAt
            fileName
            fileMimeType
            name
            subset
            uploadType
            user {
              name
              picture
            }
          }
          processingDetails {
            importCount
            reviewCount
          }
        }
      }
    }
  }
`;

export const useUploadsQuery = (
  options?: LazyQueryHookOptions<Uploads, UploadsVariables>,
) => {
  return useLazyQuery<Uploads, UploadsVariables>(UPLOADS, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};
