import { Dialog, Theme, useMediaQuery } from '@mui/material';
import React from 'react';
import { SEARCH_QUERY_BRANCH_DEFAULT } from '../../constants';
import {
  SearchFormProvider,
  useSearchTreeBranches,
  useSearchTreeNavDispatch,
  useSearchTreeNavState,
} from '../../contexts';
import SearchFormDialog from '../SearchFormDialog';

interface SearchTreeFormProps {
  isNewSearch?: boolean;
  searchId: string;
}

const SearchTreeForm: React.FC<SearchTreeFormProps> = ({
  isNewSearch,
  searchId,
}) => {
  const branches = useSearchTreeBranches(searchId);
  const { showForm, activeId, parentId } = useSearchTreeNavState();
  const setSearchTreeNavState = useSearchTreeNavDispatch();
  const matches = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const handleDialogClose = React.useCallback(() => {
    setSearchTreeNavState({ showForm: false, activeId: null, parentId: null });
  }, [setSearchTreeNavState]);

  if (!showForm) return null;

  return (
    <SearchFormProvider
      initialState={
        activeId
          ? branches[activeId]
          : { ...SEARCH_QUERY_BRANCH_DEFAULT, parent: parentId || '' }
      }
    >
      <Dialog
        open
        onClose={handleDialogClose}
        data-testid="search-filter-form"
        fullScreen={!matches}
        fullWidth={matches}
        maxWidth={matches ? 'md' : undefined}
      >
        <SearchFormDialog
          isNewSearch={isNewSearch}
          onClose={handleDialogClose}
          searchId={searchId}
        />
      </Dialog>
    </SearchFormProvider>
  );
};

export default SearchTreeForm;
