import Edit from '@mui/icons-material/Edit';
import { Dialog } from '@mui/material';
import produce from 'immer';
import React from 'react';
import { useEditEntitiesDispatch, useEditEntitiesState } from '../../contexts';
import { useToggle } from '../../hooks/useToggle';
import { useEditEntitiesMutation } from '../../mutations/useEditEntitiesMutation';
import { editContextToMutationTransformer } from '../../utils/EditContextToMutationTransformer';
import { reduceValidations } from '../../utils/reduceValidations';
import EditConfirmationSnackbar from '../EditConfirmationSnackbar';
import EditEntityModal from '../EditEntityModal';
import EntityEditApprovalModal from '../EntityEditApprovalModal';
import LoadingOverlay from '../LoadingOverlay';
import SNButton from '../SNButton';

export interface ActionEditEntityProps {
  entityId: string;
  entityTypeId: string | undefined;
}

const ActionEditEntity = ({
  entityId,
  entityTypeId = '',
}: ActionEditEntityProps) => {
  const editState = useEditEntitiesState();
  const setEditState = useEditEntitiesDispatch();
  const [editOpen, setEditOpen, setEditClosed] = useToggle(false);
  const [approvalOpen, setApprovalOpen, setApprovalClosed] = useToggle(false);
  const [confirmedEditCount, setConfirmedEditCount] = React.useState(0);
  const [confirmationOpen, setConfirmationOpen, setConfirmationClose] =
    useToggle(false);

  const [editEntities, { loading }] = useEditEntitiesMutation({});

  const handleEditSubmit = () => {
    editEntities({
      variables: {
        input: {
          entities: editContextToMutationTransformer(editState, entityTypeId),
        },
      },
      update: (cache, result) => {
        result.data?.editEntities.data?.map((node) => {
          cache.modify({
            id: `Entity:${node.id}`,
            fields: {
              fieldValues: () => {
                return node.fieldValues;
              },
            },
          });
        });
      },
      onCompleted: (result) => {
        if (result?.editEntities.data && result.editEntities.data.length > 0) {
          const count = Object.values(editState.edits).reduce(
            (result, edit) => result + Object.keys(edit).length,
            0,
          );
          setConfirmedEditCount(count);
          setConfirmationOpen();
          setEditClosed();
        } else {
          const reducedValidations = reduceValidations(result);
          if (reducedValidations) {
            setEditState((editState) =>
              produce(editState, (draft) => {
                draft.validations = reducedValidations;
              }),
            );
          }
        }
      },
    });
  };

  const handleApprovalSubmit = React.useCallback(
    (value: string) => {
      editEntities({
        variables: {
          input: {
            entities: editContextToMutationTransformer(editState, entityTypeId),
            approval: {
              reason: value,
            },
          },
        },
        onCompleted: (result) => {
          if (
            result?.editEntities.data &&
            result.editEntities.data.length > 0
          ) {
            const count = Object.values(editState.edits).reduce(
              (result, edit) => result + Object.keys(edit).length,
              0,
            );
            setConfirmedEditCount(count);
            setConfirmationOpen();
            /*
              To add back when edits persist
              // handleEditClear();
            */
          }
          setApprovalClosed();
          setEditClosed();
        },
      });
    },
    [
      editEntities,
      editState,
      entityTypeId,
      setApprovalClosed,
      setConfirmationOpen,
      setEditClosed,
    ],
  );

  const handleConfirmationClose = React.useCallback(() => {
    setConfirmationClose();
    setTimeout(() => {
      setConfirmedEditCount(0);
    }, 100);
  }, [setConfirmationClose]);

  return (
    <>
      <SNButton
        disabled={!entityTypeId}
        snVariant="text"
        startIcon={<Edit />}
        onClick={setEditOpen}
      >
        Edit
      </SNButton>
      <Dialog open={editOpen} onClose={setEditClosed} fullWidth>
        <EditEntityModal
          entityId={entityId}
          entityTypeId={entityTypeId}
          onApprove={setApprovalOpen}
          onClose={setEditClosed}
          onSubmit={handleEditSubmit}
        />
      </Dialog>
      <Dialog
        open={approvalOpen}
        onClose={setApprovalClosed}
        fullWidth
        maxWidth="sm"
      >
        <EntityEditApprovalModal
          onClose={setApprovalClosed}
          onSubmit={handleApprovalSubmit}
        />
      </Dialog>
      <EditConfirmationSnackbar
        open={confirmationOpen}
        onClose={handleConfirmationClose}
        changeCount={confirmedEditCount}
      />
      <LoadingOverlay isLoading={loading} />
    </>
  );
};

export default ActionEditEntity;
