import React from 'react';
import styled from '../../services/styled';
import { Box, Typography } from '@mui/material';
import { getMuiVariant, SNVariants } from '../../services/theme';

interface PillProps {
  customColor?: string;
  onClick?: () => void;
  snVariant?: SNVariants;
}
const StyledBorderBox = styled(Box)<PillProps>`
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${(p) => p.customColor || p.theme.palette.primary.main};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
`;

const Pill: React.FC<PillProps> = ({
  customColor,
  onClick,
  snVariant = 'text',
  children,
}) => {
  return (
    <StyledBorderBox
      height={27}
      display="flex"
      alignItems="center"
      width="fit-content"
      maxWidth="100%"
      overflow="hidden"
      textOverflow="ellipsis"
      customColor={customColor}
      role={onClick ? 'button' : undefined}
      onClick={onClick}
    >
      <Typography variant={getMuiVariant(snVariant)} noWrap>
        {children}
      </Typography>
    </StyledBorderBox>
  );
};

export default Pill;
