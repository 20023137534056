import { FilterValueType } from '../types';
import { Connective, DataType } from '../types/schemaTypes';

export const CONNECTIVE_LABELS: Record<Connective, string> = {
  [Connective.AND]: 'And',
  [Connective.OR]: 'Or',
};

export const DATA_TYPE_TO_FILTER_TYPE: Record<DataType, FilterValueType> = {
  INT: 'NUMBER',
  FLOAT: 'NUMBER',
  STRING: 'STRING',
  DATETIME: 'DATE',
  BOOLEAN: 'BOOLEAN',
  URI: 'STRING',
  URL: 'STRING',
};
