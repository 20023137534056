import React from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import SNButton from '../SNButton';
import SNInput from '../SNInput';
import { useAddGroupMutation } from '../../mutations/useAddGroupMutation';
import { AddGroupInput } from '../../types/schemaTypes';
import { useAddUsersToGroupsMutation } from '../../mutations/useAddUsersToGroupsMutation';
import UsersToAddSelector from '../UsersToAddSelector';
import { SelectedUsers } from '../UsersToAddSelector/UsersToAddSelector';
import SNDialogTitle from '../SNDialogTitle';
import SNStatusBanner from '../SNStatusBanner';
import { collectErrors } from '../../utils/collectErrors';

interface CreateGroupModalProps {
  onClose: () => void;
}

const CreateGroupModal = ({ onClose }: CreateGroupModalProps) => {
  const [newGroupVariables, setNewGroupVariables] =
    React.useState<AddGroupInput>({ name: '', description: '' });
  const [selectedUsers, setSelectedUsers] = React.useState<SelectedUsers>({});
  const [clickAttempted, setClickAttempted] = React.useState(false);
  const [addUsersToGroup, { data: userAddData }] = useAddUsersToGroupsMutation({
    refetchQueries: ['Groups'],
    onCompleted: (res) => {
      if (res?.addUsersToGroups?.errors?.length === 0) {
        setSelectedUsers({});
        onClose();
      }
    },
  });

  const handleAddUsersToGroup = (
    groupId: string | undefined,
    selectedUsers: SelectedUsers,
  ) => {
    const selectedUserIds = Object.keys(selectedUsers);
    if (groupId && selectedUserIds.length > 0) {
      const additions = selectedUserIds.map((userId) => {
        return { userId, groupId };
      });
      addUsersToGroup({
        variables: { input: { additions } },
      });
    }
  };
  const [createNewGroup, { data: newGroupData }] = useAddGroupMutation({
    variables: { input: { ...newGroupVariables } },
    onCompleted: (response) => {
      handleAddUsersToGroup(response.addGroup?.group?.id, selectedUsers);
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClickAttempted(false);
    setNewGroupVariables((previous) => {
      return {
        ...previous,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleAddNewGroup = () => {
    if (newGroupVariables.name && newGroupVariables.description) {
      createNewGroup();
      setNewGroupVariables({ name: '', description: '' });
      onClose();
    } else {
      setClickAttempted(true);
    }
  };

  const handleClose = React.useCallback(() => {
    setNewGroupVariables({ name: '', description: '' });
    setSelectedUsers({});
    setClickAttempted(false);
    onClose();
  }, [onClose, setNewGroupVariables]);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([
      newGroupData?.addGroup?.errors,
      userAddData?.addUsersToGroups?.errors,
    ]);
  }, [newGroupData, userAddData]);

  return (
    <>
      <SNDialogTitle onClose={handleClose}>New Group </SNDialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          {collectedErrors.length > 0 && (
            <Box mb={2}>
              <SNStatusBanner status="error">
                <ul>
                  {collectedErrors.map((error) => {
                    return <li key={error}>{error}</li>;
                  })}
                </ul>
              </SNStatusBanner>
            </Box>
          )}
          <Box mb={2}>
            <SNInput
              id="name"
              required
              autoFocus
              fullWidth
              value={newGroupVariables.name}
              onChange={handleChange}
              label="Name"
              name="name"
              error={clickAttempted && !newGroupVariables.name}
              helperText={
                clickAttempted && !newGroupVariables.name
                  ? 'You must provide a name for the new group'
                  : ''
              }
            />
          </Box>
          <SNInput
            id="description"
            required
            multiline
            rows={3}
            fullWidth
            value={newGroupVariables.description}
            onChange={handleChange}
            label="Description"
            name="description"
            error={clickAttempted && !newGroupVariables.description}
            helperText={
              clickAttempted && !newGroupVariables.description
                ? 'You must provide a description for the new group'
                : ''
            }
          />
          <Box mt={3}>
            <UsersToAddSelector
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              addCurrentUser
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <SNButton onClick={handleClose}>Cancel</SNButton>
        <SNButton
          snVariant="primary"
          onClick={handleAddNewGroup}
          disabled={!newGroupVariables.name || !newGroupVariables.description}
        >
          Create Group
        </SNButton>
      </DialogActions>
    </>
  );
};

export default CreateGroupModal;
