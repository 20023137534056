import { Box, Grid } from '@mui/material';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { selectedIdsAtom } from '../../atoms/listSelection';
import ListSearchControl from '../ListSearchControl';
import { ListSearchControlProps } from '../ListSearchControl/ListSearchControl';

type SearchableListToolbarActionsProps = ListSearchControlProps;

const SearchableListToolbarActions: React.FC<
  SearchableListToolbarActionsProps
> = ({ children, ...rest }) => {
  //NOTE: we're slowly depricating this component in favor of grids and several ToolbarActionGroup components
  const selectedRowIds = useRecoilValue(selectedIdsAtom);

  return (
    <Box>
      {selectedRowIds.length === 0 && (
        <Grid container spacing={1}>
          {React.Children.map(children, (child) => (
            <Grid item>{child}</Grid>
          ))}
          <Grid item>
            <ListSearchControl {...rest} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SearchableListToolbarActions;
