import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { UpdateUserRole, UpdateUserRoleVariables } from '../types/schemaTypes';

export const UPDATE_SYSTEM_ROLE = gql`
  mutation UpdateUserRole($input: UpdateUserRoleInput!) {
    updateUserRole(input: $input) {
      user {
        id
        name
        picture
        defaultRole
      }
      errors
    }
  }
`;

export const useUpdateSystemRoleMutation = (
  options?: MutationHookOptions<UpdateUserRole, UpdateUserRoleVariables>,
) => {
  return useMutation<UpdateUserRole, UpdateUserRoleVariables>(
    UPDATE_SYSTEM_ROLE,
    options,
  );
};
