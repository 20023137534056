import React from 'react';
import { Box, MenuItem, Typography } from '@mui/material';
import Checkbox from '../Checkbox';
import CollapsePanel from '../CollapsePanel';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  uploadFilesByIdAtom,
  uploadProjectsAtom,
} from '../../atoms/uploadFiles';
import {
  PossibleProject,
  ProjectInfo,
} from '../UploadModalProjectSelection/UploadModalProjectSelection';
import SNInput from '../SNInput';

interface UploadModalProjectsPanelProps {
  name: string;
  dataId: string;
  possibleProjects: PossibleProject[];
}

const UploadModalProjectsPanel: React.FC<UploadModalProjectsPanelProps> = ({
  name,
  dataId,
  possibleProjects,
}) => {
  const filesById = useRecoilValue(uploadFilesByIdAtom);
  const [filesInProjects, setFilesInProjects] =
    useRecoilState(uploadProjectsAtom);

  const projectInfo: ProjectInfo = React.useMemo(
    () => filesInProjects[dataId],
    [filesInProjects, dataId],
  );

  const handleProjectChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setFilesInProjects((previous) => {
      return {
        ...previous,
        [dataId]: {
          projectId: e.target.value,
          projectName:
            possibleProjects.filter(
              (possible) => possible.projectId === e.target.value,
            )[0].projectName || '',
          filesInProject: [...Object.keys(filesById)],
        },
      };
    });
  };

  const handleToggleFileOnProject = (fileId: string) => {
    if (projectInfo.filesInProject.includes(fileId)) {
      setFilesInProjects((previous) => {
        const remainingFileIds = previous[dataId].filesInProject.filter(
          (oldFileId) => oldFileId !== fileId,
        );
        return {
          ...previous,
          [dataId]: {
            ...previous[dataId],
            filesInProject: remainingFileIds,
          },
        };
      });
    } else {
      setFilesInProjects((previous) => {
        return {
          ...previous,
          [dataId]: {
            ...previous[dataId],
            filesInProject: [...previous[dataId].filesInProject, fileId],
          },
        };
      });
    }
  };

  const handleClose = () => {
    setFilesInProjects((previous) => {
      const { [dataId]: omitted, ...rest } = previous;
      return { ...rest };
    });
  };

  return (
    <CollapsePanel name={name} onRemove={handleClose}>
      <Box p={3} pb={2}>
        <Box>
          <SNInput
            select
            label="Project Name"
            value={projectInfo.projectId ? projectInfo.projectId : 'default'}
            onChange={handleProjectChange}
            fullWidth
          >
            <MenuItem value="default" disabled>
              <Box width={90} color="textSecondary">
                choose a project
              </Box>
            </MenuItem>
            <MenuItem value={projectInfo.projectId} disabled>
              <Box width={90} color="textSecondary">
                {projectInfo.projectName}
              </Box>
            </MenuItem>
            {possibleProjects?.map((item) => (
              <MenuItem key={item.projectId} value={item.projectId}>
                <Box width={90}>{item.projectName}</Box>
              </MenuItem>
            ))}
          </SNInput>
        </Box>
      </Box>
      <Box p={2} pt={0} pb={3}>
        <Box p={1}>
          <Typography variant="h5">Associated Files</Typography>
        </Box>
        <Box>
          {Object.entries(filesById).map(([fileId, file]) => (
            <Box
              key={fileId}
              component="label"
              display="flex"
              alignItems="center"
            >
              <Box pr={1}>
                <Checkbox
                  checked={projectInfo?.filesInProject.includes(fileId)}
                  onChange={() => handleToggleFileOnProject(fileId)}
                />
              </Box>
              <Typography variant="h5">{file.name}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </CollapsePanel>
  );
};

export default UploadModalProjectsPanel;
