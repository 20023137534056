import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { EntityHistory, EntityHistoryVariables } from '../types/schemaTypes';

export const ENTITY_HISTORY = gql`
  query EntityHistory(
    $filter: EntityHistoryFilter
    $after: String
    $before: String
    $first: Int
    $last: Int
    $id: String!
    $order: SortDirection
  ) {
    entityHistory(
      id: $id
      filter: $filter
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        cursor
        node {
          changes {
            key
            values {
              ... on EntityHistorySimpleAddition {
                simpleAdditionNew: newValue
              }
              ... on EntityHistorySimpleChange {
                simpleChangeNew: newValue
                simpleChangeOld: oldValue
              }
              ... on EntityHistoryEntityAddition {
                entityAdditionNew: newValue {
                  id
                  displayName
                }
              }
              ... on EntityHistoryEntityChange {
                entityChangeNew: newValue {
                  id
                  displayName
                }
                entityChangeOld: oldValue {
                  id
                  displayName
                }
              }
            }
          }
          eventOrigin
          eventType
          id
          updatedAt
          user {
            id
            name
            picture
          }
        }
      }
    }
  }
`;

export const useEntityHistoryQuery = (
  options?: QueryHookOptions<EntityHistory, EntityHistoryVariables>,
) => {
  return useQuery<EntityHistory, EntityHistoryVariables>(ENTITY_HISTORY, {
    ...options,
    notifyOnNetworkStatusChange: true,
  });
};
