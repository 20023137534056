import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import { Group, GroupVariables } from '../types/schemaTypes';

export const GROUP = gql`
  query Group($id: ID!) {
    group(id: $id) {
      group {
        id
        name
        description
        groupMembership {
          totalCount
        }
        groupProjectAccess {
          totalCount
        }
      }
      errors
    }
  }
`;

export const useGetLazyGroupByIdQuery = (
  options?: LazyQueryHookOptions<Group, GroupVariables>,
) => {
  return useLazyQuery<Group, GroupVariables>(GROUP, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};

export const useGetGroupByIdQuery = (
  options?: QueryHookOptions<Group, GroupVariables>,
) => {
  return useQuery<Group, GroupVariables>(GROUP, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};
