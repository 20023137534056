import React from 'react';
import Checkbox from '../Checkbox';
import { CompactSelection, GridSelection } from '@glideapps/glide-data-grid';

interface GlideDataGridSelectionControlProps {
  pageTotal: number;
  gridSelection: GridSelection | undefined;
  onGridSelectionChange: (gridSelection: GridSelection) => void;
}

const GlideDataGridSelectionControl = ({
  pageTotal,
  gridSelection,
  onGridSelectionChange,
}: GlideDataGridSelectionControlProps) => {
  const hasOneOrMoreRowsSelected = gridSelection?.rows.some(() => true);
  const allRowsSelected = gridSelection?.rows.hasAll([0, pageTotal]);

  const handleCheckboxChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (
        event.currentTarget.getAttribute('data-indeterminate') === 'true' ||
        allRowsSelected
      ) {
        if (gridSelection) {
          onGridSelectionChange({
            ...gridSelection,
            rows: CompactSelection.empty(),
          });
        }
      } else {
        if (gridSelection) {
          const newRows = gridSelection.rows.add([0, pageTotal]);
          onGridSelectionChange({
            ...gridSelection,
            rows: newRows,
          });
        }
      }
    },
    [allRowsSelected, gridSelection, onGridSelectionChange, pageTotal],
  );

  return (
    <Checkbox
      data-testid="select-all-checkbox"
      disabled={pageTotal === 0}
      checked={pageTotal > 0 && Boolean(allRowsSelected)}
      indeterminate={!allRowsSelected && Boolean(hasOneOrMoreRowsSelected)}
      onChange={handleCheckboxChange}
    />
  );
};

export default GlideDataGridSelectionControl;
