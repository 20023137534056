import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  GrantGroupsAccessToProjects,
  GrantGroupsAccessToProjectsVariables,
} from '../types/schemaTypes';

export const GRANT_PROJECTS_TO_GROUPS = gql`
  mutation GrantGroupsAccessToProjects(
    $input: GrantGroupsAccessToProjectsInput!
  ) {
    grantGroupsAccessToProjects(input: $input) {
      groupProjectAccesses {
        id
        group {
          id
          name
          description
        }
        project {
          id
          name
          description
        }
      }
      errors
    }
  }
`;

export const useAddGroupsToProjectsMutation = (
  options?: MutationHookOptions<
    GrantGroupsAccessToProjects,
    GrantGroupsAccessToProjectsVariables
  >,
) => {
  return useMutation<
    GrantGroupsAccessToProjects,
    GrantGroupsAccessToProjectsVariables
  >(GRANT_PROJECTS_TO_GROUPS, options);
};
