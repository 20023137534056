import React from 'react';
import { Box } from '@mui/material';

interface ScrollingContainerProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'ref'> {
  offset?: number;
  hideScrolls?: boolean;
}

const ScrollingContainer: React.FC<ScrollingContainerProps> = ({
  offset = 136, // toolbar height 65px + navbar height 72px - toolbar border height 1px
  children,
  hideScrolls = false,
  ...rest
}) => {
  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = React.useState(0);

  const handleWindowResize = () => {
    if (scrollContainerRef.current) {
      const { top } = scrollContainerRef.current.getBoundingClientRect();
      setHeight(window.innerHeight - top);
    }
  };

  React.useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Box
      ref={scrollContainerRef}
      sx={{
        minHeight: height,
        position: 'relative',
        overflow: hideScrolls ? 'hidden' : 'auto',
        height: `calc(100vh - ${offset}px)`,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default ScrollingContainer;
