import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Checkbox from '../Checkbox';
import styled from '../../services/styled';
import LinkingModalRelationship from '../LinkingModalRelationship';
import UploadModalContextTargets from '../UploadModalContextTargets';
import { entityTypes } from '../../constants/wellKnownDataTypes';
import CollapsePanel from '../CollapsePanel';
import { useRecoilValue } from 'recoil';
import { uploadFilesByIdAtom } from '../../atoms/uploadFiles';
import { UploadLinkCreation } from '../../types/schemaTypes';

const WorkflowLabel = styled(Typography)`
  margin: 0px;
`;

interface UploadModalLinkingPanelProps extends UploadLinkCreation {
  name: string;
  onFileSelect: (name: string, checked: boolean) => void;
  onRelationshipSelect: (linkTypeId: string) => void;
  onRemove?: () => void;
  onTargetDelete: (innerIndex: number) => () => void;
  onTargetSelect: (innerIndex: number) => (targetId: string) => void;
}

const UploadModalLinkingPanel: React.FC<UploadModalLinkingPanelProps> = ({
  name,
  onFileSelect,
  onRelationshipSelect,
  onRemove,
  onTargetDelete,
  onTargetSelect,
  linkTypeId,
  selectedUploadIds,
  linkTargetIds,
}) => {
  const filesById = useRecoilValue(uploadFilesByIdAtom);

  const handleFileSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    onFileSelect(event.currentTarget.value, checked);
  };

  const handleTargetDelete = (innerIndex: number) => () => {
    onTargetDelete(innerIndex)();
  };

  const handleTargetSelect = (innerIndex: number) => (targetId: string) => {
    onTargetSelect(innerIndex)(targetId);
  };

  return (
    <CollapsePanel name={name} onRemove={onRemove}>
      <Box px={3} py={2}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <WorkflowLabel variant="h3">From</WorkflowLabel>
              <ArrowForwardIcon color="primary" />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <WorkflowLabel variant="h3">Relationship</WorkflowLabel>
              <ArrowForwardIcon color="primary" />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <WorkflowLabel variant="h3">To</WorkflowLabel>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box width="100%" bgcolor="#3F414B" height="1px" />
      <Box px={3} py={2}>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Box py={1} color="grey.200">
              <Typography variant="h6" color="inherit">
                File Name
              </Typography>
            </Box>
            <Box ml={-1}>
              {Object.entries(filesById).map(([fileId, file]) => (
                <Box
                  key={fileId}
                  component="label"
                  display="flex"
                  alignItems="center"
                >
                  <Box pr={1}>
                    <Checkbox
                      value={fileId}
                      checked={selectedUploadIds.includes(fileId)}
                      onChange={handleFileSelect}
                    />
                  </Box>
                  <Typography variant="h5">{file.name}</Typography>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box color="grey.200" py={1}>
              <Typography variant="h6" color="inherit">
                Relationship Type
              </Typography>
            </Box>
            <LinkingModalRelationship
              entityTypeIds={[]}
              hasTargets={false}
              isFilterable={false}
              linkTypeId={linkTypeId}
              onSelect={onRelationshipSelect}
            />
          </Grid>
          <Grid item xs={4}>
            <UploadModalContextTargets
              allowedEntityTypeIds={[entityTypes.any]}
              label="Entity Name"
              onTargetDelete={handleTargetDelete}
              onTargetSelect={handleTargetSelect}
              targetEntityIds={linkTargetIds}
            />
          </Grid>
        </Grid>
      </Box>
    </CollapsePanel>
  );
};

export default UploadModalLinkingPanel;
