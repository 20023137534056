import { createTheme } from '@mui/material/styles';
import { ThemeOptions, alpha } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { PickerComponents } from '@mui/x-date-pickers/themeAugmentation';

const paletteOptions: ThemeOptions['palette'] = {
  mode: 'dark',
  background: {
    default: '#1A1B1E',
    paper: '#212224',
  },
  info: {
    main: '#355CB2',
  },
  error: {
    light: '#FF5F58',
    main: '#CA433D',
    dark: '#9B2E29',
  },
  primary: {
    light: '#4178F1',
    main: '#355CB2',
    dark: '#23448B',
  },
  secondary: {
    light: '#4178F1',
    main: '#355CB2',
    dark: '#23448B',
  },
  success: {
    light: '#4BB08F',
    main: '#3C997B',
    dark: '#24795D',
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#9DA2AF',
    disabled: alpha('#9DA2AF', 0.5),
  },
  warning: {
    light: '#ECDE80',
    main: '#D0C25F',
    dark: '#ADA042',
  },
  grey: {
    // Highlight Gray
    100: '#A3A5BB',
    // Light Gray
    200: '#9DA2AF',
    // Gray
    300: '#7D7E8F',
    // Mid gray
    400: '#40414B',
    // Selected Item Background Gray
    500: '#1D2128',
    // Button Gray Light
    600: '#3F414B',
    // Button Gray Dark
    700: '#27282D',
    // Overlay Light
    800: '#25262B',
    // Overlay Dark
    900: '#161617',
  },
};

const typographyOptions: ThemeOptions['typography'] = {
  fontFamily: '"Inter", sans-serif',
  // Heading 1
  h1: {
    fontFamily: '"Akkurat", sans-serif',
    fontSize: '1.875rem', // 30px (Responsive)
    fontWeight: 'bold',
    lineHeight: 1.4,
    letterSpacing: '0.1rem',
  },
  // Heading 2
  h2: {
    fontFamily: '"Akkurat", sans-serif',
    fontSize: '1.5rem', // 24px (Responsive)
    fontWeight: 'bold',
    lineHeight: 1.38888888889,
  },
  // Heading 3
  h3: {
    fontFamily: '"Akkurat", sans-serif',
    fontSize: '1.125rem', // 18px (Responsive)
    fontWeight: 'bold',
    lineHeight: 1.38888888889,
  },
  // Heading 4
  h4: {
    fontSize: '0.6875rem', // 11 px
    fontWeight: 'normal',
    lineHeight: 1.36363636364,
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontFeatureSettings:
      "'liga'1,'tnum'1,'case'1,'ss01'1,'ss02'1,'ss03'1,'dlig'1,'zero'1",
  },
  // Title
  // Button
  h5: {
    fontWeight: 600,
    fontSize: '0.859375rem', // 13.75px
    lineHeight: 1.30909090909,
    fontFeatureSettings:
      "'liga'1,'tnum'1,'case'1,'ss01'1,'ss02'1,'ss03'1,'dlig'1,'zero'1",
  },
  // Label
  h6: {
    fontWeight: 500,
    fontSize: '0.78125rem', // 12.5px
    lineHeight: 1.44,
    letterSpacing: '0.02rem',
    textTransform: 'uppercase',
    fontFeatureSettings:
      "'liga'1,'tnum'1,'case'1,'ss01'1,'ss02'1,'ss03'1,'dlig'1,'zero'1",
  },
  // Text
  body1: {
    fontSize: '0.78125rem', // 12.5px
    lineHeight: 1.44,
    fontFeatureSettings:
      "'liga'1,'tnum'1,'case'1,'ss01'1,'ss02'1,'ss03'1,'dlig'1,'zero'1",
  },
  // Small Text
  // Don't set fontFeatureSettings on body2 since it will be applied to the body element
  // https://material-ui.com/components/css-baseline/#typography
  body2: {
    fontSize: '0.6875rem', // 11px
    lineHeight: 1.36363636364,
  },
  // Button
  button: {
    fontWeight: 600,
    fontSize: '0.875rem', // 14px
    lineHeight: 1.42857142857,
    textTransform: 'none',
    fontFeatureSettings:
      "'liga'1,'tnum'1,'case'1,'ss01'1,'ss02'1,'ss03'1,'dlig'1,'zero'1",
  },
  // Input
  overline: {
    fontSize: '0.875rem', // 14px
    lineHeight: 1.42857142857,
    textTransform: 'none',
    fontFeatureSettings:
      "'liga'1,'tnum'1,'case'1,'ss01'1,'ss02'1,'ss03'1,'dlig'1,'zero'1",
  },
};

export type SNVariants =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'title'
  | 'text'
  | 'small-text'
  | 'button'
  | 'label'
  | 'input';

export const getMuiVariant = (snVariant: SNVariants): Variant => {
  switch (snVariant) {
    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
    case 'button':
      return snVariant;
    case 'label':
      return 'h6';
    case 'input':
      return 'overline';
    case 'title':
      return 'h5';
    case 'small-text':
      return 'body2';
    case 'text':
    default:
      return 'body1';
  }
};

const overrideOptions: ThemeOptions['components'] & PickerComponents = {
  MuiAutocomplete: {
    styleOverrides: {
      endAdornment: {
        right: '6px',
      },
      root: {
        '& .MuiInput-root': {
          paddingBottom: 0,
        },
        '& .MuiInput-root .MuiInput-input': {
          paddingTop: 9,
          paddingRight: 35,
          paddingBottom: 9,
          paddingLeft: 9,
        },
        '& .MuiInput-root.MuiInputBase-sizeSmall .MuiInput-input': {
          paddingTop: 6,
          paddingRight: 30,
          paddingBottom: 6,
          paddingLeft: 8,
        },
      },
      paper: {
        margin: 0,
      },
      popupIndicator: {
        padding: 4,
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        height: 40,
        color: '#FFFFFF',
        '&:hover': {
          backgroundColor: alpha('#fff', 0.08),
        },
      },
      sizeSmall: {
        height: 30,
      },
      startIcon: {
        transition: 'fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: '#161617',
      },
      label: {
        color: '#9DA2AF',
        fontWeight: 500,
        fontSize: '0.78125rem', // 12.5px
        lineHeight: 1.44,
        letterSpacing: '0.02rem',
        textTransform: 'uppercase',
        fontFeatureSettings:
          "'liga'1,'tnum'1,'case'1,'ss01'1,'ss02'1,'ss03'1,'dlig'1,'zero'1",
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: '24px 32px',
      },
      spacing: {
        '&> :not(:first-of-type)': {
          marginLeft: '19px',
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '0 32px',
      },
      dividers: {
        borderBottom: 'none',
        padding: '0 32px',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: { padding: '24px 32px' },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        color: '#fff',
        fontSize: '0.6875rem',
        lineHeight: 1.40636363636,
        marginLeft: 0,
        marginTop: 8,
        '&.Mui-disabled': {
          color: alpha('#fff', 0.5),
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontWeight: 500,
        fontSize: '0.78125rem', // 12.5px
        lineHeight: 1.44,
        letterSpacing: '0.02rem',
        textTransform: 'uppercase',
        fontFeatureSettings:
          "'liga'1,'tnum'1,'case'1,'ss01'1,'ss02'1,'ss03'1,'dlig'1,'zero'1",
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        padding: 10,
        '&:focus': {
          backgroundColor: alpha('#fff', 0.08),
        },
      },
      edgeEnd: {
        marginRight: 0,
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      input: {
        fontSize: '0.875rem',
        lineHeight: 1.42857142857,
        padding: 9,
        height: 20,
        '&::placeholder': {
          fontSize: '0.875rem', // 14px
          lineHeight: 1.42857142857,
          fontWeight: 400,
          color: '#fff',
        },
      },
      inputSizeSmall: {
        padding: '6px 8px',
        height: 18,
        fontSize: '0.78125rem', // 12.5px
        lineHeight: 1.44,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: 'inherit',
        textDecoration: 'none',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      icon: {
        top: 'auto',
        right: '6px',
        '.MuiInputBase-inputAdornedEnd ~ &': {
          right: '33px',
        },
      },
      select: {
        minHeight: 0,
        '&.MuiSelect-select': {
          paddingRight: 36,
        },
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        background: '#212224',
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        fontSize: '1.25rem',
      },
      fontSizeLarge: {
        fontSize: '2.15rem',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      textColorInherit: {
        opacity: 0.3,
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        height: 30,
        padding: '0 16px',
        textTransform: 'none',
        border: 0,
        backgroundColor: '#3F414B',
        borderRadius: 5,
        // boxShadow: '0 0 8px 0 rgba(0,0,0,0.38)',
        fontSize: '0.875rem', // 14px
        lineHeight: 1.42857142857,
        '&.Mui-selected': {
          backgroundColor: '#355CB2',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#4178F1',
          },
        },
      },
      sizeSmall: {
        height: 30,
        fontSize: '0.78125rem', // 12.5px
        lineHeight: 1.44,
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      grouped: {
        padding: '0 15px 0 16px',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: '#161617',
        borderRadius: 0,
        padding: 9,
        fontSize: '0.6875rem', // 11px
        lineHeight: 1.36363636364,
      },
    },
  },
};

/*
  https://material-ui.com/customization/default-theme/#explore
*/

const themeOptions = {
  palette: paletteOptions,
  typography: typographyOptions,
  components: overrideOptions,
};

const theme = createTheme(themeOptions);

export default theme;
