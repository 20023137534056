import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import Checkbox from '../Checkbox';
import { useRecoilState } from 'recoil';
import { listItemByIndexSelector } from '../../atoms/listSelection';
import SNTableRow from '../SNTableRow';
import { Link as RouterLink } from 'react-router-dom';
import ListSummaryTag from '../ListSummaryTag';
import moment from 'moment';

interface ListsTableRowProps {
  name?: string | null;
  id?: string;
  description?: string | null;
  itemCount: number;
  index: number;
  createdAt: string;
  updatedAt: string;
}

const ListsTableRow = ({
  name,
  id,
  description,
  itemCount,
  updatedAt,
  createdAt,
  index,
}: ListsTableRowProps) => {
  const [selected, selectRow] = useRecoilState(listItemByIndexSelector(index));

  const handleRowSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      selectRow(checked);
    },
    [selectRow],
  );

  return (
    <SNTableRow selected={selected} key={id}>
      <td>
        <Box pl={4} pr={3} position="relative">
          <Checkbox checked={selected} onChange={handleRowSelect} />
        </Box>
      </td>
      <td>
        <Link to={`/settings/lists/${id}`} component={RouterLink}>
          <Box display="flex" alignItems="center" height={56}>
            <Box fontWeight="bold">
              <Typography>{name}</Typography>
            </Box>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/lists/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <Box overflow="hidden" textOverflow="ellipsis">
              <Typography>{description}</Typography>
            </Box>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/lists/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            {itemCount === 0 ? (
              <Typography color="textSecondary">
                No items in this list
              </Typography>
            ) : (
              <ListSummaryTag
                remainingLabelPlural="items"
                remainingLabelSingular="item"
                totalCount={itemCount}
              />
            )}
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/lists/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <Typography>{moment(createdAt).format('YYYY-MM-DD')}</Typography>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/lists/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <Typography>{moment(updatedAt).format('YYYY-MM-DD')}</Typography>
          </Box>
        </Link>
      </td>
    </SNTableRow>
  );
};

export default ListsTableRow;
