import React from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  Tooltip,
  Typography,
} from '@mui/material';
import SNDialogTitle from '../SNDialogTitle';
import SNButton from '../SNButton';
import SNInput from '../SNInput';
import { UserRole } from '../../types/schemaTypes';
import Autocomplete from '../Autocomplete';
import ProjectsToAddSelector from '../ProjectsToAddSelector';
import GroupsToAddSelector from '../GroupsToAddSelector';
import { useCreateUserMutation } from '../../mutations';
import UserAvatar from '../UserAvatar';
import LoadingOverlay from '../LoadingOverlay';
import SNListItem from '../SNListItem';
import SNStatusBanner from '../SNStatusBanner';

interface AddUserModalProps {
  onClose: () => void;
}

const options = [
  UserRole.GUEST,
  UserRole.ADMINISTRATOR,
  UserRole.OWNER,
  UserRole.READER,
  UserRole.APPROVER,
  UserRole.COLLABORATOR,
];

const initialFormState = {
  name: '',
  email: '',
  defaultRole: UserRole.GUEST,
  picture: '',
  password: '',
};

const noErrors = {
  name: '',
  email: '',
  password: '',
  defaultRole: '',
  general: '',
};

const AddUserModal = ({ onClose }: AddUserModalProps) => {
  const [addUserFormValues, setAddUserFormValues] =
    React.useState(initialFormState);
  const [createErrors, setCreateErrors] = React.useState(noErrors);
  const [selectedProjects, setSelectedProjects] = React.useState({});
  const [selectedGroups, setSelectedGroups] = React.useState({});
  const [createUser, { loading, data }] = useCreateUserMutation({
    onCompleted: (res) => {
      if (
        res &&
        res.createUser &&
        res.createUser.errors &&
        res.createUser.errors.length > 0
      ) {
        const generalError = res?.createUser?.errors[0];
        setCreateErrors((prev) => {
          return {
            ...prev,
            general: generalError,
          };
        });
      }
    },
    refetchQueries: ['Users'],
  });

  const handleCloseModal = () => {
    setAddUserFormValues(initialFormState);
    setCreateErrors(noErrors);
    setSelectedGroups({});
    setSelectedProjects({});
    onClose();
  };

  const handleChangeValues = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setAddUserFormValues((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
    setCreateErrors((previous) => {
      return { ...previous, [e.target.name]: '' };
    });
  };
  const handleChangeUserRole = (e: React.SyntheticEvent, value: UserRole) => {
    setAddUserFormValues((prev) => {
      return {
        ...prev,
        defaultRole: value,
      };
    });
    setCreateErrors((previous) => {
      return { ...previous, defaultRole: '' };
    });
  };

  const handleAddUser = () => {
    if (!addUserFormValues.email) {
      setCreateErrors((previous) => {
        return {
          ...previous,
          email: 'An email address is required for the new user',
        };
      });
    }
    if (!addUserFormValues.name) {
      setCreateErrors((previous) => {
        return { ...previous, name: 'A name is required for the new user' };
      });
    }
    if (!addUserFormValues.defaultRole) {
      setCreateErrors((previous) => {
        return {
          ...previous,
          defaultRole: 'A default role is required for the new user',
        };
      });
    }
    if (!addUserFormValues.password) {
      setCreateErrors((previous) => {
        return {
          ...previous,
          password: 'An initial password is required for the new user',
        };
      });
    }

    if (
      addUserFormValues.email &&
      addUserFormValues.defaultRole &&
      addUserFormValues.name &&
      addUserFormValues.password
    ) {
      const newUserInputData = {
        ...addUserFormValues,
        projectIds: Object.keys(selectedProjects),
        groupIds: Object.keys(selectedGroups),
      };
      createUser({ variables: { input: newUserInputData } });
    } else {
      setCreateErrors((previous) => {
        return {
          ...previous,
          general: 'Please enter a value in all required fields',
        };
      });
    }
  };

  return (
    <>
      {data?.createUser?.user ? (
        <>
          <SNDialogTitle onClose={handleCloseModal}>
            <Box display="flex" flexDirection="column">
              <Typography variant="h2">User Created:</Typography>
            </Box>
          </SNDialogTitle>
          <DialogContent>
            <SNListItem key={data?.createUser?.user?.id}>
              <Box display="flex" alignItems="center" width="55%">
                <Box pr={2}>
                  <UserAvatar
                    name={data?.createUser?.user?.name || ''}
                    picture={data?.createUser?.user?.picture || ''}
                    size={32}
                  />
                </Box>
                <Typography variant="h5">
                  {data?.createUser?.user?.name}
                </Typography>
              </Box>
            </SNListItem>
          </DialogContent>
          <DialogActions>
            <SNButton onClick={handleCloseModal}>Done</SNButton>
          </DialogActions>
        </>
      ) : (
        <>
          <LoadingOverlay isLoading={loading} />
          <SNDialogTitle onClose={handleCloseModal}>
            <Box display="flex" flexDirection="column">
              <Typography variant="h2">New User</Typography>
              {createErrors.general && (
                <SNStatusBanner status="error">
                  {createErrors.general}
                </SNStatusBanner>
              )}
            </Box>
          </SNDialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" pb={2}>
              <SNInput
                required
                helperText={createErrors.name}
                status={createErrors.name ? 'error' : undefined}
                label="Name"
                name="name"
                value={addUserFormValues.name}
                onChange={handleChangeValues}
              />
              <SNInput
                required
                helperText={createErrors.email}
                status={createErrors.email ? 'error' : undefined}
                label="Email"
                name="email"
                value={addUserFormValues.email}
                onChange={handleChangeValues}
              />
              <SNInput
                required
                helperText={createErrors.password}
                status={createErrors.password ? 'error' : undefined}
                label="Initial Password"
                name="password"
                value={addUserFormValues.password}
                onChange={handleChangeValues}
              />
              <Box my={1} display="flex" flexDirection="column">
                <Box mb={1} display="flex">
                  <Tooltip placement="left" title="Required field">
                    <Box mr={1}>
                      <Typography color="error">*</Typography>
                    </Box>
                  </Tooltip>
                  <Typography variant="h6" color="textSecondary">
                    Default Role
                  </Typography>
                </Box>
                <Autocomplete
                  fullWidth
                  freeSolo={false}
                  disableClearable
                  multiple={false}
                  value={addUserFormValues.defaultRole}
                  options={options}
                  renderInput={({ InputProps, InputLabelProps, ...params }) => {
                    return (
                      <SNInput
                        {...params}
                        {...InputProps}
                        status={createErrors.defaultRole ? 'error' : undefined}
                        helperText={createErrors.defaultRole}
                        className=""
                      />
                    );
                  }}
                  onChange={handleChangeUserRole}
                />
                <SNInput
                  label="Avatar URL"
                  name="picture"
                  value={addUserFormValues.picture}
                  onChange={handleChangeValues}
                />
                <ProjectsToAddSelector
                  selectedProjects={selectedProjects}
                  setSelectedProjects={setSelectedProjects}
                />
                <GroupsToAddSelector
                  selectedGroups={selectedGroups}
                  setSelectedGroups={setSelectedGroups}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <SNButton onClick={handleCloseModal}>Cancel</SNButton>
            <SNButton onClick={handleAddUser}>Add User</SNButton>
          </DialogActions>
        </>
      )}
    </>
  );
};

export default AddUserModal;
