import { Box, Typography } from '@mui/material';
import UserAvatar from '../UserAvatar';

interface UserSummaryProps {
  usersToShow?: number;
  totalUsers: number;
  centerSplit?: boolean;
  users: ({
    id: string;
    name: string | null;
    picture: string | null;
  } | null)[];
  onSummaryClick?: () => void;
}

const UserSummary = ({
  users,
  centerSplit = false,
  usersToShow = 3,
  totalUsers,
  onSummaryClick,
}: UserSummaryProps) => {
  if (users.includes(null)) {
    return null;
  }
  if (centerSplit && usersToShow < users.length) {
    const leftUsers = users.slice(0, Math.ceil(usersToShow / 2));
    const rightUsers = users.slice(-(usersToShow - Math.ceil(usersToShow / 2)));
    return (
      <Box display="flex" alignItems="center" onClick={onSummaryClick}>
        {leftUsers.map((user) => (
          <Box mr={1} key={user?.id} display="flex" alignItems="center">
            <UserAvatar
              name={user?.name || ''}
              picture={user?.picture || ''}
              size={32}
            />
          </Box>
        ))}
        <Box m={1} display="flex" alignItems="center">
          <Typography>...+{totalUsers - usersToShow}...</Typography>
        </Box>
        {rightUsers.map((user) => (
          <Box mr={1} key={user?.id} display="flex" alignItems="center">
            <UserAvatar
              name={user?.name || ''}
              picture={user?.picture || ''}
              size={32}
            />
          </Box>
        ))}
      </Box>
    );
  } else if (!centerSplit && usersToShow < users.length) {
    const avatarsToShow = users.slice(0, usersToShow);
    return (
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          {avatarsToShow.map((user) => (
            <Box mr={1} key={user?.id} display="flex" alignItems="center">
              <UserAvatar
                name={user?.name || ''}
                picture={user?.picture || ''}
                size={32}
              />
            </Box>
          ))}
        </Box>
        <Box pl={1} display="flex" alignItems="center" justifyContent="center">
          <Typography>+{totalUsers - usersToShow}</Typography>
        </Box>
      </Box>
    );
  } else if (
    !centerSplit &&
    usersToShow >= users.length &&
    usersToShow < totalUsers
  ) {
    return (
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center">
          {users.map((user) => (
            <Box mr={1} key={user?.id} display="flex" alignItems="center">
              <UserAvatar
                name={user?.name || ''}
                picture={user?.picture || ''}
                size={32}
              />
            </Box>
          ))}
        </Box>
        <Box pl={1} display="flex" alignItems="center" justifyContent="center">
          <Typography>+{totalUsers - users.length}</Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box display="flex" alignItems="center">
        {users.map((user) => (
          <Box mr={1} key={user?.id} display="flex" alignItems="center">
            <UserAvatar
              name={user?.name || ''}
              picture={user?.picture || ''}
              size={32}
            />
          </Box>
        ))}
      </Box>
    );
  }
};

export default UserSummary;
