import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { SystemFields } from '../types/schemaTypes';

export const SYSTEM_FIELDS = gql`
  query SystemFields {
    systemFields {
      id
      name
      dataPoint {
        dataType
      }
      type @client
    }
  }
`;

export const useSystemFields = (options?: QueryHookOptions<SystemFields>) => {
  return useQuery<SystemFields>(SYSTEM_FIELDS, options);
};
