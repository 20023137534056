import React from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import SNButton from '../SNButton';
import { useParams } from 'react-router';
import ProjectsToAddSelector from '../ProjectsToAddSelector';
import { SelectedProjects } from '../ProjectsToAddSelector/ProjectsToAddSelector';
import { useAddGroupsToProjectsMutation } from '../../mutations/useGrantGroupsAccessToProjects';
import SNDialogTitle from '../SNDialogTitle';
import { collectErrors } from '../../utils/collectErrors';
import SNStatusBanner from '../SNStatusBanner';

interface AddProjectsToGroupFormProps {
  onClose: () => void;
}

const AddProjectsToGroupForm = ({ onClose }: AddProjectsToGroupFormProps) => {
  const { id: groupId } = useParams<{ id: string }>();
  const [selectedProjects, setSelectedProjects] =
    React.useState<SelectedProjects>({});
  const [addProjectsToGroup, { data: addProjectsData }] =
    useAddGroupsToProjectsMutation({
      refetchQueries: ['ProjectsOfGroup'],
      onCompleted: () => {
        onClose();
      },
    });

  const handleAddProjectsToGroup = React.useCallback(() => {
    const selectedProjectIds = Object.keys(selectedProjects);
    if (groupId && selectedProjectIds.length > 0) {
      const grants = selectedProjectIds.map((projectId) => {
        return { projectId, groupId };
      });
      addProjectsToGroup({ variables: { input: { grants } } });
    }
  }, [addProjectsToGroup, groupId, selectedProjects]);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([
      addProjectsData?.grantGroupsAccessToProjects?.errors,
    ]);
  }, [addProjectsData]);

  return (
    <>
      <SNDialogTitle onClose={onClose}>Add Projects to Group</SNDialogTitle>
      <DialogContent>
        {collectedErrors.length > 0 && (
          <Box mb={2}>
            <SNStatusBanner status="error">
              <ul>
                {collectedErrors.map((error) => {
                  return <li key={error}>{error}</li>;
                })}
              </ul>
            </SNStatusBanner>
          </Box>
        )}
        <ProjectsToAddSelector
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
        />
      </DialogContent>
      <DialogActions>
        <SNButton onClick={onClose}>Cancel</SNButton>
        <SNButton
          snVariant="primary"
          onClick={handleAddProjectsToGroup}
          disabled={Object.keys(selectedProjects).length === 0}
        >
          Add Projects
        </SNButton>
      </DialogActions>
    </>
  );
};

export default AddProjectsToGroupForm;
