import { CompactSelection, GridSelection } from '@glideapps/glide-data-grid';
import { atomFamily } from 'recoil';

export const EMPTY_GRID_SELECTION: GridSelection = {
  columns: CompactSelection.empty(),
  rows: CompactSelection.empty(),
  current: undefined,
};

export const glideTableSelection = atomFamily<
  Record<number, GridSelection | undefined>,
  string
>({
  key: 'glide-table-selection-atom',
  default: { 0: EMPTY_GRID_SELECTION },
});
