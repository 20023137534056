import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import {
  useDataPointsQuery,
  useEntityTypesQuery,
  useSearchTargetsQuery,
  useSystemFields,
  useUserQuery,
} from './queries';
import {
  SearchTreeProvider,
  UploadProvider,
  useUserState,
  SaveSearchProvider,
} from './contexts';
import NewSearchButton from './components/NewSearchButton';
import ProfileTabs from './components/ProfileTabs';
import TopBarNavItem from './components/TopBarNavItem';
import UploadSnackbar from './components/UploadSnackbar';
import {
  DataViews,
  EntityDetail,
  ReviewEdit,
  ReviewNew,
  SearchEdit,
  SearchNew,
  TopBar,
  Uploads,
} from './layout';
import ExportSnackbar from './components/ExportSnackbar';
import { ScreenWidthAtom } from './atoms/screenWidth';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ShowFiltersProvider } from './contexts/ShowFiltersContext';
import SettingsTabs from './layout/SettingsTabs/SettingsTabs';
import ProjectDetail from './layout/ProjectDetail';
import UserDetail from './layout/UserDetail';
import GroupDetail from './layout/GroupDetail';
import ProtectedRoute from './components/ProtectedRoute';
import { SETTINGS_ACCESS } from './constants/rolesAndPermissions';
import { searchTableColumnsSelector } from './atoms/entityTableColumns';
import Review from './layout/Review';
import ClassificationJobsSnackbar from './components/ClassificationJobsSnackbar';
import { latestSearchAtom } from './atoms/latestSearchAtom';
import { entityTypeTabMap } from './atoms/entityTypes';
import { entityTypeFieldsToColumns } from './utils/entityTypeFieldsToColumns';
import GeneralErrorSnackbar from './components/GeneralErrorSnackbar';
import { GeneralErrorSnackbarAtom } from './atoms/GeneralErrorSnackbarAtom';
import { collectErrors } from './utils/collectErrors';
import ListItemsTable from './components/ListItemsTable/ListItemsTable';

const AppContent = () => {
  const user = useUserState();
  const setSearchTableColumns = useSetRecoilState(searchTableColumnsSelector);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const setTabMap = useSetRecoilState(entityTypeTabMap);
  const { data: userData } = useUserQuery({
    variables: { id: user.userId },
  });
  useSearchTargetsQuery();
  useSystemFields();
  useDataPointsQuery();
  const { data: entityTypesData } = useEntityTypesQuery();

  React.useEffect(() => {
    if (entityTypesData?.entityTypes) {
      setSearchTableColumns(
        entityTypeFieldsToColumns(entityTypesData.entityTypes),
      );
      setTabMap(
        entityTypesData.entityTypes.reduce(
          (result, type) => ({ ...result, [type.id]: type.name }),
          {},
        ),
      );
    }
  }, [entityTypesData, setSearchTableColumns, setTabMap]);

  const setScreenWidth = useSetRecoilState(ScreenWidthAtom);
  window.onresize = React.useCallback(() => {
    setScreenWidth(
      window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
    );
  }, [setScreenWidth]);

  const latestSearch = useRecoilValue(latestSearchAtom);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([userData?.user?.errors]);
  }, [userData]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Unable to fetch user info',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <Box>
      <TopBar>
        <Box>
          <Grid container spacing={4} alignItems="center">
            <Grid item>
              <TopBarNavItem to="/upload">Upload</TopBarNavItem>
            </Grid>
            <Grid item>
              <TopBarNavItem to="/review">Review</TopBarNavItem>
            </Grid>
            <Grid item>
              <TopBarNavItem
                to="/search/latest"
                additionalMatches={['/search']}
              >
                Search
              </TopBarNavItem>
            </Grid>
            <Grid item>
              <TopBarNavItem to="/data-views">Data Views</TopBarNavItem>
            </Grid>
            <ProtectedRoute requiredRoles={SETTINGS_ACCESS}>
              <Grid item>
                <TopBarNavItem to="/settings">Settings</TopBarNavItem>
              </Grid>
            </ProtectedRoute>
          </Grid>
        </Box>
      </TopBar>
      <Box position="absolute" top={72} overflow="auto" width="100%">
        <SearchTreeProvider>
          <SaveSearchProvider>
            <UploadProvider>
              <ShowFiltersProvider>
                <Switch>
                  <Route path="/upload">
                    <Uploads />
                  </Route>
                  <Route path="/review/entity/:id/:tab?">
                    <EntityDetail />
                  </Route>
                  <Route path="/review/saved/new">
                    <ReviewNew />
                  </Route>
                  <Route path="/review/saved/:id">
                    <ReviewEdit />
                  </Route>
                  <Route path="/review/:tab?">
                    <Review />
                  </Route>
                  <Route path="/search/entity/:id/:tab?">
                    <EntityDetail />
                  </Route>
                  <Route path="/search/new">
                    <SearchNew />
                  </Route>
                  <Route path="/search/latest">
                    <Redirect to={`/search/${latestSearch}`} />
                  </Route>
                  <Route path="/search/:id">
                    <SearchEdit />
                  </Route>
                  <Route path="/search">
                    <Redirect to={`/search/${latestSearch}`} />
                  </Route>
                  <Route path="/data-views/:tab?">
                    <DataViews />
                  </Route>
                  <Route path="/profile/:tab?">
                    <ProfileTabs />
                  </Route>
                  <Route path="/settings/projects/:id/:tab?">
                    <ProjectDetail />
                  </Route>
                  <Route path="/settings/users/:id/:tab?">
                    <UserDetail />
                  </Route>
                  <Route path="/settings/groups/:id/:tab?">
                    <GroupDetail />
                  </Route>
                  <Route path="/settings/lists/:id">
                    <ListItemsTable />
                  </Route>
                  <Route path="/settings/:tab?">
                    <ProtectedRoute
                      redirect="/upload"
                      requiredRoles={SETTINGS_ACCESS}
                    >
                      <SettingsTabs />
                    </ProtectedRoute>
                  </Route>
                  <Redirect to="/upload" />
                </Switch>
                <NewSearchButton />
                <UploadSnackbar />
                <ExportSnackbar />
                <GeneralErrorSnackbar />
                <ClassificationJobsSnackbar />
              </ShowFiltersProvider>
            </UploadProvider>
          </SaveSearchProvider>
        </SearchTreeProvider>
      </Box>
    </Box>
  );
};

export default AppContent;
