import { ApolloCache } from '@apollo/client/cache';
import { gql, Resolvers } from '@apollo/client';
import { ACTIVE_UPLOADS } from '../queries';
import {
  ActiveUploads,
  EditEntities_editEntities_validations_fieldValidations,
  AnnotationSeverity,
  EditEntities_editEntities_validations,
} from '../types/schemaTypes';

type ResolverFn = (
  parent: any,
  args: any,
  { cache }: { cache: ApolloCache<any> },
) => any;

export interface ResolverMap {
  [field: string]: ResolverFn;
}

export interface AppResolvers extends Resolvers {
  // We will update this with our app's resolvers later
  Mutation: ResolverMap;
}

export const resolvers: AppResolvers = {
  EntityFieldValidation: {
    displayStatus: (
      parent: EditEntities_editEntities_validations_fieldValidations,
    ) => {
      const annotations = parent.annotations;
      if (!annotations || annotations.length === 0) return null;
      if (
        annotations &&
        annotations.some(
          (annotation) => annotation.severity === AnnotationSeverity.ERROR,
        )
      ) {
        return AnnotationSeverity.ERROR;
      } else {
        return AnnotationSeverity.WARNING;
      }
    },
    name: (
      parent: EditEntities_editEntities_validations_fieldValidations,
      args,
      context,
    ) => {
      const fieldDefinitionId = parent.fieldDefinitionId;
      const fieldInfo = context.client.readFragment({
        id: `DataFieldDefinition:${fieldDefinitionId}`,
        fragment: gql`
          fragment FieldDetails on DataFieldDefinition {
            name
          }
        `,
      });
      return fieldInfo ? fieldInfo.name : null;
    },
  },
  EntityValidations: {
    displayStatus: (parent: EditEntities_editEntities_validations) => {
      const annotations = parent.generalAnnotations;
      if (!annotations || annotations.length === 0) return null;
      if (
        annotations &&
        annotations.some(
          (annotation) => annotation.severity === AnnotationSeverity.ERROR,
        )
      ) {
        return AnnotationSeverity.ERROR;
      } else {
        return AnnotationSeverity.WARNING;
      }
    },
  },
  Mutation: {
    activeUploadAddRemove: (_, { activeUpload }, { cache }) => {
      const previous = cache.readQuery<ActiveUploads>({
        query: ACTIVE_UPLOADS,
      });
      const previousUploads = previous ? previous.activeUploads : [];
      const newUploads =
        previousUploads.findIndex((upload) => upload.id === activeUpload.id) >
        -1
          ? previousUploads.filter((upload) => upload.id !== activeUpload.id)
          : [
              { __typename: 'ActiveUpload', ...activeUpload },
              ...previousUploads,
            ];
      cache.writeQuery<ActiveUploads>({
        query: ACTIVE_UPLOADS,
        data: {
          activeUploads: newUploads,
        },
      });
      return newUploads;
    },
    activeUploadUpdate: (_, { activeUpload }, { cache }) => {
      const previous = cache.readQuery<ActiveUploads>({
        query: ACTIVE_UPLOADS,
      });
      const previousUploads = previous ? previous.activeUploads : [];
      const newUploads = previousUploads.map((upload) =>
        upload.id === activeUpload.id
          ? { __typename: 'ActiveUpload', ...activeUpload }
          : upload,
      );
      cache.writeQuery<ActiveUploads>({
        query: ACTIVE_UPLOADS,
        data: {
          activeUploads: newUploads,
        },
      });
      return newUploads;
    },
    activeUploadsSet: (_, { activeUploads }, { cache }) => {
      cache.writeQuery<ActiveUploads>({
        query: ACTIVE_UPLOADS,
        data: {
          activeUploads,
        },
      });
      return activeUploads;
    },
  },
};
