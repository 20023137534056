import React, { ReactNode } from 'react';
import { Box, DialogContent } from '@mui/material';
import SNDialogTitle from '../SNDialogTitle';

interface ListFilterModalProps {
  children: ReactNode;
  listTitle: string;
  closeModal: () => void;
}

const ListFilterModal = ({
  children,
  listTitle,
  closeModal,
}: ListFilterModalProps) => {
  return (
    <>
      <SNDialogTitle onClose={closeModal}>
        Filter the {listTitle}:
      </SNDialogTitle>
      <DialogContent>
        <Box mb={4}>{children}</Box>
      </DialogContent>
    </>
  );
};

export default ListFilterModal;
