import React from 'react';
import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export interface PaginationControlsProps {
  disableNext?: boolean;
  disablePrevious?: boolean;
  loading?: boolean;
  onPage: (increment: number) => void;
  pageSize: number;
  pageTotal: number;
  totalCount?: number | string | null;
  pageCount: number;
}

const PaginationControls = ({
  disableNext,
  disablePrevious,
  loading,
  onPage,
  pageCount,
  pageSize,
  pageTotal,
  totalCount,
}: PaginationControlsProps) => {
  const lower = pageSize * pageCount + 1;
  const upper = lower + pageTotal - 1;

  const handlePage = (increment: number) => () => {
    onPage(increment);
  };

  const inferredTotalCount = totalCount || (disableNext ? upper : 'many');

  return (
    <Box
      data-testid="pagination-controls"
      display="flex"
      alignItems="center"
      component="nav"
      role="navigation"
      aria-label="Pagination Navigation"
    >
      <Tooltip title="Previous">
        <div>
          <IconButton
            data-testid="previous-results-button"
            disabled={loading || disablePrevious}
            onClick={handlePage(-1)}
            aria-label="Go to Previous Page"
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title="Next">
        <div>
          <IconButton
            data-testid="next-results-button"
            disabled={loading || disableNext}
            onClick={handlePage(1)}
            aria-label="Go to Next Page"
          >
            <ChevronRightIcon />
          </IconButton>
        </div>
      </Tooltip>
      {pageTotal > 0 && (
        <Box display="flex" alignItems="center" pl={1}>
          <Typography variant="h4" component="span" data-testid="results-stats">
            {lower} - {upper} of {inferredTotalCount}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PaginationControls;
