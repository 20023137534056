import { Box, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import React from 'react';
import { useTabOverflowState } from '../../contexts';
import { useDebounce } from '../../hooks';
import { capitalize } from '../../utils/capitalize';
import SNButton from '../SNButton';
import ListSearchControl from '../ListSearchControl';
import { useRecoilValue } from 'recoil';
import { isEditingEntitiesAtom } from '../../atoms/editEntities';

export interface TabOption {
  name: string;
  id: string;
}

interface TabOverflowDropdownProps {
  onTabSelect: (tabId: string) => void;
  tabOptions: TabOption[];
}

const TabOverflowDropdown: React.FC<TabOverflowDropdownProps> = ({
  onTabSelect,
  tabOptions,
}) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isEditing = useRecoilValue(isEditingEntitiesAtom);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const state = useTabOverflowState();

  const options = React.useMemo(
    () => tabOptions.filter((tabOption) => !state[tabOption.id]),
    [state, tabOptions],
  );

  const filteredOptions = React.useMemo(
    () =>
      options.filter((option) =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    [options, searchTerm],
  );

  const debouncedCount = useDebounce(options.length, 300);

  const handleSelect = React.useCallback(
    (id: string) => () => {
      handleClose();
      onTabSelect(id);
    },
    [handleClose, onTabSelect],
  );

  const handleSearch = React.useCallback((newValue: string) => {
    setSearchTerm(newValue);
  }, []);

  if (options.length === 0) return null;

  return (
    <Box position="relative">
      <SNButton
        disabled={isEditing}
        snVariant="text"
        onClick={handleClick}
        endIcon={anchorEl ? <ExpandLess /> : <ExpandMore />}
      >
        {`${debouncedCount} More`}
      </SNButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box minWidth={250}>
          <Box pt={2} px={2}>
            <ListSearchControl
              value={searchTerm}
              setValue={handleSearch}
              forceShow
            />
          </Box>
          {filteredOptions.length > 0 ? (
            <MenuList>
              {filteredOptions.map(({ id, name }) => (
                <MenuItem key={id} onClick={handleSelect(id)}>
                  {capitalize(name)}
                </MenuItem>
              ))}
            </MenuList>
          ) : (
            <Box width="100%" p={2} textAlign="center">
              <Typography>No Matches Found</Typography>
            </Box>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default TabOverflowDropdown;
