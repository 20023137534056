import { Box, Grid } from '@mui/material';
import React from 'react';
import SearchByEntity from '../../components/SearchByEntity/SearchByEntity';
import SNTab from '../../components/SNTab';
import { SavedSearchType } from '../../types/schemaTypes';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import SavedSearchesTable from '../../components/SavedSearchesTable';
import { useParams } from 'react-router';

const DataViews = () => {
  const { tab = 'presets' } = useParams<{ tab?: string }>();
  const { push } = useHistory();

  const handleTabClick = React.useCallback(
    (tab: string) => () => {
      push(`/data-views/${tab}`);
    },
    [push],
  );

  const handleSearchSelect = React.useCallback(
    (id: string) => {
      push(`/search/${id}`);
    },
    [push],
  );

  return (
    <div>
      <Box
        border={1}
        borderTop={0}
        borderRight={0}
        borderLeft={0}
        borderColor="grey.600"
        width="100%"
      >
        <Box px={4} display="flex" mb="-1px">
          <Grid container spacing={4}>
            <Grid item>
              <SNTab
                active={tab}
                onClick={handleTabClick('presets')}
                value="presets"
              >
                Presets
              </SNTab>
            </Grid>
            <Grid item>
              <SNTab active={tab} onClick={handleTabClick('mine')} value="mine">
                My Data Views
              </SNTab>
            </Grid>
            <Grid item>
              <SNTab
                active={tab}
                onClick={handleTabClick('public')}
                value="public"
              >
                Public Data Views
              </SNTab>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Switch>
        <Route path="/data-views/presets">
          <Box pt={4}>
            <SearchByEntity target="search-new" />
          </Box>
        </Route>
        <Route path="/data-views/mine">
          <SavedSearchesTable
            onSearchSelect={handleSearchSelect}
            savedSearchType={SavedSearchType.SEARCH}
          />
        </Route>
        <Route path="/data-views/public">
          <SavedSearchesTable
            isPublic
            onSearchSelect={handleSearchSelect}
            savedSearchType={SavedSearchType.SEARCH}
          />
        </Route>
        <Redirect to="/data-views/presets" />
      </Switch>
    </div>
  );
};

export default DataViews;
