import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SNInput from '../SNInput';
import { SNInputProps } from '../SNInput/SNInput';

interface SNDatePickerProps extends Omit<SNInputProps, 'onChange' | 'value'> {
  onChange: (value: Date | null) => void;
  onDateConfirm?: () => void;
  value: Date | null;
}

const SNDatePicker: React.FC<SNDatePickerProps> = ({
  onDateConfirm,
  onChange,
  value,
  ...rest
}) => {
  const handleChange = React.useCallback(
    (date: Date | null, _keyboardInputValue?: string) => {
      onChange(date);
    },
    [onChange],
  );

  return (
    <DatePicker
      value={value}
      onChange={handleChange}
      mask="____-__-__"
      inputFormat="yyyy-MM-DD"
      renderInput={({
        InputProps,
        InputLabelProps,
        onKeyDown,
        onKeyUp,
        margin,
        color,
        classes,
        label,
        ...params
      }) => {
        return (
          <SNInput
            {...params}
            endAdornment={InputProps?.endAdornment}
            {...rest}
          />
        );
      }}
    />
  );
};

export default SNDatePicker;
