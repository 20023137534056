import {
  CompleteSearchObject,
  FilterBranch,
  FilterBranches,
  FilterLeaf,
  FilterLeaves,
  FilterType,
  FilterValueType,
  SearchTree,
} from '../types';
import {
  SearchQuery,
  GetSavedSearch_getSavedSearch_data_searchQuery,
} from '../types/schemaTypes';
import { Comparators, SearchTreeBranch } from '../types';
import { Draft } from 'immer';

type SearchInputTransform = (searchTree: CompleteSearchObject) => SearchQuery;
export const searchInputTransform: SearchInputTransform = (searchTree) => {
  const { branches, roots, globalFilters } = searchTree;
  const namedTreeBranchList = Object.entries(branches).map(([key, value]) => {
    const {
      children,
      filter: { filterBranches, filterLeaves, ...filterRest },
      locked,
      ...rest
    } = value;
    return {
      name: key,
      branch: {
        ...rest,
        children: children.slice(), // Copying to shake readonly :(
        filter: {
          ...filterRest,
          filterBranches: Object.entries(filterBranches).map(
            ([key, value]) => ({
              name: key,
              branch: value as Draft<FilterBranch>, // See FilterBranches interface
            }),
          ),
          filterLeaves: Object.entries(filterLeaves).map(([key, value]) => ({
            name: key,
            branch: value as Draft<FilterLeaf>, // See FilterLeaves interface
          })),
        },
      },
    };
  });
  const namedGlobalFiltersList = Object.entries(globalFilters).map(
    ([key, value]) => {
      const {
        appliesTo,
        filters: { filterBranches, filterLeaves, ...filterRest },
      } = value;
      return {
        appliesTo:
          key === 'statusFilter'
            ? Object.entries(branches)
                .filter(([, branch]) => branch.select)
                .map(([key]) => key)
            : appliesTo.slice(),
        filter: {
          ...filterRest,
          filterBranches: Object.entries(filterBranches).map(
            ([key, value]) => ({
              name: key,
              branch: value as Draft<FilterBranch>, // See FilterBranches interface
            }),
          ),
          filterLeaves: Object.entries(filterLeaves).map(([key, value]) => ({
            name: key,
            branch: value as Draft<FilterLeaf>, // See FilterLeaves interface
          })),
        },
      };
    },
  );

  const searchInput = {
    branches: namedTreeBranchList,
    roots: roots.slice(),
    globalFilters: namedGlobalFiltersList,
  };

  return searchInput;
};

type SearchQueryTransform = (
  searchTree: GetSavedSearch_getSavedSearch_data_searchQuery,
) => Draft<CompleteSearchObject>;
export const searchQueryTransform: SearchQueryTransform = (searchQuery) => {
  const { branches, roots } = searchQuery;
  const keyIndexedBranches = branches.reduce<Draft<SearchTree>>(
    (result, branch) => {
      const {
        name,
        branch: {
          __typename,
          children,
          filter: { filterBranches, filterLeaves, rootFilterId },
          parent,
          joinData,
          target: { type, id },
          ...rest
        },
      } = branch;
      const searchTreeBranch: Draft<SearchTreeBranch> = {
        ...rest,
        target: { type, id },
        joinData: joinData
          ? {
              joinDistance: joinData.joinDistance,
              joinRequired: joinData.joinRequired,
              joinType: joinData.joinType,
            }
          : null,
        parent: parent || '',
        children,
        filter: {
          rootFilterId,
          filterBranches: filterBranches.reduce<Draft<FilterBranches>>(
            (
              result,
              {
                name,
                branch: {
                  __typename,
                  name: branchName,
                  parent: branchParent,
                  ...branchRest
                },
              },
            ) => ({
              ...result,
              [name]: {
                parent: branchParent || '',
                name: branchName || '',
                ...branchRest,
              },
            }),
            {},
          ),
          filterLeaves: filterLeaves.reduce<Draft<FilterLeaves>>(
            (
              result,
              {
                name,
                branch: {
                  __typename,
                  comparator,
                  metadata,
                  type,
                  value,
                  ...leafRest
                },
              },
            ) => ({
              ...result,
              [name]: {
                comparator: comparator as Comparators,
                metadata: {
                  caseSensitive:
                    (metadata && metadata.caseSensitive) || undefined,
                },
                type: type as FilterType,
                value: value.map(({ value: innerValue, type: innerType }) => ({
                  value: innerValue || '',
                  type: innerType as FilterValueType,
                })),
                ...leafRest,
              },
            }),
            {},
          ),
        },
      };
      return {
        ...result,
        [name]: searchTreeBranch,
      };
    },
    {},
  );

  const searchState = {
    branches: keyIndexedBranches,
    roots: roots.slice(),
    globalFilters: {},
  };
  return searchState;
};
