import React from 'react';
import { useRecoilState } from 'recoil';

import { glideTableSelection } from '../../atoms/glideTableSelection';
import { GridSelection } from '@glideapps/glide-data-grid';
import GlideDataGridSelectionControl from './GlideDataGridSelectionControl';

interface ConnectedGlideDataGridSelectionControlProps {
  page: number;
  pageTotal: number;
  tableId: string;
}

const ConnectedGlideDataGridSelectionControl = ({
  page,
  pageTotal,
  tableId,
}: ConnectedGlideDataGridSelectionControlProps) => {
  const [gridSelectionByPage, setGridSelectionByPage] = useRecoilState(
    glideTableSelection(tableId),
  );

  const handleGridSelectionChange = React.useCallback(
    (newSelection: GridSelection) => {
      setGridSelectionByPage((prev) => {
        return {
          ...prev,
          [page]: newSelection,
        };
      });
    },
    [page, setGridSelectionByPage],
  );

  return (
    <GlideDataGridSelectionControl
      gridSelection={gridSelectionByPage[page]}
      onGridSelectionChange={handleGridSelectionChange}
      pageTotal={pageTotal}
    />
  );
};

export default ConnectedGlideDataGridSelectionControl;
