import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { UserProvider } from '../contexts';
import { enhanceIdTokenPayload } from '../utils';
import { Auth0User } from '../types';

const PrivateRoute: React.FC<RouteProps> = ({ path, ...rest }) => {
  const { isAuthenticated, user } = useAuth0();
  const [enhancedUser, setEnhancedUser] = React.useState<Auth0User | null>(
    null,
  );

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.Cypress) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const auth0 = JSON.parse(localStorage.getItem('auth0Cypress')!);
      setEnhancedUser(enhanceIdTokenPayload(auth0.user));
    } else {
      setEnhancedUser(enhanceIdTokenPayload(user));
    }
  }, [isAuthenticated, user]);

  if (!enhancedUser) {
    return null;
  }

  return (
    <UserProvider user={enhancedUser}>
      <Route path={path} {...rest} />
    </UserProvider>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const privateRoute = window.Cypress
  ? PrivateRoute
  : withAuthenticationRequired(PrivateRoute);
export default privateRoute;
