import { InMemoryCache, Reference } from '@apollo/client';
import {
  SearchTargetType,
  DataType,
  DataPoints_dataPoints,
  EditEntities_editEntities_validations_fieldValidations_annotations,
  AnnotationSeverity,
  AccessSourceType,
} from './types/schemaTypes';
import { DATA_TYPE_TO_FILTER_TYPE } from './constants';
import { relayStylePagination as infiniteScrollPagination } from '@apollo/client/utilities';
import {
  relayStylePagination,
  relayStylePaginationNested,
} from './utils/relayStylePagination';

const createCache = () =>
  new InMemoryCache({
    possibleTypes: {
      InputOption: [
        'LocalSelectInputOptions',
        'Autogenerated',
        'TextInputOptions',
        'DateTimeInputOptions',
        'NumericInputOptions',
      ],
      SearchTarget: ['EntityType', 'LinkType'],
      ByTypeResultsConnection: [
        'EntityResultsByTypeConnection',
        'UnclassifiedResultGroupsConnection',
      ],
    },
    typePolicies: {
      Query: {
        fields: {
          entities: relayStylePaginationNested(['searchQuery']),
          entitiesByType: relayStylePaginationNested(['searchQuery', 'type']),
          entitiesByTypeForSearch: relayStylePaginationNested([
            'searchQuery',
            'type',
          ]),
          linksForSummary: relayStylePagination(['filter']),
          users: relayStylePaginationNested(['filter']),
          groupMembership: relayStylePaginationNested(['id']),
          uploads: relayStylePagination(['filter']),
          groups: relayStylePaginationNested(['filter']),
          projects: relayStylePaginationNested(['filter']),
          unclassifiedGroupMembers: relayStylePaginationNested([
            'groupId',
            'searchQuery',
          ]),
          entityHistory: infiniteScrollPagination(['id', 'order', 'filter']),
          entity(_, { args, toReference }) {
            if (args) {
              return toReference({
                __typename: 'Entity',
                id: args.id,
              });
            }
          },
          entitiesById(_, { args, toReference }) {
            if (args) {
              return args.ids.map((id: string) =>
                toReference({
                  __typename: 'Entity',
                  id,
                }),
              );
            }
          },
          activeUploads: {
            read: (existing) => {
              return existing || [];
            },
          },
          searchSavedSearches: relayStylePaginationNested(['filter', 'order']),
        },
      },
      User: {
        fields: {
          projectAccess: relayStylePagination(false),
          groupMembership: relayStylePagination(false),
        },
      },
      Project: {
        fields: {
          projectAccess: relayStylePagination(false),
          groupProjectAccess: relayStylePagination(false),
        },
      },
      Group: {
        fields: {
          groupProjectAccess: relayStylePagination(false),
          groupMembership: relayStylePagination(false),
        },
      },
      DataFieldDefinition: {
        fields: {
          type: {
            read(_, { readField }) {
              const dataPoint = readField<DataPoints_dataPoints>('dataPoint');
              if (dataPoint) {
                return DATA_TYPE_TO_FILTER_TYPE[dataPoint.dataType];
              }
            },
          },
        },
      },
      DataPoint: {
        fields: {
          UIType: {
            read(_, { readField }) {
              const dataType = readField<DataType>('dataType');
              if (dataType) {
                return DATA_TYPE_TO_FILTER_TYPE[dataType];
              }
              return DATA_TYPE_TO_FILTER_TYPE.STRING;
            },
          },
        },
      },
      EntityFieldValidation: {
        fields: {
          displayStatus: {
            read(_, { readField }) {
              const annotations =
                readField<
                  EditEntities_editEntities_validations_fieldValidations_annotations[]
                >('annotations');
              if (!annotations || annotations.length === 0) return null;
              if (
                annotations &&
                annotations.some(
                  (annotation) =>
                    annotation.severity === AnnotationSeverity.ERROR,
                )
              ) {
                return AnnotationSeverity.ERROR;
              } else {
                return AnnotationSeverity.WARNING;
              }
            },
          },
        },
      },
      EntityType: {
        fields: {
          searchTargetType: {
            read() {
              return SearchTargetType.ENTITY_TYPE;
            },
          },
        },
      },
      LinkType: {
        fields: {
          searchTargetType: {
            read() {
              return SearchTargetType.RELATIONSHIP_TYPE;
            },
          },
        },
      },
      ProjectAccess: {
        fields: {
          hasDirectAccess: {
            read(_, { readField, isReference }) {
              const sources = readField<readonly Reference[]>('sources');
              return (
                sources?.some((source) => {
                  if (isReference(source)) {
                    const sourceType = readField('sourceType', source);
                    return sourceType === AccessSourceType.DIRECT;
                  }
                  return false;
                }) || false
              );
            },
          },
        },
      },
      UnclassifiedTypeMatch: {
        keyFields: ['entityType', ['id']],
      },
    },
  });

export default createCache;
