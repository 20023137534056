import { InMemoryCache, MutationUpdaterFn } from '@apollo/client';

export const createCacheInvalidator =
  (regex: RegExp): MutationUpdaterFn =>
  (proxy) => {
    /**
     * https://medium.com/@martinseanhunt/how-to-invalidate-cached-data-in-apollo-and-handle-updating-paginated-queries-379e4b9e4698
     */
    const cache = proxy as InMemoryCache;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.keys(cache.data.data).forEach(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (key) => key.match(regex) && cache.data.delete(key),
    );
  };
