import { Button } from '@mui/material';
import styled from '../../services/styled';

const ListSummaryButton = styled(Button)`
  background: ${(p) => p.theme.palette.background.default};
  height: 30px;
  padding: 0 10px 0 10px;
  border: 1px solid ${(p) => p.theme.palette.grey[600]};
  :hover {
    background: ${(p) => p.theme.palette.grey[600]};
    border: 1px solid ${(p) => p.theme.palette.grey[600]};
  }
  :active {
    background: #000000;
    border: 1px solid #000000;
  }
`;

export default ListSummaryButton;
