import { Box, ButtonBase, Typography } from '@mui/material';
import React from 'react';
import { BoxProps } from '@mui/material/Box';
import theme from '../../services/theme';

type TabVariants = 'underline' | 'background';

interface SNTabProps extends Omit<BoxProps, 'onClick'> {
  active: string;
  value: string;
  onClick: (value: string) => void;
  snVariant?: TabVariants;
  disabled?: boolean;
}

const getTextColor = (disabled: boolean, active: string, value: string) => {
  if (disabled) {
    return theme.palette.text.disabled;
  }
  if (active === value) {
    return theme.palette.text.primary;
  } else {
    return theme.palette.text.secondary;
  }
};

const SNTabBackground: React.FC<Omit<SNTabProps, 'onClick' | 'snVariant'>> = ({
  active,
  value,
  children,
  disabled = false,
  ...rest
}) => (
  <Box
    height={40}
    display="flex"
    alignItems="center"
    color={getTextColor(disabled, active, value)}
    px={2}
    bgcolor={active === value ? theme.palette.grey[900] : 'transparent'}
    whiteSpace="nowrap"
    {...rest}
  >
    {typeof children === 'string' ? (
      <Typography variant="h5" color="inherit">
        {children}
      </Typography>
    ) : (
      <Box>{children}</Box>
    )}
  </Box>
);

const SNTabUnderline: React.FC<Omit<SNTabProps, 'onClick' | 'snVariant'>> = ({
  active,
  value,
  children,
  disabled = false,
  ...rest
}) => (
  <Box
    width="auto"
    py={2}
    border={3}
    borderRight={0}
    borderLeft={0}
    borderTop={0}
    borderColor={active === value ? 'primary.main' : 'transparent'}
    color={getTextColor(disabled, active, value)}
    whiteSpace="nowrap"
    {...rest}
  >
    {typeof children === 'string' ? (
      <Typography variant="h3" color="inherit">
        {children}
      </Typography>
    ) : (
      <Box>{children}</Box>
    )}
  </Box>
);

const SNTab: React.FC<SNTabProps> = ({
  onClick,
  children,
  disabled,
  snVariant = 'underline',
  value,
  ...rest
}) => {
  const handleClick = React.useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  return (
    <ButtonBase disableRipple onClick={handleClick} disabled={disabled}>
      {snVariant === 'background' && (
        <SNTabBackground disabled={disabled} value={value} {...rest}>
          {children}
        </SNTabBackground>
      )}
      {snVariant === 'underline' && (
        <SNTabUnderline disabled={disabled} value={value} {...rest}>
          {children}
        </SNTabUnderline>
      )}
    </ButtonBase>
  );
};

export default SNTab;
