import React from 'react';
import { Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import SNButton from '../SNButton';
import SNDialogTitle from '../SNDialogTitle';

interface SystemRoleChangeWarningModalProps {
  isOpen: boolean;
  setClosed: () => void;
}

const SystemRoleChangeWarningModal = ({
  isOpen,
  setClosed,
}: SystemRoleChangeWarningModalProps) => {
  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <SNDialogTitle onClose={setClosed}>Please Note</SNDialogTitle>
      <DialogContent>
        <Box>
          Changing a user&apos;s system role does not automatically change their
          project roles. Users may still have higher levels of access on
          projects than their new system role would normally allow.
          <Box m={2} />
          If you want to adjust the user&apos;s project access to match the
          system role, you should manually change their role for each of the
          projects they can access.
        </Box>
      </DialogContent>
      <DialogActions>
        <SNButton snVariant="primary" onClick={setClosed}>
          Ok, understood.
        </SNButton>
      </DialogActions>
    </Dialog>
  );
};

export default SystemRoleChangeWarningModal;
