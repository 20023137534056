import { atom } from 'recoil';

interface UserMetadataEditModalOpenInterface {
  entityId: string;
  open: boolean;
}

export const userMetadataEditModalOpenAtom =
  atom<UserMetadataEditModalOpenInterface>({
    key: 'UserMetadataEditModalOpen',
    default: { entityId: '', open: false },
  });
