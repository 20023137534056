import React from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import SNButton from '../SNButton';
import UsersToAddSelector from '../UsersToAddSelector';
import { SelectedUsers } from '../UsersToAddSelector/UsersToAddSelector';
import { useParams } from 'react-router';
import { useAddUsersToProjectsMutation } from '../../mutations/useAddUsersToProjects';
import SNDialogTitle from '../SNDialogTitle';
import { collectErrors } from '../../utils/collectErrors';
import SNStatusBanner from '../SNStatusBanner';

interface AddUsersToProjectFormProps {
  onClose: () => void;
}

const AddUsersToProjectForm = ({ onClose }: AddUsersToProjectFormProps) => {
  const { id: projectId } = useParams<{ id: string }>();
  const [selectedUsers, setSelectedUsers] = React.useState<SelectedUsers>({});
  const [addUsersToProject, { data: usersAdded }] =
    useAddUsersToProjectsMutation({
      refetchQueries: ['UsersOfProject'],
      onCompleted: () => {
        onClose();
      },
    });

  const handleAddUsersToProject = React.useCallback(() => {
    const selectedUserIds = Object.keys(selectedUsers);
    if (projectId && selectedUserIds.length > 0) {
      const grants = selectedUserIds.map((userId) => {
        return {
          userId,
          projectId,
          userRole: selectedUsers[userId].defaultRole,
        };
      });
      addUsersToProject({ variables: { input: { grants } } });
    }
  }, [addUsersToProject, projectId, selectedUsers]);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([usersAdded?.grantUsersAccessToProjects?.errors]);
  }, [usersAdded]);

  return (
    <>
      <SNDialogTitle onClose={onClose}>Add Users to Project</SNDialogTitle>
      <DialogContent>
        {collectedErrors.length > 0 && (
          <Box mb={2}>
            <SNStatusBanner status="error">
              <ul>
                {collectedErrors.map((error) => {
                  return <li key={error}>{error}</li>;
                })}
              </ul>
            </SNStatusBanner>
          </Box>
        )}
        <UsersToAddSelector
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          showRole
        />
      </DialogContent>
      <DialogActions>
        <SNButton onClick={onClose}>Cancel</SNButton>
        <SNButton
          snVariant="primary"
          onClick={handleAddUsersToProject}
          disabled={Object.keys(selectedUsers).length === 0}
        >
          Save
        </SNButton>
      </DialogActions>
    </>
  );
};

export default AddUsersToProjectForm;
