import cuid from 'cuid';
import { Filters, FilterLeaves, GlobalFilter } from '../types';
import { Draft } from 'immer';
import { Connective } from '../types/schemaTypes';

const generateGlobalStatusFilters = (
  selectedFilters: string[],
): Draft<GlobalFilter> => {
  const newRootFilterId = cuid();
  const filterIds = selectedFilters.map(() => cuid());

  // ERROR and WARNING case,
  const metadataSeverityFilter = (
    selectedFilter: string,
    filterId: string,
    connective: Connective = Connective.OR,
  ): Draft<FilterLeaves> => {
    return {
      [filterId]: {
        type: 'STRING',
        parent: newRootFilterId,
        connective,
        comparator: 'EQUAL_TO',
        fieldId: 'system.annotations->severity',
        value: [
          {
            type: 'STRING',
            value: selectedFilter,
          },
        ],
      },
    };
  };
  // UNCLASSIFIED and COMPLETED case,
  const metadataStatusFilter = (
    selectedFilter: string,
    filterId: string,
    connective: Connective = Connective.OR,
  ): Draft<FilterLeaves> => {
    return {
      [filterId]: {
        type: 'STRING',
        parent: newRootFilterId,
        connective,
        comparator: 'EQUAL_TO',
        fieldId: 'system.status.state',
        value: [
          {
            type: 'STRING',
            value: selectedFilter,
          },
        ],
      },
    };
  };

  const generateFilterGroup = (): Draft<Filters> => {
    return {
      rootFilterId: newRootFilterId,
      filterBranches: {
        [newRootFilterId]: {
          parent: '',
          children: filterIds,
          connective: Connective.OR,
        },
      },
      filterLeaves: {},
    };
  };

  let combinedFilters: Draft<FilterLeaves> = {};
  selectedFilters.forEach((selected, index) => {
    if (selected === 'ERROR' || selected === 'WARNING') {
      combinedFilters = {
        ...combinedFilters,
        ...metadataSeverityFilter(selected, filterIds[index]),
      };
    } else if (selected === 'UNCLASSIFIED' || selected === 'COMPLETED') {
      combinedFilters = {
        ...combinedFilters,
        ...metadataStatusFilter(selected, filterIds[index]),
      };
    }
  });

  const filters = generateFilterGroup();
  filters.filterLeaves = {
    ...combinedFilters,
  };
  const globalFilterObject = {
    appliesTo: [],
    filters: filters,
  };

  return globalFilterObject;
};

export default generateGlobalStatusFilters;
