import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import { Users, UsersVariables } from '../types/schemaTypes';

export const FULL_PAGINATED_USERS = gql`
  query Users(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: UsersFilter
  ) {
    users(
      after: $after
      before: $before
      first: $first
      last: $last
      filter: $filter
    ) {
      data {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
          hasPreviousPage
          endIndex @client
          startIndex @client
        }
        edges {
          cursor
          node {
            id
            name
            picture
            email
            defaultRole
            source
            status
            groupMembership {
              totalCount
            }
            projectAccess {
              totalCount
            }
          }
        }
      }
      errors
    }
  }
`;

export const useLazyUsersPaginatedQuery = (
  options?: LazyQueryHookOptions<Users, UsersVariables>,
) => {
  return useLazyQuery<Users, UsersVariables>(FULL_PAGINATED_USERS, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};

export const useUsersPaginatedQuery = (
  options?: QueryHookOptions<Users, UsersVariables>,
) => {
  return useQuery<Users, UsersVariables>(FULL_PAGINATED_USERS, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};
