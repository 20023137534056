import React from 'react';
import { Box, ListItemText, MenuItem } from '@mui/material';
import Dropdown from '../Dropdown';
import Checkbox from '../Checkbox';
import { UploadState } from '../../types/schemaTypes';
import { useUploadTabsDispatch, useUploadTabsState } from '../../contexts';

interface Option {
  primary: string;
  secondary?: string;
  value: UploadState;
}

const options: Option[] = [
  {
    primary: 'Needs Review',
    secondary:
      'Show uploads that include entities which need their data reviewed',
    value: UploadState.NEEDS_REVIEW,
  },
  {
    primary: 'Capturing',
    secondary:
      'Show uploads whose entities are currently being collected from the raw files by the system',
    value: UploadState.CAPTURING,
  },
  {
    primary: 'Completed',
    secondary:
      'Show uploads that are finished capturing and processing and are completley imported into the system',
    value: UploadState.COMPLETED,
  },
  {
    primary: 'Processing',
    secondary:
      'Show uploads whose entities are being validated and checked by the system',
    value: UploadState.PROCESSING,
  },
];

const labelValue: Record<string, string> = {
  [UploadState.NEEDS_REVIEW]: 'need review',
  [UploadState.CAPTURING]: 'are capturing',
  [UploadState.COMPLETED]: 'are completed',
  [UploadState.PROCESSING]: 'are processing',
};

const UploadFilterDropdown = () => {
  const selectedValue = useUploadTabsState();
  const [checked, setChecked] = React.useState<UploadState[]>(
    selectedValue === null
      ? [
          UploadState.NEEDS_REVIEW,
          UploadState.CAPTURING,
          UploadState.COMPLETED,
          UploadState.PROCESSING,
        ]
      : selectedValue,
  );
  const setSelectedValue = useUploadTabsDispatch();
  const setUploadFilter = React.useCallback(setSelectedValue, [
    setSelectedValue,
  ]);

  React.useEffect(() => {
    setUploadFilter(checked);
  }, [setUploadFilter, checked]);

  const handleToggle = (value: Option['value']) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const label = (values: string[]) => {
    const labels = values.map((value) => labelValue[value]);
    if (labels.length === 0 || labels.length === 4) {
      return 'Showing all uploads';
    }
    if (labels.length === 1) {
      return `Showing uploads that ${labels[0]}`;
    }
    return `Showing uploads that ${labels
      .slice(0, labels.length - 1)
      .join(', ')}${
      labels.length > 1 ? ` and ${labels[labels.length - 1]}` : undefined
    }`;
  };

  return (
    <Box>
      <Dropdown label={label(checked)}>
        {options.map(({ primary, secondary, value }) => (
          <MenuItem key={value} disableRipple onClick={handleToggle(value)}>
            <Box pr={2}>
              <Checkbox
                tabIndex={-1}
                disableRipple
                checked={checked.indexOf(value) !== -1}
              />
            </Box>
            <Box pr={2}>
              <ListItemText primary={primary} secondary={secondary} />
            </Box>
          </MenuItem>
        ))}
      </Dropdown>
    </Box>
  );
};

export default UploadFilterDropdown;
