import React from 'react';
import {
  MenuItem,
  Box,
  Tooltip,
  ButtonBase,
  Typography,
  Popover,
  MenuList,
  ButtonBaseProps,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useUpsertUserMutation } from '../../mutations/useUpsertUser';
import { useUserState } from '../../contexts';
import UserAvatar from '../UserAvatar';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import styled from '../../services/styled';
import { Link as RouterLink } from 'react-router-dom';

interface StyledButtonProps extends ButtonBaseProps {
  open: boolean;
}

const StyledButton = styled(({ open, ...rest }: StyledButtonProps) => (
  <ButtonBase {...rest} />
))`
  .MuiSvgIcon-root {
    fill: ${({ open, theme }) =>
      open ? theme.palette.text.primary : theme.palette.text.secondary};
  }
  .MuiAvatar-root {
    transition: border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-color: ${({ open, theme }) =>
      open ? theme.palette.text.primary : 'transparent'};
  }
  :hover {
    .MuiAvatar-root {
      border-color: ${({ theme }) => theme.palette.text.primary};
    }
    .MuiSvgIcon-root {
      fill: ${({ theme }) => theme.palette.text.primary};
    }
  }
`;

const UserMenu: React.FC = () => {
  const user = useUserState();
  const { logout } = useAuth0();
  const [upsertUser] = useUpsertUserMutation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const { userId: id, name, email, picture, source } = user;
    upsertUser({
      variables: {
        input: {
          id,
          name,
          email,
          picture,
          source,
        },
      },
    });
  }, [upsertUser, user]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(false);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logoutWithRedirect = () => {
    handleMenuClose();
    logout({
      returnTo: window.location.origin,
    });
  };

  const handleTooltipOpen = () => {
    if (!anchorEl) {
      setOpen(true);
    }
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <Box position="relative" data-testid="user-menu">
      <Tooltip
        open={open}
        enterDelay={700}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        title={
          <>
            <Box fontWeight={600}>
              <Typography>Snthesis Account</Typography>
            </Box>
            <Typography>{user.name}</Typography>
            <Typography>{user.email}</Typography>
          </>
        }
      >
        <div>
          <StyledButton
            id="user-menu-trigger"
            onClick={handleClick}
            open={Boolean(anchorEl)}
            disableRipple
          >
            <Box height={72} display="flex" alignItems="center">
              <UserAvatar name={user.name} picture={user.picture} size={42} />
              <Box pl="6px">{anchorEl ? <ExpandLess /> : <ExpandMore />}</Box>
            </Box>
          </StyledButton>
        </div>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <Box py={1}>
          <Box
            height={42}
            px={2}
            display="flex"
            alignItems="center"
            bgcolor="#212224"
          >
            <Typography variant="h4" color="textSecondary">
              {user.name}
            </Typography>
          </Box>
          <MenuList disablePadding>
            <MenuItem
              id="profile"
              component={RouterLink}
              to="/profile"
              onClick={handleMenuClose}
            >
              My Account
            </MenuItem>
            <MenuItem id="logout" onClick={logoutWithRedirect}>
              Log Out
            </MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </Box>
  );
};

export default UserMenu;
