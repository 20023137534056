import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  DeleteSavedSearches,
  DeleteSavedSearchesVariables,
} from '../types/schemaTypes';

export const DELETE_SAVED_SEARCHES = gql`
  mutation DeleteSavedSearches($input: DeleteSavedSearchesInput!) {
    deleteSavedSearches(input: $input) {
      data {
        deleteCount
      }
      errors
    }
  }
`;

export const useDeleteSavedSearchesMutation = (
  options?: MutationHookOptions<
    DeleteSavedSearches,
    DeleteSavedSearchesVariables
  >,
) => {
  return useMutation<DeleteSavedSearches, DeleteSavedSearchesVariables>(
    DELETE_SAVED_SEARCHES,
    options,
  );
};
