import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useActiveUploadsQuery } from '../../queries';
import xlsIcon from '../../assets/xlsIcon.svg';

import {
  Box,
  CircularProgress,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { FileUploadStatus } from '../../types/schemaTypes';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useActiveUploadsSet } from '../../mutations';

const UploadSnackbar = () => {
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [activeUploadsSet] = useActiveUploadsSet();
  const activeUploads = useActiveUploadsQuery();
  const inProgressUploads = React.useMemo(
    () =>
      activeUploads.filter(
        (upload) => upload.status === FileUploadStatus.IN_PROGRESS,
      ),
    [activeUploads],
  );
  const completeUploads = React.useMemo(
    () =>
      activeUploads.filter(
        (upload) => upload.status === FileUploadStatus.COMPLETE,
      ),
    [activeUploads],
  );

  React.useEffect(() => {
    if (activeUploads.length > 0) {
      setOpen(true);
    }
  }, [activeUploads]);

  const handleClose = () => {
    setOpen(false);
    activeUploadsSet({
      variables: {
        activeUploads: [],
      },
    });
  };

  const anyIncomplete = React.useMemo(() => {
    return activeUploads.some(
      (upload) => upload.status === FileUploadStatus.IN_PROGRESS,
    );
  }, [activeUploads]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Snackbar
      id="file-upload-status"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={anyIncomplete ? null : 1000}
      onClose={handleClose}
    >
      <Box bgcolor="background.paper" minWidth={360}>
        <Box
          bgcolor="grey.900"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height={52}
        >
          {inProgressUploads.length > 0 && (
            <Box pl={2} mr={2}>
              <Typography>
                {inProgressUploads.length} file
                {inProgressUploads.length !== 1 && 's'} uploading
              </Typography>
            </Box>
          )}
          {completeUploads.length > 0 && (
            <Box ml={2} pr={2}>
              <Typography>
                {completeUploads.length} file
                {completeUploads.length !== 1 && 's'} uploaded
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="expand collapse"
              color="inherit"
              onClick={handleExpand}
            >
              {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Collapse in={expanded}>
          <Box>
            <List>
              {activeUploads.map((activeUpload) => (
                <ListItem key={activeUpload.id}>
                  <ListItemAvatar>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      mt={1}
                    >
                      <img
                        src={xlsIcon}
                        alt="Microsoft Excel Document Upload"
                        style={{ width: 38 }}
                      />
                    </Box>
                  </ListItemAvatar>
                  <ListItemText primary={activeUpload.fileName} />
                  <ListItemSecondaryAction>
                    {activeUpload.status === FileUploadStatus.IN_PROGRESS && (
                      <CircularProgress size={20} />
                    )}
                    {activeUpload.status === FileUploadStatus.COMPLETE && (
                      <Box color="success.main">
                        <CheckCircleIcon color="inherit" />
                      </Box>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>
        </Collapse>
      </Box>
    </Snackbar>
  );
};

export default UploadSnackbar;
