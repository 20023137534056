import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import { ProjectsOfUser, ProjectsOfUserVariables } from '../types/schemaTypes';

export const PROJECTS_OF_USER = gql`
  query ProjectsOfUser(
    $id: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: ProjectFilter
  ) {
    user(id: $id) {
      user {
        id
        name
        picture
        email
        defaultRole
        projectAccess(
          after: $after
          before: $before
          first: $first
          last: $last
          filter: $filter
        ) {
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
            endIndex @client
            startIndex @client
          }
          totalCount
          edges {
            cursor
            node {
              id
              userRole
              createdAt
              project {
                id
                name
                description
              }
              hasDirectAccess @client
              sources {
                id
                sourceType
              }
            }
          }
        }
      }
      errors
    }
  }
`;

export const useLazyProjectsOfUserQuery = (
  options?: LazyQueryHookOptions<ProjectsOfUser, ProjectsOfUserVariables>,
) => {
  return useLazyQuery<ProjectsOfUser, ProjectsOfUserVariables>(
    PROJECTS_OF_USER,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );
};

export const useProjectsOfUserQuery = (
  options?: QueryHookOptions<ProjectsOfUser, ProjectsOfUserVariables>,
) => {
  return useQuery<ProjectsOfUser, ProjectsOfUserVariables>(PROJECTS_OF_USER, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};
