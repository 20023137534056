import {
  useState,
  useRef,
  useEffect,
  MutableRefObject,
  Dispatch,
  SetStateAction,
} from 'react';

type Hook = <T extends HTMLElement>() => [
  MutableRefObject<T | null>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
];

const useHover: Hook = <T extends HTMLElement>() => {
  const [value, setValue] = useState(false);

  const ref = useRef<T | null>(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseenter', handleMouseOver);
        node.addEventListener('mouseleave', handleMouseOut);

        return () => {
          node.removeEventListener('mouseenter', handleMouseOver);
          node.removeEventListener('mouseleave', handleMouseOut);
        };
      }
    },
    [ref], // Recall only if ref changes
  );

  return [ref, value, setValue];
};

export default useHover;
