import {
  useSearchFormState,
  useSearchFormDispatch,
  searchFormActions,
} from '../contexts';
import { isFilterGroup } from '../utils';

function useFilterDelete() {
  const {
    filter: { filterBranches, filterLeaves },
  } = useSearchFormState();
  const dispatch = useSearchFormDispatch();

  return (id: string) => {
    const filter = filterBranches[id] || filterLeaves[id];
    if (!filter) return;
    const parentFilter = filterBranches[filter.parent];
    // First update the parent filter's list of children
    // This will hide it in the UI
    if (parentFilter) {
      dispatch(
        searchFormActions.filter.branch.update({
          id: filter.parent,
          value: {
            children: parentFilter.children.filter((childId) => childId !== id),
          },
        }),
      );
    }
    // Then we actually delete the filter object (and it's descendants if applicable)
    if (isFilterGroup(filter)) {
      dispatch(
        searchFormActions.filter.branch.delete({
          id,
        }),
      );
    } else {
      dispatch(
        searchFormActions.filter.leaf.delete({
          id,
        }),
      );
    }
  };
}

export default useFilterDelete;
