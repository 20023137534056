import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import UserAvatar from '../UserAvatar';
import Checkbox from '../Checkbox';
import UploadQualityReviewButton from '../UploadQualityReviewButton';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { listItemByIndexSelector } from '../../atoms/listSelection';
import ExtensionIcon from '../ExtensionIcon';
import moment from 'moment';
import { UploadState } from '../../types/schemaTypes';
import SNTableRow from '../SNTableRow';

interface UploadListItemProps {
  completedAt: string | null;
  fileName: string;
  id: string;
  index: number;
  name: string;
  reviewCount: number;
  subset: boolean;
  uploadState: UploadState;
  userName: string;
  userPicture: string;
}

const UploadListItem = ({
  completedAt,
  fileName,
  id,
  index,
  name,
  reviewCount,
  subset,
  uploadState,
  userName,
  userPicture,
}: UploadListItemProps) => {
  const [selected, selectRow] = useRecoilState(listItemByIndexSelector(index));

  const getLinkTarget = (uploadState: UploadState, id: string) => {
    if (uploadState === UploadState.NEEDS_REVIEW) {
      return `/review/saved/${id}`;
    }
    if (uploadState === UploadState.COMPLETED) {
      return `/search/${id}`;
    }
    return '';
  };

  const handleRowSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      selectRow(checked);
    },
    [selectRow],
  );

  const getStatusText = React.useCallback((uploadState: UploadState) => {
    switch (uploadState) {
      case UploadState.CAPTURING:
        return 'Capturing';
      case UploadState.COMPLETED:
        return 'Complete';
      case UploadState.NEEDS_REVIEW:
        return 'Uploaded';
      case UploadState.PROCESSING:
        return 'Processing';
    }
  }, []);

  return (
    <SNTableRow selected={selected}>
      <td>
        <Box pl={4} pr={3} position="relative">
          <Checkbox checked={selected} onChange={handleRowSelect} />
        </Box>
      </td>
      <td>
        <Box display="flex" alignItems="center" height={56}>
          <ExtensionIcon fileName={fileName || name} />
        </Box>
      </td>
      <td>
        <Link to={getLinkTarget(uploadState, id)} component={RouterLink}>
          <Box display="flex" alignItems="center" height={56}>
            <Box fontWeight={600}>
              <Typography>
                {(fileName || name).slice(0, (fileName || name).length - 14)}
              </Typography>
            </Box>
            <Box fontWeight={600}>
              <Typography>{(fileName || name).slice(-14)}</Typography>
            </Box>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={getLinkTarget(uploadState, id)} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            {subset ? (
              <Box overflow="hidden" textOverflow="ellipsis">
                <Typography>{name}</Typography>
              </Box>
            ) : (
              <Typography color="textSecondary">No subset selected</Typography>
            )}
          </Box>
        </Link>
      </td>
      <td>
        <Link to={getLinkTarget(uploadState, id)} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <UploadQualityReviewButton
              status={uploadState}
              count={reviewCount}
              id={id}
            />
          </Box>
        </Link>
      </td>
      <td>
        <Link to={getLinkTarget(uploadState, id)} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <Typography>{moment(completedAt).format('YYYY-MM-DD')}</Typography>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={getLinkTarget(uploadState, id)} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <UserAvatar
              name={userName || 'Unknown'}
              picture={userPicture || undefined}
              size={32}
            />
          </Box>
        </Link>
      </td>
      <td>
        <Link to={getLinkTarget(uploadState, id)} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <Typography>{getStatusText(uploadState)}</Typography>
          </Box>
        </Link>
      </td>
    </SNTableRow>
  );
};

export default UploadListItem;
