import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import { ProjectAccess, ProjectAccessVariables } from '../types/schemaTypes';

export const PROJECT_ACCESS = gql`
  query ProjectAccess($id: ID!) {
    projectAccess(id: $id) {
      projectAccess {
        id
        user {
          id
          name
        }
        project {
          id
          name
        }
        hasDirectAccess @client
        sources {
          id
          sourceType
          groupMembership {
            group {
              id
              name
            }
          }
        }
      }
      errors
    }
  }
`;

export const useLazyProjectAccessQuery = (
  options?: LazyQueryHookOptions<ProjectAccess, ProjectAccessVariables>,
) => {
  return useLazyQuery<ProjectAccess, ProjectAccessVariables>(PROJECT_ACCESS, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};

export const useProjectAccessQuery = (
  options?: QueryHookOptions<ProjectAccess, ProjectAccessVariables>,
) => {
  return useQuery<ProjectAccess, ProjectAccessVariables>(PROJECT_ACCESS, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};
