import { Box, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';
import React from 'react';
import { useGetGroupWithMembershipQuery } from '../../queries/useGetGroupWithMembershipQuery';
import UserSummary from '../UserSummary';
import { useSetRecoilState } from 'recoil';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { collectErrors } from '../../utils/collectErrors';

interface GroupMembershipProps {
  id: string;
}

const GroupMembership = ({ id }: GroupMembershipProps) => {
  const { data, loading } = useGetGroupWithMembershipQuery({
    variables: { id },
  });
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);

  const users = data?.group?.group?.groupMembership?.edges?.map((edge) => {
    return edge.node.user;
  });

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.group?.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error fetching group membership',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <>
      {loading && (
        <>
          {[1, 2, 3].map((number) => (
            <Box mr={1} key={number}>
              <Skeleton variant="circular" width={32} height={32} />
            </Box>
          ))}
        </>
      )}
      {!loading && (
        <>
          {!users || users?.length === 0 ? (
            <Typography color="textSecondary">No users</Typography>
          ) : (
            <UserSummary
              users={users}
              usersToShow={3}
              totalUsers={data?.group?.group?.groupMembership?.totalCount || 0}
            />
          )}
        </>
      )}
    </>
  );
};

export default GroupMembership;
