import * as React from 'react';

interface TabOverflowProviderProps {
  children: React.ReactNode;
}

type TabOverflowState = Record<string, boolean>;

const TabOverflowContext = React.createContext<TabOverflowState | undefined>(
  undefined,
);
const TabOverflowDispatchContexts = React.createContext<
  React.Dispatch<React.SetStateAction<TabOverflowState>> | undefined
>(undefined);

export const getTabOverflowInitialState = () => ({});

function TabOverflowProvider({ children }: TabOverflowProviderProps) {
  const [selectionState, setSelectionState] = React.useState<TabOverflowState>(
    {},
  );
  return (
    <TabOverflowContext.Provider value={selectionState}>
      <TabOverflowDispatchContexts.Provider value={setSelectionState}>
        {children}
      </TabOverflowDispatchContexts.Provider>
    </TabOverflowContext.Provider>
  );
}
function useTabOverflowState() {
  const context = React.useContext(TabOverflowContext);
  if (context === undefined) {
    throw new Error(
      'useTabOverflowState must be used within a TabOverflowProvider',
    );
  }
  return context;
}

function useTabOverflowDispatch() {
  const context = React.useContext(TabOverflowDispatchContexts);
  if (context === undefined) {
    throw new Error(
      'useTabOverflowDispatch must be used within a TabOverflowProvider',
    );
  }
  return context;
}
export { TabOverflowProvider, useTabOverflowState, useTabOverflowDispatch };
