import React from 'react';
import { useRecoilValue } from 'recoil';
import { selectedIdsAtom } from '../../atoms/listSelection';

interface ToolbarActionGroupProps {
  comparisonFunction: (count: number) => boolean;
}

const ToolbarActionGroup: React.FC<ToolbarActionGroupProps> = ({
  comparisonFunction,
  children,
}) => {
  const selectedIds = useRecoilValue(selectedIdsAtom);
  if (!comparisonFunction(selectedIds.length)) {
    return null;
  }
  return <>{children}</>;
};

export default ToolbarActionGroup;
