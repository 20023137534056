import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import styled from '../../services/styled';
import Checkbox from '../Checkbox';
import {
  LinkSummaryDetails_linksForSummary_edges_node,
  LinkSummaryDetails_linksForSummary_linkDefinition_type_fields,
  LinkSummaryDetails_linksForSummary_edges_node_metadata_fields,
} from '../../types/schemaTypes';
import SNTableRow from '../SNTableRow';
import Pill from '../Pill';
import { capitalize } from '../../utils/capitalize';
import { Link as RouterLink } from 'react-router-dom';
import { listItemByIndexSelector } from '../../atoms/listSelection';
import { useRecoilState } from 'recoil';

interface EntityLinksTableRowProps {
  columns: LinkSummaryDetails_linksForSummary_linkDefinition_type_fields[];
  index: number;
  pathPrefix: string;
  row: LinkSummaryDetails_linksForSummary_edges_node;
}

const StyledCell = styled('td')`
  border-bottom-color: ${({ theme }) => theme.palette.grey[600]};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

type FieldMap = Record<
  string,
  LinkSummaryDetails_linksForSummary_edges_node_metadata_fields | undefined
>;

const EntityLinksTableRow: React.FC<EntityLinksTableRowProps> = ({
  columns,
  index,
  pathPrefix,
  row,
}) => {
  const [selected, selectRow] = useRecoilState(listItemByIndexSelector(index));

  const fieldMap = React.useMemo(
    () =>
      row.metadata?.fields.reduce<FieldMap>(
        (result, field) => ({ ...result, [field.fieldDefinition.id]: field }),
        {},
      ) || {},
    [row],
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      selectRow(checked);
    },
    [selectRow],
  );

  return (
    <SNTableRow selected={selected}>
      <StyledCell>
        <Box pl={4} pr={3}>
          <Checkbox
            id={`row-select-${row.id}`}
            onChange={handleChange}
            checked={selected}
          />
        </Box>
      </StyledCell>
      <StyledCell>
        <Pill>
          <Link
            to={`${pathPrefix}/entity/${row.linkedEntity.id}`}
            component={RouterLink}
          >
            {capitalize(row.linkedEntity.displayName)}
          </Link>
        </Pill>
      </StyledCell>
      {columns.map((column) => (
        <StyledCell key={`${row.id}-${column.id}`}>
          <Typography>{fieldMap[column.id]?.value}</Typography>
        </StyledCell>
      ))}
    </SNTableRow>
  );
};

export default EntityLinksTableRow;
