import React from 'react';
import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
import styled from '../../services/styled';
import { useReviewSummaryQuery } from '../../queries';
import LoadingOverlay from '../LoadingOverlay';
import SNInput from '../SNInput';
import SNButton from '../SNButton';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useCompleteSearchObject } from '../../contexts';
import { searchInputTransform } from '../../utils';
import {
  useApproveEntitiesMutation,
  useApproveReviewMutation,
} from '../../mutations';
import SNDialogTitle from '../SNDialogTitle';
import { glideTableSelection } from '../../atoms/glideTableSelection';
import { pageIdsAtom } from '../../atoms/listSelection';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { collectErrors } from '../../utils/collectErrors';
import SNStatusBanner from '../SNStatusBanner';

const TabBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 133px;
  height: 85px;
  background: ${(p) => p.theme.palette.grey[900]};
`;

interface ReviewApprovalModalProps {
  closeModal: () => void;
  onEditSuccess?: () => void;
  page: number;
  searchId: string;
  setConfirmationSnackbarOpen: () => void;
  tableId: string;
}

const ReviewApprovalModal = ({
  closeModal,
  page,
  searchId,
  setConfirmationSnackbarOpen,
  tableId,
}: ReviewApprovalModalProps) => {
  const [approvalReason, setApprovalReason] = React.useState<string>('');
  const gridSelectionByPage = useRecoilValue(glideTableSelection(tableId));
  const resetGridSelection = useResetRecoilState(glideTableSelection(tableId));
  const pageIds = useRecoilValue(pageIdsAtom);
  const searchObject = useCompleteSearchObject(searchId);
  const searchQuery = React.useMemo(() => {
    return searchInputTransform(searchObject);
  }, [searchObject]);
  const selectedIds = pageIds.filter((id, index) =>
    gridSelectionByPage[page]?.rows.hasIndex(index),
  );

  const handleApprovalCleanup = () => {
    setConfirmationSnackbarOpen();
  };

  const [
    approveEntities,
    { loading: entityApprovalLoading, data: approveEntityData },
  ] = useApproveEntitiesMutation({
    onCompleted: (res) => {
      if (res.approveEntities.success) {
        resetGridSelection();
        handleApprovalCleanup();
      }
    },
    refetchQueries: ['EntitiesByType'],
  });
  const [
    approveReview,
    { loading: reviewApprovalLoading, data: approveReviewData },
  ] = useApproveReviewMutation({
    onCompleted: (res) => {
      if (res.approveReview.success) {
        handleApprovalCleanup();
      }
    },
    refetchQueries: ['EntitiesByType'],
  });
  //TODO: when backend support for reviews by entity id or search string becomes avaliable, rewrite this hook to use either or an upload id or null as seen here
  const { loading, data } = useReviewSummaryQuery();

  const totalCount = React.useMemo(() => {
    return data?.reviewSummary?.statusCounts?.reduce((acc, current) => {
      return acc + current.count;
    }, 0);
  }, [data]);

  const handleCloseModal = () => {
    setApprovalReason('');
    closeModal();
  };

  const handleApproveReview = () => {
    if (selectedIds.length > 0) {
      approveEntities({
        variables: {
          input: {
            ids: selectedIds,
            approval: {
              reason: approvalReason,
            },
          },
        },
      });
    } else {
      approveReview({
        variables: {
          input: {
            reviewQuery: searchQuery,
            approval: {
              reason: approvalReason,
            },
          },
        },
      });
    }
    handleCloseModal();
  };

  const collectedErrors = React.useMemo(() => {
    return collectErrors([
      approveEntityData?.approveEntities.errors,
      approveReviewData?.approveReview.errors,
    ]);
  }, [approveEntityData, approveReviewData]);

  return (
    <>
      {entityApprovalLoading ||
        (reviewApprovalLoading && (
          <LoadingOverlay
            isLoading={entityApprovalLoading || reviewApprovalLoading}
          />
        ))}
      <SNDialogTitle onClose={closeModal}>Approve Data</SNDialogTitle>
      <DialogContent>
        {collectedErrors.length > 0 && (
          <Box mb={2}>
            <SNStatusBanner status="error">
              <ul>
                {collectedErrors.map((error) => {
                  return <li key={error}>{error}</li>;
                })}
              </ul>
            </SNStatusBanner>
          </Box>
        )}
        <Typography color="textSecondary">
          <strong>
            This review has {totalCount} unresolved issues. Are you sure you
            want to approve this data?
          </strong>
        </Typography>
        <Box my={3}>
          <Typography variant="h4" color="textSecondary">
            Review Summary
          </Typography>
          <Box
            mt={2}
            display="flex"
            minWidth={504}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            {data?.reviewSummary?.statusCounts?.map((statusCount) => (
              <TabBox key={statusCount.status}>
                <Typography variant="h2">{statusCount.count}</Typography>
                <Typography variant="h4">{statusCount.status}</Typography>
              </TabBox>
            ))}
          </Box>
          <Box mt={6}>
            <SNInput
              label="Approval Notes"
              required
              value={approvalReason}
              onChange={(e) => setApprovalReason(e.target.value)}
              multiline
              fullWidth
              rows={3}
            />
          </Box>
        </Box>
        <LoadingOverlay isLoading={loading} />
      </DialogContent>
      <DialogActions>
        <SNButton onClick={handleCloseModal}>Cancel</SNButton>
        <SNButton
          snVariant="approve"
          onClick={handleApproveReview}
          disabled={!approvalReason}
          startIcon={<CheckCircle />}
        >
          Approve
        </SNButton>
      </DialogActions>
    </>
  );
};

export default ReviewApprovalModal;
