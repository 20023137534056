import React from 'react';
import { Box, Grid } from '@mui/material';
import SNTab from '../SNTab';
import TabOverflowDropdown from '../TabOverflowDropdown';
import TabOverflow from '../TabOverflow';
import { EntitiesByType_entitiesByType_data } from '../../types/schemaTypes';
import { useRecoilState, useRecoilValue } from 'recoil';
import { entityTypeTabMap } from '../../atoms/entityTypes';
import {
  searchTabsActiveTabSelector,
  searchTabsTabOrderSelector,
} from '../../atoms/latestSearchAtom';

interface EntityTypeResultTabsProps {
  searchId: string;
  disabled?: boolean;
  types?: EntitiesByType_entitiesByType_data['types'];
}

const emptyTypes: EntitiesByType_entitiesByType_data['types'] = [];

const EntityTypeResultTabs = ({
  searchId,
  disabled,
  types = emptyTypes,
}: EntityTypeResultTabsProps) => {
  const [activeTab, setActiveTab] = useRecoilState(
    searchTabsActiveTabSelector(searchId),
  );
  const [tabOrder, setTabOrder] = useRecoilState(
    searchTabsTabOrderSelector(searchId),
  );
  const tabMap = useRecoilValue(entityTypeTabMap);

  const handleTabOptionSelect = React.useCallback(
    (typeId: string) => {
      setTabOrder((previous) => [
        typeId,
        ...previous.filter((orderedTabId) => orderedTabId !== typeId),
      ]);
      setActiveTab(typeId);
    },
    [setActiveTab, setTabOrder],
  );

  return (
    <Box
      height={56}
      bgcolor="common.black"
      display="flex"
      alignItems="flex-end"
    >
      <Box
        pl={5}
        pr={20}
        display="flex"
        overflow="hidden"
        position="relative"
        width="100%"
      >
        <Grid
          component={Box}
          container
          spacing={1}
          wrap="nowrap"
          id="entity-type-tabs"
          overflow="hidden"
        >
          {tabOrder.map((tabId) => (
            <Grid item key={tabId}>
              <TabOverflow
                tabId={tabId}
                threshold={1}
                root={document.querySelector('#entity-type-tabs')}
              >
                <SNTab
                  data-testid={`${tabId}_tab`}
                  active={activeTab}
                  value={tabId}
                  onClick={setActiveTab}
                  snVariant="background"
                  disabled={disabled}
                >
                  {tabMap[tabId]}
                </SNTab>
              </TabOverflow>
            </Grid>
          ))}
        </Grid>
        <Box position="absolute" right={40}>
          <TabOverflowDropdown
            tabOptions={types}
            onTabSelect={handleTabOptionSelect}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EntityTypeResultTabs;
