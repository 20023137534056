import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  RevokeUserProjectAccess,
  RevokeUserProjectAccessVariables,
} from '../types/schemaTypes';

export const REMOVE_USER_FROM_GROUP = gql`
  mutation RevokeUserProjectAccess($input: RevokeProjectAccessInput!) {
    revokeProjectAccess(input: $input) {
      data {
        projectAccessIds
        groupMembershipIds
      }
      errors
    }
  }
`;

export const useRevokeUserProjectAccessMutation = (
  options?: MutationHookOptions<
    RevokeUserProjectAccess,
    RevokeUserProjectAccessVariables
  >,
) => {
  return useMutation<RevokeUserProjectAccess, RevokeUserProjectAccessVariables>(
    REMOVE_USER_FROM_GROUP,
    options,
  );
};
