import { atom } from 'recoil';
import { EntityJobState } from '../types/schemaTypes';

export interface Job {
  id: string;
  name: string;
  state: EntityJobState;
}

export interface ClassificationJob extends Job {
  groupId: string;
}

export const ClassificationSnackbarOpen = atom({
  key: 'classificationSnackbarOpen',
  default: false,
});

export const ClassificationJobsAtom = atom<ClassificationJob[]>({
  key: 'classificationJobs',
  default: [],
});
