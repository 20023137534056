import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { ExportSearch, ExportSearchVariables } from '../types/schemaTypes';

export const EXPORT_SEARCH = gql`
  mutation ExportSearch($input: ExportSearchInput!) {
    exportSearch(input: $input) {
      data {
        id
        type
        state
        outputDetails {
          link
          hasExportedResults
          includesRawFiles
        }
      }
      errors
    }
  }
`;

export const useExportSearchMutation = (
  options?: MutationHookOptions<ExportSearch, ExportSearchVariables>,
) => {
  return useMutation<ExportSearch, ExportSearchVariables>(
    EXPORT_SEARCH,
    options,
  );
};
