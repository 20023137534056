import React from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import SNButton from '../SNButton';
import SNInput from '../SNInput';
import SNDialogTitle from '../SNDialogTitle';
import SNStatusBanner from '../SNStatusBanner';
import { useAddTermsToListMutation } from '../../mutations';

interface CreateListModalProps {
  onClose: () => void;
  listId: string;
  listName: string;
}

const AddTermToListModal = ({
  onClose,
  listId,
  listName,
}: CreateListModalProps) => {
  const [newTermVariables, setNewTermVariables] = React.useState({
    name: '',
    description: '',
  });
  const [clickAttempted, setClickAttempted] = React.useState(false);
  const [createNewTerm, { error }] = useAddTermsToListMutation({
    variables: {
      input: {
        categoryId: listId,
        items: [
          {
            value: newTermVariables.name,
            description: newTermVariables.description,
          },
        ],
      },
    },
    onCompleted: () => {
      onClose();
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTermVariables((previous) => {
      return {
        ...previous,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleAddNewTerm = () => {
    if (newTermVariables.name) {
      createNewTerm();
      setNewTermVariables({ name: '', description: '' });
      setClickAttempted(false);
    } else {
      setClickAttempted(true);
    }
  };

  const handleClose = React.useCallback(() => {
    setNewTermVariables({ name: '', description: '' });
    setClickAttempted(false);
    onClose();
  }, [onClose, setNewTermVariables]);

  return (
    <>
      <SNDialogTitle onClose={handleClose}>
        Add New Term To {listName}
      </SNDialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          {error && (
            <Box mb={2}>
              <SNStatusBanner status="error">
                <ul>
                  <li key={error?.message}>{error?.message}</li>
                </ul>
              </SNStatusBanner>
            </Box>
          )}
          <Box mb={2}>
            <SNInput
              id="name"
              required
              autoFocus
              fullWidth
              value={newTermVariables.name}
              onChange={handleChange}
              label="Name"
              name="name"
              error={clickAttempted && !newTermVariables.name}
              helperText={
                clickAttempted && !newTermVariables.name
                  ? 'You must provide a name for the new list'
                  : ''
              }
            />
          </Box>
          <SNInput
            id="description"
            multiline
            rows={3}
            fullWidth
            value={newTermVariables.description}
            onChange={handleChange}
            label="Description"
            name="description"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <SNButton onClick={handleClose}>Cancel</SNButton>
        <SNButton
          snVariant="primary"
          onClick={handleAddNewTerm}
          disabled={!newTermVariables.name}
        >
          Add term
        </SNButton>
      </DialogActions>
    </>
  );
};

export default AddTermToListModal;
