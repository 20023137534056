import React from 'react';
import { Box, Typography } from '@mui/material';
import { UserRole } from '../../types/schemaTypes';
import SNTableRow from '../SNTableRow';
import moment from 'moment';
import RoleDropdown from '../RoleDropdown';
import ListSummaryButton from '../ListSummaryButton';
import pluralize from 'pluralize';

interface UserProjectsTableRowProps {
  id?: string;
  name?: string | null;
  description?: string | null;
  userRole: UserRole;
  createdAt: string;
  hasDirectAccess: boolean;
  sourceCount: number;
}

const UserProjectsTableRowReadOnly = ({
  name,
  id,
  description,
  userRole,
  createdAt,
  hasDirectAccess,
  sourceCount,
}: UserProjectsTableRowProps) => {
  const groupCount = sourceCount - (hasDirectAccess ? 1 : 0);

  return (
    <SNTableRow key={id}>
      <td>
        <Box display="flex" alignItems="center" height={56} pl={4}>
          <Box fontWeight={600}>
            <Typography>{name}</Typography>
          </Box>
        </Box>
      </td>
      <td>
        <Box height={56} display="flex" alignItems="center">
          <Box overflow="hidden" textOverflow="ellipsis">
            <Typography>{description}</Typography>
          </Box>
        </Box>
      </td>
      <td>
        <Box height={56} display="flex" alignItems="center">
          <RoleDropdown currentRole={userRole} id={id} disabled />
        </Box>
      </td>
      <td>
        <Box height={56} display="flex" alignItems="center">
          {hasDirectAccess && (
            <ListSummaryButton variant="text">Direct</ListSummaryButton>
          )}
          {groupCount > 0 && (
            <Box ml={hasDirectAccess ? 1 : 0}>
              <ListSummaryButton variant="text">
                {hasDirectAccess && '+'} {groupCount}{' '}
                {pluralize('Group', groupCount)}
              </ListSummaryButton>
            </Box>
          )}
        </Box>
      </td>
      <td>
        <Box height={56} display="flex" alignItems="center">
          <Typography>{moment(createdAt).format('YYYY-MM-DD')}</Typography>
        </Box>
      </td>
    </SNTableRow>
  );
};

export default UserProjectsTableRowReadOnly;
