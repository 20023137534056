import React from 'react';
import * as Sentry from '@sentry/browser';
import styled from '../../services/styled';
import env from '@beam-australia/react-env';
import WarningIcon from '@mui/icons-material/Warning';
import { Box } from '@mui/material';

const ErrorNotice = styled(Box)`
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding-top: 60px;
  width: 100%;
  height: 100%;

  .icon {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    color: rgba(255, 255, 255, 0.7);
  }
`;

export default class ErrorNotificationBoundary extends React.Component<
  any,
  any
> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      eventId: null,
      enabled: env('SENTRY_ENABLED'),
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (this.state.enabled === 'TRUE') {
      this.setState({ error, errorInfo });
      Sentry.withScope((scope) => {
        scope.setExtras({ componentStack: errorInfo.componentStack });
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorNotice>
          <h1>An Error Has Occurred</h1>
          <WarningIcon fontSize="large" />
          <h1>Snthesis has been notified and will address it shortly</h1>
        </ErrorNotice>
      );
    } else {
      return this.props.children;
    }
  }
}
