import React from 'react';
import produce from 'immer';
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SNButton from '../SNButton';
import UploadModalContextPanel from '../UploadModalContextPanel';
import { useRecoilState } from 'recoil';
import { uploadLinkingContextsAtom } from '../../atoms/uploadFiles';

const UploadModalContext = () => {
  const [contexts, setContexts] = useRecoilState(uploadLinkingContextsAtom);

  const handleAddContext = (index: number) => () => {
    setContexts((prevState) => [
      ...prevState.slice(0, index),
      {
        selectedUploadIds: [],
        existingObjectIds: [],
      },
      ...prevState.slice(index + 1),
    ]);
  };

  const handleRemoveContext = (index: number) => () => {
    setContexts((prevState) => [
      ...prevState.slice(0, index),
      ...prevState.slice(index + 1),
    ]);
  };

  const handleFileSelect =
    (index: number) => (selectedId: string, checked: boolean) => {
      setContexts((prevState) =>
        produce(prevState, (draftState) => {
          checked
            ? (draftState[index].selectedUploadIds = [
                ...new Set([
                  ...draftState[index].selectedUploadIds,
                  selectedId,
                ]),
              ])
            : (draftState[index].selectedUploadIds = draftState[
                index
              ].selectedUploadIds.filter((fileId) => fileId !== selectedId));
        }),
      );
    };

  const handleTargetSelect =
    (index: number) => (innerIndex: number) => (targetId: string) => {
      setContexts((prevState) =>
        produce(prevState, (draftState) => {
          draftState[index].existingObjectIds[innerIndex] = targetId;
        }),
      );
    };

  const handleTargetDelete = (index: number) => (innerIndex: number) => () => {
    setContexts((prevState) =>
      produce(prevState, (draftState) => {
        delete draftState[index].existingObjectIds[innerIndex];
      }),
    );
  };

  return (
    <Box py={1.5}>
      {contexts.map((context, index) => (
        <Box key={index} py={1.5}>
          <UploadModalContextPanel
            {...context}
            name={`Context ${index + 1}`}
            onRemove={
              contexts.length > 1 ? handleRemoveContext(index) : undefined
            }
            onFileSelect={handleFileSelect(index)}
            onTargetDelete={handleTargetDelete(index)}
            onTargetSelect={handleTargetSelect(index)}
          />
        </Box>
      ))}
      <Box py={1.5}>
        <SNButton
          startIcon={<AddIcon />}
          onClick={handleAddContext(contexts.length + 1)}
          hover
        >
          Add another linking context
        </SNButton>
      </Box>
    </Box>
  );
};

export default UploadModalContext;
