import gql from 'graphql-tag';
import { MutationHookOptions, useMutation } from '@apollo/client';
import {
  RevokeGroupProjectAccess,
  RevokeGroupProjectAccessVariables,
} from '../types/schemaTypes';

export const REVOKE_GROUP_ACCESS = gql`
  mutation RevokeGroupProjectAccess($input: DeleteInput!) {
    revokeGroupProjectAccess(input: $input) {
      id
      errors
    }
  }
`;

export const useRevokeGroupProjectAccess = (
  options?: MutationHookOptions<
    RevokeGroupProjectAccess,
    RevokeGroupProjectAccessVariables
  >,
) => {
  return useMutation<
    RevokeGroupProjectAccess,
    RevokeGroupProjectAccessVariables
  >(REVOKE_GROUP_ACCESS, options);
};
