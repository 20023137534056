import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  ActiveUploadsSet,
  ActiveUploadsSetVariables,
} from '../types/schemaTypes';

export const ACTIVE_UPLOADS_SET = gql`
  mutation ActiveUploadsSet($activeUploads: [ActiveUploadInput!]!) {
    activeUploadsSet(activeUploads: $activeUploads) @client {
      id
      fileName
      fileType
    }
  }
`;

export const useActiveUploadsSet = () => {
  return useMutation<ActiveUploadsSet, ActiveUploadsSetVariables>(
    ACTIVE_UPLOADS_SET,
  );
};
