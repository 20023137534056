import React from 'react';
import {
  Box,
  CheckboxProps,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
} from '@mui/material';
import SNButton from '../SNButton';
import Checkbox from '../Checkbox';
import { useSetRecoilState } from 'recoil';
import { exportJobIdsAtom } from '../../atoms/exportJobIdAtom';
import { ExportOption } from '../../types/schemaTypes';
import {
  useExportSearchMutation,
  useUpdateUserPreferences,
} from '../../mutations';
import { useCompleteSearchObject, useUserState } from '../../contexts';
import { useUserQuery } from '../../queries';
import SNDialogTitle from '../SNDialogTitle';
import SNStatusBanner from '../SNStatusBanner';
import { searchInputTransform } from '../../utils';
import LoadingOverlay from '../LoadingOverlay';

interface ExportOptionsModalProps {
  searchId: string;
  setClosed: () => void;
}

const ExportOptionsModal = ({
  setClosed,
  searchId,
}: ExportOptionsModalProps) => {
  const setExportJobIds = useSetRecoilState(exportJobIdsAtom);
  const { userId } = useUserState();
  const { data: userData, loading: userLoading } = useUserQuery({
    variables: { id: userId },
  });
  const searchObject = useCompleteSearchObject(searchId);
  const [exportOptions, setExportOptions] = React.useState(
    ExportOption.EXPORT_RESULTS_ONLY,
  );
  const [rememberExportOptions, setRememberExportOptions] =
    React.useState(false);

  React.useEffect(() => {
    const preferences = userData?.user?.user?.preferences;
    if (preferences) {
      if (preferences.exportOptionsPreference !== null) {
        setExportOptions(preferences.exportOptionsPreference);
      }
      if (preferences.rememberExportOptions !== null) {
        setRememberExportOptions(preferences.rememberExportOptions);
      }
    }
  }, [userData]);

  const [exportSearch, { data, loading }] = useExportSearchMutation({
    onCompleted: (res) => {
      const exportJobId = res?.exportSearch?.data?.id;
      if (exportJobId) {
        setExportJobIds((prev) => [exportJobId, ...prev]);
        setClosed();
      }
    },
  });

  const [updateUserPreferences] = useUpdateUserPreferences();

  const handleResultsOptionChange = React.useCallback<
    Required<RadioGroupProps>['onChange']
  >((event) => {
    setExportOptions(event.target.value as ExportOption);
  }, []);

  const handleUserPrefsToggle = React.useCallback<
    Required<CheckboxProps>['onChange']
  >((_event, checked) => {
    setRememberExportOptions(checked);
  }, []);

  const handleExportClick = () => {
    const searchQuery = searchInputTransform(searchObject);
    exportSearch({
      variables: {
        input: {
          searchQuery: searchQuery,
          includeRawFiles:
            exportOptions === ExportOption.EXPORT_RESULTS_AND_SOURCE,
        },
      },
    });
    if (rememberExportOptions) {
      updateUserPreferences({
        variables: {
          input: {
            id: userId,
            preferences: {
              rememberExportOptions: rememberExportOptions,
              exportOptionsPreference: exportOptions,
            },
          },
        },
      });
    }
  };

  return (
    <>
      <SNDialogTitle onClose={setClosed}>
        What would you like to export?
      </SNDialogTitle>
      <DialogContent>
        <Box mb={2}>
          {data?.exportSearch?.errors && data.exportSearch.errors.length > 0 && (
            <SNStatusBanner status="error">
              <ul>
                {data.exportSearch.errors.map((error) => {
                  return (
                    <li key={error}>
                      <Typography>{error}</Typography>
                    </li>
                  );
                })}
              </ul>
            </SNStatusBanner>
          )}
        </Box>
        <RadioGroup
          aria-label="Export Options"
          value={exportOptions}
          onChange={handleResultsOptionChange}
        >
          <FormControlLabel
            value={ExportOption.EXPORT_RESULTS_ONLY}
            control={<Radio />}
            label="Results only (.CSV)"
          />
          <FormControlLabel
            value={ExportOption.EXPORT_RESULTS_AND_SOURCE}
            control={<Radio />}
            label="Results and raw data (.CSV + original source files)"
          />
        </RadioGroup>
        <Box mt={4} ml={0.25} display="flex" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                id="savePrefs"
                checked={rememberExportOptions}
                onChange={handleUserPrefsToggle}
              />
            }
            label="Remember my selection"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <SNButton onClick={setClosed}>Cancel</SNButton>
        <SNButton snVariant="primary" onClick={handleExportClick}>
          Export
        </SNButton>
      </DialogActions>
      <LoadingOverlay isLoading={loading || userLoading} />
    </>
  );
};

export default ExportOptionsModal;
