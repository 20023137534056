import { Connective, SearchTargetType } from '../types/schemaTypes';
import cuid from 'cuid';
import { searchInputTransform } from './searchInputTransform';
import { SearchTree } from '../types';

const generateTargetEntitySearch = (
  allowedEntityTypeIds: string[],
  debouncedValue: string,
) => {
  const roots: string[] = [];
  const branches: SearchTree = {};

  allowedEntityTypeIds.forEach((entityTypeId) => {
    const newBranchId = cuid();
    const newRootFilterId = cuid();
    const newFilterId = cuid();
    roots.push(newBranchId);
    branches[newBranchId] = {
      target: {
        id: entityTypeId,
        type: SearchTargetType.ENTITY_TYPE,
      },
      children: [],
      parent: '',
      select: true,
      joinData: null,
      filter: {
        rootFilterId: newRootFilterId,
        filterBranches: {
          [newRootFilterId]: {
            parent: '',
            children: [newFilterId],
            connective: Connective.AND,
          },
        },
        filterLeaves: {
          [newFilterId]: {
            type: 'STRING',
            value: [
              {
                value: debouncedValue,
                type: 'STRING',
              },
            ],
            connective: Connective.AND,
            comparator: 'INCLUDES',
            metadata: {
              caseSensitive: false,
            },
            fieldId: 'system.displayName',
            parent: newRootFilterId,
          },
        },
      },
    };
  });

  return searchInputTransform({
    branches: branches,
    roots: roots,
    globalFilters: {},
  });
};

export default generateTargetEntitySearch;
