import { Box, Grid } from '@mui/material';
import React from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { selectAllResultsSelector } from '../../atoms/listSelection';
import { TabOverflowProvider } from '../../contexts';
import { useEntityDetailsQuery } from '../../queries/useEntityDetailsQuery';
import { EntityById_entityById_data_linkSummaries } from '../../types/schemaTypes';
import { capitalize } from '../../utils/capitalize';
import EntityLinksTable from '../EntityLinksTable';
import EntityLinkSummaryTable from '../EntityLinkSummaryTable';
import SNTab from '../SNTab';
import TabOverflow from '../TabOverflow';
import TabOverflowDropdown from '../TabOverflowDropdown';
import { collectErrors } from '../../utils/collectErrors';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';

interface EntityLinkSummaryTabsProps {
  id: string;
}

type TabMap = Record<string, EntityById_entityById_data_linkSummaries>;

const EntityLinkSummaryTabs: React.FC<EntityLinkSummaryTabsProps> = ({
  id,
}) => {
  const [activeId, setActiveId] = React.useState<string>('summary');
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const [tabOrder, setTabOrder] = React.useState<string[]>([]);
  const [tabMap, setTabMap] = React.useState<TabMap>({});
  const resetSelectionState = useResetRecoilState(selectAllResultsSelector);
  const { data } = useEntityDetailsQuery({
    variables: { id },
    onCompleted: (result) => {
      if (result.entityById?.data) {
        const newTabMap = result.entityById?.data.linkSummaries.reduce<TabMap>(
          (result, linkSummary) => {
            return {
              ...result,
              [linkSummary.id]: linkSummary,
            };
          },
          {},
        );
        setTabMap(newTabMap);
        setTabOrder(Object.keys(newTabMap));
        setActiveId('summary');
      }
    },
  });

  const handleTabClick = (tabId: string) => () => {
    resetSelectionState();
    setActiveId(tabId);
  };

  const handleTabSelect = React.useCallback((selectedTabId: string) => {
    setTabOrder((previous) => [
      selectedTabId,
      ...previous.filter((orderedTabId) => orderedTabId !== selectedTabId),
    ]);
  }, []);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.entityById?.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error fetching this entity',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <div>
      <Box
        height={56}
        bgcolor="common.black"
        width="100%"
        display="flex"
        alignItems="flex-end"
      >
        <Box
          pl={5}
          pr={20}
          display="flex"
          overflow="hidden"
          position="relative"
        >
          <TabOverflowProvider>
            <Grid
              component={Box}
              container
              spacing={1}
              wrap="nowrap"
              id="entity-link-tabs"
              overflow="hidden"
            >
              <Grid item>
                <TabOverflow tabId="summary" threshold={1}>
                  <SNTab
                    active={activeId}
                    onClick={handleTabClick('summary')}
                    value="summary"
                    snVariant="background"
                  >
                    Link Summary
                  </SNTab>
                </TabOverflow>
              </Grid>
              {tabOrder.map((linkSummaryId) => (
                <Grid key={linkSummaryId} item>
                  <TabOverflow
                    tabId={linkSummaryId}
                    threshold={1}
                    root={document.querySelector('#entity-link-tabs')}
                  >
                    <SNTab
                      active={activeId}
                      onClick={handleTabClick(linkSummaryId)}
                      value={linkSummaryId}
                      snVariant="background"
                    >
                      {capitalize(tabMap[linkSummaryId]?.label)}
                    </SNTab>
                  </TabOverflow>
                </Grid>
              ))}
            </Grid>
            <Box position="absolute" right={40}>
              <TabOverflowDropdown
                tabOptions={
                  data?.entityById?.data?.linkSummaries.map(
                    ({ id, label }) => ({ id, name: label }),
                  ) || []
                }
                onTabSelect={handleTabSelect}
              />
            </Box>
          </TabOverflowProvider>
        </Box>
      </Box>
      {activeId === 'summary' && (
        <EntityLinkSummaryTable id={id} onLinksClick={handleTabClick} />
      )}
      {activeId !== 'summary' && <EntityLinksTable id={activeId} />}
    </div>
  );
};

export default EntityLinkSummaryTabs;
