import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { DataPoints } from '../types/schemaTypes';

export const DATAPOINTS = gql`
  query DataPoints {
    dataPoints {
      id
      name
      description
      dataType
      UIType @client
    }
  }
`;

export const useDataPointsQuery = (options?: QueryHookOptions<DataPoints>) => {
  return useQuery<DataPoints>(DATAPOINTS, options);
};
