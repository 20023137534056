import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { SearchEntity } from '../fragments';
import { EditEntities, EditEntitiesVariables } from '../types/schemaTypes';

export const EDIT_ENTITIES = gql`
  mutation EditEntities($input: EditEntitiesInput!) {
    editEntities(input: $input) {
      data {
        ...SearchEntity
      }
      validations {
        id
        displayStatus @client
        generalAnnotations {
          source
          severity
          message
          dataPath
        }
        fieldValidations {
          fieldDefinitionId
          displayStatus @client
          name @client
          annotations {
            source
            severity
            message
            dataPath
          }
        }
      }
    }
  }
  ${SearchEntity}
`;

export const useEditEntitiesMutation = (
  options?: MutationHookOptions<EditEntities, EditEntitiesVariables>,
) => {
  return useMutation<EditEntities, EditEntitiesVariables>(
    EDIT_ENTITIES,
    options,
  );
};
