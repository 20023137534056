import React from 'react';
import {
  searchFormActions,
  useSearchFormDispatch,
  useSearchFormState,
  useSearchTreeNavState,
} from '../../contexts';
import { Connective, SearchTreeTarget } from '../../types/schemaTypes';
import SearchFormTargetSelect from './SearchFormTargetSelect';
import { ROOT_FILTER_GROUP_ID, NEW_FILTER_LEAF_ID } from '../../constants';

const SearchFormTargetSelectConnected: React.FC = () => {
  const { parentId } = useSearchTreeNavState();
  const searchFormDispatch = useSearchFormDispatch();
  const branch = useSearchFormState();
  const isRoot = !parentId;

  const updateTarget = React.useCallback(
    (target: SearchTreeTarget) => {
      searchFormDispatch(
        searchFormActions.update({
          value: {
            target,
            filter: {
              rootFilterId: branch.filter.rootFilterId,
              filterBranches: {
                [branch.filter.rootFilterId]: {
                  parent: '',
                  children: [NEW_FILTER_LEAF_ID],
                  connective: Connective.AND,
                },
              },
              filterLeaves: {
                [NEW_FILTER_LEAF_ID]: {
                  parent: ROOT_FILTER_GROUP_ID,
                  type: 'STRING',
                  value: [
                    {
                      value: '',
                      type: 'STRING',
                    },
                  ],
                  connective: Connective.AND,
                  comparator: 'INCLUDES',
                  metadata: {
                    caseSensitive: false,
                  },
                  fieldId: 'system.displayName',
                },
              },
            },
          },
        }),
      );
    },
    [branch, searchFormDispatch],
  );

  return <SearchFormTargetSelect isRoot={isRoot} updateTarget={updateTarget} />;
};

export default SearchFormTargetSelectConnected;
