import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { SearchEntity } from '../fragments';
import {
  UnclassifiedGroupMembers,
  UnclassifiedGroupMembersVariables,
} from '../types/schemaTypes';

export const UNCLASSIFIED_GROUP_MEMBERS = gql`
  query UnclassifiedGroupMembers(
    $groupId: String!
    $searchQuery: SearchQuery
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    unclassifiedGroupMembers(
      groupId: $groupId
      searchQuery: $searchQuery
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data {
        totalCount
        pageInfo {
          endCursor
          startCursor
          endIndex @client
          startIndex @client
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            entity {
              ...SearchEntity
            }
          }
        }
      }
      errors
    }
  }
  ${SearchEntity}
`;

export const useUnclassifiedGroupMembersQuery = (
  options?: QueryHookOptions<
    UnclassifiedGroupMembers,
    UnclassifiedGroupMembersVariables
  >,
) => {
  return useQuery<UnclassifiedGroupMembers, UnclassifiedGroupMembersVariables>(
    UNCLASSIFIED_GROUP_MEMBERS,
    {
      ...options,
      notifyOnNetworkStatusChange: true,
    },
  );
};
