import { Draft } from 'immer';
import { useSearchTreeDispatch, searchTreeActions } from '../contexts';
import { SearchTreeBranch, AtLeastOne } from '../types';

function useBranchUpdate(searchId: string) {
  const dispatch = useSearchTreeDispatch();
  return (id: string, changes: Draft<AtLeastOne<SearchTreeBranch>>) => {
    dispatch(
      searchTreeActions.branch.update({
        key: searchId,
        id,
        value: changes,
      }),
    );
  };
}

export default useBranchUpdate;
