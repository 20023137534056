import { Draft } from 'immer';
import { searchFormActions, useSearchFormDispatch } from '../contexts';
import { FilterBranch, AtLeastOne } from '../types';

interface UseFilterBranchUpdateOptions {
  id: string;
  changes: Draft<AtLeastOne<FilterBranch>>;
}

function useFilterBranchUpdate() {
  const dispatch = useSearchFormDispatch();
  return ({ id, changes }: UseFilterBranchUpdateOptions) => {
    dispatch(
      searchFormActions.filter.branch.update({
        id,
        value: changes,
      }),
    );
  };
}

export default useFilterBranchUpdate;
