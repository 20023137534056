import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import styled from '../../services/styled';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useSetRecoilState } from 'recoil';
import { UploadToReviewIdAtom } from '../UploadsTable/UploadToReviewIdAtom';
import { UploadState } from '../../types/schemaTypes';

interface UploadQualityReviewButtonProps {
  status: UploadState;
  count: number;
  id: string;
}
interface QualityReviewButtonProps {
  status: UploadState;
}
const QualityReviewButton = styled(Button)<QualityReviewButtonProps>`
  background: ${(p) => p.theme.palette.background.default};
  height: 30px;
  padding: 0 10px 0 10px;
  :hover {
    background: ${(p) => p.theme.palette.grey[600]};
    border: 1px solid ${(p) => p.theme.palette.grey[600]};
  }
  :active {
    background: #000000;
    border: 1px solid #000000;
  }
  border: 1px solid
    ${(p) =>
      p.status === UploadState.NEEDS_REVIEW
        ? p.theme.palette.error.main
        : p.theme.palette.warning.main};
  border-radius: 2px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(p) => p.theme.palette.grey[600]};
`;

const UploadQualityReviewButton = ({
  status,
  count,
  id,
}: UploadQualityReviewButtonProps) => {
  const setUploadToReviewId = useSetRecoilState(UploadToReviewIdAtom);

  const handleQualitySummaryButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setUploadToReviewId({ open: true, id: id });
  };

  if (status === UploadState.PROCESSING) {
    return (
      <Box>
        <StyledCircularProgress
          variant="indeterminate"
          size={20}
          thickness={6}
        />
      </Box>
    );
  } else if (count === 0) {
    return (
      <Box display="flex" alignItems="center" height={30} px="10px">
        <Typography variant="body1">No errors</Typography>
      </Box>
    );
  } else {
    return (
      <QualityReviewButton
        status={status}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          handleQualitySummaryButtonClick(e, id)
        }
      >
        <Box display="flex" alignItems="center">
          <ReportProblemIcon />
          <Box>&nbsp;{count} issues</Box>
        </Box>
      </QualityReviewButton>
    );
  }
};

export default UploadQualityReviewButton;
