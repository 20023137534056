import { Box, Dialog, Grid } from '@mui/material';
import React from 'react';
import SelectableListToolbar from '../SelectableListToolbar';
import SNButton from '../SNButton';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { pageIdsAtom, selectedIdsAtom } from '../../atoms/listSelection';

import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import { useGetProjectsOfGroupQuery } from '../../queries/useProjectsOfGroupsQuery';
import SNTable from '../SNTable';
import GroupProjectsTableRow from '../GroupProjectsTableRow';
import SNPagination from '../SNPagination';
import AddProjectsToGroupForm from '../AddProjectsToGroupForm';
import ToolbarActionGroup from '../ToolbarActionGroup';
import ListSearchControl from '../ListSearchControl';
import { Delete } from '@mui/icons-material';
import { useRevokeGroupProjectAccess } from '../../mutations/useRevokeGroupProjectAccess';
import { useToggle } from '../../hooks/useToggle';
import { UserRole } from '../../types/schemaTypes';
import RoleMask from '../RoleMask';
import ListSelectionControl from '../ListSelectionControl';
import { collectErrors } from '../../utils/collectErrors';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { useDebounce } from '../../hooks';

const headers = ['name', 'description', 'date added'];
const pageSize = 10;

const UserGroupsDetailProjectsTable = () => {
  const { id } = useParams<{ id: string }>();
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const [
    addProjectsModalOpen,
    setAddProjectsModalOpen,
    setAddProjectsModalClosed,
  ] = useToggle(false);
  const [selectedIds, setSelectedIds] = useRecoilState(selectedIdsAtom);
  const [revokeGroupAccess, { data: revokeData }] = useRevokeGroupProjectAccess(
    {
      refetchQueries: ['ProjectsOfGroup'],
      onCompleted: () => {
        setSelectedIds([]);
      },
    },
  );
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { data, loading, error, fetchMore, refetch } =
    useGetProjectsOfGroupQuery({
      variables: {
        id: id,
        first: pageSize,
        filter: { searchTerm: debouncedSearchTerm },
      },
      onCompleted: (result) => {
        const pageIds = result?.group?.group?.groupProjectAccess?.edges.map(
          (edge) => edge.node.id,
        );
        setPageIds(pageIds || []);
      },
    });
  React.useEffect(() => {
    refetch({
      id: id,
      first: pageSize,
      filter: { searchTerm: debouncedSearchTerm },
    });
  }, [refetch, id, debouncedSearchTerm]);

  const handleRemoveGroupAccess = () => {
    revokeGroupAccess({
      variables: {
        input: {
          id: selectedIds[0],
        },
      },
    });
  };

  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);

  const pageTotal = data?.group?.group?.groupProjectAccess?.edges.length || 0;

  const groupProjectAccess = React.useMemo(
    () =>
      data?.group?.group?.groupProjectAccess?.edges.map((edge) => edge.node),
    [data],
  );

  const collectedErrors = React.useMemo(() => {
    return collectErrors([
      data?.group?.errors,
      revokeData?.revokeGroupProjectAccess?.errors,
    ]);
  }, [data, revokeData]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error fetching or deleting projects',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <Box>
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            {...data?.group?.group?.groupProjectAccess?.pageInfo}
            fetchMore={fetchMore}
            loading={loading}
            pageSize={pageSize}
            pageTotal={pageTotal}
            totalCount={data?.group?.group?.groupProjectAccess?.totalCount}
          />
        </Box>
        <Box>
          <Grid container spacing={1}>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 0}
            >
              <Grid item>
                <Dialog
                  fullWidth
                  open={addProjectsModalOpen}
                  onClose={setAddProjectsModalClosed}
                >
                  <AddProjectsToGroupForm onClose={setAddProjectsModalClosed} />
                </Dialog>
                <RoleMask allowedRoles={[UserRole.ADMINISTRATOR]}>
                  <SNButton
                    startIcon={<AddIcon />}
                    onClick={setAddProjectsModalOpen}
                    snVariant="text"
                  >
                    Add Projects
                  </SNButton>
                </RoleMask>
              </Grid>
              <Grid item>
                <ListSearchControl
                  setValue={setSearchTerm}
                  value={searchTerm}
                />
              </Grid>
            </ToolbarActionGroup>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 1}
            >
              <Grid item>
                <RoleMask allowedRoles={[UserRole.ADMINISTRATOR]}>
                  <SNButton
                    onClick={handleRemoveGroupAccess}
                    snVariant="text"
                    startIcon={<Delete />}
                  >
                    Remove Project Access
                  </SNButton>
                </RoleMask>
              </Grid>
            </ToolbarActionGroup>
          </Grid>
        </Box>
      </SelectableListToolbar>
      <SNTable
        error={error}
        hasResults={!!data?.group?.group?.groupProjectAccess?.edges}
        headers={headers}
        id="group-projects"
        loading={loading}
        rowCount={pageTotal}
        selectable
      >
        {groupProjectAccess?.map(
          ({ id, project, ...groupProjectAccess }, index) => (
            <GroupProjectsTableRow
              key={id}
              index={index}
              {...groupProjectAccess}
              {...project}
            />
          ),
        )}
      </SNTable>
    </Box>
  );
};

export default UserGroupsDetailProjectsTable;
