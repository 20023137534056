import React from 'react';
import { FilterLeaf, FilterType, FilterValueType } from '../../types';
import { Box, IconButton } from '@mui/material';
import {
  useBranchFields,
  useFilterDelete,
  useFilterLeafUpdate,
} from '../../hooks';
import SearchFormFilterLeafInputText from '../SearchFormFilterLeafInputText';
import Autocomplete from '../Autocomplete';
import SearchFormFilterLeafComparator from '../SearchFormFilterLeafComparator';
import SearchFormFilterLeafInputDate from '../SearchFormFilterLeafInputDate';
import moment from 'moment';
import { createDefaultFilterLeaf } from '../../contexts';
import SearchFormFilterLeafInputBoolean from '../SearchFormFilterLeafInputBoolean';
import { Clear } from '@mui/icons-material';
import SNInput from '../SNInput';

interface SearchFormFilterLeafProps extends FilterLeaf {
  id: string;
}

interface SearchFormFilterLeafInputProps {
  disabled: boolean;
  id: string;
  type: FilterType;
  value: string;
}

const SearchFormFilterLeafInput: React.FC<SearchFormFilterLeafInputProps> = ({
  disabled,
  id,
  type,
  value,
}) => {
  switch (type) {
    case 'DATE':
      return (
        <SearchFormFilterLeafInputDate
          id={id}
          disabled={disabled}
          value={moment(value).isValid() ? moment(value) : null}
        />
      );
    case 'NUMBER':
    case 'STRING':
      return (
        <SearchFormFilterLeafInputText
          id={id}
          disabled={disabled}
          value={value}
          type={type}
        />
      );
    case 'BOOLEAN':
      return (
        <SearchFormFilterLeafInputBoolean
          id={id}
          disabled={disabled}
          value={value}
        />
      );
    default:
      return (
        <SearchFormFilterLeafInputText
          id={id}
          disabled={disabled}
          value={value}
          type={type}
        />
      );
  }
};

const SearchFormFilterLeaf = (props: SearchFormFilterLeafProps) => {
  const { comparator, fieldId, id, type, value } = props;
  const fieldOptions = useBranchFields();
  const updateFilterLeaf = useFilterLeafUpdate();
  const deleteFilter = useFilterDelete();

  const handleNameChange = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    _event: React.ChangeEvent<{}>,
    newValue: { id: string; name: string; type: FilterValueType } | null,
  ) => {
    if (newValue) {
      if (newValue.type === type) {
        updateFilterLeaf(id, {
          fieldId: newValue.id,
          type: newValue.type,
        });
      } else {
        updateFilterLeaf(
          id,
          createDefaultFilterLeaf({
            type: newValue.type,
            fieldId: newValue.id,
          }),
        );
      }
    }
  };

  const handleDeleteFilter = () => {
    deleteFilter(id);
  };

  const options = fieldOptions.slice().sort((a, b) => {
    const textA = a.type.toUpperCase();
    const textB = b.type.toUpperCase();
    return textA > textB ? -1 : textA < textB ? 1 : 0;
  });

  const selectedOption = React.useMemo(
    () => fieldOptions.find((fieldOption) => fieldOption.id === fieldId),
    [fieldOptions, fieldId],
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Box flexGrow={1}>
        {selectedOption ? (
          <Autocomplete
            size="small"
            options={options}
            onChange={handleNameChange}
            freeSolo={false}
            multiple={false}
            disableClearable
            filterOptions={(options, state) => {
              return options.filter((option) => {
                return state
                  .getOptionLabel(option)
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase());
              });
            }}
            getOptionLabel={(option) => option.name}
            groupBy={(option) => option.type}
            value={selectedOption}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
            renderInput={({ InputProps, InputLabelProps, ...params }) => (
              <SNInput {...InputProps} {...params} className="" />
            )}
          />
        ) : (
          <SNInput fullWidth readOnly size="small" />
        )}
      </Box>
      <Box display="flex">
        <Box mx={2}>
          <SearchFormFilterLeafComparator
            comparator={comparator}
            id={id}
            type={type}
          />
        </Box>
      </Box>
      <Box flexGrow={1}>
        <SearchFormFilterLeafInput
          disabled={comparator === 'EMPTY' || comparator === 'PRESENT'}
          id={id}
          type={type}
          value={value[0] ? value[0].value : ''}
        />
      </Box>
      <Box px={1}>
        <IconButton size="small" onClick={handleDeleteFilter}>
          <Clear fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SearchFormFilterLeaf;
