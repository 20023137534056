import { Box, ListItemText, MenuItem, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import Dropdown from '../Dropdown';
import Checkbox from '../Checkbox';
import { SortDirection } from '../../types/schemaTypes';

interface Option {
  primary: string;
  secondary?: string;
  value: SortDirection;
}

const options: Option[] = [
  {
    primary: 'Recent First',
    secondary: '',
    value: SortDirection.DESC,
  },
  {
    primary: 'Oldest First',
    secondary: '',
    value: SortDirection.ASC,
  },
];

interface EventHistoryDropdownProps {
  historyDirection: SortDirection;
  setHistoryDirection: Dispatch<SetStateAction<SortDirection>>;
}

const EntityHistoryDirectionDropdown = ({
  historyDirection,
  setHistoryDirection,
}: EventHistoryDropdownProps) => {
  const handleToggle = (value: Option['value']) => () => {
    setHistoryDirection(
      value === SortDirection.ASC ? SortDirection.ASC : SortDirection.DESC,
    );
  };

  return (
    <Box pl={4}>
      <Dropdown
        label={
          <Typography variant="h5">
            {historyDirection === SortDirection.ASC
              ? 'Viewing oldest events first'
              : 'Viewing recent events first'}
          </Typography>
        }
        snVariant="underline"
      >
        {options.map(({ primary, secondary, value }) => (
          <MenuItem key={value} disableRipple onClick={handleToggle(value)}>
            <Box pr={2}>
              <Checkbox
                tabIndex={-1}
                disableRipple
                checked={historyDirection === value}
              />
            </Box>
            <Box pr={2}>
              <ListItemText primary={primary} secondary={secondary} />
            </Box>
          </MenuItem>
        ))}
      </Dropdown>
    </Box>
  );
};

export default EntityHistoryDirectionDropdown;
