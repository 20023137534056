import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import LinkingModalTargetEntity from '../LinkingModalTargetEntity/LinkingModalTargetEntity';

interface UploadModalContextTargetsProps {
  allowedEntityTypeIds: string[];
  label: string;
  onTargetDelete: (index: number) => () => void;
  onTargetSelect: (index: number) => (targetId: string) => void;
  targetEntityIds?: string[] | null;
}

const UploadModalContextTargets = ({
  allowedEntityTypeIds,
  label,
  onTargetDelete,
  onTargetSelect,
  targetEntityIds,
}: UploadModalContextTargetsProps) => {
  return (
    <div>
      {targetEntityIds?.map((targetEntityId, index) => (
        <Box key={targetEntityId} display="flex" pb={1}>
          <Box flexGrow={1}>
            <LinkingModalTargetEntity
              allowedEntityTypeIds={allowedEntityTypeIds}
              index={index}
              label={label}
              onTargetSelect={onTargetSelect(index)}
              targetEntityId={targetEntityId}
            />
          </Box>
          <Box pl={1} pt={4.25}>
            <IconButton onClick={onTargetDelete(index)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      ))}
      {targetEntityIds && (
        <Box display="flex" pr={5.5}>
          <Box flexGrow={1}>
            <LinkingModalTargetEntity
              allowedEntityTypeIds={allowedEntityTypeIds}
              index={targetEntityIds.length}
              label={label}
              onTargetSelect={onTargetSelect(targetEntityIds.length)}
              targetEntityId={null}
              byPassable={false}
            />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default UploadModalContextTargets;
