import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { ProcessingState } from '../../types/schemaTypes';
import Theme from '../../services/theme';

interface EntityDetailStatusBadgeProps {
  badgeColor?: string;
  entityStatus?: ProcessingState;
}

const EntityDetailStatusBadge = ({
  badgeColor,
  entityStatus = ProcessingState.PROCESSING,
}: EntityDetailStatusBadgeProps) => {
  const getStateColor = (badgeLabel: ProcessingState) => {
    switch (badgeLabel) {
      case ProcessingState.PROCESSING:
        return Theme.palette.primary.main;
      case ProcessingState.COMPLETED:
        return Theme.palette.success.main;
      case ProcessingState.QUALITY_ISSUES:
        return Theme.palette.warning.main;
      case ProcessingState.UNCLASSIFIED:
        return Theme.palette.error.main;
      case ProcessingState.UNSTRUCTURED:
        return Theme.palette.error.main;
      default:
        return;
    }
  };

  const getStateTooltip = (entityStatus: ProcessingState) => {
    switch (entityStatus) {
      case ProcessingState.PROCESSING:
        return 'Processing entities are currently undergoing some lengthy process (upload, export, classification, transformation, etc...)';
      case ProcessingState.COMPLETED:
        return 'Completed entities are acceptable according to their entity type definition and are available in search.';
      case ProcessingState.QUALITY_ISSUES:
        return "Entities with a 'Quality Issues' state have some errors or warnings associated with their data based on their entity type definition.";
      case ProcessingState.UNCLASSIFIED:
        return 'Unclassified entities have not been assigned an entity type by the Snthesis Bio engine and require human intervention to classify.';
      case ProcessingState.UNSTRUCTURED:
        return "Entities in the 'Unstructured' state were not processable by the Snthesis Bio engine and need human review.";
      default:
        return 'This is the status of the entity';
    }
  };

  const displayTooltipText = React.useMemo(
    () => getStateTooltip(entityStatus),
    [entityStatus],
  );
  const displayBadgeColor = React.useMemo(() => {
    return badgeColor ? badgeColor : getStateColor(entityStatus);
  }, [badgeColor, entityStatus]);

  return (
    <Tooltip title={displayTooltipText} placement="left">
      <Box
        display="flex"
        py="2px"
        px="4px"
        ml={1}
        alignItems="center"
        bgcolor={Theme.palette.grey[800]}
        borderRadius={3}
      >
        <Box
          zIndex={0}
          bgcolor={displayBadgeColor}
          borderRadius={5}
          height={10}
          width={10}
          mr={0.5}
        />
        <Typography color="textSecondary">{entityStatus.toString()}</Typography>
      </Box>
    </Tooltip>
  );
};

export default EntityDetailStatusBadge;
