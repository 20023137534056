import { Box, Grid } from '@mui/material';
import React from 'react';
import { SavedSearchType, SortOrder } from '../../types/schemaTypes';
import { useSaveSearchDispatch, useUserState } from '../../contexts';
import { useDeleteSavedSearchesMutation } from '../../mutations/useDeleteSavedSearchMutation';
import {
  SEARCH_SAVED_SEARCHES,
  useSearchSavedSearchesQuery,
} from '../../queries';
import SelectableListToolbar from '../SelectableListToolbar';
import SNButton from '../SNButton';
import { useDebounce } from '../../hooks';
import { createCacheInvalidator } from '../../utils/invalidateCache';
import { Add, FileCopyOutlined } from '@mui/icons-material';
import ListSearchControl from '../ListSearchControl';
import { useSetRecoilState } from 'recoil';
import {
  pageIdsAtom,
  selectAllResultsSelector,
} from '../../atoms/listSelection';
import SNPagination from '../SNPagination';
import SNTable from '../SNTable';
import SavedSearchesTableRow from '../SavedSearchesTableRow';
import ToolbarActionGroup from '../ToolbarActionGroup';
import RecencyDirectionSelector from '../RecencyDirectionSelector';
import SearchSaveDuplicateConnected from '../SearchSave/SearchSaveDuplicateConnected';
import SavedSearchesDelete from '../SavedSearchesDelete';
import ListSelectionControl from '../ListSelectionControl';
import { collectErrors } from '../../utils/collectErrors';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';

interface SavedSearchesTableProps {
  isPublic?: boolean;
  onNewClick?: () => void;
  onSearchSelect: (id: string) => void;
  savedSearchType: SavedSearchType;
}

const headers = ['name', 'visibility', 'created on', 'last modified'];
const publicHeaders = ['name', 'created by', 'created on', 'last modified'];

const SavedSearchesTable: React.FC<SavedSearchesTableProps> = ({
  isPublic,
  onNewClick,
  onSearchSelect,
  savedSearchType,
}) => {
  const { userId } = useUserState();
  const pageSize = 10;

  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const setSaveSearchState = useSaveSearchDispatch();
  const [sortDirection, setSortDirection] = React.useState(SortOrder.desc);
  const setSelectAllRows = useSetRecoilState(selectAllResultsSelector);
  const setPageIds = useSetRecoilState(pageIdsAtom);

  const { data, error, fetchMore, loading, refetch } =
    useSearchSavedSearchesQuery({
      variables: {
        first: pageSize,
        order: sortDirection,
        filter: {
          authorId: isPublic ? undefined : userId,
          userId: isPublic ? undefined : userId,
          searchTerm: debouncedSearchTerm,
          types: [savedSearchType],
        },
      },
    });

  React.useEffect(() => {
    setPageIds(
      data?.searchSavedSearches?.data?.edges?.map((edge) => edge.node.id) || [],
    );
  }, [data, setPageIds]);

  const [deleteSavedSearches, { loading: reFetching, data: deleteData }] =
    useDeleteSavedSearchesMutation({
      onCompleted: () => {
        setSelectAllRows(false);
      },
      refetchQueries: [SEARCH_SAVED_SEARCHES],
      update: createCacheInvalidator(/^SavedSearch/),
    });

  const handleDelete = React.useCallback(
    (ids: string[]) => {
      deleteSavedSearches({
        variables: { input: { ids } },
      });
    },
    [deleteSavedSearches],
  );

  const handleDuplicateModalOpen = () => {
    setSaveSearchState('COPY');
  };

  const pageTotal = data?.searchSavedSearches?.data?.edges.length || 0;

  const collectedErrors = React.useMemo(() => {
    return collectErrors([
      deleteData?.deleteSavedSearches?.errors,
      data?.searchSavedSearches?.errors,
    ]);
  }, [deleteData, data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error fetching saved searches',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);
  return (
    <Box data-testid="my_saved_searches">
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            {...data?.searchSavedSearches?.data?.pageInfo}
            fetchMore={fetchMore}
            loading={loading}
            pageSize={pageSize}
            pageTotal={pageTotal}
            totalCount={data?.searchSavedSearches?.data?.totalCount}
          />
        </Box>
        <Box>
          <Grid container spacing={1}>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 1}
            >
              <Grid item>
                <SNButton
                  data-testid="duplicate_saved_search_button"
                  snVariant="text"
                  onClick={handleDuplicateModalOpen}
                  startIcon={<FileCopyOutlined />}
                >
                  Duplicate
                </SNButton>
              </Grid>
            </ToolbarActionGroup>
            {!isPublic && (
              <ToolbarActionGroup
                comparisonFunction={(count: number) => count > 0}
              >
                <Grid item>
                  <SavedSearchesDelete onDelete={handleDelete} />
                </Grid>
              </ToolbarActionGroup>
            )}
            {onNewClick && (
              <Grid item>
                <SNButton
                  snVariant="text"
                  startIcon={<Add />}
                  onClick={onNewClick}
                >
                  Create Review
                </SNButton>
              </Grid>
            )}
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 0}
            >
              <Grid item>
                <RecencyDirectionSelector
                  direction={sortDirection}
                  setDirection={setSortDirection}
                  label="data views"
                />
              </Grid>
              <Grid item>
                <ListSearchControl
                  value={searchTerm}
                  setValue={setSearchTerm}
                />
              </Grid>
            </ToolbarActionGroup>
          </Grid>
        </Box>
      </SelectableListToolbar>
      <SNTable
        error={error}
        hasResults={!!data?.searchSavedSearches?.data?.edges}
        headers={isPublic ? publicHeaders : headers}
        id="my-saved-searches"
        loading={loading || reFetching}
        rowCount={pageTotal}
        selectable
      >
        {data?.searchSavedSearches?.data?.edges.map(
          ({ node: { id, user, ...rest } }, index) => (
            <SavedSearchesTableRow
              key={id}
              id={id}
              isPublic={isPublic}
              {...rest}
              userName={user?.name}
              userPicture={user?.picture}
              onSelect={onSearchSelect}
              index={index}
            />
          ),
        )}
      </SNTable>
      <SearchSaveDuplicateConnected
        onCopyComplete={refetch}
        savedSearchType={savedSearchType}
      />
    </Box>
  );
};

export default SavedSearchesTable;
