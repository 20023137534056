import gql from 'graphql-tag';

export const SearchEntity = gql`
  fragment SearchEntity on Entity {
    id
    createdBy {
      id
      name
      picture
    }
    createdOn
    displayName
    displayStatus
    lastModified
    linkSummaries {
      id
      label
      value
      direction
      linkCount
      displayStatus
      linkDefinition {
        id
      }
      annotations {
        message
        severity
        source
      }
    }
    userMetadata {
      label
      value
    }
    metadata {
      sourceId
      sourceType
      previewLink
      status {
        state
      }
      contributors {
        id
        name
        picture
      }
    }
    fieldValues {
      displayStatus
      value
      annotations {
        message
        severity
        source
      }
      fieldDefinition {
        id
        dataPoint {
          dataType
        }
      }
    }
    type {
      id
      name
    }
    annotations {
      source
      severity
      message
    }
  }
`;
