import { atom, DefaultValue, selector, selectorFamily } from 'recoil';
import {
  UploadLinkCreation,
  UploadLinkingContext,
  UploadMetadataInput,
} from '../types/schemaTypes';

export const uploadFilesByIdAtom = atom<Record<string, File>>({
  key: 'upload-files-by-id',
  default: {},
});

export const uploadFilesCountSelector = selector<number>({
  key: 'upload-files-count',
  get: ({ get }) => Object.keys(get(uploadFilesByIdAtom)).length,
});

export const uploadFileSelector = selectorFamily<File, string>({
  key: 'upload-file',
  get:
    (fileId: string) =>
    ({ get }) =>
      get(uploadFilesByIdAtom)[fileId] || {},
});

export const uploadSubsetsByIdAtom = atom<
  Record<string, Record<string, boolean>>
>({
  key: 'upload-subsets-by-id',
  default: {},
});

export const uploadSubsetsCountSelector = selector<number>({
  key: 'upload-subsets-count',
  get: ({ get }) => Object.keys(get(uploadSubsetsByIdAtom)).length,
});

export const uploadMetadataByIdAtom = atom<
  Record<string, Record<string, UploadMetadataInput>>
>({
  key: 'upload-file-metadata',
  default: {},
});

export const uploadLinkingContextsAtom = atom<UploadLinkingContext[]>({
  key: 'upload-linking-contexts',
  default: [{ existingObjectIds: [], selectedUploadIds: [] }],
});

export const uploadLinksAtom = atom<UploadLinkCreation[]>({
  key: 'upload-links',
  default: [
    {
      linkTargetIds: [],
      linkTypeId: '',
      selectedUploadIds: [],
    },
  ],
});

export const hasSubsetsSelector = selector<boolean>({
  key: 'has-subsets',
  get: ({ get }) => Object.keys(get(uploadSubsetsByIdAtom)).length > 0,
});

export const uploadSubsetsSelector = selectorFamily<
  Record<string, boolean> | undefined,
  string
>({
  key: 'upload-subset-selector',
  get:
    (fileId: string) =>
    ({ get }) =>
      get(uploadSubsetsByIdAtom)[fileId],
  set:
    (fileId: string) =>
    ({ reset, set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        reset(uploadSubsetsByIdAtom);
      } else {
        set(uploadSubsetsByIdAtom, (prevState) => ({
          ...prevState,
          [fileId]: newValue || {},
        }));
      }
    },
});

export const uploadMetadataSelector = selectorFamily<
  Record<string, UploadMetadataInput>,
  string
>({
  key: 'upload-metadata-selector',
  get:
    (fileId: string) =>
    ({ get }) =>
      get(uploadMetadataByIdAtom)[fileId] || {},
  set:
    (fileId: string) =>
    ({ reset, set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        reset(uploadMetadataByIdAtom);
      } else {
        set(uploadMetadataByIdAtom, (prevState) => ({
          ...prevState,
          [fileId]: newValue,
        }));
      }
    },
});
export const uploadProjectsAtom = atom<
  Record<
    string,
    { projectName: string; projectId: string; filesInProject: string[] }
  >
>({
  key: 'uploadProjectsAtom',
  default: {},
});
