import { Theme } from '@glideapps/glide-data-grid';
import { alpha } from '@mui/material';
import theme from './theme';

export const dataGridTheme: Partial<Theme> = {
  accentColor: theme.palette.primary.main,
  accentLight: alpha(theme.palette.primary.main, 0.2),
  bgCell: theme.palette.background.default,
  bgHeader: theme.palette.background.default,
  bgHeaderHasFocus: theme.palette.background.default,
  bgHeaderHovered: theme.palette.background.default,
  bgIconHeader: theme.palette.background.default,
  fgIconHeader: theme.palette.grey[200],
  cellHorizontalPadding: 16,
  borderColor: theme.palette.grey[600],
  headerFontStyle: '500 0.78125rem',
  textDark: theme.palette.text.primary,
  textMedium: theme.palette.text.secondary,
  textHeader: theme.palette.grey[200],
} as Record<string, unknown>;
