import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { ScrollbarsX } from '../../utils/ScrollbarStyles';
import SearchTreeBranch from '../SearchTreeBranch';
import styled from '../../services/styled';
import { useSearchTreeRoots, useSearchTreeNavDispatch } from '../../contexts';
import SearchTreeLeafDialog from '../SearchTreeLeafDialog';
import { useBranchDelete } from '../../hooks';
import AddIcon from '@mui/icons-material/Add';
import SearchTreeForm from '../SearchTreeForm';

const StyledSearchTreeArea = styled(Box)`
  background: repeating-linear-gradient(
    90deg,
    transparent,
    transparent 100px,
    #353435 1px,
    #353435 101px
  );
  white-space: nowrap;
`;
const StyledSearchTreeAreaContainer = styled(Box)`
  ${ScrollbarsX}
  overflow-x: auto;
`;

const StyledAddRoot = styled(Box)`
  > * {
    opacity: 0.2;
  }
  :hover {
    > * {
      opacity: 1;
    }
  }
`;

interface SearchTreeProps {
  searchId: string;
}

const SearchTree = ({ searchId }: SearchTreeProps) => {
  const setSearchTreeNavState = useSearchTreeNavDispatch();
  const rootIds = useSearchTreeRoots(searchId);
  const deleteSearchTreeBranch = useBranchDelete(searchId);
  const [deleteId, setDeleteId] = React.useState('');

  const handleAddRootClick = () => {
    setSearchTreeNavState({ showForm: true, activeId: null, parentId: null });
  };

  const handleClearDeleteId = () => {
    setDeleteId('');
  };

  const handleNodeDelete = () => {
    deleteSearchTreeBranch(deleteId);
    handleClearDeleteId();
  };

  return (
    <Box width="100%" position="relative" data-testid="search-tree">
      <StyledSearchTreeAreaContainer width="100%" pl={3} ml={-3}>
        <StyledSearchTreeArea
          pt={3}
          pb={5}
          display="flex"
          flexDirection="column"
        >
          {rootIds.map((rootId) => (
            <SearchTreeBranch
              key={rootId}
              id={rootId}
              isOnlyRoot={rootIds.length === 1}
              onDelete={setDeleteId}
              searchId={searchId}
            />
          ))}
        </StyledSearchTreeArea>
      </StyledSearchTreeAreaContainer>
      <StyledAddRoot position="absolute" bottom={-16}>
        <Tooltip title="Add Root Node" placement="right">
          <IconButton onClick={handleAddRootClick}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </StyledAddRoot>
      <SearchTreeLeafDialog
        data-testid="search-tree-leaf-dialog"
        id={deleteId}
        onClose={handleClearDeleteId}
        onDelete={handleNodeDelete}
      />
      <SearchTreeForm searchId={searchId} />
    </Box>
  );
};

export default SearchTree;
