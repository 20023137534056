import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import UploadWidgetBackgroundImage from './UploadWidgetBackground.svg';
import UploadFlyingFiles from './UploadFlyingFiles.svg';
import styled from '../../services/styled';
import SNButton from '../SNButton';

interface FileUploadProps extends DropzoneOptions {
  onFilesAccept: (files: File[]) => void;
}

const StyledRoot = styled('div')`
  width: 100%;
  outline: none;
  :focus {
    box-shadow: ${({ theme }) =>
      `inset 0 0 20px ${theme.palette.primary.main}`};
  }
`;

const StyleUploadBox = styled(Box)`
  background-image: url(${UploadWidgetBackgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const FileUpload: React.FC<FileUploadProps> = ({
  onFilesAccept,
  ...options
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    ...options,
  });

  React.useEffect(() => {
    onFilesAccept(acceptedFiles);
  }, [acceptedFiles, onFilesAccept]);

  return (
    <Box bgcolor="#151616" p="3px 3px 1px 3px" position="relative">
      <Box
        display="flex"
        alignItems="stretch"
        justifyContent="center"
        width="100%"
      >
        <StyledRoot {...getRootProps()}>
          <Box flexGrow={1}>
            <StyleUploadBox
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box position="absolute" top={0}>
                <img alt="Flying File Types" src={UploadFlyingFiles} />
              </Box>
              <Box mt={17} mb={3}>
                <input {...getInputProps()} />

                <Box
                  display="flex"
                  flexDirection="column"
                  alignContent="center"
                  justifyContent="center"
                >
                  <Typography variant="h3" color="textSecondary">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Box>Drag and Drop Files, or&nbsp;</Box>
                      <Typography
                        variant="h3"
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        Browse
                      </Typography>
                      <Box>&nbsp; to upload</Box>
                    </Box>
                    <Box mt={1} display="flex" justifyContent="center">
                      <Tooltip title="Coming Soon" placement="top">
                        <Box>
                          <SNButton snVariant="text" disabled>
                            Add via URL
                          </SNButton>
                        </Box>
                      </Tooltip>
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </StyleUploadBox>
          </Box>
        </StyledRoot>
      </Box>
    </Box>
  );
};

export default FileUpload;
