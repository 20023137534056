import React from 'react';
import {
  CustomCell,
  CustomRenderer,
  GridCellKind,
  ProvideEditorCallback,
} from '@glideapps/glide-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { CellInput, drawTextCell } from './CustomCells';
import { AnnotationSeverity } from '../../types/schemaTypes';

interface DateTimeCellProps {
  readonly kind: 'date-time-cell';
  readonly date: Date | undefined;
  readonly displayDate: string;
  readonly displayStatus: AnnotationSeverity | null;
}

type DateTimeCell = CustomCell<DateTimeCellProps>;
export type { DateTimeCell as DateTimeCellType };

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Editor: ReturnType<ProvideEditorCallback<DateTimeCell>> = (p) => {
  const cellData = p.value.data;
  const { date } = cellData;

  return (
    <Wrap>
      <DatePicker
        PopperProps={{
          className: 'click-outside-ignore',
          popperOptions: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 30],
                },
              },
            ],
          },
        }}
        mask="____-__-__"
        inputFormat="yyyy-MM-DD"
        onChange={(date) => {
          p.onChange({
            ...p.value,
            data: {
              ...p.value.data,
              date: date ?? undefined,
            },
          });
        }}
        value={date ?? null}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <Box minHeight={59} px={2} alignItems="center" display="flex">
            <CellInput ref={inputRef} autoFocus {...inputProps} />
            {InputProps?.endAdornment}
          </Box>
        )}
      />
    </Wrap>
  );
};

const renderer: CustomRenderer<DateTimeCell> = {
  isMatch: (cell: CustomCell): cell is DateTimeCell =>
    (cell.data as any).kind === 'date-time-cell',
  draw: (args, cell) => {
    const { data, readonly } = cell;
    const { displayDate: value, displayStatus: status } = data;
    return drawTextCell(args, { value, status, readonly });
  },
  // eslint-disable-next-line react/display-name
  provideEditor: () => ({
    editor: Editor,
    disablePadding: true,
  }),
  onPaste: (v, d) => {
    let newDate: Date | undefined;
    try {
      newDate = new Date(v);
    } catch {
      /* do nothing */
    }

    return {
      ...d,
      date: Number.isNaN(newDate) ? undefined : newDate,
    };
  },
  kind: GridCellKind.Custom,
};

export default renderer;
