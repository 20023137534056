import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import {
  GroupsOfProject,
  GroupsOfProjectVariables,
} from '../types/schemaTypes';

export const GROUPS_OF_PROJECT = gql`
  query GroupsOfProject(
    $id: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: GroupFilter
  ) {
    project(id: $id) {
      project {
        id
        name
        description
        groupProjectAccess(
          after: $after
          before: $before
          first: $first
          last: $last
          filter: $filter
        ) {
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
            endIndex @client
            startIndex @client
          }
          totalCount
          edges {
            cursor
            node {
              id
              createdAt
              lastModified
              group {
                id
                name
                description
                groupMembership(first: 5) {
                  totalCount
                  edges {
                    cursor
                    node {
                      user {
                        id
                        name
                        picture
                      }
                    }
                  }
                }
                groupProjectAccess(first: 0) {
                  totalCount
                }
              }
            }
          }
        }
      }
      errors
    }
  }
`;

export const useLazyGroupsOfProjectQuery = (
  options?: LazyQueryHookOptions<GroupsOfProject, GroupsOfProjectVariables>,
) => {
  return useLazyQuery<GroupsOfProject, GroupsOfProjectVariables>(
    GROUPS_OF_PROJECT,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );
};

export const useGroupsOfProjectQuery = (
  options?: QueryHookOptions<GroupsOfProject, GroupsOfProjectVariables>,
) => {
  return useQuery<GroupsOfProject, GroupsOfProjectVariables>(
    GROUPS_OF_PROJECT,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );
};
