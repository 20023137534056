import React from 'react';
import * as XLSX from 'xlsx';
import { Box, Dialog } from '@mui/material';
import { MemoryRouter } from 'react-router-dom';
import FileUpload from '../../components/FileUpload';
import UploadModal from '../../components/UploadModal';
import UploadsTable from '../../components/UploadsTable';
import { UploadTabsProvider, UploadWizardProvider } from '../../contexts';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  uploadFilesByIdAtom,
  uploadLinkingContextsAtom,
  uploadLinksAtom,
  uploadMetadataByIdAtom,
  uploadProjectsAtom,
  uploadSubsetsByIdAtom,
} from '../../atoms/uploadFiles';
import cuid from 'cuid';
import { ulid } from 'ulid';

const Uploads = () => {
  const [filesById, setFilesById] = useRecoilState(uploadFilesByIdAtom);
  const setSubsetById = useSetRecoilState(uploadSubsetsByIdAtom);
  const setMetadataById = useSetRecoilState(uploadMetadataByIdAtom);
  const resetLinks = useResetRecoilState(uploadLinksAtom);
  const resetLinkingContexts = useResetRecoilState(uploadLinkingContextsAtom);
  const resetProjects = useResetRecoilState(uploadProjectsAtom);

  const handleFilesAccept = React.useCallback(
    (newFiles: File[]) => {
      const fileIds = newFiles.map(() => ulid());
      setFilesById((prevState) => ({
        ...prevState,
        ...Object.fromEntries(
          newFiles.map((newFile, index) => [fileIds[index], newFile]),
        ),
      }));
      setMetadataById((prevState) => ({
        ...prevState,
        ...Object.fromEntries(
          newFiles.map((newFile, index) => [
            fileIds[index],
            {
              [cuid()]: { field: '', value: '' },
              description: { field: 'description', value: '' },
            },
          ]),
        ),
      }));
      newFiles.forEach((file, index) => {
        if (file.name.includes('.xl')) {
          const reader = new FileReader();
          reader.onabort = () => console.log('file reading was aborted');
          // tslint:disable-next-line
          reader.onerror = () => console.log('file reading has failed');
          reader.onload = () => {
            const binaryStr = reader.result as ArrayBuffer;
            const data = new Uint8Array(binaryStr);
            /*
              https://github.com/SheetJS/sheetjs#parsing-options
              */
            try {
              const workbook = XLSX.read(data, {
                type: 'array',
                bookSheets: true,
              });
              // Files with actual sheets will not have a .Sheets property when bookSheets is true
              // So we are checking that here as a proxy for the file type
              if (!workbook.Sheets) {
                setSubsetById((previous) => ({
                  ...previous,
                  [fileIds[index]]: Object.fromEntries(
                    workbook.SheetNames.map((sheetName) => [sheetName, true]),
                  ),
                }));
              }
            } catch (error) {
              console.warn(error);
            }
          };
          reader.readAsArrayBuffer(file);
        }
      });
    },
    [setFilesById, setMetadataById, setSubsetById],
  );

  const handleClose = () => {
    setFilesById({});
    setMetadataById({});
    setSubsetById({});
    resetLinks();
    resetLinkingContexts();
    resetProjects();
  };

  return (
    <Box>
      <Box>
        <FileUpload onFilesAccept={handleFilesAccept} />
      </Box>
      <UploadTabsProvider>
        <UploadsTable />
      </UploadTabsProvider>
      <UploadWizardProvider>
        <MemoryRouter initialEntries={['/files']} initialIndex={0}>
          <Dialog fullScreen open={Object.keys(filesById).length > 0}>
            <UploadModal
              onClose={handleClose}
              onFilesAccept={handleFilesAccept}
            />
          </Dialog>
        </MemoryRouter>
      </UploadWizardProvider>
    </Box>
  );
};

export default Uploads;
