import { Typography } from '@mui/material';
import React from 'react';
import styled from '../../services/styled';
import { getMuiVariant, SNVariants } from '../../services/theme';
import ShadowBox from '../ShadowBox';
import { ShadowBoxProps } from '../ShadowBox/ShadowBox';

interface StickyColumnHeadingProps extends ShadowBoxProps {
  snVariant?: SNVariants;
}

const StickyColumnHeading = styled(
  ({ children, snVariant = 'label', ...rest }: StickyColumnHeadingProps) => (
    <ShadowBox
      component="th"
      py={1}
      scope="col"
      bgcolor="background.default"
      {...rest}
    >
      {typeof children === 'string' ? (
        <Typography variant={getMuiVariant(snVariant)} color="textSecondary">
          {children}
        </Typography>
      ) : (
        children
      )}
    </ShadowBox>
  ),
)`
  position: sticky;
  top: 0;
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[700]};
`;

export default StickyColumnHeading;
