import { GridSelection } from '@glideapps/glide-data-grid';

//this function takes a grid selection object, and an array with x and y, representing the location of a cell
// then determines if the cell is in the selected range
export const isInRange = (
  location: [number, number],
  gridSelection?: GridSelection,
): boolean => {
  if (!gridSelection || !gridSelection.current?.range) {
    return false;
  }
  const range = gridSelection.current?.range;
  if (
    location[0] >= range.x &&
    location[0] < range.x + range.width &&
    location[1] >= range.y &&
    location[1] < range.y + range.height
  ) {
    return true;
  } else {
    return false;
  }
};
