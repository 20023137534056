import React from 'react';
import { Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import SNButton from '../SNButton';
import SNDialogTitle from '../SNDialogTitle';

interface ExportNavigationWarningModalProps {
  isOpen: boolean;
  setClosed: () => void;
  setSnackbarClose: () => void;
}

const ExportNavigationWarningModal = ({
  isOpen,
  setClosed,
  setSnackbarClose,
}: ExportNavigationWarningModalProps) => {
  const handleCloseAll = () => {
    setSnackbarClose();
    setClosed();
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <SNDialogTitle onClose={setClosed}>Export in progress</SNDialogTitle>
      <DialogContent>
        <Box>
          Closing the notification below will cause the export link to be lost.
          Be sure you&apos;ve downloaded your data before closing.
        </Box>
      </DialogContent>
      <DialogActions>
        <SNButton snVariant="text" onClick={handleCloseAll}>
          Discard the link
        </SNButton>
        <SNButton onClick={setClosed}>Keep the link</SNButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExportNavigationWarningModal;
