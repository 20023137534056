import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  ActiveUploadAddRemove,
  ActiveUploadAddRemoveVariables,
} from '../types/schemaTypes';

export const ACTIVE_UPLOAD_ADD_REMOVE = gql`
  mutation ActiveUploadAddRemove($activeUpload: ActiveUploadInput!) {
    activeUploadAddRemove(activeUpload: $activeUpload) @client {
      id
      fileName
      fileType
    }
  }
`;

export const useActiveUploadAddRemove = () => {
  return useMutation<ActiveUploadAddRemove, ActiveUploadAddRemoveVariables>(
    ACTIVE_UPLOAD_ADD_REMOVE,
  );
};
