import { Box, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import SNButton from '../SNButton';
import SNInput from '../SNInput';

export interface ListSearchControlProps {
  value: string;
  setValue: (value: string) => void;
  forceShow?: boolean;
}

const ListSearchControl: React.FC<ListSearchControlProps> = ({
  value,
  setValue,
  forceShow = false,
}) => {
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const [showSearch, setShowSearch] = React.useState(forceShow);

  const handleBlur = React.useCallback(() => {
    if (value.length === 0 && !forceShow) setShowSearch(false);
  }, [forceShow, value, setShowSearch]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  const handleClick = React.useCallback(() => {
    setValue('');
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [setValue]);

  if (!showSearch) {
    return (
      <SNButton
        data-testid="filter_list_button"
        snVariant="text"
        startIcon={<SearchIcon />}
        onClick={() => setShowSearch(true)}
      >
        Filter this list
      </SNButton>
    );
  }

  return (
    <SNInput
      data-testid="filter_list_input"
      inputRef={searchInputRef}
      autoFocus
      onBlur={handleBlur}
      onChange={handleChange}
      startAdornment={
        <Box display="flex" ml={1}>
          <SearchIcon />
        </Box>
      }
      endAdornment={
        <Box
          visibility={value.length > 0 ? 'visible' : 'hidden'}
          display="flex"
          mr={1}
        >
          <IconButton onClick={handleClick} size="small">
            <ClearIcon />
          </IconButton>
        </Box>
      }
      value={value}
    />
  );
};

export default ListSearchControl;
