import {
  CustomCell,
  ProvideEditorCallback,
  CustomRenderer,
  GridCellKind,
} from '@glideapps/glide-data-grid';
import styled, { useTheme } from 'styled-components';
import React from 'react';
import {
  AnnotationSeverity,
  FieldDefinitionDetails_inputOptions_LocalSelectInputOptions_options,
} from '../../types/schemaTypes';
import type { Theme } from '@glideapps/glide-data-grid';
import Select, { MenuProps, components } from 'react-select';
import { drawTextCell } from './CustomCells';

type CustomMenuProps = MenuProps<any>;

const CustomMenu: React.FC<CustomMenuProps> = (p) => {
  const { Menu } = components;
  const { children, ...rest } = p;
  return <Menu {...rest}>{children}</Menu>;
};

interface LocalSelectCellProps {
  readonly kind: 'local-select-cell';
  readonly value: string;
  readonly options: FieldDefinitionDetails_inputOptions_LocalSelectInputOptions_options[];
  readonly displayStatus: AnnotationSeverity | null;
}

type LocalSelectCell = CustomCell<LocalSelectCellProps>;
export type { LocalSelectCell as LocalSelectCellType };

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .glide-select {
    font-family: var(--gdg-font-family);
    font-size: var(--gdg-editor-font-size);
  }
`;

const PortalWrap = styled.div`
  font-family: var(--gdg-font-family);
  font-size: var(--gdg-editor-font-size);
  color: var(--gdg-text-dark);
  > div {
    border-radius: 4px;
    border: 1px solid var(--gdg-border-color);
  }
`;

const Editor: ReturnType<ProvideEditorCallback<LocalSelectCell>> = (p) => {
  const { value: cell, onFinishedEditing, initialValue } = p;
  const { options, value: valueIn } = cell.data;

  const [value, setValue] = React.useState(valueIn);
  const [inputValue, setInputValue] = React.useState(initialValue ?? '');

  const theme = useTheme() as Theme;

  const selectedOption =
    options.find((option) => option.value === value) || null;

  return (
    <Wrap>
      <Select
        className="glide-select"
        inputValue={inputValue}
        onInputChange={setInputValue}
        menuPlacement="auto"
        value={
          selectedOption
            ? { value: selectedOption.value, label: selectedOption.text }
            : null
        }
        styles={{
          control: (base) => ({
            ...base,
            minHeight: 59,
            border: 0,
            boxShadow: 'none',
            paddingLeft: 6,
            paddingRight: 6,
          }),
        }}
        theme={(t) => {
          return {
            ...t,
            colors: {
              ...t.colors,
              neutral0: theme.bgCell, // this is both the background color AND the fg color of
              // the selected item because of course it is.
              neutral5: theme.bgCell,
              neutral10: theme.bgCell,
              neutral20: theme.bgCellMedium,
              neutral30: theme.bgCellMedium,
              neutral40: theme.bgCellMedium,
              neutral50: theme.textLight,
              neutral60: theme.textMedium,
              neutral70: theme.textMedium,
              neutral80: theme.textDark,
              neutral90: theme.textDark,
              neutral100: theme.textDark,
              primary: theme.accentColor,
              primary75: theme.accentColor,
              primary50: theme.accentColor,
              primary25: theme.accentLight, // prelight color
            },
          };
        }}
        menuPortalTarget={
          document.getElementsByTagName('body')[0] as HTMLElement
        }
        autoFocus
        openMenuOnFocus
        menuIsOpen
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          Menu: (props) => (
            <PortalWrap>
              <CustomMenu className="click-outside-ignore" {...props} />
            </PortalWrap>
          ),
        }}
        options={options.map((option) => ({
          value: option.value,
          label: option.text,
        }))}
        onChange={(option) => {
          if (option === null) return;
          setValue(option.value);
          const newCell: LocalSelectCell = {
            ...cell,
            data: {
              ...cell.data,
              value: option.value,
            },
          };
          onFinishedEditing(newCell);
        }}
      />
    </Wrap>
  );
};

const renderer: CustomRenderer<LocalSelectCell> = {
  isMatch: (c): c is LocalSelectCell =>
    (c.data as any).kind === 'local-select-cell',
  draw: (args, cell) => {
    const { data, readonly } = cell;
    const { value, options, displayStatus: status } = data;
    const selectedOption = options.find((option) => option.value === value);
    return drawTextCell(args, {
      value: selectedOption?.text ?? value,
      status,
      readonly,
    });
  },
  provideEditor: () => ({
    editor: Editor,
    disablePadding: true,
    deletedValue: (toDelete) => ({
      ...toDelete,
      copyData: '',
      data: {
        ...toDelete.data,
        value: '',
      },
    }),
  }),
  onPaste: (val, cellData) => {
    return {
      ...cellData,
      value: cellData.options.map((option) => option.value).includes(val)
        ? val
        : cellData.value,
    };
  },
  kind: GridCellKind.Custom,
};

export default renderer;
