import React from 'react';
import Checkbox from '../Checkbox';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import {
  pageIdsAtom,
  selectAllResultsSelector,
  selectedIdsSelector,
} from '../../atoms/listSelection';
import { useLocation } from 'react-router';

const ListSelectionControl = () => {
  const location = useLocation();
  const [selectedIds, setSelectedIds] = useRecoilState(selectedIdsSelector);
  const pageIds = useRecoilValue(pageIdsAtom);
  const setSelectAll = useSetRecoilState(selectAllResultsSelector);
  const resetListSelection = useResetRecoilState(selectAllResultsSelector);

  React.useEffect(() => {
    resetListSelection();
    return () => {
      resetListSelection();
    };
  }, [resetListSelection, location.pathname]);

  const handleCheckboxChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (
        event.currentTarget.getAttribute('data-indeterminate') === 'true' ||
        selectedIds.length === pageIds.length
      ) {
        setSelectAll(false);
      } else {
        setSelectedIds(pageIds);
      }
    },
    [pageIds, selectedIds, setSelectedIds, setSelectAll],
  );

  return (
    <Checkbox
      data-testid="select-all-checkbox"
      disabled={pageIds.length === 0}
      checked={pageIds.length > 0 && pageIds.length === selectedIds.length}
      indeterminate={
        selectedIds.length > 0 && selectedIds.length < pageIds.length
      }
      onChange={handleCheckboxChange}
    />
  );
};

export default ListSelectionControl;
