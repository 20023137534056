import React from 'react';
import { useCompleteSearchObject } from '../contexts';
import { searchInputTransform } from '../utils';

function useSearchInput(searchId: string) {
  const searchObject = useCompleteSearchObject(searchId);

  return React.useCallback(
    () => searchInputTransform(searchObject),
    [searchObject],
  );
}

export default useSearchInput;
