import { Box, BoxProps, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import React from 'react';
import SNButton from '../SNButton';

interface ErrorResultProps extends BoxProps {
  primary?: string;
}

const ErrorResult: React.FC<ErrorResultProps> = ({
  primary = 'Something went wrong',
  ...rest
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      position="absolute"
      pb={9}
      {...rest}
    >
      <Box fontSize={88} display="flex" alignItems="center" color="grey.300">
        <ErrorOutline fontSize="inherit" color="inherit" />
      </Box>
      <Box py={1}>
        <Typography variant="h2">{primary}</Typography>
      </Box>
      <Box py={1}>
        <Typography>Please try again</Typography>
      </Box>
      <Box py={2}>
        <SNButton snVariant="light">Report an issue</SNButton>
      </Box>
    </Box>
  );
};

export default ErrorResult;
