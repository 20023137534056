// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/browser';
import env from '@beam-australia/react-env';
import './fonts/Akkurat-Bold.ttf';
import './fonts/Akkurat-Normal.ttf';
import './index.css';

// A function that routes the user to the right place
// after login
const onRedirectCallback: Auth0ProviderOptions['onRedirectCallback'] = (
  appState,
) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

const audience = env('API_AUDIENCE');
const domain = env('API_LOGIN_DOMAIN');
const clientId = env('API_CLIENT_ID');
const useFullStory = env('FULLSTORY_ENABLED');
const useSentry = env('SENTRY_ENABLED');

if (useFullStory === 'TRUE') {
  FullStory.init({ orgId: 'GWQX1' });
}
if (useSentry === 'TRUE') {
  Sentry.init({
    dsn: 'https://797c5996e7744902a0907231f07a44a9@o247991.ingest.sentry.io/5263687',
  });
}

ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    audience={audience}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    cacheLocation="localstorage"
  >
    <BrowserRouter>
      <PrivateRoute path="/">
        <App />
      </PrivateRoute>
    </BrowserRouter>
  </Auth0Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
