import {
  Redirect,
  Switch,
  Route,
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useEntityDetailsQuery } from '../../queries/useEntityDetailsQuery';
import DetailTabs from '../../components/DetailTabs';
import { EditEntitiesProvider } from '../../contexts';
import EntityData from '../../components/EntityData';
import EntityHistory from '../../components/EntityHistory';
import EntityLinkSummaryTabs from '../../components/EntityLinkSummaryTabs';
import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import EntityDetailStatusBadge from '../../components/EntityDetailStatusBadge';
import { collectErrors } from '../../utils/collectErrors';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { useSetRecoilState } from 'recoil';

const entityDetailTabs = ['data', 'history', 'links'];

const EntityDetails = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string; tab: string }>();
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const { data, loading } = useEntityDetailsQuery({ variables: { id } });
  const { pathname } = useLocation();
  const isReviewRoute = React.useMemo(
    () => pathname.includes('review'),
    [pathname],
  );

  const entityTitle: ReactNode = (
    <Box>
      <Box>
        <Typography variant="h1">
          {data?.entityById?.data?.displayName}
        </Typography>
      </Box>
      <Box my={1} display="flex" alignItems="center">
        <Typography variant="h5">Entity Type: &nbsp;&nbsp;</Typography>
        <Typography variant="h5" color="textSecondary">
          {data?.entityById?.data?.type.name}
        </Typography>
        <EntityDetailStatusBadge
          entityStatus={data?.entityById?.data?.metadata.status.state}
        />
      </Box>
    </Box>
  );

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.entityById?.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error fetching this entity',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <DetailTabs
      baseRoute={isReviewRoute ? 'review/entity' : 'search/entity'}
      id={id}
      loading={loading}
      onBack={() => history.goBack()}
      tabs={entityDetailTabs}
      title={
        data?.entityById?.data?.displayName && data?.entityById?.data?.type.name
          ? entityTitle
          : 'Entity Not Found'
      }
    >
      <Switch>
        <Route path="/search/entity/:id/data">
          <EditEntitiesProvider>
            <EntityData id={id} />
          </EditEntitiesProvider>
        </Route>
        <Route path="/search/entity/:id/history">
          <EntityHistory />
        </Route>
        <Route path="/search/entity/:id/links">
          <EntityLinkSummaryTabs id={id} />
        </Route>
        <Route path="/review/entity/:id/data">
          <EditEntitiesProvider>
            <EntityData id={id} />
          </EditEntitiesProvider>
        </Route>
        <Route path="/review/entity/:id/history">
          <EntityHistory />
        </Route>
        <Route path="/review/entity/:id/links">
          <EntityLinkSummaryTabs id={id} />
        </Route>
        <Redirect
          to={
            isReviewRoute
              ? `/review/entity/${id}/data`
              : `/search/entity/${id}/data`
          }
        />
      </Switch>
    </DetailTabs>
  );
};

export default EntityDetails;
