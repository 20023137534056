import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { SearchTargets } from '../types/schemaTypes';

export const SEARCH_TARGETS = gql`
  query SearchTargets {
    searchTargets {
      __typename
      ... on LinkType {
        id
        name
        description
        searchTargetType @client
        fields {
          id
          name
          description
          inputOptions {
            ... on TextInputOptions {
              minLength
              maxLength
              type
            }
            ... on LocalSelectInputOptions {
              options {
                value
                text
              }
              type
            }
            ... on NumericInputOptions {
              type
            }
            ... on DateTimeInputOptions {
              minDate
            }
          }
        }
      }
      ... on EntityType {
        id
        name
        searchTargetType @client
      }
    }
  }
`;

export const useSearchTargetsQuery = (
  options?: QueryHookOptions<SearchTargets>,
) => {
  return useQuery<SearchTargets>(SEARCH_TARGETS, options);
};
