import React from 'react';
import { Box, Typography } from '@mui/material';
import { LayerProps } from 'react-laag';

type TooltipProps = Omit<LayerProps, 'ref'>;

export interface GeneralTooltipProps extends TooltipProps {
  message: string;
}

const GeneralTooltip = React.forwardRef<HTMLDivElement, GeneralTooltipProps>(
  ({ message, ...rest }, ref) => {
    return (
      <Box px={2.5} py={1} bgcolor="grey.900" {...rest} ref={ref}>
        <Typography component="span" fontWeight={700}>
          {message}
        </Typography>
      </Box>
    );
  },
);

GeneralTooltip.displayName = 'GeneralTooltip';

export default GeneralTooltip;
