import React from 'react';
import { Typography } from '@mui/material';
import styled from '../../services/styled';

interface ValidationBoxProps {
  fieldId: string;
  rowId: string;
  displayStatus: string;
  value: string;
  name: string;
}
const StyledRowBox = styled('tr')`
  border-collapse: collapse;
  width: 100%;
`;

const StyledNameBox = styled('td')`
  background: ${(p) => p.theme.palette.background.default};
  padding: 8px;
  width: 30%;
`;
const StyledValueBox = styled('td')`
  background: ${(p) => p.theme.palette.background.default};
  border-left: 1px solid ${(p) => p.theme.palette.grey[600]};
  padding: 8px;
  width: 70%;
`;
interface ValidationStatusTypographyProps {
  status: string;
}
const ValidationStatusTypography = styled(
  Typography,
)<ValidationStatusTypographyProps>`
  color: ${(p) =>
    p.status === 'error'
      ? p.theme.palette.error.main
      : p.theme.palette.warning.main};
  text-decoration: underline;
`;

const ValidationBox = ({ displayStatus, value, name }: ValidationBoxProps) => {
  return (
    <StyledRowBox>
      <StyledNameBox>
        <Typography variant="h6" color="textSecondary">
          {name}
        </Typography>
      </StyledNameBox>
      <StyledValueBox>
        <ValidationStatusTypography status={displayStatus} variant="h6">
          {value}
        </ValidationStatusTypography>
      </StyledValueBox>
    </StyledRowBox>
  );
};

export default ValidationBox;
