import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import theme from '../../services/theme';
import { round } from '../../utils/round';

interface SingleValuePieChartProps {
  total: number;
  value: number;
  valueColor: string;
  totalColor?: string;
}

const getPercentValue = function getPercentValue(value: number, total: number) {
  if (total == 0) {
    return 0;
  }
  return round((value / total) * 100, 0);
};

const SingleValuePieChart = ({
  total,
  value,
  valueColor,
  totalColor = theme.palette.grey[600],
}: SingleValuePieChartProps) => {
  const data = [
    {
      id: 'value',
      label: 'value',
      value: value,
      color: valueColor,
    },
    {
      id: 'rest',
      label: '',
      value: total - value,
      color: totalColor,
    },
  ];

  const CenteredMetric = (props: { centerX: number; centerY: number }) => {
    const percentValue = getPercentValue(value, total);
    const displayValue =
      percentValue > 0.1 || value === 0 ? percentValue : '<0.1';
    const { fontFamily, fontSize, fontWeight, lineHeight, letterSpacing } =
      theme.typography.h1;

    return (
      <text
        x={props.centerX}
        y={props.centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{ fontFamily, fontSize, fontWeight, lineHeight, letterSpacing }}
        fill="#ffffff"
      >
        {displayValue}%
      </text>
    );
  };

  return (
    <div
      style={{
        minWidth: 80,
        minHeight: 80,
        maxHeight: 500,
        maxWidth: 500,
        width: 'inherit',
        height: 'inherit',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      >
        <ResponsivePie
          animate={false}
          enableArcLinkLabels={false}
          enableArcLabels={false}
          isInteractive={false}
          colors={{ datum: 'data.color' }}
          data={data}
          activeInnerRadiusOffset={0}
          innerRadius={0.85}
          layers={['arcs', CenteredMetric]}
        />
      </div>
    </div>
  );
};

export default SingleValuePieChart;
