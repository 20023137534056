import { User } from '@auth0/auth0-react';
import { Auth0User } from '../types';

const enhanceIdTokenPayload = (user: User | undefined = {}): Auth0User => ({
  name: user.name || '',
  email: user.email || '',
  picture: user.picture || '',
  sub: user.sub || '',
  userId: user.sub ? user.sub.split('|')[1] : '',
  source: user.sub ? user.sub.split('|')[0] : '',
});

export default enhanceIdTokenPayload;
