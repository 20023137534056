import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

import {
  RawObjectStorageComplete,
  RawObjectStorageCompleteVariables,
} from '../types/schemaTypes';

export const RAW_OBJECT_STORAGE_COMPLETE = gql`
  mutation RawObjectStorageComplete($input: StorageCompleteInput!) {
    rawObjectStorageComplete(input: $input) {
      uploadStatus
      fileId
    }
  }
`;

export const useRawObjectStorageCompleteMutation = () => {
  return useMutation<
    RawObjectStorageComplete,
    RawObjectStorageCompleteVariables
  >(RAW_OBJECT_STORAGE_COMPLETE);
};
