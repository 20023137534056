import gql from 'graphql-tag';
import { ClassificationTypeMatch } from './ClassificationTypeMatch';

export const UnclassifiedGroup = gql`
  fragment UnclassifiedGroup on UnclassifiedResultGroup {
    id
    name
    totalCount
    confidence
    headers
    matchingTypes {
      ...ClassificationTypeMatch
    }
    locked
  }
  ${ClassificationTypeMatch}
`;
