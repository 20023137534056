import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import ExtensionIcon from '../ExtensionIcon';
import {
  uploadMetadataSelector,
  uploadFilesByIdAtom,
} from '../../atoms/uploadFiles';
import { useRecoilState, useRecoilValue } from 'recoil';
import SNInput from '../SNInput';
import produce from 'immer';
import cuid from 'cuid';
import { StyledCloseButton } from '../DialogCloseButton/DialogCloseButton';
import Close from '@mui/icons-material/Close';
import Info from '@mui/icons-material/Info';

interface SheetSelectionSectionProps {
  fileId: string;
}

export const MetadataSection = ({ fileId }: SheetSelectionSectionProps) => {
  const [{ description, ...metadataById }, setMetadataById] = useRecoilState(
    uploadMetadataSelector(fileId),
  );

  const handleChange =
    (metadataId: string) =>
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const name = event.target.name as 'field' | 'value';
      const value = event.target.value;
      setMetadataById((prevState) =>
        produce(prevState, (draftState) => {
          if (
            metadataId !== 'description' &&
            draftState[metadataId].value === '' &&
            name === 'value'
          ) {
            draftState[cuid()] = { field: '', value: '' };
          }
          draftState[metadataId][name] = value;
        }),
      );
    };

  const handleDelete = (metadataId: string) => () => {
    setMetadataById((prevState) => {
      const { [metadataId]: omit, ...rest } = prevState;
      return rest;
    });
  };

  return (
    <>
      {Object.entries(metadataById).map(([metadataId, metadata], index) => (
        <Box key={metadataId} display="flex" alignItems="flex-end" py={1}>
          <Box
            pr={1}
            height={40}
            width={18}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Typography variant="h5">{index + 1}</Typography>
          </Box>

          <Box px={1} flexBasis="50%">
            <SNInput
              label="Label"
              name="field"
              value={metadata.field}
              onChange={handleChange(metadataId)}
              fullWidth
              endAdornment={
                metadata.value && !metadata.field ? (
                  <Box mr="7px" display="flex">
                    <Tooltip title="Metadata fields without labels will be omitted.">
                      <Info color="primary" />
                    </Tooltip>
                  </Box>
                ) : undefined
              }
            />
          </Box>

          <Box px={1} flexBasis="50%">
            <SNInput
              label="Value"
              name="value"
              value={metadata.value}
              onChange={handleChange(metadataId)}
              fullWidth
            />
          </Box>

          <Box
            pl={1}
            height={40}
            width={44}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            {Object.keys(metadataById).length > 1 && (
              <StyledCloseButton
                onClick={handleDelete(metadataId)}
                size="small"
              >
                <Close fontSize="small" />
              </StyledCloseButton>
            )}
          </Box>
        </Box>
      ))}
      {description && (
        <Box display="flex" pt={2} pb={1}>
          <Box pr={1} height={33} width={18} />
          <Box flexGrow={1} px={1}>
            <SNInput
              multiline
              fullWidth
              rows={5}
              label="description"
              name="value"
              onChange={handleChange('description')}
              value={description.value}
            />
          </Box>
          <Box pl={1} height={33} width={44} />
        </Box>
      )}
    </>
  );
};

const UploadModalMetadataCollection = () => {
  const filesById = useRecoilValue(uploadFilesByIdAtom);

  return (
    <Box py={1.5}>
      {Object.entries(filesById).map(([fileId, file]) => (
        <Box key={fileId} py={1.5}>
          <Box display="flex" alignItems="center" pb={2}>
            <Box display="flex" alignItems="center" pr={2}>
              <ExtensionIcon fileName={file.name} />
            </Box>
            <Typography variant="h5">{file.name}</Typography>
          </Box>
          <MetadataSection fileId={fileId} />
        </Box>
      ))}
    </Box>
  );
};

export default UploadModalMetadataCollection;
