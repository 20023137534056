import React from 'react';
import { Box, Collapse } from '@mui/material';
import { SearchTree } from '../../components';
import { SearchTreeNavProvider, EditEntitiesProvider } from '../../contexts';
import SearchSavePrompt from '../../components/SearchSavePrompt';
import EntityTypeResultTabs from '../../components/EntityTypeResultTabs';
import { useShowFiltersState } from '../../contexts/ShowFiltersContext';
import { hasEntityEditsState, unsavedSearchVar } from '../../localState';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isEditingEntitiesAtom } from '../../atoms/editEntities';
import { pageIdsAtom } from '../../atoms/listSelection';
import { useSearchInput } from '../../hooks';
import ErrorResult from '../../components/ErrorResult';
import LoadingOverlay from '../../components/LoadingOverlay';
import SNPagination from '../../components/SNPagination';
import { useApolloClient } from '@apollo/client';
import { NEW_SEARCH_KEY } from '../../constants';
import {
  searchTabsActiveTabSelector,
  searchTabsTabOrderSelector,
} from '../../atoms/latestSearchAtom';
import {
  ENTITIES_BY_TYPE_FOR_SEARCH,
  useEntitiesByTypeForSearchQuery,
} from '../../queries/useEntitiesByTypeForSearchQuery';
import { entityTableRows } from '../../atoms/entityTableRows';
import { mapEntityResultToTableRow } from '../../utils/entityResultsToTableRows';
import EntitiesTable from '../../components/EntitiesTable';
import SNToolbar from '../../components/SNToolbar';
import ConnectedGlideDataGridSelectionControl from '../../components/GlideDataGridSelectionControl/ConnectedGlideDataGridSelectionControl';
import ScrollingContainer from '../../components/ScrollingContainer';
import SearchResultsTableActions from '../../components/SearchResultsTableActions';
import { collectErrors } from '../../utils/collectErrors';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import SearchFilterDropdown from '../../components/SearchFilterDropdown';

interface SearchProps {
  searchId: string;
}

/*
  So this is a bit hacky. Basically what I am using this for
  is to sort one list by another list so that the new list takes
  on the order of the old list wherever they overlap. Without this
  conversion items that are in the new list, but not the old get
  an sort value -1 which puts them at the front of the list. I want
  them to go at the end. This seemed like the cleanest way to do that.
*/
const changeNegativeOneToNineHundredNinetyNine = (int: number) =>
  int === -1 ? 999 : int;

const pageSize = 30;
const tableId = 'search';

const SearchContainer: React.FC<SearchProps> = ({ searchId }) => {
  const [page, setPage] = React.useState(0);
  const showFilters = useShowFiltersState();
  const isEditing = useRecoilValue(isEditingEntitiesAtom);
  const hasEntityEdits = useRecoilValue(hasEntityEditsState);
  const client = useApolloClient();
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);

  const [currentTypeId, setCurrentTypeId] = useRecoilState(
    searchTabsActiveTabSelector(searchId),
  );
  const generateSearchInput = useSearchInput(searchId);
  const searchQuery = React.useMemo(() => {
    return generateSearchInput();
  }, [generateSearchInput]);
  const setSearchResultValues = useSetRecoilState(entityTableRows(tableId));
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const setTabOrder = useSetRecoilState(searchTabsTabOrderSelector(searchId));

  const { loading, error, data, fetchMore, refetch } =
    useEntitiesByTypeForSearchQuery({
      variables: {
        first: pageSize,
        searchQuery,
        type: currentTypeId,
      },
      onCompleted: (result) => {
        const startIndex =
          result.entitiesByTypeForSearch.data?.pageInfo.startIndex;
        if (typeof startIndex === 'number') {
          setPage(startIndex / pageSize);
        }
        if (
          result.entitiesByTypeForSearch.data?.__typename ===
          'EntityResultsByTypeConnection'
        ) {
          const receivedTypeId =
            result.entitiesByTypeForSearch.data?.currentType?.id;
          if (result.entitiesByTypeForSearch.data?.types.length) {
            if (receivedTypeId && !currentTypeId) {
              client.cache.updateQuery(
                {
                  query: ENTITIES_BY_TYPE_FOR_SEARCH,
                  variables: {
                    first: pageSize,
                    searchQuery,
                    type: receivedTypeId,
                  },
                },
                () => result,
              );
              setCurrentTypeId(receivedTypeId);
            }
          }
        }
      },
    });

  const handleRefetch = React.useCallback(() => {
    refetch();
  }, [refetch]);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.entitiesByTypeForSearch.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error querying for entities',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  React.useEffect(() => {
    const receivedTypeId = data?.entitiesByTypeForSearch.data?.currentType?.id;
    if (data?.entitiesByTypeForSearch.data?.types.length) {
      const typeIds = data.entitiesByTypeForSearch.data.types.map(
        ({ id }) => id,
      );
      setTabOrder((prev) =>
        typeIds
          .slice()
          .sort(
            (a, b) =>
              changeNegativeOneToNineHundredNinetyNine(prev.indexOf(a)) -
              changeNegativeOneToNineHundredNinetyNine(prev.indexOf(b)),
          ),
      );
      if (
        receivedTypeId &&
        !data.entitiesByTypeForSearch.data.types.find(
          (type) => type.id === receivedTypeId,
        )
      ) {
        setCurrentTypeId(data.entitiesByTypeForSearch.data.types[0].id);
      }
    }
  }, [client.cache, data, searchQuery, setCurrentTypeId, setTabOrder]);

  React.useEffect(() => {
    if (
      data?.entitiesByTypeForSearch?.data?.__typename ===
      'EntityResultsByTypeConnection'
    ) {
      setPageIds(
        data.entitiesByTypeForSearch.data.edges.map(
          (edge) => edge.node.entity.id,
        ),
      );
      setSearchResultValues(
        data.entitiesByTypeForSearch.data.edges.map(mapEntityResultToTableRow),
      );
    }
  }, [data, setPageIds, setSearchResultValues]);

  const handleBlockSearchNavigation = React.useCallback(
    () => unsavedSearchVar(),
    [],
  );

  const handleSearchNavigation = React.useCallback(() => {
    unsavedSearchVar(false);
  }, []);

  const pageTotal = data?.entitiesByTypeForSearch?.data?.edges.length || 0;

  return (
    <>
      {!isEditing && (
        <SearchSavePrompt
          when={searchId !== NEW_SEARCH_KEY && !hasEntityEdits}
          onNavigate={handleSearchNavigation}
          shouldBlockNavigation={handleBlockSearchNavigation}
          title="Unsaved changes"
          message="You have unsaved changes to this search. Do you want to proceed and
            discard those changes?"
          primary="Discard Changes"
        />
      )}
      <SearchTreeNavProvider>
        <Collapse in={!isEditing && showFilters}>
          <Box pt={2} pb={4} px={4}>
            <SearchTree searchId={searchId} />
          </Box>
        </Collapse>
      </SearchTreeNavProvider>
      <Box pb={2}>
        <SearchFilterDropdown searchId={searchId} />
      </Box>
      <Box position="relative" id="search-results-table">
        <EntityTypeResultTabs
          searchId={searchId}
          disabled={isEditing || loading}
          types={data?.entitiesByTypeForSearch?.data?.types}
        />
        {error && <ErrorResult data-testid="search-results-error" />}
        <EditEntitiesProvider>
          <SNToolbar bgcolor={isEditing ? 'primary.main' : 'grey.900'}>
            <Box display="flex" alignItems="center" ml={-1}>
              <Box display="flex" alignItems="center" pr={2}>
                <ConnectedGlideDataGridSelectionControl
                  page={page}
                  pageTotal={pageTotal}
                  tableId={tableId}
                />
              </Box>
              <SNPagination
                {...data?.entitiesByTypeForSearch.data?.pageInfo}
                fetchMore={fetchMore}
                loading={loading}
                pageSize={pageSize}
                pageTotal={pageTotal}
              />
            </Box>
            <SearchResultsTableActions
              page={page}
              pageTotal={pageTotal}
              onEditSuccess={handleRefetch}
              searchId={searchId}
              tableId={tableId}
              typeId={currentTypeId}
            />
          </SNToolbar>
          <ScrollingContainer>
            <EntitiesTable
              rowCount={data?.entitiesByTypeForSearch?.data?.edges.length}
              currentTypeId={currentTypeId}
              tableId={tableId}
              page={page}
            />
          </ScrollingContainer>
        </EditEntitiesProvider>
        {loading && (
          <LoadingOverlay
            isLoading={loading}
            data-testid="search-results-loading"
          />
        )}
      </Box>
    </>
  );
};

export default SearchContainer;
