import React from 'react';
import { Box, Typography } from '@mui/material';
import pluralize from 'pluralize';
import { getMuiVariant } from '../../services/theme';
import SNButton from '../SNButton';
import LockIcon from '@mui/icons-material/Lock';

export interface UnclassifiedGroupCardProps {
  confidence?: number | null;
  entityTypeName?: string;
  id: string;
  name: string;
  onClick: (groupId: string) => void;
  totalCount: number;
  locked: boolean;
}

const UnclassifiedGroupCard = ({
  confidence,
  entityTypeName,
  id,
  name,
  totalCount,
  locked,
  onClick,
}: UnclassifiedGroupCardProps) => {
  const handleClick = React.useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <Box
      id={id}
      bgcolor="common.black"
      display="flex"
      flexDirection="column"
      p={2}
      justifyContent="space-between"
      minWidth={293}
      minHeight={150}
    >
      <Box>
        <Typography variant={getMuiVariant('title')}>{name}</Typography>
        <Box py={1}>
          <Typography>
            {totalCount} {pluralize('entity', totalCount)}
          </Typography>
          {typeof confidence === 'number' && (
            <Typography>
              {confidence}% match for {entityTypeName}
            </Typography>
          )}
        </Box>
      </Box>
      <Box pt={1}>
        {locked ? (
          <Box display="flex" alignItems="center">
            <Box m={1}>
              <LockIcon />
            </Box>
            <Typography color="textSecondary">
              This group is being classified
            </Typography>
          </Box>
        ) : (
          <SNButton snVariant="primary" onClick={handleClick}>
            Classify
          </SNButton>
        )}
      </Box>
    </Box>
  );
};

export default UnclassifiedGroupCard;
