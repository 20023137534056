import { useUserState } from '../contexts';
import { UserRole } from '../types/schemaTypes';
import { useUserQuery } from '../queries';
import React from 'react';

const useIsUserAllowedTo = (allowedRoles: UserRole[]) => {
  const user = useUserState();
  const { data } = useUserQuery({ variables: { id: user.userId } });

  const [isUserAllowedTo, setIsUserAllowedTo] = React.useState(false);

  React.useEffect(() => {
    setIsUserAllowedTo(
      allowedRoles.includes(data?.user?.user?.defaultRole || UserRole.GUEST),
    );
  }, [allowedRoles, data, setIsUserAllowedTo]);

  return isUserAllowedTo;
};

export default useIsUserAllowedTo;
