import React from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router';
import UploadsTable from '../UploadsTable';
import { UploadTabsProvider } from '../../contexts';
import { SavedSearchType, UploadState } from '../../types/schemaTypes';
import { useHistory } from 'react-router-dom';
import QualityReviewDashboard from '../QualityReviewDashboard/QualityReviewDashboard';
import SavedSearchesTable from '../SavedSearchesTable';
import SNTabs from '../SNTabs';

const ReviewTabs = () => {
  const { tab = 'dashboard' } = useParams<{ tab?: string }>();
  const { push } = useHistory();

  const handleTabClick = React.useCallback(
    (tab: string) => () => {
      push(`/review/${tab}`);
    },
    [push],
  );

  const handleCreateReview = () => {
    push(`/review/saved/new`);
  };

  const handleSearchSelect = React.useCallback(
    (id: string) => {
      push(`/review/saved/${id}`);
    },
    [push],
  );

  return (
    <>
      <SNTabs
        active={tab}
        onTabClick={handleTabClick}
        tabs={['dashboard', 'uploads', 'saved', 'public']}
      />
      <Switch>
        <Route path="/review/dashboard">
          <QualityReviewDashboard />
        </Route>
        <Route path="/review/uploads">
          <UploadTabsProvider initialState={[UploadState.NEEDS_REVIEW]}>
            <UploadsTable disableStateFilter />
          </UploadTabsProvider>
        </Route>
        <Route path="/review/saved">
          <SavedSearchesTable
            onNewClick={handleCreateReview}
            onSearchSelect={handleSearchSelect}
            savedSearchType={SavedSearchType.REVIEW}
          />
        </Route>
        <Route path="/review/public">
          <SavedSearchesTable
            isPublic
            onSearchSelect={handleSearchSelect}
            savedSearchType={SavedSearchType.REVIEW}
          />
        </Route>

        <Redirect to="/review/dashboard" />
      </Switch>
    </>
  );
};

export default ReviewTabs;
