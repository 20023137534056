import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  RemoveUserFromGroup,
  RemoveUserFromGroupVariables,
} from '../types/schemaTypes';

export const REMOVE_USER_FROM_GROUP = gql`
  mutation RemoveUserFromGroup($input: DeleteInput!) {
    removeUserFromGroup(input: $input) {
      id
      errors
    }
  }
`;

export const useRemoveUserFromGroupMutation = (
  options?: MutationHookOptions<
    RemoveUserFromGroup,
    RemoveUserFromGroupVariables
  >,
) => {
  return useMutation<RemoveUserFromGroup, RemoveUserFromGroupVariables>(
    REMOVE_USER_FROM_GROUP,
    options,
  );
};
