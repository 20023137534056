import { atomFamily } from 'recoil';
import { SearchResultDatum } from '../types';

export const entityTableRows = atomFamily<
  Record<string, SearchResultDatum>[],
  string
>({
  key: 'entity-table-rows',
  default: [],
});
