import { Box, BoxProps, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import React, { ReactNode } from 'react';
import styled from '../../services/styled';
import { PaletteStatus } from '../../types';

interface SNStatusBannerProps extends BoxProps {
  status?: PaletteStatus;
  children?: string | ReactNode;
}

export const StatusIcon = styled(WarningIcon)<SNStatusBannerProps>`
  color: ${(p) => {
    switch (p.status) {
      case 'error':
        return p.theme.palette.error.light;
      case 'info':
        return p.theme.palette.info.light;
      case 'success':
        return p.theme.palette.success.light;
      case 'warning':
        return p.theme.palette.warning.light;
      default:
        return p.theme.palette.grey['300'];
    }
  }};
`;

const SNStatusBanner = styled(
  ({ status, children, ...rest }: SNStatusBannerProps) => (
    <Box bgcolor="grey.900" display="flex" alignItems="center" p={2} {...rest}>
      <Box mr={1} display="flex">
        <StatusIcon status={status} />
      </Box>
      {typeof children === 'string' ? (
        <Typography>{children}</Typography>
      ) : (
        <Box>{children}</Box>
      )}
    </Box>
  ),
)`
  border-left: 4px solid
    ${({ status = 'info', theme }) => theme.palette[status].main};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

export default SNStatusBanner;
