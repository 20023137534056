import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { CreateUser, CreateUserVariables } from '../types/schemaTypes';

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        name
        picture
      }
      errors
    }
  }
`;

export const useCreateUserMutation = (
  options?: MutationHookOptions<CreateUser, CreateUserVariables>,
) => {
  return useMutation<CreateUser, CreateUserVariables>(CREATE_USER, options);
};
