import React, { ReactNode } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';

interface CollapsePanelProps {
  name: string | ReactNode;
  open?: boolean;
  onRemove?: () => void;
}

const CollapsePanel: React.FC<CollapsePanelProps> = ({
  name,
  open = true,
  onRemove,
  children,
}) => {
  const [isOpen, setIsOpen] = React.useState(open);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box width="100%">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bgcolor="#000"
        height={56}
      >
        <Box display="flex" alignItems="center">
          <Box px={1}>
            <IconButton onClick={handleToggleOpen}>
              {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
          {typeof name === 'string' ? (
            <Typography variant="h4">{name}</Typography>
          ) : (
            <Box>{name}</Box>
          )}
        </Box>
        {onRemove && (
          <Box px={1}>
            <IconButton size="small" onClick={onRemove}>
              <ClearIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
      <Collapse in={isOpen}>
        <Box bgcolor="background.default">{children}</Box>
      </Collapse>
    </Box>
  );
};

export default CollapsePanel;
