import React from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import SNButton from '../SNButton';
import { useAddUsersToGroupsMutation } from '../../mutations/useAddUsersToGroupsMutation';
import UsersToAddSelector from '../UsersToAddSelector';
import { SelectedUsers } from '../UsersToAddSelector/UsersToAddSelector';
import { useParams } from 'react-router';
import SNDialogTitle from '../SNDialogTitle';
import { collectErrors } from '../../utils/collectErrors';
import SNStatusBanner from '../SNStatusBanner';

interface AddUsersToGroupFormProps {
  onClose: () => void;
}

const AddUsersToGroupForm = ({ onClose }: AddUsersToGroupFormProps) => {
  const { id: groupId } = useParams<{ id: string }>();
  const [selectedUsers, setSelectedUsers] = React.useState<SelectedUsers>({});
  const [addUsersToGroup, { data: usersAddedData }] =
    useAddUsersToGroupsMutation({
      refetchQueries: ['UsersOfGroup'],
      onCompleted: () => {
        onClose();
      },
    });

  const handleAddUsersToGroup = React.useCallback(() => {
    const selectedUserIds = Object.keys(selectedUsers);
    if (groupId && selectedUserIds.length > 0) {
      const additions = selectedUserIds.map((userId) => {
        return { userId, groupId };
      });
      addUsersToGroup({ variables: { input: { additions } } });
    }
  }, [addUsersToGroup, groupId, selectedUsers]);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([usersAddedData?.addUsersToGroups?.errors]);
  }, [usersAddedData]);

  return (
    <>
      <SNDialogTitle onClose={onClose}>Add Users to Group</SNDialogTitle>
      <DialogContent>
        {collectedErrors.length > 0 && (
          <Box mb={2}>
            <SNStatusBanner status="error">
              <ul>
                {collectedErrors.map((error) => {
                  return <li key={error}>{error}</li>;
                })}
              </ul>
            </SNStatusBanner>
          </Box>
        )}
        <UsersToAddSelector
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
        />
      </DialogContent>
      <DialogActions>
        <SNButton onClick={onClose}>Cancel</SNButton>
        <SNButton
          snVariant="primary"
          onClick={handleAddUsersToGroup}
          disabled={Object.keys(selectedUsers).length === 0}
        >
          Add Users
        </SNButton>
      </DialogActions>
    </>
  );
};

export default AddUsersToGroupForm;
