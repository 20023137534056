import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import { GroupsOfUser, GroupsOfUserVariables } from '../types/schemaTypes';

export const GROUPS_OF_USER = gql`
  query GroupsOfUser(
    $id: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: GroupFilter
  ) {
    user(id: $id) {
      user {
        id
        name
        picture
        email
        defaultRole
        groupMembership(
          after: $after
          before: $before
          first: $first
          last: $last
          filter: $filter
        ) {
          totalCount
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
            endIndex @client
            startIndex @client
          }
          edges {
            cursor
            node {
              id
              user {
                id
                name
                picture
              }
              createdAt
              lastModified
              group {
                id
                name
                description
                groupMembership(first: 5) {
                  totalCount
                  edges {
                    cursor
                    node {
                      user {
                        id
                        name
                        picture
                      }
                    }
                  }
                }
                groupProjectAccess(first: 0) {
                  totalCount
                }
              }
            }
          }
        }
      }
      errors
    }
  }
`;

export const useLazyGroupsOfUserQuery = (
  options?: LazyQueryHookOptions<GroupsOfUser, GroupsOfUserVariables>,
) => {
  return useLazyQuery<GroupsOfUser, GroupsOfUserVariables>(GROUPS_OF_USER, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};

export const useGroupsOfUserQuery = (
  options?: QueryHookOptions<GroupsOfUser, GroupsOfUserVariables>,
) => {
  return useQuery<GroupsOfUser, GroupsOfUserVariables>(GROUPS_OF_USER, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};
