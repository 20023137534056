import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
  AllowedLinkTypes,
  AllowedLinkTypesVariables,
} from '../types/schemaTypes';

export const ALLOWED_LINK_TYPES = gql`
  query AllowedLinkTypes($filter: AllowedLinkTypesInput!) {
    allowedLinkTypes(filter: $filter) {
      status {
        message
        severity
      }
      linkTypes {
        id
        name
        description
        fields {
          id
          name
          description
          inputOptions {
            ... on TextInputOptions {
              minLength
              maxLength
              type
            }
            ... on LocalSelectInputOptions {
              options {
                value
                text
              }
              type
              minItems
              maxItems
            }
            ... on NumericInputOptions {
              type
              minValue
              maxValue
            }
            ... on DateTimeInputOptions {
              minDate
              maxDate
              captureTime
            }
          }
        }
      }
    }
  }
`;

export const useAllowedLinkTypesQuery = (
  variables: AllowedLinkTypesVariables,
) => {
  return useQuery<AllowedLinkTypes, AllowedLinkTypesVariables>(
    ALLOWED_LINK_TYPES,
    {
      notifyOnNetworkStatusChange: true,
      variables,
    },
  );
};
