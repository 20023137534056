import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

import {
  NewLocalFileUpload,
  NewLocalFileUploadVariables,
} from '../types/schemaTypes';

export const LOCAL_FILE_UPLOAD_MUTATION = gql`
  mutation NewLocalFileUpload($input: NewLocalFileInput!) {
    newLocalFileUpload(input: $input) {
      uploadUrls {
        partNumber
        url
      }
      uploadId
      fileId
    }
  }
`;

export const useNewLocalFileUploadMutation = () => {
  return useMutation<NewLocalFileUpload, NewLocalFileUploadVariables>(
    LOCAL_FILE_UPLOAD_MUTATION,
  );
};
