import React from 'react';
import { Snackbar, Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styled from '../../services/styled';
import pluralize from 'pluralize';

interface EditConfirmationSnackBarProps {
  open: boolean;
  onClose: () => void;
  changeCount: number;
}

const StyledSnackBarBox = styled(Box)`
  background: ${(p) => p.theme.palette.grey[600]};
  width: 320px;
  box-shadow: 0px 4px 10px 10px rgba(0, 0, 0, 0.25);
`;

const EditConfirmationSnackBar = ({
  open,
  onClose,
  changeCount,
}: EditConfirmationSnackBarProps) => {
  return (
    <Snackbar
      autoHideDuration={6000}
      id="entity-edit-confirm"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      onClose={onClose}
    >
      <StyledSnackBarBox>
        <Box display="flex" alignItems="center" m={2}>
          <Box mr={2} display="flex">
            <CheckCircleIcon />
          </Box>
          <Typography variant="h5">
            {changeCount} {pluralize('value', changeCount)} changed!
          </Typography>
        </Box>
      </StyledSnackBarBox>
    </Snackbar>
  );
};

export default EditConfirmationSnackBar;
