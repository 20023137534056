import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { AddSavedSearch, AddSavedSearchVariables } from '../types/schemaTypes';

export const ADD_SAVED_SEARCH = gql`
  mutation AddSavedSearch($input: AddSavedSearchInput!) {
    addSavedSearch(input: $input) {
      data {
        id
      }
      errors
    }
  }
`;

export const useAddSavedSearchMutation = (
  options?: MutationHookOptions<AddSavedSearch, AddSavedSearchVariables>,
) => {
  return useMutation<AddSavedSearch, AddSavedSearchVariables>(
    ADD_SAVED_SEARCH,
    options,
  );
};
