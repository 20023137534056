import React from 'react';
import { Box, BoxProps, CircularProgress, Fade } from '@mui/material';

interface LoadingOverlayProps extends BoxProps {
  isLoading: boolean;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  isLoading,
  ...rest
}) => {
  return (
    <Fade in={isLoading}>
      <Box
        p={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bgcolor="rgba(0, 0, 0, 0.3)"
        zIndex={1}
        {...rest}
      >
        <Box>
          <CircularProgress size={30} />
        </Box>
      </Box>
    </Fade>
  );
};

export default LoadingOverlay;
