import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import { Groups, GroupsVariables } from '../types/schemaTypes';

export const GROUPS = gql`
  query Groups(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: GroupFilter
  ) {
    groups(
      last: $last
      first: $first
      before: $before
      after: $after
      filter: $filter
    ) {
      data {
        totalCount
        pageInfo {
          endCursor
          startCursor
          hasNextPage
          hasPreviousPage
          endIndex @client
          startIndex @client
        }
        edges {
          cursor
          node {
            id
            name
            description
            lastModified
            groupMembership(first: 5) {
              totalCount
              edges {
                cursor
                node {
                  user {
                    id
                    name
                    picture
                  }
                }
              }
            }
            groupProjectAccess(first: 0) {
              totalCount
            }
          }
        }
      }
      errors
    }
  }
`;

export const useGetLazyGroupsQuery = (
  options?: LazyQueryHookOptions<Groups, GroupsVariables>,
) => {
  return useLazyQuery<Groups, GroupsVariables>(GROUPS, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};

export const useGetGroupsQuery = (
  options?: QueryHookOptions<Groups, GroupsVariables>,
) => {
  return useQuery<Groups, GroupsVariables>(GROUPS, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};
