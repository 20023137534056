import React from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import SNButton from '../SNButton';
import styled from '../../services/styled';
import {
  useShowFiltersDispatch,
  useShowFiltersState,
} from '../../contexts/ShowFiltersContext';
import { useRecoilValue } from 'recoil';
import { isEditingEntitiesAtom } from '../../atoms/editEntities';

const StyledButton = styled(SNButton)`
  min-width: 150px;
`;

const SearchFilterToggle = () => {
  const showFilters = useShowFiltersState();
  const setShowFilters = useShowFiltersDispatch();
  const isEditing = useRecoilValue(isEditingEntitiesAtom);

  const handleClick = React.useCallback(() => {
    setShowFilters((prevState) => !prevState);
  }, [setShowFilters]);

  return (
    <StyledButton
      disabled={isEditing}
      startIcon={<FilterListIcon />}
      onClick={handleClick}
      snVariant="text"
    >
      {showFilters ? 'Hide Filters' : 'Show Filters'}
    </StyledButton>
  );
};

export default SearchFilterToggle;
