import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  ClassifyEntities,
  ClassifyEntitiesVariables,
} from '../types/schemaTypes';

export const CLASSIFY_ENTITIES = gql`
  mutation ClassifyEntities($input: ClassifyEntitiesInput!) {
    classifyEntities(input: $input) {
      data {
        classificationJob {
          id
          type
          state
        }
        group {
          id
          name
          totalCount
          locked
        }
      }
      errors
    }
  }
`;

export const useClassifyEntitiesMutation = (
  options?: MutationHookOptions<ClassifyEntities, ClassifyEntitiesVariables>,
) => {
  return useMutation<ClassifyEntities, ClassifyEntitiesVariables>(
    CLASSIFY_ENTITIES,
    options,
  );
};
