import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  AddItemsToControlledVocabularyCategory,
  AddItemsToControlledVocabularyCategoryVariables,
} from '../types/schemaTypes';

const ADD_TERMS = gql`
  mutation AddItemsToControlledVocabularyCategory(
    $input: AddItemsToControlledVocabularyCategoryInput!
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    addItemsToControlledVocabularyCategory(input: $input) {
      data {
        updatedAt
        name
        items(first: $first, last: $last, before: $before, after: $after) {
          totalCount
          edges {
            node {
              category {
                id
                name
              }
              description
              createdAt
              id
              value
              updatedAt
            }
          }
        }
        id
        description
        createdAt
      }
    }
  }
`;

export const useAddTermsToListMutation = (
  options?: MutationHookOptions<
    AddItemsToControlledVocabularyCategory,
    AddItemsToControlledVocabularyCategoryVariables
  >,
) => {
  return useMutation<
    AddItemsToControlledVocabularyCategory,
    AddItemsToControlledVocabularyCategoryVariables
  >(ADD_TERMS, options);
};
