import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  DuplicateSavedSearch,
  DuplicateSavedSearchVariables,
} from '../types/schemaTypes';

export const DUPLICATE_SAVED_SEARCH = gql`
  mutation DuplicateSavedSearch($input: DuplicateSavedSearchInput!) {
    duplicateSavedSearch(input: $input) {
      data {
        id
      }
      errors
    }
  }
`;

export const useDuplicateSavedSearchMutation = (
  options?: MutationHookOptions<
    DuplicateSavedSearch,
    DuplicateSavedSearchVariables
  >,
) => {
  return useMutation<DuplicateSavedSearch, DuplicateSavedSearchVariables>(
    DUPLICATE_SAVED_SEARCH,
    options,
  );
};
