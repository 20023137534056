import React from 'react';
import { Box, Typography, Grid, Tooltip } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import {
  useSearchTreeRoots,
  useSearchTreeDispatch,
  searchTreeActions,
  useSaveSearchDispatch,
  TabOverflowProvider,
} from '../../contexts';
import SNButton from '../../components/SNButton';
import ReviewBanner from '../../components/ReviewBanner';
import { SearchSaveAdd } from '../../components/SearchSave';
import styled from '../../services/styled';
import { useHistory } from 'react-router-dom';
import { SavedSearchType } from '../../types/schemaTypes';
import { ShowFiltersProvider } from '../../contexts/ShowFiltersContext';
import SearchFilterToggle from '../../components/SearchFilterToggle';
import { unsavedSearchVar } from '../../localState';
import NewSearchForm from '../../components/NewSearchForm';
import { NEW_REVIEW_KEY } from '../../constants';
import ReviewContainer from '../ReviewContainer/ReviewContainer';

const SearchToolbarButton = styled(SNButton)`
  min-width: 150px;
`;

const ReviewNew = () => {
  const rootIds = useSearchTreeRoots(NEW_REVIEW_KEY);
  const dispatch = useSearchTreeDispatch();
  const setSaveSearchState = useSaveSearchDispatch();
  const { push } = useHistory();

  const handleClear = React.useCallback(() => {
    dispatch(
      searchTreeActions.tree.set({
        key: NEW_REVIEW_KEY,
        branches: {},
        roots: [],
      }),
    );
  }, [dispatch]);

  const handleSaveClick = React.useCallback(() => {
    setSaveSearchState('SAVE_AS');
  }, [setSaveSearchState]);

  const handleSaveComplete = React.useCallback(
    (id: string) => {
      unsavedSearchVar(false);
      push(`/review/saved/${id}`);
      handleClear();
    },
    [handleClear, push],
  );

  if (rootIds.length === 0) {
    return <NewSearchForm searchId={NEW_REVIEW_KEY} isReview />;
  }

  return (
    <>
      <ReviewBanner onBack={handleClear} />
      <ShowFiltersProvider>
        <Box position="relative">
          <Box
            display="flex"
            justifyContent="space-between"
            alignContent="center"
            py={2}
            px={4}
          >
            <Tooltip title="Save Review">
              <Box role="button" onClick={handleSaveClick}>
                <Typography variant="h1">Untitled Review</Typography>
              </Box>
            </Tooltip>
            <Box>
              <Grid container spacing={1}>
                <Grid item>
                  <SearchFilterToggle />
                </Grid>
                <Grid item>
                  <SearchToolbarButton
                    snVariant="text"
                    startIcon={<SaveIcon />}
                    onClick={handleSaveClick}
                  >
                    Save Review
                  </SearchToolbarButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <TabOverflowProvider>
            <ReviewContainer searchId={NEW_REVIEW_KEY} />
          </TabOverflowProvider>
          <SearchSaveAdd
            onSaveComplete={handleSaveComplete}
            searchId={NEW_REVIEW_KEY}
            title="Save New Review"
            type={SavedSearchType.REVIEW}
          />
        </Box>
      </ShowFiltersProvider>
    </>
  );
};

export default ReviewNew;
