import gql from 'graphql-tag';

export const EntityTypeFieldsFragment = gql`
  fragment EntityTypeFields on EntityType {
    name
    fields {
      id
      name
      dataPoint {
        dataType
      }
    }
  }
`;
