import { ReactNode } from 'react';
import { useIsUserOwnerOf } from '../../hooks';

interface OwnerOfProjectMaskProps {
  projectId: string;
  children: ReactNode;
}

const OwnerOfProjectMask = ({
  projectId,
  children,
}: OwnerOfProjectMaskProps) => {
  const isOwner = useIsUserOwnerOf(projectId);

  if (isOwner) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default OwnerOfProjectMask;
