import React from 'react';
import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
import SNButton from '../SNButton';
import SNInput from '../SNInput';
import { getMuiVariant } from '../../services/theme';
import { useSaveSearchDispatch } from '../../contexts';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import LoadingOverlay from '../LoadingOverlay';
import SNStatusBanner from '../SNStatusBanner';

export interface SaveSearchFormValues {
  name: string;
  description: string;
  isPublic: boolean;
}

interface SearchSaveProps {
  description: string;
  isPublic: boolean;
  name: string;
  loading: boolean;
  onSubmit: (formValues: SaveSearchFormValues) => void;
  errors?: string[];
}

const SearchSave: React.FC<SearchSaveProps> = ({
  description,
  isPublic,
  name,
  loading,
  onSubmit,
  errors = [],
}) => {
  const [changes, setChanges] = React.useState<SaveSearchFormValues>({
    description,
    isPublic,
    name,
  });

  const setSaveSearchState = useSaveSearchDispatch();

  const handleClose = React.useCallback(() => {
    setSaveSearchState(null);
  }, [setSaveSearchState]);

  const handleTextChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.value;
      setChanges((prevState) => ({
        ...prevState,
        [field]: newValue,
      }));
    };

  const handleToggleButtonChange =
    (field: string) =>
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      value: boolean | null,
    ) => {
      if (value !== null) {
        setChanges((prevState) => ({
          ...prevState,
          [field]: value,
        }));
      }
    };

  const handleSubmit = () => {
    onSubmit(changes);
  };

  return (
    <>
      <DialogContent>
        <Box pb={1}>
          {errors.length > 0 && (
            <Box mb={2}>
              <SNStatusBanner status="error">
                <ul>
                  {errors.map((error) => {
                    return <li key={error}>{error}</li>;
                  })}
                </ul>
              </SNStatusBanner>
            </Box>
          )}
          <Box mb={1}>
            <Typography variant={getMuiVariant('label')} color="textSecondary">
              Type
            </Typography>
          </Box>
          <ToggleButtonGroup
            value={changes.isPublic}
            exclusive
            onChange={handleToggleButtonChange('isPublic')}
          >
            <ToggleButton disableRipple value={false}>
              Private
            </ToggleButton>
            <ToggleButton disableRipple value>
              Public
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box pb={2}>
          <SNInput
            autoFocus
            id="search-name"
            label="Name"
            onChange={handleTextChange('name')}
            value={changes.name}
            fullWidth
          />
        </Box>
        <Box>
          <SNInput
            id="search-description"
            label="Description"
            onChange={handleTextChange('description')}
            value={changes.description}
            multiline
            fullWidth
            rows={3}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <SNButton snVariant="light" onClick={handleClose}>
          Cancel
        </SNButton>
        <SNButton
          snVariant="primary"
          onClick={handleSubmit}
          type="submit"
          disabled={loading}
        >
          Save
        </SNButton>
      </DialogActions>
      <LoadingOverlay isLoading={loading} />
    </>
  );
};

export default SearchSave;
