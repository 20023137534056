import React from 'react';
import styled from '../../services/styled';
import { Checkbox as MuiCheckbox, CheckboxProps, alpha } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';

const UncheckedBoxIcon = styled('div')<CheckboxProps>`
  background: transparent;
  border: 1px solid
    ${({ disabled, theme }) =>
      disabled ? theme.palette.text.disabled : theme.palette.text.primary};
  border-radius: 2px;
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  pointer-events: none;
`;

const CheckedBoxIcon = styled('div')<CheckboxProps>`
  background-color: ${({ disabled, theme }) =>
    disabled
      ? alpha(theme.palette.text.disabled, 0.3)
      : alpha(theme.palette.text.primary, 0.3)};
  border: 1px solid
    ${({ disabled, theme }) =>
      disabled ? theme.palette.text.disabled : theme.palette.text.primary};
  border-radius: 2px;
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  pointer-events: none;
`;

const StyledCheckIcon = styled(CheckIcon)<CheckboxProps>`
  color: ${({ theme }) => theme.palette.text.primary};
  height: 14px;
  width: 14px;
`;

const StyledRemoveIcon = styled(RemoveIcon)`
  color: ${({ theme }) => theme.palette.text.primary};
  height: 14px;
  width: 14px;
`;

const Checkbox = (props: CheckboxProps) => {
  return (
    <MuiCheckbox
      icon={<UncheckedBoxIcon disabled={props.disabled} />}
      checkedIcon={
        <CheckedBoxIcon disabled={props.disabled}>
          <StyledCheckIcon />
        </CheckedBoxIcon>
      }
      indeterminateIcon={
        <CheckedBoxIcon disabled={props.disabled}>
          <StyledRemoveIcon />
        </CheckedBoxIcon>
      }
      {...props}
    />
  );
};

export default Checkbox;
