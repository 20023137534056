import { Draft } from 'immer';
import { SearchTreeBranch } from '../types';
import { Connective, JoinType, SearchTargetType } from '../types/schemaTypes';
import { entityTypes } from './wellKnownDataTypes';
import cuid from 'cuid';

export const ROOT_FILTER_GROUP_ID = 'sn:rootFilter';
export const NEW_FILTER_LEAF_ID = cuid();
export const NEW_SEARCH_KEY = 'search-new';
export const NEW_REVIEW_KEY = 'review-new';

export const SEARCH_QUERY_BRANCH_DEFAULT: Draft<SearchTreeBranch> = {
  children: [],
  filter: {
    rootFilterId: ROOT_FILTER_GROUP_ID,
    filterBranches: {
      [ROOT_FILTER_GROUP_ID]: {
        parent: '',
        children: [NEW_FILTER_LEAF_ID],
        connective: Connective.AND,
      },
    },
    filterLeaves: {
      [NEW_FILTER_LEAF_ID]: {
        parent: ROOT_FILTER_GROUP_ID,
        type: 'STRING',
        value: [
          {
            value: '',
            type: 'STRING',
          },
        ],
        connective: Connective.AND,
        comparator: 'INCLUDES',
        metadata: {
          caseSensitive: false,
        },
        fieldId: 'system.displayName',
      },
    },
  },
  joinData: {
    joinRequired: true,
    joinDistance: 1,
    joinType: JoinType.OUTBOUND,
  },
  parent: '',
  select: true,
  target: {
    type: SearchTargetType.ENTITY_TYPE,
    id: entityTypes.any,
  },
};

export const SEARCH_FORM_STATE_DEFAULT: SearchTreeBranch = {
  children: [],
  filter: {
    rootFilterId: ROOT_FILTER_GROUP_ID,
    filterBranches: {
      [ROOT_FILTER_GROUP_ID]: {
        parent: '',
        children: [NEW_FILTER_LEAF_ID],
        connective: Connective.AND,
      },
    },
    filterLeaves: {
      [NEW_FILTER_LEAF_ID]: {
        parent: ROOT_FILTER_GROUP_ID,
        type: 'STRING',
        value: [
          {
            value: '',
            type: 'STRING',
          },
        ],
        connective: Connective.AND,
        comparator: 'INCLUDES',
        metadata: {
          caseSensitive: false,
        },
        fieldId: 'system.displayName',
      },
    },
  },
  joinData: {
    joinRequired: true,
    joinDistance: 1,
    joinType: JoinType.OUTBOUND,
  },
  parent: '',
  select: true,
  target: {
    type: SearchTargetType.ENTITY_TYPE,
    id: entityTypes.any,
  },
};
