import { EditEntitiesState } from '../contexts';

export const editContextToMutationTransformer = (
  editState: EditEntitiesState,
  typeId: string,
) => {
  const ids = Object.keys(editState.edits);
  const editedEntities = ids.map((id) => {
    const entityFieldIds = Object.keys(editState.edits[id]);
    const fieldInputs = entityFieldIds.map((fieldId) => {
      return {
        fieldDefinitionId: fieldId,
        value: editState.edits[id][fieldId],
      };
    });
    return {
      id: id,
      typeId: typeId,
      fields: fieldInputs,
    };
  });
  return editedEntities;
};
