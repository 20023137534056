import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from '../../services/styled';
import { Typography, ButtonBase, Box, Grid } from '@mui/material';
import { useUploadWizardState } from '../../contexts';
import GridOnIcon from '@mui/icons-material/GridOn';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LinkIcon from '@mui/icons-material/Link';
import ListIcon from '@mui/icons-material/List';
import { Link as RouterLink } from 'react-router-dom';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

interface StyledNavLinkProps {
  active: boolean;
}
const StyledNavLink = styled(ButtonBase)<StyledNavLinkProps>`
  color: ${({ active, theme }) =>
    active ? theme.palette.text.primary : theme.palette.text.secondary};
  border-bottom: ${({ active, theme }) =>
    active
      ? `${theme.palette.primary.main} solid 3px`
      : 'transparent solid 3px'};
  padding: 12px;
  :disabled {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  :focus {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;
const StyledRouterLink = styled(RouterLink)`
  text-decoration: none;
`;

interface UploadModalTabsProps {
  showSheetsTab: boolean;
}

const UploadModalTabs = ({ showSheetsTab }: UploadModalTabsProps) => {
  const { pathname } = useLocation();
  const { skipAllQuestions } = useUploadWizardState();

  return (
    <Box height={88} width="100%" display="flex" alignItems="flex-end">
      <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
        <Grid item>
          <StyledRouterLink
            to={skipAllQuestions ? '/projects/content' : '/projects/question'}
          >
            <StyledNavLink active={pathname.includes('/projects')}>
              <Box pr={1} display="flex" alignItems="center">
                <LibraryBooksIcon fontSize="small" />
              </Box>
              <Typography variant="h4">Projects</Typography>
            </StyledNavLink>
          </StyledRouterLink>
        </Grid>
        {showSheetsTab && (
          <Grid item>
            <StyledRouterLink
              to={skipAllQuestions ? '/sheets/content' : '/sheets/question'}
            >
              <StyledNavLink active={pathname.includes('/sheets')}>
                <Box pr={1} display="flex" alignItems="center">
                  <GridOnIcon fontSize="small" />
                </Box>
                <Typography variant="h4">Sheets</Typography>
              </StyledNavLink>
            </StyledRouterLink>
          </Grid>
        )}
        <Grid item>
          <StyledRouterLink
            to={skipAllQuestions ? '/metadata/content' : '/metadata/question'}
          >
            <StyledNavLink active={pathname.includes('/metadata')}>
              <Box pr={1} display="flex" alignItems="center">
                <LocalOfferIcon fontSize="small" />
              </Box>
              <Typography variant="h4">Metadata</Typography>
            </StyledNavLink>
          </StyledRouterLink>
        </Grid>
        <Grid item>
          <StyledRouterLink
            to={skipAllQuestions ? '/context/content' : '/context/question'}
          >
            <StyledNavLink active={pathname.includes('/context')}>
              <Box pr={1} display="flex" alignItems="center">
                <LinkIcon fontSize="small" />
              </Box>
              <Typography variant="h4">Context</Typography>
            </StyledNavLink>
          </StyledRouterLink>
        </Grid>
        <Grid item>
          <StyledRouterLink
            to={skipAllQuestions ? '/linking/content' : '/linking/question'}
          >
            <StyledNavLink active={pathname.includes('/linking')}>
              <Box pr={1} display="flex" alignItems="center">
                <LinkIcon fontSize="small" />
              </Box>
              <Typography variant="h4">Linking</Typography>
            </StyledNavLink>
          </StyledRouterLink>
        </Grid>
        <Grid item>
          <StyledRouterLink to="/summary">
            <StyledNavLink active={pathname.includes('/summary')}>
              <Box pr={1} display="flex" alignItems="center">
                <ListIcon fontSize="small" />
              </Box>
              <Typography variant="h4">Summary</Typography>
            </StyledNavLink>
          </StyledRouterLink>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UploadModalTabs;
