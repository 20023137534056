import cuid from 'cuid';
import { Draft } from 'immer';

import { useSearchTreeDispatch, searchTreeActions } from '../contexts';
import { SearchTreeBranch } from '../types';
import { SEARCH_QUERY_BRANCH_DEFAULT } from '../constants/searchQueries';

const useBranchAdd = (searchId: string) => {
  const dispatch = useSearchTreeDispatch();

  return (changes: Draft<Partial<Omit<SearchTreeBranch, 'filter'>>>) => {
    const newNodeId = cuid();
    const newRootFilterId = cuid();

    dispatch(
      searchTreeActions.branch.add({
        key: searchId,
        id: newNodeId,
        value: {
          ...SEARCH_QUERY_BRANCH_DEFAULT,
          ...changes,
        },
      }),
    );
    return [newNodeId, newRootFilterId];
  };
};

export default useBranchAdd;
