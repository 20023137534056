import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useReviewSummaryQuery } from '../../queries';
import LoadingOverlay from '../LoadingOverlay';
import DashboardPieChart from '../DashboardPieChart';
import TopList from '../TopList';
import styled from '../../services/styled';
import { capitalize } from '../../utils/capitalize';
import { round } from '../../utils/round';

const ResizingBox = styled(Box)`
  width: 1210px;
  display: flex;
  flex-direction: column;
  margin: 24px;
  @media (max-width: 1280px) {
    max-width: 593px;
  }
`;

const QualityReviewDashboard = () => {
  const { loading, data } = useReviewSummaryQuery();

  const totalErrors =
    data?.reviewSummary?.statusCounts?.reduce((acc, current) => {
      if (current.count) {
        return acc + current.count;
      }
      return acc;
    }, 0) || 0;

  return (
    <Box display="flex" justifyContent="center">
      <ResizingBox>
        <Grid container spacing={3}>
          {data?.reviewSummary && (
            <Grid item xs={6} lg={3}>
              <Box
                bgcolor="#000000"
                height={245}
                width="100%"
                display="flex"
                flexDirection="column"
                p={3}
              >
                <Box mb={1}>
                  <Typography variant="h4">Quality Overview</Typography>
                </Box>
                <Typography
                  component="div"
                  variant="h1"
                  data-testid="total-count"
                >
                  {data?.reviewSummary?.totalCount || 0}
                </Typography>
                <Box mb={3}>
                  <Box mb={3}>
                    <Typography variant="h3" color="textSecondary">
                      Records in review
                    </Typography>
                  </Box>
                  <Typography variant="h2">
                    {totalErrors}&nbsp;(
                    {data?.reviewSummary?.totalCount &&
                      round(
                        (totalErrors / data?.reviewSummary?.totalCount) * 100,
                        0,
                      )}
                    %)
                  </Typography>
                  <Typography
                    variant="h3"
                    color="textSecondary"
                    sx={{ fontSize: '0.6875rem' }}
                  >
                    Include errors
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}
          {data?.reviewSummary?.statusCounts?.map((status, index) => (
            <Grid item key={index} xs={6} lg={3}>
              <DashboardPieChart
                label={capitalize(status.status)}
                total={data?.reviewSummary?.totalCount || 0}
                value={status.count}
                status={status.status}
              />
            </Grid>
          ))}
        </Grid>
        <Box pt={3}>
          <Grid container spacing={3}>
            {data?.reviewSummary?.reviewSummaryIssuesLists?.map(
              (list, index) => (
                <Grid item key={index} xs={12} lg={6}>
                  <TopList title={list.title} items={list.items} />
                </Grid>
              ),
            )}
          </Grid>
        </Box>
      </ResizingBox>
      {loading && (
        <LoadingOverlay isLoading={loading} data-testid="dashboard-loading" />
      )}
    </Box>
  );
};

export default QualityReviewDashboard;
