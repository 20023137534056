import React from 'react';
import {
  CustomCell,
  CustomRenderer,
  GridCellKind,
  ProvideEditorCallback,
} from '@glideapps/glide-data-grid';
import { AnnotationSeverity } from '../../types/schemaTypes';
import { Box } from '@mui/material';
import { CellInput, drawTextCell } from './CustomCells';

interface AnnotatedTextCellProps {
  readonly kind: 'annotated-text-cell';
  readonly displayStatus: AnnotationSeverity | null;
  readonly displayValue: string;
  readonly type?: 'text' | 'number';
}

type AnnotatedTextCell = CustomCell<AnnotatedTextCellProps>;
export type { AnnotatedTextCell as AnnotatedTextCellType };

const Editor: ReturnType<ProvideEditorCallback<AnnotatedTextCell>> = (
  props,
) => {
  const { value, onChange } = props;

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const text = value.data.displayValue;
  const type = value.data.type;

  React.useEffect(() => {
    const ta = inputRef.current;
    if (ta === null) return;

    if (ta.disabled) return;
    ta.focus();
    ta.select();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box minHeight={59} px={2} alignItems="center" display="flex">
      <CellInput
        ref={inputRef}
        autoFocus
        value={text}
        type={type}
        onChange={(e) =>
          onChange({
            ...value,
            data: {
              ...value.data,
              displayValue: e.target.value,
            },
          })
        }
      />
    </Box>
  );
};

const renderer: CustomRenderer<AnnotatedTextCell> = {
  isMatch: (cell: CustomCell): cell is AnnotatedTextCell =>
    (cell.data as any).kind === 'annotated-text-cell',
  draw: (args, cell) => {
    const { data, readonly } = cell;
    const { displayStatus: status, displayValue: value } = data;
    return drawTextCell(args, { value, status, readonly });
  },
  provideEditor: () => ({
    editor: Editor,
    disablePadding: true,
  }),
  onPaste: (val, cellData) => ({
    ...cellData,
    displayValue: val,
  }),
  kind: GridCellKind.Custom,
};

export default renderer;
