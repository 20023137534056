import gql from 'graphql-tag';
import { SearchEntity } from '../fragments';
import { useQuery } from '@apollo/client';
import { EntitiesById, EntitiesById_entitiesById } from '../types/schemaTypes';

const ENTITIES_BY_ID = gql`
  query EntitiesById($ids: [String!]!) {
    entitiesById(ids: $ids) @client {
      ...SearchEntity
    }
  }
  ${SearchEntity}
`;

export const emptyEntitiesById: EntitiesById_entitiesById[] = [];

export const useEntitiesByIdQuery = (entityIds: string[]) => {
  return useQuery<EntitiesById>(ENTITIES_BY_ID, {
    variables: {
      ids: entityIds,
    },
  });
};
