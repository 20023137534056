import { Dialog } from '@mui/material';
import React from 'react';
import {
  useSaveSearchDispatch,
  useSaveSearchState,
  useUserState,
} from '../../contexts';
import { useGetSavedSearchQuery } from '../../queries';
import SearchSave, { SaveSearchFormValues } from './SearchSave';
import cuid from 'cuid';
import { useDuplicateSavedSearchMutation } from '../../mutations';
import { createCacheInvalidator } from '../../utils/invalidateCache';
import { SavedSearchType } from '../../types/schemaTypes';
import { useSetRecoilState } from 'recoil';
import { selectAllResultsSelector } from '../../atoms/listSelection';
import SNDialogTitle from '../SNDialogTitle';
import { collectErrors } from '../../utils/collectErrors';

interface SearchSaveEditProps {
  id: string;
  refetch: () => void;
  title?: string;
  type?: SavedSearchType;
}

const SearchSaveDuplicate: React.FC<SearchSaveEditProps> = ({
  id,
  refetch,
  title = 'Duplicate Search',
  type = SavedSearchType.SEARCH,
}) => {
  const saveType = useSaveSearchState();
  const user = useUserState();
  const setSaveSearchState = useSaveSearchDispatch();
  const setSelectAll = useSetRecoilState(selectAllResultsSelector);
  const [duplicateSavedSearch, { loading, data: duplicateData }] =
    useDuplicateSavedSearchMutation({
      onCompleted: () => {
        setSelectAll(false);
        refetch();
        setSaveSearchState(null);
      },
      update: createCacheInvalidator(/^SavedSearch/),
    });

  const { data } = useGetSavedSearchQuery({ variables: { id } });

  const handleClose = React.useCallback(() => {
    setSaveSearchState(null);
  }, [setSaveSearchState]);

  const handleSubmit = async ({
    description,
    isPublic,
    name,
  }: SaveSearchFormValues) => {
    duplicateSavedSearch({
      variables: {
        input: {
          newId: cuid(),
          oldId: id,
          name,
          description,
          public: isPublic,
          type,
          userId: user.userId,
          userName: user.name,
        },
      },
    });
  };

  const collectedErrors = React.useMemo(() => {
    return collectErrors([
      duplicateData?.duplicateSavedSearch?.errors,
      data?.getSavedSearch.errors,
    ]);
  }, [duplicateData, data]);

  if (!data || !data.getSavedSearch.data) return null;

  const { name, description, public: isPublic } = data.getSavedSearch.data;

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={saveType === 'COPY'}
      onClose={handleClose}
    >
      <SNDialogTitle onClose={handleClose}>{title}</SNDialogTitle>
      <SearchSave
        loading={loading}
        onSubmit={handleSubmit}
        name={name}
        description={description || ''}
        isPublic={isPublic}
        errors={collectedErrors}
      />
    </Dialog>
  );
};

export default SearchSaveDuplicate;
