import { Box, MenuItem, Radio, RadioGroup, Typography } from '@mui/material';
import React from 'react';
import {
  ClassificationTypeMatch as ClassificationTypeMatchInterface,
  EntityTypeFields,
} from '../../types/schemaTypes';
import { useRecoilState } from 'recoil';
import { ClassificationWizardFormInfo } from '../../atoms/ClassificationWizardFormInfo';
import { useApolloClient } from '@apollo/client';
import SNInput from '../SNInput';
import { ClassificationTypeMatch } from '../../fragments/ClassificationTypeMatch';
import Checkbox from '../Checkbox';
import { EntityTypeFieldsFragment } from '../../fragments/EntityTypeFields';

interface ClassificationQuestionRepairExistingColumnsProps {
  useSuggestions: Record<string, boolean>;
  showErrors: boolean;
  setUseSuggestions: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
}

const ClassificationQuestionRepairExistingColumns = ({
  useSuggestions,
  showErrors,
  setUseSuggestions,
}: ClassificationQuestionRepairExistingColumnsProps) => {
  const [classificationWizardFormInfo, setClassificationWizardFormInfo] =
    useRecoilState(ClassificationWizardFormInfo);

  const client = useApolloClient();
  const selectedEntityTypeFieldInfo = client.readFragment<EntityTypeFields>({
    id: `EntityType:${classificationWizardFormInfo.selectedTypeId}`,
    fragment: EntityTypeFieldsFragment,
  });
  const typeMatch = client.readFragment<ClassificationTypeMatchInterface>({
    id: client.cache.identify({
      __typename: 'UnclassifiedTypeMatch',
      entityType: {
        id: classificationWizardFormInfo.selectedTypeId,
      },
    }),
    fragment: ClassificationTypeMatch,
  });

  const defaultFieldOptions = selectedEntityTypeFieldInfo?.fields.map(
    (field) => {
      return {
        confidence: 0,
        fieldDefinitionId: field.id,
        name: field.name,
      };
    },
  );

  const getUnlockedOptions = React.useCallback(
    (columnName: string, useSuggestions: boolean) => {
      const invalidColumn = typeMatch?.invalidColumns.find(
        (invalidColumn) => invalidColumn.header === columnName,
      );
      if (useSuggestions) {
        return invalidColumn?.suggestedColumns;
      } else {
        return selectedEntityTypeFieldInfo?.fields.map((field) => {
          return {
            confidence:
              invalidColumn?.suggestedColumns.find(
                (suggestedColumn) =>
                  suggestedColumn.fieldDefinitionId === field.id,
              )?.confidence || 0,
            fieldDefinitionId: field.id,
            name: field.name,
          };
        });
      }
    },
    [selectedEntityTypeFieldInfo?.fields, typeMatch?.invalidColumns],
  );

  const getFieldStatus = React.useCallback(
    (targetFieldDefinitionId?: string) => {
      if (showErrors && !targetFieldDefinitionId) return 'error';
      if (
        classificationWizardFormInfo.columnsToRename.filter(
          (columnToRename) =>
            columnToRename.targetFieldDefinitionId === targetFieldDefinitionId,
        ).length > 1
      ) {
        return 'info';
      }
      return undefined;
    },
    [classificationWizardFormInfo.columnsToRename, showErrors],
  );

  const getHelperText = React.useCallback(
    (targetFieldDefinitionId?: string) => {
      if (showErrors && !targetFieldDefinitionId) {
        return 'You must select a column name';
      }
      if (
        classificationWizardFormInfo.columnsToRename.filter(
          (columnToRename) =>
            columnToRename.targetFieldDefinitionId === targetFieldDefinitionId,
        ).length > 1
      ) {
        return `The column ${
          defaultFieldOptions?.find(
            (col) => col.fieldDefinitionId === targetFieldDefinitionId,
          )?.name
        } has already been used in this data set. Multiple columns with matching names will be merged.`;
      }
      return undefined;
    },
    [
      classificationWizardFormInfo.columnsToRename,
      defaultFieldOptions,
      showErrors,
    ],
  );

  const handleColumnChoice = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClassificationWizardFormInfo((prev) => {
      // first, remove header from any previously selected options
      let alteredDelete = prev.columnsToDelete.filter(
        (header: string) => header !== e.target.name,
      );
      let alteredMetadata = prev.columnsAsMetadata.filter(
        (header: string) => header !== e.target.name,
      );
      let alteredRename = prev.columnsToRename.filter(
        (renameObject: {
          sourceColumnName: string;
          targetFieldDefinitionId: string;
        }) => renameObject.sourceColumnName !== e.target.name,
      );
      // then, depending on what we've chosen, put the column back into the forminfo in the right place.
      if (e.target.value === 'delete') {
        alteredDelete = [...alteredDelete, e.target.name];
      }
      if (e.target.value === 'metadata') {
        alteredMetadata = [...alteredMetadata, e.target.name];
      }
      if (e.target.value === 'rename') {
        setUseSuggestions((prev) => ({
          ...prev,
          [e.target.name]: true,
        }));
        const invalidColumn = typeMatch?.invalidColumns.find(
          (invalidColumn) => invalidColumn.header === e.target.name,
        );
        alteredRename = [
          ...alteredRename,
          {
            sourceColumnName: e.target.name,
            targetFieldDefinitionId:
              invalidColumn && invalidColumn.suggestedColumns.length > 0
                ? invalidColumn.suggestedColumns[0].fieldDefinitionId
                : '',
          },
        ];
      }

      const missingColumns = typeMatch?.missingColumns.filter((missingCol) => {
        let renamedMatch = false;
        classificationWizardFormInfo.columnsToRename.forEach((renamedCol) => {
          if (
            !renamedMatch &&
            renamedCol.targetFieldDefinitionId === missingCol.fieldDefinitionId
          ) {
            renamedMatch = true;
          }
        });
        if (!renamedMatch) {
          return missingCol;
        }
      });

      const missingColValues = missingColumns?.map((missingColumn) => ({
        fieldDefinitionId: missingColumn.fieldDefinitionId,
        value: '',
      }));

      return {
        ...prev,
        columnsToDelete: alteredDelete,
        columnsToRename: alteredRename,
        columnsAsMetadata: alteredMetadata,
        missingColumns: missingColValues || [],
      };
    });
  };

  const handleRenameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setClassificationWizardFormInfo((prev) => {
      const otherChangedCols = prev.columnsToRename.filter(
        (col) => col.sourceColumnName !== e.target.name,
      );
      return {
        ...prev,
        columnsToRename: [
          ...otherChangedCols,
          {
            sourceColumnName: e.target.name,
            targetFieldDefinitionId: e.target.value,
          },
        ],
      };
    });
  };

  const getRenameValue = React.useCallback(
    (columnName: string, checked: boolean, currentValue: string) => {
      const invalidColumn = typeMatch?.invalidColumns.find(
        (invalidColumn) => invalidColumn.header === columnName,
      );

      if (checked && invalidColumn) {
        const suggestedIds = invalidColumn.suggestedColumns.map(
          ({ fieldDefinitionId }) => fieldDefinitionId,
        );
        if (currentValue && suggestedIds.includes(currentValue)) {
          return currentValue;
        }
        if (invalidColumn.suggestedColumns.length > 0) {
          return invalidColumn.suggestedColumns[0].fieldDefinitionId;
        }
      }
      if (!checked) {
        if (currentValue) return currentValue;
        if (defaultFieldOptions && defaultFieldOptions.length > 0) {
          return defaultFieldOptions[0].fieldDefinitionId;
        }
      }
      return '';
    },
    [defaultFieldOptions, typeMatch?.invalidColumns],
  );

  const handleUseSuggestionsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setUseSuggestions((prev) => ({ ...prev, [event.target.name]: checked }));
    setClassificationWizardFormInfo((prev) => ({
      ...prev,
      columnsToRename: prev.columnsToRename.map((columnToRename) =>
        columnToRename.sourceColumnName === event.target.name
          ? {
              sourceColumnName: event.target.name,
              targetFieldDefinitionId: getRenameValue(
                event.target.name,
                checked,
                columnToRename.targetFieldDefinitionId,
              ),
            }
          : columnToRename,
      ),
    }));
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="h3">Step 2: Invalid Columns</Typography>
      </Box>
      <Typography>
        The following columns are invalid. You must delete or rename the columns
        before proceeding. You may also choose to capture these columns as
        metadata.
      </Typography>
      {typeMatch?.invalidColumns.map((invalidColumn, index) => {
        const value = classificationWizardFormInfo.columnsAsMetadata.includes(
          invalidColumn.header,
        )
          ? 'metadata'
          : classificationWizardFormInfo.columnsToDelete.includes(
              invalidColumn.header,
            )
          ? 'delete'
          : 'rename';
        const column = classificationWizardFormInfo?.columnsToRename.find(
          (columnToRename) =>
            columnToRename.sourceColumnName === invalidColumn.header,
        );

        return (
          <Box key={invalidColumn.header} mt={4}>
            <Typography variant="h5">
              {index + 1}. {invalidColumn.header}
            </Typography>
            <Box mt={1} ml={1}>
              <RadioGroup
                value={value}
                name={invalidColumn.header}
                onChange={handleColumnChoice}
              >
                <Box display="flex" alignItems="center" component="label">
                  <Radio value="metadata" />
                  <Typography>Capture column as metadata</Typography>
                </Box>
                <Box display="flex" alignItems="center" component="label">
                  <Radio value="delete" />
                  <Typography>Delete column</Typography>
                </Box>
                <Box display="flex" alignItems="center" component="label">
                  <Radio value="rename" />
                  <Typography>Rename column to...</Typography>
                </Box>
                {value === 'rename' && (
                  <Box pl="38px">
                    <SNInput
                      label="Column Name"
                      fullWidth
                      select
                      size="small"
                      onChange={handleRenameChange}
                      name={invalidColumn.header}
                      value={column?.targetFieldDefinitionId || ''}
                      displayEmpty
                      error={showErrors && !column?.targetFieldDefinitionId}
                      status={getFieldStatus(column?.targetFieldDefinitionId)}
                      helperText={getHelperText(
                        column?.targetFieldDefinitionId,
                      )}
                    >
                      {getUnlockedOptions(
                        invalidColumn.header,
                        useSuggestions[invalidColumn.header],
                      )?.map(({ confidence, fieldDefinitionId, name }) => (
                        <MenuItem
                          key={fieldDefinitionId}
                          value={fieldDefinitionId}
                        >
                          {name}
                          {confidence > 0 &&
                            ` - ${confidence * 100}% confidence`}
                        </MenuItem>
                      ))}
                      {useSuggestions[invalidColumn.header] &&
                        invalidColumn.suggestedColumns.length === 0 && (
                          <MenuItem disabled value="">
                            No matches with &gt;50% match
                          </MenuItem>
                        )}
                    </SNInput>
                    <Box
                      component="label"
                      display="flex"
                      alignItems="center"
                      ml="-9px"
                      mt={0.5}
                    >
                      <Checkbox
                        name={invalidColumn.header}
                        checked={useSuggestions[invalidColumn.header]}
                        onChange={handleUseSuggestionsChange}
                      />
                      <Typography ml={1}>
                        Limit to values with &gt;50% match
                      </Typography>
                    </Box>
                  </Box>
                )}
              </RadioGroup>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default ClassificationQuestionRepairExistingColumns;
