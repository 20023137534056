import React from 'react';
import { DialogTitle, Typography, Box, DialogContent } from '@mui/material';
import FileUpload from '../FileUpload';
import styled from '../../services/styled';
import { Route, Switch, useHistory } from 'react-router-dom';
import UploadModalSheetSelection from '../UploadModalSheetSelection';
import UploadModalMetadataCollection from '../UploadModalMetadataCollection';
import UploadModalActions from '../UploadModalActions';
import UploadModalTabs from '../UploadModalTabs';
import UploadModalQuestion from '../UploadModalQuestion';
import {
  useUploadWizardDispatch,
  initialUploadWizardState,
  useUploadWizardState,
  useUserState,
} from '../../contexts';
import UploadModalFileList from '../UploadModalFileList';
import UploadModalLinking from '../UploadModalLinking';
import UploadModalContext from '../UploadModalContext';
import UploadModalGuidePrompt from '../UploadModalGuidePrompt';
import { useUpdateUserPreferences } from '../../mutations';
import UploadModalSummary from '../UploadModalSummary';
import UploadModalProjectSelection from '../UploadModalProjectSelection';
import DialogCloseButton from '../DialogCloseButton';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  hasSubsetsSelector,
  uploadFilesCountSelector,
} from '../../atoms/uploadFiles';
import { useGetProjectByIdQuery } from '../../queries/useGetProjectByIdQuery';
import { defaultProjectId } from '../../constants';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { collectErrors } from '../../utils/collectErrors';

interface UploadModalProps {
  onClose: () => void;
  onFilesAccept: (files: File[]) => void;
}

const UploadDialogTitle = styled(DialogTitle)`
  padding: 0;
`;

const UploadModal = ({ onClose, onFilesAccept }: UploadModalProps) => {
  const history = useHistory();
  const user = useUserState();
  const [mutation] = useUpdateUserPreferences();
  const wizardState = useUploadWizardState();
  const setUploadWizardState = useUploadWizardDispatch();
  const [skipPrompt, setSkipPrompt] = React.useState(false);
  const { data } = useGetProjectByIdQuery({
    variables: { id: defaultProjectId },
  });
  const defaultProjectName = React.useMemo(() => {
    return data?.project?.project?.name;
  }, [data]);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);

  const hasSubsets = useRecoilValue(hasSubsetsSelector);

  const fileCount = useRecoilValue(uploadFilesCountSelector);

  const handleClose = React.useCallback(() => {
    onClose();
    history.replace('/files');
    setUploadWizardState(initialUploadWizardState);
  }, [history, onClose, setUploadWizardState]);

  const handleSkipPromptChange = (checked: boolean) => {
    setSkipPrompt(checked);
  };

  const handleGuidePromptOptOut = React.useCallback(() => {
    mutation({
      variables: {
        input: {
          id: user.userId,
          preferences: {
            uploadWizardSkipAllQuestions: wizardState.skipAllQuestions,
            uploadWizardSkipAllQuestionsPrompt: skipPrompt,
          },
        },
      },
    });
  }, [mutation, skipPrompt, user, wizardState]);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.project?.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error fetching default project',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <>
      <UploadDialogTitle>
        <Switch>
          <Route path="/files">
            <Box width="100%">
              <FileUpload onFilesAccept={onFilesAccept} />
            </Box>
          </Route>
          <Route path="/guide" />
          <Route path="/">
            <UploadModalTabs showSheetsTab={hasSubsets} />
          </Route>
        </Switch>
        <Box position="absolute" top={29} left={35}>
          <Typography variant="h2">Upload</Typography>
        </Box>
        <DialogCloseButton onClose={handleClose} size="large" />
      </UploadDialogTitle>
      <DialogContent dividers>
        <Route path="/files">
          <Box display="flex" justifyContent="center">
            <Box py={5} width={580}>
              <Typography variant="h2">
                {fileCount} file{fileCount > 1 && 's'} selected
              </Typography>
              <UploadModalFileList />
            </Box>
          </Box>
        </Route>
        <Route path="/guide">
          <UploadModalGuidePrompt
            onChange={handleSkipPromptChange}
            skipPrompt={skipPrompt}
          />
        </Route>
        <Route path="/projects/question">
          <UploadModalQuestion
            question="Would you like to add these files to a project?"
            details={
              <Box>
                All data is automatically added to the&nbsp;
                <Box display="inline" sx={{ fontWeight: 900 }}>
                  {defaultProjectName}
                </Box>
                &nbsp;project. In this step, you can also add files to
                additional projects
              </Box>
            }
            field="addProjects"
          />
        </Route>
        <Route path="/projects/content">
          <Box display="flex" justifyContent="center" py={5}>
            <Box width={580}>
              <Typography variant="h2">Add this data to projects:</Typography>
              <UploadModalProjectSelection />
            </Box>
          </Box>
        </Route>
        <Route path="/metadata/question">
          <UploadModalQuestion
            question="Would you like to add metadata to these files?"
            details="You can add searchable metadata tags to the records
                    extracted from these files if you like. These can be notes
                    about these records, a personal classification system, or
                    anything else you might want to add. You can do this later
                    if you want, so no pressure."
            field="addMetadata"
          />
        </Route>
        <Route path="/sheets/question">
          <UploadModalQuestion
            question="Do you want to upload all sheets within these files?"
            details="You can specify which of the sheets in these documents you
                    want to upload and which you don't. This is useful if
                    these documents have sheets that are not data that you
                    don't want to upload. If you select yes, we'll just
                    upload all the sheets for you."
            field="uploadAllSheets"
          />
        </Route>
        <Route path="/sheets/content">
          <Box display="flex" justifyContent="center" py={5}>
            <Box width={580}>
              <Typography variant="h2">
                Select the sheets you’d like to upload:
              </Typography>
              <UploadModalSheetSelection />
            </Box>
          </Box>
        </Route>
        <Route path="/metadata/content">
          <Box display="flex" justifyContent="center" py={5}>
            <Box width={580}>
              <Typography variant="h2">Add Metadata</Typography>
              <UploadModalMetadataCollection />
            </Box>
          </Box>
        </Route>
        <Route path="/context/question">
          <UploadModalQuestion
            question="Do the files you’re uploading contain related data?"
            details=""
            field="createContext"
          />
        </Route>
        <Route path="/context/content">
          <Box display="flex" justifyContent="center" py={5}>
            <Box width="80%" maxWidth={600} minWidth={580}>
              <Typography variant="h2">Define Linking Context</Typography>
              <Box pt={2}>
                <Typography color="textSecondary">
                  These documents represent a context for finding links.
                </Typography>
              </Box>
              <UploadModalContext />
            </Box>
          </Box>
        </Route>
        <Route path="/linking/question">
          <UploadModalQuestion
            question="Do the files you’re uploading relate to data that’s already in the platform?"
            details=""
            field="linkData"
          />
        </Route>
        <Route path="/linking/content">
          <Box display="flex" justifyContent="center" py={5}>
            <Box width="80%" maxWidth={1052} minWidth={580}>
              <Typography variant="h2">Link Data</Typography>
              <UploadModalLinking />
            </Box>
          </Box>
        </Route>
        <Route path="/summary">
          <Box display="flex" justifyContent="center">
            <Box py={5} width={580}>
              <Typography variant="h2">Summary</Typography>
              <UploadModalSummary />
            </Box>
          </Box>
        </Route>
      </DialogContent>
      <UploadModalActions
        hasSubsets={hasSubsets}
        onUpload={handleClose}
        onGuidePromptOptOut={handleGuidePromptOptOut}
      />
    </>
  );
};

export default UploadModal;
