import { Box, ListItemText, MenuItem, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import Dropdown from '../Dropdown';
import Checkbox from '../Checkbox';
import { EntityHistoryEventType } from '../../types/schemaTypes';

interface Option {
  primary: string;
  secondary?: string;
  value: EntityHistoryEventType;
}

const options: Option[] = [
  {
    primary: 'Links',
    secondary:
      'An event representing the addition or removal of one or more links',
    value: EntityHistoryEventType.LINKING,
  },
  {
    primary: 'Delete',
    secondary: 'An event representing the deletion of this entity',
    value: EntityHistoryEventType.DELETE,
  },
  {
    primary: 'Edits',
    secondary: 'An event representing a change to one or more data fields',
    value: EntityHistoryEventType.EDIT,
  },
  {
    primary: 'Upload',
    secondary: 'An event representing an initial upload of data',
    value: EntityHistoryEventType.CREATE,
  },
  {
    primary: 'Approvals',
    secondary:
      'An event representing the approval of data that has not been validated',
    value: EntityHistoryEventType.APPROVAL,
  },
];

const labelValue: Record<string, string> = {
  [EntityHistoryEventType.CREATE]: 'upload',
  [EntityHistoryEventType.EDIT]: 'edit',
  [EntityHistoryEventType.APPROVAL]: 'approval',
  [EntityHistoryEventType.LINKING]: 'linking',
  [EntityHistoryEventType.DELETE]: 'Deletion',
};

interface EventHistoryDropdownProps {
  eventTypes: EntityHistoryEventType[];
  setEventTypes: Dispatch<SetStateAction<EntityHistoryEventType[]>>;
}

export const EntityHistoryTypeDropdown = ({
  eventTypes,
  setEventTypes,
}: EventHistoryDropdownProps) => {
  const handleToggle = (value: Option['value']) => () => {
    const currentIndex = eventTypes.indexOf(value);
    if (eventTypes.length === 1 && eventTypes[currentIndex]) {
      return null;
    }
    const newChecked = [...eventTypes];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setEventTypes(newChecked);
  };

  const label = (values: string[]) => {
    const labels = values.map((value) => labelValue[value]);
    if (labels.length === 0) {
      return 'No filters selected';
    }
    if (labels.length === 1) {
      return `Showing ${labels[0]} events`;
    }
    return `Showing ${labels.slice(0, labels.length - 1).join(', ')}${
      labels.length > 1 ? ` and ${labels[labels.length - 1]}` : undefined
    } events`;
  };

  return (
    <Box pl={4}>
      <Dropdown
        label={<Typography variant="h5">{label(eventTypes)}</Typography>}
        snVariant="underline"
      >
        {options.map(({ primary, secondary, value }) => (
          <MenuItem key={value} disableRipple onClick={handleToggle(value)}>
            <Box pr={2}>
              <Checkbox
                tabIndex={-1}
                disableRipple
                checked={eventTypes.indexOf(value) !== -1}
              />
            </Box>
            <Box pr={2}>
              <ListItemText primary={primary} secondary={secondary} />
            </Box>
          </MenuItem>
        ))}
      </Dropdown>
    </Box>
  );
};

export default EntityHistoryTypeDropdown;
