import React from 'react';
import { Snackbar, Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styled from '../../services/styled';

interface ApprovalConfirmationSnackBarProps {
  open: boolean;
  onClose: () => void;
}

const StyledSnackBarBox = styled(Box)`
  background: ${(p) => p.theme.palette.grey[600]};
  width: 320px;
  box-shadow: 0px 4px 10px 10px rgba(0, 0, 0, 0.25);
`;

const ApprovalConfirmationSnackBar = ({
  open,
  onClose,
}: ApprovalConfirmationSnackBarProps) => {
  return (
    <Snackbar
      autoHideDuration={6000}
      id="entity-edit-confirm"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      onClose={onClose}
    >
      <StyledSnackBarBox>
        <Box display="flex" alignItems="center" m={2}>
          <Box mr={2} display="flex">
            <CheckCircleIcon />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="h5">Approval successful.</Typography>
            <Typography variant="h5" color="textSecondary">
              The approved entities have been removed from review and added to
              search.
            </Typography>
          </Box>
        </Box>
      </StyledSnackBarBox>
    </Snackbar>
  );
};

export default ApprovalConfirmationSnackBar;
