import React from 'react';
import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
import SNButton from '../SNButton';
import {
  searchTreeActions,
  useSearchFormState,
  useSearchTreeBranches,
  useSearchTreeDispatch,
  useSearchTreeNavState,
} from '../../contexts';
import { useLocation } from 'react-router-dom';
import SearchFormTargetSelect from '../SearchFormTargetSelect/SearchFormTargetSelectConnected';
import SearchFormVisibilityToggle from '../SearchFormVisibilityToggle/SearchFormVisibilityToggleConnected';
import SearchFormFilter from '../SearchFormFilter';
import cuid from 'cuid';
import { useRootAdd } from '../../hooks';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { unsavedSearchVar } from '../../localState';
import SNDialogTitle from '../SNDialogTitle';

interface SearchFromDialogProps {
  isNewSearch?: boolean;
  onClose?: () => void;
  searchId: string;
}

const SearchFormDialog = ({
  isNewSearch,
  onClose,
  searchId,
}: SearchFromDialogProps) => {
  const { activeId, parentId } = useSearchTreeNavState();
  const formState = useSearchFormState();
  const branches = useSearchTreeBranches(searchId);
  const addRoot = useRootAdd(searchId);
  const dispatch = useSearchTreeDispatch();
  const { pathname } = useLocation();

  const handleSubmit = () => {
    const id = activeId || cuid();
    dispatch(
      searchTreeActions.branch.update({
        key: searchId,
        id,
        value: JSON.parse(JSON.stringify(formState)),
      }),
    );
    if (!activeId) {
      if (parentId) {
        dispatch(
          searchTreeActions.branch.update({
            key: searchId,
            id: parentId,
            value: {
              children: [...branches[parentId].children, id],
            },
          }),
        );
      } else {
        addRoot(id);
      }
    }
    if (onClose) onClose();
    unsavedSearchVar(true);
  };

  const title = React.useMemo(() => {
    const label = pathname.includes('review') ? 'Review' : 'Search';
    if (isNewSearch) {
      return `New ${label}`;
    }
    if (!activeId) {
      return parentId ? `Add ${label} Node` : `Add Root ${label} Node`;
    }
    return `Edit ${label} Node`;
  }, [activeId, isNewSearch, parentId, pathname]);

  const submitLabel = React.useMemo(() => {
    const label = pathname.includes('review') ? 'Review' : 'Search';
    if (isNewSearch) {
      return 'Show Results';
    }
    if (!activeId) {
      return `Add to ${label}`;
    }
    return 'Apply Changes';
  }, [activeId, isNewSearch, pathname]);

  return (
    <>
      <SNDialogTitle onClose={onClose}>
        <Typography variant="h2">{title}</Typography>
        <Box pt={2} maxWidth={340}>
          <SearchFormTargetSelect />
          <SearchFormVisibilityToggle />
        </Box>
      </SNDialogTitle>
      <DialogContent dividers>
        <Box py={1}>
          <Typography variant="h3">Filters</Typography>
        </Box>
        <SearchFormFilter
          id={formState.filter.rootFilterId}
          isRoot
          index={0}
          depth={0}
        />
      </DialogContent>
      <DialogActions>
        {!isNewSearch && <SNButton onClick={onClose}>Cancel</SNButton>}
        <SNButton
          snVariant="primary"
          onClick={handleSubmit}
          data-testid="apply-search-options"
          endIcon={isNewSearch ? <ArrowForwardIcon /> : undefined}
          rounded={isNewSearch}
        >
          {submitLabel}
        </SNButton>
      </DialogActions>
    </>
  );
};

export default SearchFormDialog;
