import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DialogActions, Box, Grid } from '@mui/material';
import styled from '../../services/styled';
import SNButton from '../SNButton';
import { Route } from 'react-router-dom';
import { useUpload, useUploadWizardState } from '../../contexts';
import { Link as RouterLink } from 'react-router-dom';

interface UploadModalActionsProps {
  hasSubsets: boolean;
  onUpload: () => void;
  onGuidePromptOptOut: () => void;
}

const UploadDialogActions = styled(DialogActions)`
  box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.25);
  padding: ${({ theme }) => theme.spacing(3)}px;
`;
const StyledRouterLink = styled(RouterLink)`
  text-decoration: none;
`;

const UploadNowButton = ({
  onUpload,
}: Pick<UploadModalActionsProps, 'onUpload'>) => (
  <SNButton
    data-testid="upload-wizard-upload-now"
    snVariant="dark"
    onClick={onUpload}
  >
    Upload Now
  </SNButton>
);

const WizardBackButton = React.memo(({ to }: { to: string }) => (
  <StyledRouterLink to={to}>
    <SNButton snVariant="dark" startIcon={<ArrowBackIcon />}>
      Back
    </SNButton>
  </StyledRouterLink>
));

WizardBackButton.displayName = 'WizardBackButton';

interface WizardNextButtonProps {
  disabled?: boolean;
  to: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  testId?: string;
}

const WizardNextButton = React.memo(
  ({ disabled, to, onClick, testId, ...rest }: WizardNextButtonProps) =>
    disabled ? (
      <SNButton
        data-testid={testId}
        disabled
        snVariant="primary"
        endIcon={<ArrowForwardIcon />}
        onClick={onClick}
        {...rest}
      >
        Next
      </SNButton>
    ) : (
      <StyledRouterLink to={to} sx={{ background: 'transparent' }}>
        <SNButton
          data-testid={testId}
          snVariant="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={onClick}
          {...rest}
        >
          Next
        </SNButton>
      </StyledRouterLink>
    ),
);

WizardNextButton.displayName = 'WizardNextButton';

const UploadModalActions = ({
  hasSubsets,
  onUpload,
  onGuidePromptOptOut,
}: UploadModalActionsProps) => {
  const wizardState = useUploadWizardState();
  const upload = useUpload();

  const handleGuideNextClick = () => {
    onGuidePromptOptOut();
  };

  const handleUpload = React.useCallback(() => {
    upload();
    onUpload();
  }, [onUpload, upload]);

  return (
    <UploadDialogActions>
      <Route path="/files">
        <Box>
          <Grid container spacing={1}>
            <Grid item>
              <UploadNowButton onUpload={handleUpload} />
            </Grid>
            <Grid item>
              <WizardNextButton
                testId="upload-wizard-files-next"
                to={
                  wizardState.skipAllQuestions ? `/projects/content` : '/guide'
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Route>
      <Route path="/guide">
        <Box display="flex" justifyContent="space-between" width="100%">
          <WizardBackButton to="/files" />
          <Box>
            <Grid container spacing={1}>
              <Grid item>
                <UploadNowButton onUpload={handleUpload} />
              </Grid>
              <Grid item>
                <WizardNextButton
                  testId="upload-wizard-guide-next"
                  disabled={wizardState.skipAllQuestions === null}
                  to={`/projects/${
                    wizardState.skipAllQuestions ? 'content' : 'question'
                  }`}
                  onClick={handleGuideNextClick}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Route>
      <Route path="/projects/question">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            <WizardBackButton to="/guide" />
          </Box>
          <Box>
            <Grid container spacing={1}>
              <Grid item>
                <UploadNowButton onUpload={handleUpload} />
              </Grid>
              <Grid item>
                {hasSubsets ? (
                  <WizardNextButton
                    testId="upload-wizard-projects-question-next"
                    disabled={wizardState.addProjects === null}
                    to={
                      wizardState.addProjects === true
                        ? '/projects/content'
                        : '/sheets/question'
                    }
                  />
                ) : (
                  <WizardNextButton
                    testId="upload-wizard-projects-question-next"
                    disabled={wizardState.addProjects === null}
                    to={
                      wizardState.addProjects === true
                        ? '/projects/content'
                        : '/metadata/question'
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Route>
      <Route path="/projects/content">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            {!wizardState.skipAllQuestions && (
              <WizardBackButton to="/project/question" />
            )}
          </Box>
          <Box>
            <Grid container spacing={1}>
              <Grid item>
                <UploadNowButton onUpload={handleUpload} />
              </Grid>
              <Grid item>
                {hasSubsets ? (
                  <WizardNextButton
                    testId="upload-wizard-projects-question-next"
                    to={
                      wizardState.skipAllQuestions
                        ? '/sheets/content'
                        : '/sheets/question'
                    }
                  />
                ) : (
                  <WizardNextButton
                    testId="upload-wizard-projects-question-next"
                    to={
                      wizardState.addMetadata === true
                        ? '/metadata/content'
                        : '/metadata/question'
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Route>
      <Route path="/sheets/question">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            {!wizardState.skipAllQuestions && (
              <WizardBackButton to="/projects/question" />
            )}
          </Box>
          <Box>
            <Grid container spacing={1}>
              <Grid item>
                <UploadNowButton onUpload={handleUpload} />
              </Grid>
              <Grid item>
                <WizardNextButton
                  testId="upload-wizard-sheets-question-next"
                  disabled={wizardState.uploadAllSheets === null}
                  to={
                    wizardState.uploadAllSheets === false
                      ? '/sheets/content'
                      : '/metadata/question'
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Route>
      <Route path="/sheets/content">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            {!wizardState.skipAllQuestions && (
              <WizardBackButton to="/sheets/question" />
            )}
          </Box>
          <Box>
            <Grid container spacing={1}>
              <Grid item>
                <UploadNowButton onUpload={handleUpload} />
              </Grid>
              <Grid item>
                <WizardNextButton
                  testId="upload-wizard-sheets-content-next"
                  to={
                    wizardState.skipAllQuestions
                      ? '/metadata/content'
                      : '/metadata/question'
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Route>
      <Route path="/metadata/question">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            {!wizardState.skipAllQuestions && hasSubsets ? (
              <WizardBackButton to="/sheets/question" />
            ) : (
              <WizardBackButton to="/projects/question" />
            )}
          </Box>
          <Box>
            <Grid container spacing={1}>
              <Grid item>
                <UploadNowButton onUpload={handleUpload} />
              </Grid>
              <Grid item>
                <WizardNextButton
                  testId="upload-wizard-metadata-question-next"
                  disabled={wizardState.addMetadata === null}
                  to={
                    wizardState.addMetadata === true
                      ? '/metadata/content'
                      : '/context/question'
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Route>
      <Route path="/metadata/content">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            {!wizardState.skipAllQuestions && (
              <WizardBackButton to="/metadata/question" />
            )}
          </Box>
          <Box>
            <Grid container spacing={1}>
              <Grid item>
                <UploadNowButton onUpload={handleUpload} />
              </Grid>
              <Grid item>
                <WizardNextButton
                  testId="upload-wizard-metadata-content-next"
                  to={
                    wizardState.skipAllQuestions
                      ? '/context/content'
                      : '/context/question'
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Route>
      <Route path="/context/question">
        <Box display="flex" justifyContent="space-between" width="100%">
          <WizardBackButton to="/metadata/question" />
          <Box>
            <Grid container spacing={1}>
              <Grid item>
                <UploadNowButton onUpload={handleUpload} />
              </Grid>
              <Grid item>
                <WizardNextButton
                  testId="upload-wizard-context-question-next"
                  disabled={wizardState.createContext === null}
                  to={
                    wizardState.createContext === true
                      ? '/context/content'
                      : '/linking/question'
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Route>
      <Route path="/context/content">
        <Box display="flex" justifyContent="space-between" width="100%">
          <WizardBackButton
            to={
              wizardState.skipAllQuestions
                ? '/metadata/content'
                : '/context/question'
            }
          />
          <Box>
            <Grid container spacing={1}>
              <Grid item>
                <UploadNowButton onUpload={handleUpload} />
              </Grid>
              <Grid item>
                <WizardNextButton
                  testId="upload-wizard-context-content-next"
                  to={
                    wizardState.skipAllQuestions
                      ? '/linking/content'
                      : '/linking/question'
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Route>
      <Route path="/linking/question">
        <Box display="flex" justifyContent="space-between" width="100%">
          <WizardBackButton to="/context/question" />
          <Box>
            <Grid container spacing={1}>
              <Grid item>
                <UploadNowButton onUpload={handleUpload} />
              </Grid>
              <Grid item>
                <WizardNextButton
                  testId="upload-wizard-linking-question-next"
                  disabled={wizardState.linkData === null}
                  to={wizardState.linkData ? '/linking/content' : '/summary'}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Route>
      <Route path="/linking/content">
        <Box display="flex" justifyContent="space-between" width="100%">
          <WizardBackButton
            to={
              wizardState.skipAllQuestions
                ? '/context/content'
                : '/linking/question'
            }
          />
          <Box>
            <Grid container spacing={1}>
              <Grid item>
                <UploadNowButton onUpload={handleUpload} />
              </Grid>
              <Grid item>
                <WizardNextButton
                  data-testid="upload-wizard-linking-content-next"
                  to="/summary"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Route>
      <Route path="/summary">
        <Box display="flex" justifyContent="space-between" width="100%">
          <WizardBackButton
            to={
              wizardState.skipAllQuestions
                ? '/linking/content'
                : '/linking/question'
            }
          />
          <SNButton
            data-testid="upload-wizard-final-upload"
            hover
            snVariant="light"
            onClick={handleUpload}
          >
            Finalize Upload
          </SNButton>
        </Box>
      </Route>
    </UploadDialogActions>
  );
};

export default UploadModalActions;
