import { atom } from 'recoil';
import { ClassifyEntitiesInput } from '../types/schemaTypes';

export const ClassificationWizardFormInfo = atom<ClassifyEntitiesInput>({
  key: 'classification-wizard-form-info',
  default: {
    searchQuery: { branches: [], roots: [] },
    groupId: '',
    selectedTypeId: '',
    columnsToDelete: [],
    columnsAsMetadata: [],
    columnsToRename: [],
    missingColumns: [],
  },
});
