import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from '@apollo/client';
import {
  EntityLinkSummaries,
  EntityLinkSummariesVariables,
} from '../types/schemaTypes';

const ENTITY_LINK_SUMMARIES = gql`
  query EntityLinkSummaries($id: ID!) {
    entityById(id: $id) {
      data {
        id
        linkSummaries {
          id
          label
          value
          direction
          displayStatus
          linkCount
          annotations {
            source
            severity
            message
            dataPath
          }
          linkDefinition {
            id
            name
            description
            type {
              id
              name
              description
              fields {
                id
                name
                description
                dataPoint {
                  id
                  name
                  description
                  dataType
                }
                inputOptions {
                  __typename
                  ... on Autogenerated {
                    displayOnEntry
                  }
                  ... on DateTimeInputOptions {
                    captureTime
                  }
                  ... on LocalSelectInputOptions {
                    type
                    options {
                      text
                      value
                    }
                  }
                  ... on NumericInputOptions {
                    type
                    precision
                  }
                  ... on TextInputOptions {
                    type
                  }
                }
              }
            }
          }
        }
      }
      errors
    }
  }
`;

export const useEntityLinkSummariesQuery = (
  options?: QueryHookOptions<EntityLinkSummaries, EntityLinkSummariesVariables>,
) => {
  return useQuery<EntityLinkSummaries, EntityLinkSummariesVariables>(
    ENTITY_LINK_SUMMARIES,
    {
      ...options,
    },
  );
};
