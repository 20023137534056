import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import { UsersOfGroup, UsersOfGroupVariables } from '../types/schemaTypes';

export const USERS_OF_GROUP = gql`
  query UsersOfGroup(
    $id: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: UserFilter
  ) {
    group(id: $id) {
      group {
        id
        name
        description
        groupMembership(
          after: $after
          before: $before
          first: $first
          last: $last
          filter: $filter
        ) {
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
            endIndex @client
            startIndex @client
          }
          totalCount
          edges {
            cursor
            node {
              id
              createdAt
              user {
                id
                name
                picture
                email
              }
            }
          }
        }
      }
      errors
    }
  }
`;

export const useLazyUsersOfGroupQuery = (
  options?: LazyQueryHookOptions<UsersOfGroup, UsersOfGroupVariables>,
) => {
  return useLazyQuery<UsersOfGroup, UsersOfGroupVariables>(USERS_OF_GROUP, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};

export const useUsersOfGroupQuery = (
  options?: QueryHookOptions<UsersOfGroup, UsersOfGroupVariables>,
) => {
  return useQuery<UsersOfGroup, UsersOfGroupVariables>(USERS_OF_GROUP, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};
