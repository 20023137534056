import React from 'react';
import * as Icons from '../SvgIcon';

interface ExtensionIconProps {
  fileName?: string;
}

const ExtensionIcon = (props: ExtensionIconProps) => {
  const { fileName = 'none' } = props;
  const extension = fileName.split('.').slice(-1)[0];

  const getIconByFileExtension = (extension: string) => {
    switch (extension.toLowerCase()) {
      case 'csv':
        return <Icons.Csv />;
      case 'xls':
      case 'xlsb':
      case 'xlsm':
      case 'xlsx':
      case 'xlt':
      case 'xltm':
      case 'xltw':
        return <Icons.Excel />;
      case 'doc':
      case 'docm':
      case 'docx':
      case 'dot':
      case 'dotm':
      case 'dotx':
      case 'wps':
        return <Icons.Word />;
      case 'txt':
        return <Icons.Txt />;
      case 'odt':
      case 'rtf':
        return <Icons.GenericText />;
      case 'ods':
        return <Icons.GenericTabs />;
      case 'gsheet':
        return <Icons.Gsheet />;
      case 'gdoc':
        return <Icons.Gdoc />;
      case 'pdf':
        return <Icons.Pdf />;
      case 'one':
        return <Icons.Note />;
      case 'wma':
      case 'mp3':
      case 'alac':
      case 'flac':
      case 'ogg':
      case 'aac':
      case 'mid':
      case 'midi':
        return <Icons.Audio />;
      case 'avi':
      case 'avchd':
      case 'mpg':
      case 'mpeg':
      case 'flv':
      case 'mkv':
      case 'mp4':
      case 'webm':
      case 'wmv':
        return <Icons.Video />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
      case 'tiff':
      case 'tif':
      case 'psd':
      case 'xcf':
      case 'ai':
      case 'raw':
        return <Icons.Image />;
      default:
        return <Icons.Unknown />;
    }
  };

  return getIconByFileExtension(extension);
};

export default ExtensionIcon;
