import React from 'react';
import { Box, BoxProps } from '@mui/material';
import styled from '../../services/styled';

type Placement = 'above' | 'below';

export interface ShadowBoxProps extends BoxProps {
  isOverlapping?: boolean;
  scope?: 'col';
  placement?: Placement;
}

const positionOptions: Record<Placement, any> = {
  above: {
    bottom: -8,
    background:
      'linear-gradient(180deg,rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%)',
  },
  below: {
    top: -8,
    background:
      'linear-gradient(180deg,rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%)',
  },
};

const ShadowBox = styled(({ isOverlapping, ...rest }: ShadowBoxProps) => (
  <Box position="relative" zIndex={1} {...rest} />
))({}, ({ isOverlapping, placement = 'above' }) => ({
  '::after': {
    height: 8,
    content: "''",
    right: 0,
    left: 0,
    opacity: isOverlapping ? 1 : 0,
    transition: 'opacity 0.1s ease-in-out',
    position: 'absolute',
    zIndex: -1,
    ...positionOptions[placement],
  },
}));

export default ShadowBox;
