import React from 'react';
import { useSetRecoilState } from 'recoil';
import { shiftKeyAtom } from '../atoms/listSelection';

const useShiftKeyDown = () => {
  const setShift = useSetRecoilState(shiftKeyAtom);

  React.useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Shift') {
        setShift(true);
      }
    }
    function handleKeyUp(event: KeyboardEvent) {
      if (event.key === 'Shift') {
        setShift(false);
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [setShift]);
};

export default useShiftKeyDown;
