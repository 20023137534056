import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { UnclassifiedGroupById } from '../types/schemaTypes';

const UNCLASSIFIED_GROUP_BY_ID = gql`
  query UnclassifiedGroupById($id: ID!) {
    unclassifiedGroupById(id: $id) {
      id
      locked
    }
  }
`;

export const useUnclassifiedGroupById = (
  options: QueryHookOptions<UnclassifiedGroupById>,
) => {
  return useQuery<UnclassifiedGroupById>(UNCLASSIFIED_GROUP_BY_ID, {
    ...options,
  });
};
