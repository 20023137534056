import { searchFormActions, useSearchFormDispatch } from '../contexts';
import cuid from 'cuid';

interface UseFilterBranchAddOptions {
  parentId: string;
  children: string[];
}

function useFilterBranchAdd() {
  const dispatch = useSearchFormDispatch();
  return ({ parentId, children }: UseFilterBranchAddOptions) => {
    const newId = cuid();
    dispatch(
      searchFormActions.filter.branch.add({
        id: newId,
        parentId,
        children,
      }),
    );
    return newId;
  };
}

export default useFilterBranchAdd;
