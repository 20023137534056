import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { AddGroup, AddGroupVariables } from '../types/schemaTypes';

export const ADD_GROUP = gql`
  mutation AddGroup($input: AddGroupInput!) {
    addGroup(input: $input) {
      group {
        id
        name
        description
      }
      errors
    }
  }
`;

export const useAddGroupMutation = (
  options?: MutationHookOptions<AddGroup, AddGroupVariables>,
) => {
  return useMutation<AddGroup, AddGroupVariables>(ADD_GROUP, options);
};
