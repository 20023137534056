import React from 'react';
import { Box, Typography } from '@mui/material';
import SNTableRow from '../SNTableRow';
import moment from 'moment';
import { GroupsOfUserForProfile_user_user_groupMembership_edges_node_group_createdBy } from '../../types/schemaTypes';

interface GroupsTableRowProps {
  name?: string | null;
  id?: string;
  description?: string | null;
  admin:
    | GroupsOfUserForProfile_user_user_groupMembership_edges_node_group_createdBy
    | null
    | undefined;
  lastModified: string;
}

const UserGroupsTableRowReadOnly = ({
  name,
  id,
  description,
  lastModified,
  admin,
}: GroupsTableRowProps) => {
  return (
    <SNTableRow selected={false} key={id}>
      <td>
        <Box display="flex" alignItems="center" height={56} pl={4}>
          <Box fontWeight="bold">
            <Typography>{name}</Typography>
          </Box>
        </Box>
      </td>
      <td>
        <Box height={56} display="flex" alignItems="center">
          <Box overflow="hidden" textOverflow="ellipsis">
            <Typography>{description}</Typography>
          </Box>
        </Box>
      </td>
      <td>
        <Box height={56} display="flex" alignItems="center">
          {admin ? (
            <Typography>{admin.name}</Typography>
          ) : (
            <Typography>Admin unknown</Typography>
          )}
        </Box>
      </td>
      <td>
        <Box height={56} display="flex" alignItems="center">
          <Typography>{moment(lastModified).format('YYYY-MM-DD')}</Typography>
        </Box>
      </td>
    </SNTableRow>
  );
};

export default UserGroupsTableRowReadOnly;
