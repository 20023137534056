import React from 'react';
import { Box, alpha, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styled from '../../services/styled';
import SNButton from '../SNButton';

interface ReviewBannerProps {
  onBack?: () => void;
}

const StyledBanner = styled(Box)`
  background-color: ${({ theme }) => alpha(theme.palette.info.main, 0.4)};
`;

const ReviewBanner: React.FC<ReviewBannerProps> = ({ onBack }) => {
  return (
    <StyledBanner
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      height={48}
    >
      {onBack && (
        <Box position="absolute" left={24}>
          <SNButton
            startIcon={<ArrowBackIcon />}
            snVariant="underline"
            onClick={onBack}
          >
            Back
          </SNButton>
        </Box>
      )}
      <Box display="flex" alignItems="center">
        <Box display="flex" mr={1}>
          <InfoOutlinedIcon />
        </Box>
        <Typography>
          This file is in review. Data will not be available in search until it
          is approved.
        </Typography>
      </Box>
      <Box></Box>
    </StyledBanner>
  );
};

export default ReviewBanner;
