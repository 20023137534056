import { useSearchTreeDispatch, searchTreeActions } from '../contexts';

function useRootAdd(searchId: string) {
  const dispatch = useSearchTreeDispatch();
  return (newRootId: string) => {
    dispatch(
      searchTreeActions.roots.add({
        key: searchId,
        value: newRootId,
      }),
    );
  };
}

export default useRootAdd;
