import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { DeleteUser, DeleteUserVariables } from '../types/schemaTypes';

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      user {
        id
        name
        status
      }
      errors
    }
  }
`;

export const useDeleteUserMutation = (
  options?: MutationHookOptions<DeleteUser, DeleteUserVariables>,
) => {
  return useMutation<DeleteUser, DeleteUserVariables>(DELETE_USER, options);
};
