import {
  CustomCell,
  EditableGridCell,
  getMiddleCenterBias,
  GridCellKind,
} from '@glideapps/glide-data-grid';
import LocalSelectCell from './LocalSelectCell';
import DateTimeCell from './DateTimeCell';
import AnnotatedTextCell from './AnnotatedTextCell';
import moment from 'moment';
import { AnnotationSeverity } from '../../types/schemaTypes';
import { getThemeStatusFromServerStatus } from '../../utils';
import MUITheme from '../../services/theme';
import styled from 'styled-components';
import { BaseDrawArgs } from '@glideapps/glide-data-grid/dist/ts/data-grid/cells/cell-types';

interface CellData {
  value: string;
  readonly?: boolean | undefined;
  status: AnnotationSeverity | null;
  textDecoration?: 'underline';
  underlineColor?: 'accentColor';
}

export const drawTextCell = (args: BaseDrawArgs, cell: CellData) => {
  const { ctx, rect, theme } = args;
  const {
    readonly,
    status,
    value,
    textDecoration,
    underlineColor = 'accentColor',
  } = cell;
  const iconSize = 24;
  const themeStatus = getThemeStatusFromServerStatus(status);

  const xPad = theme.cellHorizontalPadding;

  const textX = rect.x + xPad;
  const clipX = themeStatus ? rect.width - xPad - iconSize : rect.width - xPad;

  if (themeStatus) {
    const icon = new Path2D(
      'M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z',
    );
    const iconX = rect.x + rect.width - xPad / 2 - iconSize;
    ctx.save();
    ctx.translate(iconX, rect.y + rect.height / 2 - iconSize / 2);
    ctx.fillStyle = MUITheme.palette[themeStatus].main;
    ctx.fill(icon);
    ctx.restore();
  }

  if (textDecoration === 'underline') {
    // draw underline for links
    const textWidth = ctx.measureText(value).width;
    ctx.moveTo(
      textX,
      rect.y + rect.height / theme.lineHeight + getMiddleCenterBias(ctx, theme),
    );
    ctx.lineWidth = 2;
    ctx.lineTo(
      textX + textWidth,
      rect.y + rect.height / theme.lineHeight + getMiddleCenterBias(ctx, theme),
    );
    ctx.strokeStyle = theme[underlineColor];
    ctx.stroke();
  }

  let textColor = theme.textDark;

  if (themeStatus) {
    textColor = MUITheme.palette[themeStatus].light;
  } else if (readonly) {
    textColor = theme.textMedium;
  }

  ctx.save();
  ctx.beginPath();
  ctx.rect(rect.x, rect.y, clipX, rect.height);
  ctx.clip();
  ctx.font = `${theme.baseFontStyle} ${theme.fontFamily}`;
  ctx.fillStyle = textColor;
  ctx.fillText(
    value,
    textX,
    rect.y + rect.height / 2 + getMiddleCenterBias(ctx, theme),
  );
  ctx.restore();

  return true;
};

export const CellInput = styled.input`
  color: inherit;
  background: 0px center;
  opacity: 1;
  width: 100%;
  grid-area: 1 / 2 / auto / auto;
  font: inherit;
  min-width: 2px;
  border: 0px;
  margin: 0px;
  outline: 0px;
  padding: 0px;
`;

const isCustomCell = (cell: EditableGridCell): cell is CustomCell =>
  cell.kind === GridCellKind.Custom;

export const getValueFromCell = (cell: EditableGridCell): string => {
  if (isCustomCell(cell)) {
    if (LocalSelectCell.isMatch(cell)) {
      return cell.data.value;
    }
    if (DateTimeCell.isMatch(cell)) {
      return moment(cell.data.date).format('YYYY-MM-DD');
    }
    if (AnnotatedTextCell.isMatch(cell)) {
      return cell.data.displayValue;
    }
  }
  return cell.data?.toString() || '';
};
