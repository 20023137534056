import { SpriteMap } from '@glideapps/glide-data-grid';
import { SpriteProps } from '@glideapps/glide-data-grid/dist/ts/common/utils';

const glideSvg_Link = (
  props: SpriteProps,
) => `<svg width="24" height="24" fill="${props.fgColor}" xmlns="http://www.w3.org/2000/svg">
<path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z" />
</svg>`;

const glideSvg_snLogo = (
  props: SpriteProps,
) => `<svg width="32" height="32" fill="${props.fgColor}" xmlns="http://www.w3.org/2000/svg">
<path d="M18.3032 30.0364L14.8858 31.9404L8.59934 26.7036V25.203L18.3032 30.0364Z"/>
<path d="M10.7042 12.6492C11.0843 13.0738 11.3774 13.3851 11.5834 13.583C11.7894 13.781 12.1122 14.0616 12.5518 14.4247L1.40274 14.8716V13.9258L10.7042 12.6492Z"/>
<path d="M8.9497 9.69233L9.25293 10.5L9.60107 11.157L2.77211 12.6216V11.7212L8.9497 9.69233Z"/>
<path d="M18.3032 30.0363C18.0184 30.3152 17.4736 30.6788 16.6688 31.1272C15.864 31.5756 15.2697 31.8467 14.8858 31.9404L8.59934 26.7036V25.203L18.3032 30.0363Z"/>
<path d="M8.55408 6.78831C8.52492 7.12955 8.52492 7.42323 8.55408 7.66935C8.58323 7.91547 8.64268 8.12362 8.73242 8.29381L4.2256 10.1851V9.58606L8.55408 6.78831Z"/>
<path d="M21.7461 26.7714C21.6621 27.0072 21.3623 27.4235 20.8469 28.0204C20.3315 28.6174 19.98 28.9553 19.7923 29.0342L6.91998 23.2183V21.8115L21.7461 26.7714Z"/>
<path d="M15.0828 16.11C16.2342 16.6402 17.2041 17.1723 17.9926 17.7064C18.7811 18.2405 19.4828 18.8406 20.0977 19.5068L-0.000230789 16.9095V15.906L15.0828 16.11Z"/>
<path d="M15.0828 16.11C16.2342 16.6402 17.2041 17.1723 17.9926 17.7064C18.7811 18.2405 19.4828 18.8406 20.0977 19.5068L-0.000230789 16.9095V15.906L15.0828 16.11Z"/>
<path d="M21.7459 20.7836C22.1778 21.7313 22.4468 22.5214 22.5531 23.154C22.6594 23.7866 22.6594 24.4159 22.5531 25.042L4.0104 20.0131V18.6447L21.7459 20.7836Z"/>
<path d="M13.6968 1.90408L17.1142 0L23.4007 5.23681V6.73741L13.6968 1.90408Z"/>
<path d="M21.2958 19.2913C20.9157 18.8666 20.6226 18.5554 20.4166 18.3574C20.2106 18.1594 19.8878 17.8789 19.4482 17.5157L30.5973 17.0688V18.0147L21.2958 19.2913Z"/>
<path d="M23.0503 22.2481L22.7471 21.4404L22.3989 20.7835L29.2279 19.3188V20.2193L23.0503 22.2481Z"/>
<path d="M13.6968 1.90408C13.9816 1.62527 14.5264 1.26166 15.3312 0.813252C16.136 0.364843 16.7303 0.0937588 17.1142 0L23.4007 5.23681V6.73741L13.6968 1.90408Z"/>
<path d="M23.4459 25.1521C23.4751 24.8109 23.4751 24.5172 23.4459 24.2711C23.4168 24.025 23.3573 23.8168 23.2676 23.6466L27.7744 21.7554V22.3544L23.4459 25.1521Z"/>
<path d="M10.2544 5.16903C10.3384 4.93325 10.6381 4.5169 11.1535 3.91998C11.6689 3.32305 12.0205 2.98515 12.2082 2.90625L25.0805 8.72212V10.1289L10.2544 5.16903Z"/>
<path d="M16.9172 15.8305C15.7658 15.3003 14.7959 14.7681 14.0074 14.234C13.2189 13.6999 12.5172 13.0998 11.9023 12.4336L32.0002 15.0309V16.0345L16.9172 15.8305Z"/>
<path d="M16.9172 15.8305C15.7658 15.3003 14.7959 14.7681 14.0074 14.234C13.2189 13.6999 12.5172 13.0998 11.9023 12.4336L32.0002 15.0309V16.0345L16.9172 15.8305Z"/>
<path d="M10.3193 10.9183C9.84396 10.1297 9.55317 9.41907 9.4469 8.78648C9.34062 8.15389 9.34062 7.48792 9.4469 6.78857L27.9896 11.9274V13.2958L10.3193 10.9183Z"/>
</svg>
`;

const glideSvg_userMetadata = (props: SpriteProps) =>
  `<svg width="24" height="24" fill="${props.fgColor}" xmlns="http://www.w3.org/2000/svg">
<path d="m21.41 11.58-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7z"></path>
</svg>`;

const headerIcons: SpriteMap = {
  link: glideSvg_Link,
  metadata: glideSvg_userMetadata,
  snLogo: glideSvg_snLogo,
};

export default headerIcons;
