import styled from '../../services/styled';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { PaletteStatus } from '../../types';

interface SNTooltipProps extends TooltipProps {
  color?: PaletteStatus;
  variant?: 'main' | 'light' | 'dark';
}

const SNTooltip = styled(({ className, ...props }: SNTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ color, theme, variant = 'main' }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    borderColor: color ? theme.palette[color][variant] : 'transparent',
    borderWidth: '1px',
    borderStyle: 'solid',
    padding: theme.spacing(2),
  },
}));

export default SNTooltip;
