import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  UpdateProjectAccess,
  UpdateProjectAccessVariables,
} from '../types/schemaTypes';

export const UPDATE_PROJECT_ACCESS = gql`
  mutation UpdateProjectAccess($input: UpdateProjectAccessInput!) {
    updateProjectAccess(input: $input) {
      projectAccess {
        id
        user {
          id
          name
          picture
        }
        userRole
        project {
          id
          name
        }
      }
      errors
    }
  }
`;

export const useUpdateProjectAccessMutation = (
  options?: MutationHookOptions<
    UpdateProjectAccess,
    UpdateProjectAccessVariables
  >,
) => {
  return useMutation<UpdateProjectAccess, UpdateProjectAccessVariables>(
    UPDATE_PROJECT_ACCESS,
    options,
  );
};
