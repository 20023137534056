import { Box } from '@mui/material';
import React from 'react';
import { useEntityLinkSummariesQuery } from '../../queries/useEntityLinkSummariesQuery';
import SelectableListToolbar from '../SelectableListToolbar';
import PaginationControls from '../PaginationControls';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { pageIdsAtom } from '../../atoms/listSelection';
import SNTable from '../SNTable';
import EntityLinkSummaryRow from './EntityLinkSummaryRow';
import ListSelectionControl from '../ListSelectionControl';
import { collectErrors } from '../../utils/collectErrors';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';

interface EntityLinkSummaryTableProps {
  id: string;
  onLinksClick: (tabId: string) => () => void;
}

const headers = ['relationship type', 'links', 'description'];

const EntityLinkSummaryTable: React.FC<EntityLinkSummaryTableProps> = ({
  id,
  onLinksClick,
}) => {
  const pageSize = 20;
  const [page, setPage] = React.useState(0);
  const [pageIds, setPageIds] = useRecoilState(pageIdsAtom);
  const { data, loading, error } = useEntityLinkSummariesQuery({
    variables: { id },
  });
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);

  const loadMore = (direction: number) => {
    setPage((previous) => previous + direction);
  };

  const items = React.useMemo(
    () =>
      data?.entityById?.data?.linkSummaries.slice(
        page * pageSize,
        page * pageSize + pageSize,
      ),
    [data, page],
  );

  React.useEffect(() => {
    setPageIds((items || []).map((item) => item.id));
  }, [items, setPageIds]);

  const pageCount = Math.floor(
    (data?.entityById?.data?.linkSummaries.length || 0) / pageSize,
  );

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.entityById?.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error fetching group',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <Box maxHeight="100vh" position="relative">
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <PaginationControls
            disableNext={pageCount === page}
            disablePrevious={pageCount === page}
            loading={loading}
            onPage={loadMore}
            pageCount={pageCount}
            pageSize={pageSize}
            pageTotal={pageIds.length}
            totalCount={data?.entityById?.data?.linkSummaries.length || 0}
          />
        </Box>
      </SelectableListToolbar>
      <SNTable
        error={error}
        hasResults={!!items}
        headers={headers}
        id="entity-links-summary"
        loading={loading}
        rowCount={pageIds.length}
        selectable
      >
        {items?.map(
          ({ id, label, value, linkDefinition: { description } }, index) => (
            <EntityLinkSummaryRow
              key={id}
              description={description || ''}
              id={id}
              index={index}
              label={label}
              onLinksClick={onLinksClick}
              value={value}
            />
          ),
        )}
      </SNTable>
    </Box>
  );
};

export default EntityLinkSummaryTable;
