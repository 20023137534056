import React from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import SNButton from '../SNButton';
import EditValidationsBanner from '../EditValidationsBanner';
import SearchResultsFieldValidationsList from '../SearchResultsEditFieldValidationsList';
import SNDialogTitle from '../SNDialogTitle';
import CheckCircle from '@mui/icons-material/CheckCircle';

interface SearchResultsEditValidationModalProps {
  onClose: () => void;
  onApprove: () => void;
}

const SearchResultsEditValidationModal = ({
  onClose,
  onApprove,
}: SearchResultsEditValidationModalProps) => {
  return (
    <>
      <SNDialogTitle onClose={onClose}>Edit Data</SNDialogTitle>
      <DialogContent>
        <Box pb={2}>
          <EditValidationsBanner secondaryFieldValidationMessage="These values do not meet the criteria for this entity type. You may continue editing or approve the data as is." />
        </Box>
        <SearchResultsFieldValidationsList />
      </DialogContent>
      <DialogActions>
        <SNButton
          snVariant="text"
          onClick={onApprove}
          startIcon={<CheckCircle />}
        >
          Approve data
        </SNButton>
        <SNButton snVariant="primary" onClick={onClose}>
          Return To Editing
        </SNButton>
      </DialogActions>
    </>
  );
};

export default SearchResultsEditValidationModal;
