import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
import SNDialogTitle from '../SNDialogTitle';
import SNButton from '../SNButton';
import React from 'react';
import { useUpdateEntityMetadata } from '../../mutations';
import { UploadMetadataInput } from '../../types/schemaTypes';
import SNStatusBanner from '../SNStatusBanner';

import { userMetadataEditModalOpenAtom } from '../../atoms/userMetadataEditModalOpenAtom';
import { useRecoilState } from 'recoil';
import { MetadataSection } from '../UploadModalMetadataCollection/UploadModalMetadataCollection';
import { uploadMetadataSelector } from '../../atoms/uploadFiles';
import cuid from 'cuid';
import { useEntityQuery } from '../../queries/useEntityQuery';
import LoadingOverlay from '../LoadingOverlay';

const MetadataEditModal = () => {
  const [open, setOpen] = useRecoilState(userMetadataEditModalOpenAtom);
  const [metadataInfo, setMetadataById] = useRecoilState(
    uploadMetadataSelector(open.entityId),
  );

  const { data } = useEntityQuery({
    variables: { id: open.entityId },
  });

  React.useEffect(() => {
    if (data?.entity.userMetadata) {
      const metadataFormData = data.entity.userMetadata.reduce<
        Record<string, UploadMetadataInput>
      >((result, { label: field, value }) => {
        const key = field === 'description' ? field : cuid();
        return {
          ...result,
          [key]: { field, value },
        };
      }, {});
      metadataFormData[cuid()] = {
        field: '',
        value: '',
      };
      if (!metadataFormData['description']) {
        metadataFormData['description'] = {
          field: 'description',
          value: '',
        };
      }
      setMetadataById(metadataFormData);
    }
  }, [data, setMetadataById]);

  const onClose = () => {
    setOpen((prev) => ({ ...prev, open: false }));
  };

  const [updateMetadata, { data: updatedResponseData, loading }] =
    useUpdateEntityMetadata({
      onCompleted: (res) => {
        if (res.editEntityMetadata.data?.id) {
          onClose();
        }
      },
    });

  const handleUpdateMetadata = () => {
    const items = Object.values(metadataInfo)
      .filter((metadataItem) => metadataItem.field)
      .map((metadataItem) => {
        return { label: metadataItem.field, value: metadataItem.value };
      });
    updateMetadata({
      variables: {
        input: {
          id: open.entityId,
          userMetadata: items,
        },
      },
    });
  };

  return (
    <>
      <SNDialogTitle onClose={onClose}>
        <Typography variant="h2">Edit Metadata</Typography>
        <Box pt={1}>
          <Typography>{data?.entity.displayName}</Typography>
        </Box>
      </SNDialogTitle>
      <DialogContent>
        {updatedResponseData?.editEntityMetadata.generalAnnotations &&
          updatedResponseData.editEntityMetadata.generalAnnotations.length >
            0 && (
            <SNStatusBanner status="error">
              <ul>
                {updatedResponseData?.editEntityMetadata.generalAnnotations.map(
                  (error) => (
                    <li key={error.message}>{error.message}</li>
                  ),
                )}
              </ul>
            </SNStatusBanner>
          )}
        <MetadataSection fileId={open.entityId} />
        <LoadingOverlay isLoading={loading} data-testid="updating-metadata" />
      </DialogContent>
      <DialogActions>
        <SNButton onClick={onClose}>Cancel</SNButton>
        <SNButton snVariant="primary" onClick={handleUpdateMetadata}>
          Save
        </SNButton>
      </DialogActions>
    </>
  );
};

export default MetadataEditModal;
