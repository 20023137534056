import React from 'react';
import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
import SearchResultsFieldValidationsList from '../SearchResultsEditFieldValidationsList';
import SNButton from '../SNButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEditEntitiesState } from '../../contexts';
import SNInput from '../SNInput';
import { countValidations } from '../../utils/countValidations';
import SNDialogTitle from '../SNDialogTitle';
import pluralize from 'pluralize';

interface EntityEditApprovalModalProps {
  onClose: () => void;
  onSubmit: (value: string) => void;
}

const EntityEditApprovalModal = ({
  onClose,
  onSubmit,
}: EntityEditApprovalModalProps) => {
  const editState = useEditEntitiesState();
  const validationNumbers = countValidations(editState);
  const [approvalReason, setApprovalReason] = React.useState('');

  return (
    <>
      <SNDialogTitle onClose={onClose}>Approve Data</SNDialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textSecondary">
          These edits have {validationNumbers.fieldsWithValidations} unresolved
          &nbsp;{pluralize('issue', validationNumbers.fieldsWithValidations)}.
          Please state your reason for approving the edits as entered.
        </Typography>
        <Box my={2}>
          <SNInput
            label="Approval Notes"
            required
            value={approvalReason}
            onChange={(e) => setApprovalReason(e.target.value)}
            multiline
            fullWidth
            rows={3}
          />
        </Box>
        <Typography variant="h4">Issues:</Typography>
        <SearchResultsFieldValidationsList />
      </DialogContent>
      <DialogActions>
        <SNButton onClick={onClose}>Back to editing</SNButton>
        <SNButton
          onClick={() => onSubmit(approvalReason)}
          snVariant="approve"
          disabled={!approvalReason}
          startIcon={<CheckCircleIcon />}
        >
          Approve data
        </SNButton>
      </DialogActions>
    </>
  );
};

export default EntityEditApprovalModal;
