import React from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
import SNButton from '../SNButton';
import { useRecoilValue } from 'recoil';
import { isEditingEntitiesAtom } from '../../atoms/editEntities';
import { Box, Dialog } from '@mui/material';
import ExportOptionsModal from '../ExportOptionsModal';
import { glideTableSelection } from '../../atoms/glideTableSelection';

interface ActionExportEntitiesProps {
  pageTotal: number;
  page: number;
  searchId: string;
  tableId: string;
  isReview: boolean;
}

const ActionExportEntities = ({
  pageTotal,
  page,
  searchId,
  tableId,
  isReview,
}: ActionExportEntitiesProps) => {
  const isEditing = useRecoilValue(isEditingEntitiesAtom);
  const [exportModalOpen, setExportModalOpen] = React.useState(false);
  const selectedIds = useRecoilValue(glideTableSelection(tableId));

  return (
    <>
      {!isEditing && !isReview && selectedIds[page]?.rows.length === 0 && (
        <Box px={1}>
          <SNButton
            disabled={pageTotal === 0}
            startIcon={<GetAppIcon />}
            onClick={() => setExportModalOpen(true)}
            snVariant="text"
          >
            Export
          </SNButton>
        </Box>
      )}

      <Dialog open={exportModalOpen} fullWidth maxWidth="sm">
        <ExportOptionsModal
          setClosed={() => setExportModalOpen(false)}
          searchId={searchId}
        />
      </Dialog>
    </>
  );
};

export default ActionExportEntities;
