import React from 'react';
import {
  useFilterLeafAdd,
  useFilterBranchAdd,
  useFilterBranchUpdate,
} from '../../hooks';
import { Box } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import SNButton from '../SNButton';

interface SearchFormFilterAddButtonProps {
  depth: number;
  onClick: (newId: string) => void;
  parentId: string;
}

const SearchFormFilterAddButton = ({
  depth,
  onClick,
  parentId,
}: SearchFormFilterAddButtonProps) => {
  const addFilterLeaf = useFilterLeafAdd();
  const addFilterBranch = useFilterBranchAdd();
  const updateFilterBranch = useFilterBranchUpdate();
  const handleFilterClick = () => {
    const newLeafId = addFilterLeaf({
      parentId,
      fieldId: 'system.displayName',
      type: 'STRING',
    });
    onClick(newLeafId);
  };

  const handleGroupClick = () => {
    const newBranchId = addFilterBranch({ parentId, children: [] });
    const newLeafId = addFilterLeaf({
      parentId: newBranchId,
      fieldId: 'system.displayName',
      type: 'STRING',
    });
    updateFilterBranch({
      id: newBranchId,
      changes: { children: [newLeafId] },
    });
    onClick(newBranchId);
  };

  return (
    <Box display="flex" justifyContent="flex-start" width="100%">
      <SNButton
        size="small"
        disableRipple
        snVariant="text"
        onClick={handleFilterClick}
        startIcon={<AddIcon />}
      >
        Add Filter
      </SNButton>
      <SNButton
        disabled={depth > 2}
        size="small"
        disableRipple
        snVariant="text"
        onClick={handleGroupClick}
        startIcon={<AddIcon />}
      >
        Add Group
      </SNButton>
    </Box>
  );
};

export default SearchFormFilterAddButton;
