import { atom } from 'recoil';

export interface GeneralError {
  open: boolean;
  message: string;
  details: string;
}

export const GeneralErrorSnackbarAtom = atom<GeneralError>({
  key: 'generalErrorSnackbarAtom',
  default: {
    open: false,
    message: 'Please try again or contact Snthesis for help.',
    details: `We're looking into it.`,
  },
});
