import { Box, Grid } from '@mui/material';
import React from 'react';
import SelectableListToolbar from '../SelectableListToolbar';
import { useSetRecoilState } from 'recoil';
import { pageIdsAtom } from '../../atoms/listSelection';
import { useProjectsOfUserQuery } from '../../queries/useProjectsOfUserQuery';
import { useParams } from 'react-router-dom';
import SNTable from '../SNTable';
import SNPagination from '../SNPagination';
import RoleHeaderModalButton from '../RoleHeaderModalButton';
import ToolbarActionGroup from '../ToolbarActionGroup';
import ListSearchControl from '../ListSearchControl';
import UserProjectsTableRowReadOnly from '../UserProjectsTableRowReadOnly';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { collectErrors } from '../../utils/collectErrors';
import { useDebounce } from '../../hooks';

const headers = [
  'name',
  'description',
  <RoleHeaderModalButton key="usersTableRoleButton" label="Project role" />,
  'access',
  'date added',
];

interface UserDetailProjectsTableProps {
  desiredUserId?: string;
}

const UserDetailProjectsTableReadOnly = ({
  desiredUserId,
}: UserDetailProjectsTableProps) => {
  const pageSize = 10;
  const { id } = useParams<{ id: string }>();
  const userId = React.useMemo(() => {
    return desiredUserId ? desiredUserId : id;
  }, [id, desiredUserId]);

  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { data, loading, error, fetchMore, refetch } = useProjectsOfUserQuery({
    variables: {
      id: userId,
      first: pageSize,
      filter: { searchTerm: debouncedSearchTerm },
    },
    onCompleted: (result) => {
      const pageIds = result?.user?.user?.projectAccess?.edges.map(
        (edge) => edge.node.id,
      );
      setPageIds(pageIds || []);
    },
  });
  React.useEffect(() => {
    refetch({
      id: userId,
      first: pageSize,
      filter: { searchTerm: debouncedSearchTerm },
    });
  }, [data, refetch, userId, debouncedSearchTerm]);

  const projectAccess = React.useMemo(
    () => data?.user?.user?.projectAccess?.edges.map((edge) => edge.node),
    [data],
  );
  const pageTotal = projectAccess?.length || 0;

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.user?.errors]);
  }, [data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: "Error fetching user's projects",
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <Box>
      <SelectableListToolbar>
        <SNPagination
          {...data?.user?.user?.projectAccess?.pageInfo}
          fetchMore={fetchMore}
          loading={loading}
          pageSize={pageSize}
          pageTotal={pageTotal}
          totalCount={data?.user?.user?.projectAccess?.totalCount}
        />
        <Box>
          <Grid container spacing={1}>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 0}
            >
              <Grid item>
                <ListSearchControl
                  setValue={setSearchTerm}
                  value={searchTerm}
                />
              </Grid>
            </ToolbarActionGroup>
          </Grid>
        </Box>
      </SelectableListToolbar>
      <SNTable
        error={error}
        hasResults={!!projectAccess}
        headers={headers}
        id="user-projects"
        loading={loading}
        rowCount={pageTotal}
        selectable={false}
      >
        {projectAccess?.map(({ project, sources, ...projectAccess }) => (
          <UserProjectsTableRowReadOnly
            key={projectAccess.id}
            sourceCount={sources.length}
            {...projectAccess}
            {...project}
          />
        ))}
      </SNTable>
    </Box>
  );
};

export default UserDetailProjectsTableReadOnly;
