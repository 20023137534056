import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import Checkbox from '../Checkbox';
import { useRecoilState } from 'recoil';
import { listItemByIndexSelector } from '../../atoms/listSelection';
import moment from 'moment';
import SNTableRow from '../SNTableRow';
import { Link as RouterLink } from 'react-router-dom';
import { Projects_projects_data_edges_node_projectAccess_edges } from '../../types/schemaTypes';
import UserSummary from '../UserSummary';
import ListSummaryButton from '../ListSummaryButton';
import pluralize from 'pluralize';

interface ProjectsTableRowProps {
  name: string | null;
  id: string;
  description: string | null;
  lastModified: string;
  totalProjectAccessCount: number;
  totalGroupCount: number;
  projectAccess:
    | Projects_projects_data_edges_node_projectAccess_edges[]
    | undefined;
  index: number;
}

const ProjectsTableRow = ({
  name,
  id,
  description,
  lastModified,
  totalProjectAccessCount,
  totalGroupCount,
  projectAccess,
  index,
}: ProjectsTableRowProps) => {
  const [selected, selectRow] = useRecoilState(listItemByIndexSelector(index));

  const handleRowSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      selectRow(checked);
    },
    [selectRow],
  );

  const users = projectAccess?.map((access) => {
    return access.node.user;
  });

  return (
    <SNTableRow selected={selected} key={id}>
      <td>
        <Box pl={4} pr={3} position="relative">
          <Checkbox checked={selected} onChange={handleRowSelect} />
        </Box>
      </td>
      <td>
        <Link to={`/settings/projects/${id}`} component={RouterLink}>
          <Box display="flex" alignItems="center" height={56}>
            <Box fontWeight="600">
              <Typography>{name}</Typography>
            </Box>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/projects/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <Box overflow="hidden" textOverflow="ellipsis">
              <Typography>{description}</Typography>
            </Box>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/projects/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            {!users || users?.length === 0 ? (
              <Typography color="textSecondary">
                No users with access
              </Typography>
            ) : (
              <UserSummary
                users={users}
                usersToShow={5}
                totalUsers={totalProjectAccessCount}
              />
            )}
          </Box>
        </Link>
      </td>
      <td>
        {totalGroupCount > 0 ? (
          <Link to={`/settings/projects/${id}/groups`} component={RouterLink}>
            <Box height={56} display="flex" alignItems="center">
              <ListSummaryButton variant="text">
                {totalGroupCount} {pluralize('Group', totalGroupCount)}
              </ListSummaryButton>
            </Box>
          </Link>
        ) : (
          <Link to={`/settings/projects/${id}`} component={RouterLink}>
            <Box height={56} display="flex" alignItems="center">
              <Typography color="textSecondary">
                No groups with access
              </Typography>
            </Box>
          </Link>
        )}
      </td>
      <td>
        <Link to={`/settings/projects/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <Typography>{moment(lastModified).format('YYYY-MM-DD')}</Typography>
          </Box>
        </Link>
      </td>
    </SNTableRow>
  );
};

export default ProjectsTableRow;
