import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from '../../services/styled';
import { EntityHistory_entityHistory_edges_node_changes } from '../../types/schemaTypes';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link } from 'react-router-dom';

interface HistoryEventDetailProps {
  change: EntityHistory_entityHistory_edges_node_changes | null;
}

const ChangeBox = styled(Box)`
  margin-top: -1px;
  background: ${(p) => p.theme.palette.background.default};
  border: 1px solid ${(p) => p.theme.palette.grey[600]};
  display: flex;
`;

const KeyBox = styled(Box)`
  border-right: 1px solid ${(p) => p.theme.palette.grey[600]};
  width: 30%;
  word-break: break-word;
  padding: 8px;
`;

const StrikeType = styled(Typography)`
  text-decoration: line-through;
`;
const WhiteLink = styled(Link)`
  text-decoration-color: ${(p) => p.theme.palette.text.primary};
`;

const HistoryEventDetail = ({ change }: HistoryEventDetailProps) => {
  if (change === null) {
    return null;
  }
  const getChangeContent = (
    change: EntityHistory_entityHistory_edges_node_changes,
  ) => {
    switch (change.values?.__typename) {
      case 'EntityHistorySimpleChange':
        return (
          <Box display="flex">
            <StrikeType>{change.values?.simpleChangeOld}</StrikeType>
            <Box mx={1}>
              <ArrowRightAltIcon />
            </Box>
            <Typography>{change.values?.simpleChangeNew}</Typography>
          </Box>
        );
      case 'EntityHistorySimpleAddition':
        return <Typography>{change.values?.simpleAdditionNew}</Typography>;
      case 'EntityHistoryEntityChange':
        return (
          <Box display="flex">
            <WhiteLink to={`${change.values?.entityChangeOld?.id}`}>
              <StrikeType color="textPrimary">
                {change.values?.entityChangeOld?.displayName}
              </StrikeType>
            </WhiteLink>
            <Box mx={1}>
              <ArrowRightAltIcon />
            </Box>
            <WhiteLink to={`${change.values?.entityChangeNew?.id}`}>
              <Typography color="textPrimary">
                {change.values?.entityChangeNew?.displayName}
              </Typography>
            </WhiteLink>
          </Box>
        );
      case 'EntityHistoryEntityAddition':
        return (
          <WhiteLink to={`${change.values?.entityAdditionNew?.id}`}>
            <Typography color="textPrimary">
              {change.values?.entityAdditionNew?.displayName}
            </Typography>
          </WhiteLink>
        );
      default:
        return null;
    }
  };

  return (
    <ChangeBox>
      <KeyBox>
        <Typography color="textSecondary">{change.key}</Typography>
      </KeyBox>
      <Box p={1}>{getChangeContent(change)}</Box>
    </ChangeBox>
  );
};

export default HistoryEventDetail;
