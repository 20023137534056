import pluralize from 'pluralize';
import { SearchResultDatum } from '../types';
import {
  EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges,
  EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity,
} from '../types/schemaTypes';

export const mapEntityToTableRow = (
  entity: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges_node_entity,
): Record<string, SearchResultDatum> => {
  const {
    annotations,
    createdBy,
    createdOn,
    displayStatus,
    displayName,
    fieldValues,
    id,
    lastModified,
    linkSummaries,
    metadata: { sourceType },
    userMetadata,
  } = entity;
  let metadataValue = 'No User Metadata';
  if (userMetadata.length > 0) {
    metadataValue = `${userMetadata.length} ${pluralize(
      'value',
      userMetadata.length,
    )}`;
  }
  return {
    'system.displayName': { value: displayName, displayStatus, annotations },
    'user.metadata': {
      value: metadataValue,
      additionalData: userMetadata,
    },
    'system.createdOn': { value: createdOn },
    'system.createdBy': { value: createdBy.name || '' },
    'system.lastModified': { value: lastModified },
    'system.sourceType': { value: sourceType || '' },
    id: { value: id },
    ...linkSummaries.reduce<Record<string, SearchResultDatum>>(
      (data, linkSummary) => ({
        ...data,
        [linkSummary.linkDefinition.id]: {
          value: linkSummary.value,
          linkSummaryId: linkSummary.id,
        },
      }),
      {},
    ),
    ...fieldValues.reduce<Record<string, SearchResultDatum>>(
      (data, fieldValue) => ({
        ...data,
        [fieldValue.fieldDefinition.id]: {
          annotations: fieldValue.annotations,
          displayStatus: fieldValue.displayStatus,
          value: fieldValue.value,
        },
      }),
      {},
    ),
  };
};

export const mapEntityResultToTableRow = (
  entityResult: EntitiesByTypeForSearch_entitiesByTypeForSearch_data_edges,
): Record<string, SearchResultDatum> =>
  mapEntityToTableRow(entityResult.node.entity);
