export const entityTypes = {
  any: 'sn:any',
  rawObject: 'sn:rawObject',
};

export const dataPoints = {
  fileName: 'sn:uploadFileName',
  subsetName: 'sn:uploadSubset',
};

export const linkTypes = {
  extractedFrom: 'sn:extractedFrom',
};

export const defaultProjectId = 'default-project-id';
