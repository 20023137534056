import React from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
import {
  ClassificationColumnRename,
  ClassificationDetail,
  EntityJobState,
  EntityJobType,
} from '../../types/schemaTypes';
import ShadowBox from '../ShadowBox';
import SNButton from '../SNButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SNDialogTitle from '../SNDialogTitle';
import ClassificationQuestionClassifyType from '../ClassificationQuestionClassifyType';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { ClassificationWizardFormInfo } from '../../atoms/ClassificationWizardFormInfo';
import ClassificationQuestionRepairExistingColumns from '../ClassificationQuestionRepairExistingColumns';
import { useCompleteSearchObject } from '../../contexts';
import { searchInputTransform } from '../../utils';
import ClassificationQuestionAddMissingColumns from '../ClassificationQuestionAddMissingColumns';
import ClassificationTable from '../ClassificationTable';
import ClassificationReview from '../ClassificationReview';
import ClassificationReviewTable from '../ClassificationReviewTable';
import { useClassifyEntitiesMutation } from '../../mutations/useClassifyEntitiesMutation';
import { UnclassifiedGroup } from '../../fragments/UnclassifiedGroup';
import {
  ClassificationJobsAtom,
  ClassificationSnackbarOpen,
} from '../../atoms/ClassificationJobsAtom';

export interface ClassificationWizardProps {
  groupId: string;
  onClose: () => void;
  searchId: string;
}

const ClassificationWizard = ({
  groupId,
  onClose,
  searchId,
}: ClassificationWizardProps) => {
  const client = useApolloClient();
  const unclassifiedGroup = client.readFragment<ClassificationDetail>({
    id: `UnclassifiedResultGroup:${groupId}`,
    fragment: gql`
      fragment ClassificationDetail on UnclassifiedResultGroup {
        ...UnclassifiedGroup
      }
      ${UnclassifiedGroup}
    `,
    fragmentName: 'ClassificationDetail',
  });

  const [questionIndex, setQuestionIndex] = React.useState(0);
  const [submitClassification] = useClassifyEntitiesMutation();
  const [showErrors, setShowErrors] = React.useState(false);
  const [classificationJobs, setClassificationJobs] = useRecoilState(
    ClassificationJobsAtom,
  );
  const [classificationErrors, setClassificationErrors] = React.useState<
    string[]
  >([]);
  const setClassificationSnackbarOpen = useSetRecoilState(
    ClassificationSnackbarOpen,
  );
  const [useSuggestions, setUseSuggestions] = React.useState<
    Record<string, boolean>
  >({});

  const [classificationWizardFormInfo, setClassificationWizardFormInfo] =
    useRecoilState(ClassificationWizardFormInfo);
  const resetFormToBlank = useResetRecoilState(ClassificationWizardFormInfo);

  const searchObject = useCompleteSearchObject(searchId);
  const searchQuery = React.useMemo(() => {
    return searchInputTransform(searchObject);
  }, [searchObject]);

  const setFormDefaults = React.useCallback(
    (entityTypeId: string) => {
      const selectedType = unclassifiedGroup?.matchingTypes.find(
        (group) => group.entityType.id === entityTypeId,
      );

      if (selectedType) {
        const columnsAsMetadata: string[] = [];
        const columnsToRename: ClassificationColumnRename[] = [];
        const invalidColumns = selectedType.invalidColumns;
        invalidColumns.forEach((col) => {
          setUseSuggestions((prev) => ({ ...prev, [col.header]: true }));
          if (col.suggestedColumns.length === 0) {
            columnsAsMetadata.push(col.header);
          } else {
            columnsToRename.push({
              sourceColumnName: col.header,
              targetFieldDefinitionId:
                col.suggestedColumns[0].fieldDefinitionId,
            });
          }
        });
        //check the missing cols against the renamed cols
        const filteredMissingColumns = selectedType.missingColumns.filter(
          (missingCol) => {
            let renamedMatch = false;
            columnsToRename.forEach((renamedCol) => {
              if (
                !renamedMatch &&
                renamedCol.targetFieldDefinitionId ===
                  missingCol.fieldDefinitionId
              ) {
                renamedMatch = true;
              }
            });
            if (!renamedMatch) {
              return missingCol;
            }
          },
        );

        const missingColumnValues = filteredMissingColumns?.map(
          (missingCol) => {
            return {
              fieldDefinitionId: missingCol.fieldDefinitionId,
              value: '',
            };
          },
        );

        setClassificationWizardFormInfo({
          groupId: groupId,
          searchQuery: searchQuery,
          selectedTypeId: selectedType.entityType.id,
          columnsAsMetadata: columnsAsMetadata,
          columnsToRename: columnsToRename,
          columnsToDelete: [],
          missingColumns: missingColumnValues,
        });
      }
    },
    [unclassifiedGroup, setClassificationWizardFormInfo, groupId, searchQuery],
  );

  React.useEffect(() => {
    setShowErrors(false);
  }, [classificationWizardFormInfo]);

  React.useEffect(() => {
    if (unclassifiedGroup?.matchingTypes[0]) {
      setFormDefaults(unclassifiedGroup.matchingTypes[0].entityType.id);
    }
  }, [
    unclassifiedGroup,
    setFormDefaults,
    searchQuery,
    groupId,
    setClassificationWizardFormInfo,
  ]);

  const handleModalClose = () => {
    setClassificationSnackbarOpen(classificationJobs.length > 0);
    setClassificationErrors([]);
    resetFormToBlank();
    onClose();
  };

  const handleNextClick = () => {
    setQuestionIndex((prev) => {
      if (
        prev === 1 &&
        classificationWizardFormInfo.columnsToRename.some(
          ({ targetFieldDefinitionId }) => !targetFieldDefinitionId,
        )
      ) {
        setShowErrors(true);
        return prev;
      }
      return prev + 1;
    });
  };
  const handleBackClick = () => {
    setQuestionIndex((prev) => prev - 1);
  };

  const handleSubmitClassification = () => {
    submitClassification({
      variables: { input: { ...classificationWizardFormInfo } },
      onCompleted: (res) => {
        if (res.classifyEntities.errors) {
          setClassificationErrors(res.classifyEntities.errors);
        } else {
          setClassificationJobs((previous) => {
            return [
              ...previous,
              {
                id: res.classifyEntities.data?.classificationJob.id || '',
                name: res.classifyEntities.data?.group.name || '',
                type:
                  res.classifyEntities.data?.classificationJob.type ||
                  EntityJobType.Classification,
                state:
                  res.classifyEntities.data?.classificationJob.state ||
                  EntityJobState.PROCESSING,
                groupId: res.classifyEntities.data?.group.id || '',
              },
            ];
          });
          handleModalClose();
          setClassificationSnackbarOpen(true);
        }
      },
    });
  };

  const getQuestion = React.useCallback(
    (questionIndex: number) => {
      switch (questionIndex) {
        case 0:
          return (
            <ClassificationQuestionClassifyType
              groupInfo={unclassifiedGroup}
              setFormDefaults={setFormDefaults}
            />
          );
        case 1:
          return (
            <ClassificationQuestionRepairExistingColumns
              useSuggestions={useSuggestions}
              setUseSuggestions={setUseSuggestions}
              showErrors={showErrors}
            />
          );
        case 2:
          return (
            <ClassificationQuestionAddMissingColumns
              groupInfo={unclassifiedGroup}
            />
          );
        case 3:
          return (
            <ClassificationReview
              groupId={groupId}
              classificationErrors={classificationErrors}
            />
          );
        default:
          return null;
      }
    },
    [
      classificationErrors,
      groupId,
      setFormDefaults,
      unclassifiedGroup,
      showErrors,
      useSuggestions,
    ],
  );

  return (
    <>
      <SNDialogTitle onClose={handleModalClose} fullScreen>
        <Box display="flex" alignItems="center">
          <Typography variant="h2">{unclassifiedGroup?.name} </Typography>
          {questionIndex === 3 && (
            <>
              <Box px={3} display="flex">
                <ArrowForwardIcon />
              </Box>
              <Typography variant="h2">
                {
                  unclassifiedGroup?.matchingTypes?.find(
                    (group) =>
                      group?.entityType?.id ===
                      classificationWizardFormInfo?.selectedTypeId,
                  )?.name
                }
              </Typography>
            </>
          )}
        </Box>
      </SNDialogTitle>
      <DialogContent>
        <Box
          display="flex"
          height="100%"
          borderTop={1}
          borderColor="grey.600"
          mx={-4}
        >
          <Box
            flex="2 1 0"
            height="100%"
            borderRight={1}
            borderColor="grey.600"
            bgcolor="background.default"
            overflow="hidden"
          >
            {questionIndex === 3 ? (
              <ClassificationReviewTable />
            ) : (
              <ClassificationTable groupId={groupId} searchId={searchId} />
            )}
          </Box>
          <Box flex="1 1 0" height="100%" py={2} px={3} overflow="auto">
            {getQuestion(questionIndex)}
          </Box>
        </Box>
      </DialogContent>
      <ShadowBox
        placement="below"
        isOverlapping
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <DialogActions>
          <SNButton onClick={handleModalClose} snVariant="light">
            Cancel
          </SNButton>
        </DialogActions>
        <DialogActions>
          <SNButton
            onClick={handleBackClick}
            disabled={questionIndex === 0}
            snVariant="light"
          >
            Back
          </SNButton>
          {questionIndex < 3 ? (
            <SNButton snVariant="primary" onClick={handleNextClick}>
              Next
            </SNButton>
          ) : (
            <SNButton snVariant="primary" onClick={handleSubmitClassification}>
              Done
            </SNButton>
          )}
        </DialogActions>
      </ShadowBox>
    </>
  );
};

export default ClassificationWizard;
