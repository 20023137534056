import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  GrantUserAccessToProject,
  GrantUserAccessToProjectVariables,
} from '../types/schemaTypes';

export const GRANT_PROJECT_ACCESS_TO_USER = gql`
  mutation GrantUserAccessToProject($input: GrantUserAccessToProjectInput!) {
    grantUserAccessToProject(input: $input) {
      projectAccess {
        project {
          id
          name
        }
        user {
          id
          name
        }
        userRole
      }
      errors
    }
  }
`;

export const useGrantUserToProjectsMutation = (
  options?: MutationHookOptions<
    GrantUserAccessToProject,
    GrantUserAccessToProjectVariables
  >,
) => {
  return useMutation<
    GrantUserAccessToProject,
    GrantUserAccessToProjectVariables
  >(GRANT_PROJECT_ACCESS_TO_USER, options);
};
