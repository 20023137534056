import React from 'react';
import { useFilterLeafUpdate } from '../../hooks';
import SNDatePicker from '../SNDatePicker';
import moment, { Moment } from 'moment';
import { getSafeDateValue } from '../../utils/getSafeDateValue';

interface SearchFormFilterLeafDateInputProps {
  disabled: boolean;
  id: string;
  value: Moment | null;
}

const SearchFormFilterLeafDateInput = ({
  disabled,
  id,
  value,
}: SearchFormFilterLeafDateInputProps) => {
  const [localValue, setLocalValue] = React.useState<Moment | null>(value);

  const updateFilterLeaf = useFilterLeafUpdate();

  const handleBlur = () => {
    if (localValue) {
      updateFilterLeaf(id, {
        value: [
          {
            value: moment(localValue.toString()).format('YYYY-MM-DD'),
            type: 'DATE',
          },
        ],
      });
    }
  };

  const handleDateChange = React.useCallback(
    (date: Date | null) => {
      const newValue = date
        ? moment(date.toString()).format('YYYY-MM-DD')
        : null;

      if (newValue) {
        setLocalValue(moment(newValue));
        updateFilterLeaf(id, {
          value: [
            {
              value: moment(newValue.toString()).format('YYYY-MM-DD'),
              type: 'DATE',
            },
          ],
        });
      } else {
        setLocalValue(null);
      }
    },
    [id, updateFilterLeaf, setLocalValue],
  );

  return (
    <SNDatePicker
      size="small"
      disabled={disabled}
      fullWidth
      id={id}
      onBlur={handleBlur}
      onChange={handleDateChange}
      value={getSafeDateValue(
        localValue ? localValue.toString() : null,
        value ? value.toString() : null,
      )}
    />
  );
};

export default SearchFormFilterLeafDateInput;
