import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { User, UserVariables } from '../types/schemaTypes';

export const USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      user {
        id
        email
        name
        picture
        defaultRole
        preferences {
          uploadWizardSkipAllQuestions
          uploadWizardSkipAllQuestionsPrompt
          rememberExportOptions
          exportOptionsPreference
        }
      }
      errors
    }
  }
`;

export const useUserQuery = (
  options: QueryHookOptions<User, UserVariables>,
) => {
  return useQuery<User, UserVariables>(USER, {
    ...options,
  });
};
