import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';

import {
  useSearchTreeBranches,
  useSearchTreeNavDispatch,
} from '../../contexts';
import styled from '../../services/styled';
import SearchTreeLeaf from '../SearchTreeLeaf';
import { useHover } from '../../hooks';
import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { SEARCH_TARGET_TYPE_RECONVERSION_HASH } from '../../constants';
import { SearchBranchSearchTarget } from '../../types/schemaTypes';

interface SearchTreeBranchProps {
  id: string;
  isForked?: boolean;
  isOnlyRoot?: boolean;
  onDelete: (id: string) => void;
  searchId: string;
}

const StyledContainerBox = styled(Box)`
  vertical-align: top;
`;

const StyledEllipsisBox = styled(Box)`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledTypographySecondary = styled(Typography)`
  font-size: 14px;
  line-height: 19px;
`;

const SearchTreeBranch = ({
  id,
  isForked,
  isOnlyRoot,
  onDelete,
  searchId,
}: SearchTreeBranchProps) => {
  const client = useApolloClient();
  const setActiveId = useSearchTreeNavDispatch();
  const branches = useSearchTreeBranches(searchId);
  const treeBranch = branches[id];
  const [hoverRef, isHovered, setIsHovered] = useHover<HTMLDivElement>();

  const fragmentIdentifier = `${
    SEARCH_TARGET_TYPE_RECONVERSION_HASH[treeBranch.target.type]
  }:${treeBranch.target.id}`;

  const searchTarget = client.readFragment<SearchBranchSearchTarget>({
    id: fragmentIdentifier,
    fragment: gql`
      fragment SearchBranchSearchTarget on SearchTarget {
        ... on LinkType {
          name
        }
        ... on EntityType {
          name
        }
      }
    `,
  });
  const targetLength = React.useMemo(() => {
    return (searchTarget?.name?.length || 0) * 12.5;
  }, [searchTarget]);

  if (!searchTarget) return null;

  const {
    children,
    filter: { filterLeaves },
    parent,
  } = treeBranch;
  const filterIds = Object.keys(filterLeaves);
  const filterCount = filterIds.length;
  const firstFilter = filterLeaves[filterIds[0]];
  const description =
    filterCount === 1 && firstFilter
      ? `with '${firstFilter.value[0].value.toString()}'`
      : `${filterCount} filter${filterCount !== 1 ? 's' : ''}`;

  function handleAddChild() {
    setIsHovered(false);
    setActiveId({ showForm: true, activeId: null, parentId: id });
  }

  return (
    <Box
      display={isForked ? 'block' : 'inline-block'}
      data-testid={`search-tree-branch-${id}`}
    >
      <Box>
        <Tooltip
          title={targetLength > 200 ? searchTarget.name : ''}
          placement="top-start"
        >
          <StyledContainerBox p="1px" display="inline-block">
            <SearchTreeLeaf
              id={id}
              hoverRef={hoverRef}
              isLast={children.length === 0}
              isHovered={isHovered}
              onAddChild={handleAddChild}
              onDelete={isOnlyRoot ? undefined : onDelete}
              parentId={parent}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <StyledEllipsisBox>
                  <Typography variant="h6" noWrap>
                    {searchTarget.name}
                  </Typography>
                </StyledEllipsisBox>
                <StyledEllipsisBox>
                  <StyledTypographySecondary noWrap>
                    {description}
                  </StyledTypographySecondary>
                </StyledEllipsisBox>
              </Box>
            </SearchTreeLeaf>
          </StyledContainerBox>
        </Tooltip>
        {children.length > 0 && (
          <Box
            display={children.length > 1 ? 'block' : 'inline-block'}
            paddingLeft={children.length > 1 ? '101px' : 0}
          >
            {children.map((childId) => {
              return (
                <SearchTreeBranch
                  key={childId}
                  id={childId}
                  isForked={children.length > 1}
                  onDelete={onDelete}
                  searchId={searchId}
                />
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SearchTreeBranch;
