import React from 'react';
import { Box, BoxProps } from '@mui/material';

const SNToolbar: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height={65}
      px={2}
      bgcolor="grey.900"
      borderLeft={0}
      borderBottom={0}
      borderRight={0}
      borderColor="grey.600"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default SNToolbar;
