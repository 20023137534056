import React from 'react';
import { useRecoilValue } from 'recoil';
import { SearchSaveDuplicate } from '.';
import { selectedIdsAtom } from '../../atoms/listSelection';
import { SavedSearchType } from '../../types/schemaTypes';

interface SearchSaveDuplicateConnectedProps {
  onCopyComplete: () => void;
  savedSearchType: SavedSearchType;
}

const SearchSaveDuplicateConnected = ({
  onCopyComplete,
  savedSearchType,
}: SearchSaveDuplicateConnectedProps) => {
  const selectedIds = useRecoilValue(selectedIdsAtom);

  if (selectedIds.length !== 1) {
    return null;
  }

  return (
    <SearchSaveDuplicate
      id={selectedIds[0]}
      refetch={onCopyComplete}
      type={savedSearchType}
    />
  );
};

export default SearchSaveDuplicateConnected;
