import React from 'react';
import { Box } from '@mui/material';
import ListSummaryButton from '../ListSummaryButton';

interface ListSummaryTagProps {
  remainingLabelPlural: string;
  remainingLabelSingular: string;
  totalCount: number;
  firstItemDisplayTitle?: string;
  onFirstItemClick?: () => void;
  onRemainingClick?: () => void;
}

const ListSummaryTag = ({
  remainingLabelPlural,
  remainingLabelSingular,
  firstItemDisplayTitle,
  totalCount,
  onFirstItemClick,
  onRemainingClick,
}: ListSummaryTagProps) => {
  const handleFirstItemClick = () => {
    if (onFirstItemClick) onFirstItemClick();
  };

  const handleRemainingClick = () => {
    if (onRemainingClick) onRemainingClick();
  };

  if (totalCount === 1 && firstItemDisplayTitle) {
    return (
      <Box display="flex" alignItems="center">
        <ListSummaryButton onClick={handleFirstItemClick}>
          {firstItemDisplayTitle}
        </ListSummaryButton>
      </Box>
    );
  } else if (totalCount > 1 && firstItemDisplayTitle) {
    return (
      <Box display="flex" alignItems="center">
        <Box mr={2}>
          <ListSummaryButton variant="text" onClick={handleFirstItemClick}>
            {firstItemDisplayTitle}
          </ListSummaryButton>
        </Box>
        <ListSummaryButton variant="text" onClick={handleRemainingClick}>
          {totalCount - 1 > 1
            ? `+ ${totalCount - 1} ${remainingLabelPlural}`
            : `+ ${totalCount - 1} ${remainingLabelSingular}`}
        </ListSummaryButton>
      </Box>
    );
  } else {
    return (
      <Box display="flex" alignItems="center">
        <ListSummaryButton variant="text" onClick={handleRemainingClick}>
          {totalCount > 1
            ? `${totalCount} ${remainingLabelPlural}`
            : `${totalCount} ${remainingLabelSingular}`}
        </ListSummaryButton>
      </Box>
    );
  }
};

export default ListSummaryTag;
