import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  AddControlledVocabularyCategory,
  AddControlledVocabularyCategoryVariables,
} from '../types/schemaTypes';

export const CREATE_LIST = gql`
  mutation AddControlledVocabularyCategory(
    $input: AddControlledVocabularyCategoryInput!
  ) {
    addControlledVocabularyCategory(input: $input) {
      data {
        updatedAt
        createdAt
        description
        id
        name
      }
      errors
    }
  }
`;

export const useCreateListMutation = (
  options?: MutationHookOptions<
    AddControlledVocabularyCategory,
    AddControlledVocabularyCategoryVariables
  >,
) => {
  return useMutation<
    AddControlledVocabularyCategory,
    AddControlledVocabularyCategoryVariables
  >(CREATE_LIST, options);
};
