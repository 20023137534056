import React from 'react';
import { useRecoilValue } from 'recoil';
import { ClassificationWizardFormInfo } from '../../atoms/ClassificationWizardFormInfo';
import { useApolloClient } from '@apollo/client';
import { EntityTypeFields } from '../../types/schemaTypes';
import { Box, Typography } from '@mui/material';
import styled from '../../services/styled';
import StickyColumnHeading from '../StickyColumnHeading';
import SNTableRow from '../SNTableRow';
import { useOnScreen } from '../../hooks/useOnScreen';
import { EntityTypeFieldsFragment } from '../../fragments/EntityTypeFields';

const StyledTable = styled('table')`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
`;
const StyledHead = styled('thead')`
  padding: 16px;
`;

const StyledCell = styled('td')`
  padding: 16px 16px 16px 34px;
  border-bottom: 1px solid ${(p) => p.theme.palette.grey[600]};
`;

const StyledScrollTracker = styled('tr')`
  transform: translateY(-34px); // table heading height
`;

const ClassificationReviewTable = () => {
  const classificationWizardFormInfo = useRecoilValue(
    ClassificationWizardFormInfo,
  );
  const client = useApolloClient();
  const selectedEntityTypeFieldInfo = client.readFragment<EntityTypeFields>({
    id: `EntityType:${classificationWizardFormInfo.selectedTypeId}`,
    fragment: EntityTypeFieldsFragment,
  });

  const [ref, setRef] = React.useState<React.RefObject<HTMLTableRowElement>>({
    current: null,
  });
  const isOnScreen = useOnScreen(ref, {});
  const measuredRef = React.useCallback((node: HTMLTableRowElement | null) => {
    setRef({ current: node });
  }, []);

  const addedColumns = React.useMemo(() => {
    return classificationWizardFormInfo.missingColumns.map((missingCol) => {
      return {
        columnName: selectedEntityTypeFieldInfo?.fields?.find(
          (field) => field.id === missingCol.fieldDefinitionId,
        )?.name,
        defaultValue: missingCol.value,
      };
    });
  }, [selectedEntityTypeFieldInfo, classificationWizardFormInfo]);
  const renamedColumns = React.useMemo(() => {
    return classificationWizardFormInfo.columnsToRename.map((renamedCol) => {
      return {
        initialColumnName: renamedCol.sourceColumnName,
        finalColumnName: selectedEntityTypeFieldInfo?.fields?.find(
          (field) => field.id === renamedCol.targetFieldDefinitionId,
        )?.name,
        finalColumnId: renamedCol.targetFieldDefinitionId,
      };
    });
  }, [classificationWizardFormInfo, selectedEntityTypeFieldInfo]);
  const matchedColumns = React.useMemo(() => {
    return selectedEntityTypeFieldInfo?.fields?.filter((entityField) => {
      let found = false;
      classificationWizardFormInfo.columnsToRename.forEach((renamedCol) => {
        if (!found && renamedCol.targetFieldDefinitionId === entityField.id) {
          found = true;
        }
      });
      classificationWizardFormInfo.missingColumns.forEach((missingCol) => {
        if (!found && missingCol?.fieldDefinitionId === entityField.id) {
          found = true;
        }
      });
      return !found;
    });
  }, [selectedEntityTypeFieldInfo, classificationWizardFormInfo]);

  return (
    <Box overflow="auto" height="100%" position="relative">
      <StyledTable>
        <StyledHead>
          <tr>
            <StickyColumnHeading
              sx={{ paddingLeft: 4 }}
              isOverlapping={!isOnScreen}
            >
              <Typography variant="h6" color="textSecondary">
                Original Column
              </Typography>
            </StickyColumnHeading>
            <StickyColumnHeading
              sx={{ paddingLeft: 4 }}
              isOverlapping={!isOnScreen}
            >
              <Typography variant="h6" color="textSecondary">
                Action
              </Typography>
            </StickyColumnHeading>
            <StickyColumnHeading
              sx={{ paddingLeft: 4 }}
              isOverlapping={!isOnScreen}
            >
              <Typography variant="h6" color="textSecondary">
                Updated Column
              </Typography>
            </StickyColumnHeading>
          </tr>
        </StyledHead>
        <tbody>
          <StyledScrollTracker ref={measuredRef} />
          {matchedColumns?.map((matchedCol) => (
            <SNTableRow key={matchedCol.id}>
              <StyledCell>{matchedCol.name}</StyledCell>
              <StyledCell>Matched</StyledCell>
              <StyledCell>{matchedCol.name}</StyledCell>
            </SNTableRow>
          ))}
          {classificationWizardFormInfo.columnsAsMetadata.map((metadataCol) => (
            <SNTableRow key={metadataCol}>
              <StyledCell>{metadataCol}</StyledCell>
              <StyledCell>Captured as metadata</StyledCell>
              <StyledCell>{metadataCol}</StyledCell>
            </SNTableRow>
          ))}
          {renamedColumns.map((renamedCol) => (
            <SNTableRow key={renamedCol.finalColumnId}>
              <StyledCell>{renamedCol.initialColumnName}</StyledCell>
              <StyledCell>Renamed to</StyledCell>
              <StyledCell>{renamedCol.finalColumnName}</StyledCell>
            </SNTableRow>
          ))}
          {addedColumns.map((addedCol) => (
            <SNTableRow key={addedCol.columnName}>
              <StyledCell>&nbsp;</StyledCell>
              <StyledCell>
                {addedCol.defaultValue ? (
                  <Box>added with default value: {addedCol.defaultValue}</Box>
                ) : (
                  <Box>Empty column added</Box>
                )}
              </StyledCell>
              <StyledCell>{addedCol.columnName}</StyledCell>
            </SNTableRow>
          ))}
          {classificationWizardFormInfo.columnsToDelete.map((deletedCol) => (
            <SNTableRow key={deletedCol}>
              <StyledCell>{deletedCol}</StyledCell>
              <StyledCell>deleted</StyledCell>
              <StyledCell>&nbsp;</StyledCell>
            </SNTableRow>
          ))}
        </tbody>
      </StyledTable>
    </Box>
  );
};

export default ClassificationReviewTable;
