import { Box, Radio, RadioGroup, Typography } from '@mui/material';
import React from 'react';
import { ClassificationDetail } from '../../types/schemaTypes';
import { useRecoilValue } from 'recoil';
import { ClassificationWizardFormInfo } from '../../atoms/ClassificationWizardFormInfo';

interface ClassificationQuestionClassifyTypeProps {
  groupInfo: ClassificationDetail | null;
  setFormDefaults: (entityTypeId: string) => void;
}

const ClassificationQuestionClassifyType = ({
  groupInfo,
  setFormDefaults,
}: ClassificationQuestionClassifyTypeProps) => {
  const classificationWizardFormInfo = useRecoilValue(
    ClassificationWizardFormInfo,
  );

  const handleEntityTypeSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormDefaults(e.target.value);
  };

  if (groupInfo === null) {
    return null;
  }
  const possibleTypes = groupInfo.matchingTypes;

  return (
    <>
      <Box mb={2}>
        <Typography variant="h3">Step 1: Select Entity Type</Typography>
      </Box>
      <Typography>
        Select the correct entity type for this data. We found{' '}
        {possibleTypes.length} entity type{possibleTypes.length > 1 && 's'} that
        are a potential match
      </Typography>
      <RadioGroup
        value={classificationWizardFormInfo.selectedTypeId}
        name="selected-entity-type-radio-group"
        onChange={handleEntityTypeSelection}
      >
        {possibleTypes.map((type) => (
          <Box key={type?.entityType?.id} mt={2}>
            <Box display="flex" alignItems="center" component="label">
              <Radio value={type?.entityType?.id} />
              <Typography variant="h5">
                {type.name} - {type.confidence * 100}%
              </Typography>
            </Box>
            <Box ml={5}>
              <Typography variant="h6" color="textSecondary">
                Invalid Columns
              </Typography>
              <Box mt={1}>
                {type.invalidColumns.map((invalidCol, index) => (
                  <Typography key={invalidCol.header}>
                    {index + 1}.&nbsp;{invalidCol.header}
                  </Typography>
                ))}
              </Box>
            </Box>
            <Box mt={1} ml={5}>
              <Typography variant="h6" color="textSecondary">
                Missing Columns
              </Typography>
              <Box mt={1}>
                {type.missingColumns.map((missingCol, index) => (
                  <Typography key={missingCol.name}>
                    {index + 1}.&nbsp;{missingCol.name}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
        ))}
      </RadioGroup>
    </>
  );
};

export default ClassificationQuestionClassifyType;
