import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { GetSavedSearch, GetSavedSearchVariables } from '../types/schemaTypes';
export const GET_SAVED_SEARCH = gql`
  query GetSavedSearch($id: ID!) {
    getSavedSearch(id: $id) {
      data {
        id
        name
        description
        public
        createdAt
        searchQuery {
          branches {
            name
            branch {
              target {
                id
                type
              }
              select
              children
              parent
              filter {
                rootFilterId
                filterBranches {
                  name
                  branch {
                    parent
                    connective
                    name
                    children
                  }
                }
                filterLeaves {
                  name
                  branch {
                    parent
                    connective
                    fieldId
                    type
                    value {
                      value
                      type
                    }
                    comparator
                    metadata {
                      caseSensitive
                    }
                  }
                }
              }
              joinData {
                joinType
                joinDistance
                joinRequired
              }
            }
          }
          roots
        }
        updatedAt
        user {
          id
          name
          picture
        }
      }
      errors
    }
  }
`;

export const useGetSavedSearchQuery = (
  options?: QueryHookOptions<GetSavedSearch, GetSavedSearchVariables>,
) => {
  return useQuery<GetSavedSearch, GetSavedSearchVariables>(GET_SAVED_SEARCH, {
    ...options,
  });
};
