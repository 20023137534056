import { useQuery, QueryHookOptions } from '@apollo/client';
import gql from 'graphql-tag';
import { SearchEntity } from '../fragments';
import {
  EntitiesByTypeForSearch,
  EntitiesByTypeForSearchVariables,
} from '../types/schemaTypes';

export const ENTITIES_BY_TYPE_FOR_SEARCH = gql`
  query EntitiesByTypeForSearch(
    $first: Int
    $last: Int
    $searchQuery: SearchQuery
    $before: String
    $after: String
    $type: String
  ) {
    entitiesByTypeForSearch(
      last: $last
      first: $first
      searchQuery: $searchQuery
      before: $before
      after: $after
      type: $type
    ) {
      errors
      data {
        totalCount
        pageInfo {
          endCursor
          startCursor
          endIndex @client
          startIndex @client
          hasNextPage
          hasPreviousPage
        }
        types {
          id
          name
        }
        currentType {
          id
          name
        }
        edges {
          cursor
          node {
            matches {
              fieldName
              display {
                match
                type
              }
              highlightEnd
              highlightStart
            }
            entity {
              ...SearchEntity
            }
          }
        }
      }
    }
  }
  ${SearchEntity}
`;

export const useEntitiesByTypeForSearchQuery = (
  options?: QueryHookOptions<
    EntitiesByTypeForSearch,
    EntitiesByTypeForSearchVariables
  >,
) => {
  return useQuery<EntitiesByTypeForSearch, EntitiesByTypeForSearchVariables>(
    ENTITIES_BY_TYPE_FOR_SEARCH,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );
};
