import { useUserState } from '../contexts';
import { UserRole } from '../types/schemaTypes';
import { useUserQuery } from '../queries';
import { useProjectsOfUserQuery } from '../queries/useProjectsOfUserQuery';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import { GeneralErrorSnackbarAtom } from '../atoms/GeneralErrorSnackbarAtom';
import { collectErrors } from '../utils/collectErrors';

const useIsUserOwnerOf = (projectId: string) => {
  const user = useUserState();
  const { data: userData } = useUserQuery({ variables: { id: user.userId } });
  const { data: projectsData } = useProjectsOfUserQuery({
    variables: {
      id: userData?.user?.user?.id || '',
      first: 50,
    },
  });
  const [isUserOwnerOf, setIsUserOwnerOf] = React.useState(false);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([userData?.user?.errors, projectsData?.user?.errors]);
  }, [userData, projectsData]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: "Error determining user's project ownership",
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  React.useEffect(() => {
    const isUserAnOwner =
      userData?.user?.user?.defaultRole &&
      (userData.user.user.defaultRole === UserRole.OWNER ||
        userData.user.user.defaultRole === UserRole.ADMINISTRATOR);

    const thisProject = projectsData?.user?.user?.projectAccess?.edges
      .map((edge) => {
        return { role: edge.node.userRole, projectId: edge?.node?.project?.id };
      })
      .filter((project) => project.projectId === projectId)[0];

    setIsUserOwnerOf(
      (isUserAnOwner &&
        (thisProject?.role === UserRole.ADMINISTRATOR ||
          thisProject?.role === UserRole.OWNER)) ||
        false,
    );
  }, [userData, projectsData, projectId, setIsUserOwnerOf]);

  return isUserOwnerOf;
};

export default useIsUserOwnerOf;
