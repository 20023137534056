import { atomFamily, atom, selector, DefaultValue } from 'recoil';
import { GlideHeaderInfo } from '../types';

export const entityTableColumns = atomFamily<GlideHeaderInfo[], string>({
  key: 'search-table-columns',
  default: [],
});

export const entityTypeIds = atom<string[]>({
  key: 'entity-type-ids',
  default: [],
});

export const searchTableColumnsSelector = selector<
  Record<string, GlideHeaderInfo[]>
>({
  key: 'search-table-columns-selector',
  get: ({ get }) => {
    const entityIds = get(entityTypeIds);
    return entityIds.reduce(
      (result, entityId) => ({
        ...result,
        [entityId]: get(entityTableColumns(entityId)),
      }),
      {},
    );
  },
  set: ({ get, reset, set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      const entityIds = get(entityTypeIds);
      entityIds.forEach((entityId) => {
        reset(entityTableColumns(entityId));
      });
    } else {
      const newEntityIds = Object.keys(newValue);
      set(entityTypeIds, newEntityIds);
      Object.entries(newValue).forEach(([entityId, value]) => {
        set(entityTableColumns(entityId), value);
      });
    }
  },
});
