import * as React from 'react';

interface SearchTreeProviderProps {
  children: React.ReactNode;
}

interface SearchTreeNavState {
  showForm: boolean;
  activeId: string | null;
  parentId: string | null;
}
type Dispatch = (action: React.SetStateAction<SearchTreeNavState>) => void;

const SearchTreeStateContext = React.createContext<
  SearchTreeNavState | undefined
>(undefined);
const SearchTreeDispatchContext = React.createContext<Dispatch | undefined>(
  undefined,
);

const initialState: SearchTreeNavState = {
  showForm: false,
  activeId: null,
  parentId: null,
};

function SearchTreeNavProvider({ children }: SearchTreeProviderProps) {
  const [state, setState] = React.useState(initialState);
  return (
    <SearchTreeStateContext.Provider value={state}>
      <SearchTreeDispatchContext.Provider value={setState}>
        {children}
      </SearchTreeDispatchContext.Provider>
    </SearchTreeStateContext.Provider>
  );
}
function useSearchTreeNavState() {
  const context = React.useContext(SearchTreeStateContext);
  if (context === undefined) {
    throw new Error(
      'useSearchTreeNavState must be used within a SearchTreeNavProvider',
    );
  }
  return context;
}
function useSearchTreeNavDispatch() {
  const context = React.useContext(SearchTreeDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useSearchTreeNavDispatch must be used within a SearchTreeNavProvider',
    );
  }
  return context;
}
export {
  SearchTreeNavProvider,
  useSearchTreeNavState,
  useSearchTreeNavDispatch,
};
