import { EntityJobState } from '../../types/schemaTypes';
import {
  Box,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { useGetEntityJobStatus } from '../../queries/useGetEntityJobStatus';
import { useSetRecoilState } from 'recoil';
import { ClassificationJobsAtom } from '../../atoms/ClassificationJobsAtom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CategoryIcon from '@mui/icons-material/Category';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

interface ClassificationJobItemProps {
  id: string;
  name: string;
  groupId: string;
  state: EntityJobState;
}

const ClassificationJobItem = ({
  id,
  name,
  state,
}: ClassificationJobItemProps) => {
  const setClassificationJobs = useSetRecoilState(ClassificationJobsAtom);
  const [status, setStatus] = React.useState<EntityJobState | undefined>(state);
  const { data, stopPolling, startPolling } = useGetEntityJobStatus({
    variables: {
      id: id,
    },
  });

  React.useEffect(() => {
    if (status === EntityJobState.PROCESSING) {
      startPolling(1000);
    }
    if (
      status === EntityJobState.COMPLETE ||
      status === EntityJobState.FAILED
    ) {
      stopPolling();
      setTimeout(() => {
        setClassificationJobs((previous) => {
          const newJobs = previous.filter((job) => {
            return job.id !== id;
          });
          return newJobs;
        });
      }, 5000);
    }
    if (data?.getEntityJobStatus?.state !== status) {
      setStatus(data?.getEntityJobStatus?.state);
    }
  }, [id, setClassificationJobs, startPolling, stopPolling, status, data]);

  return (
    <ListItem>
      <ListItemAvatar>
        <CategoryIcon />
      </ListItemAvatar>
      <ListItemText primary={name} />
      <ListItemSecondaryAction>
        {status === EntityJobState.PROCESSING && <CircularProgress size={20} />}
        {status === EntityJobState.COMPLETE && (
          <Box color="success.main">
            <CheckCircleIcon color="inherit" />
          </Box>
        )}
        {status === EntityJobState.FAILED && <ErrorOutline color="error" />}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ClassificationJobItem;
