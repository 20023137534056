import React from 'react';
import ReviewTabs from '../../components/ReviewTabs';
import { SearchTreeNavProvider } from '../../contexts';

const Review = () => {
  return (
    <>
      <SearchTreeNavProvider>
        <ReviewTabs />
      </SearchTreeNavProvider>
    </>
  );
};

export default Review;
