import React from 'react';
import axios from 'axios';

import {
  useNewLocalFileUploadMutation,
  useRawObjectStorageCompleteMutation,
  useActiveUploadAddRemove,
  useActiveUploadUpdate,
} from '../mutations';
import { FileUploadStatus } from '../types/schemaTypes';
import { useUserState } from './UserContext';
import { useRecoilValue } from 'recoil';
import {
  uploadFilesByIdAtom,
  uploadLinkingContextsAtom,
  uploadLinksAtom,
  uploadMetadataByIdAtom,
  uploadProjectsAtom,
  uploadSubsetsByIdAtom,
} from '../atoms/uploadFiles';
import { extractProjectIdsByFileId } from '../utils/extractProjectIdsByFileId';

type HandleUpload = () => void;

const UploadContext = React.createContext<HandleUpload | undefined>(undefined);

const UploadProvider: React.FC = ({ children }) => {
  const user = useUserState();
  const [activeUploadAddRemove] = useActiveUploadAddRemove();
  const [activeUploadUpdate] = useActiveUploadUpdate();
  const [newLocalFileUploadMutation] = useNewLocalFileUploadMutation();
  const [rawObjectStorageCompleteMutation] =
    useRawObjectStorageCompleteMutation();

  const filesById = useRecoilValue(uploadFilesByIdAtom);
  const metadataById = useRecoilValue(uploadMetadataByIdAtom);
  const subsetsById = useRecoilValue(uploadSubsetsByIdAtom);
  const linkingContexts = useRecoilValue(uploadLinkingContextsAtom);
  const links = useRecoilValue(uploadLinksAtom);
  const projectInfo = useRecoilValue(uploadProjectsAtom);

  const handleUpload: HandleUpload = React.useCallback(() => {
    Object.entries(filesById).map(([fileId, file]) => {
      newLocalFileUploadMutation({
        variables: {
          input: {
            fileId: fileId,
            fileName: file.name,
            fileSize: file.size,
          },
        },
        update: async (cache, { data }) => {
          if (data) {
            const { newLocalFileUpload } = data;
            activeUploadAddRemove({
              variables: {
                activeUpload: {
                  id: newLocalFileUpload.uploadId,
                  fileName: file.name,
                  fileType: file.type,
                  status: FileUploadStatus.IN_PROGRESS,
                },
              },
            });
            newLocalFileUpload.uploadUrls.forEach(async (uploadUrl) => {
              try {
                const FILE_CHUNK_SIZE = 10000000; // 10MB
                const fileSize = file.size;
                const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1;
                const promisesArray = [];
                let start, end, blob;

                for (let index = 1; index < NUM_CHUNKS + 1; index++) {
                  start = (index - 1) * FILE_CHUNK_SIZE;
                  end = index * FILE_CHUNK_SIZE;
                  blob =
                    index < NUM_CHUNKS
                      ? file.slice(start, end)
                      : file.slice(start);

                  // (2) Puts each file part into the storage server
                  const uploadResp = axios.put(uploadUrl.url, blob, {
                    headers: { 'Content-Type': file.type },
                  });

                  promisesArray.push(uploadResp);
                }
                const resolvedArray = await Promise.all(promisesArray);

                const uploadPartsArray = resolvedArray.map(
                  (resolvedPromise, index) => ({
                    eTag: resolvedPromise.headers.etag,
                    partNumber: index + 1,
                  }),
                );

                if (user) {
                  window.setTimeout(() => {
                    rawObjectStorageCompleteMutation({
                      variables: {
                        input: {
                          fileId: fileId,
                          fileName: file.name,
                          fileSize: file.size,
                          linkingContexts: linkingContexts.filter(
                            (linkingContext) =>
                              linkingContext.selectedUploadIds.includes(fileId),
                          ),
                          links: links.filter((link) =>
                            link.selectedUploadIds.includes(fileId),
                          ),
                          metadata: Object.values(metadataById[fileId]).filter(
                            (metadata) => metadata.field && metadata.value,
                          ),
                          projectIds: extractProjectIdsByFileId(
                            fileId,
                            projectInfo,
                          ),
                          selectedSubsets: Object.entries(
                            subsetsById[fileId] || {},
                          )
                            .filter(([, selected]) => selected)
                            .map(([sheet]) => sheet),
                          subsets: Object.keys(subsetsById[fileId] || {}),
                          uploadId: newLocalFileUpload.uploadId,
                          uploadPartConfirmations: uploadPartsArray,
                          uploadedBy: user.userId,
                        },
                      },
                      update: (cache, { data }) => {
                        if (data && user) {
                          activeUploadUpdate({
                            variables: {
                              activeUpload: {
                                id: newLocalFileUpload.uploadId,
                                fileName: file.name,
                                fileType: file.type,
                                status: FileUploadStatus.COMPLETE,
                              },
                            },
                          });
                        }
                      },
                    });
                  }, 3000);
                }
              } catch (error) {
                console.log(error);
              }
            });
          }
        },
      });
    });
  }, [
    activeUploadAddRemove,
    activeUploadUpdate,
    filesById,
    linkingContexts,
    links,
    metadataById,
    newLocalFileUploadMutation,
    rawObjectStorageCompleteMutation,
    subsetsById,
    user,
    projectInfo,
  ]);

  return (
    <UploadContext.Provider value={handleUpload}>
      {children}
    </UploadContext.Provider>
  );
};

function useUpload() {
  const context = React.useContext(UploadContext);
  if (context === undefined) {
    throw new Error('useUpload must be used within a UploadProvider');
  }
  return context;
}

export { UploadProvider, useUpload };
