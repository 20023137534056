import React from 'react';
import styled from '../../services/styled';
import { Button, ButtonProps, alpha } from '@mui/material';

export type SNVariant =
  | 'primary'
  | 'light'
  | 'dark'
  | 'text'
  | 'underline'
  | 'destructive'
  | 'confirm'
  | 'approve'
  | 'deny';

interface SNButtonProps extends ButtonProps {
  snVariant?: SNVariant;
  hover?: boolean;
  rounded?: boolean;
}

interface SNButtonVariantProps extends ButtonProps {
  hover?: boolean;
  rounded?: boolean;
}

const SNButtonBase = styled(({ rounded, ...props }: SNButtonVariantProps) => (
  <Button {...props} />
))`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: ${({ rounded, size }) => {
    if (rounded) {
      return size === 'small' ? '15px' : '20px';
    }
    return 0;
  }};
  padding: ${({ rounded, size }) => {
    switch (size) {
      case 'small':
        return rounded ? '0 16px' : '0 12px';
      default:
        return rounded ? '0 20px' : '0 16px';
    }
  }};
  :focus {
    border: ${({ theme }) => `1px solid ${theme.palette.grey['300']}`};
  }
  :hover {
    border: 1px solid transparent;
  }
  :active {
    border: 1px solid transparent;
  }
  white-space: nowrap;
`;

const StyledPrimaryButton = styled(
  ({ hover, ...props }: SNButtonVariantProps) => <SNButtonBase {...props} />,
)`
  background: ${(p) =>
    p.hover ? p.theme.palette.primary.light : p.theme.palette.primary.main};
  :hover {
    background: ${(p) => p.theme.palette.primary.light};
  }
  :active {
    background: ${(p) => p.theme.palette.primary.dark};
  }
`;
const StyledLightButton = styled(
  ({ hover, ...props }: SNButtonVariantProps) => <SNButtonBase {...props} />,
)`
  background: ${(p) =>
    p.hover ? p.theme.palette.primary.light : p.theme.palette.grey['600']};
  :hover {
    background: ${(p) => p.theme.palette.primary.light};
  }
  :active {
    background: ${(p) => p.theme.palette.primary.main};
  }
`;
const StyledDarkButton = styled(({ hover, ...props }: SNButtonVariantProps) => (
  <SNButtonBase {...props} />
))`
  background: ${(p) =>
    p.hover ? p.theme.palette.primary.main : p.theme.palette.grey['700']};
  :hover {
    background: ${(p) => p.theme.palette.primary.main};
  }
  :active {
    background: ${(p) => p.theme.palette.primary.dark};
  }
`;
const StyledTextButton = styled(({ hover, ...props }: SNButtonVariantProps) => (
  <SNButtonBase {...props} />
))`
  background: ${(p) => (p.hover ? alpha('#fff', 0.08) : 'transparent')};
  :hover {
    background: ${alpha('#fff', 0.08)};
  }
  :active {
    background: ${alpha('#fff', 0.04)};
  }
`;

const StyledConfirmButton = styled(
  ({ hover, ...props }: SNButtonVariantProps) => <SNButtonBase {...props} />,
)`
  background: ${(p) =>
    p.hover ? p.theme.palette.success.light : p.theme.palette.grey['600']};
  :hover {
    background: ${(p) => p.theme.palette.success.light};
  }
  :active {
    background: ${(p) => p.theme.palette.success.main};
  }
`;

const StyledApproveButton = styled(
  ({ hover, ...props }: SNButtonVariantProps) => <SNButtonBase {...props} />,
)`
  background: ${(p) =>
    p.hover ? p.theme.palette.success.light : p.theme.palette.success.main};
  :hover {
    background: ${(p) => p.theme.palette.success.light};
  }
  :active {
    background: ${(p) => p.theme.palette.success.main};
  }
`;

const StyledDenyButton = styled(({ hover, ...props }: SNButtonVariantProps) => (
  <SNButtonBase {...props} />
))`
  background: ${(p) =>
    p.hover ? p.theme.palette.error.main : p.theme.palette.grey['600']};
  :hover {
    background: ${(p) => p.theme.palette.error.main};
  }
  :active {
    background: ${(p) => p.theme.palette.error.dark};
  }
`;
const StyledDestructiveButton = styled(
  ({ hover, ...props }: SNButtonVariantProps) => <SNButtonBase {...props} />,
)`
  background: ${(p) =>
    p.hover ? p.theme.palette.error.light : p.theme.palette.error.main};
  :hover {
    background: ${(p) => p.theme.palette.error.light};
  }
  :active {
    background: ${(p) => p.theme.palette.error.dark};
  }
`;

const StyledUnderlineButton = styled(
  ({ hover, ...props }: SNButtonVariantProps) => <SNButtonBase {...props} />,
)`
  border: none;
  height: auto;
  padding: 0;
  .MuiButton-label > div {
    padding: 4px 0 3px;
    transition: border-bottom 300ms ease-in-out;
    border-bottom: ${(p) =>
      p.hover
        ? `1px solid ${p.theme.palette.grey['300']}`
        : '1px solid transparent'};
  }
  :focus {
    border: none;
    .MuiButton-label > div {
      border-bottom: ${(p) => `1px solid ${p.theme.palette.common.white}`};
    }
  }
  :hover {
    border: none;
    background: none;
    .MuiButton-label > div {
      border-bottom: ${(p) => `1px solid ${p.theme.palette.grey['300']}`};
    }
  }
  :active {
    .MuiButton-label > div {
      border-bottom: ${(p) => `1px solid ${p.theme.palette.grey['300']}`};
    }
  }
`;

const SNButton: React.FC<SNButtonProps> = ({
  children,
  snVariant,
  ...rest
}) => {
  switch (snVariant) {
    case 'primary':
      return <StyledPrimaryButton {...rest}>{children}</StyledPrimaryButton>;
    case 'light':
      return <StyledLightButton {...rest}>{children}</StyledLightButton>;
    case 'dark':
      return <StyledDarkButton {...rest}>{children}</StyledDarkButton>;
    case 'text':
      return <StyledTextButton {...rest}>{children}</StyledTextButton>;
    case 'confirm':
      return <StyledConfirmButton {...rest}>{children}</StyledConfirmButton>;
    case 'approve':
      return <StyledApproveButton {...rest}>{children}</StyledApproveButton>;
    case 'deny':
      return <StyledDenyButton {...rest}>{children}</StyledDenyButton>;
    case 'destructive':
      return (
        <StyledDestructiveButton {...rest}>{children}</StyledDestructiveButton>
      );
    case 'underline':
      return (
        <StyledUnderlineButton {...rest}>
          <div>{children}</div>
        </StyledUnderlineButton>
      );
    default:
      return <StyledLightButton {...rest}>{children}</StyledLightButton>;
  }
};

export default SNButton;
