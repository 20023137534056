import { useSearchTreeDispatch, searchTreeActions } from '../contexts';

function useRootDelete(searchId: string) {
  const dispatch = useSearchTreeDispatch();
  return (newRootId: string) => {
    dispatch(
      searchTreeActions.roots.delete({
        key: searchId,
        value: newRootId,
      }),
    );
  };
}

export default useRootDelete;
