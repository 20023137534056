import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  UpdateSavedSearch,
  UpdateSavedSearchVariables,
} from '../types/schemaTypes';

export const UPDATE_SAVED_SEARCH = gql`
  mutation UpdateSavedSearch($input: UpdateSavedSearchInput!) {
    updateSavedSearch(input: $input) {
      data {
        id
        name
        description
        public
      }
      errors
    }
  }
`;

export const useUpdateSavedSearchMutation = (
  options?: MutationHookOptions<UpdateSavedSearch, UpdateSavedSearchVariables>,
) => {
  return useMutation<UpdateSavedSearch, UpdateSavedSearchVariables>(
    UPDATE_SAVED_SEARCH,
    options,
  );
};
