import React from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import SNButton from '../SNButton';
import { useParams } from 'react-router';
import { useAddUsersToGroupsMutation } from '../../mutations/useAddUsersToGroupsMutation';
import GroupsToAddSelector, {
  SelectedGroups,
} from '../GroupsToAddSelector/GroupsToAddSelector';
import SNDialogTitle from '../SNDialogTitle';
import SNStatusBanner from '../SNStatusBanner';
import { collectErrors } from '../../utils/collectErrors';

interface AddGroupsToProjectFormProps {
  onClose: () => void;
}

const AddGroupsToUserForm = ({ onClose }: AddGroupsToProjectFormProps) => {
  const { id: userId } = useParams<{ id: string }>();
  const [selectedGroups, setSelectedGroups] = React.useState<SelectedGroups>(
    {},
  );
  const [addGroupsToUser, { data: groupsAddedData }] =
    useAddUsersToGroupsMutation({
      refetchQueries: [''],
      onCompleted: () => {
        onClose();
      },
    });

  const handleAddGroupsToUser = React.useCallback(() => {
    const selectedGroupIds = Object.keys(selectedGroups);
    if (userId && selectedGroupIds.length > 0) {
      const additions = selectedGroupIds.map((groupId) => {
        return { userId, groupId };
      });
      addGroupsToUser({ variables: { input: { additions } } });
    }
  }, [addGroupsToUser, userId, selectedGroups]);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([groupsAddedData?.addUsersToGroups?.errors]);
  }, [groupsAddedData]);

  return (
    <>
      <SNDialogTitle onClose={onClose}>Add User to Groups</SNDialogTitle>
      <DialogContent>
        {collectedErrors.length > 0 && (
          <Box mb={2}>
            <SNStatusBanner status="error">
              <ul>
                {collectedErrors.map((error) => {
                  return <li key={error}>{error}</li>;
                })}
              </ul>
            </SNStatusBanner>
          </Box>
        )}
        <GroupsToAddSelector
          selectedGroups={selectedGroups}
          setSelectedGroups={setSelectedGroups}
          showProjectAccess
        />
      </DialogContent>
      <DialogActions>
        <SNButton onClick={onClose}>Cancel</SNButton>
        <SNButton
          snVariant="primary"
          onClick={handleAddGroupsToUser}
          disabled={Object.keys(selectedGroups).length === 0}
        >
          Add User
        </SNButton>
      </DialogActions>
    </>
  );
};

export default AddGroupsToUserForm;
