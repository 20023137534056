import React from 'react';
import Autocomplete from '../Autocomplete';
import SNInput from '../SNInput';
import Checkbox from '../Checkbox';

import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { useAllowedLinkTypesQuery } from '../../queries';
import {
  AllowedLinkTypes_allowedLinkTypes_linkTypes,
  AllowedLinkTypes,
} from '../../types/schemaTypes';
import SNButton from '../SNButton';
import SNDialogTitle from '../SNDialogTitle';

interface LinkingModalRelationshipProps {
  entityTypeIds: string[];
  hasTargets: boolean;
  isFilterable?: boolean;
  linkTypeId: string;
  onSelect: (linkTypeId: string) => void;
}
const defaultResponse: AllowedLinkTypes = {
  allowedLinkTypes: {
    __typename: 'AllowedLinkTypesResponse',
    linkTypes: [],
    status: null,
  },
};

const LinkingModalRelationship = ({
  entityTypeIds,
  hasTargets,
  isFilterable = true,
  linkTypeId,
  onSelect,
}: LinkingModalRelationshipProps) => {
  const [expectedRelationshipsOnly, setExpectedRelationshipsOnly] =
    React.useState(true);
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    data: { allowedLinkTypes: { linkTypes: options } } = defaultResponse,
  } = useAllowedLinkTypesQuery({
    filter: {
      entityTypeIds: expectedRelationshipsOnly ? entityTypeIds : [],
    },
  });

  const handleLinkTypeSelect = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    linkType: AllowedLinkTypes_allowedLinkTypes_linkTypes | null,
  ) => {
    onSelect(linkType ? linkType.id : '');
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (checked && hasTargets) {
      setIsOpen(true);
    } else {
      if (checked) {
        onSelect('');
      }
      setExpectedRelationshipsOnly(checked);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleReset = () => {
    onSelect('');
    setExpectedRelationshipsOnly(true);
    handleClose();
  };

  return (
    <div>
      <Box display="flex" pr={5.5}>
        <Box flexGrow={1}>
          <Autocomplete
            value={options.find((option) => option.id === linkTypeId) || null}
            multiple={false}
            freeSolo={false}
            onChange={handleLinkTypeSelect}
            options={options}
            getOptionLabel={(option) => option.name}
            renderInput={({ InputProps, InputLabelProps, ...params }) => (
              <SNInput
                {...InputProps}
                {...params}
                className=""
                status={!expectedRelationshipsOnly ? 'error' : undefined}
                placeholder="Select"
              />
            )}
          />
        </Box>
      </Box>
      {isFilterable && (
        <Box
          display="flex"
          mt={1}
          ml={-1}
          alignItems="center"
          component="label"
        >
          <Checkbox
            checked={expectedRelationshipsOnly}
            onChange={handleChange}
          />
          <Box ml={1}>
            <Typography>Only show expected relationship types</Typography>
          </Box>
        </Box>
      )}
      <Dialog open={isOpen} onClose={handleClose}>
        <SNDialogTitle onClose={handleClose}>Reset Relationship?</SNDialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            Rechecking this box will limit available relationships to expected
            options and clear any select linking targets.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SNButton snVariant="dark" onClick={handleClose}>
            Cancel
          </SNButton>
          <SNButton
            snVariant="light"
            onClick={handleReset}
            autoFocus
            data-testid="search-tree-leaf-dialog-delete-confirm"
          >
            Reset
          </SNButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LinkingModalRelationship;
