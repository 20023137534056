import gql from 'graphql-tag';
import {
  LazyQueryHookOptions,
  useLazyQuery,
  useQuery,
  QueryHookOptions,
} from '@apollo/client';
import { ReviewSummary, ReviewSummaryVariables } from '../types/schemaTypes';

const REVIEW_SUMMARY = gql`
  query ReviewSummary($reviewSummaryFilter: ReviewSummaryFilter) {
    reviewSummary(filter: $reviewSummaryFilter) {
      totalCount
      statusCounts {
        count
        status
        reviewSummaryIssuesLists {
          title
          items {
            label
          }
        }
      }
      reviewSummaryIssuesLists {
        title
        items {
          label
        }
      }
    }
  }
`;

export const useReviewSummaryQuery = (
  options?: QueryHookOptions<ReviewSummary, ReviewSummaryVariables>,
) => {
  return useQuery<ReviewSummary, ReviewSummaryVariables>(REVIEW_SUMMARY, {
    ...options,
  });
};

export const useReviewSummaryLazyQuery = (
  options?: LazyQueryHookOptions<ReviewSummary, ReviewSummaryVariables>,
) => {
  return useLazyQuery<ReviewSummary, ReviewSummaryVariables>(REVIEW_SUMMARY, {
    ...options,
  });
};
