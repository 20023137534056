import React from 'react';
import { Box, Collapse, List, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useRecoilState } from 'recoil';
import {
  ClassificationJobsAtom,
  ClassificationSnackbarOpen,
} from '../../atoms/ClassificationJobsAtom';
import ClassificationJobItem from '../ClassificationJobItem/ClassificationJobItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const ClassificationJobsSnackbar = () => {
  const [classificationJobs, setClassificationJobs] = useRecoilState(
    ClassificationJobsAtom,
  );
  const [expanded, setExpanded] = React.useState(true);
  const [classificationSnackbarOpen, setClassificationSnackbarOpen] =
    useRecoilState(ClassificationSnackbarOpen);

  const handleClose = () => {
    setClassificationJobs([]);
    setClassificationSnackbarOpen(false);
  };

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Snackbar
      id="export-status"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={classificationSnackbarOpen}
    >
      <Box bgcolor="background.paper" minWidth={360}>
        <Box
          bgcolor="grey.900"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height={52}
          p={2}
        >
          <Box pl={2} mr={2}>
            <Typography>Classification Jobs</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="expand collapse"
              color="inherit"
              onClick={handleExpand}
            >
              {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Collapse in={expanded}>
          <Box display="flex" flexDirection="column">
            <List>
              {classificationJobs.map((job) => {
                return <ClassificationJobItem key={job.id} {...job} />;
              })}
            </List>
          </Box>
        </Collapse>
      </Box>
    </Snackbar>
  );
};

export default ClassificationJobsSnackbar;
