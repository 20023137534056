import { EditEntitiesState } from '../contexts';

export const countValidations = (editState: EditEntitiesState) => {
  const validationErrorReturn = editState.validations;
  const entitiesWithValidations = validationErrorReturn.length;
  let fieldsWithValidations = 0;
  let generalAnnotations = 0;
  let totalValidations = 0;
  const EntityIdsWithValidations = Object.keys(validationErrorReturn);
  EntityIdsWithValidations.forEach((entityId) => {
    const fieldIdsWithAnnotations = Object.keys(
      validationErrorReturn[entityId].fieldValidations,
    );
    fieldsWithValidations =
      fieldsWithValidations + fieldIdsWithAnnotations.length;
    generalAnnotations =
      generalAnnotations +
      validationErrorReturn[entityId].generalAnnotations.length;
    fieldIdsWithAnnotations.forEach((fieldId) => {
      totalValidations =
        totalValidations +
        validationErrorReturn[entityId].fieldValidations[fieldId].annotations
          .length;
    });
  });

  return {
    entitiesWithValidations,
    generalAnnotations,
    fieldsWithValidations,
    totalValidations,
  };
};
