import React from 'react';
import { FilterValueType, SearchTreeTarget } from '../types';
import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { activeEntityType, SearchTargetType } from '../types/schemaTypes';
import {
  useDataPointsQuery,
  useEntityTypesQuery,
  useSystemFields,
} from '../queries';
import { entityTypes } from '../constants/wellKnownDataTypes';

type UseFieldOptionsOptions = SearchTreeTarget;

const useEntityFields = ({ id, type }: UseFieldOptionsOptions) => {
  const client = useApolloClient();
  const { data: dataPointsData } = useDataPointsQuery();
  const { data: systemFieldData } = useSystemFields();
  const { loading } = useEntityTypesQuery();

  return React.useMemo(() => {
    const fields: { id: string; name: string; type: FilterValueType }[] = [];
    if (type === SearchTargetType.ENTITY_TYPE && !loading) {
      //when we upgrade to apollo 3.0 review all usage of readFragment
      const entityType = client.readFragment<activeEntityType>({
        id: `EntityType:${id}`,
        fragment: gql`
          fragment activeEntityType on EntityType {
            id
            fields {
              id
              name
              type @client
            }
          }
        `,
      });

      if (entityType) {
        fields.push(...entityType.fields);
      }
      if (id === entityTypes.any && dataPointsData) {
        if (dataPointsData && dataPointsData.dataPoints) {
          dataPointsData.dataPoints.forEach((datum) => {
            fields.push({
              id: datum.id,
              name: datum.name,
              type: datum.UIType,
            });
          });
        }
      }
    }

    if (systemFieldData) {
      fields.push(
        ...systemFieldData.systemFields.map(
          ({ dataPoint, __typename, ...rest }) => rest,
        ),
      );
    }
    return fields;
  }, [loading, systemFieldData, dataPointsData, client, id, type]);
};

export default useEntityFields;
