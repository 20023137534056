import React from 'react';
import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
import SNButton from '../SNButton';
import styled from '../../services/styled';
import {
  EntityDataHiddenTableHead,
  EntityDataLabel,
  EntityDataValue,
} from '../EntityData/EntityData';
import SNDialogTitle from '../SNDialogTitle';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userMetadataEditModalOpenAtom } from '../../atoms/userMetadataEditModalOpenAtom';
import { userMetadataModalOpenAtom } from '../../atoms/userMetadataModalOpenAtom';
import { useEntityQuery } from '../../queries/useEntityQuery';

const StyledTable = styled(Box)`
  border-collapse: collapse;
  overflow-wrap: word-break;
`;

const StyledTableRow = styled(Box)`
  height: 40px;
`;

const UserMetadataModal = () => {
  const [userMetadataModalOpen, setUserMetadataModalOpen] = useRecoilState(
    userMetadataModalOpenAtom,
  );

  const { data } = useEntityQuery({
    variables: { id: userMetadataModalOpen.entityId },
  });

  const setEditOpen = useSetRecoilState(userMetadataEditModalOpenAtom);

  const handleEditOpen = () => {
    setEditOpen({ entityId: userMetadataModalOpen.entityId, open: true });
    handleModalClose();
  };

  const handleModalClose = () => {
    setUserMetadataModalOpen((previous) => {
      return {
        ...previous,
        open: false,
      };
    });
  };

  return (
    <>
      <SNDialogTitle onClose={handleModalClose}>
        <Typography variant="h2">Metadata</Typography>
        <Box pt={1}>
          <Typography>{data?.entity.displayName}</Typography>
        </Box>
      </SNDialogTitle>
      <DialogContent>
        {data?.entity.userMetadata ? (
          <StyledTable
            component="table"
            width="100%"
            bgcolor="background.default"
          >
            <EntityDataHiddenTableHead />
            <tbody>
              {data?.entity.userMetadata?.map(({ value, label }) => (
                <StyledTableRow key={`${label}:${value}`} component="tr">
                  <EntityDataLabel>{label}</EntityDataLabel>
                  <EntityDataValue>{value}</EntityDataValue>
                </StyledTableRow>
              ))}
            </tbody>
          </StyledTable>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h3">
              This entity has no user defined metadata
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <SNButton onClick={handleEditOpen}>Edit Metadata</SNButton>
        <SNButton snVariant="primary" onClick={handleModalClose}>
          Close
        </SNButton>
      </DialogActions>
    </>
  );
};

export default UserMetadataModal;
