import React from 'react';
import { Box, Typography } from '@mui/material';
import Checkbox from '../Checkbox';
import UploadModalContextTargets from '../UploadModalContextTargets';
import { entityTypes } from '../../constants/wellKnownDataTypes';
import CollapsePanel from '../CollapsePanel';
import { useRecoilValue } from 'recoil';
import { uploadFilesByIdAtom } from '../../atoms/uploadFiles';

interface UploadModalContextPanelProps {
  name: string;
  onFileSelect: (name: string, checked: boolean) => void;
  onRemove?: () => void;
  onTargetDelete: (innerIndex: number) => () => void;
  onTargetSelect: (innerIndex: number) => (targetId: string) => void;
  selectedUploadIds: string[];
  existingObjectIds: string[];
}

const UploadModalContextPanel: React.FC<UploadModalContextPanelProps> = ({
  name,
  onFileSelect,
  onRemove,
  onTargetDelete,
  onTargetSelect,
  selectedUploadIds,
  existingObjectIds,
}) => {
  const filesById = useRecoilValue(uploadFilesByIdAtom);

  const handleFileSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    onFileSelect(event.currentTarget.value, checked);
  };

  const handleTargetDelete = (innerIndex: number) => () => {
    onTargetDelete(innerIndex)();
  };

  const handleTargetSelect = (innerIndex: number) => (targetId: string) => {
    onTargetSelect(innerIndex)(targetId);
  };

  return (
    <CollapsePanel name={name} onRemove={onRemove}>
      <Box p={2}>
        <Box p={1}>
          <Typography variant="h5">Files in this upload</Typography>
        </Box>
        <Box py={1}>
          {Object.entries(filesById).map(([fileId, file]) => (
            <Box
              key={fileId}
              component="label"
              display="flex"
              alignItems="center"
            >
              <Box pr={1}>
                <Checkbox
                  value={fileId}
                  checked={selectedUploadIds.includes(fileId)}
                  onChange={handleFileSelect}
                />
              </Box>
              <Typography variant="h5">{file.name}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box width="100%" bgcolor="#3F414B" height="1px" />
      <Box p={2}>
        <Box p={1}>
          <Typography variant="h5">Documents within Snthesis</Typography>
        </Box>
        <Box p={1} maxWidth="50%">
          <UploadModalContextTargets
            allowedEntityTypeIds={[entityTypes.rawObject]}
            label="Document Name"
            onTargetDelete={handleTargetDelete}
            onTargetSelect={handleTargetSelect}
            targetEntityIds={existingObjectIds}
          />
        </Box>
      </Box>
    </CollapsePanel>
  );
};

export default UploadModalContextPanel;
