import { QueryHookOptions, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
  LinkSummaryDetails,
  LinkSummaryDetailsVariables,
} from '../types/schemaTypes';

export const LINK_SUMMARY_DETAILS = gql`
  query LinkSummaryDetails(
    $filter: SummaryFilter!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    linksForSummary(
      filter: $filter
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      edges {
        cursor
        node {
          id
          direction
          linkedEntity {
            id
            displayName
          }
          metadata {
            fields {
              label
              value
              fieldDefinition {
                id
              }
            }
          }
        }
      }
      linkDefinition {
        id
        name
        description
        allowedTypes {
          id
        }
        type {
          id
          name
          description
          fields {
            id
            name
            description
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
        endIndex @client
        startIndex @client
      }
    }
  }
`;

export const useLinkSummaryDetailsQuery = (
  options?: QueryHookOptions<LinkSummaryDetails, LinkSummaryDetailsVariables>,
) => {
  return useQuery<LinkSummaryDetails, LinkSummaryDetailsVariables>(
    LINK_SUMMARY_DETAILS,
    {
      ...options,
    },
  );
};
