import React from 'react';
import { Box, Typography } from '@mui/material';
import Checkbox from '../Checkbox';
import { useRecoilState } from 'recoil';
import { listItemByIndexSelector } from '../../atoms/listSelection';
import SNTableRow from '../SNTableRow';
import moment from 'moment';

interface ListItemsTableRowProps {
  value?: string | null;
  id?: string;
  index: number;
  description: string | null;
  createdAt: string;
  updatedAt: string;
}

const ListItemsTableRow = ({
  value,
  id,
  description,
  updatedAt,
  createdAt,
  index,
}: ListItemsTableRowProps) => {
  const [selected, selectRow] = useRecoilState(listItemByIndexSelector(index));

  const handleRowSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      selectRow(checked);
    },
    [selectRow],
  );

  return (
    <SNTableRow selected={selected} key={id}>
      <td>
        <Box pl={4} pr={3} position="relative">
          <Checkbox checked={selected} onChange={handleRowSelect} />
        </Box>
      </td>
      <td>
        <Box display="flex" alignItems="center" height={56}>
          <Box fontWeight="bold">
            <Typography>{value}</Typography>
          </Box>
        </Box>
      </td>
      <td>
        <Box height={56} display="flex" alignItems="center">
          <Box overflow="hidden" textOverflow="ellipsis">
            <Typography>{description}</Typography>
          </Box>
        </Box>
      </td>
      <td>
        <Box height={56} display="flex" alignItems="center">
          <Typography>{moment(createdAt).format('YYYY-MM-DD')}</Typography>
        </Box>
      </td>
      <td>
        <Box height={56} display="flex" alignItems="center">
          <Typography>{moment(updatedAt).format('YYYY-MM-DD')}</Typography>
        </Box>
      </td>
    </SNTableRow>
  );
};

export default ListItemsTableRow;
