import { atom, selector } from 'recoil';
import { selectedIdsAtom } from './listSelection';

export const isEditingEntitiesAtom = atom({
  key: 'editing-entities',
  default: false,
});

export const editEntitiesSelector = selector({
  key: 'editing-entities-selector',
  get: ({ get }) => get(isEditingEntitiesAtom),
  set: ({ get, set }) => {
    const selectedIds = get(selectedIdsAtom);
    if (selectedIds.length === 0) {
      set(isEditingEntitiesAtom, true);
    }
  },
});
