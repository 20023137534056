import React from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Checkbox from '../Checkbox';
import { ExportOption } from '../../types/schemaTypes';
import { useUserState } from '../../contexts';
import { useUserQuery } from '../../queries';
import { useUpdateUserPreferences } from '../../mutations';
import styled from '../../services/styled';
import { getMuiVariant } from '../../services/theme';

const StyledInputLabel = styled(Box)`
  cursor: pointer;
`;

const StyledTable = styled(Box)`
  border-collapse: collapse;
  overflow-wrap: anywhere;
`;

const StyledTableRow = styled(Box)`
  height: 40px;
`;

const StyledTableCell = styled(Box)`
  border-color: ${({ theme }) => theme.palette.grey[600]};
  border-style: solid;
  border-width: 1px;
  vertical-align: top;
`;

const ProfilePreferences = () => {
  const user = useUserState();
  const { data } = useUserQuery({ variables: { id: user.userId } });
  const [mutation] = useUpdateUserPreferences();

  const handleUploadWizardPreferenceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    mutation({
      variables: {
        input: {
          id: user.userId,
          preferences: {
            uploadWizardSkipAllQuestionsPrompt: checked,
          },
        },
      },
    });
  };

  const handleExportOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    mutation({
      variables: {
        input: {
          id: user.userId,
          preferences: {
            exportOptionsPreference: event.target.value as ExportOption,
          },
        },
      },
    });
  };

  const handleRememberExportPreferenceChange = () => {
    mutation({
      variables: {
        input: {
          id: user.userId,
          preferences: {
            exportOptionsPreference: data?.user?.user?.preferences
              ?.rememberExportOptions
              ? data?.user?.user?.preferences?.exportOptionsPreference
              : null,
            rememberExportOptions:
              !data?.user?.user?.preferences?.rememberExportOptions,
          },
        },
      },
    });
  };

  const userName = React.useMemo(() => user.name, [user]);
  const userEmail = React.useMemo(() => user.email, [user]);

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item md={6} sm={12}>
          <Box p={2} ml={2} bgcolor="background.paper">
            <Typography variant="h2">Preferences</Typography>
            <Box py={1}>
              <StyledInputLabel
                component="label"
                display="flex"
                alignItems="center"
              >
                <Checkbox
                  disabled={
                    typeof data?.user?.user?.preferences
                      ?.uploadWizardSkipAllQuestions !== 'boolean'
                  }
                  checked={
                    data?.user?.user?.preferences
                      ?.uploadWizardSkipAllQuestionsPrompt || false
                  }
                  onChange={handleUploadWizardPreferenceChange}
                />
                <Typography variant="h5">
                  Skip Upload Wizard Guide Prompt
                </Typography>
              </StyledInputLabel>
              <Box py={1}>
                <StyledInputLabel
                  component="label"
                  display="flex"
                  alignItems="center"
                >
                  <Checkbox
                    disabled={
                      typeof data?.user?.user?.preferences
                        ?.rememberExportOptions !== 'boolean'
                    }
                    checked={
                      data?.user?.user?.preferences?.rememberExportOptions ||
                      false
                    }
                    onChange={handleRememberExportPreferenceChange}
                  />
                  <Typography variant="h5">
                    Remember Export Option Preferences
                  </Typography>
                </StyledInputLabel>
                <Box pl={4} py={1}>
                  <RadioGroup
                    aria-label="Export Options"
                    value={
                      data?.user?.user?.preferences?.exportOptionsPreference
                    }
                    onChange={handleExportOptionChange}
                  >
                    <FormControlLabel
                      disabled={
                        !data?.user?.user?.preferences?.rememberExportOptions
                      }
                      value={ExportOption.EXPORT_RESULTS_ONLY}
                      control={<Radio />}
                      label={
                        <Typography color="textSecondary">
                          Results only (.CSV)
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      disabled={
                        !data?.user?.user?.preferences?.rememberExportOptions
                      }
                      value={ExportOption.EXPORT_RESULTS_AND_SOURCE}
                      control={<Radio />}
                      label={
                        <Typography color="textSecondary">
                          Results and raw data (.CSV + original source files)
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box p={3} ml={2} bgcolor="background.paper">
            <Box mb={2}>
              <Typography variant="h2">Account info</Typography>
            </Box>
            <Box pb={2.5}>
              <StyledTable
                component="table"
                width="100%"
                bgcolor="background.default"
              >
                <tbody>
                  <StyledTableRow component="tr">
                    <StyledTableCell component="td" whiteSpace="nowrap">
                      <Box px={2} py="11px" color="grey.300">
                        <Typography
                          color="inherit"
                          variant={getMuiVariant('label')}
                        >
                          Name
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell component="td">
                      <Box px={2} py="11px">
                        <Typography>{userName}</Typography>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow component="tr">
                    <StyledTableCell component="td" whiteSpace="nowrap">
                      <Box px={2} py="11px" color="grey.300">
                        <Typography
                          color="inherit"
                          variant={getMuiVariant('label')}
                        >
                          Email
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell component="td">
                      <Box px={2} py="11px">
                        <Typography>{userEmail}</Typography>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                </tbody>
              </StyledTable>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfilePreferences;
