import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { AddProject, AddProjectVariables } from '../types/schemaTypes';

export const ADD_PROJECT = gql`
  mutation AddProject($input: AddProjectInput!) {
    addProject(input: $input) {
      project {
        id
        name
        description
      }
      errors
    }
  }
`;

export const useAddProjectMutation = (
  options?: MutationHookOptions<AddProject, AddProjectVariables>,
) => {
  return useMutation<AddProject, AddProjectVariables>(ADD_PROJECT, options);
};
