import React, { Dispatch, SetStateAction } from 'react';
import EntityHistoryTypeDropdown from '../EntityHistoryTypeDropdown/EntityHistoryTypeDropdown';
import SNToolbar from '../SNToolbar';
import EntityHistoryDirectionDropdown from '../EntityHistoryDirectionDropdown/EntityHistoryDirectionDropdown';
import { Box, Dialog } from '@mui/material';
import ListSearchControl from '../ListSearchControl';
import { EntityHistoryEventType, SortDirection } from '../../types/schemaTypes';
import ListFilterModal from '../ListFilterModal';
import SearchIcon from '@mui/icons-material/Search';
import SNButton from '../SNButton';
import { useRecoilValue } from 'recoil';
import { ScreenWidthAtom } from '../../atoms/screenWidth';

interface EntityHistoryToolbarProps {
  eventTypes: EntityHistoryEventType[];
  setEventTypes: Dispatch<SetStateAction<EntityHistoryEventType[]>>;
  textFilter: string;
  setTextFilter: Dispatch<SetStateAction<string>>;
  historyDirection: SortDirection;
  setHistoryDirection: Dispatch<SetStateAction<SortDirection>>;
}

const EntityHistoryToolbar = ({
  eventTypes,
  setEventTypes,
  historyDirection,
  setHistoryDirection,
  textFilter,
  setTextFilter,
}: EntityHistoryToolbarProps) => {
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const screenWidth = useRecoilValue(ScreenWidthAtom);

  return (
    <SNToolbar>
      {screenWidth > 900 ? (
        <>
          <Box display="flex" alignItems="center">
            <Box pr={3}>
              <EntityHistoryDirectionDropdown
                historyDirection={historyDirection}
                setHistoryDirection={setHistoryDirection}
              />
            </Box>
            <EntityHistoryTypeDropdown
              eventTypes={eventTypes}
              setEventTypes={setEventTypes}
            />
          </Box>
          <ListSearchControl value={textFilter} setValue={setTextFilter} />
        </>
      ) : (
        <>
          <SNButton
            data-testid="filter_list_button"
            snVariant="text"
            startIcon={<SearchIcon />}
            onClick={() => setFilterModalOpen(true)}
          >
            Filter this list
          </SNButton>
          <Dialog open={filterModalOpen}>
            <ListFilterModal
              listTitle="Entity History"
              closeModal={() => setFilterModalOpen(false)}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Box m={1} mt={0} ml={3}>
                  <ListSearchControl
                    value={textFilter}
                    setValue={setTextFilter}
                    forceShow
                  />
                </Box>
                <Box m={1}>
                  <EntityHistoryDirectionDropdown
                    historyDirection={historyDirection}
                    setHistoryDirection={setHistoryDirection}
                  />
                </Box>
                <Box m={1}>
                  <EntityHistoryTypeDropdown
                    eventTypes={eventTypes}
                    setEventTypes={setEventTypes}
                  />
                </Box>
              </Box>
            </ListFilterModal>
          </Dialog>
        </>
      )}
    </SNToolbar>
  );
};

export default EntityHistoryToolbar;
