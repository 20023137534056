import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetEntityJobStatus } from '../types/schemaTypes';

const GET_ENTITY_JOB = gql`
  query GetEntityJobStatus($id: String!) {
    getEntityJobStatus(id: $id) {
      id
      state
      #      type
    }
  }
`;

export const useGetEntityJobStatus = (options: QueryHookOptions) => {
  return useQuery<GetEntityJobStatus>(GET_ENTITY_JOB, { ...options });
};
