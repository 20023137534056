import React from 'react';
import {
  Dialog,
  DialogContentText,
  DialogActions,
  DialogContent,
} from '@mui/material';
import SNButton from '../SNButton';
import SNDialogTitle from '../SNDialogTitle';

interface SearchTreeLeafDialogProps {
  id: string;
  onClose: () => void;
  onDelete: () => void;
}

const SearchTreeLeafDialog = ({
  id,
  onClose,
  onDelete,
}: SearchTreeLeafDialogProps) => {
  return (
    <Dialog
      open={Boolean(id)}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <SNDialogTitle id="alert-dialog-title">Delete search node?</SNDialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Delete this search node and all of its filters?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <SNButton snVariant="light" onClick={onClose}>
          Cancel
        </SNButton>
        <SNButton
          snVariant="destructive"
          onClick={onDelete}
          autoFocus
          data-testid="search-tree-leaf-dialog-delete-confirm"
        >
          Delete
        </SNButton>
      </DialogActions>
    </Dialog>
  );
};

export default SearchTreeLeafDialog;
