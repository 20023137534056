import { Box, Dialog, Grid } from '@mui/material';
import React from 'react';
import SelectableListToolbar from '../SelectableListToolbar';
import SNButton from '../SNButton';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { pageIdsAtom, selectedIdsSelector } from '../../atoms/listSelection';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useProjectsOfUserQuery } from '../../queries/useProjectsOfUserQuery';
import { useParams } from 'react-router-dom';
import SNTable from '../SNTable';
import SNPagination from '../SNPagination';
import RoleHeaderModalButton from '../RoleHeaderModalButton';
import AddProjectsToUserForm from '../AddProjectstoUserForm';
import ToolbarActionGroup from '../ToolbarActionGroup';
import ListSearchControl from '../ListSearchControl';
import RevokeProjectAccess from '../RevokeProjectAccess';
import { useToggle } from '../../hooks/useToggle';
import { useRevokeUserProjectAccessMutation } from '../../mutations/useRevokeUserProjectAccess';
import { RevokeProjectAccessInput, UserRole } from '../../types/schemaTypes';
import UserProjectsTableRow from '../UserProjectsTableRow';
import RoleMask from '../RoleMask';
import ListSelectionControl from '../ListSelectionControl';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { collectErrors } from '../../utils/collectErrors';
import { useGrantUserToProjectsMutation } from '../../mutations';
import { useDebounce } from '../../hooks';

const headers = [
  'name',
  'description',
  <RoleHeaderModalButton key="usersTableRoleButton" label="Project role" />,
  'access',
  'date added',
];

const UserDetailProjectsTable = () => {
  const pageSize = 10;
  const { id } = useParams<{ id: string }>();
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const resetSelectedIds = useResetRecoilState(selectedIdsSelector);
  const [addUserModalOpen, setAddProjectModalOpen, setAddProjectModalClosed] =
    useToggle(false);
  const [removeUserOpen, setRemoveUserOpen, setRemoveUserClosed] =
    useToggle(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { data, loading, error, fetchMore, refetch } = useProjectsOfUserQuery({
    variables: {
      id: id,
      first: pageSize,
      filter: {
        searchTerm: debouncedSearchTerm,
      },
    },
    onCompleted: (result) => {
      const pageIds = result?.user?.user?.projectAccess?.edges.map(
        (edge) => edge.node.id,
      );
      setPageIds(pageIds || []);
    },
  });
  React.useEffect(() => {
    refetch({
      id: id,
      first: pageSize,
      filter: { searchTerm: debouncedSearchTerm },
    });
  }, [refetch, id, debouncedSearchTerm]);

  const [grantNewUserRole] = useGrantUserToProjectsMutation();
  const updateUserRole = (projectId: string, role: UserRole) => {
    grantNewUserRole({
      variables: {
        input: {
          projectId: projectId,
          userId: id,
          userRole: role,
        },
      },
      onCompleted: (res) => {
        if (res.grantUserAccessToProject?.errors) {
          setGeneralError({
            open: true,
            message: 'Failed to change Role',
            details: res.grantUserAccessToProject.errors[0],
          });
        }
      },
      refetchQueries: ['UsersOfProject', 'ProjectsOfUser'],
    });
  };

  const [revokeProjectAccess, { data: revokeData }] =
    useRevokeUserProjectAccessMutation({
      refetchQueries: ['UsersOfProject', 'ProjectsOfUser'],
      onCompleted: () => {
        resetSelectedIds();
      },
    });

  const projectAccess = React.useMemo(
    () => data?.user?.user?.projectAccess?.edges.map((edge) => edge.node),
    [data],
  );
  React.useEffect(() => {
    console.log(debouncedSearchTerm);
    console.log(
      data?.user?.user?.projectAccess?.edges.map(
        (access) => access.node.project?.name,
      ),
    );
    console.log(projectAccess);
  }, [data, debouncedSearchTerm, projectAccess]);

  const pageTotal = projectAccess?.length || 0;

  const handleRevokeAccess = React.useCallback(
    (input?: RevokeProjectAccessInput) => {
      if (input) {
        revokeProjectAccess({ variables: { input } });
      } else {
        setRemoveUserOpen();
      }
    },
    [revokeProjectAccess, setRemoveUserOpen],
  );

  const collectedErrors = React.useMemo(() => {
    return collectErrors([
      data?.user?.errors,
      revokeData?.revokeProjectAccess?.errors,
    ]);
  }, [data, revokeData]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: "Error fetching user's projects",
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <Box>
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            {...data?.user?.user?.projectAccess?.pageInfo}
            fetchMore={fetchMore}
            loading={loading}
            pageSize={pageSize}
            pageTotal={pageTotal}
            totalCount={data?.user?.user?.projectAccess?.totalCount}
          />
        </Box>
        <Box>
          <Grid container spacing={1}>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 0}
            >
              <Grid item>
                <RoleMask allowedRoles={[UserRole.ADMINISTRATOR]}>
                  <SNButton
                    startIcon={<AddIcon />}
                    onClick={setAddProjectModalOpen}
                    snVariant="text"
                  >
                    Add Projects
                  </SNButton>
                </RoleMask>
                <Dialog
                  open={addUserModalOpen}
                  onClose={setAddProjectModalClosed}
                  fullWidth
                >
                  <AddProjectsToUserForm onClose={setAddProjectModalClosed} />
                </Dialog>
              </Grid>
              <Grid item>
                <ListSearchControl
                  setValue={setSearchTerm}
                  value={searchTerm}
                />
              </Grid>
            </ToolbarActionGroup>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 1}
            >
              <Grid item>
                <RoleMask allowedRoles={[UserRole.ADMINISTRATOR]}>
                  <SNButton
                    onClick={setRemoveUserOpen}
                    snVariant="text"
                    startIcon={<DeleteIcon />}
                  >
                    Remove User
                  </SNButton>
                </RoleMask>
                <Dialog
                  fullWidth
                  open={removeUserOpen}
                  onClose={setRemoveUserClosed}
                >
                  <RevokeProjectAccess
                    onDone={handleRevokeAccess}
                    onClose={setRemoveUserClosed}
                  />
                </Dialog>
              </Grid>
            </ToolbarActionGroup>
          </Grid>
        </Box>
      </SelectableListToolbar>
      <SNTable
        error={error}
        hasResults={!!projectAccess}
        headers={headers}
        id="user-projects"
        loading={loading}
        rowCount={pageTotal}
        selectable
      >
        {projectAccess?.map(({ project, sources, ...projectAccess }, index) => (
          <UserProjectsTableRow
            key={projectAccess.id}
            index={index}
            sourceCount={sources.length}
            {...projectAccess}
            {...project}
            onRoleChange={updateUserRole}
          />
        ))}
      </SNTable>
    </Box>
  );
};

export default UserDetailProjectsTable;
