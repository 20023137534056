import * as React from 'react';

interface SaveSearchProviderProps {
  children: React.ReactNode;
}

type SaveType = 'SAVE_AS' | 'SAVE' | 'COPY' | null;

type Dispatch = (action: React.SetStateAction<SaveType>) => void;

const SaveSearchContext = React.createContext<SaveType | undefined>(undefined);
const SaveSearchDispatchContext = React.createContext<Dispatch | undefined>(
  undefined,
);

function SaveSearchProvider({ children }: SaveSearchProviderProps) {
  const [state, setState] = React.useState<SaveType>(null);
  return (
    <SaveSearchContext.Provider value={state}>
      <SaveSearchDispatchContext.Provider value={setState}>
        {children}
      </SaveSearchDispatchContext.Provider>
    </SaveSearchContext.Provider>
  );
}
function useSaveSearchState() {
  const context = React.useContext(SaveSearchContext);
  if (context === undefined) {
    throw new Error(
      'useSaveSearchState must be used within a SaveSearchProvider',
    );
  }
  return context;
}

function useSaveSearchDispatch() {
  const context = React.useContext(SaveSearchDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useSaveSearchDispatch must be used within a SaveSearchProvider',
    );
  }
  return context;
}
export { SaveSearchProvider, useSaveSearchState, useSaveSearchDispatch };
