import CheckCircle from '@mui/icons-material/CheckCircle';
import { Dialog } from '@mui/material';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { isEditingEntitiesAtom } from '../../atoms/editEntities';
import { useToggle } from '../../hooks/useToggle';
import ApprovalConfirmationSnackbar from '../ApprovalConfirmationSnackbar';
import ReviewApprovalModal from '../ReviewApprovalModal';
import SNButton from '../SNButton';

export interface ActionApproveDataProps {
  page: number;
  pageTotal: number;
  searchId: string;
  tableId: string;
}

const ActionApproveData = ({
  page,
  pageTotal,
  searchId,
  tableId,
}: ActionApproveDataProps) => {
  const isEditing = useRecoilValue(isEditingEntitiesAtom);
  const [isApprovalOpen, setApprovalOpen, setApprovalClosed] = useToggle(false);
  const [
    approvalSnackbarOpen,
    setApprovalSnackbarOpen,
    setApprovalSnackbarClosed,
  ] = useToggle(false);

  if (isEditing) {
    return null;
  }

  return (
    <>
      <SNButton
        disabled={pageTotal === 0}
        startIcon={<CheckCircle />}
        snVariant="text"
        onClick={setApprovalOpen}
      >
        Approve Data
      </SNButton>
      <Dialog open={isApprovalOpen} onClose={setApprovalClosed}>
        <ReviewApprovalModal
          closeModal={setApprovalClosed}
          page={page}
          searchId={searchId}
          setConfirmationSnackbarOpen={setApprovalSnackbarOpen}
          tableId={tableId}
        />
      </Dialog>
      <ApprovalConfirmationSnackbar
        open={approvalSnackbarOpen}
        onClose={setApprovalSnackbarClosed}
      />
    </>
  );
};

export default ActionApproveData;
