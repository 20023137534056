import { Dialog } from '@mui/material';
import React from 'react';
import {
  useCompleteSearchObject,
  useSaveSearchDispatch,
  useSaveSearchState,
} from '../../contexts';
import { useUpdateSavedSearchMutation } from '../../mutations';
import { useGetSavedSearchQuery } from '../../queries';
import { searchInputTransform } from '../../utils';
import SearchSave, { SaveSearchFormValues } from './SearchSave';
import SNDialogTitle from '../SNDialogTitle';
import { collectErrors } from '../../utils/collectErrors';

interface SearchSaveEditProps {
  id: string;
  onSaveComplete: () => void;
  searchId: string;
  title: string;
}

const SearchSaveEdit = ({
  id,
  onSaveComplete,
  searchId,
  title,
}: SearchSaveEditProps) => {
  const saveType = useSaveSearchState();
  const searchState = useCompleteSearchObject(searchId);
  const setSaveSearchState = useSaveSearchDispatch();
  const [updateSavedSearch, { loading, data: updateData }] =
    useUpdateSavedSearchMutation({
      onCompleted: () => {
        onSaveComplete();
      },
    });
  const { data } = useGetSavedSearchQuery({ variables: { id } });

  const handleClose = React.useCallback(() => {
    setSaveSearchState(null);
  }, [setSaveSearchState]);

  const handleSubmit = async ({
    description,
    isPublic,
    name,
  }: SaveSearchFormValues) => {
    const searchQuery = searchInputTransform(searchState);
    await updateSavedSearch({
      variables: {
        input: {
          name,
          description,
          id,
          public: isPublic,
          searchQuery,
        },
      },
    });
    handleClose();
  };

  const collectedErrors = React.useMemo(() => {
    return collectErrors([
      updateData?.updateSavedSearch?.errors,
      data?.getSavedSearch.errors,
    ]);
  }, [updateData, data]);

  if (!data?.getSavedSearch.data) return null;

  const { name, description, public: isPublic } = data.getSavedSearch.data;

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={saveType === 'SAVE'}
      onClose={handleClose}
    >
      <SNDialogTitle onClose={handleClose}>{title}</SNDialogTitle>
      <SearchSave
        loading={loading}
        onSubmit={handleSubmit}
        name={name}
        description={description || ''}
        isPublic={isPublic}
        errors={collectedErrors}
      />
    </Dialog>
  );
};

export default SearchSaveEdit;
