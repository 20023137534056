import React from 'react';
const useGrabScrolling = (element: Element) => {
  const [tableGrabbed, setTableGrabbed] = React.useState<{
    alt: boolean;
    clicked: boolean;
  }>({ alt: false, clicked: false });

  if (!element || !element.classList) {
    return null;
  }
  document.onkeydown = (e) => {
    if (document.body.contains(element)) {
      if (e.key === 'Alt') {
        setTableGrabbed((prev) => {
          return { ...prev, alt: true };
        });
        element.classList.add('grab');
      }
    }
  };
  document.onkeyup = (e) => {
    if (document.body.contains(element)) {
      if (e.key === 'Alt') {
        setTableGrabbed((prev) => {
          return { ...prev, alt: false };
        });
        element.classList.remove('grab');
      }
    }
  };
  document.onmousedown = (e) => {
    if (document.body.contains(element)) {
      if (
        (e.button === 1 || e.button === 4 || tableGrabbed.alt) &&
        e.target === element
      ) {
        e.preventDefault();
        e.stopPropagation();
        setTableGrabbed((prev) => {
          return { ...prev, clicked: true };
        });
        element.classList.add('grabbing');
      }
    }
  };
  document.onmousemove = (e) => {
    if (document.body.contains(element)) {
      if ((tableGrabbed.alt && tableGrabbed.clicked) || tableGrabbed.clicked) {
        if (e.target === element) {
          element.scrollBy(e.movementX * -1, e.movementY * -1);
        }
      }
    }
  };
  document.onmouseup = () => {
    if (document.body.contains(element)) {
      element.classList.remove('grabbing');
      setTableGrabbed((prev) => {
        return { ...prev, clicked: false };
      });
    }
  };
};

export default useGrabScrolling;
