import React from 'react';
import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
import { Group } from '@mui/icons-material';
import SNButton from '../SNButton';
import { useRecoilValue } from 'recoil';
import { selectedIdsAtom } from '../../atoms/listSelection';
import {
  AccessSourceType,
  RevokeProjectAccessInput,
} from '../../types/schemaTypes';
import { useProjectAccessQuery } from '../../queries/useProjectAccessQuery';
import Checkbox from '../Checkbox';
import SNDialogTitle from '../SNDialogTitle';
import { collectErrors } from '../../utils/collectErrors';
import SNStatusBanner from '../SNStatusBanner';

interface RevokeProjectAccessProps {
  onClose: () => void;
  onDone: (input: RevokeProjectAccessInput) => void;
}

const RevokeProjectAccess = ({ onClose, onDone }: RevokeProjectAccessProps) => {
  const selectedIds = useRecoilValue(selectedIdsAtom);
  const [groupIds, setGroupIds] = React.useState<string[]>([]);
  const { data } = useProjectAccessQuery({
    variables: { id: selectedIds[0] },
  });

  const handleDone = React.useCallback(() => {
    onDone({ id: selectedIds[0], groupIds });
  }, [groupIds, onDone, selectedIds]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setGroupIds((prev) => [...new Set([...prev, event.target.id])]);
      } else {
        setGroupIds((prev) =>
          prev.filter((groupId) => groupId !== event.target.id),
        );
      }
    },
    [],
  );

  const sourceCount = data?.projectAccess?.projectAccess?.sources.length || 1;
  const groupCount =
    sourceCount - (data?.projectAccess?.projectAccess?.hasDirectAccess ? 1 : 0);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([data?.projectAccess?.errors]);
  }, [data]);

  return (
    <>
      <SNDialogTitle onClose={onClose}>Revoke Project Access</SNDialogTitle>
      <DialogContent>
        {collectedErrors.length > 0 && (
          <Box mb={2}>
            <SNStatusBanner status="error">
              <ul>
                {collectedErrors.map((error) => {
                  return <li key={error}>{error}</li>;
                })}
              </ul>
            </SNStatusBanner>
          </Box>
        )}
        {data?.projectAccess?.projectAccess?.hasDirectAccess && (
          <>
            <Typography>
              Remove {data.projectAccess.projectAccess.user?.name}&apos;s Direct
              access to {data?.projectAccess?.projectAccess?.project?.name}?
            </Typography>
            <br></br>
          </>
        )}
        {groupCount > 0 && (
          <>
            <Box>
              {data?.projectAccess?.projectAccess?.hasDirectAccess ? (
                <Typography>
                  {data?.projectAccess?.projectAccess?.user?.name} will still
                  have access to{' '}
                  {data?.projectAccess?.projectAccess?.project?.name} through
                  the following groups:
                </Typography>
              ) : (
                <Typography>
                  {data?.projectAccess?.projectAccess?.user?.name} has access to{' '}
                  {data?.projectAccess?.projectAccess?.project?.name} through
                  the following groups:
                </Typography>
              )}
            </Box>
            <ul>
              {data?.projectAccess?.projectAccess?.sources
                .filter(
                  (source) => source.sourceType === AccessSourceType.GROUP,
                )
                .map((source) => (
                  <Box key={source.id} component="li" my={2}>
                    <Box display="flex" alignItems="center">
                      <Box
                        borderRadius="50%"
                        bgcolor="primary.main"
                        width={32}
                        height={32}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mr={2}
                      >
                        <Group />
                      </Box>
                      <Box fontWeight={600}>
                        <Typography>
                          {source.groupMembership?.group?.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      component="label"
                      display="flex"
                      alignItems="center"
                      pl={5}
                    >
                      <Checkbox
                        id={source.groupMembership?.group?.id}
                        checked={
                          !!source.groupMembership?.group?.id &&
                          groupIds.includes(source.groupMembership.group.id)
                        }
                        onChange={handleChange}
                      />
                      <Typography>
                        Remove {data.projectAccess?.projectAccess?.user?.name}{' '}
                        from {source.groupMembership?.group?.name}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </ul>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <SNButton onClick={onClose}>Cancel</SNButton>
        <SNButton
          snVariant="primary"
          onClick={handleDone}
          disabled={
            !data?.projectAccess?.projectAccess?.hasDirectAccess &&
            groupIds.length === 0
          }
        >
          Confirm
        </SNButton>
      </DialogActions>
    </>
  );
};

export default RevokeProjectAccess;
