import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { RemoveProject, RemoveProjectVariables } from '../types/schemaTypes';

export const DELETE_PROJECT = gql`
  mutation RemoveProject($input: DeleteInput!) {
    removeProject(input: $input) {
      id
      errors
    }
  }
`;

export const useDeleteProjectMutation = (
  options?: MutationHookOptions<RemoveProject, RemoveProjectVariables>,
) => {
  return useMutation<RemoveProject, RemoveProjectVariables>(
    DELETE_PROJECT,
    options,
  );
};
