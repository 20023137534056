import React from 'react';
import { Box, Typography } from '@mui/material';
import SingleValuePieChart from '../SingleValuePieChart';
import theme from '../../services/theme';
import { ReviewStatus } from '../../types/schemaTypes';

interface DashboardPieChartProps {
  label: string;
  total: number;
  value: number;
  status: ReviewStatus;
}

const DashboardPieChart = ({
  label,
  total,
  value,
  status,
}: DashboardPieChartProps) => {
  const determineColor = (status: ReviewStatus) => {
    switch (status) {
      case ReviewStatus.ANOMALOUS:
        return theme.palette.warning.main;
      case ReviewStatus.UNCLASSIFIED:
      case ReviewStatus.INVALID:
        return theme.palette.error.main;
    }
  };

  return (
    <Box
      bgcolor="#000000"
      height={245}
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={3}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <SingleValuePieChart
          total={total}
          value={value}
          valueColor={determineColor(status)}
          totalColor={theme.palette.grey[600]}
        />
      </div>
      <Box mt={2} display="flex" flexDirection="column" alignItems="center">
        <Box width={100} display="flex" justifyContent="center">
          <Typography variant="h3">{label}</Typography>
        </Box>
        <Box mt={1} width="100%" display="flex" justifyContent="center">
          <Typography variant="h4">{value} Records</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardPieChart;
