import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useRecoilState } from 'recoil';
import { exportJobIdsAtom } from '../../atoms/exportJobIdAtom';
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Close from '@mui/icons-material/Close';
import ExportNavigationWarningModal from '../ExportNavigationWarningModal';
import ExportSnackbarContent from './ExportSnackbarContent';
import { EntityJobState } from '../../types/schemaTypes';

const ExportSnackbar = () => {
  const [exportJobIds, setExportJobIds] = useRecoilState(exportJobIdsAtom);

  const [navWarningOpen, setNavWarningOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [jobStates, setJobStates] = React.useState<EntityJobState[]>([]);

  const handleClose = React.useCallback(() => {
    setExportJobIds([]);
  }, [setExportJobIds]);

  const handleSafeClose = () => {
    setNavWarningOpen(true);
  };

  const handleReportJobState = React.useCallback(
    (jobState: EntityJobState, index: number) => {
      setJobStates((prev) => [
        ...prev.slice(0, index),
        jobState,
        ...prev.slice(index + 1),
      ]);
    },
    [],
  );

  const exportInProgress = jobStates.some(
    (jobState) => jobState === EntityJobState.PROCESSING,
  );

  return (
    <Snackbar
      id="export-status"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={exportJobIds.length > 0}
    >
      <Box bgcolor="background.paper" minWidth={360}>
        <ExportNavigationWarningModal
          isOpen={navWarningOpen}
          setClosed={() => setNavWarningOpen(false)}
          setSnackbarClose={handleClose}
        />
        <Box
          bgcolor="grey.900"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height={52}
        >
          <Box pl={2} mr={2}>
            <Typography>Search Results Exports</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="expand collapse"
              color="inherit"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? <ExpandMore /> : <ExpandLess />}
            </IconButton>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={exportInProgress ? handleSafeClose : handleClose}
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Collapse in={expanded}>
          <Box>
            <List>
              {exportJobIds.map((exportJobId, index) => (
                <ListItem key={exportJobId}>
                  <ExportSnackbarContent
                    exportJobId={exportJobId}
                    index={index}
                    reportJobState={handleReportJobState}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Collapse>
      </Box>
    </Snackbar>
  );
};

export default ExportSnackbar;
