import React from 'react';
import { Box, CheckboxProps, Typography } from '@mui/material';

import Checkbox from '../Checkbox/Checkbox';
import styled from '../../services/styled';
import ExtensionIcon from '../ExtensionIcon';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  uploadSubsetsSelector,
  uploadSubsetsByIdAtom,
  uploadFileSelector,
} from '../../atoms/uploadFiles';

const LabelBox = styled(Box)`
  cursor: pointer;
`;

interface SheetSelectionSectionProps {
  fileId: string;
}

const SheetSelectionSection = ({ fileId }: SheetSelectionSectionProps) => {
  const file = useRecoilValue(uploadFileSelector(fileId));

  const [sheetSelections, setSheetSelections] = useRecoilState(
    uploadSubsetsSelector(fileId),
  );

  const handleChange: CheckboxProps['onChange'] = React.useCallback(
    (event, checked) => {
      setSheetSelections((prevState) => ({
        ...prevState,
        [event.target.name]: checked,
      }));
    },
    [setSheetSelections],
  );

  return (
    <Box py={1.5}>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" pr={2}>
          <ExtensionIcon fileName={file.name} />
        </Box>
        <Typography variant="h5">{file.name}</Typography>
      </Box>
      <Box pl={6} py={1}>
        {Object.entries(sheetSelections || {}).map(([sheetName, selected]) => (
          <LabelBox
            key={sheetName}
            component="label"
            display="flex"
            alignItems="center"
          >
            <Box pr={1}>
              <Checkbox
                name={sheetName}
                checked={selected}
                onChange={handleChange}
              />
            </Box>
            <Typography>{sheetName}</Typography>
          </LabelBox>
        ))}
      </Box>
    </Box>
  );
};

const UploadModalSheetSelection = () => {
  const subsetsById = useRecoilValue(uploadSubsetsByIdAtom);

  return (
    <Box py={1.5}>
      {Object.keys(subsetsById).map((fileId) => (
        <SheetSelectionSection key={fileId} fileId={fileId} />
      ))}
    </Box>
  );
};

export default UploadModalSheetSelection;
