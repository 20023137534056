import React from 'react';
import styled from '../../services/styled';
import { alpha, FormControl, Input, Select } from '@mui/material';
import { InputProps } from '@mui/material/Input/Input';
import { ExpandMore } from '@mui/icons-material';

interface SNInputProps extends InputProps {
  helperText?: string;
  label?: string;
  select?: boolean;
  size?: 'small';
}

const StyledInput = styled(Input)`
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  :hover {
    background-color: ${alpha('#fff', 0.16)};
  }
  &.Mui-focused {
    background-color: ${alpha('#fff', 0.16)};
    border-color: ${({ theme }) => theme.palette.grey['300']};
  }
`;

const StyledSelect = styled(Select)`
  > .MuiSelect-root {
    display: flex;
    align-items: center;
  }
`;
const DropdownSelect = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<SNInputProps>
>(({ children, value, size, ...rest }, ref) => {
  const InputElement = (
    <StyledInput
      disableUnderline
      margin={size === 'small' ? 'dense' : undefined}
      value={value}
      {...rest}
    />
  );

  return (
    <FormControl fullWidth={rest.fullWidth} ref={ref}>
      <StyledSelect
        id={rest.id}
        value={value}
        input={InputElement}
        IconComponent={ExpandMore}
        MenuProps={{
          sx: { marginTop: '1px' },
          transitionDuration: 0,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {children}
      </StyledSelect>
    </FormControl>
  );
});
DropdownSelect.displayName = 'DropdownSelect';

export default DropdownSelect;
