import * as React from 'react';

interface ShowFiltersProviderProps {
  children: React.ReactNode;
}

type Dispatch = (action: React.SetStateAction<boolean>) => void;

const ShowFiltersContext = React.createContext<boolean | undefined>(undefined);
const ShowFiltersDispatchContext = React.createContext<Dispatch | undefined>(
  undefined,
);

function ShowFiltersProvider({ children }: ShowFiltersProviderProps) {
  const [state, setState] = React.useState<boolean>(true);
  return (
    <ShowFiltersContext.Provider value={state}>
      <ShowFiltersDispatchContext.Provider value={setState}>
        {children}
      </ShowFiltersDispatchContext.Provider>
    </ShowFiltersContext.Provider>
  );
}
function useShowFiltersState() {
  const context = React.useContext(ShowFiltersContext);
  if (context === undefined) {
    throw new Error(
      'useShowFiltersState must be used within a ShowFiltersProvider',
    );
  }
  return context;
}

function useShowFiltersDispatch() {
  const context = React.useContext(ShowFiltersDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useShowFiltersDispatch must be used within a ShowFiltersProvider',
    );
  }
  return context;
}
export { ShowFiltersProvider, useShowFiltersState, useShowFiltersDispatch };
