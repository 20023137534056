import moment from 'moment';

export const getSafeDateValue = (
  localValue: string | null | undefined,
  value: string | null,
) => {
  if (localValue === null) return localValue;
  if (typeof localValue === 'string') {
    return moment(localValue).isValid() ? moment(localValue).toDate() : null;
  }
  return typeof value === 'string' && moment(value).isValid()
    ? moment(value).toDate()
    : null;
};

export const getGlideSafeDateValue = (value: string | null | undefined) => {
  if (typeof value === 'string') {
    return moment(value).isValid() ? moment(value).toDate() : undefined;
  }
  return typeof value === 'string' && moment(value).isValid()
    ? moment(value).toDate()
    : undefined;
};
