import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import Checkbox from '../Checkbox';
import { useRecoilState } from 'recoil';
import { listItemByIndexSelector } from '../../atoms/listSelection';
import { UserRole } from '../../types/schemaTypes';
import SNTableRow from '../SNTableRow';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import RoleDropdown from '../RoleDropdown';
import ListSummaryButton from '../ListSummaryButton';
import pluralize from 'pluralize';
import { useIsUserAllowedTo } from '../../hooks';

interface UserProjectsTableRowProps {
  id?: string;
  name?: string | null;
  description?: string | null;
  userRole: UserRole;
  createdAt: string;
  hasDirectAccess: boolean;
  sourceCount: number;
  index: number;
  onRoleChange: (projectId: string, newRole: UserRole) => void;
}

const UserProjectsTableRow = ({
  name,
  id,
  description,
  userRole,
  createdAt,
  hasDirectAccess,
  sourceCount,
  index,
  onRoleChange,
}: UserProjectsTableRowProps) => {
  const [selected, selectRow] = useRecoilState(listItemByIndexSelector(index));
  const isAdmin = useIsUserAllowedTo([UserRole.ADMINISTRATOR]);

  const handleRowSelect = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      selectRow(checked);
    },
    [selectRow],
  );

  const groupCount = sourceCount - (hasDirectAccess ? 1 : 0);

  return (
    <SNTableRow selected={selected} key={id}>
      <td>
        <Box display="block" pl={4} pr={3}>
          <Checkbox checked={selected} onChange={handleRowSelect} />
        </Box>
      </td>
      <td>
        <Link to={`/settings/projects/${id}`} component={RouterLink}>
          <Box display="flex" alignItems="center" height={56}>
            <Box fontWeight={600}>
              <Typography>{name}</Typography>
            </Box>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/projects/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <Box overflow="hidden" textOverflow="ellipsis">
              <Typography>{description}</Typography>
            </Box>
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/projects/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <RoleDropdown
              currentRole={userRole}
              id={id}
              disabled={!isAdmin}
              onRoleChange={onRoleChange}
            />
          </Box>
        </Link>
      </td>
      <td>
        <Link
          to={`/settings/projects/${id}${groupCount > 0 ? '/groups' : ''}`}
          component={RouterLink}
        >
          <Box height={56} display="flex" alignItems="center">
            {hasDirectAccess && (
              <ListSummaryButton variant="text">Direct</ListSummaryButton>
            )}
            {groupCount > 0 && (
              <Box ml={hasDirectAccess ? 1 : 0}>
                <ListSummaryButton variant="text">
                  {hasDirectAccess && '+'} {groupCount}{' '}
                  {pluralize('Group', groupCount)}
                </ListSummaryButton>
              </Box>
            )}
          </Box>
        </Link>
      </td>
      <td>
        <Link to={`/settings/projects/${id}`} component={RouterLink}>
          <Box height={56} display="flex" alignItems="center">
            <Typography>{moment(createdAt).format('YYYY-MM-DD')}</Typography>
          </Box>
        </Link>
      </td>
    </SNTableRow>
  );
};

export default UserProjectsTableRow;
