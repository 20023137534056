import { Box, Dialog, Grid } from '@mui/material';
import React from 'react';
import SelectableListToolbar from '../SelectableListToolbar';
import SNButton from '../SNButton';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  pageIdsAtom,
  selectedIdsAtom,
  selectedIdsSelector,
} from '../../atoms/listSelection';

import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import { useUsersOfGroupQuery } from '../../queries/useUsersOfGroupQuery';
import SNPagination from '../SNPagination';
import SNTable from '../SNTable';
import GroupUsersTableRow from '../GroupUsersTableRow';
import SearchableListToolbarActions from '../SearchableListToolbarActions';
import ToolbarActionGroup from '../ToolbarActionGroup';
import { Delete } from '@mui/icons-material';
import { useRemoveUserFromGroupMutation } from '../../mutations/useRemoveUserFromGroupMutation';
import AddUsersToGroupForm from '../AddUsersToGroupForm';
import { UserRole } from '../../types/schemaTypes';
import RoleMask from '../RoleMask';
import ListSelectionControl from '../ListSelectionControl';
import { collectErrors } from '../../utils/collectErrors';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';
import { useDebounce } from '../../hooks';

const headers = ['name', 'email', 'date added'];

const UserGroupDetailUsersTable = () => {
  const pageSize = 10;
  const { id } = useParams<{ id: string }>();
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const selectedIds = useRecoilValue(selectedIdsAtom);
  const resetSelectedIds = useResetRecoilState(selectedIdsSelector);
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { data, loading, error, fetchMore, refetch } = useUsersOfGroupQuery({
    variables: { id: id, filter: { searchTerm: debouncedSearchTerm } },
    onCompleted: (result) => {
      setPageIds(
        result?.group?.group?.groupMembership?.edges.map(
          (edge) => edge.node.id,
        ) || [],
      );
    },
  });
  React.useEffect(() => {
    refetch({
      id: id,
      filter: { searchTerm: debouncedSearchTerm },
    });
  }, [refetch, id, debouncedSearchTerm]);

  const [removeUserFromGroup, { data: removeData }] =
    useRemoveUserFromGroupMutation({
      refetchQueries: ['UsersOfGroup'],
      onCompleted: () => {
        resetSelectedIds();
      },
    });
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const handleOpen = React.useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleDelete = React.useCallback(() => {
    removeUserFromGroup({ variables: { input: { id: selectedIds[0] } } });
  }, [removeUserFromGroup, selectedIds]);

  const groupMemberships = React.useMemo(
    () => data?.group?.group?.groupMembership?.edges.map((edge) => edge.node),
    [data],
  );

  const pageTotal = groupMemberships?.length || 0;

  const collectedErrors = React.useMemo(() => {
    return collectErrors([
      data?.group?.errors,
      removeData?.removeUserFromGroup?.errors,
    ]);
  }, [data, removeData]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Unable to fetch user info',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <Box>
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            {...data?.group?.group?.groupMembership?.pageInfo}
            fetchMore={fetchMore}
            loading={loading}
            pageSize={pageSize}
            pageTotal={pageTotal}
            totalCount={data?.group?.group?.groupMembership?.totalCount}
          />
        </Box>
        <SearchableListToolbarActions
          value={searchTerm}
          setValue={setSearchTerm}
        >
          <RoleMask allowedRoles={[UserRole.ADMINISTRATOR]}>
            <SNButton
              startIcon={<AddIcon />}
              snVariant="text"
              onClick={handleOpen}
            >
              Add Users
            </SNButton>
          </RoleMask>
          <Dialog fullWidth open={isOpen} onClose={handleClose}>
            <AddUsersToGroupForm onClose={handleClose} />
          </Dialog>
        </SearchableListToolbarActions>
        <ToolbarActionGroup comparisonFunction={(count: number) => count === 1}>
          <Grid item>
            <RoleMask allowedRoles={[UserRole.ADMINISTRATOR]}>
              <SNButton
                onClick={handleDelete}
                snVariant="text"
                startIcon={<Delete />}
              >
                Remove User
              </SNButton>
            </RoleMask>
          </Grid>
        </ToolbarActionGroup>
      </SelectableListToolbar>
      <SNTable
        avatar
        error={error}
        hasResults={!!groupMemberships}
        headers={headers}
        id="group-users"
        loading={loading}
        rowCount={pageTotal}
        selectable
      >
        {groupMemberships?.map(({ id, user, ...groupMembership }, index) => (
          <GroupUsersTableRow
            key={id}
            index={index}
            {...groupMembership}
            {...user}
          />
        ))}
      </SNTable>
    </Box>
  );
};

export default UserGroupDetailUsersTable;
