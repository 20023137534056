import { RecoilRoot } from 'recoil';
import { StylesProvider } from '@mui/styles';
import { ThemeProvider as MuiThemeProvider } from '@mui/system';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import * as FullStory from '@fullstory/browser';
import React from 'react';

import theme from './services/theme';
import AppContent from './AppContent';
import env from '@beam-australia/react-env';
import AuthorizedApolloProvider from './AuthorizedApolloProvider';
import { useUserState } from './contexts';
import ErrorNotificationBoundary from './components/ErrorNotificationBoundary';

function App() {
  const user = useUserState();
  const useFullStory = env('FULLSTORY_ENABLED');

  if (user && useFullStory === 'TRUE') {
    FullStory.identify(user.userId, {
      displayName: user.name,
      email: user.email,
    });
  }

  return (
    <ErrorNotificationBoundary>
      <RecoilRoot>
        <AuthorizedApolloProvider>
          <MuiThemeProvider theme={theme}>
            <StylesProvider injectFirst>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <CssBaseline />
                <AppContent />
              </LocalizationProvider>
            </StylesProvider>
          </MuiThemeProvider>
        </AuthorizedApolloProvider>
      </RecoilRoot>
    </ErrorNotificationBoundary>
  );
}

export default App;
