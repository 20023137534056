import React from 'react';
import { UploadState } from '../types/schemaTypes';

interface UploadTabsProviderProps {
  initialState?: UploadState[] | null;
}

const UploadTabsContext = React.createContext<UploadState[] | null>(null);
const UploadTabsDispatchContext = React.createContext<
  React.Dispatch<React.SetStateAction<UploadState[] | null>> | undefined
>(undefined);
const UploadTabsProvider: React.FC<UploadTabsProviderProps> = ({
  initialState = null,
  children,
}) => {
  const [value, setValue] = React.useState<UploadState[] | null>(initialState);
  return (
    <UploadTabsContext.Provider value={value}>
      <UploadTabsDispatchContext.Provider value={setValue}>
        {children}
      </UploadTabsDispatchContext.Provider>
    </UploadTabsContext.Provider>
  );
};
function useUploadTabsState() {
  const context = React.useContext(UploadTabsContext);
  if (context === undefined) {
    throw new Error(
      'useUploadTabsState must be used within a UploadTabsProvider',
    );
  }
  return context;
}

function useUploadTabsDispatch() {
  const context = React.useContext(UploadTabsDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useUploadTabsDispatch must be used within a UploadTabsProvider',
    );
  }
  return context;
}
export { UploadTabsProvider, useUploadTabsState, useUploadTabsDispatch };
