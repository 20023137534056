import React from 'react';
import { Draft } from 'immer';
import { useSearchTreeDispatch, searchTreeActions } from '../contexts';
import { GlobalFilter } from '../types';

function useAddGlobalFilter(searchId: string) {
  const dispatch = useSearchTreeDispatch();

  return React.useCallback(
    (filterId: string, changes: Draft<GlobalFilter>) => {
      dispatch(
        searchTreeActions.globalFilter.add({
          key: searchId,
          id: filterId,
          value: changes,
        }),
      );
    },
    [dispatch, searchId],
  );
}

export default useAddGlobalFilter;
