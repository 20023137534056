import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  GrantUsersAccessToProjects,
  GrantUsersAccessToProjectsVariables,
} from '../types/schemaTypes';

export const GRANT_USERS_TO_PROJECTS = gql`
  mutation GrantUsersAccessToProjects(
    $input: GrantUsersAccessToProjectsInput!
  ) {
    grantUsersAccessToProjects(input: $input) {
      projectAccesses {
        user {
          id
          name
          picture
        }
        userRole
        project {
          id
          name
        }
      }
      errors
    }
  }
`;

export const useAddUsersToProjectsMutation = (
  options?: MutationHookOptions<
    GrantUsersAccessToProjects,
    GrantUsersAccessToProjectsVariables
  >,
) => {
  return useMutation<
    GrantUsersAccessToProjects,
    GrantUsersAccessToProjectsVariables
  >(GRANT_USERS_TO_PROJECTS, options);
};
