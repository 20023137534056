import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { ActiveUploads } from '../types/schemaTypes';

export const ACTIVE_UPLOADS = gql`
  query ActiveUploads {
    activeUploads @client {
      id
      fileName
      fileType
      status
    }
  }
`;

export const useActiveUploadsQuery = () => {
  const { data } = useQuery<ActiveUploads>(ACTIVE_UPLOADS);
  return data ? data.activeUploads : [];
};
