import { useQuery, QueryHookOptions } from '@apollo/client';
import gql from 'graphql-tag';
import { SearchEntity } from '../fragments';
import { UnclassifiedGroup } from '../fragments/UnclassifiedGroup';
import { EntitiesByType, EntitiesByTypeVariables } from '../types/schemaTypes';

export const ENTITIES_BY_TYPE = gql`
  query EntitiesByType(
    $first: Int
    $last: Int
    $searchQuery: SearchQuery
    $before: String
    $after: String
    $type: String
  ) {
    entitiesByType(
      last: $last
      first: $first
      searchQuery: $searchQuery
      before: $before
      after: $after
      type: $type
    ) {
      errors
      data {
        ... on EntityResultsByTypeConnection {
          totalCount
          pageInfo {
            endCursor
            startCursor
            endIndex @client
            startIndex @client
            hasNextPage
            hasPreviousPage
          }
          types {
            id
            name
          }
          currentType {
            id
            name
          }
          edges {
            cursor
            node {
              matches {
                fieldName
                display {
                  match
                  type
                }
                highlightEnd
                highlightStart
              }
              entity {
                ...SearchEntity
              }
            }
          }
        }
        ... on UnclassifiedResultGroupsConnection {
          totalCount
          pageInfo {
            endCursor
            startCursor
            endIndex @client
            startIndex @client
            hasNextPage
            hasPreviousPage
          }
          types {
            id
            name
          }
          currentType {
            id
            name
          }
          edges {
            cursor
            node {
              ...UnclassifiedGroup
            }
          }
        }
      }
    }
  }
  ${SearchEntity}
  ${UnclassifiedGroup}
`;

export const useEntitiesByTypeQuery = (
  options?: QueryHookOptions<EntitiesByType, EntitiesByTypeVariables>,
) => {
  return useQuery<EntitiesByType, EntitiesByTypeVariables>(ENTITIES_BY_TYPE, {
    notifyOnNetworkStatusChange: true,
    ...options,
  });
};
