import gql from 'graphql-tag';

export const ClassificationTypeMatch = gql`
  fragment ClassificationTypeMatch on UnclassifiedTypeMatch {
    name
    confidence
    entityType {
      id
    }
    invalidColumns {
      header
      hint
      suggestedColumns {
        name
        fieldDefinitionId
        confidence
      }
    }
    missingColumns {
      name
      fieldDefinitionId
    }
  }
`;
