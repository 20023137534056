import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  AddUsersToGroups,
  AddUsersToGroupsVariables,
} from '../types/schemaTypes';

export const ADD_USERS_TO_GROUPS = gql`
  mutation AddUsersToGroups($input: AddUsersToGroupsInput!) {
    addUsersToGroups(input: $input) {
      groupMemberships {
        user {
          id
          name
          picture
        }
        userRole
        group {
          id
          name
        }
      }
      errors
    }
  }
`;

export const useAddUsersToGroupsMutation = (
  options?: MutationHookOptions<AddUsersToGroups, AddUsersToGroupsVariables>,
) => {
  return useMutation<AddUsersToGroups, AddUsersToGroupsVariables>(
    ADD_USERS_TO_GROUPS,
    options,
  );
};
