import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { LinkEntities, LinkEntitiesVariables } from '../types/schemaTypes';

const TARGET_ENTITIES = gql`
  query LinkEntities(
    $first: Int
    $last: Int
    $searchQuery: SearchQuery
    $before: String
    $after: String
  ) {
    entities(
      last: $last
      first: $first
      searchQuery: $searchQuery
      before: $before
      after: $after
    ) {
      data {
        edges {
          node {
            entity {
              id
              displayName
            }
          }
        }
      }
      errors
    }
  }
`;

export const useTargetEntitySearchQuery = () => {
  return useLazyQuery<LinkEntities, LinkEntitiesVariables>(TARGET_ENTITIES);
};
