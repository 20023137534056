import {
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import {
  ProjectsOfGroup,
  ProjectsOfGroupVariables,
} from '../types/schemaTypes';

export const PROJECTS_OF_GROUP = gql`
  query ProjectsOfGroup(
    $id: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
    $filter: ProjectFilter
  ) {
    group(id: $id) {
      group {
        id
        name
        description
        groupProjectAccess(
          after: $after
          before: $before
          first: $first
          last: $last
          filter: $filter
        ) {
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
            endIndex @client
            startIndex @client
          }
          totalCount
          edges {
            cursor
            node {
              id
              createdAt
              project {
                id
                name
                description
              }
            }
          }
        }
      }
      errors
    }
  }
`;

export const useLazyProjectsOfGroupQuery = (
  options?: LazyQueryHookOptions<ProjectsOfGroup, ProjectsOfGroupVariables>,
) => {
  return useLazyQuery<ProjectsOfGroup, ProjectsOfGroupVariables>(
    PROJECTS_OF_GROUP,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );
};

export const useGetProjectsOfGroupQuery = (
  options?: QueryHookOptions<ProjectsOfGroup, ProjectsOfGroupVariables>,
) => {
  return useQuery<ProjectsOfGroup, ProjectsOfGroupVariables>(
    PROJECTS_OF_GROUP,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );
};
