import { useSearchFormState } from '../contexts';
import { useEntityFields } from '.';

const useBranchFields = () => {
  const formState = useSearchFormState();
  const { target } = formState;
  return useEntityFields({ ...target });
};

export default useBranchFields;
