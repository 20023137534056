import React from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import SNButton from '../SNButton';
import { useParams } from 'react-router';
import { useAddGroupsToProjectsMutation } from '../../mutations/useGrantGroupsAccessToProjects';
import GroupsToAddSelector, {
  SelectedGroups,
} from '../GroupsToAddSelector/GroupsToAddSelector';
import SNDialogTitle from '../SNDialogTitle';
import SNStatusBanner from '../SNStatusBanner';
import { collectErrors } from '../../utils/collectErrors';

interface AddGroupsToProjectFormProps {
  onClose: () => void;
}

const AddGroupsToProjectForm = ({ onClose }: AddGroupsToProjectFormProps) => {
  const { id: projectId } = useParams<{ id: string }>();
  const [selectedGroups, setSelectedGroups] = React.useState<SelectedGroups>(
    {},
  );
  const [addGroupsToProject, { data: addGroupsData }] =
    useAddGroupsToProjectsMutation({
      refetchQueries: ['GroupsOfProject'],
      onCompleted: () => {
        onClose();
      },
    });

  const handleAddGroupsToProject = React.useCallback(() => {
    const selectedGroupIds = Object.keys(selectedGroups);
    if (projectId && selectedGroupIds.length > 0) {
      const grants = selectedGroupIds.map((groupId) => {
        return { projectId, groupId };
      });
      addGroupsToProject({ variables: { input: { grants } } });
    }
  }, [addGroupsToProject, projectId, selectedGroups]);

  const collectedErrors = React.useMemo(() => {
    return collectErrors([addGroupsData?.grantGroupsAccessToProjects?.errors]);
  }, [addGroupsData]);

  return (
    <>
      <SNDialogTitle onClose={onClose}>Add Groups to Project</SNDialogTitle>
      <DialogContent>
        {collectedErrors.length > 0 && (
          <Box mb={2}>
            <SNStatusBanner status="error">
              <ul>
                {collectedErrors.map((error) => {
                  return <li key={error}>{error}</li>;
                })}
              </ul>
            </SNStatusBanner>
          </Box>
        )}
        <GroupsToAddSelector
          selectedGroups={selectedGroups}
          setSelectedGroups={setSelectedGroups}
          showGroupMembership
        />
      </DialogContent>
      <DialogActions>
        <SNButton onClick={onClose}>Cancel</SNButton>
        <SNButton
          snVariant="primary"
          onClick={handleAddGroupsToProject}
          disabled={Object.keys(selectedGroups).length === 0}
        >
          Add Groups
        </SNButton>
      </DialogActions>
    </>
  );
};

export default AddGroupsToProjectForm;
