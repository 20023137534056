import React from 'react';
import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
import SNButton from '../SNButton';
import SNDialogTitle from '../SNDialogTitle';

interface GeneralConfirmationModalProps {
  onClose: () => void;
  onProceed: () => void;
  message: string;
}

const GeneralConfirmationModalProps = ({
  onClose,
  onProceed,
  message,
}: GeneralConfirmationModalProps) => {
  const handleClose = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const handleProceed = () => {
    onProceed();
    onClose();
  };

  return (
    <>
      <SNDialogTitle onClose={handleClose}>Are you sure?</SNDialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Typography>{message}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <SNButton onClick={handleClose}>Cancel</SNButton>
        <SNButton snVariant="primary" onClick={handleProceed}>
          Continue
        </SNButton>
      </DialogActions>
    </>
  );
};
export default GeneralConfirmationModalProps;
