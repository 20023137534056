import * as React from 'react';
import { createAction, ActionType } from 'typesafe-actions';
import produce, { Draft } from 'immer';
import Moment from 'moment';
import {
  Filters,
  FilterLeaf,
  FilterBranch,
  AtLeastOne,
  FilterValueType,
  SearchTreeBranch,
} from '../types';
import { isFilterGroup } from '../utils';
import { Connective } from '../types/schemaTypes';

export const createDefaultFilterLeaf = ({
  type,
  value,
  fieldId,
}: {
  type: FilterValueType;
  value?: string;
  fieldId?: string;
}): Draft<Omit<FilterLeaf, 'parent'>> => {
  switch (type) {
    case 'BOOLEAN':
      return {
        type,
        value: [
          {
            value: value || 'true',
            type,
          },
        ],
        connective: Connective.AND,
        fieldId: fieldId || 'any_boolean',
        comparator: 'EQUAL_TO',
      };
    case 'DATE':
      return {
        type,
        value: [
          {
            value: value || Moment().format(),
            type,
          },
        ],
        connective: Connective.AND,
        comparator: 'EQUAL_TO',
        fieldId: fieldId || 'any_date',
      };
    case 'NUMBER':
      return {
        type,
        value: [
          {
            value: value || '0',
            type,
          },
        ],
        connective: Connective.AND,
        comparator: 'EQUAL_TO',
        fieldId: fieldId || 'any_number',
      };
    case 'STRING':
      return {
        type,
        value: [
          {
            value: value || '',
            type,
          },
        ],
        connective: Connective.AND,
        comparator: 'INCLUDES',
        metadata: {
          caseSensitive: false,
        },
        fieldId: fieldId || 'any_string',
      };
  }
};

interface PayloadFilterBase {
  id: string;
}

interface PayloadFilterBranchAddCreator extends PayloadFilterBase {
  parentId: string;
  children: string[];
}

interface PayloadFilterBranchAdd extends PayloadFilterBase {
  value: Draft<FilterBranch>;
}

interface PayloadFilterBranchUpdate extends PayloadFilterBase {
  value: Draft<AtLeastOne<FilterBranch>>;
}

type PayloadFilterBranchDelete = PayloadFilterBase;

interface PayloadFilterLeafAddCreator extends PayloadFilterBase {
  parentId: string;
  type: FilterValueType;
  fieldId?: string;
  value?: string;
}

interface PayloadFilterLeafAdd extends PayloadFilterBase {
  value: Draft<FilterLeaf>;
}

interface PayloadFilterLeafUpdate extends PayloadFilterBase {
  value: Draft<AtLeastOne<FilterLeaf>>;
}

type PayloadFilterLeafDelete = PayloadFilterBase;

interface PayloadUpdate {
  value: Draft<
    AtLeastOne<
      Pick<SearchTreeBranch, 'select' | 'joinData' | 'target' | 'filter'>
    >
  >;
}

const filterBranchAdd = createAction(
  'searchForm/FILTER_BRANCH_ADD',
  ({
    id,
    parentId,
    children,
  }: PayloadFilterBranchAddCreator): PayloadFilterBranchAdd => ({
    id,
    value: {
      connective: Connective.AND,
      parent: parentId,
      children,
    },
  }),
)<PayloadFilterBranchAdd>();
const filterBranchDelete = createAction(
  'searchForm/FILTER_BRANCH_DELETE',
)<PayloadFilterBranchDelete>();
const filterBranchUpdate = createAction(
  'searchForm/FILTER_BRANCH_UPDATE',
)<PayloadFilterBranchUpdate>();
const filterLeafAdd = createAction(
  'searchForm/FILTER_LEAF_ADD',
  ({
    id,
    parentId,
    type,
    fieldId,
    value,
  }: PayloadFilterLeafAddCreator): PayloadFilterLeafAdd => ({
    id,
    value: {
      ...createDefaultFilterLeaf({ type, value, fieldId }),
      parent: parentId,
    },
  }),
)<PayloadFilterLeafAdd>();
const filterLeafDelete = createAction(
  'searchForm/FILTER_LEAF_DELETE',
)<PayloadFilterLeafDelete>();
const filterLeafUpdate = createAction(
  'searchForm/FILTER_LEAF_UPDATE',
)<PayloadFilterLeafUpdate>();
const update = createAction('searchForm/UPDATE')<PayloadUpdate>();

const searchFormActions = {
  filter: {
    branch: {
      add: filterBranchAdd,
      delete: filterBranchDelete,
      update: filterBranchUpdate,
    },
    leaf: {
      add: filterLeafAdd,
      delete: filterLeafDelete,
      update: filterLeafUpdate,
    },
  },
  update,
};
type SearchFormActions = ActionType<typeof searchFormActions>;

type Dispatch = (action: SearchFormActions) => void;
interface SearchFormProviderProps {
  children: React.ReactNode;
  initialState: SearchTreeBranch;
}
const SearchFormContext = React.createContext<SearchTreeBranch | undefined>(
  undefined,
);
const SearchFormDispatchContext = React.createContext<Dispatch | undefined>(
  undefined,
);

const getFilterDescendants = (filters: Filters, id: string): string[] => {
  const filter = filters.filterBranches[id] || filters.filterLeaves[id];
  if (filter && isFilterGroup(filter)) {
    return filter.children.reduce(
      (result, child) => result.concat(getFilterDescendants(filters, child)),
      [id],
    );
  }
  return [id];
};

export const searchFormReducer = (
  draft: Draft<SearchTreeBranch>,
  action: SearchFormActions,
) => {
  switch (action.type) {
    case 'searchForm/FILTER_BRANCH_ADD':
      draft.filter.filterBranches[action.payload.id] = action.payload.value;
      break;
    case 'searchForm/FILTER_BRANCH_DELETE': {
      getFilterDescendants(draft.filter, action.payload.id).forEach(
        (filterId) => {
          delete draft.filter.filterBranches[filterId];
          delete draft.filter.filterLeaves[filterId];
        },
      );
      break;
    }
    case 'searchForm/FILTER_BRANCH_UPDATE': {
      const targetFilter = draft.filter.filterBranches[action.payload.id];
      if (targetFilter) {
        // Not as type-safe as you might think because payload relies on Partial<>
        const newFilter = {
          ...targetFilter,
          ...action.payload.value,
        };
        draft.filter.filterBranches[action.payload.id] = newFilter;
      }
      break;
    }
    case 'searchForm/FILTER_LEAF_ADD':
      draft.filter.filterLeaves[action.payload.id] = action.payload.value;
      break;
    case 'searchForm/FILTER_LEAF_DELETE': {
      delete draft.filter.filterLeaves[action.payload.id];
      break;
    }
    case 'searchForm/FILTER_LEAF_UPDATE': {
      const targetFilter = draft.filter.filterLeaves[action.payload.id];
      if (targetFilter) {
        // Not as type-safe as you might think because payload relies on Partial<>
        const newFilter = {
          ...targetFilter,
          ...action.payload.value,
        };
        draft.filter.filterLeaves[action.payload.id] = newFilter;
      }
      break;
    }
    case 'searchForm/UPDATE': {
      return {
        ...draft,
        ...action.payload.value,
      };
    }
  }
};

const curriedTreeReducer = produce(searchFormReducer);

function SearchFormProvider({
  initialState,
  children,
}: SearchFormProviderProps) {
  const [state, dispatch] = React.useReducer(curriedTreeReducer, initialState);
  return (
    <SearchFormContext.Provider value={state}>
      <SearchFormDispatchContext.Provider value={dispatch}>
        {children}
      </SearchFormDispatchContext.Provider>
    </SearchFormContext.Provider>
  );
}

function useSearchFormState() {
  const context = React.useContext(SearchFormContext);
  if (context === undefined) {
    throw new Error(
      'useSearchFormDispatch must be used within a SearchFormProvider',
    );
  }
  return context;
}

function useSearchFormDispatch() {
  const context = React.useContext(SearchFormDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useSearchFormDispatch must be used within a SearchFormProvider',
    );
  }
  return context;
}

export {
  SearchFormProvider,
  useSearchFormState,
  useSearchFormDispatch,
  searchFormActions,
};
