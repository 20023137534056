import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from '../../services/styled';
import { capitalize } from '../../utils/capitalize';
import Checkbox from '../Checkbox';
import Pill from '../Pill';
import SNTableRow from '../SNTableRow';
import { useRecoilState } from 'recoil';
import { listItemByIndexSelector } from '../../atoms/listSelection';

const StyledCell = styled('td')`
  border-bottom-color: ${({ theme }) => theme.palette.grey[600]};
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

interface EntityLinkSummaryRowProps {
  description: string;
  id: string;
  index: number;
  label: string;
  onLinksClick: (id: string) => () => void;
  value: string;
}

const EntityLinkSummaryRow = ({
  description,
  id,
  index,
  label,
  onLinksClick,
  value,
}: EntityLinkSummaryRowProps) => {
  const [selected, selectRow] = useRecoilState(listItemByIndexSelector(index));

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      selectRow(checked);
    },
    [selectRow],
  );

  return (
    <SNTableRow key={id} selected={selected}>
      <StyledCell>
        <Box pl={4} pr={3}>
          <Checkbox
            id={`row-select-${id}`}
            onChange={handleChange}
            checked={selected}
          />
        </Box>
      </StyledCell>
      <StyledCell>
        <Typography>{capitalize(label)}</Typography>
      </StyledCell>
      <StyledCell>
        <Pill onClick={onLinksClick(id)}>{value}</Pill>
      </StyledCell>
      <StyledCell>
        <Typography>{description}</Typography>
      </StyledCell>
    </SNTableRow>
  );
};

export default EntityLinkSummaryRow;
