import { Box, Dialog, Grid } from '@mui/material';
import React from 'react';
import SelectableListToolbar from '../SelectableListToolbar';
import SNButton from '../SNButton';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  pageIdsAtom,
  selectedIdsAtom,
  selectedIdsSelector,
} from '../../atoms/listSelection';
import AddIcon from '@mui/icons-material/Add';
import { useGetGroupsQuery } from '../../queries/useGetGroupsQuery';
import GroupsTableRow from '../GroupsTableRow';
import SNTable from '../SNTable';
import SNPagination from '../SNPagination';
import { useDebounce } from '../../hooks';
import CreateGroupModal from '../CreateGroupModal';
import ToolbarActionGroup from '../ToolbarActionGroup';
import ListSearchControl from '../ListSearchControl';
import { Delete } from '@mui/icons-material';
import { useDeleteGroupMutation } from '../../mutations/useDeleteGroupMutation';
import { UserRole } from '../../types/schemaTypes';
import RoleMask from '../RoleMask';
import ListSelectionControl from '../ListSelectionControl';
import { collectErrors } from '../../utils/collectErrors';
import { GeneralErrorSnackbarAtom } from '../../atoms/GeneralErrorSnackbarAtom';

const headers = ['name', 'description', 'users', 'projects', 'updated'];

const GroupsTable = () => {
  const pageSize = 10;
  const setPageIds = useSetRecoilState(pageIdsAtom);
  const setGeneralError = useSetRecoilState(GeneralErrorSnackbarAtom);
  const selectedIds = useRecoilValue(selectedIdsAtom);
  const resetSelectedIds = useResetRecoilState(selectedIdsSelector);
  const [deleteGroup, { data: deleteData }] = useDeleteGroupMutation({
    onCompleted: () => {
      resetSelectedIds();
    },
    refetchQueries: ['Groups'],
    variables: { input: { id: selectedIds[0] } },
  });
  const [newGroupModalOpen, setNewGroupModalOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { data, error, loading, fetchMore } = useGetGroupsQuery({
    variables: {
      first: pageSize,
      filter: {
        searchTerm: debouncedSearchTerm,
      },
    },
    onCompleted: (result) => {
      const pageIds = result?.groups?.data?.edges?.map((edge) => edge.node.id);
      setPageIds(pageIds || []);
    },
  });

  const groupNodes = React.useMemo(
    () => data?.groups?.data?.edges?.map((edge) => edge.node),
    [data],
  );
  const setNewGroupModalClosed = () => {
    setNewGroupModalOpen(false);
  };

  const pageTotal = groupNodes?.length || 0;

  const collectedErrors = React.useMemo(() => {
    return collectErrors([
      deleteData?.removeGroup?.errors,
      data?.groups?.errors,
    ]);
  }, [deleteData, data]);
  React.useEffect(() => {
    if (collectedErrors.length > 0) {
      setGeneralError({
        open: true,
        message: 'Error with groups',
        details: collectedErrors.toString(),
      });
    }
  }, [collectedErrors, setGeneralError]);

  return (
    <Box>
      <SelectableListToolbar>
        <Box display="flex" alignItems="center" pl={2}>
          <Box display="flex" alignItems="center" pr={2}>
            <ListSelectionControl />
          </Box>
          <SNPagination
            {...data?.groups?.data?.pageInfo}
            fetchMore={fetchMore}
            loading={loading}
            pageSize={pageSize}
            pageTotal={pageTotal}
            totalCount={data?.groups?.data?.totalCount}
          />
        </Box>
        <Box>
          <Grid container spacing={1}>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 0}
            >
              <Grid item>
                <Dialog
                  fullWidth
                  open={newGroupModalOpen}
                  onClose={setNewGroupModalClosed}
                >
                  <CreateGroupModal onClose={setNewGroupModalClosed} />
                </Dialog>
                <RoleMask allowedRoles={[UserRole.ADMINISTRATOR]}>
                  <SNButton
                    startIcon={<AddIcon />}
                    snVariant="text"
                    onClick={() => setNewGroupModalOpen(true)}
                  >
                    Create Group
                  </SNButton>
                </RoleMask>
              </Grid>
              <Grid item>
                <ListSearchControl
                  setValue={setSearchTerm}
                  value={searchTerm}
                />
              </Grid>
            </ToolbarActionGroup>
            <ToolbarActionGroup
              comparisonFunction={(count: number) => count === 1}
            >
              <Grid item>
                <RoleMask allowedRoles={[UserRole.ADMINISTRATOR]}>
                  <SNButton
                    onClick={() => deleteGroup()}
                    snVariant="text"
                    startIcon={<Delete />}
                  >
                    Delete Group
                  </SNButton>
                </RoleMask>
              </Grid>
            </ToolbarActionGroup>
          </Grid>
        </Box>
      </SelectableListToolbar>
      <SNTable
        error={error}
        hasResults={!!groupNodes}
        headers={headers}
        id="groups"
        loading={loading}
        rowCount={pageTotal}
        selectable
      >
        {groupNodes?.map(
          ({ groupMembership, groupProjectAccess, ...group }, index) => (
            <GroupsTableRow
              key={group.id}
              index={index}
              groupMembership={groupMembership?.edges}
              totalGroupMembershipCount={groupMembership?.totalCount || 0}
              totalGroupProjectAccessCount={groupProjectAccess?.totalCount || 0}
              {...group}
            />
          ),
        )}
      </SNTable>
    </Box>
  );
};

export default GroupsTable;
